import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useKeycloak } from '@react-keycloak/web';
import {
  isValidCronMinute,
  isValidCronHour,
  isValidCronDayOfMonth,
  isValidCronMonthOfYear,
  isValidCronDayOfWeek,
  isCronExpressionInUse
}
  from '../../../common/validation/cron/CronExpressionValidator';
import {
  isValidIntervalEveryValue,
  isValidIntervalPeriodValue,
  intervalIsInUse
}
  from '../../../common/validation/interval/IntervalValidator';
import {
  isValidJobNameValue,
  jobNameIsInUse,
  getPossibleTasknames
}
  from '../../../common/validation/job/JobValidator';
import CronSchedulesTab from './CronSchedulesTab';
import IntervalsTab from './IntervalsTab';
import JobsTab from './JobsTab';
import EditCrontabDialog from './modals/EditCrontabDialog';
import AddIntervalDialog from './modals/AddIntervalDialog';
import EditIntervalDialog from './modals/EditIntervalDialog';
import JobDialog from './modals/JobDialog';
import AddCrontabDialog from './modals/AddCrontabDialog';
import './ScheduledTasksComponent.css';
import { fetchErrorMessage } from '../../mainpage';

const rowsPerPage = 20;

const crontabExpressionUrl = '/middleware/api/crontab/';
const intervalUrl = '/middleware/api/interval/';
const jobUrl = '/middleware/api/definedtasks/';
const celeryBeatScheduleUrl = '/middleware/api/v3/periodic_task/celery_beat_schedule';

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `scheduled-tasks-tab-${index}`,
    'aria-controls': `scheduled-tasks-tabpanel-${index}`,
  };
}

function createCronExpressionRowData(id, minute, hour, day_of_week, day_of_month, month_of_year, timezone) {
  return {
    id, minute, hour, day_of_week, day_of_month, month_of_year, timezone
  };
}

function createIntervalsRowData(id, every, period) {
  return {
    id, every, period
  };
}

function createJobsRowData(id, name, task, enabled, last_run_at, total_run_count, date_changed,
  crontab_id, interval_id, one_off, start_time, expire_seconds) {
  return {
    id, name, task, enabled, last_run_at, total_run_count, date_changed,
    crontab_id, interval_id, one_off, start_time, expire_seconds
  };
}

export default function ScheduledTasksComponent() {
  const { keycloak } = useKeycloak();

  const [tabIndex, setTabIndex] = useState(0);

  // snackbar state vars
  const [snackState,] = React.useState({
    snackbarVertical: 'bottom',
    snackbarHorizontal: 'center',
  });

  const { snackbarHorizontal, snackbarVertical } = snackState;

  const [cronSchedulesRefreshNotificationOpen, setCronSchedulesRefreshNotificationOpen] = useState(false);
  const cronSchedulesRefreshNotificationMessage = "Cron Expressions Data Refreshed";

  const [intervalsRefreshNotificationOpen, setIntervalsRefreshNotificationOpen] = useState(false);
  const intervalsRefreshNotificationMessage = "Intervals Data Refreshed";

  const [jobsRefreshNotificationOpen, setJobsRefreshNotificationOpen] = useState(false);
  const jobsRefreshNotificationMessage = "Jobs Data Refreshed";
  const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
  const handleFetchErrorClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFetchErrorMessageOpen(false);
  };

  // cron expression tab state vars
  const [cronExpressionsOrder, setCronExpressionsOrder] = useState('asc');
  const [cronExpressionsOrderBy, setCronExpressionsOrderBy] = useState('id');
  const [cronExpressionsSelected, setCronExpressionsSelected] = useState([]);
  const [cronExpressionsOffset, setCronExpressionsOffset] = useState(0);
  const [cronExpressionsPage, setCronExpressionsPage] = useState(0);
  const [cronExpressionsRows, setCronExpressionsRows] = useState([]);
  const [cronExpressionsTotal, setCronExpressionsTotal] = useState(0);
  const [ceToggle, setCeToggle] = useState(true);
  const [addCrontabButtonEnabled,] = useState(true);
  const [editCrontabButtonEnabled, setEditCrontabButtonEnabled] = useState(false);
  const [deleteCrontabButtonEnabled, setDeleteCrontabButtonEnabled] = useState(false);
  const [selectedCrontabId, setSelectedCrontabId] = useState(0);
  const [cronSwitch, setCronSwitch] = useState(false);

  // add cron expression modal related
  const [addCrontabModalOpen, setAddCrontabModalOpen] = useState(false);
  const [addCrontabModalMinute, setAddCrontabModalMinute] = useState('*');
  const [addCrontabModalMinuteValid, setAddCrontabModalMinuteValid] = useState(false);
  const [addCrontabModalMinuteErrorMsg, setAddCrontabModalMinuteErrorMsg] = useState('');
  const [addCrontabModalHour, setAddCrontabModalHour] = useState('*');
  const [addCrontabModalHourValid, setAddCrontabModalHourValid] = useState(false);
  const [addCrontabModalHourErrorMsg, setAddCrontabModalHourErrorMsg] = useState('');
  const [addCrontabModalDayOfMonth, setAddCrontabModalDayOfMonth] = useState('*');
  const [addCrontabModalDayOfMonthValid, setAddCrontabModalDayOfMonthValid] = useState(false);
  const [addCrontabModalDayOfMonthErrorMsg, setAddCrontabModalDayOfMonthErrorMsg] = useState('');
  const [addCrontabModalMonthOfYear, setAddCrontabModalMonthOfYear] = useState('*');
  const [addCrontabModalMonthOfYearValid, setAddCrontabModalMonthOfYearValid] = useState(false);
  const [addCrontabModalMonthOfYearErrorMsg, setAddCrontabModalMonthOfYearErrorMsg] = useState('');
  const [addCrontabModalDayOfWeek, setAddCrontabModalDayOfWeek] = useState('*');
  const [addCrontabModalDayOfWeekValid, setAddCrontabModalDayOfWeekValid] = useState(false);
  const [addCrontabModalDayOfWeekErrorMsg, setAddCrontabModalDayOfWeekErrorMsg] = useState('');
  const [addCrontabModalTimezone,] = useState('UTC');
  const [addCrontabModalAddButtonEnabled, setAddCrontabModalAddButtonEnabled] = useState(false);
  const [addCrontabModalValidationMessage, setAddCrontabModalValidationMessage] = useState("Cron Expression is Invalid");

  // edit cron expression modal related
  const [editCrontabModalOpen, setEditCrontabModalOpen] = useState(false);
  const [editCrontabModalMinute, setEditCrontabModalMinute] = useState('*');
  const [editCrontabModalMinuteValid, setEditCrontabModalMinuteValid] = useState(false);
  const [editCrontabModalMinuteErrorMsg, setEditCrontabModalMinuteErrorMsg] = useState('');
  const [editCrontabModalHour, setEditCrontabModalHour] = useState('*');
  const [editCrontabModalHourValid, setEditCrontabModalHourValid] = useState(false);
  const [editCrontabModalHourErrorMsg, setEditCrontabModalHourErrorMsg] = useState('');
  const [editCrontabModalDayOfMonth, setEditCrontabModalDayOfMonth] = useState('*');
  const [editCrontabModalDayOfMonthValid, setEditCrontabModalDayOfMonthValid] = useState(false);
  const [editCrontabModalDayOfMonthErrorMsg, setEditCrontabModalDayOfMonthErrorMsg] = useState('');
  const [editCrontabModalMonthOfYear, setEditCrontabModalMonthOfYear] = useState('*');
  const [editCrontabModalMonthOfYearValid, setEditCrontabModalMonthOfYearValid] = useState(false);
  const [editCrontabModalMonthOfYearErrorMsg, setEditCrontabModalMonthOfYearErrorMsg] = useState('');
  const [editCrontabModalDayOfWeek, setEditCrontabModalDayOfWeek] = useState('*');
  const [editCrontabModalDayOfWeekValid, setEditCrontabModalDayOfWeekValid] = useState(false);
  const [editCrontabModalDayOfWeekErrorMsg, setEditCrontabModalDayOfWeekErrorMsg] = useState('');
  const [editCrontabModalTimezone, setEditCrontabModalTimezone] = useState('UTC');
  const [editCrontabModalUpdateButtonEnabled, setEditCrontabModalUpdateButtonEnabled] = useState(false);
  const [editCrontabModalValidationMessage, setEditCrontabModalValidationMessage] = useState("Cron Expression is Valid");

  // intervals tab state vars
  const [intervalsOrder, setIntervalsOrder] = useState('asc');
  const [intervalsOrderBy, setIntervalsOrderBy] = useState('id');
  const [intervalsSelected, setIntervalsSelected] = useState([]);
  const [intervalsOffset, setIntervalsOffset] = useState(0);
  const [intervalsPage, setIntervalsPage] = useState(0);
  const [intervalsRows, setIntervalsRows] = useState([]);
  const [intervalsTotal, setIntervalsTotal] = useState(0);
  const [addIntervalButtonEnabled,] = useState(true);
  const [editIntervalButtonEnabled, setEditIntervalButtonEnabled] = useState(false);
  const [deleteIntervalButtonEnabled, setDeleteIntervalButtonEnabled] = useState(false);
  const [selectedIntervalId, setSelectedIntervalId] = useState(0);
  const [intervalSwitch, setIntervalSwitch] = useState(false);

  // add interval modal related
  const [addIntervalModalOpen, setAddIntervalModalOpen] = useState(false);
  const [addIntervalModalEvery, setAddIntervalModalEvery] = useState(1);
  const [addIntervalModalEveryValid, setAddIntervalModalEveryValid] = useState(false);
  const [addIntervalModalEveryErrorMsg, setAddIntervalModalEveryErrorMsg] = useState('');
  const [addIntervalModalPeriod, setAddIntervalModalPeriod] = useState('minutes');
  const [addIntervalModalPeriodValid, setAddIntervalModalPeriodValid] = useState(false);
  const [addIntervalModalPeriodErrorMsg, setAddIntervalModalPeriodErrorMsg] = useState('');
  const [addIntervalModalAddButtonEnabled, setAddIntervalModalAddButtonEnabled] = useState(false);
  const [addIntervalModalValidationMessage, setAddIntervalModalValidationMessage] = useState("Interval is Invalid");

  // edit interval modal related
  const [editIntervalModalOpen, setEditIntervalModalOpen] = useState(false);
  const [editIntervalModalEvery, setEditIntervalModalEvery] = useState(1);
  const [editIntervalModalEveryValid, setEditIntervalModalEveryValid] = useState(false);
  const [editIntervalModalEveryErrorMsg, setEditIntervalModalEveryErrorMsg] = useState('');
  const [editIntervalModalPeriod, setEditIntervalModalPeriod] = useState('minutes');
  const [editIntervalModalPeriodValid, setEditIntervalModalPeriodValid] = useState(false);
  const [editIntervalModalPeriodErrorMsg, setEditIntervalModalPeriodErrorMsg] = useState('');
  const [editIntervalModalUpdateButtonEnabled, setEditIntervalModalUpdateButtonEnabled] = useState(false);
  const [editIntervalModalValidationMessage, setEditIntervalModalValidationMessage] = useState(false);

  // jobs tab state vars
  const [jobsOrder, setJobsOrder] = useState('asc');
  const [jobsOrderBy, setJobsOrderBy] = useState('name');
  const [jobsSelected, setJobsSelected] = useState([]);
  const [jobsOffset, setJobsOffset] = useState(0);
  const [jobsPage, setJobsPage] = useState(0);
  const [jobsRows, setJobsRows] = useState([]);
  const [jobsTotal, setJobsTotal] = useState(0);
  const [jobSwitch, setJobSwitch] = useState(false);

  // jobs modal related
  const [addJobButtonEnabled,] = useState(true);
  const [editJobButtonEnabled, setEditJobButtonEnabled] = useState(false);
  const [deleteJobButtonEnabled, setDeleteJobButtonEnabled] = useState(false);
  const [jobModalOpen, setJobModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(0);
  const [selectedJobName, setSelectedJobName] = useState('');
  const [selectedJobNameValid, setSelectedJobNameValid] = useState(false);
  const [jobModalNameErrorMsg, setJobModalNameErrorMsg] = useState('');
  const [selectedJobTask, setSelectedJobTask] = useState('');
  const [selectedJobEnabled, setSelectedJobEnabled] = useState(false);
  const [selectedJobLastRun, setSelectedJobLastRun] = useState('');
  const [selectedJobTotalRunCount, setSelectedJobTotalRunCount] = useState(0);
  const [selectedJobDateChanged, setSelectedJobDateChanged] = useState('');
  const [selectedJobCrontabId, setSelectedJobCrontabId] = useState('0');
  const [selectedJobIntervalId, setSelectedJobIntervalId] = useState('0');
  const [selectedJobOneOff, setSelectedJobOneOff] = useState(false);
  const [jobModalCrontabOptions, setJobModalCrontabOptions] = useState([]);
  const [jobModalIntervalOptions, setJobModalIntervalOptions] = useState([]);
  const [schedTaskData, setSchedTaskData] = useState([])

  const toggleCronData = () => {
    // This is where the variable created should reverse but it's not happening.
    const foo = ceToggle;
    setCeToggle(!foo);
  }

  useEffect(() => {
    fetchCronExpressionsData();
  }, [cronSwitch]);

  useEffect(() => {
    fetchIntervalsData();
  }, [intervalSwitch]);

  useEffect(() => {
    fetchJobsData();
  }, [jobSwitch]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleToggleEditCrontabButtonVisibility = (val) => {
    setEditCrontabButtonEnabled(val);
  };

  const handleToggleDeleteCrontabButtonVisibility = (val) => {
    setDeleteCrontabButtonEnabled(val);
  };

  const fetchCeleryScheduleModalData = async () => {
    fetch(celeryBeatScheduleUrl, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      }
    }).then((response) => {
      if (!response.ok) throw new Error(response.status);
      else {
        // console.log(response);
        return response.json();
      }
    }).then((respData) => {
      setSchedTaskData(respData['data']);
      // console.log('Respy: ', respData['data'])
    })
  }

  const fetchCrontabModalData = async () => {
    await fetch(crontabExpressionUrl
      + selectedCrontabId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setSelectedCrontabId(respData['id']);
        setEditCrontabModalMinute(respData['minute']);
        setEditCrontabModalMinuteValid(true)
        setEditCrontabModalMinuteErrorMsg('');
        setEditCrontabModalHour(respData['hour']);
        setEditCrontabModalHourValid(true)
        setEditCrontabModalHourErrorMsg('');
        setEditCrontabModalDayOfMonth(respData['day_of_month']);
        setEditCrontabModalDayOfMonthValid(true)
        setEditCrontabModalDayOfMonthErrorMsg('');
        setEditCrontabModalMonthOfYear(respData['month_of_year']);
        setEditCrontabModalMonthOfYearValid(true)
        setEditCrontabModalMonthOfYearErrorMsg('');
        setEditCrontabModalDayOfWeek(respData['day_of_week']);
        setEditCrontabModalDayOfWeekValid(true)
        setEditCrontabModalDayOfWeekErrorMsg('');
        setEditCrontabModalTimezone(respData['timezone']);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const updateCrontabEntry = async () => {
    let patchData = {
      id: selectedCrontabId,
      minute: editCrontabModalMinute,
      hour: editCrontabModalHour,
      day_of_week: editCrontabModalDayOfWeek,
      day_of_month: editCrontabModalDayOfMonth,
      month_of_year: editCrontabModalMonthOfYear
      // Not supplying timezone
    };
    await fetch(crontabExpressionUrl
      + selectedCrontabId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setSelectedCrontabId(respData['id']);
        setEditCrontabModalMinute(respData['minute']);
        setEditCrontabModalHour(respData['hour']);
        setEditCrontabModalDayOfWeek(respData['day_of_week']);
        setEditCrontabModalDayOfMonth(respData['day_of_month']);
        setEditCrontabModalMonthOfYear(respData['month_of_year']);

        fetchCronExpressionsData();
        setEditCrontabModalOpen(false);
        setDeleteCrontabButtonEnabled(false);
        setEditCrontabButtonEnabled(false);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const addCrontabEntry = async () => {
    let postData = {
      minute: addCrontabModalMinute,
      hour: addCrontabModalHour,
      day_of_week: addCrontabModalDayOfWeek,
      day_of_month: addCrontabModalDayOfMonth,
      month_of_year: addCrontabModalMonthOfYear,
      // hard-coding to UTC
      timezone: addCrontabModalTimezone
    };
    await fetch(crontabExpressionUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchCronExpressionsData();
        setAddCrontabModalOpen(false);
        setDeleteCrontabButtonEnabled(false);
        setEditCrontabButtonEnabled(false);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const deleteCrontabEntry = async (fetchFunctionCallback) => {
    await fetch(crontabExpressionUrl
      + selectedCrontabId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        fetchCronExpressionsData();
      });
  };

  const handleAddCrontabButtonClick = (fetchFunctionCallback) => {

    setAddCrontabModalMinute("*");
    setAddCrontabModalMinuteValid(true);
    setAddCrontabModalMinuteErrorMsg("");
    setAddCrontabModalHour("*");
    setAddCrontabModalHourValid(true);
    setAddCrontabModalHourErrorMsg("");
    setAddCrontabModalDayOfWeek("*");
    setAddCrontabModalDayOfWeekValid(true);
    setAddCrontabModalDayOfWeekErrorMsg("");
    setAddCrontabModalDayOfMonth("*");
    setAddCrontabModalDayOfMonthValid(true);
    setAddCrontabModalDayOfMonthErrorMsg("");
    setAddCrontabModalMonthOfYear("*");
    setAddCrontabModalMonthOfYearValid(true);
    setAddCrontabModalMonthOfYearErrorMsg("");

    setAddCrontabModalAddButtonEnabled(false);
    setAddCrontabModalOpen(true);
  };

  const handleAddCrontabModalAddButtonClick = (event) => {
    addCrontabEntry();
  };

  const handleEditCrontabButtonClick = (event) => {
    // fetch the crontab data for display in model
    fetchCrontabModalData();
    setEditCrontabModalOpen(true);
  };

  const handleDeleteCrontabButtonClick = (fetchFunctionCallback) => {
    deleteCrontabEntry(fetchFunctionCallback);
  };

  const handleEditCrontabModalUpdateButtonClick = (event) => {
    updateCrontabEntry();
  };

  const handleAddCrontabModalClose = (val) => {
    setAddCrontabModalOpen(val);
  };

  const handleEditCrontabModalClose = (val) => {
    setEditCrontabModalOpen(val);
  };

  const handleToggleEditIntervalButtonVisibility = (val) => {
    setEditIntervalButtonEnabled(val);
  };

  const handleToggleDeleteIntervalButtonVisibility = (val) => {
    setDeleteIntervalButtonEnabled(val);
  };

  const fetchIntervalModalData = async () => {
    await fetch(intervalUrl
      + selectedIntervalId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setSelectedIntervalId(respData['id']);
        setEditIntervalModalEvery(respData['every']);
        setEditIntervalModalEveryValid(true);
        setEditIntervalModalEveryErrorMsg('');
        setEditIntervalModalPeriod(respData['period']);
        setEditIntervalModalPeriodValid(true);
        setEditIntervalModalPeriodErrorMsg('');
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const updateIntervalEntry = async () => {
    let patchData = {
      id: selectedIntervalId,
      every: editIntervalModalEvery,
      period: editIntervalModalPeriod
    };
    await fetch(intervalUrl
      + selectedIntervalId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setSelectedIntervalId(respData['id']);
        setEditIntervalModalEvery(respData['every']);
        setEditIntervalModalPeriod(respData['period']);

        setEditIntervalModalOpen(false);
        setDeleteIntervalButtonEnabled(false);
        setEditIntervalButtonEnabled(false);

        fetchIntervalsData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const addIntervalEntry = async (fetchFunctionCallback) => {
    let postData = {
      every: addIntervalModalEvery,
      period: addIntervalModalPeriod
    };
    await fetch(intervalUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchFunctionCallback();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const deleteIntervalEntry = async (fetchFunctionCallback) => {
    await fetch(intervalUrl
      + selectedIntervalId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        fetchFunctionCallback();
      });
  };

  const handleAddIntervalButtonClick = (event) => {
    // fetch the interval data for display in model
    setAddIntervalModalEvery("");
    setAddIntervalModalEveryValid(false);
    setAddIntervalModalEveryErrorMsg("Must be a valid Number");
    setAddIntervalModalPeriod("");
    setAddIntervalModalPeriodValid(false);
    setAddIntervalModalPeriodErrorMsg("Must be a valid period, e.g. hours");
    setAddIntervalModalAddButtonEnabled(false);
    setAddIntervalModalValidationMessage("Interval is Invalid")
    setAddIntervalModalOpen(true);
  };

  const handleEditIntervalButtonClick = (event) => {
    // fetch the interval data for display in model
    fetchIntervalModalData();
    setEditIntervalModalOpen(true);
  };

  const handleDeleteIntervalButtonClick = (fetchFunctionCallback) => {
    deleteIntervalEntry(fetchFunctionCallback);
  };

  const handleAddIntervalModalAddButtonClick = (fetchFunctionCallback) => {
    addIntervalEntry(fetchFunctionCallback);
    setAddIntervalModalOpen(false);
    fetchIntervalsData();
  };

  const handleEditIntervalModalUpdateButtonClick = (event) => {
    updateIntervalEntry();
  };


  const handleAddIntervalModalClose = (val) => {
    setAddIntervalModalOpen(val);
    fetchIntervalsData();
  };

  const handleEditIntervalModalClose = (val) => {
    setEditIntervalModalOpen(val);
    fetchIntervalsData();
  };

  const fetchJobModalData = async () => {
    await fetch(jobUrl
      + selectedJobId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setSelectedJobId(respData['id']);
        setSelectedJobName(respData['name']);
        setSelectedJobNameValid(true);
        setJobModalNameErrorMsg('');
        setSelectedJobTask(respData['task']);
        setSelectedJobEnabled(respData['enabled']);
        setSelectedJobLastRun(respData['last_run_at']);
        setSelectedJobTotalRunCount(respData['total_run_count']);
        setSelectedJobDateChanged(respData['date_changed']);
        if (respData['crontab_id'] !== null) {
          setSelectedJobCrontabId(respData['crontab_id'] + '');
        }
        else {
          setSelectedJobCrontabId('0');
        }
        if (respData['interval_id'] !== null) {
          setSelectedJobIntervalId(respData['interval_id'] + '');
        }
        else {
          setSelectedJobIntervalId('0');
        }
        setSelectedJobOneOff(respData['one_off']);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });

    // fetch list of cron expressions for use in job modal dialog
    await fetch(crontabExpressionUrl, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          let entryId = entry['id'];
          let entryAsString = entry['minute'] + ' ' + entry['hour'] + ' ' + entry['day_of_week']
            + ' ' + entry['day_of_month'] + ' ' + entry['month_of_year'] + ' ' + entry['timezone'];
          tmpRows.push({ id: entryId, cron_expression: entryAsString });
        }

        setJobModalCrontabOptions(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });

    // fetch list of intervals for use in job modal dialog
    await fetch(intervalUrl, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          let entryId = entry['id'];
          let entryAsString = entry['every'] + ' ' + entry['period'];
          tmpRows.push({ id: entryId, interval: entryAsString });
        }

        setJobModalIntervalOptions(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const updateJobEntry = async () => {
    let patchData = {
      id: selectedJobId,
      name: selectedJobName,
      task: selectedJobTask,
      enabled: selectedJobEnabled,
      crontab_id: selectedJobCrontabId !== '0' ? parseInt(selectedJobCrontabId) : null,
      interval_id: selectedJobIntervalId !== '0' ? parseInt(selectedJobIntervalId) : null,
      one_off: selectedJobOneOff
    };
    let stringPatchData = JSON.stringify(patchData);
    await fetch(jobUrl
      + selectedJobId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: stringPatchData
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setSelectedJobId(respData['id']);
        setSelectedJobName(respData['name']);
        setSelectedJobTask(respData['task']);
        setSelectedJobEnabled(respData['enabled']);
        setSelectedJobLastRun(respData['last_run_at']);
        setSelectedJobTotalRunCount(respData['total_run_count']);
        setSelectedJobDateChanged(respData['date_changed']);
        if (respData['crontab_id'] !== null) {
          setSelectedJobCrontabId(respData['crontab_id'] + '');
        }
        else {
          setSelectedJobCrontabId('0');
        }
        if (respData['interval_id'] !== null) {
          setSelectedJobIntervalId(respData['interval_id'] + '');
        }
        else {
          setSelectedJobIntervalId('0');
        }
        setSelectedJobOneOff(respData['one_off']);
        setJobModalOpen(false);
        setDeleteJobButtonEnabled(false);
        setEditJobButtonEnabled(false);

        fetchJobsData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const addJobEntry = async (fetchFunctionCallback) => {
    let newJobTempName = "_NewJob_";

    let postData = {
      name: newJobTempName,
      task: getPossibleTasknames()[0],
      enabled: false,
      crontab_id: 1,
      interval_id: null,
      expire_seconds: 0
    };
    await fetch(jobUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchFunctionCallback();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const deleteJobEntry = async (fetchFunctionCallback) => {
    await fetch(jobUrl
      + selectedJobId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        fetchFunctionCallback();
      });
  };

  const handleAddJobButtonClick = (fetchFunctionCallback) => {
    addJobEntry(fetchFunctionCallback);
  };

  const handleEditJobButtonClick = (event) => {
    // fetch the job data for display in model
    fetchJobModalData();
    setJobModalOpen(true);
  };

  const handleDeleteJobButtonClick = (fetchFunctionCallback) => {
    deleteJobEntry(fetchFunctionCallback);
  };

  const handleJobModalUpdateButtonClick = (event) => {
    updateJobEntry();
  };

  const handleJobModalClose = (val) => {
    setJobModalOpen(val);
  };

  const handleToggleEditJobButtonVisibility = (val) => {
    setEditJobButtonEnabled(val);
  };

  const handleToggleDeleteJobButtonVisibility = (val) => {
    setDeleteJobButtonEnabled(val);
  };

  const handleAddCrontabModalValidateButtonClicked = () => {
    let shouldEnableAddButton = true;
    let validationMessage = "Cron Expression is Valid";
    // check the various values in the modal to ensure they have
    // valid values and if they don't, this function will return false.

    // minute
    if (!addCrontabModalMinuteValid) {
      shouldEnableAddButton = false;
    }

    // hour
    if (!addCrontabModalHourValid) {
      shouldEnableAddButton = false;
    }

    // day of month
    if (!addCrontabModalDayOfMonthValid) {
      shouldEnableAddButton = false;
    }

    // month of year
    if (!addCrontabModalMonthOfYearValid) {
      shouldEnableAddButton = false;
    }

    // day of week
    if (!addCrontabModalDayOfWeekValid) {
      shouldEnableAddButton = false;
    }

    if (shouldEnableAddButton) {
      // if the fields themselves are valid check to see if they would conflict with
      // an existing entry
      isCronExpressionInUse(crontabExpressionUrl,
        keycloak.token,
        null,
        addCrontabModalMinute,
        addCrontabModalHour,
        addCrontabModalDayOfMonth,
        addCrontabModalMonthOfYear,
        addCrontabModalDayOfWeek,
        setAddCrontabModalAddButtonEnabled,
        setAddCrontabModalValidationMessage
      );
    }
    else {
      setAddCrontabModalAddButtonEnabled(shouldEnableAddButton);
      setAddCrontabModalValidationMessage(validationMessage);
    }
  }

  const handleEditCrontabModalValidateButtonClicked = () => {
    let shouldEnableUpdateButton = true;
    let validationMessage = "Cron Expression is Valid";

    // check the various values in the modal to ensure they have
    // valid values and if they don't, this function will return false.

    // minute
    if (!editCrontabModalMinuteValid) {
      shouldEnableUpdateButton = false;
    }

    // hour
    if (!editCrontabModalHourValid) {
      shouldEnableUpdateButton = false;
    }

    // day of month
    if (!editCrontabModalDayOfMonthValid) {
      shouldEnableUpdateButton = false;
    }

    // month of year
    if (!editCrontabModalMonthOfYearValid) {
      shouldEnableUpdateButton = false;
    }

    // day of week
    if (!editCrontabModalDayOfWeekValid) {
      shouldEnableUpdateButton = false;
    }

    if (shouldEnableUpdateButton) {
      // if the fields themselves are valid check to see if they would conflict with
      // an existing entry
      isCronExpressionInUse(crontabExpressionUrl,
        keycloak.token,
        selectedCrontabId,
        editCrontabModalMinute,
        editCrontabModalHour,
        editCrontabModalDayOfMonth,
        editCrontabModalMonthOfYear,
        editCrontabModalDayOfWeek,
        setEditCrontabModalUpdateButtonEnabled,
        setEditCrontabModalValidationMessage
      );
    }
    else {
      setEditCrontabModalUpdateButtonEnabled(shouldEnableUpdateButton);
      setEditCrontabModalValidationMessage(validationMessage);
    }
  }

  const handleAddCrontabModalMinuteChanged = (userInput) => {
    // validate that what the user typed in is valid as the minute field of a cron
    // expression
    let validInput = isValidCronMinute(userInput);

    setAddCrontabModalMinute(userInput);
    setAddCrontabModalMinuteValid(validInput);
    setAddCrontabModalMinuteErrorMsg(validInput ? "" : "Typed input is not a valid minute field value");
  }

  const handleEditCrontabModalMinuteChanged = (userInput) => {
    // validate that what the user typed in is valid as the minute field of a cron
    // expression
    let validInput = isValidCronMinute(userInput);

    setEditCrontabModalMinute(userInput);
    setEditCrontabModalMinuteValid(validInput);
    setEditCrontabModalMinuteErrorMsg(validInput ? "" : "Typed input is not a valid minute field value");
  }

  const handleAddCrontabModalHourChanged = (userInput) => {
    // validate that what the user typed in is valid as the hour field of a cron
    // expression
    let validInput = isValidCronHour(userInput);

    setAddCrontabModalHour(userInput);
    setAddCrontabModalHourValid(validInput);
    setAddCrontabModalHourErrorMsg(validInput ? "" : "Typed input is not a valid hour field value");
  }

  const handleEditCrontabModalHourChanged = (userInput) => {
    // validate that what the user typed in is valid as the hour field of a cron
    // expression
    let validInput = isValidCronHour(userInput);

    setEditCrontabModalHour(userInput);
    setEditCrontabModalHourValid(validInput);
    setEditCrontabModalHourErrorMsg(validInput ? "" : "Typed input is not a valid hour field value");
  }

  const handleAddCrontabModalDayOfMonthChanged = (userInput) => {
    // validate that what the user typed in is valid as the day of month field of a cron
    // expression
    let validInput = isValidCronDayOfMonth(userInput);

    setAddCrontabModalDayOfMonth(userInput);
    setAddCrontabModalDayOfMonthValid(validInput);
    setAddCrontabModalDayOfMonthErrorMsg(validInput ? "" : "Typed input is not a valid day of month field value");
  }

  const handleEditCrontabModalDayOfMonthChanged = (userInput) => {
    // validate that what the user typed in is valid as the day of month field of a cron
    // expression
    let validInput = isValidCronDayOfMonth(userInput);

    setEditCrontabModalDayOfMonth(userInput);
    setEditCrontabModalDayOfMonthValid(validInput);
    setEditCrontabModalDayOfMonthErrorMsg(validInput ? "" : "Typed input is not a valid day of month field value");
  }

  const handleAddCrontabModalMonthOfYearChanged = (userInput) => {
    // validate that what the user typed in is valid as the month of year field of a cron
    // expression
    let validInput = isValidCronMonthOfYear(userInput);

    setAddCrontabModalMonthOfYear(userInput);
    setAddCrontabModalMonthOfYearValid(validInput);
    setAddCrontabModalMonthOfYearErrorMsg(validInput ? "" : "Typed input is not a valid month of year field value");
  }

  const handleEditCrontabModalMonthOfYearChanged = (userInput) => {
    // validate that what the user typed in is valid as the month of year field of a cron
    // expression
    let validInput = isValidCronMonthOfYear(userInput);

    setEditCrontabModalMonthOfYear(userInput);
    setEditCrontabModalMonthOfYearValid(validInput);
    setEditCrontabModalMonthOfYearErrorMsg(validInput ? "" : "Typed input is not a valid month of year field value");
  }

  const handleAddCrontabModalDayOfWeekChanged = (userInput) => {
    // validate that what the user typed in is valid as the day of week field of a cron
    // expression
    let validInput = isValidCronDayOfWeek(userInput);

    setAddCrontabModalDayOfWeek(userInput);
    setAddCrontabModalDayOfWeekValid(validInput);
    setAddCrontabModalDayOfWeekErrorMsg(validInput ? "" : "Typed input is not a valid day of week field value");
  }

  const handleEditCrontabModalDayOfWeekChanged = (userInput) => {
    // validate that what the user typed in is valid as the day of week field of a cron
    // expression
    let validInput = isValidCronDayOfWeek(userInput);

    setEditCrontabModalDayOfWeek(userInput);
    setEditCrontabModalDayOfWeekValid(validInput);
    setEditCrontabModalDayOfWeekErrorMsg(validInput ? "" : "Typed input is not a valid day of week field value");
  }

  const handleAddIntervalModalEveryChanged = (userInput) => {
    // validate that what the user typed in is valid
    let validInput = isValidIntervalEveryValue(userInput);

    setAddIntervalModalEvery(userInput);
    setAddIntervalModalEveryValid(validInput);
    setAddIntervalModalEveryErrorMsg(validInput ? "" : "Typed input is not valid number");

    // force user to click validate button
    setAddIntervalModalAddButtonEnabled(false);
  }

  const handleEditIntervalModalEveryChanged = (userInput) => {
    // validate that what the user typed in is valid
    let validInput = isValidIntervalEveryValue(userInput);

    setEditIntervalModalEvery(userInput);
    setEditIntervalModalEveryValid(validInput);
    setEditIntervalModalEveryErrorMsg(validInput ? "" : "Typed input is not valid number");

    // force user to click validate button
    setEditIntervalModalUpdateButtonEnabled(false);
  }

  const handleAddIntervalModalPeriodChanged = (userInput) => {
    // validate that what the user typed in is valid
    let validInput = isValidIntervalPeriodValue(userInput);

    setAddIntervalModalPeriod(userInput);
    setAddIntervalModalPeriodValid(validInput);
    setAddIntervalModalPeriodErrorMsg(validInput ? "" : "Typed input is not valid time unit");

    // force user to click validate button
    setAddIntervalModalAddButtonEnabled(false);
  }

  const handleEditIntervalModalPeriodChanged = (userInput) => {
    // validate that what the user typed in is valid
    let validInput = isValidIntervalPeriodValue(userInput);

    setEditIntervalModalPeriod(userInput);
    setEditIntervalModalPeriodValid(validInput);
    setEditIntervalModalPeriodErrorMsg(validInput ? "" : "Typed input is not valid time unit");

    // force user to click validate button
    setEditIntervalModalUpdateButtonEnabled(false);
  }

  const handleAddIntervalModalValidateButtonClick = () => {
    let shouldEnabledAddButton = true;
    let validationMessage = "Interval is Valid";

    // check the various values in the modal to ensure they have
    // valid values and if they don't, this function will return false.

    // every
    if (!addIntervalModalEveryValid) {
      validationMessage = "Interval is Invalid";
      shouldEnabledAddButton = false;
    }

    // period
    if (!addIntervalModalPeriodValid) {
      validationMessage = "Interval is Invalid";
      shouldEnabledAddButton = false;
    }

    if (shouldEnabledAddButton) {
      // only check usage on backend if the other checks have passed
      intervalIsInUse(intervalUrl,
        keycloak.token,
        null,
        addIntervalModalEvery,
        addIntervalModalPeriod,
        setAddIntervalModalAddButtonEnabled,
        setAddIntervalModalValidationMessage
      )
    }
    else {
      validationMessage = "Interval is Invalid";
      shouldEnabledAddButton = false;
      setAddIntervalModalValidationMessage(validationMessage);
      setAddIntervalModalAddButtonEnabled(shouldEnabledAddButton);
    }
  }


  const handleEditIntervalModalValidateButtonClick = () => {
    let shouldEnabledUpdateButton = true;
    let validationMessage = "Interval is Valid";

    // check the various values in the modal to ensure they have
    // valid values and if they don't, this function will return false.

    // every
    if (!editIntervalModalEveryValid) {
      validationMessage = "Interval is Invalid";
      shouldEnabledUpdateButton = false;
    }

    // period
    if (!editIntervalModalPeriodValid) {
      validationMessage = "Interval is Invalid";
      shouldEnabledUpdateButton = false;
    }

    // todo check for this interval being in use already
    setEditIntervalModalValidationMessage(validationMessage);
    setEditIntervalModalUpdateButtonEnabled(shouldEnabledUpdateButton);
  }

  const jobModalUpdateButtonEnabled = () => {
    let shouldEnabledUpdateButton = true;

    // check the various values in the modal to ensure they have
    // valid values and if they don't, this function will return false.

    // name
    if (!selectedJobNameValid) {
      shouldEnabledUpdateButton = false;
    }

    return shouldEnabledUpdateButton;
  }

  const handleJobModalNameChanged = (userInput) => {
    // validate that what the user typed in is valid
    let validInput = isValidJobNameValue(userInput);
    setSelectedJobName(userInput);
    setSelectedJobNameValid(validInput);
    setJobModalNameErrorMsg(validInput ? "" : "Typed input is not valid name");

    if (!validInput) {
      // no point in checking for name in use if the name wasn't valid
      return;
    }

    // check if the name is already in use by a different job, if so
    // we will mark this as invalid and change the error message to let the user know the name
    jobNameIsInUse(jobUrl, keycloak.token, selectedJobId, userInput.trim(),
      setSelectedJobNameValid, setJobModalNameErrorMsg);
  }

  const handleJobModalTaskSelection = (event) => {
    setSelectedJobTask(event.target.value);
  };

  const handleJobModalEnabledSelection = (val) => {
    setSelectedJobEnabled(val);
  };

  const handleJobModalCronSelection = (val) => {
    setSelectedJobCrontabId(val + '');
    if ("0" !== val) {
      // cron sechedule was selected, so deselect interval
      setSelectedJobIntervalId("0");
    }
  };

  const handleJobModalIntervalSelection = (val) => {
    setSelectedJobIntervalId(val + '');
    if ("0" !== val) {
      // interval sechedule was selected, so deselect crontab
      setSelectedJobCrontabId("0");
    }
  };

  const handleJobModalOneOffSelection = (val) => {
    setSelectedJobOneOff(val);
  };

  const fetchCronExpressionsData = async () => {
    const orderPrefix = (cronExpressionsOrder === 'desc' ? '-' : '');

    await fetch(crontabExpressionUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + cronExpressionsOffset
      + '&page=' + (cronExpressionsPage + 1)
      + '&ordering=' + orderPrefix + cronExpressionsOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setCronExpressionsTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createCronExpressionRowData(entry['id'], entry['minute'], entry['hour'], entry['day_of_week'],
            entry['day_of_month'], entry['month_of_year'], entry['timezone']));
        }

        setCronExpressionsSelected([]);

        setCronExpressionsRows((ceToggle ? [...tmpRows].reverse() : tmpRows));
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setCronSwitch(false);
  };

  const fetchIntervalsData = async () => {
    const orderPrefix = (intervalsOrder === 'desc' ? '-' : '');

    await fetch(intervalUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + intervalsOffset
      + '&page=' + (intervalsPage + 1)
      + '&ordering=' + orderPrefix + intervalsOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setIntervalsTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createIntervalsRowData(entry['id'], entry['every'], entry['period']));
        }

        setIntervalsSelected([]);
        setIntervalsRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setIntervalSwitch(false);
  };

  const fetchJobsData = async () => {
    const orderPrefix = (jobsOrder === 'desc' ? '-' : '');

    await fetch(jobUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + jobsOffset
      + '&page=' + (jobsPage + 1)
      + '&ordering=' + orderPrefix + jobsOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setJobsTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createJobsRowData(entry['id'], entry['name'], entry['task'],
            entry['enabled'], entry['last_run_at'], entry['total_run_count'], entry['date_changed'],
            entry['crontab_id'], entry['interval_id'], entry['one_off'], entry['start_time'],
            entry['expire_seconds']));
        }

        setJobsSelected([]);
        setJobsRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setJobSwitch(false);
  };

  const handleCronSchedulesRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setCronSchedulesRefreshNotificationOpen(false);
  };

  const displayCronExpressionsRefreshStarted = (event) => {
    setCronSchedulesRefreshNotificationOpen(true);
  };

  const handleCronSchedulesRefreshButtonClick = (event) => {
    fetchCronExpressionsData();
    displayCronExpressionsRefreshStarted();
  };

  const handleIntervalsRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIntervalsRefreshNotificationOpen(false);
  };

  const displayIntervalsRefreshStarted = (event) => {
    setIntervalsRefreshNotificationOpen(true);
  };

  const handleIntervalsRefreshButtonClick = (event) => {
    fetchIntervalsData();
    displayIntervalsRefreshStarted();
  };

  const handleJobsRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setJobsRefreshNotificationOpen(false);
  };

  const displayJobsRefreshStarted = (event) => {
    setJobsRefreshNotificationOpen(true);
  };

  const handleJobsRefreshButtonClick = (event) => {
    fetchJobsData();
    displayJobsRefreshStarted();
  };

  const getJobsOrderBy = () => {
    return jobsOrderBy;
  }

  const getJobsOrder = () => {
    return jobsOrder;
  }

  const getIntervalsOrderBy = () => {
    return intervalsOrderBy;
  }

  const getIntervalsOrder = () => {
    return intervalsOrder;
  }

  const getCronExpressionsOrderBy = () => {
    return cronExpressionsOrderBy;
  }

  const getCronExpressionsOrder = () => {
    return cronExpressionsOrder;
  }

  return (
    <Box sx={{ width: '100%' }} id="scheduled-tasks-top-level-box">
      <Snackbar
        open={cronSchedulesRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleCronSchedulesRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {cronSchedulesRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={intervalsRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleIntervalsRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {intervalsRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={jobsRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleJobsRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {jobsRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={fetchErrorMessageOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleFetchErrorClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {fetchErrorMessage}
        </MuiAlert>
      </Snackbar>
      <AddCrontabDialog
        open={addCrontabModalOpen}
        onClose={handleAddCrontabModalClose}
        minute={addCrontabModalMinute}
        minuteSetter={handleAddCrontabModalMinuteChanged}
        crontabModalMinuteValid={addCrontabModalMinuteValid}
        crontabModalMinuteErrorMsg={addCrontabModalMinuteErrorMsg}
        hour={addCrontabModalHour}
        hourSetter={handleAddCrontabModalHourChanged}
        crontabModalHourValid={addCrontabModalHourValid}
        crontabModalHourErrorMsg={addCrontabModalHourErrorMsg}
        dayOfMonth={addCrontabModalDayOfMonth}
        dayOfMonthSetter={handleAddCrontabModalDayOfMonthChanged}
        crontabModalDayOfMonthValid={addCrontabModalDayOfMonthValid}
        crontabModalDayOfMonthErrorMsg={addCrontabModalDayOfMonthErrorMsg}
        monthOfYear={addCrontabModalMonthOfYear}
        monthOfYearSetter={handleAddCrontabModalMonthOfYearChanged}
        crontabModalMonthOfYearValid={addCrontabModalMonthOfYearValid}
        crontabModalMonthOfYearErrorMsg={addCrontabModalMonthOfYearErrorMsg}
        dayOfWeek={addCrontabModalDayOfWeek}
        dayOfWeekSetter={handleAddCrontabModalDayOfWeekChanged}
        crontabModalDayOfWeekValid={addCrontabModalDayOfWeekValid}
        crontabModalDayOfWeekErrorMsg={addCrontabModalDayOfWeekErrorMsg}
        timezone={addCrontabModalTimezone}
        addButtonEnabled={addCrontabModalAddButtonEnabled}
        onAddButtonClick={handleAddCrontabModalAddButtonClick}
        onValidateButtonClick={handleAddCrontabModalValidateButtonClicked}
        validationMessage={addCrontabModalValidationMessage}
      />
      <EditCrontabDialog
        open={editCrontabModalOpen}
        onClose={handleEditCrontabModalClose}
        minute={editCrontabModalMinute}
        minuteSetter={handleEditCrontabModalMinuteChanged}
        crontabModalMinuteValid={editCrontabModalMinuteValid}
        crontabModalMinuteErrorMsg={editCrontabModalMinuteErrorMsg}
        hour={editCrontabModalHour}
        hourSetter={handleEditCrontabModalHourChanged}
        crontabModalHourValid={editCrontabModalHourValid}
        crontabModalHourErrorMsg={editCrontabModalHourErrorMsg}
        dayOfMonth={editCrontabModalDayOfMonth}
        dayOfMonthSetter={handleEditCrontabModalDayOfMonthChanged}
        crontabModalDayOfMonthValid={editCrontabModalDayOfMonthValid}
        crontabModalDayOfMonthErrorMsg={editCrontabModalDayOfMonthErrorMsg}
        monthOfYear={editCrontabModalMonthOfYear}
        monthOfYearSetter={handleEditCrontabModalMonthOfYearChanged}
        crontabModalMonthOfYearValid={editCrontabModalMonthOfYearValid}
        crontabModalMonthOfYearErrorMsg={editCrontabModalMonthOfYearErrorMsg}
        dayOfWeek={editCrontabModalDayOfWeek}
        dayOfWeekSetter={handleEditCrontabModalDayOfWeekChanged}
        crontabModalDayOfWeekValid={editCrontabModalDayOfWeekValid}
        crontabModalDayOfWeekErrorMsg={editCrontabModalDayOfWeekErrorMsg}
        timezone={editCrontabModalTimezone}
        updateButtonEnabled={editCrontabModalUpdateButtonEnabled}
        onUpdateButtonClick={handleEditCrontabModalUpdateButtonClick}
        onValidateButtonClick={handleEditCrontabModalValidateButtonClicked}
        validationMessage={editCrontabModalValidationMessage}
      />
      <AddIntervalDialog
        open={addIntervalModalOpen}
        onClose={handleAddIntervalModalClose}
        every={addIntervalModalEvery}
        everySetter={handleAddIntervalModalEveryChanged}
        intervalModalEveryValid={addIntervalModalEveryValid}
        intervalModalEveryErrorMsg={addIntervalModalEveryErrorMsg}
        period={addIntervalModalPeriod}
        periodSetter={handleAddIntervalModalPeriodChanged}
        intervalModalPeriodValid={addIntervalModalPeriodValid}
        intervalModalPeriodErrorMsg={addIntervalModalPeriodErrorMsg}
        addButtonEnabled={addIntervalModalAddButtonEnabled}
        onAddButtonClick={handleAddIntervalModalAddButtonClick}
        onValidateButtonClick={handleAddIntervalModalValidateButtonClick}
        validationMessage={addIntervalModalValidationMessage}
      />
      <EditIntervalDialog
        open={editIntervalModalOpen}
        onClose={handleEditIntervalModalClose}
        every={editIntervalModalEvery}
        everySetter={handleEditIntervalModalEveryChanged}
        intervalModalEveryValid={editIntervalModalEveryValid}
        intervalModalEveryErrorMsg={editIntervalModalEveryErrorMsg}
        period={editIntervalModalPeriod}
        periodSetter={handleEditIntervalModalPeriodChanged}
        intervalModalPeriodValid={editIntervalModalPeriodValid}
        intervalModalPeriodErrorMsg={editIntervalModalPeriodErrorMsg}
        updateButtonEnabled={editIntervalModalUpdateButtonEnabled}
        onUpdateButtonClick={handleEditIntervalModalUpdateButtonClick}
        onValidateButtonClick={handleEditIntervalModalValidateButtonClick}
        validationMessage={editIntervalModalValidationMessage}
      />
      <JobDialog
        open={jobModalOpen}
        onClose={handleJobModalClose}
        name={selectedJobName}
        nameSetter={handleJobModalNameChanged}
        jobModalNameValid={selectedJobNameValid}
        jobModalNameErrorMsg={jobModalNameErrorMsg}
        task={selectedJobTask}
        taskSetter={handleJobModalTaskSelection}
        enabled={selectedJobEnabled}
        enabledSetter={handleJobModalEnabledSelection}
        lastRun={selectedJobLastRun}
        totalRunCount={selectedJobTotalRunCount}
        dateChanged={selectedJobDateChanged}
        crontabId={selectedJobCrontabId}
        crontabIdSetter={handleJobModalCronSelection}
        intervalId={selectedJobIntervalId}
        intervalIdSetter={handleJobModalIntervalSelection}
        oneOff={selectedJobOneOff}
        oneOffSetter={handleJobModalOneOffSelection}
        updateButtonEnabled={jobModalUpdateButtonEnabled}
        onUpdateButtonClick={handleJobModalUpdateButtonClick}
        crontabOptions={jobModalCrontabOptions}
        intervalOptions={jobModalIntervalOptions}
        fetchCeleryScheduleModalData={fetchCeleryScheduleModalData}
        schedTaskData={schedTaskData}
      />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Schedule Tasks Tab Panel">
          <Tab label="Cron Schedules" {...a11yProps(0)} />
          <Tab label="Intervals" {...a11yProps(1)} />
          <Tab label="Jobs" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <CronSchedulesTab
          addButtonEnabled={addCrontabButtonEnabled}
          addButtonClickListener={handleAddCrontabButtonClick}
          editButtonEnabled={editCrontabButtonEnabled}
          editButtonClickListener={handleEditCrontabButtonClick}
          deleteButtonEnabled={deleteCrontabButtonEnabled}
          deleteButtonClickListener={handleDeleteCrontabButtonClick}
          onSelectRowId={setSelectedCrontabId}
          editButtonToggle={handleToggleEditCrontabButtonVisibility}
          deleteButtonToggle={handleToggleDeleteCrontabButtonVisibility}
          toggleCronData={toggleCronData}
          setCronSwitch={setCronSwitch}
          order={getCronExpressionsOrder}
          orderSetter={setCronExpressionsOrder}
          orderBy={getCronExpressionsOrderBy}
          orderBySetter={setCronExpressionsOrderBy}
          selected={cronExpressionsSelected}
          selectedSetter={setCronExpressionsSelected}
          offset={cronExpressionsOffset}
          offsetSetter={setCronExpressionsOffset}
          page={cronExpressionsPage}
          pageSetter={setCronExpressionsPage}
          rows={cronExpressionsRows}
          total={cronExpressionsTotal}
          rowsPerPage={rowsPerPage}
          fetchData={fetchCronExpressionsData}
          refreshButtonClickHandler={handleCronSchedulesRefreshButtonClick}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <IntervalsTab
          addButtonEnabled={addIntervalButtonEnabled}
          addButtonClickListener={handleAddIntervalButtonClick}
          editButtonEnabled={editIntervalButtonEnabled}
          editButtonClickListener={handleEditIntervalButtonClick}
          deleteButtonEnabled={deleteIntervalButtonEnabled}
          deleteButtonClickListener={handleDeleteIntervalButtonClick}
          onSelectRowId={setSelectedIntervalId}
          editButtonToggle={handleToggleEditIntervalButtonVisibility}
          deleteButtonToggle={handleToggleDeleteIntervalButtonVisibility}
          setIntervalSwitch={setIntervalSwitch}
          order={getIntervalsOrder}
          orderSetter={setIntervalsOrder}
          orderBy={getIntervalsOrderBy}
          orderBySetter={setIntervalsOrderBy}
          selected={intervalsSelected}
          selectedSetter={setIntervalsSelected}
          offset={intervalsOffset}
          offsetSetter={setIntervalsOffset}
          page={intervalsPage}
          pageSetter={setIntervalsPage}
          rows={intervalsRows}
          total={intervalsTotal}
          rowsPerPage={rowsPerPage}
          fetchData={fetchIntervalsData}
          refreshButtonClickHandler={handleIntervalsRefreshButtonClick}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <JobsTab
          addButtonEnabled={addJobButtonEnabled}
          addButtonClickListener={handleAddJobButtonClick}
          editButtonEnabled={editJobButtonEnabled}
          editButtonClickListener={handleEditJobButtonClick}
          deleteButtonEnabled={deleteJobButtonEnabled}
          deleteButtonClickListener={handleDeleteJobButtonClick}
          onSelectRowId={setSelectedJobId}
          editButtonToggle={handleToggleEditJobButtonVisibility}
          deleteButtonToggle={handleToggleDeleteJobButtonVisibility}
          setJobSwitch={setJobSwitch}
          order={getJobsOrder}
          orderSetter={setJobsOrder}
          orderBy={getJobsOrderBy}
          orderBySetter={setJobsOrderBy}
          selected={jobsSelected}
          selectedSetter={setJobsSelected}
          offset={jobsOffset}
          offsetSetter={setJobsOffset}
          page={jobsPage}
          pageSetter={setJobsPage}
          rows={jobsRows}
          total={jobsTotal}
          rowsPerPage={rowsPerPage}
          fetchData={fetchJobsData}
          refreshButtonClickHandler={handleJobsRefreshButtonClick}
        />
      </TabPanel>
    </Box>
  );
}
