import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

export default function AddBlockedAsnDialog(props) {
    const {
        onClose,
        open,
        asn,
        asnSetter,
        asnValid,
        asnErrMessage,
        onCancel,
        onAdd,
        addButtonDisabled
    } = props;

    const handleCancelButtonClick = (event) => {
        onCancel();
    };

    const handleAddButtonClick = (event) => {
        onAdd();
    };

    const handleAsnChange = (event) => {
        asnSetter(event.target.value);
    };

    const handleModalClose = (event) => {
        onClose();
    };

    return (
        <Dialog onClose={handleModalClose} open={open}>
            <DialogTitle>Add Blocked ASN</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <div height="1rem" />
                    <TextField
                        {...(asnValid !== true && { error: undefined })}
                        helperText={asnErrMessage}
                        label="Autonomous System Number"
                        variant="outlined"
                        size="medium"
                        width="480px"
                        value={asn}
                        onChange={handleAsnChange}
                        autoFocus="true"
                        id="asn-blocked-asn-tab-modal-dialog-add-asn-field"
                        style={{marginTop: '15px'}}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="asn-blocked-asn-tab-modal-dialog-add-button"
                            variant="contained"
                            disabled={addButtonDisabled}
                            onClick={handleAddButtonClick}>Add</Button>
                        <Button id="asn-blocked-asn-tab-modal-dialog-cancel-button"
                            variant="contained"
                            onClick={handleCancelButtonClick}>Cancel</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
