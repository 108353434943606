import React from 'react';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

export default function AddScanWhitelistDialog(props) {
    const {
        onClose,
        open,
        subnet,
        subnetSetter,
        subnetValid,
        subnetErrMessage,
        enabled,
        enabledSetter,
        comments,
        commentsSetter,
        start,
        startSetter,
        end,
        endSetter,
        monday,
        mondaySetter,
        tuesday,
        tuesdaySetter,
        wednesday,
        wednesdaySetter,
        thursday,
        thursdaySetter,
        friday,
        fridaySetter,
        saturday,
        saturdaySetter,
        sunday,
        sundaySetter,
        addButtonEnabled,
        addButtonClickListener,
        validateButtonClickListener,
        validationMessage
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleSubnetContentChange = (event) => {
        subnetSetter(event.target.value);
    };

    const handleCommentsContentChange = (event) => {
        commentsSetter(event.target.value);
    };

    const handleEnabledChange = (event) => {
        enabledSetter(event.target.checked);
    };
    const handleMondayChange = (event) => {
        mondaySetter(event.target.checked);
    };

    const handleTuesdayChange = (event) => {
        tuesdaySetter(event.target.checked);
    };

    const handleWednesdayChange = (event) => {
        wednesdaySetter(event.target.checked);
    };

    const handleThursdayChange = (event) => {
        thursdaySetter(event.target.checked);
    };

    const handleFridayChange = (event) => {
        fridaySetter(event.target.checked);
    };

    const handleSaturdayChange = (event) => {
        saturdaySetter(event.target.checked);
    };

    const handleSundayChange = (event) => {
        sundaySetter(event.target.checked);
    };

    const handleAddButtonClicked = (event) => {
        addButtonClickListener();
    };

    const handlValidateButtonClicked = (event) => {
        validateButtonClickListener();
    };

    const handleStartChange = (val) => {
        startSetter(val);
    };

    const handleEndChange = (val) => {
        endSetter(val);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Add New Scan Whitelist</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        {...(subnetValid !== true && { error: undefined })}
                        helperText={subnetErrMessage}
                        label="Subnet"
                        variant="outlined"
                        size="medium"
                        value={subnet}
                        onChange={handleSubnetContentChange}
                        id="scan-whitelist-table-add-modal-dialog-subnet-field"
                        style={{marginTop: '15px'}}
                    />
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={enabled}
                            onChange={handleEnabledChange}
                            id="scan-whitelist-table-add-modal-dialog-enabled-checkbox"
                        />} label="Enabled" />
                    </FormGroup>
                    <TextField
                        label="Comments"
                        variant="outlined"
                        size="medium"
                        value={comments}
                        multiline
                        minRows="5"
                        onChange={handleCommentsContentChange}
                        id="scan-whitelist-table-add-modal-dialog-comments-field"
                    />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            slotProps={{
                                textField: {
                                required: true,
                                id: 'scan-whitelist-table-add-modal-dialog-start-time-picker',
                                },
                                }}
                            // renderInput={(props) => <TextField
                            //    {...props}
                            //    id="scan-whitelist-table-add-modal-dialog-start-time-picker" />}
                            label="Start"
                            value={start}
                            onChange={handleStartChange}
                            ampm={false}
                        />
                        <TimePicker
                            slotProps={{
                                textField: {
                                required: true,
                                id: 'scan-whitelist-table-add-modal-dialog-end-time-picker',
                                },
                                }}
                            // renderInput={(props) => <TextField
                            //    {...props}
                            //    id="scan-whitelist-table-add-modal-dialog-end-time-picker" />}
                            label="End"
                            value={end}
                            onChange={handleEndChange}
                            ampm={false}
                        />
                    </LocalizationProvider>
                    <Stack
                        spacing={2}
                        direction="row"
                    >
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                checked={monday}
                                onChange={handleMondayChange}
                                id="scan-whitelist-table-add-modal-dialog-monday-checkbox"
                            />} label="Monday" />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                checked={tuesday}
                                onChange={handleTuesdayChange}
                                id="scan-whitelist-table-add-modal-dialog-tuesday-checkbox"
                            />} label="Tuesday" />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                checked={wednesday}
                                onChange={handleWednesdayChange}
                                id="scan-whitelist-table-add-modal-dialog-wednesday-checkbox"
                            />} label="Wednesday" />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                checked={thursday}
                                onChange={handleThursdayChange}
                                id="scan-whitelist-table-add-modal-dialog-thursday-checkbox"
                            />} label="Thursday" />
                        </FormGroup>
                    </Stack>
                    <Stack
                        spacing={2}
                        direction="row"
                    >
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                checked={friday}
                                onChange={handleFridayChange}
                                id="scan-whitelist-table-add-modal-dialog-friday-checkbox"
                            />} label="Friday" />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                checked={saturday}
                                onChange={handleSaturdayChange}
                                id="scan-whitelist-table-add-modal-dialog-saturday-checkbox"
                            />} label="Saturday" />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                checked={sunday}
                                onChange={handleSundayChange}
                                id="scan-whitelist-table-add-modal-dialog-sunday-checkbox"
                            />} label="Sunday" />
                        </FormGroup>
                    </Stack>
                    <TextField
                        label="Validation Status"
                        variant="outlined"
                        size="medium"
                        value={validationMessage}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="scan-whitelist-table-add-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="scan-whitelist-table-add-modal-dialog-validate-button"
                            variant="contained"
                            onClick={handlValidateButtonClicked}>Validate</Button>
                        <Button id="scan-whitelist-table-add-modal-dialog-add-button"
                            variant="contained"
                            onClick={handleAddButtonClicked}
                            disabled={!addButtonEnabled}>Add</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}  