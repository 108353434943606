import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    LOGI_ROLE_CONTENT_DOWNLOAD,
    LOGI_ROLE_CONTENT_READ,
    LOGI_ROLE_CONTENT_UPLOAD
} from '../../../common/LogiRoles';

function FileWidget(props) {
    const {
        hasDownload,
        hasUpload,
        uploadFileChooserListener,
        uploadButtonEnabled,
        uploadbuttonClickListener,
        downloadbuttonClickListener,
        checkButtonClickListener
    } = props;

    return (
        <div id="contentupload-file-widget-div" class="contentupload-file-widget-div">
            <div class="contentupload-filter-widget-body-div">
                <Stack id="contentupload-file-widget-control-stack" spacing={2} direction="column">
                    {hasUpload ? (<Stack spacing={2} direction="row">
                        <label for="contentupload-file-widget-upload-input">Select a file:</label>
                        <input
                            id="contentupload-file-widget-upload-input"
                            name="file_uploaded"
                            type="file"
                            onChange={uploadFileChooserListener} />
                        <Button id="contentupload-file-widget-check-button"
                            variant="contained"
                            disabled={!uploadButtonEnabled}
                            onClick={checkButtonClickListener}
                        >Check Content File Changes</Button>
                        <Button id="contentupload-file-widget-upload-button"
                            variant="contained"
                            disabled={!uploadButtonEnabled}
                            onClick={uploadbuttonClickListener}
                        >Upload Content File</Button>

                    </Stack>) : (<p />)}
                    {hasDownload ? (<Stack spacing={2} direction="row">
                        <Button id="contentupload-file-widget-download-button"
                            variant="contained"
                            onClick={downloadbuttonClickListener}
                        >Download Content File</Button>
                    </Stack>) : (<p />)}
                </Stack>
            </div>

        </div>
    );
};

export default function ContentUploadTab(props) {
    const {
        fileUploadListener,
        uploadButtonEnabled,
        uploadButtonEnabledSetter,
        selectedUploadFile,
        selectedUploadFileSetter,
        downloadButtonClickListener,
        fileCheckListener
    } = props;

    const { keycloak } = useKeycloak();
    /* NUmber of milliseconds to show notifications */
    const notificationAutoclose = 2000;

    let hasContentRead = keycloak.hasRealmRole(LOGI_ROLE_CONTENT_READ);
    let hasContentUpload = keycloak.hasRealmRole(LOGI_ROLE_CONTENT_UPLOAD);
    let hasContentDownload = keycloak.hasRealmRole(LOGI_ROLE_CONTENT_DOWNLOAD);

    const [errorMsg, setErrorMsg] = useState("");
    // snackbar state vars
    const [snackState,] = React.useState({
        snackbarVertical: 'bottom',
        snackbarHorizontal: 'center',
    });

    const { snackbarHorizontal, snackbarVertical } = snackState;

    const [errorMsgOpen, setErrorMsgOpen] = useState(false);

    const handleUploadRulesFileChosen = (event) => {
        if (event.target.files) {
            if (event.target.files.length > 0) {
                const selectedFile = event.target.files[0];
                uploadButtonEnabledSetter(false);
                // Checking if the file type is allowed or not
                const allowedTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
                if (!allowedTypes.includes(selectedFile?.type)) {
                    uploadButtonEnabledSetter(false);
                    setErrorMsg("Only .xls or .xlsx File types allowed.");
                    setErrorMsgOpen(true)
                    return;
                }
                else {
                    // the input only allows single selection so we can get the first(0th) element
                    selectedUploadFileSetter(event.target.files[0]);
                    uploadButtonEnabledSetter(true);
                }
            }
            else {
                selectedUploadFileSetter(null);
                uploadButtonEnabledSetter(false);
            }
        }
        else {
            selectedUploadFileSetter(null);
            uploadButtonEnabledSetter(false);
        }
    };

    const handleUploadRulesFileButtonClick = () => {
        // upload the selected file
        const formData = new FormData();
        formData.append("file_uploaded", selectedUploadFile);

        fileUploadListener(formData);
    };

    const handleCheckRulesFileButtonClick = () => {
        // upload the selected file
        const formData = new FormData();
        formData.append("file_uploaded", selectedUploadFile);
        fileCheckListener(formData);
    };


    if (hasContentRead) {
        return (
            <div id="content-upload-table-top-level-div">
                <div id="content-upload-table-button-bar-div">
                    <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Content Upload</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Snackbar
                                open={errorMsgOpen}
                                autoHideDuration={notificationAutoclose}
                                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
                            >
                                <MuiAlert
                                    className="logi-snackbar-notification-message"
                                    severity="info"
                                    variant="filled"
                                    sx={{ width: '100%' }}>
                                    {errorMsg}
                                </MuiAlert>
                            </Snackbar>
                            <Stack spacing={2} direction="column">
                                <FileWidget
                                    hasDownload={hasContentDownload}
                                    hasUpload={hasContentUpload}
                                    uploadFileChooserListener={handleUploadRulesFileChosen}
                                    uploadButtonEnabled={uploadButtonEnabled}
                                    uploadbuttonClickListener={handleUploadRulesFileButtonClick}
                                    downloadbuttonClickListener={downloadButtonClickListener}
                                    checkButtonClickListener={handleCheckRulesFileButtonClick} />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        );
    }
    else {
        return (<div />);
    }
}
