import React from 'react';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function ViewCeleryResultDialog(props) {
    const {
        open,
        onClose,
        selectedTaskId,
        selectedPeriodicTaskName,
        selectedDateDone,
        selectedDateCreated,
        selectedStatus
    } = props;
    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const readOnlyTextFieldHandler = (event) => {
        // do nothing.  Don't allow value to be changed
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Celery Result</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        label="Task ID"
                        variant="outlined"
                        size="medium"
                        value={selectedTaskId}
                        sx={{ width: '280px' }}
                        onChange={readOnlyTextFieldHandler}
                        style={{marginTop: '15px'}}
                    />
                    <TextField
                        label="Job Name"
                        variant="outlined"
                        size="medium"
                        value={selectedPeriodicTaskName}
                        onChange={readOnlyTextFieldHandler}
                    />
                    <TextField
                        label="Created"
                        variant="outlined"
                        size="medium"
                        value={selectedDateCreated}
                        onChange={readOnlyTextFieldHandler}
                    />
                    <TextField
                        label="Completed"
                        variant="outlined"
                        size="medium"
                        value={selectedDateDone}
                        onChange={readOnlyTextFieldHandler}
                    />
                    <TextField
                        label="Status"
                        variant="outlined"
                        size="medium"
                        value={selectedStatus}
                        onChange={readOnlyTextFieldHandler}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="celeryresults-table-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
