import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols
}
    from '../../common/table/TableHelper';
import SingleSelectTableHead from '../../common/table/SingleSelectTableHead';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import {
    LOGI_ROLE_ANTISPOOFING_RULES_ADD,
    LOGI_ROLE_ANTISPOOFING_RULES_DELETE,
    LOGI_ROLE_ANTISPOOFING_RULES_EDIT,
    LOGI_ROLE_ANTISPOOFING_RULES_READ
} from '../../common/LogiRoles';

const headCells = [
    {
        id: 'subnet',
        numeric: false,
        disablePadding: true,
        label: 'Subnet',
        hide: false,
    },
    {
        id: 'comments',
        numeric: false,
        disablePadding: true,
        label: 'Comments',
        hide: false,
    },
    {
        id: 'is_allowed',
        numeric: false,
        disablePadding: true,
        label: 'Is Allowed',
        hide: false,
    },

];

const hiddenCols = populateHiddenCols(headCells);

const getRowCells = (row, labelId, isItemSelected, handleClick) => {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("subnet") ? (<TableCell align="left">{row.subnet}</TableCell>) : ''}
            {!hiddenCols.has("comments") ? (<TableCell align="left">{row.comments}</TableCell>) : ''}
            {!hiddenCols.has("is_allowed") ? (<TableCell align="left">{row.is_allowed ? "✔" : "✘"}</TableCell>) : ''}
        </TableRow>
    )
};

function FilterWidget(props) {
    const { searchTerm,
        onSearchClick,
        onResetClick,
        onSearchTermChange,
        onSearchAntispoofingVersionChange,
        defaultAntispoofingSearchOption,
        searchAntispoofingVersion,
        antispoofingSearchOptions,
    } = props;

    return (
        <form onSubmit={onSearchClick}>
            <div id="antispoof-filter-widget-div" class="antispoof-filter-widget-div">
                {/* <p class="antispoof-filter-widget-title-p">This is the filter widget</p> */}
                <div class="antispoof-filter-widget-body-div">
                    <Stack id="antispoof-filter-widget-control-stack" spacing={2} direction="row">
                        <TextField id="antispoof-filter-widget-search-input"
                            label="Search"
                            variant="outlined"
                            size="medium"
                            value={searchTerm}
                            onChange={onSearchTermChange}
                            sx={{ m: 1, minWidth: 100, maxWidth: 410 }}
                            fullWidth
                        />
                        <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 175 }}>
                            <InputLabel id="antispoofing-filter-widget-ip-filter-select-label">IP Filter</InputLabel>
                            <Select
                                labelId="antispoofing-exception-filter-widget-select-label"
                                id="antispoofing-exception-table-edit-modal-dialog-ip-filter-select"
                                label="IP Filter"
                                onChange={onSearchAntispoofingVersionChange}
                                defaultValue={defaultAntispoofingSearchOption}
                                value={searchAntispoofingVersion}
                                autoWidth
                            >
                                <MenuItem
                                    selected={defaultAntispoofingSearchOption === searchAntispoofingVersion ? true : false}
                                    value={defaultAntispoofingSearchOption}>{defaultAntispoofingSearchOption}</MenuItem>
                                {antispoofingSearchOptions.map((ipVersion) => {
                                    let isSelected = ipVersion === searchAntispoofingVersion;
                                    return (
                                        <MenuItem selected={isSelected} value={ipVersion}>{ipVersion}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <Button id="antispoof-filter-widget-search-button" variant="contained" button type="submit">Search</Button>
                        <Button id="antispoof-filter-widget-reset-button" variant="contained" onClick={onResetClick}>Reset</Button>
                    </Stack>
                </div>
            </div>
        </form>
    );
};

export default function AntispoofTab(props) {
    const {
        handleRowSelected,
        addButtonEnabled,
        addButtonClickHandler,
        editButtonEnabled,
        editButtonClickHandler,
        deleteButtonEnabled,
        deleteButtonClickHandler,
        order,
        orderSetter,
        orderBy,
        orderBySetter,
        selected,
        selectedSetter,
        offset,
        offsetSetter,
        page,
        pageSetter,
        rows,
        total,
        totalSetter,
        searchTerm,
        searchTermSetter,
        rowsPerPage,
        fetchData,
        refreshButtonClickHandler,
        searchAntispoofingVersion,
        antispoofingSearchOptions,
        searchAntispoofingVersionSetter,
        defaultAntispoofingSearchOption
    } = props;

    const { keycloak } = useKeycloak();

    let hasAntispoofingRead = keycloak.hasRealmRole(LOGI_ROLE_ANTISPOOFING_RULES_READ);
    let hasAntispoofingAdd = keycloak.hasRealmRole(LOGI_ROLE_ANTISPOOFING_RULES_ADD);
    let hasAntispoofingEdit = keycloak.hasRealmRole(LOGI_ROLE_ANTISPOOFING_RULES_EDIT);
    let hasAntispoofingDelete = keycloak.hasRealmRole(LOGI_ROLE_ANTISPOOFING_RULES_DELETE);

    useEffect(() => {
        handleResetButtonClick();
        // fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy === property && order === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        fetchData();
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleSearchTermChange = (event) => {
        searchTermSetter(event.target.value);
    };
    const handleSearchButtonClick = (event) => {
        event.preventDefault();
        fetchData();
    };

    function handleResetButtonClick(event) {
        offsetSetter(0);
        pageSetter(0);
        totalSetter(0);
        orderSetter('asc');
        orderBySetter('subnet');
        searchTermSetter('');
        selectedSetter([]);
        searchAntispoofingVersionSetter('Both')
        handleRowSelected(0);
        fetchData();
    }

    const handleChangePage = (event, newPage) => {

        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        selectedSetter([]);

        pageSetter(newPage);
        // fetch the next page's data
        fetchData();
    };

    const handleRowClick = (event, id) => {
        if (selected[0] === id) {
            handleRowSelected(0);
            selectedSetter([]);
        } else {
            let newSelected = [id];
            handleRowSelected(newSelected[0]);
            selectedSetter(newSelected);
        }
    };


    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    const handleDeleteButtonClick = (event) => {
        deleteButtonClickHandler(fetchData);
    };

    const handleSearchAntispoofingVersionChange = (event) => {
        // console.log("Version change: ", event.target.value);
        searchAntispoofingVersionSetter(event.target.value);
    };

    if (hasAntispoofingRead) {
        return (
            <div id="antispoof-table-top-level-div">
                <div id="antispoof-table-button-bar-div">
                    <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Antispoof Search / Filter</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2} direction="column">
                                <Stack spacing={2} direction="row">
                                    <Button
                                        id="antispoof-table-refresh-button"
                                        variant="contained"
                                        onClick={refreshButtonClickHandler}>Refresh</Button>
                                </Stack>
                                <FilterWidget
                                    searchTerm={searchTerm}
                                    onSearchClick={handleSearchButtonClick}
                                    onResetClick={handleResetButtonClick}
                                    onSearchTermChange={handleSearchTermChange}
                                    onSearchAntispoofingVersionChange={handleSearchAntispoofingVersionChange}
                                    defaultAntispoofingSearchOption={defaultAntispoofingSearchOption}
                                    searchAntispoofingVersion={searchAntispoofingVersion}
                                    antispoofingSearchOptions={antispoofingSearchOptions} />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div id="antispoof-table-div">
                    <Stack spacing={2} direction="row">
                        <Box sx={{ width: '100%' }} id="antispoof_table_box">
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    id="antispoof-table"
                                >
                                    <SingleSelectTableHead
                                        numSelected={selected.length}
                                        order={order()}
                                        orderBy={orderBy()}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                        fetchMethod={fetchData}
                                        id="antispoof-table-head"
                                    />
                                    <TableBody id="antispoof-table-body">
                                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                        {rows.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `antispoof-table-checkbox-${index}`;

                                            return (
                                                getRowCells(row, labelId, isItemSelected, handleRowClick)
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    // height: 33 * emptyRows,
                                                    height: 33,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[rowsPerPage]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                labelDisplayedRows={defaultLabelDisplayedRows}
                            />
                            <EnhancedTableToolbar numSelected={selected.length} />
                        </Box>
                        <div class="antispoof-table-buttons-div">
                            <Stack spacing={2} direction="column">
                                {hasAntispoofingAdd === true ? (<Button id="antispoof-table-add-button"
                                    variant="contained"
                                    onClick={addButtonClickHandler}
                                    disabled={!addButtonEnabled}>Add</Button>) : ('')}
                                {hasAntispoofingEdit === true ? (<Button id="antispoof-table-edit-button"
                                    variant="contained"
                                    onClick={editButtonClickHandler}
                                    disabled={!editButtonEnabled}>Edit</Button>) : ('')}
                                {hasAntispoofingDelete === true ? (<Button id="antispoof-table-delete-button"
                                    variant="contained"
                                    onClick={handleDeleteButtonClick}
                                    disabled={!deleteButtonEnabled}>Delete</Button>) : ('')}
                            </Stack>
                        </div>
                    </Stack>
                </div>
            </div>
        );
    }
    else {
        return (<div />);
    }
}
