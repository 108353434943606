import { useRef } from 'react';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import NoCheckBoxPaginatedTableHead from '../../../../common/table/NoCheckBoxPaginatedTableHead';
import {
    populateHiddenCols
}
    from '../../../../common/table/TableHelper';
import { CSVLink } from 'react-csv';


const headCells = [
    {
        id: 'sheet',
        numeric: false,
        disablePadding: true,
        label: 'Sheet',
        hide: false,
    },
    {
        id: 'error',
        numeric: false,
        disablePadding: true,
        label: 'Error',
        hide: false,
    },
];

const hiddenCols = populateHiddenCols(headCells);

function getRowCells(row, labelId) {
    return (
        <TableRow
            hover
            tabIndex={-1}
            key={row.sheet}
        >
            {!hiddenCols.has("sheet") ? (<TableCell component="td" id={labelId} scope="row" padding="5px">{row.sheet}</TableCell>) : ''}
            {!hiddenCols.has("error") ? (<TableCell align="left">{row.error}</TableCell>) : ''}
        </TableRow>
    )
}




export default function ErrorMessageDialog(props) {
    const {
        onClose,
        open,
        onCancel,
        order,
        orderBy,
        rows,
        rowsPerPage
    } = props;

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;
    const csvLink = useRef()

    const handleCancelButtonClick = (event) => {
        onCancel();
    };

    const handleDownloadButtonClick = (event) => {
        csvLink.current.link.click()
    };

    const handleModalClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };


    return (
        <Dialog fullWidth={true} maxWidth={'xl'} style={{ marginTop: '100px' }} scroll={'paper'} onClose={handleModalClose} open={open}>
            <DialogTitle>Error Messages</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={4} direction="column">
                    <div height="3rem" />
                    <div id="content-upload-table-div">
                        <Stack spacing={2} direction="row">
                            <Box sx={{ width: '100%' }} id="content-upload-table-box">
                                <Stack>
                                    <Alert severity="info">File was not uploaded because of the following errors:</Alert>
                                </Stack>
                                <Table
                                    sx={{ width: '100%', maxHeight: '500px' }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    id="content-upload-table"
                                >
                                    <NoCheckBoxPaginatedTableHead
                                        order={order()}
                                        orderBy={orderBy()}
                                        rowCount={rowsPerPage}
                                        headCells={headCells}
                                        id="content-upload-table-head"
                                    />
                                    <TableBody id="content-upload-table-body" style={{ maxHeight: '500px', width: '100%' }}>
                                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                        {rows.map((row, index) => {
                                            const labelId = `content-upload-table-checkbox-${index}`;

                                            return (
                                                getRowCells(row, labelId)
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    // height: 33 * emptyRows,
                                                    height: 33,
                                                    maxHeight: 33,
                                                }}
                                            >
                                                <TableCell colSpan={6} style={{ padding: 50, margin: 50 }} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Stack>
                    </div>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="content-tab-modal-dialog-download-button"
                            variant="contained"
                            onClick={handleDownloadButtonClick}>Download
                        </Button>
                        <CSVLink data={rows} target="_blank" filename={"content-error-file.csv"} className='hidden' ref={csvLink} />
                        <Button id="content-tab-modal-dialog-cancel-button"
                            variant="contained"
                            onClick={handleCancelButtonClick}>Cancel</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
