import React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function EditAnonymousSettingsDialog(props) {
    const { open, onClose,
        isAnonymous,
        isAnonymousVpn, isAnonymousVpnSetter,
        isHostingProvider,
        isPublicProxy,
        isTorExitNode, isTorExitNodeSetter,
        isResidentialProxy, isResidentialProxySetter,
        updateButtonEnabled, updateButtonClickHandler } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleIsAnonymousChanged = (event) => {

        // readonly for now
        handleReadOnlyField(event);
        // isAnonymousSetter(event.target.checked);
    };

    const handleIsAnonymousVpnChanged = (event) => {
        isAnonymousVpnSetter(event.target.checked);
    };

    const handleIsHostingProviderChanged = (event) => {
        // readonly for now
        handleReadOnlyField(event);
        // isHostingProviderSetter(event.target.checked);
    };

    const handleIsPublicProxyChanged = (event) => {
        // readonly for now
        handleReadOnlyField(event);
        // isPublicProxySetter(event.target.checked);
    };

    const handleIsTorExitNodeChanged = (event) => {
        isTorExitNodeSetter(event.target.checked);
    };

    const handleIsResidentialProxyChanged = (event) => {
        isResidentialProxySetter(event.target.checked);
    };

    const handleUpdateButtonClick = (event) => {
        updateButtonClickHandler();
    };

    const handleReadOnlyField = (event) => {
        // do nothing here
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Anonymous Settings</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={isAnonymous}
                            onChange={handleIsAnonymousChanged}
                        />} label="Is Anonymous(Read-Only)" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={isAnonymousVpn}
                            onChange={handleIsAnonymousVpnChanged}
                        />} label="Anonymous VPN" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={isHostingProvider}
                            onChange={handleIsHostingProviderChanged}
                        />} label="Hosting Provider(Read-Only)" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={isPublicProxy}
                            onChange={handleIsPublicProxyChanged}
                        />} label="Public Proxy(Read-Only)" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={isTorExitNode}
                            onChange={handleIsTorExitNodeChanged}
                        />} label="Tor Exit Node" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={isResidentialProxy}
                            onChange={handleIsResidentialProxyChanged}
                        />} label="Residential Proxy" />
                    </FormGroup>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="job-tab-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="job-tab-modal-dialog-update-button"
                            variant="contained"
                            onClick={handleUpdateButtonClick}
                            disabled={!updateButtonEnabled}
                        >Update</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
