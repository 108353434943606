import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

export default function CountrySubnetDialog(props) {
    let { open, onClose,
        selectedCountrySubnetNetwork,
        selectedCountrySubnetCountry,
        selectedCountrySubnetRegisteredCountry,
        selectedCountrySubnetRepresentedCountry
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const readOnlyTextFieldHandler = (event) => {
        // don't do anything with typed input, making this
        // field read-only
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Country Subnet</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        label="Subnet"
                        variant="outlined"
                        size="medium"
                        // width="480px"
                        value={selectedCountrySubnetNetwork}
                        onChange={readOnlyTextFieldHandler}
                        style={{marginTop: '15px'}}
                    />
                    <TextField
                        label="Country"
                        variant="outlined"
                        size="medium"
                        // width="480px"
                        value={selectedCountrySubnetCountry}
                        onChange={readOnlyTextFieldHandler}
                    />
                    <TextField
                        label="Registered Country"
                        variant="outlined"
                        size="medium"
                        // width="480px"
                        value={selectedCountrySubnetRegisteredCountry}
                        onChange={readOnlyTextFieldHandler}
                    />
                    <TextField
                        label="Represented Country"
                        variant="outlined"
                        size="medium"
                        // width="480px"
                        value={selectedCountrySubnetRepresentedCountry}
                        onChange={readOnlyTextFieldHandler}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="geoip-country-subnets-tab-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
