import IPCIDR from "ip-cidr";

/**
 * Check that the input value is a number suitable for being used
 * as a bll confidence threshold
 * 
 * @param {*} inputVal 
 */

export function isValidConfidence(input) {
    let ret = { res: true, msg: "" };

    const min = 60;
    const max = 100;

    if (input === null) {
        // null input is not at all valid
        ret.res = false;
        ret.msg = "Blank values not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.res = false;
        ret.msg = "Blank values not allowed";
        return ret;
    }

    if (isNaN(input)) {
        // not a number, so not valid
        ret.res = false;
        ret.msg = "Input is not a number";
    }
    else {
        // we have a number, validate it's within a reasonable range
        let parsedNum = parseInt(trimmedInput);

        if (parsedNum < min || parsedNum > max) {
            // outside our allowed range
            ret.res = false;
            ret.msg = "Input must be a number in the range " + min + "-" + max;
        }
    }

    return ret;
}

/**
 * Validate that the input is a valid CIDR network block of the ip version
 * specified.
 * 
 * @param {*} ipVersion ip version the input must be a cidr block for
 * @param {*} input 
 */
export function isValidIpVersionCidr(input) {
    console.log("In isValidIpVersion")
    let ret = { res: false, msg: "" };

    if (input === null || input === undefined) {
        // null input is considered "any"
        return ret;
    }

    let trimmedInput = input;
    if (trimmedInput === '') {
        // blank input is considered "any" port
        return ret;
    }

    // use ip-cidr so we can check the input is a cidr block and if so
    // what ip version
    // const isCidr = require("is-cidr");

    let cidrResult;

    try {
        // cidrResult = isCidr.v4(trimmedInput);
        cidrResult = IPCIDR.isValidCIDR(trimmedInput);

        if (cidrResult) {
            // it says it's a valid IPv4 or IPv6 CIDR block, we still need to check some things
            const parsed = new IPCIDR(trimmedInput);
            const address = parsed.address;
            const addressStart = parsed.addressStart;

            const addrBytes = JSON.stringify(address.parsedAddress);
            const addrStartBytes = JSON.stringify(addressStart.parsedAddress);

            // check that the address contains no host bits compared to address start
            // the two should be arrays of the same length and every element should be equal
            if (addrBytes === addrStartBytes) {
                ret.res = true;
                ret.msg = "Looks like a valid IPv4 or IPv6 CIDR block";
                return ret;
            }
            else {
                ret.res = false;
                ret.message = "Not a valid IPv4 or IPv6 CIDR block";
                return ret;
            }
        } else {
            ret.res = false;
            ret.message = "Not a valid IPv4 or IPv6 CIDR block";
            return ret;
        }
    } catch {
        ret.res = false;
        ret.message = "This is not a valid CIDR block."
        return ret;
    }
}
