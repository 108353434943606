
/**
 * Validate that the input is a valid CIDR network block
 * 
 * @param {*} input 
 */
export function isAnyValidCidr(input) {
    let ret = { res: true, msg: "" };

    if (input === null || input === undefined) {
        // null input is considered "any"
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is considered "any" port
        return ret;
    }

    if (trimmedInput === 'any') {
        // any is also valid
        return ret;
    }

    // use isCidr so we can check the input is a cidr block and if so
    // what ip version
    const isCidr = require("is-cidr");

    let cidrResult = isCidr.v4(trimmedInput)
    if (!cidrResult) {
        // the address block entered is not a v6 block

        // try as v6
        cidrResult = isCidr.v6(trimmedInput)
        if (!cidrResult) {
            ret.res = false;
            ret.msg = "The ip block entered is not valid or is not IPv4";
            return ret;
        }
    }

    return ret;
}
