import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

export default function EditCrontabDialog(props) {
    let { onClose,
        open,
        minute,
        minuteSetter,
        crontabModalMinuteValid,
        crontabModalMinuteErrorMsg,
        hour,
        hourSetter,
        crontabModalHourValid,
        crontabModalHourErrorMsg,
        dayOfMonth,
        dayOfMonthSetter,
        crontabModalDayOfMonthValid,
        crontabModalDayOfMonthErrorMsg,
        monthOfYear,
        monthOfYearSetter,
        crontabModalMonthOfYearValid,
        crontabModalMonthOfYearErrorMsg,
        dayOfWeek,
        dayOfWeekSetter,
        crontabModalDayOfWeekValid,
        crontabModalDayOfWeekErrorMsg,
        timezone,
        updateButtonEnabled,
        onValidateButtonClick,
        onUpdateButtonClick,
        validationMessage } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleMinuteContentChange = (event) => {
        minuteSetter(event.target.value);
    };

    const handleHourContentChange = (event) => {
        hourSetter(event.target.value);
    };

    const handleDayOfMonthContentChange = (event) => {
        dayOfMonthSetter(event.target.value);
    };

    const handleMonthOfYearContentChange = (event) => {
        monthOfYearSetter(event.target.value);
    };

    const handleDayOfWeekContentChange = (event) => {
        dayOfWeekSetter(event.target.value);
    };

    const readonlyContentHandler = (event) => {
        // do nothing here we don't want to allow them to change it
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Edit Cron Expression</DialogTitle>
            <div class="modal-dialog-content-div">
                <p>NOTE: All cron schedules are in {timezone}</p>
                <Stack spacing={2} direction="column">
                    <TextField
                        id="edit-crontab-tab-modal-dialog-minute-text-field"
                        {...(crontabModalMinuteValid !== true && { error: undefined })}
                        helperText={crontabModalMinuteErrorMsg}
                        label="Minute"
                        variant="outlined"
                        size="medium"
                        width="480px"
                        value={minute}
                        onChange={handleMinuteContentChange}
                        style={{marginTop: '15px'}}
                    />
                    <TextField
                        id="edit-crontab-tab-modal-dialog-hour-text-field"
                        {...(crontabModalHourValid !== true && { error: undefined })}
                        helperText={crontabModalHourErrorMsg}
                        label="Hour"
                        variant="outlined"
                        size="medium"
                        value={hour}
                        onChange={handleHourContentChange}
                    />
                    <TextField
                        id="edit-crontab-tab-modal-dialog-day-of-month-text-field"
                        {...(crontabModalDayOfMonthValid !== true && { error: undefined })}
                        helperText={crontabModalDayOfMonthErrorMsg}
                        label="Day of Month"
                        variant="outlined"
                        size="medium"
                        value={dayOfMonth}
                        onChange={handleDayOfMonthContentChange}
                    />
                    <TextField
                        id="edit-crontab-tab-modal-dialog-month-of-year-text-field"
                        {...(crontabModalMonthOfYearValid !== true && { error: undefined })}
                        helperText={crontabModalMonthOfYearErrorMsg}
                        label="Month of Year"
                        variant="outlined"
                        size="medium"
                        value={monthOfYear}
                        onChange={handleMonthOfYearContentChange}
                    />
                    <TextField
                        id="edit-crontab-tab-modal-dialog-day-of-week-text-field"
                        {...(crontabModalDayOfWeekValid !== true && { error: undefined })}
                        helperText={crontabModalDayOfWeekErrorMsg}
                        label="Day of Week"
                        variant="outlined"
                        size="medium"
                        value={dayOfWeek}
                        onChange={handleDayOfWeekContentChange}
                    />
                    <TextField
                        id="edit-crontab-tab-modal-dialog-validation-message-text-field"
                        label="Validation Status"
                        variant="outlined"
                        size="medium"
                        value={validationMessage}
                        multiline
                        onChange={readonlyContentHandler}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="edit-crontab-tab-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="edit-crontab-tab-modal-dialog-close-button"
                            variant="contained"
                            onClick={onValidateButtonClick}>Validate</Button>
                        <Button id="edit-crontab-tab-modal-dialog-update-button"
                            variant="contained"
                            onClick={onUpdateButtonClick}
                            disabled={!updateButtonEnabled}
                        >Update</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
