import Box from '@mui/material/Box';
import Navs from '../components/topnav';
import Sidenavs from '../components/sidenav';
import AnonymousComponent from '../components/Anonymous/AnonymousComponent';
import { useKeycloak } from '@react-keycloak/web';
import { LOGI_ROLE_ANON_READ } from '../common/LogiRoles';
import '../components/Anonymous/Anonymous.css';

function AnonymousPage() {
    const { keycloak } = useKeycloak();
    const hasAnonRead = keycloak.hasRealmRole(LOGI_ROLE_ANON_READ);

    if (keycloak.idTokenParsed) {
        return (
            <div className='App'>
                <Navs />
                <table className='mptable'>
                    <tr>
                        <td className='sidenavtd'>
                            <Box sx={{ position: 'sticky', top: 0 }}>
                                <Sidenavs firewallSettingsOpen={true} adminOpen={false}/>
                            </Box>
                        </td>
                        <td class="anon-page-td">
                            {hasAnonRead ? (<Box sx={{ height: `100%` }}>
                                <div class="anon-page-div">
                                    <AnonymousComponent />
                                </div>
                            </Box>) : (<p>You do not have permission to view this page</p>)}
                        </td>
                    </tr>
                </table>
            </div>
        );
    } else {
        window.location.href = '/';
    }
};

export default AnonymousPage
