import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useKeycloak } from '@react-keycloak/web';
import { coalesce } from '../../common/utility/DataUtil';
import AutonomousSystemNumbersTab from './AutonomousSystemNumbersTab';
import BlockedAsnTab from './BlockedAsnTab';
import ExceptionTab from './ExceptionTab';
import ASNAddExceptionsDialog from './modals/AddASNExceptionsDialog';
import { isValidAsnValue } from '../../common/validation/asn/AsnValidator';
import AddBlockedAsnDialog from './modals/AddBlockedAsnDialog';
import ConfirmBulkBlockAsnsDialog from './modals/ConfirmBulkBlockAsnsDialog';
import './AutonomousSystemNumber.css';
import { fetchErrorMessage } from '../mainpage';

const autonomousSystemNumberUrl = '/middleware/api/asn_autosystemnumber/';
const asnV4Url = "/middleware/api/asn_subnets_v4/";
const asnV6Url = "/middleware/api/asn_subnets_v6/";
const blockedAsnUrl = '/middleware/api/asn_blockedautosystemnumber/';
const lastDbUpdateURL = "/middleware/api/bll_blacklotuslabsipaddress/?ppage=1&ppage_size=10&pager=custom2";
const asnAdhocRefreshUrl = '/middleware/api/v3/asn/adhoc_refresh/';
const ripeAdhocRefreshUrl = '/middleware/api/v3/asn/adhoc_ripe_refresh/';
const exceptionsUrl = '/middleware/api/asn_exceptionsubnets/';
const exceptionsIPv4Url = '/middleware/api/asn_exceptionsubnets_v4/';
const exceptionsIPv6Url = '/middleware/api/asn_exceptionsubnets_v6/';

const rowsPerPage = 20;

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

const defaultAsnSearchOption = "Both";

const asnSearchOptions = [
  "IPv4",
  "IPv6"
];

const defaultAsnSearchFilterOption = "IP Address"

const asnSearchFilterOptions = [
  "Organization",
  "ISP",
  "ASN",
  "ASO",
];

const defaultAsnSourceOption = "Any";

const asnSourceOptions = [
  "Both",
  "Maxmind"
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`autonomous-system-number-tabpanel-${index}`}
      aria-labelledby={`autonomous-system-number-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `autonomous-system-number-tab-${index}`,
    'aria-controls': `autonomous-system-number-tabpanel-${index}`,
  };
}

function createAsnSubnetRowData(ip_address, organization, isp, autonomous_system_number, autonomous_system_organization, source) {
  return {
    ip_address,
    organization,
    isp,
    autonomous_system_number,
    autonomous_system_organization,
    source
  };
}

function createBlockedAsnRowData(id, autonomous_system_number) {
  return {
    id,
    autonomous_system_number
  };
}


// needed so that reset button doesn't have to be clicked twice
let freshAsnSubnetSearchTerm = '';
let freshAsnExceptionSearchTerm = '';

// maintains a map of currently selected asn to subnet mappings to support
// the ability to select multiple rows on the asn subnets tab and click
// the block button and block the distinct asn's selected
// Multiple rows can have the same asn which is why we need
// the ability to keep track of which distinct asns are selected
const asnSubnetsTabSelectedAsns = new Map();

export default function AsnComponent() {
  const { keycloak } = useKeycloak();

  const [tabIndex, setTabIndex] = useState(0);

  // snackbar state vars
  const [snackState,] = React.useState({
    snackbarVertical: 'bottom',
    snackbarHorizontal: 'center',
  });

  const { snackbarHorizontal, snackbarVertical } = snackState;

  const [asnRefreshNotificationOpen, setAsnRefreshNotificationOpen] = useState(false);
  const asnRefreshNotificationMessage = "ASN Subnet Data Refreshed";
  const [adhocAsnPollStartedNotificationOpen, setAhocAsnPollStartedNotificationOpen] = useState(false);
  const adhocAsnPollStartedNotificationMessage = "Adhoc ASN/RIPE Fetch Started";
  const [adhocRIPEPollStartedNotificationOpen, setAhocRIPEPollStartedNotificationOpen] = useState(false);
  const adhocRIPEPollStartedNotificationMessage = "Adhoc RIPE ASN Fetch Started";
  const [blockedAsnRefreshNotificationOpen, setBlockedAsnRefreshNotificationOpen] = useState(false);
  const blockedAsnRefreshNotificationMessage = "Blocked ASN Data Refreshed";
  const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
  const handleFetchErrorClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFetchErrorMessageOpen(false);
  };

  // lastdb pull
  const [dbpull, setDbpull] = useState('Last database update not available.')

  // asn tab state vars
  const [asnOrder, setAsnOrder] = useState('asc');
  const [asnOrderBy, setAsnOrderBy] = useState('ip_address');
  const [asnSelectedSubnets, setAsnSelectedSubnets] = useState([]);
  const [asnOffset, setAsnOffset] = useState(0);
  const [asnPage, setAsnPage] = useState(0);
  const [asnRows, setAsnRows] = useState([]);
  const [asnTotal, setAsnTotal] = useState(0);
  const [asnSearchTerm, setAsnSearchTerm] = useState('');
  const [asnFetchRunning, setAsnFetchRunning] = useState(true);
  // the subnets that are currently selected
  // a Set containing the set of distinct asn values
  // contained in the set of subnets selected
  // this may be smaller than the number of selected subnets
  // since multiple subnets could have the same asn
  const [asnSelectedAsns, setAsnSelectedAsns] = useState(new Set());
  const [asnBlockSelectedButtonEnabled, setAsnBlockSelectedButtonEnabled] = useState(false);
  const [searchAsnVersion, setSearchAsnVersion] = useState(defaultAsnSearchOption)
  const [searchFilter, setSearchFilter] = useState(defaultAsnSearchFilterOption)
  const [sourceFilter, setSourceFilter] = useState(defaultAsnSourceOption)
  const [asnSwitch, setAsnSwitch] = useState(false);


  // blocked asn tab state vars
  const [blockedAsnOrder, setBlockedAsnOrder] = useState('asc');
  const [blockedAsnOrderBy, setBlockedAsnOrderBy] = useState('autonomous_system_number');
  const [blockedAsnSelected, setBlockedAsnSelected] = useState([]);
  const [blockedAsnOffset, setBlockedAsnOffset] = useState(0);
  const [blockedAsnPage, setBlockedAsnPage] = useState(0);
  const [blockedAsnRows, setBlockedAsnRows] = useState([]);
  const [blockedAsnTotal, setBlockedAsnTotal] = useState(0);
  const [blockedAsnSwitch, setBlockedAsnSwitch] = useState(false);

  // exception asn tab state vars
  const [selectedExceptionAsnId, setSelectedExceptionAsnId] = useState(0);
  const [selectedExceptionAsnSubnet, setSelectedExceptionAsnSubnet] = useState('');
  const [selectedExceptionAsnCreated, setSelectedExceptionAsnCreated] = useState('');
  const [selectedExceptionId, setSelectedExceptionId] = useState(0);
  const [exceptionAsnOrder, setExceptionAsnOrder] = useState('asc');
  const [exceptionAsnOrderBy, setExceptionAsnOrderBy] = useState('subnet');
  const [exceptionTableData, setExceptionTableData] = useState([]);

  const [asnExceptionsModalOpen, setAsnExceptionsModalOpen] = useState(false);
  const [deleteExceptionsButtonEnabled, setDeleteExceptionsButtonEnabled] = useState(false);
  const [uploadExceptionsButtonEnabled, setUploadExceptionsButtonEnabled] = useState(false);
  const [exceptionAsnOffset, setExceptionAsnOffset] = useState(0);
  const [exceptionAsnPage, setExceptionAsnPage] = useState(0);
  const [exceptionAsnSelected, setExceptionAsnSelected] = useState([]);
  const [exceptionAsnRows, setExceptionAsnRows] = useState([]);
  const [exceptionAsnTotal, setExceptionAsnTotal] = useState(0);
  const [asnExceptionSearchTerm, setAsnExceptionSearchTerm] = useState('');
  const [viewAsnExceptionButtonEnabled, setViewAsnExceptionButtonEnabled] = useState(false);
  const [asnExceptionsSwitch, setAsnExceptionsSwitch] = useState(false);
  const [exceptionAsnRefreshNotificationOpen, setExceptionAsnRefreshNotificationOpen] = useState(false);
  const [searchAsnExceptionVersion, setSearchAsnExceptionVersion] = useState(defaultAsnSearchOption);

  // blocked asn modal state vars
  const [addBlockedAsnModalOpen, setAddBlockedAsnModalOpen] = useState(false);
  const [blockedAsnToAdd, setBlockedAsnToAdd] = useState("");
  const [blockedAsnToAddValid, setBlockedAsnToAddValid] = useState(false);
  const [blockedAsnToAddErrMsg, setBlockedAsnToAddErrMsg] = useState('');
  const [selectedBlockedAsns, setSelectedBlockedAsns] = useState([]);
  const [addBlockedAsnModalAddButtonEnabled, setAddBlockedAsnModalAddButtonEnabled] = useState(false);
  const [addBlockedAsnButtonEnabled,] = useState(true);
  const [deleteBlockedAsnsButtonEnabled, setDeleteBlockedAsnsButtonEnabled] = useState(false);

  // confirm bulk asn block modal state vars
  const [confirmBbulkBlockAsnsModalOpen, setConfirmBulkBlockAsnsModalOpen] = useState(false);

  useEffect(() => {
    fetchAsnSubnetData();
  }, [asnSwitch])

  useEffect(() => {
    fetchBlockedAsnData();
  }, [blockedAsnSwitch])

  const handleAsnExceptionRowSelection = (val) => {
    setSelectedExceptionAsnId(val);
    setSelectedExceptionAsnSubnet("");
    setSelectedExceptionAsnCreated("");
    setSelectedExceptionId(val);
  };

  const getExceptionAsnOrder = () => {
    return exceptionAsnOrder;
  }

  const getExceptionAsnCollectionOrderBy = () => {
    return exceptionAsnOrderBy;
  }

  const handleExceptionsSelected = (id) => {
    setSelectedExceptionId(id);
    if (id !== 0) {
      setDeleteExceptionsButtonEnabled(true);
    }
    else {
      setDeleteExceptionsButtonEnabled(false);
    }
  };

  const handleAsnExceptionsModalClose = () => {
    setAsnExceptionsModalOpen(false);
  }

  const handleDeleteExceptionButtonClick = (refreshCallBack) => {
    deleteException(refreshCallBack);
  };

  const handleToggleViewAsnExceptionButtonVisibility = (val) => {
    setViewAsnExceptionButtonEnabled(val);
  };

  const handleAsnExceptionSearchTermChanged = (val) => {
    setAsnExceptionSearchTerm(val);
    freshAsnExceptionSearchTerm = val;
  };

  const deleteException = (refreshCallBack) => {
    fetch(exceptionsUrl
      + selectedExceptionId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        setDeleteExceptionsButtonEnabled(false);
        refreshCallBack();
      });
  };

  const getFreshAsnExceptionSearchTerm = () => {
    return freshAsnExceptionSearchTerm;
  }

  const getSanitizedAsnExceptionSearchFieldContent = () => {
    let ret = getFreshAsnExceptionSearchTerm();
    return encodeURIComponent(ret);
  }

  function createExceptionAsnRowData(id, subnet, created) {
    return {
      id,
      subnet,
      created
    };
  }

  const handleAsnExceptionVersionTermChanged = (val) => {
    setSearchAsnExceptionVersion(val);
  };

  const fetchExceptionAsnData = () => {
    const sanitizedSearchTerm = getSanitizedAsnExceptionSearchFieldContent();
    const ipFilter = searchAsnExceptionVersion;
    // console.log("IP Filter: ", ipFilter);
    const exceptionOrderPrefix = (exceptionAsnOrder === 'desc' ? '-' : '');
    let asnExceptionsUrl = exceptionsUrl;
    if (ipFilter === 'Both') {
      asnExceptionsUrl = exceptionsUrl;
      // console.log("It's Both.");
    } else if (ipFilter === 'IPv4') {
      asnExceptionsUrl = exceptionsIPv4Url;
      // console.log("It's IPv4");
    } else if (ipFilter === 'IPv6') {
      asnExceptionsUrl = exceptionsIPv6Url;
      // console.log("It's IPv6");
    }
    // console.log("What URL: ", bllExceptionsUrl)

    fetch(asnExceptionsUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + exceptionAsnOffset
      + '&page=' + (exceptionAsnPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + exceptionOrderPrefix + exceptionAsnOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setExceptionAsnTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createExceptionAsnRowData(entry['id'],
            entry['subnet'],
            coalesce(entry['created'], "-")));
        }

        setExceptionAsnSelected([]);
        setExceptionAsnRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setAsnSwitch(false);
  };

  const handleBulkBlockAsnAddModalClose = () => {
    console.log("Close Block Button");
    setConfirmBulkBlockAsnsModalOpen(false);
  };

  const handleBlockedAsnAddModalClose = () => {
    setAddBlockedAsnModalOpen(false);
    setBlockedAsnToAddValid(false);
    setAddBlockedAsnModalAddButtonEnabled(false);
  };

  const handleAsnFetchRunningChange = (val) => {
    setAsnFetchRunning(val);
    // console.log("Set ASN Search to:", asnFetchRunning);
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleAsnSelection = (newSelection, distinctAsns) => {
    setAsnSelectedSubnets(newSelection);
    //    setAsnSelectedAsns(distinctAsns);
    // selection changed, clear the Set and add the new selections
    let newAsnSet = new Set();

    for (const asn of distinctAsns) {
      newAsnSet.add(asn);
    }
    setAsnSelectedAsns(newAsnSet);

    if (distinctAsns.size !== 0) {
      // enable the block button on asn tab
      setAsnBlockSelectedButtonEnabled(true);
    }
    else {
      setAsnBlockSelectedButtonEnabled(false);
    }
  };

  const addBlockedAsn = async (distinctAsn) => {
    let postData = {
      organization: "",
      isp: "",
      autonomous_system_number: distinctAsn,
      autonomous_system_organization: ""
    };
    await fetch(blockedAsnUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchBlockedAsnData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleConfirmBulkBlockAsnsModalClose = () => {
    setConfirmBulkBlockAsnsModalOpen(false);
    setAsnSelectedSubnets([]);
    setAsnSelectedAsns([]);
    fetchBlockedAsnData();

    // on close of the confirm dialog, via confirm or cancel
    // we want to clear the map
    asnSubnetsTabSelectedAsns.clear();
  };

  /**
   * Called when a user has confirmed that they want to block the
   * selected asns via the the ConfirmBlockAsnsDialog modal
   * @param {*} event 
   */
  const handleBlockAsnsConfirmModalBlockButtonClick = (event) => {
    for (const asn of asnSelectedAsns) {
      addBlockedAsn(asn);
    }
    setAsnBlockSelectedButtonEnabled(false);
    handleConfirmBulkBlockAsnsModalClose();
  };

  const handleConfirmBulkBlockAsnsModalCancelButtonClicked = (event) => {
    handleConfirmBulkBlockAsnsModalClose();
  };

  const handleBlockSelectedAsnsButtonClick = (event) => {
    // launch the confirm dialog
    setConfirmBulkBlockAsnsModalOpen(true);
  };

  const handleConfirmBulkBlockAsnsModalConfirmButtonClicked = (event) => {
    handleBlockAsnsConfirmModalBlockButtonClick();
  };

  const handleBlockedAsnsSelection = (newSelection) => {
    setSelectedBlockedAsns(newSelection);
    if (newSelection.length !== 0) {
      // enable the delete button on blocked asns tab
      setDeleteBlockedAsnsButtonEnabled(true);
    }
    else {
      setDeleteBlockedAsnsButtonEnabled(false);
    }
  };

  const handleAddBlockedAsnButtonClick = (event) => {
    setAddBlockedAsnModalOpen(true);
  };

  const displayExceptionAsnRefreshStarted = (event) => {
    setExceptionAsnRefreshNotificationOpen(true);
  };

  const handleExceptionAsnRefreshButtonClick = (event) => {
    fetchExceptionAsnData();
    displayExceptionAsnRefreshStarted();
  };

  const handleAddAsnExceptionsButtonClick = (event) => {
    // fetch modal data
    //fetchBlockedBLLCollectionModalData();
    // display modal
    setAsnExceptionsModalOpen(true);
  };

  const deleteBlockedAsn = async (blockedAsnId, fetchFunctionCallback) => {
    await fetch(blockedAsnUrl
      + blockedAsnId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        // don't do anything here
        fetchFunctionCallback();
      });
  };

  const handleDeleteBlockedAsnsButtonClick = (fetchFunctionCallback) => {
    for (let i = 0; i < selectedBlockedAsns.length; i++) {
      deleteBlockedAsn(selectedBlockedAsns[i], fetchFunctionCallback);
    }
  };

  const handleAsnToAddChanged = (val) => {
    setBlockedAsnToAdd(val);
    // check the value supplied to see if it's valid
    let { valid, msg } = isValidAsnValue(val);

    if (!valid) {
      setBlockedAsnToAddValid(false);
      setBlockedAsnToAddErrMsg(msg);
      setAddBlockedAsnModalAddButtonEnabled(false);
    }
    else {
      setBlockedAsnToAddValid(true);
      setBlockedAsnToAddErrMsg(msg);
      setAddBlockedAsnModalAddButtonEnabled(true);
    }
  };

  const handleAddBlockedAsnModalCancel = () => {
    setAddBlockedAsnModalOpen(false);
  };

  const handleAddBlockedAsnModalAdd = () => {
    // do the stuff needed to add the blocked as
    addBlockedAsn(blockedAsnToAdd);
    setBlockedAsnToAdd('');
    setAddBlockedAsnModalAddButtonEnabled(false);
    setAddBlockedAsnModalOpen(false);
  };

  const getFreshAsnSubnetSearchTerm = () => {
    return freshAsnSubnetSearchTerm;
  };

  const getSantizedAsnSearchFieldContent = () => {
    let ret = getFreshAsnSubnetSearchTerm();
    return encodeURIComponent(ret);
  };

  const handleAsnVersionTermChanged = (val) => {
    setSearchAsnVersion(val);
  };

  const handleAsnSourceChanged = (val) => {
    setSourceFilter(val);
  };

  const handleASNSearchFilterChanged = (val) => {
    setSearchFilter(val);
  };

  const fetchAsnSubnetData = async () => {
    handleAsnFetchRunningChange(false);
    const sanitizedSearchTerm = getSantizedAsnSearchFieldContent();
    const orderPrefix = (asnOrder === 'desc' ? '-' : '');
    const ipFilter = searchAsnVersion;

    let asnUrl = autonomousSystemNumberUrl;

    await fetch(asnUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + asnOffset
      + '&page=' + (asnPage + 1)
      + '&ip_version=' + (ipFilter)
      + '&search_filter=' + (searchFilter)
      + '&source=' + (sourceFilter)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + orderPrefix + asnOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setAsnTotal(respData[1]);
        let data = respData[0]

        let tmpRows = [];
        for (let i = 0; i < data.length; i++) {
          let entry = data[i];
          tmpRows.push(createAsnSubnetRowData(entry['ip_address'],
            entry['organization'],
            entry['isp'],
            entry['autonomous_system_number'],
            entry['autonomous_system_organization'],
            entry['source']));
        }

        setAsnSelectedSubnets([]);
        setAsnRows(tmpRows);

        getLastDbUpdate();
        handleAsnFetchRunningChange(true);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setAsnSwitch(false)
  };

  const fetchBlockedAsnData = async () => {
    const orderPrefix = (blockedAsnOrder === 'desc' ? '-' : '');

    await fetch(blockedAsnUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + blockedAsnOffset
      + '&page=' + (blockedAsnPage + 1)
      + '&ordering=' + orderPrefix + blockedAsnOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setBlockedAsnTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createBlockedAsnRowData(
            entry['id'], entry['autonomous_system_number']));
        }

        setBlockedAsnSelected([]);
        handleBlockedAsnsSelection([]);
        setBlockedAsnRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setBlockedAsnSwitch(false)
  };

  const getLastDbUpdate = async () => {
    await fetch(lastDbUpdateURL, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((response) => {
        let result;
        let ripe_result;
        result = response['last_updated'].find((tk) => tk.startsWith('ASN:'))
        ripe_result = response['last_updated'].find((tk) => tk.startsWith('Ripe ASN:'))
        setDbpull(result + "\n" + ripe_result);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  }

  const handleASNSearchTermChanged = (val) => {
    setAsnSearchTerm(val);
    freshAsnSubnetSearchTerm = val;
  };

  const handleAsnRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAsnRefreshNotificationOpen(false);
  };

  const displayAsnRefreshStarted = (event) => {
    setAsnRefreshNotificationOpen(true);
  };

  const handleAsnRefreshButtonClick = (event) => {
    fetchAsnSubnetData();
    displayAsnRefreshStarted();
  };

  const handleAdhocAsnPollStartedNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAhocAsnPollStartedNotificationOpen(false);
  };

  const displayAhocAsnPollStarted = (event) => {
    setAhocAsnPollStartedNotificationOpen(true);
  };

  const displayAhocRIPEPollStarted = (event) => {
    setAhocRIPEPollStartedNotificationOpen(true);
  };

  const displayBlockedAsnRefreshStarted = (event) => {
    setBlockedAsnRefreshNotificationOpen(true);
  };

  const handleBlockedAsnRefreshButtonClick = (event) => {
    fetchBlockedAsnData();
    displayBlockedAsnRefreshStarted();
  };

  const handleBlockedAsnRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setBlockedAsnRefreshNotificationOpen(false);
  };

  const handleBlockedAsnAdhocRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAhocRIPEPollStartedNotificationOpen(false);
  };

  const handleAdhocAsnPollButtonClick = async () => {
    await fetch(asnAdhocRefreshUrl, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.text();
      })
      .then((response) => {
        displayAhocAsnPollStarted();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleAdhocRIPEAsnPollButtonClick = async () => {
    await fetch(ripeAdhocRefreshUrl, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.text();
      })
      .then((response) => {
        displayAhocRIPEPollStarted();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const getFreshSelectedAsns = () => {
    return asnSelectedAsns;
  };

  const getBlockedAsnOrderBy = () => {
    return blockedAsnOrderBy;
  }

  const getBlockedAsnOrder = () => {
    return blockedAsnOrder;
  }

  const getAsnOrderBy = () => {
    return asnOrderBy;
  }

  const getAsnOrder = () => {
    return asnOrder;
  }

  return (
    <Box sx={{ width: '100%' }} id="autonomous-system-number-top-level-box">
      <Snackbar
        open={asnRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleAsnRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {asnRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={fetchErrorMessageOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleFetchErrorClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {fetchErrorMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={adhocAsnPollStartedNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleAdhocAsnPollStartedNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {adhocAsnPollStartedNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={blockedAsnRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleBlockedAsnRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {blockedAsnRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={adhocRIPEPollStartedNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleBlockedAsnAdhocRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {adhocRIPEPollStartedNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <AddBlockedAsnDialog
        open={addBlockedAsnModalOpen}
        onClose={handleBlockedAsnAddModalClose}
        asn={blockedAsnToAdd}
        asnSetter={handleAsnToAddChanged}
        asnValid={blockedAsnToAddValid}
        asnErrMessage={blockedAsnToAddErrMsg}
        onCancel={handleAddBlockedAsnModalCancel}
        onAdd={handleAddBlockedAsnModalAdd}
        addButtonDisabled={!addBlockedAsnModalAddButtonEnabled}
      />
      <ConfirmBulkBlockAsnsDialog
        open={confirmBbulkBlockAsnsModalOpen}
        asnsToBlock={getFreshSelectedAsns}
        onCancel={handleConfirmBulkBlockAsnsModalCancelButtonClicked}
        onConfirm={handleConfirmBulkBlockAsnsModalConfirmButtonClicked}
        onClose={handleBulkBlockAsnAddModalClose}
      />
      <ASNAddExceptionsDialog
        open={asnExceptionsModalOpen}
        onClose={handleAsnExceptionsModalClose}
        fetchData={fetchExceptionAsnData}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="GeoIP Tab Panel">
          <Tab label="Autonomous System Number Subnets" {...a11yProps(0)} />
          <Tab label="Blocked Autonomous System Numbers" {...a11yProps(1)} />
          <Tab label="Exceptions" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <AutonomousSystemNumbersTab
          asnFetchRunning={asnFetchRunning}
          rowSelectionChanged={handleAsnSelection}
          blockButtonEnabled={asnBlockSelectedButtonEnabled}
          blockButtonClickListener={handleBlockSelectedAsnsButtonClick}
          order={getAsnOrder}
          orderSetter={setAsnOrder}
          setAsnSwitch={setAsnSwitch}
          orderBy={getAsnOrderBy}
          orderBySetter={setAsnOrderBy}
          offset={asnOffset}
          offsetSetter={setAsnOffset}
          page={asnPage}
          pageSetter={setAsnPage}
          rows={asnRows}
          rowSetter={setAsnRows}
          total={asnTotal}
          totalSetter={setAsnTotal}
          selected={asnSelectedSubnets}
          selectedSetter={setAsnSelectedSubnets}
          searchTerm={asnSearchTerm}
          searchTermSetter={handleASNSearchTermChanged}
          fetchData={fetchAsnSubnetData}
          rowsPerPage={rowsPerPage}
          refreshButtonClickHandler={handleAsnRefreshButtonClick}
          dbpull={dbpull}
          adhocPollButtonClickListener={handleAdhocAsnPollButtonClick}
          asnSubnetsTabSelectedAsns={asnSubnetsTabSelectedAsns}
          searchAsnVersion={searchAsnVersion}
          asnSearchOptions={asnSearchOptions}
          sourceOptions={asnSourceOptions}
          defaultAsnSearchOption={defaultAsnSearchOption}
          searchAsnVersionSetter={handleAsnVersionTermChanged}
          sourceFilterSetter={handleAsnSourceChanged}
          sourceFilter={sourceFilter}
          searchFilter={searchFilter}
          searchFilterSetter={setSearchFilter}
          defaultAsnSearchFilterOption={defaultAsnSearchFilterOption}
          defaultAsnSourceOption={defaultAsnSourceOption}
          asnSearchFilterChange={handleASNSearchFilterChanged}
          asnSearchFilterOptions={asnSearchFilterOptions}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <BlockedAsnTab
          addButtonEnabled={addBlockedAsnButtonEnabled}
          addButtonClickListener={handleAddBlockedAsnButtonClick}
          deleteButtonEnabled={deleteBlockedAsnsButtonEnabled}
          deleteButtonClickListener={handleDeleteBlockedAsnsButtonClick}
          rowSelectionChanged={handleBlockedAsnsSelection}
          order={getBlockedAsnOrder}
          orderSetter={setBlockedAsnOrder}
          orderBy={getBlockedAsnOrderBy}
          orderBySetter={setBlockedAsnOrderBy}
          offset={blockedAsnOffset}
          offsetSetter={setBlockedAsnOffset}
          setBlockedAsnSwitch={setBlockedAsnSwitch}
          page={blockedAsnPage}
          pageSetter={setBlockedAsnPage}
          rows={blockedAsnRows}
          total={blockedAsnTotal}
          selected={blockedAsnSelected}
          selectedSetter={setBlockedAsnSelected}
          fetchData={fetchBlockedAsnData}
          fetchUrlRoot={blockedAsnUrl}
          refreshButtonClickHandler={handleBlockedAsnRefreshButtonClick}
          adhocPollButtonClickListener={handleAdhocRIPEAsnPollButtonClick}
          dbpull={dbpull}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <ExceptionTab
          onSelectRowId={handleAsnExceptionRowSelection}
          order={getExceptionAsnOrder}
          orderSetter={setExceptionAsnOrder}
          orderBy={getExceptionAsnCollectionOrderBy}
          orderBySetter={setExceptionAsnOrderBy}
          setAsnExceptionsSwitch={setAsnExceptionsSwitch}
          selected={exceptionAsnSelected}
          selectedSetter={setExceptionAsnSelected}
          offset={exceptionAsnOffset}
          offsetSetter={setExceptionAsnOffset}
          page={exceptionAsnPage}
          pageSetter={setExceptionAsnPage}
          rows={exceptionAsnRows}
          total={exceptionAsnTotal}
          totalSetter={setExceptionAsnTotal}
          rowsPerPage={rowsPerPage}
          refreshButtonClickHandler={handleExceptionAsnRefreshButtonClick}
          handleRowSelected={handleExceptionsSelected}
          fetchData={fetchExceptionAsnData}
          exceptionTableData={exceptionTableData}
          setExceptionTableData={setExceptionTableData}
          addButtonClickListener={handleAddAsnExceptionsButtonClick}
          deleteButtonEnabled={deleteExceptionsButtonEnabled}
          deleteButtonClickHandler={handleDeleteExceptionButtonClick}
          deleteButtonToggle={setDeleteExceptionsButtonEnabled}
          uploadButtonEnabled={uploadExceptionsButtonEnabled}
          uploadButtonEnabledSetter={setUploadExceptionsButtonEnabled}
          viewButtonToggle={handleToggleViewAsnExceptionButtonVisibility}
          searchTerm={asnExceptionSearchTerm}
          searchTermSetter={handleAsnExceptionSearchTermChanged}
          asnSearchOptions={asnSearchOptions}
          defaultAsnSearchOption={defaultAsnSearchOption}
          searchAsnVersion={searchAsnExceptionVersion}
          searchAsnVersionSetter={handleAsnExceptionVersionTermChanged}
        />
      </TabPanel>
    </Box>
  );
}
