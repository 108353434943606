import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    populateHiddenCols
}
    from '../../common/table/TableHelper';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import {
    LOGI_ROLE_SCANNER_MESSAGES_ADD,
    LOGI_ROLE_SCANNER_MESSAGES_DELETE,
    LOGI_ROLE_SCANNER_MESSAGES_EDIT,
    LOGI_ROLE_SCANNER_MESSAGES_READ
} from '../../common/LogiRoles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import SingleSelectTableHead from '../../common/table/SingleSelectTableHead';

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
        hide: true,
    },
    {
        id: 'host_address',
        numeric: false,
        disablePadding: true,
        label: 'Host Address',
        hide: false,
    },
    {
        id: 'active',
        numeric: false,
        disablePadding: true,
        label: 'Active',
        hide: false,
    },
    {
        id: 'sensor_location',
        numeric: false,
        disablePadding: true,
        label: 'IAP Location',
        hide: false,
    },
    {
        id: 'dc_location',
        numeric: false,
        disablePadding: true,
        label: 'ISPSS Location',
        hide: false,
    },
    {
        id: 'received',
        numeric: false,
        disablePadding: true,
        label: 'Received ',
        hide: false,
    },
    {
        id: 'updated',
        numeric: false,
        disablePadding: true,
        label: 'Updated',
        hide: false,
    },
];

const hiddenCols = populateHiddenCols(headCells);

const getRowCells = (row, labelId, isItemSelected, handleClick) => {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("id") ? (<TableCell align="left">{row.id}</TableCell>) : ''}
            {!hiddenCols.has("host_address") ? (<TableCell align="left">{row.host_address}</TableCell>) : ''}
            {!hiddenCols.has("active") ? (<TableCell align="left">{row.active ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("sensor_location") ? (<TableCell align="left">{row.sensor_location}</TableCell>) : ''}
            {!hiddenCols.has("dc_location") ? (<TableCell align="left">{row.dc_location}</TableCell>) : ''}
            {!hiddenCols.has("received") ? (<TableCell align="left">{row.received}</TableCell>) : ''}
            {!hiddenCols.has("updated") ? (<TableCell align="left">{row.updated}</TableCell>) : ''}
        </TableRow>
    )
};

function FilterWidget(props) {
    const {
        searchTerm,
        onSearchTermChange,
        searchIAPLocation,
        onSearchIAPLocationChange,
        onSearchClick,
        onResetClick,
        defaultIAPSearchOption,
        iapLocationOptions,
        searchReceivedFloor,
        searchReceivedFloorSetter,
        searchReceivedCeiling,
        searchReceivedCeilingSetter,
        searchUpdatedFloor,
        searchUpdatedFloorSetter,
        searchUpdatedCeiling,
        searchUpdatedCeilingSetter,
        onSearchScanMessageVersionChange,
        defaultScanMessageSearchOption,
        searchScanMessageVersion,
        scanMessageSearchOptions,
    } = props;


    const enterSearch = (event) => {
        event.preventDefault();
        if (event.key === "Enter") {
            onSearchClick();
        }
    };



    return (
        <div id="scan-messages-filter-widget-div" class="scan-messages-filter-widget-div">
            <div class="scan-messages-filter-widget-body-div">
                <Stack id="scan-messages-filter-widget-control-stack"
                    spacing={2}
                    direction="row"
                    justifyContent="left"
                    alignItems="left">
                    <TextField sx={{ m: 1, minWidth: 100, maxWidth: 410 }}
                        label="Search"
                        variant="outlined"
                        size="medium"
                        value={searchTerm}
                        onChange={onSearchTermChange}
                        onKeyUp={enterSearch}
                        id="scan-messages-filter-widget-search-search-field"
                        fullWidth
                    />
                    <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 175 }}>
                        <InputLabel id="scan-messages-filter-widget-iap-location-select-label">IAP Location</InputLabel>
                        <Select
                            labelId="scan-messages-filter-widget-iap-location-select-label"
                            id="scan-message-table-edit-modal-dialog-iap-location-select"
                            label="IAP Location"
                            onChange={onSearchIAPLocationChange}
                            defaultValue={defaultIAPSearchOption}
                            value={searchIAPLocation}
                            autoWidth
                        >
                            <MenuItem
                                selected={defaultIAPSearchOption === searchIAPLocation ? true : false}
                                value={defaultIAPSearchOption}>{defaultIAPSearchOption}</MenuItem>
                            {iapLocationOptions.map((location) => {
                                let isSelected = location === searchIAPLocation;
                                return (
                                    <MenuItem selected={isSelected} value={location}>{location}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 175 }}>
                        <InputLabel id="scan-message-filter-widget-ip-filter-select-label">IP Filter</InputLabel>
                        <Select
                            labelId="scan-message-filter-widget-ip-filter-select-label"
                            id="scan-message-filter-widget-ip-filter-select"
                            label="IP Filter"
                            onChange={onSearchScanMessageVersionChange}
                            defaultValue={defaultScanMessageSearchOption}
                            value={searchScanMessageVersion}
                            autoWidth
                        >
                            <MenuItem
                                selected={defaultScanMessageSearchOption === searchScanMessageVersion ? true : false}
                                value={defaultScanMessageSearchOption}>{defaultScanMessageSearchOption}</MenuItem>
                            {scanMessageSearchOptions.map((ipVersion) => {
                                let isSelected = ipVersion === searchScanMessageVersion;
                                return (
                                    <MenuItem selected={isSelected} value={ipVersion}>{ipVersion}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="left"
                    alignItems="baseline">
                    <p>From</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            slotProps={{
                                textField: {
                                    required: true,
                                    id: 'scan-messages-filter-widget-search-received-floor-field',
                                },
                            }}
                            // renderInput={(props) => <TextField
                            //    id="scan-messages-filter-widget-search-received-floor-field"
                            //    {...props} sx={{ minWidth: 250, maxWidth: 250 }} />}
                            label="Received Floor"
                            value={searchReceivedFloor}
                            onChange={searchReceivedFloorSetter}
                            ampm={false}
                        />
                    </LocalizationProvider>
                    <p>To</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            slotProps={{
                                textField: {
                                    required: true,
                                    id: 'scan-messages-filter-widget-search-received-ceiling-field',
                                },
                            }}
                            // renderInput={(props) => <TextField
                            //     id="scan-messages-filter-widget-search-received-ceiling-field"
                            //     {...props} sx={{ minWidth: 250, maxWidth: 250 }} />}
                            label="Received Ceiling"
                            value={searchReceivedCeiling}
                            onChange={searchReceivedCeilingSetter}
                            ampm={false}
                        />
                    </LocalizationProvider>
                </Stack>
                <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="left"
                    alignItems="baseline">
                    <p>From</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            slotProps={{
                                textField: {
                                    required: true,
                                    id: 'scan-messages-filter-widget-search-updated-floor-field',
                                },
                            }}
                            // renderInput={(props) => <TextField
                            //     id="scan-messages-filter-widget-search-updated-floor-field"
                            //     {...props} sx={{ minWidth: 250, maxWidth: 250 }} />}
                            label="Updated Floor"
                            value={searchUpdatedFloor}
                            onChange={searchUpdatedFloorSetter}
                            ampm={false}
                        />
                    </LocalizationProvider>
                    <p>To</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            slotProps={{
                                textField: {
                                    required: true,
                                    id: 'scan-messages-filter-widget-search-updated-ceiling-field',
                                },
                            }}
                            // renderInput={(props) => <TextField
                            //     id="scan-messages-filter-widget-search-updated-ceiling-field"
                            //     {...props} sx={{ minWidth: 250, maxWidth: 250 }} />}
                            label="Updated Ceiling"
                            value={searchUpdatedCeiling}
                            onChange={searchUpdatedCeilingSetter}
                            ampm={false}
                        />
                    </LocalizationProvider>
                </Stack>

                <Stack spacing={2} direction="row">
                    <Button id="scan-messages-filter-widget-search-button" variant="contained" onClick={onSearchClick}>Search</Button>
                    <Button id="scan-messages-filter-widget-reset-button" variant="contained" onClick={onResetClick}>Reset</Button>
                </Stack>
            </div>
        </div >
    );
};

export default function ScanMessagesTab(props) {
    const {
        handleRowSelected,
        addButtonEnabled,
        addButtonClickHandler,
        editButtonEnabled,
        editButtonClickHandler,
        deleteButtonEnabled,
        deleteButtonClickHandler,
        order,
        orderSetter,
        orderBy,
        orderBySetter,
        selected,
        selectedSetter,
        offset,
        offsetSetter,
        page,
        pageSetter,
        rows,
        total,
        totalSetter,
        searchTerm,
        searchTermSetter,
        rowsPerPage,
        fetchData,
        searchIAPLocation,
        searchIAPLocationSetter,
        defaultIAPSearchOption,
        iapLocationOptions,
        searchReceivedFloor,
        searchReceivedFloorSetter,
        searchReceivedCeiling,
        searchReceivedCeilingSetter,
        searchUpdatedFloor,
        searchUpdatedFloorSetter,
        searchUpdatedCeiling,
        searchUpdatedCeilingSetter,
        refreshButtonClickHandler,
        searchScanMessageVersionSetter,
        defaultScanMessageSearchOption,
        searchScanMessageVersion,
        scanMessageSearchOptions,
        setScanMessageSwitch,
        scanMessageSwitch
    } = props;

    const { keycloak } = useKeycloak();
    const hasScanMessageRead = keycloak.hasRealmRole(LOGI_ROLE_SCANNER_MESSAGES_READ);
    const hasScanMessageAdd = keycloak.hasRealmRole(LOGI_ROLE_SCANNER_MESSAGES_ADD);
    const hasScanMessageEdit = keycloak.hasRealmRole(LOGI_ROLE_SCANNER_MESSAGES_EDIT);
    const hasScanMessageDelete = keycloak.hasRealmRole(LOGI_ROLE_SCANNER_MESSAGES_DELETE);

    useEffect(() => {
        handleResetButtonClick();
        // fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy === property && order === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        setScanMessageSwitch(true)
        fetchData();
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleSearchTermChange = (event) => {
        searchTermSetter(event.target.value);
    };

    const handleSearchIAPLocationChange = (event) => {
        searchIAPLocationSetter(event.target.value);
    };

    const handleSearchButtonClick = (event) => {
        fetchData();
    };

    function handleResetButtonClick(event) {
        offsetSetter(0);
        pageSetter(0);
        totalSetter(0);
        orderSetter('desc');
        orderBySetter('updated');
        searchScanMessageVersionSetter("Both");
        searchTermSetter('');
        selectedSetter([]);
        handleRowSelected(0);
        searchIAPLocationSetter(defaultIAPSearchOption);
        searchReceivedFloorSetter(null);
        searchReceivedCeilingSetter(null);
        searchUpdatedFloorSetter(null);
        searchUpdatedCeilingSetter(null);
        setScanMessageSwitch(!scanMessageSwitch)
        fetchData();
    }

    const handleChangePage = (event, newPage) => {

        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        selectedSetter([]);
        handleRowSelected(0);
        pageSetter(newPage);
        setScanMessageSwitch(true)
        // fetch the next page's data
        fetchData();
    };

    const handleRowClick = (event, id) => {
        if (selected[0] === id) {
            handleRowSelected(0);
            selectedSetter([])
        } else {
            let newSelected = [id];
            handleRowSelected(id);
            selectedSetter(newSelected);
        }
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    const handleDeleteScanMessageClick = (event) => {
        deleteButtonClickHandler(fetchData);
    };

    const handleSearchScanMessageVersionChange = (event) => {
        // console.log("Version change: ", event.target.value);
        searchScanMessageVersionSetter(event.target.value);
    };

    if (hasScanMessageRead) {
        return (
            <div id="scan-messages-table-top-level-div">
                <div id="scan-messages-table-button-bar-div">
                    <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Scan Messages Filter / Search</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2} direction="column">
                                <Stack spacing={2} direction="row">
                                    <Button
                                        id="scan-messages-table-refresh-button"
                                        variant="contained"
                                        onClick={refreshButtonClickHandler}>Refresh</Button>
                                </Stack>
                                <FilterWidget
                                    searchTerm={searchTerm}
                                    onSearchTermChange={handleSearchTermChange}
                                    searchIAPLocation={searchIAPLocation}
                                    onSearchIAPLocationChange={handleSearchIAPLocationChange}
                                    onSearchClick={handleSearchButtonClick}
                                    onResetClick={handleResetButtonClick}
                                    defaultIAPSearchOption={defaultIAPSearchOption}
                                    iapLocationOptions={iapLocationOptions}
                                    searchReceivedFloor={searchReceivedFloor}
                                    searchReceivedFloorSetter={searchReceivedFloorSetter}
                                    searchReceivedCeiling={searchReceivedCeiling}
                                    searchReceivedCeilingSetter={searchReceivedCeilingSetter}
                                    searchUpdatedFloor={searchUpdatedFloor}
                                    searchUpdatedFloorSetter={searchUpdatedFloorSetter}
                                    searchUpdatedCeiling={searchUpdatedCeiling}
                                    searchUpdatedCeilingSetter={searchUpdatedCeilingSetter}
                                    onSearchScanMessageVersionChange={handleSearchScanMessageVersionChange}
                                    defaultScanMessageSearchOption={defaultScanMessageSearchOption}
                                    searchScanMessageVersion={searchScanMessageVersion}
                                    scanMessageSearchOptions={scanMessageSearchOptions}
                                />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div id="scan-messages-table-div">
                    <Stack spacing={2} direction="row">
                        <Box sx={{ width: '100%' }} id="scan-messages-table-box">
                            <TableContainer>
                                <Table
                                    sx={{ width: '100%' }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    id="scan-messages-table"
                                >
                                    <SingleSelectTableHead
                                        numSelected={selected.length}
                                        order={order()}
                                        orderBy={orderBy()}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                        fetchMethod={fetchData}
                                        id="scan-messages-table-head"
                                    />
                                    <TableBody id="scan-messages-table-body">
                                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                        {rows.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `scan-messages-table-checkbox-${index}`;

                                            return (
                                                getRowCells(row, labelId, isItemSelected, handleRowClick)
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    // height: 33 * emptyRows,
                                                    height: 33,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[rowsPerPage]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                labelDisplayedRows={defaultLabelDisplayedRows}
                            />
                            <EnhancedTableToolbar numSelected={selected.length} />
                        </Box>
                        <div class="scan-messages-table-buttons-div">
                            <Stack spacing={2} direction="column">
                                {hasScanMessageAdd ? (<Button id="scan-messages-table-add-button"
                                    variant="contained"
                                    onClick={addButtonClickHandler}
                                    disabled={!addButtonEnabled}>Add</Button>) : (<p />)}
                                {hasScanMessageEdit ? (<Button id="scan-messages-table-edit-button"
                                    variant="contained"
                                    onClick={editButtonClickHandler}
                                    disabled={!editButtonEnabled}>Edit</Button>) : (<p />)}
                                {hasScanMessageDelete ? (<Button id="scan-messages-table-delete-button"
                                    variant="contained"
                                    onClick={handleDeleteScanMessageClick}
                                    disabled={!deleteButtonEnabled}>Delete</Button>) : (<p />)}
                            </Stack>
                        </div>
                    </Stack>
                </div>
            </div>
        );
    }
    else {
        return (<div />);
    }
}
