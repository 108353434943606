import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols,
}
    from '../../common/table/TableHelper';
import SingleSelectTableHead from '../../common/table/SingleSelectTableHead';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import {
    LOGI_ROLE_BLOCKED_BLL_EDIT,
    LOGI_ROLE_BLOCKED_BLL_READ
} from '../../common/LogiRoles';

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Id',
        hide: true,
    },
    {
        id: 'collection_name',
        numeric: false,
        disablePadding: true,
        label: 'Collection Name',
        hide: false,
    },
    {
        id: 'enabled',
        numeric: false,
        disablePadding: true,
        label: 'Enabled',
        hide: false,
    },
    {
        id: 'confidence',
        numeric: false,
        disablePadding: true,
        label: 'Confidence',
        hide: false,
    },
];

const hiddenCols = populateHiddenCols(headCells);

function getRowCells(row, labelId, isItemSelected, handleClick) {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("id") ? (<TableCell component="td" id={labelId} scope="row" padding="none">{row.id}</TableCell>) : ''}
            {!hiddenCols.has("collection_name") ? (<TableCell align="left">{row.collection_name}</TableCell>) : ''}
            {!hiddenCols.has("enabled") ? (<TableCell align="left">{row.enabled ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("confidence") ? (<TableCell align="left">{row.confidence}</TableCell>) : ''}
        </TableRow>
    )
}

export default function BlockedBllTab(props) {
    const {
        editButtonEnabled,
        editButtonClickListener,
        onSelectRowId,
        editButtonToggle,
        order,
        orderSetter,
        orderBy,
        orderBySetter,
        setBlockedBllSwitch,
        selected,
        selectedSetter,
        offset,
        offsetSetter,
        page,
        pageSetter,
        rows,
        total,
        rowsPerPage,
        fetchData,
        refreshButtonClickHandler
    } = props;
    const { keycloak } = useKeycloak();

    const hasBlockedBllRead = keycloak.hasRealmRole(LOGI_ROLE_BLOCKED_BLL_READ);
    const hasBlockedBllEdit = keycloak.hasRealmRole(LOGI_ROLE_BLOCKED_BLL_EDIT);

    useEffect(() => {
        fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy() === property && order() === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        setBlockedBllSwitch(true);
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleChangePage = (event, newPage) => {

        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        selectedSetter([]);
        pageSetter(newPage);
        setBlockedBllSwitch(true);
        onSelectRowId(0);
    };

    const handleRowClick = (event, id) => {
        if (selected[0] === id) {
            onSelectRowId(0);
            editButtonToggle(false);
            selectedSetter([]);
        } else {
            let newSelected = [id];
            onSelectRowId(id);
            editButtonToggle(true);
            selectedSetter(newSelected);
        }
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    if (hasBlockedBllRead) {
        return (
            <div id="blocked-bll-collections-table-top-level-div">
                <div id="blocked-bll-collections-table-button-bar-div">
                    <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Blocked BLL Refresh</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2} direction="column">
                                <Stack spacing={2} direction="row">
                                    <Button
                                        id="blocked-bll-collections-table-refresh-button"
                                        variant="contained"
                                        onClick={refreshButtonClickHandler}>Refresh</Button>
                                </Stack>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div id="blocked-bll-collections-table-div">
                    <Stack spacing={2} direction="row">
                        <Box sx={{ width: '100%' }} id="blocked-bll-collections-table-box">
                            <TableContainer>
                                <Table
                                    sx={{ width: '100%' }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    id="blocked-bll-collections-table"
                                >
                                    <SingleSelectTableHead
                                        numSelected={selected.length}
                                        order={order()}
                                        orderBy={orderBy()}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                        fetchMethod={fetchData}
                                        id="blocked-bll-collections-table-head"
                                    />
                                    <TableBody id="blocked-bll-collections-table-body">
                                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                        {rows.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `blocked-bll-collections-table-checkbox-${index}`;

                                            return (
                                                getRowCells(row, labelId, isItemSelected, handleRowClick)
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    // height: 33 * emptyRows,
                                                    height: 33,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[rowsPerPage]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                labelDisplayedRows={defaultLabelDisplayedRows}
                            />
                            <EnhancedTableToolbar numSelected={selected.length} />
                        </Box>
                        <div class="blocked-bll-collections-table-buttons-div">
                            {hasBlockedBllEdit ? (<Stack spacing={2} direction="column">
                                <Button id="blocked-bll-collections-table-edit-button"
                                    variant="contained"
                                    onClick={editButtonClickListener}
                                    disabled={!editButtonEnabled}>Edit</Button>
                            </Stack>) : (<p />)}
                        </div>
                    </Stack>
                </div>
            </div>
        );
    }
    else {
        return (<div />)
    }
}
