/**
 * Validate that the input is a number suitable to be used as the every value
 * in an interval, i.e. every n period
 * 
 * @param {*} input 
 */
export function isValidIntervalEveryValue(input) {
    let ret = true;
    const min = 1;
    const max = 3600;

    if (input === null) {
        // null input is not at all valid
        ret = false;
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret = false;
        return ret;
    }

    if (isNaN(input)) {
        // not a number, so not valid
        ret = false;
    }
    else {
        // we have a number, validate it's within a reasonable range
        let parsedNum = parseInt(trimmedInput);

        if (parsedNum < min || parsedNum > max) {
            // outside our allowed range
            ret = false;
        }
    }
    return ret;
}

/**
 * Returns the allowed values that can be used as the period of an interval.
 * 
 * @returns An array of allowed values for the period of an interval.
 */
export function getPossibleIntervalPeriods() {
    let possibleValues = [];
    possibleValues.push("seconds");
    possibleValues.push("minutes");
    possibleValues.push("hours");
    possibleValues.push("days");
    possibleValues.push("weeks");
    possibleValues.push("months");
    possibleValues.push("years");

    return possibleValues;
}

/**
 * Validate that the input is suitable to be used as the period of an interval.  e.g. seconds, minutes, hours, etc
 * 
 * @param {*} input 
 */
export function isValidIntervalPeriodValue(input) {
    let ret = true;

    let possibleValues = getPossibleIntervalPeriods();

    if (input === null) {
        // null input is not at all valid
        ret = false;
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret = false;
        return ret;
    }

    let index = possibleValues.indexOf(trimmedInput);
    if (index === -1) {
        // the supplied value isn't present in possible values
        ret = false;
    }

    return ret;
}

/**
 * Check if the every + period the user wants as an interval is already in use by another interval definition.  If it is, they'll have to choose another every + period combination
 * 
 * @param {*} baseUrl Base url to fetch intervals with
 * @param {*} keycloakToken Our keycloak token to authenticate with
 * @param {*} id If supplied, it means we are editing an existing interval and if a result comes for this id we'll allow it since it's updating the same interval
 * @param {*} every The every value for interval we are checking is in use or not
 * @param {*} period The period value for the interval we are checking is in use or not
 * @param {*} fieldIsValidCallback A function that we'll call with the final result of true or false
 * @param {*} fieldErrorMessageCallback A function that we'll call to set a final status message
 * 
 * @returns true if an existing interval already exists with the values for every and period set to the same values as supplied to this function, false otherwise
 */
export function intervalIsInUse(baseUrl, keycloakToken, id, every, period, fieldIsValidCallback,
    fieldErrorMessageCallback) {

    // build the url
    let fetchUrl = baseUrl
        + "?every=" + every
        + "&period=" + period;

    fetch(fetchUrl, {
        method: 'GET',
        headers: {
            'access-token': keycloakToken
        },
    })
        .then((response) => response.json())
        .then((respData) => {
            let existingId = null
            let inUse = false;

            if (respData.results.length > 0) {
                // should be only one result
                let entry = respData.results[0];
                existingId = entry['id'];
            }

            if (id !== null) {
                // we are editing an existing interval, so if existingId != null but equals
                // the id of the interval we are editing, it's no issue
                if (existingId !== null) {
                    if (existingId !== id) {
                        // another interval has this every + period so we must force the user to pick something else
                        inUse = true;
                    }
                    else {
                        // since this every + period exists on the interval we are editing, we'll not say the every + period is already in use
                        // by another interval
                    }
                }
                else {
                    // there is no interval with this every + period, all good
                }
            }
            else {
                // since id is null here, we are likely adding a new interval that doesn't have an id yet
                // we we are simply interested if any interval already exists with every + period
                if (existingId !== null) {
                    // an existing interval has this every + period
                    inUse = true;
                }
            }

            if (inUse) {
                fieldIsValidCallback(false);
                fieldErrorMessageCallback("The values for every and period are in use by another interval, please pick different values");
            }
            else {
                fieldIsValidCallback(true);
                fieldErrorMessageCallback("Interval is Valid");
            }
        });
}