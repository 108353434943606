import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols
}
    from '../../common/table/TableHelper';
import SingleSelectTableHead from '../../common/table/SingleSelectTableHead';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import {
    LOGI_ROLE_ASN_EXCEPTIONS_READ,
    LOGI_ROLE_ASN_EXCEPTIONS_ADD,
    LOGI_ROLE_ASN_EXCEPTIONS_DELETE
} from '../../common/LogiRoles';



const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
        hide: true,
    },
    {
        id: 'subnet',
        numeric: false,
        disablePadding: true,
        label: 'Subnet',
        hide: false,
    },
    {
        id: 'created',
        numeric: false,
        disablePadding: true,
        label: 'Created',
        hide: false,
    },
];

const hiddenCols = populateHiddenCols(headCells);

function getRowCells(row, labelId, isItemSelected, handleClick) {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("id") ? (<TableCell component="td" id={labelId} scope="row" padding="none">{row.id}</TableCell>) : ''}
            {!hiddenCols.has("subnet") ? (<TableCell align="left">{row.subnet}</TableCell>) : ''}
            {!hiddenCols.has("created") ? (<TableCell align="left">{row.created}</TableCell>) : ''}
        </TableRow>
    )
}

function FilterWidget(props) {
    const { searchTerm, onSearchClick, onResetClick, onSearchTermChange,
        defaultAsnSearchOption, searchAsnVersion, asnSearchOptions, onSearchAsnVersionChange,
    } = props;

    return (
        <form onSubmit={onSearchClick}>
            <div id="asn-exception-filter-widget-div" class="geoip-filter-widget-div">
                <div class="asn-exception-filter-widget-body-div">
                    <Stack spacing={2} direction="column">
                        <Stack id="asn-exception-filter-widget-control-stack" spacing={2} direction="row">
                            <TextField id="asn-exception-filter-widget-search-input"
                                label="Search"
                                variant="outlined"
                                size="medium"
                                value={searchTerm}
                                onChange={onSearchTermChange}
                                sx={{ m: 1, minWidth: 100, maxWidth: 410 }}
                                fullWidth
                            />
                            <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 175 }}>
                                <InputLabel id="asn-exception-filter-widget-iap-location-select-label">IP Filter</InputLabel>
                                <Select
                                    labelId="asn-exception-filter-widget-select-label"
                                    id="asn-exception-table-edit-modal-dialog-iap-location-select"
                                    label="IP Filter"
                                    onChange={onSearchAsnVersionChange}
                                    defaultValue={defaultAsnSearchOption}
                                    value={searchAsnVersion}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultAsnSearchOption === searchAsnVersion ? true : false}
                                        value={defaultAsnSearchOption}>{defaultAsnSearchOption}</MenuItem>
                                    {asnSearchOptions.map((ipVersion) => {
                                        let isSelected = ipVersion === searchAsnVersion;
                                        return (
                                            <MenuItem selected={isSelected} value={ipVersion}>{ipVersion}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack spacing={2} direction="row">
                            <Button id="asn-exception-filter-widget-search-button" variant="contained" button type="submit">Search</Button>
                            <Button id="asn-exception-filter-widget-reset-button" variant="contained" onClick={onResetClick}>Reset</Button>
                        </Stack>
                    </Stack>
                </div>
            </div>
        </form>
    );
};

export default function ExceptionTab(props) {
    const {
        addButtonClickListener,
        asnSearchOptions,
        defaultAsnSearchOption,
        deleteButtonClickHandler,
        deleteButtonEnabled,
        deleteButtonToggle,
        fetchData,
        offset,
        offsetSetter,
        onSelectRowId,
        order,
        orderBy,
        orderBySetter,
        orderSetter,
        page,
        pageSetter,
        refreshButtonClickHandler,
        rows,
        rowsPerPage,
        searchAsnVersion,
        searchAsnVersionSetter,
        searchTerm,
        searchTermSetter,
        setAsnExceptionsSwitch,
        selected,
        selectedSetter,
        total,
        totalSetter,
        viewButtonToggle,
    } = props;

    const { keycloak } = useKeycloak();
    const hasExceptionAdd = keycloak.hasRealmRole(LOGI_ROLE_ASN_EXCEPTIONS_ADD);
    const hasExceptionDelete = keycloak.hasRealmRole(LOGI_ROLE_ASN_EXCEPTIONS_DELETE);
    const hasExceptionRead = keycloak.hasRealmRole(LOGI_ROLE_ASN_EXCEPTIONS_READ);

    const emptyRows = 0;

    useEffect(() => {
        deleteButtonToggle(false);
        fetchData();
    }, [])

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy() === property && order() === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        setAsnExceptionsSwitch(true);
    };

    const handleChangePage = (event, newPage) => {

        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        selectedSetter([]);
        onSelectRowId(0)
        pageSetter(newPage);
        setAsnExceptionsSwitch(true);
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    const handleRowClick = (event, id) => {
        if (selected[0] === id) {
            onSelectRowId(0);
            deleteButtonToggle(false);
            selectedSetter([]);
        } else {
            let newSelected = [id];
            onSelectRowId(id);
            deleteButtonToggle(true);
            selectedSetter(newSelected);
        }
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    const handleDeleteExceptionClick = (event) => {
        deleteButtonClickHandler(fetchData);
    };

    const handleSearchButtonClick = (event) => {
        event.preventDefault();
        fetchData();
    };

    const handleSearchTermChange = (event) => {
        searchTermSetter(event.target.value);
    };

    function handleResetButtonClick(event) {
        offsetSetter(0);
        pageSetter(0);
        totalSetter(0);
        orderSetter('asc');
        orderBySetter('id');
        searchTermSetter('');
        selectedSetter([]);
        viewButtonToggle(false);
        searchAsnVersionSetter(searchAsnVersion);
        fetchData();
    }

    const handleSearchAsnVersionChange = (event) => {
        // console.log("Version change: ", event.target.value);
        searchAsnVersionSetter(event.target.value);
    };

    if (hasExceptionRead) {
        return (
            <div>
                <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Exception Filter / Search</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2} direction="column">
                            <Stack spacing={2} direction="row">
                                <Button
                                    id="asn-exception-table-refresh-button"
                                    variant="contained"
                                    onClick={refreshButtonClickHandler}>Refresh</Button>
                            </Stack>
                            <Stack>
                                <FilterWidget
                                    searchTerm={searchTerm}
                                    onSearchClick={handleSearchButtonClick}
                                    onResetClick={handleResetButtonClick}
                                    onSearchTermChange={handleSearchTermChange}
                                    onSearchAsnVersionChange={handleSearchAsnVersionChange}
                                    defaultAsnSearchOption={defaultAsnSearchOption}
                                    searchAsnVersion={searchAsnVersion}
                                    asnSearchOptions={asnSearchOptions} />
                            </Stack>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
                <Stack spacing={2} direction="row">
                    <Box sx={{ width: '100%' }} id="exceptions-asn-collections-table-box">
                        <TableContainer>
                            <Table
                                sx={{ width: '100%' }}
                                aria-labelledby="tableTitle"
                                size={'small'}
                                id="exceptions-asn-collections-table-head"
                            >
                                <SingleSelectTableHead
                                    numSelected={selected.length}
                                    order={order()}
                                    orderBy={orderBy()}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                    fetchMethod={fetchData}
                                    id="exceptions-asn-collections-table-head"
                                />
                                <TableBody id="exceptions-asn-collections-table-body">
                                    {rows.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `asn-exceptions-table-checkbox-${index}`;

                                        return (
                                            getRowCells(row, labelId, isItemSelected, handleRowClick)
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 33,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            labelDisplayedRows={defaultLabelDisplayedRows}
                        />
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </Box>
                    <Box>
                        <div class="asn-exception-table-buttons-div">
                            <Stack spacing={2} direction="column">
                                {hasExceptionAdd ? (<Button id="asn-exception-table-add-button"
                                    variant="contained"
                                    onClick={addButtonClickListener}
                                >Add</Button>) : (<p />)}
                                {hasExceptionDelete ? (<Button id="asn-exception-table-delete-button"
                                    variant="contained"
                                    onClick={handleDeleteExceptionClick}
                                    disabled={!deleteButtonEnabled}
                                >Delete</Button>) : (<p />)}
                            </Stack>
                        </div>
                    </Box>
                </Stack>
            </div>
        )
    }
}