import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { coalesce } from '../../common/utility/DataUtil'
import { useKeycloak } from '@react-keycloak/web';
import AnonymousSubnetsTab from './AnonymousSubnetsTab';
import AnonymousSettingsTab from './AnonymousSettingsTab';
import EditAnonymousSettingsDialog from './modals/EditAnonymousSettingsDialog';
import AnonymousExceptionTab from './AnonymousExceptionTab';
import AddAnonExceptionsDialog from './modals/AddAnonExceptionsDialog';
import "./Anonymous.css";

import { LOGI_ROLE_ANON_SETTINGS_READ } from '../../common/LogiRoles';
import { fetchErrorMessage } from '../mainpage';

const anonymousSubnetsUrl = '/middleware/api/max_maxmindanonymoussubnet/';

const anonSubnetV4Url = '/middleware/api/maxmind_anon_subnets_v4/';

const anonSubnetV6Url = '/middleware/api/maxmind_anon_subnets_v6/';

const rowsPerPage = 20;

const anonymousSettingsUrl = '/middleware/api/max_maxmindanonymoussetting/';

const anonAdhocRefreshUrl = '/middleware/api/v3/anonymous_adhoc_refresh/';

const lastDbUpdateURL = "/middleware/api/bll_blacklotuslabsipaddress/?ppage=1&ppage_size=10&pager=custom2";

const exceptionsUrl = '/middleware/api/anon_exceptionsubnets/';
const exceptionsIPv4Url = '/middleware/api/anon_exceptionsubnets_v4/';
const exceptionsIPv6Url = '/middleware/api/anon_exceptionsubnets_v6/';

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

const defaultAnonSearchOption = "Both";

const anonSearchOptions = [
    "IPv4",
    "IPv6"
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `anon-tab-${index}`,
        'aria-controls': `anon-tabpanel-${index}`,
    };
}

function createSubnetRowData(id, subnet, is_anonymous, is_anonymous_vpn, is_hosting_provider,
    is_public_proxy, is_tor_exit_node, is_residential_proxy,) {
    return {
        id,
        subnet,
        is_anonymous,
        is_anonymous_vpn,
        is_hosting_provider,
        is_public_proxy,
        is_tor_exit_node,
        is_residential_proxy,
    };
}

function createSettingsRowData(id,
    is_anonymous,
    is_anonymous_vpn,
    is_hosting_provider,
    is_public_proxy,
    is_tor_exit_node,
    is_residential_proxy,) {
    return {
        id,
        is_anonymous,
        is_anonymous_vpn,
        is_hosting_provider,
        is_public_proxy,
        is_tor_exit_node,
        is_residential_proxy,
    };
}

function createExceptionAnonRowData(id, subnet, created) {
    return {
        id,
        subnet,
        created
    };
}

// needed so that reset button doesn't have to be clicked twice
let freshSubnetsSearchTerm = '';
let freshAnonExceptionSearchTerm = '';

export default function AnonymousComponent() {
    const [tabIndex, setTabIndex] = React.useState(0);

    const { keycloak } = useKeycloak();

    const hasAnonSettingsRead = keycloak.hasRealmRole(LOGI_ROLE_ANON_SETTINGS_READ);

    // snackbar state vars
    const [snackState,] = React.useState({
        snackbarVertical: 'bottom',
        snackbarHorizontal: 'center',
    });

    const { snackbarHorizontal, snackbarVertical } = snackState;

    const [anonymousSubnetsRefreshNotificationOpen, setAnonymousSubnetsRefreshNotificationOpen] = useState(false);
    const anonymousSubnetsRefreshNotificationMessage = "Anonymous Subnet Data Refreshed";

    const [adhocAnonymousPollStartedNotificationOpen, setAdhocAnonymousPollStartedNotificationOpen] = useState(false);
    const adhocAnonymousPollStartedNotificationMessage = "Adhoc Anonymous Poll Started";

    const [anonymousSettingsRefreshNotificationOpen, setAnonymousSettingsRefreshNotificationOpen] = useState(false);
    const anonymousSettingsRefreshNotificationMessage = "Anonymous Seetings Data Refreshed";
    const [exceptionAnonRefreshNotificationOpen, setExceptionAnonRefreshNotificationOpen] = useState(false);
    const exceptionAnonRefreshNotificationMessage = "Anonymous Exception Data Refreshed";
    const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
    const handleFetchErrorClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFetchErrorMessageOpen(false);
    };

    // anonymous subnets state vars
    const [subnetsOrder, setSubnetsOrder] = useState('asc');
    const [subnetsOrderBy, setSubnetsOrderBy] = useState('subnet');
    const [subnetsSelected, setSubnetsSelected] = useState([]);
    const [subnetsRows, setSubnetsRows] = useState([]);
    const [subnetsOffset, setSubnetsOffset] = useState(0);
    const [subnetsPage, setSubnetsPage] = useState(0);
    const [subnetsTotal, setSubnetsTotal] = useState(0);
    const [subnetsSearchTerm, setSubnetsSearchTerm] = useState('');
    const [searchAnonVersion, setSearchAnonVersion] = useState(defaultAnonSearchOption)
    const [anonSubnetSwitch, setAnonSubnetSwitch] = useState(false);

    // these will be used by the search widget to allow user to limit results to those
    // with flags that are set. values of true or false will limit results to those with
    // the the flag set to true or false.  a value of any will omit this criteria from
    // the search
    const defaultIsAnonymousVPNOption = "any";
    const [subnetsSearchFlagIsAnonymousVPN, setSubnetsSearchFlagIsAnonymousVPN] = useState(defaultIsAnonymousVPNOption);
    const defaultIsHostingProviderOption = "any";
    const [subnetsSearchFlagIsHostingProvider, setSubnetsSearchFlagIsHostingProvider] = useState(defaultIsHostingProviderOption);
    const defaultIsPublicProxyOption = "any";
    const [subnetsSearchFlagIsPublicProxy, setSubnetsSearchFlagIsPublicProxy] = useState(defaultIsPublicProxyOption);
    const defaultIsTorExitNodeOption = "any";
    const [subnetsSearchFlagIsTorExitNode, setSubnetsSearchFlagIsTorExitNode] = useState(defaultIsTorExitNodeOption);
    const defaultIsResidentialProxyOption = "any";
    const [subnetsSearchFlagIsResidentialProxy, setSubnetsSearchFlagIsResidentialProxy] = useState(defaultIsResidentialProxyOption);

    // Last pull
    const [dbpull, setDbpull] = useState('Last database update not available.')

    // anonymous settings related
    const [settingsSelected, setSettingsSelected] = useState([]);
    const [settingsRows, setSettingsRows] = useState([]);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [editButtonEnabled, setEditButtonEnabled] = useState(false);
    const [selectedSettingsId, setSelectedSettingsId] = useState(0);
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [isAnonymousVpn, setIsAnonymousVpn] = useState(false);
    const [isHostingProvider, setIsHostingProvider] = useState(false);
    const [isPublicProxy, setIsPublicProxy] = useState(false);
    const [isTorExitNode, setIsTorExitNode] = useState(false);
    const [isResidentialProxy, setIsResidentialProxy] = useState(false);
    const [settingsUpdateButtonEnabled, setSettingsUpdateButtonEnabled] = useState(false);
    const [anonSettingSwitch, setAnonSettingSwitch] = useState(false);

    // exceptions anon tab state vars
    const [exceptionAnonOrder, setExceptionAnonOrder] = useState('asc');
    const [exceptionAnonOrderBy, setExceptionAnonOrderBy] = useState('subnet');
    const [exceptionTableData, setExceptionTableData] = useState([]);
    const [anonExceptionsModalOpen, setAnonExceptionsModalOpen] = useState(false);
    const [deleteExceptionsButtonEnabled, setDeleteExceptionsButtonEnabled] = useState(false);
    const [uploadExceptionsButtonEnabled, setUploadExceptionsButtonEnabled] = useState(false);
    const [selectedExceptionId, setSelectedExceptionId] = useState(0);
    const [exceptionAnonOffset, setExceptionAnonOffset] = useState(0);
    const [exceptionAnonPage, setExceptionAnonPage] = useState(0);
    const [exceptionAnonSelected, setExceptionAnonSelected] = useState([]);
    const [exceptionAnonRows, setExceptionAnonRows] = useState([]);
    const [exceptionAnonTotal, setExceptionAnonTotal] = useState(0);
    const [, setSelectedExceptionAnonId] = useState(0);
    const [, setSelectedExceptionAnonSubnet] = useState('');
    const [, setSelectedExceptionAnonCreated] = useState('');
    const [anonExceptionSearchTerm, setAnonExceptionSearchTerm] = useState('');
    const [, setViewAnonExceptionButtonEnabled] = useState(false);
    const [searchAnonExVersion, setSearchAnonExVersion] = useState(defaultAnonSearchOption);
    const [anonExceptionsSwitch, setAnonExceptionsSwitch] = useState(false);

    useEffect(() => {
        fetchSubnetData();
    }, [anonSubnetSwitch])

    useEffect(() => {
        fetchSettingsData();
    }, [anonSettingSwitch])

    useEffect(() => {
        fetchExceptionAnonData();
    }, [anonExceptionsSwitch])

    const handleAnonVersionTermChanged = (val) => {
        setSearchAnonVersion(val);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const fetchSettingsModalData = () => {
        fetch(anonymousSettingsUrl
            + selectedSettingsId + "/", {
            method: 'GET',
            headers: {
                'access-token': keycloak.token
            },
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((respData) => {

                setIsAnonymous(respData['is_anonymous']);
                setIsAnonymousVpn(respData['is_anonymous_vpn']);
                setIsHostingProvider(respData['is_hosting_provider']);
                setIsPublicProxy(respData['is_public_proxy']);
                setIsTorExitNode(respData['is_tor_exit_node']);
                setIsResidentialProxy(respData['is_residential_proxy']);
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    };

    const handleToggleViewAnonManualBlockButtonVisibility = (val) => {
        setViewAnonExceptionButtonEnabled(val);
    };

    const handleEditAnonSettingsButtonClick = () => {
        // fetch the the settings for display in modal
        fetchSettingsModalData();

        // show the modal to allow editing
        setSettingsModalOpen(true);
    };

    const handleSettingsRowSelection = (newSettingsId) => {
        setSelectedSettingsId(newSettingsId);

        if (newSettingsId !== 0) {
            setEditButtonEnabled(true);
        }
        else {
            setEditButtonEnabled(false);
        }
    };

    const handleSettingsModalClose = () => {
        setSettingsModalOpen(false);
    };

    const handleSettingsModalIsAnonymousChanged = (val) => {
        setIsAnonymous(val);
        setSettingsUpdateButtonEnabled(true);
    };

    const handleSettingsModalIsAnonymousVpnChanged = (val) => {
        setIsAnonymousVpn(val);
        setSettingsUpdateButtonEnabled(true);
    };

    const handleSettingsModalIsHostingProvider = (val) => {
        setIsHostingProvider(val);
        setSettingsUpdateButtonEnabled(true);
    };

    const handleSettingsModalIsPublicProxy = (val) => {
        setIsPublicProxy(val);
        setSettingsUpdateButtonEnabled(true);
    };

    const handleSettingsModalIsTorExitNode = (val) => {
        setIsTorExitNode(val);
        setSettingsUpdateButtonEnabled(true);
    };

    const handleSettingsModalIsResidentialProxy = (val) => {
        setIsResidentialProxy(val);
        setSettingsUpdateButtonEnabled(true);
    };

    const getSearchFieldContent = () => {
        return freshSubnetsSearchTerm;
    };

    const getSanitizedSearchFieldContent = () => {
        let ret = getSearchFieldContent();
        return encodeURIComponent(ret);
    };


    const handleAnonExceptionsModalClose = () => {
        setAnonExceptionsModalOpen(false);
    }


    const handleAddAnonExceptionsButtonClick = (event) => {
        setAnonExceptionsModalOpen(true);
    };

    const handleAnonExceptionRowSelection = (val) => {
        setSelectedExceptionAnonId(val);
        setSelectedExceptionAnonSubnet("");
        setSelectedExceptionAnonCreated("");
        setSelectedExceptionId(val);
    };


    const getFreshAnonExceptionSearchTerm = () => {
        return freshAnonExceptionSearchTerm;
    }


    const getSanitizedAnonExceptionSearchFieldContent = () => {
        let ret = getFreshAnonExceptionSearchTerm();
        return encodeURIComponent(ret);
    }

    const handleAnonExceptionVersionTermChanged = (val) => {
        setSearchAnonVersion(val);
    };

    const handleAnonExceptionSearchTermChanged = (val) => {
        setAnonExceptionSearchTerm(val);
        freshAnonExceptionSearchTerm = val;
    };

    const handleExceptionAnonRefreshNotificationClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setExceptionAnonRefreshNotificationOpen(false);
    };

    const displayExceptionAnonRefreshStarted = (event) => {
        setExceptionAnonRefreshNotificationOpen(true);
    };

    const handleExceptionAnonRefreshButtonClick = (event) => {
        fetchExceptionAnonData();
        displayExceptionAnonRefreshStarted();
    };

    const getExceptionAnonOrder = () => {
        return exceptionAnonOrder;
    }

    const getExceptionAnonCollectionOrderBy = () => {
        return exceptionAnonOrderBy;
    }

    const handleExceptionsSelected = (id) => {
        setSelectedExceptionId(id);
        if (id !== 0) {
            setDeleteExceptionsButtonEnabled(true);
        }
        else {
            setDeleteExceptionsButtonEnabled(false);
        }
    };

    const deleteException = (refreshCallBack) => {
        fetch(exceptionsUrl
            + selectedExceptionId + '/', {
            method: 'DELETE',
            headers: {
                'access-token': keycloak.token
            },
        })
            .then((response) => {
                setDeleteExceptionsButtonEnabled(false);
                refreshCallBack();
            });
    };

    const handleDeleteExceptionButtonClick = (refreshCallBack) => {
        deleteException(refreshCallBack);
    };


    const fetchSubnetData = () => {
        // todo, do URLencoding on this to avoid any wonky input
        const sanitizedSearchTerm = getSanitizedSearchFieldContent();
        const orderPrefix = (getSubnetsOrder() === 'desc' ? '-' : '');
        const ipFilter = searchAnonVersion;

        let isAnonymousVPNFlagCriteria = "";
        if (getSubnetsSearchFlagIsAnonymousVPN() !== defaultAnonSearchOption) {
            // the user picked either true or false
            // so we need to add criteria to the url query parms
            isAnonymousVPNFlagCriteria = "&is_anonymous_vpn=" + getSubnetsSearchFlagIsAnonymousVPN();
        }

        let isHostingProviderFlagCriteria = "";
        if (getSubnetsSearchFlagIsHostingProvider() !== defaultIsHostingProviderOption) {
            // the user picked either true or false
            // so we need to add criteria to the url query parms
            isHostingProviderFlagCriteria = "&is_hosting_provider=" + getSubnetsSearchFlagIsHostingProvider();
        }

        let isPublicProxyFlagCriteria = "";
        if (getSubnetsSearchFlagIsPublicProxy() !== defaultIsPublicProxyOption) {
            // the user picked either true or false
            // so we need to add criteria to the url query parms
            isPublicProxyFlagCriteria = "&is_public_proxy=" + getSubnetsSearchFlagIsPublicProxy();
        }

        let isTorExitNodeFlagCriteria = "";
        if (getSubnetsSearchFlagIsTorExitNode() !== defaultIsTorExitNodeOption) {
            // the user picked either true or false
            // so we need to add criteria to the url query parms
            isTorExitNodeFlagCriteria = "&is_tor_exit_node=" + getSubnetsSearchFlagIsTorExitNode();
        }

        let isResidentialProxyFlagCriteria = "";
        if (getSubnetsSearchFlagIsResidentialProxy() !== defaultIsResidentialProxyOption) {
            // the user picked either true or false
            // so we need to add criteria to the url query parms
            isResidentialProxyFlagCriteria = "&is_residential_proxy=" + getSubnetsSearchFlagIsResidentialProxy();
        }

        let anonUrl = anonymousSubnetsUrl;
        if (ipFilter === 'Both') {
            anonUrl = anonymousSubnetsUrl;
        } else if (ipFilter === 'IPv4') {
            anonUrl = anonSubnetV4Url;
        } else if (ipFilter === 'IPv6') {
            anonUrl = anonSubnetV6Url;
        }

        fetch(anonUrl
            + '?limit=' + rowsPerPage
            + '&offset=' + subnetsOffset
            + '&page=' + (subnetsPage + 1)
            + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
            + (isAnonymousVPNFlagCriteria === '' ? '' : isAnonymousVPNFlagCriteria)
            + (isHostingProviderFlagCriteria === '' ? '' : isHostingProviderFlagCriteria)
            + (isPublicProxyFlagCriteria === '' ? '' : isPublicProxyFlagCriteria)
            + (isTorExitNodeFlagCriteria === '' ? '' : isTorExitNodeFlagCriteria)
            + (isResidentialProxyFlagCriteria === '' ? '' : isResidentialProxyFlagCriteria)
            + '&ordering=' + orderPrefix + getSubnetsOrderBy(), {
            method: 'GET',
            headers: {
                'access-token': keycloak.token
            },
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((respData) => {
                setSubnetsTotal(respData.count);

                let tmpRows = [];
                for (let i = 0; i < respData.results.length; i++) {
                    let entry = respData.results[i];
                    tmpRows.push(createSubnetRowData(entry['id'],
                        entry['subnet'],
                        entry['is_anonymous'],
                        entry['is_anonymous_vpn'],
                        entry['is_hosting_provider'],
                        entry['is_public_proxy'],
                        entry['is_tor_exit_node'],
                        entry['is_residential_proxy']));
                }

                setSubnetsSelected([]);
                setSubnetsRows(tmpRows);
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
        getLastDbUpdate();
        setAnonSubnetSwitch(false);
    };

    const fetchSettingsData = async () => {
        await fetch(anonymousSettingsUrl, {
            method: 'GET',
            headers: {
                'access-token': keycloak.token
            },
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((respData) => {
                let tmpRows = [];
                for (let i = 0; i < respData.results.length; i++) {
                    let entry = respData.results[i];
                    tmpRows.push(createSettingsRowData(entry['id'],
                        entry['is_anonymous'],
                        entry['is_anonymous_vpn'],
                        entry['is_hosting_provider'],
                        entry['is_public_proxy'],
                        entry['is_tor_exit_node'],
                        entry['is_residential_proxy']));
                }

                setSettingsRows(tmpRows);
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
        setAnonSettingSwitch(false)
    };

    const fetchExceptionAnonData = () => {
        const sanitizedSearchTerm = getSanitizedAnonExceptionSearchFieldContent();
        const ipFilter = searchAnonVersion;
        // console.log("IP Filter: ", ipFilter);
        const exceptionOrderPrefix = (exceptionAnonOrder === 'desc' ? '-' : '');
        let AnonExceptionsUrl = exceptionsUrl;
        if (ipFilter === 'Both') {
            AnonExceptionsUrl = exceptionsUrl;
            // console.log("It's Both.");
        } else if (ipFilter === 'IPv4') {
            AnonExceptionsUrl = exceptionsIPv4Url;
            // console.log("It's IPv4");
        } else if (ipFilter === 'IPv6') {
            AnonExceptionsUrl = exceptionsIPv6Url;
            // console.log("It's IPv6");
        }
        // console.log("What URL: ", AnonExceptionsUrl)

        fetch(AnonExceptionsUrl
            + '?limit=' + rowsPerPage
            + '&offset=' + exceptionAnonOffset
            + '&page=' + (exceptionAnonPage + 1)
            + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
            + '&ordering=' + exceptionOrderPrefix + exceptionAnonOrderBy, {
            method: 'GET',
            headers: {
                'access-token': keycloak.token
            },
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((respData) => {
                setExceptionAnonTotal(respData.count);

                let tmpRows = [];
                for (let i = 0; i < respData.results.length; i++) {
                    let entry = respData.results[i];
                    tmpRows.push(createExceptionAnonRowData(entry['id'],
                        entry['subnet'],
                        coalesce(entry['created'], "-")));
                }

                setExceptionAnonSelected([]);
                setExceptionAnonRows(tmpRows);
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    };


    const updateAnonymousSettings = async () => {
        let patchData = {
            id: selectedSettingsId,
            is_anonymous: isAnonymous,
            is_anonymous_vpn: isAnonymousVpn,
            is_hosting_provider: isHostingProvider,
            is_public_proxy: isPublicProxy,
            is_tor_exit_node: isTorExitNode,
            is_residential_proxy: isResidentialProxy,
        };
        await fetch(anonymousSettingsUrl
            + selectedSettingsId + '/', {
            method: 'PATCH',
            headers: {
                'access-token': keycloak.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(patchData)
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((respData) => {
                setSelectedSettingsId(respData['id']);
                setIsAnonymous(respData['is_anonymous']);
                setIsAnonymousVpn(respData['is_anonymous_vpn']);
                setIsHostingProvider(respData['is_hosting_provider']);
                setIsPublicProxy(respData['is_public_proxy']);
                setIsTorExitNode(respData['is_tor_exit_node']);
                setIsResidentialProxy(respData['is_residential_proxy']);
                fetchSettingsData();
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    };

    const handleSettingsModalUpdateButtonClick = () => {
        // do stuff to update the settings
        updateAnonymousSettings();
        setSettingsModalOpen(false);
    };

    const handleSubnetsSearchTermChanged = (val) => {
        setSubnetsSearchTerm(val);
        freshSubnetsSearchTerm = val;
    };

    const handleAnonymousSubnetsRefreshNotificationClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAnonymousSubnetsRefreshNotificationOpen(false);
    };

    const displayAnonymousRefreshStarted = (event) => {
        setAnonymousSubnetsRefreshNotificationOpen(true);
    };

    const handleAnonymousRefreshButtonClick = (event) => {
        fetchSubnetData();
        displayAnonymousRefreshStarted();
    };

    const handleAdhocAnonymousPollStartedNotificationClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAdhocAnonymousPollStartedNotificationOpen(false);
    };

    const displayAdhocAnonymousPollStarted = (event) => {
        setAdhocAnonymousPollStartedNotificationOpen(true);
    };

    const handleAdhocAnonymousPollButtonClick = async () => {
        await fetch(anonAdhocRefreshUrl, {
            method: 'GET',
            headers: {
                'access-token': keycloak.token
            }
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.text();
        })
            .then((response) => {
                displayAdhocAnonymousPollStarted();
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    }

    const handleAnonymousSettingsRefreshNotificationClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAnonymousSettingsRefreshNotificationOpen(false);
    };

    const displayAnonymousSettingsRefreshStarted = (event) => {
        setAnonymousSettingsRefreshNotificationOpen(true);
    };

    const handleAnonymousSettingsRefreshButtonClick = (event) => {
        fetchSettingsData();
        displayAnonymousSettingsRefreshStarted();
    };

    const getLastDbUpdate = async () => {
        await fetch(lastDbUpdateURL, {
            method: 'GET',
            headers: {
                'access-token': keycloak.token
            }
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((response) => {
                let result;
                result = response['last_updated'].find((tk) => tk.includes('Anonymous:'))
                setDbpull(result);
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    };

    const getSubnetsOrderBy = () => {
        return subnetsOrderBy;
    };

    const getSubnetsOrder = () => {
        return subnetsOrder;
    };

    const getSubnetsSearchFlagIsTorExitNode = () => {
        return subnetsSearchFlagIsTorExitNode;
    };

    const getSubnetsSearchFlagIsResidentialProxy = () => {
        return subnetsSearchFlagIsResidentialProxy;
    };

    const getSubnetsSearchFlagIsAnonymousVPN = () => {
        return subnetsSearchFlagIsAnonymousVPN;
    };

    const getSubnetsSearchFlagIsHostingProvider = () => {
        return subnetsSearchFlagIsHostingProvider;
    };

    const getSubnetsSearchFlagIsPublicProxy = () => {
        return subnetsSearchFlagIsPublicProxy;
    };

    return (
        <Box sx={{ width: '100%' }} id="anon-top-level-box">
            <Snackbar
                open={anonymousSubnetsRefreshNotificationOpen}
                autoHideDuration={notificationAutoclose}
                onClose={handleAnonymousSubnetsRefreshNotificationClosed}
                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
            >
                <MuiAlert
                    className="logi-snackbar-notification-message"
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {anonymousSubnetsRefreshNotificationMessage}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={adhocAnonymousPollStartedNotificationOpen}
                autoHideDuration={notificationAutoclose}
                onClose={handleAdhocAnonymousPollStartedNotificationClosed}
                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
            >
                <MuiAlert
                    className="logi-snackbar-notification-message"
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {adhocAnonymousPollStartedNotificationMessage}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={anonymousSettingsRefreshNotificationOpen}
                autoHideDuration={notificationAutoclose}
                onClose={handleAnonymousSettingsRefreshNotificationClosed}
                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
            >
                <MuiAlert
                    className="logi-snackbar-notification-message"
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {anonymousSettingsRefreshNotificationMessage}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={exceptionAnonRefreshNotificationOpen}
                autoHideDuration={notificationAutoclose}
                onClose={handleExceptionAnonRefreshNotificationClosed}
                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
            >
                <MuiAlert
                    className="logi-snackbar-notification-message"
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {exceptionAnonRefreshNotificationMessage}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={fetchErrorMessageOpen}
                autoHideDuration={notificationAutoclose}
                onClose={handleFetchErrorClosed}
                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
            >
                <MuiAlert
                    className="logi-snackbar-notification-message"
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {fetchErrorMessage}
                </MuiAlert>
            </Snackbar>
            <EditAnonymousSettingsDialog
                open={settingsModalOpen}
                onClose={handleSettingsModalClose}
                isAnonymous={isAnonymous}
                isAnonymousSetter={handleSettingsModalIsAnonymousChanged}
                isAnonymousVpn={isAnonymousVpn}
                isAnonymousVpnSetter={handleSettingsModalIsAnonymousVpnChanged}
                isHostingProvider={isHostingProvider}
                isHostingProviderSetter={handleSettingsModalIsHostingProvider}
                isPublicProxy={isPublicProxy}
                isPublicProxySetter={handleSettingsModalIsPublicProxy}
                isTorExitNode={isTorExitNode}
                isTorExitNodeSetter={handleSettingsModalIsTorExitNode}
                isResidentialProxy={isResidentialProxy}
                isResidentialProxySetter={handleSettingsModalIsResidentialProxy}
                updateButtonEnabled={settingsUpdateButtonEnabled}
                updateButtonClickHandler={handleSettingsModalUpdateButtonClick}
            />
            <AddAnonExceptionsDialog
                open={anonExceptionsModalOpen}
                onClose={handleAnonExceptionsModalClose}
                fetchData={fetchExceptionAnonData}
            />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Anonymous Panel">
                    <Tab label="Anonymous Subnets" {...a11yProps(0)} />
                    <Tab label="Anonymous Exceptions" {...a11yProps(1)} />
                    {hasAnonSettingsRead ? <Tab label="Anonymous Settings" {...a11yProps(2)} /> : ''}
                </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={0}>
                <AnonymousSubnetsTab
                    order={getSubnetsOrder}
                    orderSetter={setSubnetsOrder}
                    orderBy={getSubnetsOrderBy}
                    orderBySetter={setSubnetsOrderBy}
                    selected={subnetsSelected}
                    selectedSetter={setSubnetsSelected}
                    offset={subnetsOffset}
                    offsetSetter={setSubnetsOffset}
                    setAnonSubnetSwitch={setAnonSubnetSwitch}
                    page={subnetsPage}
                    pageSetter={setSubnetsPage}
                    rows={subnetsRows}
                    total={subnetsTotal}
                    totalSetter={setSubnetsTotal}
                    searchTerm={subnetsSearchTerm}
                    searchTermSetter={handleSubnetsSearchTermChanged}
                    fetchData={fetchSubnetData}
                    rowsPerPage={rowsPerPage}
                    refreshButtonClickHandler={handleAnonymousRefreshButtonClick}
                    adhocPollButotnClickListener={handleAdhocAnonymousPollButtonClick}
                    dbpull={dbpull}
                    subnetsSearchFlagIsAnonymousVPN={getSubnetsSearchFlagIsAnonymousVPN}
                    searchFlagIsAnonymousVPNSetter={setSubnetsSearchFlagIsAnonymousVPN}
                    defaultIsAnonymousVPNOption={defaultIsAnonymousVPNOption}
                    subnetsSearchFlagIsHostingProvider={getSubnetsSearchFlagIsHostingProvider}
                    searchFlagIsHostingProviderSetter={setSubnetsSearchFlagIsHostingProvider}
                    defaultIsHostingProviderOption={defaultIsHostingProviderOption}
                    subnetsSearchFlagIsPublicProxy={getSubnetsSearchFlagIsPublicProxy}
                    searchFlagIsPublicProxySetter={setSubnetsSearchFlagIsPublicProxy}
                    defaultIsPublicProxyOption={defaultIsPublicProxyOption}
                    searchIsTorExitNode={getSubnetsSearchFlagIsTorExitNode}
                    searchIsTorExitNodeSetter={setSubnetsSearchFlagIsTorExitNode}
                    defaultIsTorExitNodeOption={defaultIsTorExitNodeOption}
                    searchIsResidentialProxy={getSubnetsSearchFlagIsResidentialProxy}
                    searchIsResidentialProxySetter={setSubnetsSearchFlagIsResidentialProxy}
                    defaultIsResidentialProxyOption={defaultIsResidentialProxyOption}
                    anonSearchOptions={anonSearchOptions}
                    defaultAnonSearchOption={defaultAnonSearchOption}
                    searchAnonVersion={searchAnonVersion}
                    searchAnonVersionSetter={handleAnonVersionTermChanged}
                />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <AnonymousExceptionTab
                    onSelectRowId={handleAnonExceptionRowSelection}
                    order={getExceptionAnonOrder}
                    orderSetter={setExceptionAnonOrder}
                    orderBy={getExceptionAnonCollectionOrderBy}
                    orderBySetter={setExceptionAnonOrderBy}
                    setAnonExceptionsSwitch={setAnonExceptionsSwitch}
                    selected={exceptionAnonSelected}
                    selectedSetter={setExceptionAnonSelected}
                    offset={exceptionAnonOffset}
                    offsetSetter={setExceptionAnonOffset}
                    page={exceptionAnonPage}
                    pageSetter={setExceptionAnonPage}
                    rows={exceptionAnonRows}
                    total={exceptionAnonTotal}
                    totalSetter={setExceptionAnonTotal}
                    rowsPerPage={rowsPerPage}
                    refreshButtonClickHandler={handleExceptionAnonRefreshButtonClick}
                    handleRowSelected={handleExceptionsSelected}
                    fetchData={fetchExceptionAnonData}
                    manualBlockTableData={exceptionTableData}
                    setManualBlockTableData={setExceptionTableData}
                    addButtonClickListener={handleAddAnonExceptionsButtonClick}
                    deleteButtonEnabled={deleteExceptionsButtonEnabled}
                    deleteButtonClickHandler={handleDeleteExceptionButtonClick}
                    deleteButtonToggle={setDeleteExceptionsButtonEnabled}
                    uploadButtonEnabled={uploadExceptionsButtonEnabled}
                    uploadButtonEnabledSetter={setUploadExceptionsButtonEnabled}
                    viewButtonToggle={handleToggleViewAnonManualBlockButtonVisibility}
                    searchTerm={anonExceptionSearchTerm}
                    searchTermSetter={handleAnonExceptionSearchTermChanged}
                    anonSearchOptions={anonSearchOptions}
                    defaultAnonSearchOption={defaultAnonSearchOption}
                    searchAnonVersion={searchAnonVersion}
                    searchAnonVersionSetter={handleAnonExceptionVersionTermChanged}
                />
            </TabPanel>
            {hasAnonSettingsRead ?
                <TabPanel value={tabIndex} index={2}>
                    <AnonymousSettingsTab
                        selectedSettingsIdSetter={handleSettingsRowSelection}
                        editButtonEnabled={editButtonEnabled}
                        editButtonClickListener={handleEditAnonSettingsButtonClick}
                        rows={settingsRows}
                        selected={settingsSelected}
                        selectedSetter={setSettingsSelected}
                        setAnonSettingSwitch={setAnonSettingSwitch}
                        fetchData={fetchSettingsData}
                        refreshButtonClickHandler={handleAnonymousSettingsRefreshButtonClick}
                    />
                </TabPanel> : ''
            }
        </Box>
    );
}