import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useKeycloak } from '@react-keycloak/web';
import BlockedIPSearchTab from './BlockedIPSearchTab';
import { fetchErrorMessage } from '../mainpage';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const connectionsUrl = '/middleware/v3/analysis_connections/';
const nftRulesUrl = '/middleware/api/nftrules/';
const nftLimit = 2000;
const LATEST_RULESET = 'Latest Ruleset';
/* Number of milliseconds to show notifications */
const notificationAutoclose = 5000;


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`connections-tabpanel-${index}`}
      aria-labelledby={`connections-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `connections-tab-${index}`,
    'aria-controls': `connections-tabpanel-${index}`,
  };
}

export default function AnalysisComponent() {
  const { keycloak } = useKeycloak();
  const [tabIndex, setTabIndex] = useState(0);

  const [srcIp, setSrcIp] = useState('');
  const [ipVersion, setIpVersion] = useState('IPv4');
  const [match, setMatch] = useState({ status: "", action: "", messageDetail: "" });
  const [matchStatus, setMatchStatus] = useState("");
  const [messageSwitch, setMessageSwitch] = useState(false);
  const [rulesetSelect, setRulesetSelect] = useState(LATEST_RULESET);
  const [nftRulesTotal, setNftRulesTotal] = useState(0);
  const [nftRulesSelected, setNftRulesSelected] = useState([]);
  const [nftRulesRows, setNftRulesRows] = useState([]);
  const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
  /* Number of milliseconds to show notifications */
  const notificationAutoclose = 5000;

  // snackbar state vars
  const [snackState,] = React.useState({
    snackbarVertical: 'bottom',
    snackbarHorizontal: 'center',
  });

  const { snackbarHorizontal, snackbarVertical } = snackState;

  const handleFetchErrorClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFetchErrorMessageOpen(false);
  };


  // console.log("On the Connections Component.")
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleIpCheckButtonClick = async () => {
    // console.log("Running the fetch now.")
    let data = [{
      ipVersion: ipVersion,
      srcAddress: srcIp,
      id: null
    }]

    if (rulesetSelect !== LATEST_RULESET) {
      data[0].id = rulesetSelect
    }

    // console.log("IP Check: ", data)
    await fetch(connectionsUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        console.log("Connection: "
          + respData);

        let newMatch = {
          status: respData[0]
        };

        setMatchStatus(respData[0]);

        setMatch(newMatch);

        setMessageSwitch(true);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  function createNftRulesRowData(id,
    created) {
    return {
      id,
      created
    };
  }

  const fetchNftRulesData = async () => {
    // todo, do URLencoding on this to avoid any wonky input

    let mrUrl = nftRulesUrl;
    let finalnft = []

    await fetch(mrUrl
      + '?limit=' + nftLimit,
      {
        method: 'GET',
        headers: {
          'access-token': keycloak.token
        },
      })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setNftRulesTotal(respData.count);
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          if (!finalnft.includes(entry['sha1'])) {
            tmpRows.push(createNftRulesRowData(
              entry['id'],
              entry['created']));
            finalnft.push(entry['sha1'])
          }
        }
        setNftRulesSelected([]);
        setNftRulesRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  return (
    <Box sx={{ width: '100%' }} id="connections-top-level-box">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Connections">
          <Tab label="Blocked IP Search" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <BlockedIPSearchTab
          srcIp={srcIp}
          srcIpSetter={setSrcIp}
          ipVersion={ipVersion}
          ipVersionSetter={setIpVersion}
          match={match}
          matchStatus={matchStatus}
          handleIpCheckButtonClickListener={handleIpCheckButtonClick}
          messageSwitch={messageSwitch}
          setMessageSwitch={setMessageSwitch}
          rulesetSelect={rulesetSelect}
          setRulesetSelect={setRulesetSelect}
          fetchNftData={fetchNftRulesData}
          nftRulesRows={nftRulesRows}
          LATEST_RULESET={LATEST_RULESET}
        />
      </TabPanel>
      <Snackbar
        open={fetchErrorMessageOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleFetchErrorClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {fetchErrorMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  )
}