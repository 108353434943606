import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useKeycloak } from '@react-keycloak/web';
import dayjs from 'dayjs';
import { isAnyValidCidr }
  from '../../common/validation/general/NetworkValidator';
import {
  isCorrectIPVersion,
  isValidScanMsgCidrValue,
  isValidScanMsgIPValue
}
  from '../../common/validation/scan/ScanMsgValidator';
import {
  isValidScanContainerNameValue,
  scanContainerNameIsInUse
}
  from '../../common/validation/scan/ScanContainerValidator';
import {
  isValidScanPolicyNameValue,
  scanPolicyNameIsInUse,
  isValidPolicyPriorityValue,
  isPolicyPriorityInUse
} from '../../common/validation/scan/ScanPolicyValidator';
import {
  getEnabledWeekdaysString,
  getWeekdaysFromString
} from '../../common/validation/scan/ScanWhitelistValidator';
import AddScanMessageDialog from './modals/AddScanMessageDialog';
import EditScanMessageDialog from './modals/EditScanMessageDialog';
import AddScanContainerDialog from './modals/AddScanContainerDialog';
import AddScanPolicyDialog from './modals/AddScanPolicyDialog';
import AddScanWhitelistDialog from './modals/AddScanWhitelistDialog';
import EditScanWhitelistDialog from './modals/EditScanWhitelistDialog';
import ScanMessagesTab from './ScanMessagesTab';
import ScanContainersTab from './ScanContainersTab';
import ScanPoliciesTab from './ScanPoliciesTab';
import ScanSubnetsTab from './ScanSubnetsTab';
import ScanWhitelistsTab from './ScanWhitelistsTab';
import ScanPilotTab from './ScanPilotTab';
import EditScanPolicyDialog from './modals/EditScanPolicyDialog';
import AddScanContainerSubnetDialog from './modals/AddScanContainerSubnetDialog';
import AddScanPilotDialog from './modals/AddScanPilotDialog';
import "./Scans.css";
import { fetchErrorMessage } from '../mainpage';

import {
  LOGI_ROLE_SCANNER_MESSAGES_READ,
  LOGI_ROLE_SCAN_CONTAINERS_READ,
  LOGI_ROLE_SCAN_POLICIES_READ,
  LOGI_ROLE_SCAN_SUBNETS_READ,
  LOGI_ROLE_SCAN_WHITELISTS_READ,
  LOGI_ROLE_SCAN_PILOT_READ
} from '../../common/LogiRoles';

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const rowsPerPage = 20;

const scanMessagesUrl = '/middleware/api/scan_scannermessage/';
const scanV4Url = '/middleware/api/scan_message_v4/';
const scanV6Url = '/middleware/api/scan_message_v6/';
const scanContainerUrl = '/middleware/api/scan_container/';
const scanSubnetsUrl = '/middleware/api/scan_subnet/';
const scanSubnetsV4Url = '/middleware/api/scan_subnets_v4/';
const scanSubnetsV6Url = '/middleware/api/scan_subnets_v6/';
const scanPilotUrl = '/middleware/api/scan_disascanpilot/';
const scanPilotv6Url = '/middleware/api/scan_disa_v6/';
const scanPoliciesUrl = '/middleware/api/scan_policy/';
const scanAlertWhitelistUrl = '/middleware/api/scan_scanalertwhitelist/';
const scanWhitelistV4Url = '/middleware/api/scan_whitelist_v4/';
const scanWhitelistV6Url = '/middleware/api/scan_whitelist_v6/';
const containerSubnetRemoveUrl = '/middleware/api/scan_containerremovesubnet/';

const defaultIAPSearchOption = "any";

const iapLocationOptions = [
  "IACCOL",
  "IACHKM",
  "IACHRO",
  "IACKEL",
  "IACNIS",
  "IACPNT",
  "IACPTM",
  "IACROB",
  "IACWBM",
  "IACYOK"
];

const defaultIpSearchOption = "Both";

const scanIpSearchOptions = [
  "IPv4",
  "IPv6"
];

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scan-tabpanel-${index}`}
      aria-labelledby={`scan-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `scan-tab-${index}`,
    'aria-controls': `scan-tabpanel-${index}`,
  };
}

function createScanMessagesRowData(id,
  host_address,
  active,
  sensor_location,
  dc_location,
  received,
  updated) {
  return {
    id,
    host_address,
    active,
    sensor_location,
    dc_location,
    received,
    updated
  };
}

function createScanContainersRowData(id, name,
  subnet_count,
  total_addresses,
  ip_version) {
  return {
    id,
    name,
    subnet_count,
    total_addresses,
    ip_version,
  };
}

function createScanPoliciesRowData(id,
  name,
  active,
  priority,
  ip_version,
  source_container,
  destination_container,
) {
  return {
    id,
    name,
    active,
    priority,
    ip_version,
    source_container,
    destination_container,
  };
}

function createScanSubnetsRowData(id, address, containers) {
  return {
    id,
    address,
    containers
  };
}

function createScanContainerSubnetsRowData(id, address) {
  return {
    id,
    address
  };
}

function createScanWhitelistsRowData(id,
  subnet,
  start,
  end,
  enabled,
  weekdays,
  comments) {
  return {
    id,
    subnet,
    start,
    end,
    enabled,
    weekdays,
    comments
  };
}

function createScanPilotRowData(id, subnet) {
  return {
    id,
    subnet,
  };
}

// needed so that reset button doesn't have to be clicked twice
let freshScanMessagesSearchTerm = '';
let freshScanContainersSearchTerm = '';
let freshScanSubnetsSearchTerm = '';
let freshScanWhitelistsSearchTerm = '';
let freshScanPilotSearchTerm = '';

export default function ScanComponent() {
  const { keycloak } = useKeycloak();
  const [tabIndex, setTabIndex] = useState(0);

  // use a map to store what the index numbers for the tabs will be
  // for tabs that are actually visible to the user
  var tabIndexAutoNumber = 0;
  const tabIndexMap = new Map();

  const hasScanMessagesRead = keycloak.hasRealmRole(LOGI_ROLE_SCANNER_MESSAGES_READ);
  if (hasScanMessagesRead) {
    tabIndexMap.set("hasScanMessagesRead", tabIndexAutoNumber++);
  }
  const hasScanContainersRead = keycloak.hasRealmRole(LOGI_ROLE_SCAN_CONTAINERS_READ);
  if (hasScanContainersRead) {
    tabIndexMap.set("hasScanContainersRead", tabIndexAutoNumber++);
  }
  const hasScanPoliciesRead = keycloak.hasRealmRole(LOGI_ROLE_SCAN_POLICIES_READ);
  if (hasScanPoliciesRead) {
    tabIndexMap.set("hasScanPoliciesRead", tabIndexAutoNumber++);
  }
  const hasScanSubnetsRead = keycloak.hasRealmRole(LOGI_ROLE_SCAN_SUBNETS_READ);
  if (hasScanSubnetsRead) {
    tabIndexMap.set("hasScanSubnetsRead", tabIndexAutoNumber++);
  }
  const hasScanWhitelistsRead = keycloak.hasRealmRole(LOGI_ROLE_SCAN_WHITELISTS_READ);
  if (hasScanWhitelistsRead) {
    tabIndexMap.set("hasScanWhitelistsRead", tabIndexAutoNumber++);
  }
  const hasScanPilotRead = keycloak.hasRealmRole(LOGI_ROLE_SCAN_PILOT_READ);
  if (hasScanPilotRead) {
    tabIndexMap.set("hasScanPilotRead", tabIndexAutoNumber++);
  }

  // snackbar state vars
  const [snackState] = React.useState({
    snackbarVertical: 'bottom',
    snackbarHorizontal: 'center',
  });

  const { snackbarHorizontal, snackbarVertical } = snackState;

  const [scanMessagesRefreshNotificationOpen, setScanMessagesRefreshNotificationOpen] = useState(false);
  const scanMessagesRefreshNotificationMessage = "Scan Message Data Refreshed";

  const [scanContainersRefreshNotificationOpen, setScanContainersRefreshNotificationOpen] = useState(false);
  const scanContainersRefreshNotificationMessage = "Scan Container Data Refreshed";

  const [scanPoliciesRefreshNotificationOpen, setScanPoliciesRefreshNotificationOpen] = useState(false);
  const scanPoliciesRefreshNotificationMessage = "Scan Policy Data Refreshed";

  const [scanSubnetsRefreshNotificationOpen, setScanSubnetsRefreshNotificationOpen] = useState(false);
  const scanSubnetsRefreshNotificationMessage = "Scan Subnet Data Refreshed";

  const [scanWhitelistsRefreshNotificationOpen, setScanWhitelistsRefreshNotificationOpen] = useState(false);
  const scanWhitelistsRefreshNotificationMessage = "Scan Whitelist Data Refreshed";
  const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
  const handleFetchErrorClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFetchErrorMessageOpen(false);
  };

  const [, setScanPilotRefreshNotificationOpen] = useState(false);



  // general scan messages related
  const [selectedScanMessageId, setSelectedScanMessageId] = useState(0);

  // scan messages tab state vars
  const [addScanMessageButtonEnabled] = useState(true);
  const [editScanMessageButtonEnabled, setEditScanMessageButtonEnabled] = useState(false);
  const [deleteScanMessageButtonEnabled, setDeleteScanMessageButtonEnabled] = useState(false);
  const [scanMessagesOrder, setScanMessagesOrder] = useState('desc');
  const [scanMessagesOrderBy, setScanMessagesOrderBy] = useState('updated');
  const [scanMessagesSelected, setScanMessagesSelected] = useState([]);
  const [scanMessagesOffset, setScanMessagesOffset] = useState(0);
  const [scanMessagesPage, setScanMessagesPage] = useState(0);
  const [scanMessagesRows, setScanMessagesRows] = useState([]);
  const [scanMessagesTotal, setScanMessagesTotal] = useState(0);
  const [scanMessagesSearchTerm, setScanMessagesSearchTerm] = useState('');
  const [scanMessagesSearchIAPLocation, setScanMessagesSearchIAPLocation] = useState(defaultIAPSearchOption);
  const [scanMessagesSearchReceivedFloor, setScanMessagesSearchReceivedFloor] = useState(null);
  const [scanMessagesSearchReceivedCeiling, setScanMessagesSearchReceivedCeiling] = useState(null);
  const [scanMessagesSearchUpdatedFloor, setScanMessagesSearchUpdatedFloor] = useState(null);
  const [scanMessagesSearchUpdatedCeiling, setScanMessagesSearchUpdatedCeiling] = useState(null);
  const [searchScanMessageVersion, setSearchScanMessageVersion] = useState(defaultIpSearchOption);
  const [scanMessageSwitch, setScanMessageSwitch] = useState(false);

  // scan messages add modal
  const [scanMessageAddModalOpen, setScanMessageAddModalOpen] = useState(false);
  const [scanMessageAddModalIPAddress, setScanMessageAddModalIPAddress] = useState('');
  const [scanMessageAddModalIPAddressValid, setScanMessageAddModalIPAddressValid] = useState(true);
  const [scanMessageAddModalIPAddressErrMessage, setScanMessageAddModalIPAddressErrMessage] = useState('');
  const [scanMessageAddModalIAPLocation, setScanMessageAddModalIAPLocation] = useState(iapLocationOptions[0]);
  const [scanMessageAddModalIAPLocationValid] = useState(true);
  const [scanMessageAddModalIAPLocationErrMessage] = useState('');
  const [scanMessageAddModalReceived, setScanMessageAddModalReceived] = useState(dayjs.utc(new Date().toLocaleString()));
  const [scanMessageAddModalReceivedValid, setScanMessageAddModalReceivedValid] = useState(true);
  const [scanMessageAddModalReceivedErrMessage, setScanMessageAddModalReceivedErrMessage] = useState('');
  const [scanMessageAddModalUpdated, setScanMessageAddModalUpdated] = useState(dayjs.utc(new Date().toLocaleString()));
  const [scanMessageAddModalUpdatedValid, setScanMessageAddModalUpdatedValid] = useState(true);
  const [scanMessageAddModalUpdatedErrMessage, setScanMessageAddModalUpdatedErrMessage] = useState('');
  const [scanMessageAddModalAddButtonEnabled, setScanMessageAddModalAddButtonEnabled] = useState(false);

  // scan messages edit modal
  const [scanMessageEditModalOpen, setScanMessageEditModalOpen] = useState(false);
  const [scanMessageEditModalIPAddress, setScanMessageEditModalIPAddress] = useState('');
  const [scanMessageEditModalIPAddressValid] = useState(true);
  const [scanMessageEditModalIAPLocation, setScanMessageEditModalIAPLocation] = useState(iapLocationOptions[0]);
  const [scanMessageEditModalIAPLocationValid] = useState(true);
  const [scanMessageEditModalReceived, setScanMessageEditModalReceived] = useState(dayjs.utc(new Date().toLocaleString()));
  const [scanMessageEditModalReceivedValid, setScanMessageEditModalReceivedValid] = useState(true);
  const [scanMessageEditModalReceivedErrMessage, setScanMessageEditModalReceivedErrMessage] = useState('');
  const [scanMessageEditModalUpdated, setScanMessageEditModalUpdated] = useState(dayjs.utc(new Date().toLocaleString()));
  const [scanMessageEditModalUpdatedValid, setScanMessageEditModalUpdatedValid] = useState(true);
  const [scanMessageEditModalUpdatedErrMessage, setScanMessageEditModalUpdatedErrMessage] = useState('');
  const [scanMessageEditModalUpdateButtonEnabled, setScanMessageEditModalUpdateButtonEnabled] = useState(false);

  // general scan containers related
  const [selectedScanContainerId, setSelectedScanContainerId] = useState(0);
  const [selectedScanContainerSubnet, setSelectedScanContainerSubnet] = useState(0);
  const [selectedScanContainersIPVersion, setSelectedScanContainersIPVersion] = useState(4);

  // scan containers tab state vars
  const [addScanContainerButtonEnabled, setAddScanContainerButtonEnabled] = useState(true);
  const [deleteScanContainerButtonEnabled, setDeleteScanContainerButtonEnabled] = useState(false);
  const [scanContainersOrder, setScanContainersOrder] = useState('asc');
  const [scanContainersOrderBy, setScanContainersOrderBy] = useState('name');
  const [scanContainersSelected, setScanContainersSelected] = useState([]);
  const [scanContainersOffset, setScanContainersOffset] = useState(0);
  const [scanContainersPage, setScanContainersPage] = useState(0);
  const [scanContainersRows, setScanContainersRows] = useState([]);
  const [scanContainersTotal, setScanContainersTotal] = useState(0);
  const [scanContainersSearchTerm, setScanContainersSearchTerm] = useState('');

  // scan container add modal
  const [scanContainerAddModalOpen, setScanContainerAddModalOpen] = useState(false);
  const [scanContainerAddModalName, setScanContainerAddModalName] = useState('');
  const [scanContainerAddModalNameValid, setScanContainerAddModalNameValid] = useState(true);
  const [scanContainerAddModalNameErrMessage, setScanContainerAddModalNameErrMessage] = useState('');
  const [scanContainerAddModalAddButtonEnabled, setScanContainerAddModalAddButtonEnabled] = useState(false);

  // scan container add subnet modal
  const [scanContainerAddSubnetModalOpen, setScanContainerAddSubnetModalOpen] = useState(false);
  const [scanContainerAddSubnetModalSubnetAddress, setScanContainerAddSubnetModalSubnetAddress] = useState('');
  const [scanContainerAddSubnetModalSubnetAddressValid, setScanContainerAddSubnetModalSubnetAddressValid] = useState(true);
  const [scanContainerAddSubnetModalSubnetAddressErrMessage, setScanContainerAddSubnetModalSubnetAddressErrMessage] = useState('');
  const [scanContainerAddSubnetModalAddButtonEnabled, setScanContainerAddSubnetModalAddButtonEnabled] = useState(false);

  // scan container edit widget
  const [scanContainerEditWidgetContainerName, setScanContainerEditWidgetContainerName] = useState('');
  const [scanContainerEditWidgetContainerNameValid, setScanContainerEditWidgetContainerNameValid] = useState(true);
  const [scanContainerEditWidgetContainerNameErrorMessage, setScanContainerEditWidgetContainerNameErrorMessage] = useState('');
  const [scanContainerEditWidgetUpdateContainerNameButtonEnabled, setScanContainerEditWidgetUpdateContainerNameButtonEnabled] = useState(false);
  const [scanContainerEditWidgetValidateContainerNameButtonEnabled] = useState(true);
  const [scanContainerEditWidgetContainerDescription, setScanContainerEditWidgetContainerDescription] = useState('');
  const [scanContainerEditWidgetUpdateContainerDescriptionButtonEnabled] = useState(true);
  const [scanContainerEditWidgetAddSubnetButtonEnabled, setScanContainerEditWidgetAddSubnetButtonEnabled] = useState(true);
  const [scanContainerEditWidgetDeleteSubnetButtonEnabled, setScanContainerEditWidgeDeleteSubnetButtonEnabled] = useState(false);
  const [scanContainerEditWidgetSubnetsOrder, setScanContainerEditWidgetSubnetsOrder] = useState('asc');
  const [scanContainerEditWidgetSubnetsOrderBy, setScanContainerEditWidgetSubnetsOrderBy] = useState('address');
  const [scanContainerEditWidgetSelectedSubnets, setScanContainerEditWidgetSelectedSubnets] = useState([]);
  const [scanContainerEditWidgetSubnetRows, setScanContainerEditWidgetSubnetRows] = useState([]);
  const [scanContainerEditWidgetSubnetTotal, setScanContainerEditWidgetSubnetTotal] = useState(0);

  // general scan policy related
  const [selectedScanPolicyId, setSelectedScanPolicyId] = useState(0);
  const [, setSelectedScanPolicyIPVersion] = useState(4);

  // scan policies tab state vars
  const [addScanPolicyButtonEnabled] = useState(true);
  const [editScanPolicyButtonEnabled, setEditScanPolicyButtonEnabled] = useState(false);
  const [deleteScanPolicyButtonEnabled, setDeleteScanPolicyButtonEnabled] = useState(false);
  const [scanPoliciesOrder, setScanPoliciesOrder] = useState('asc');
  const [scanPoliciesOrderBy, setScanPoliciesOrderBy] = useState('address');
  const [scanPoliciesSelected, setScanPoliciesSelected] = useState([]);
  const [scanPoliciesOffset, setScanPoliciesOffset] = useState(0);
  const [scanPoliciesPage, setScanPoliciesPage] = useState(0);
  const [scanPoliciesRows, setScanPoliciesRows] = useState([]);
  const [scanPoliciesTotal, setScanPoliciesTotal] = useState(0);

  // scan policy add modal
  const [scanPolicyAddModalOpen, setScanPolicyAddModalOpen] = useState(false);
  const [scanPolicyAddModalName, setScanPolicyAddModalName] = useState('');
  const [scanPolicyAddModalNameValid, setScanPolicyAddModalNameValid] = useState(true);
  const [scanPolicyAddModalNameErrMessage, setScanPolicyAddModalNameErrMessage] = useState('');
  const [scanPolicyAddModalDescription, setScanPolicyAddModalDescription] = useState('');
  const [scanPolicyAddModalActive, setScanPolicyAddModalActive] = useState(false);
  const [scanPolicyAddModalPriority, setScanPolicyAddModalPriority] = useState(1);
  const [scanPolicyAddModalPriorityValid, setScanPolicyAddModalPriorityValid] = useState(false);
  const [scanPolicyAddModalPriorityErrMessage, setScanPolicyAddModalPriorityErrMessage] = useState('Pick priority');
  const [scanPolicyAddModalSourceContainer, setScanPolicyAddModalSourceContainer] = useState(0);
  const [scanPolicyAddModalDestinationContainer, setScanPolicyAddModalDestinationContainer] = useState(0);
  const [scanPolicyAddModalIPVersion, setScanPolicyAddModalIPVersion] = useState(4);
  const [scanPolicyAddModalAddButtonEnabled, setScanPolicyAddModalAddButtonEnabled] = useState(false);
  const [scanPolicyAddModalContainer4Options, setScanPolicyAddModalContainer4Options] = useState([]);
  const [scanPolicyAddModalContainer6Options, setScanPolicyAddModalContainer6Options] = useState([]);
  const [scanPolicyAddModalValidationMessage, setScanPolicyAddModalValidationMessage] = useState('');

  // scan policy edit modal
  const [scanPolicyEditModalOpen, setScanPolicyEditModalOpen] = useState(false);
  const [scanPolicyEditModalName, setScanPolicyEditModalName] = useState('');
  const [scanPolicyEditModalNameValid, setScanPolicyEditModalNameValid] = useState(true);
  const [scanPolicyEditModalNameErrMessage, setScanPolicyEditModalNameErrMessage] = useState('');
  const [scanPolicyEditModalDescription, setScanPolicyEditModalDescription] = useState('');
  const [scanPolicyEditModalActive, setScanPolicyEditModalActive] = useState(false);
  const [scanPolicyEditModalPriority, setScanPolicyEditModalPriority] = useState(1);
  const [scanPolicyEditModalPriorityValid, setScanPolicyEditModalPriorityValid] = useState(true);
  const [scanPolicyEditModalPriorityErrMessage, setScanPolicyEditModalPriorityErrMessage] = useState('');
  const [scanPolicyEditModalSourceContainer, setScanPolicyEditModalSourceContainer] = useState(0);
  const [scanPolicyEditModalDestinationContainer, setScanPolicyEditModalDestinationContainer] = useState(0);
  const [scanPolicyEditModalIPVersion, setScanPolicyEditModalIPVersion] = useState(4);
  const [scanPolicyEditModalUpdateButtonEnabled, setScanPolicyEditModalUpdateButtonEnabled] = useState(false);
  const [scanPolicyEditModalContainer4Options, setScanPolicyEditModalContainer4Options] = useState([]);
  const [scanPolicyEditModalContainer6Options, setScanPolicyEditModalContainer6Options] = useState([]);
  const [scanPolicyEditModalValidationMessage, setScanPolicyEditModalValidationMessage] = useState('');

  // general scan subnets related
  const [, setSelectedScanSubnetId] = useState(0);

  // scan subnets tab state vars
  const [scanSubnetsOrder, setScanSubnetsOrder] = useState('asc');
  const [scanSubnetsOrderBy, setScanSubnetsOrderBy] = useState('address');
  const [scanSubnetsSelected, setScanSubnetsSelected] = useState([]);
  const [scanSubnetsOffset, setScanSubnetsOffset] = useState(0);
  const [scanSubnetsPage, setScanSubnetsPage] = useState(0);
  const [scanSubnetsRows, setScanSubnetsRows] = useState([]);
  const [scanSubnetsTotal, setScanSubnetsTotal] = useState(0);
  const [scanSubnetsSearchTerm, setScanSubnetsSearchTerm] = useState('');
  const [searchScanSubnetsVersion, setSearchScanSubnetsVersion] = useState(defaultIpSearchOption)

  // general scan whitelist related
  const [selectedScanWhitelistId, setSelectedScanWhitelistId] = useState(0);

  // scan whitelists tab state vars
  const [addScanWhitelistButtonEnabled] = useState(true);
  const [editScanWhitelistButtonEnabled, setEditScanWhitelistButtonEnabled] = useState(false);
  const [deleteScanWhitelistButtonEnabled, setDeleteScanWhitelistButtonEnabled] = useState(false);
  const [scanWhitelistsOrder, setScanWhitelistsOrder] = useState('asc');
  const [scanWhitelistsOrderBy, setScanWhitelistsOrderBy] = useState('subnet');
  const [scanWhitelistsSelected, setScanWhitelistsSelected] = useState([]);
  const [scanWhitelistsOffset, setScanWhitelistsOffset] = useState(0);
  const [scanWhitelistsPage, setScanWhitelistsPage] = useState(0);
  const [scanWhitelistsRows, setScanWhitelistsRows] = useState([]);
  const [scanWhitelistsTotal, setScanWhitelistsTotal] = useState(0);
  const [scanWhitelistsSearchTerm, setScanWhitelistsSearchTerm] = useState('');
  const [searchScanWhitelistVersion, setSearchScanWhitelistVersion] = useState(defaultIpSearchOption)

  // scan whitelist add modal
  const [scanWhitelistAddModalOpen, setScanWhitelistAddModalOpen] = useState(false);
  const [scanWhitelistAddModalSubnet, setScanWhitelistAddModalSubnet] = useState('');
  const [scanWhitelistAddModalSubnetValid, setScanWhitelistAddModalSubnetValid] = useState(true);
  const [scanWhitelistAddModalSubnetErrMessage, setScanWhitelistAddModalSubnetErrMessage] = useState('');
  const [scanWhitelistAddModalAddButtonEnabled, setScanWhitelistAddModalAddButtonEnabled] = useState(false);
  const [scanWhitelistAddModalEnabled, setScanWhitelistAddModalEnabled] = useState(false);
  const [scanWhitelistAddModalComments, setScanWhitelistAddModalComments] = useState('');
  const [scanWhitelistAddModalStart, setScanWhitelistAddModalStart] = useState(dayjs.utc(new Date().toLocaleString()));
  // set end to be 2 hours from start
  const [scanWhitelistAddModalEnd, setScanWhitelistAddModalEnd] = useState(dayjs.utc(new Date(new Date().setHours(new Date().getHours() + 2))));
  const [scanWhitelistAddModalMonday, setScanWhitelistAddModalMonday] = useState(true);
  const [scanWhitelistAddModalTuesday, setScanWhitelistAddModalTuesday] = useState(true);
  const [scanWhitelistAddModalWednesday, setScanWhitelistAddModalWednesday] = useState(true);
  const [scanWhitelistAddModalThursday, setScanWhitelistAddModalThursday] = useState(true);
  const [scanWhitelistAddModalFriday, setScanWhitelistAddModalFriday] = useState(true);
  const [scanWhitelistAddModalSaturday, setScanWhitelistAddModalSaturday] = useState(true);
  const [scanWhitelistAddModalSunday, setScanWhitelistAddModalSunday] = useState(true);
  const [scanWhitelistAddModalValidationMessage, setScanWhitelistAddModalValidationmessage] = useState("");

  // scan whitelist edit modal
  const [scanWhitelistEditModalOpen, setScanWhitelistEditModalOpen] = useState(false);
  const [scanWhitelistEditModalSubnet, setScanWhitelistEditModalSubnet] = useState('');
  const [scanWhitelistEditModalSubnetValid, setScanWhitelistEditModalSubnetValid] = useState(true);
  const [scanWhitelistEditModalSubnetErrMessage, setScanWhitelistEditModalSubnetErrMessage] = useState('');
  const [scanWhitelistEditModalUpdateButtonEnabled, setScanWhitelistEditModalUpdateButtonEnabled] = useState(false);
  const [scanWhitelistEditModalEnabled, setScanWhitelistEditModalEnabled] = useState(false);
  const [scanWhitelistEditModalComments, setScanWhitelistEditModalComments] = useState('');
  const [scanWhitelistEditModalStart, setScanWhitelistEditModalStart] = useState(dayjs.utc(new Date().toLocaleString()));
  // set end to be 2 hours from start
  const [scanWhitelistEditModalEnd, setScanWhitelistEditModalEnd] = useState(dayjs.utc(new Date(new Date().setHours(new Date().getHours() + 2))));
  const [scanWhitelistEditModalMonday, setScanWhitelistEditModalMonday] = useState(true);
  const [scanWhitelistEditModalTuesday, setScanWhitelistEditModalTuesday] = useState(true);
  const [scanWhitelistEditModalWednesday, setScanWhitelistEditModalWednesday] = useState(true);
  const [scanWhitelistEditModalThursday, setScanWhitelistEditModalThursday] = useState(true);
  const [scanWhitelistEditModalFriday, setScanWhitelistEditModalFriday] = useState(true);
  const [scanWhitelistEditModalSaturday, setScanWhitelistEditModalSaturday] = useState(true);
  const [scanWhitelistEditModalSunday, setScanWhitelistEditModalSunday] = useState(true);
  const [scanWhitelistEditModalValidationMessage, setScanWhitelistEditModalValidationmessage] = useState("");

  // scan pilot tab state vars
  const [scanPilotOrder, setScanPilotOrder] = useState('asc');
  const [scanPilotOrderBy, setScanPilotOrderBy] = useState('subnet');
  const [scanPilotModalOpen, setScanPilotModalOpen] = useState(false);
  const [deleteScanPilotButtonEnabled, setDeleteScanPilotButtonEnabled] = useState(false);
  const [uploadScanPilotButtonEnabled, setUploadScanPilotButtonEnabled] = useState(false);
  const [selectedScanPilotId, setSelectedScanPilotId] = useState(0);
  const [scanPilotOffset, setScanPilotOffset] = useState(0);
  const [scanPilotPage, setScanPilotPage] = useState(0);
  const [scanPilotSelected, setScanPilotSelected] = useState([]);
  const [scanPilotRows, setScanPilotRows] = useState([]);
  const [scanPilotTotal, setScanPilotTotal] = useState(0);
  const [scanPilotSearchTerm, setScanPilotSearchTerm] = useState('');
  const [scanPilotIPVersion, setScanPilotIPVersion] = useState(defaultIpSearchOption);
  const [ScanPilotSwitch, setScanPilotSwitch] = useState(false);

  useEffect(() => {
    fetchScanPilotData();
  }, [ScanPilotSwitch])

  useEffect(() => {
    fetchScanMessagesData();
  }, [scanMessageSwitch])

  const handleScanPilotModalClose = () => {
    setScanPilotModalOpen(false);
  }


  const handleAddScanPilotButtonClick = (event) => {
    setScanPilotModalOpen(true);
  };


  const handleScanPilotRowSelection = (val) => {
    setSelectedScanPilotId(val);
  };

  const getFreshScanPilotSearchTerm = () => {
    return freshScanPilotSearchTerm;
  }

  const getSanitizedScanPilotSearchFieldContent = () => {
    let ret = getFreshScanPilotSearchTerm();
    return encodeURIComponent(ret);
  }


  const fetchScanPilotData = () => {
    const sanitizedSearchTerm = getSanitizedScanPilotSearchFieldContent();
    const scanPilotOrderPrefix = (scanPilotOrder === 'desc' ? '-' : '');

    let ScanPilotUrl = scanPilotv6Url;

    fetch(ScanPilotUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + scanPilotOffset
      + '&page=' + (scanPilotPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + scanPilotOrderPrefix + scanPilotOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setScanPilotTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createScanPilotRowData(entry['id'],
            entry['subnet']));
        }

        setScanPilotSelected([]);
        setScanPilotRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setScanPilotSwitch(false);
  };

  const handleScanPilotSearchTermChanged = (val) => {
    setScanPilotSearchTerm(val);
    freshScanPilotSearchTerm = val;
  };

  const displayScanPilotRefreshStarted = (event) => {
    setScanPilotRefreshNotificationOpen(true);
  };

  const handleScanPilotRefreshButtonClick = (event) => {
    fetchScanPilotData();
    displayScanPilotRefreshStarted();
  };


  const getScanPilotOrder = () => {
    return scanPilotOrder;
  }

  const getScanPilotOrderBy = () => {
    return scanPilotOrderBy;
  }


  const handleScanPilotSelected = (id) => {
    setSelectedScanPilotId(id);
    if (id !== 0) {
      setDeleteScanPilotButtonEnabled(true);
    }
    else {
      setDeleteScanPilotButtonEnabled(false);
    }
  };

  const deleteScanPilot = (refreshCallBack) => {
    fetch(scanPilotUrl
      + selectedScanPilotId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        setDeleteScanPilotButtonEnabled(false);
        refreshCallBack();
      });
  };

  const handleDeleteScanPilotButtonClick = (refreshCallBack) => {
    deleteScanPilot(refreshCallBack);
  };


  const handleScanWhitelistVersionTermChanged = (val) => {
    setSearchScanWhitelistVersion(val);
  };

  const handleScanSubnetsVersionTermChanged = (val) => {
    setSearchScanSubnetsVersion(val);
  };

  const handleScanMessageVersionTermChanged = (val) => {
    setSearchScanMessageVersion(val);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleScanMessagesSearchReceivedFloorChanged = (val) => {
    setScanMessagesSearchReceivedFloor(val);
    // TODO, make sure that ceiling, if set, is after floor
  };

  const handleScanMessagesSearchReceivedCeilingChanged = (val) => {
    setScanMessagesSearchReceivedCeiling(val);
    // TODO, make sure that floor, if set, is before ceiling
  };

  const handleScanMessagesSearchUpdatedFloorChanged = (val) => {
    setScanMessagesSearchUpdatedFloor(val);
    // TODO, make sure that ceiling, if set, is after floor
  };

  const handleScanMessagesSearchUpdatedCeilingChanged = (val) => {
    setScanMessagesSearchUpdatedCeiling(val);
    // TODO, make sure that floor, if set, is before ceiling
  };

  const handleEditScanMessageButtonClick = (event) => {
    fetchScanMessageModalData();
    setScanMessageEditModalOpen(true);
  };

  const handleAddScanMessageButtonClick = (event) => {
    setScanMessageAddModalIPAddress('1.1.1.1');
    setScanMessageAddModalIAPLocation(iapLocationOptions[0]);
    setScanMessageAddModalReceived(dayjs.utc(new Date().toLocaleString()));
    setScanMessageAddModalUpdated(dayjs.utc(new Date().toLocaleString()));

    // disable any current selection
    handleScannerMessageSelected(0);

    // force user to click validate
    setScanMessageAddModalAddButtonEnabled(false);
    setScanMessageAddModalOpen(true);
  };

  const deleteScanMessage = async (refreshCallBack) => {
    await fetch(scanMessagesUrl + selectedScanMessageId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        refreshCallBack();

        setEditScanMessageButtonEnabled(false);
        setDeleteScanMessageButtonEnabled(false);
      });
  };

  const handleDeleteScanMessageButtonClick = (refreshCallBack) => {
    deleteScanMessage(refreshCallBack);
  };

  const handleScannerMessageSelected = (id) => {
    setSelectedScanMessageId(id);
    if (id !== 0) {
      setEditScanMessageButtonEnabled(true);
      setDeleteScanMessageButtonEnabled(true);
    }
    else {
      setEditScanMessageButtonEnabled(false);
      setDeleteScanMessageButtonEnabled(false);
    }
  };

  const addScanMessage = async () => {
    let postData = {
      host_address: scanMessageAddModalIPAddress,
      sensor_location: scanMessageAddModalIAPLocation,
      received: scanMessageAddModalReceived,
      updated: scanMessageAddModalUpdated
    };
    await fetch(scanMessagesUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        // refresh currently displayed data
        fetchScanMessagesData();
      });
  };

  const updateScanMessage = async () => {
    let patchData = {
      host_address: scanMessageEditModalIPAddress,
      sensor_location: scanMessageEditModalIAPLocation,
      // TODO: check with nate2 about why this can't be set
      // dc_location: scanMessageEditModalDCLocation,
      received: scanMessageEditModalReceived,
      updated: scanMessageEditModalUpdated
    };
    await fetch(scanMessagesUrl + selectedScanMessageId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setSelectedScanMessageId(respData['id']);
        setScanMessageEditModalIPAddress(respData['host_address']);
        setScanMessageEditModalIAPLocation(respData['sensor_location']);
        setScanMessageEditModalReceived(dayjs.utc(respData['received']));
        setScanMessageEditModalUpdated(dayjs.utc(respData['updated']));

        // refresh currently displayed data
        fetchScanMessagesData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });

  };

  const handleScanMessageAddModalClose = () => {
    setScanMessageAddModalOpen(false);
    setEditScanMessageButtonEnabled(false);
    setDeleteScanMessageButtonEnabled(false);

    setScanMessageAddModalIPAddressValid(true);
    setScanMessageAddModalIPAddressErrMessage('');
    setScanMessageAddModalReceivedValid(true);
    setScanMessageAddModalReceivedErrMessage('');
    setScanMessageAddModalUpdatedValid(true);
    setScanMessageAddModalUpdatedErrMessage('');

    setScanMessageAddModalAddButtonEnabled(false);
    fetchScanMessagesData();
  };

  const handleScanMessageEditModalClose = () => {
    setScanMessageEditModalOpen(false);
    setEditScanMessageButtonEnabled(false);
    setDeleteScanMessageButtonEnabled(false);

    fetchScanMessagesData();
  };

  const handleScanMessageAddModalAddButtonClicked = (val) => {
    addScanMessage();
    handleScanMessageAddModalClose();
  };

  const handleScanMessageEditModalUpdateButtonClicked = (val) => {
    updateScanMessage();
    handleScanMessageEditModalClose();
  };

  const handleScanMessageAddModalValidateButtonClicked = (val) => {
    let shouldEnableAddButton = true;

    // check that all the fields have valid values

    if (!scanMessageAddModalIPAddressValid) {
      shouldEnableAddButton = false;
    }

    // if (!scanMessageAddModalIAPLocationValid) {
    //   shouldEnableAddButton = false;
    // }

    if (!scanMessageAddModalReceivedValid) {
      shouldEnableAddButton = false;
    }

    if (!scanMessageAddModalUpdatedValid) {
      shouldEnableAddButton = false;
    }

    if (scanMessageAddModalUpdated < scanMessageAddModalReceived) {
      shouldEnableAddButton = false;
    }

    setScanMessageAddModalAddButtonEnabled(shouldEnableAddButton);
  };

  const handleScanMessageEditModalValidateButtonClicked = (val) => {
    let shouldEnableUpdateButton = true;

    // check that all the fields have valid values

    if (!scanMessageEditModalIPAddressValid) {
      shouldEnableUpdateButton = false;
    }

    if (!scanMessageEditModalIAPLocationValid) {
      shouldEnableUpdateButton = false;
    }

    if (!scanMessageEditModalReceivedValid) {
      shouldEnableUpdateButton = false;
    }

    if (!scanMessageEditModalUpdatedValid) {
      shouldEnableUpdateButton = false;
    }

    if (scanMessageEditModalUpdated < scanMessageEditModalReceived) {
      shouldEnableUpdateButton = false;
    }

    setScanMessageEditModalUpdateButtonEnabled(shouldEnableUpdateButton);
  };

  const getFreshScanMessagesSearchTerm = () => {
    return freshScanMessagesSearchTerm;
  };

  const getSanitizedScanMessagesSearchFieldContent = () => {
    let ret = getFreshScanMessagesSearchTerm();
    return encodeURIComponent(ret);
  };

  const getScanMessagesSearchReceivedFloorContent = () => {
    if (scanMessagesSearchReceivedFloor != null) {
      return '&received_after=' + encodeURIComponent(scanMessagesSearchReceivedFloor.toISOString());
    }
    else {
      return '';
    }
  };

  const getScanMessagesSearchReceivedCeilingContent = () => {
    if (scanMessagesSearchReceivedCeiling != null) {
      return '&received_before=' + encodeURIComponent(scanMessagesSearchReceivedCeiling.toISOString());
    }
    else {
      return '';
    }
  };

  const getScanMessagesSearchUpdatedFloorContent = () => {
    if (scanMessagesSearchUpdatedFloor != null) {
      return '&updated_after=' + encodeURIComponent(scanMessagesSearchUpdatedFloor.toISOString());
    }
    else {
      return '';
    }
  };

  const getScanMessagesSearchUpdatedCeilingContent = () => {
    if (scanMessagesSearchUpdatedCeiling != null) {
      return '&updated_before=' + encodeURIComponent(scanMessagesSearchUpdatedCeiling.toISOString());
    }
    else {
      return '';
    }
  };

  const getScanMessageVersion = () => {
    return searchScanMessageVersion;
  }

  const fetchScanMessagesData = async () => {
    const sanitizedSearchTerm = getSanitizedScanMessagesSearchFieldContent();
    const orderPrefix = (scanMessagesOrder === 'desc' ? '-' : '');
    const ipFilter = getScanMessageVersion();
    // console.log("IP Filter: ", ipFilter);

    let smUrl = scanMessagesUrl;
    if (ipFilter === 'Both') {
      smUrl = scanMessagesUrl;
    } else if (ipFilter === 'IPv4') {
      smUrl = scanV4Url;
    } else if (ipFilter === 'IPv6') {
      smUrl = scanV6Url;
    }

    // console.log("Url: ", smUrl);
    // console.log("Sanitized Search Term: ", sanitizedSearchTerm)

    await fetch(smUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + scanMessagesOffset
      + '&page=' + (scanMessagesPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + (scanMessagesSearchIAPLocation !== defaultIAPSearchOption
        ? '&sensor_location=' + scanMessagesSearchIAPLocation : '')
      + getScanMessagesSearchReceivedFloorContent()
      + getScanMessagesSearchReceivedCeilingContent()
      + getScanMessagesSearchUpdatedFloorContent()
      + getScanMessagesSearchUpdatedCeilingContent()
      + '&ordering=' + orderPrefix + scanMessagesOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setScanMessagesTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createScanMessagesRowData(entry['id'],
            entry['host_address'],
            entry['active'],
            entry['sensor_location'],
            entry['dc_location'],
            entry['received'],
            entry['updated'],));
        }

        setScanMessagesSelected([]);
        setScanMessagesRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setScanMessageSwitch(false);
  };

  const fetchScanMessageModalData = async () => {
    await fetch(scanMessagesUrl
      + selectedScanMessageId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {

        setSelectedScanMessageId(respData['id']);
        setScanMessageEditModalIPAddress(respData['host_address']);
        setScanMessageEditModalIAPLocation(respData['sensor_location']);
        setScanMessageEditModalReceived(dayjs.utc(respData['received']));
        setScanMessageEditModalUpdated(dayjs.utc(respData['updated']));
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleScanMessageEditModalReceivedChanged = (val) => {
    setScanMessageEditModalReceived(val);

    let validationResult = { res: true, msg: '' };
    if (val === null || val === undefined) {
      validationResult.res = false;
      validationResult.msg = "Invalid date/time specified";
    }

    // TODO: do validation to make sure the received time is valid
    setScanMessageEditModalReceivedValid(validationResult.res);
    setScanMessageEditModalReceivedErrMessage(validationResult.msg);

    // force user to click the validate button
    setScanMessageEditModalUpdateButtonEnabled(false);
  };

  const handleScanMessageEditModalUpdatedChanged = (val) => {
    setScanMessageEditModalUpdated(val);

    let validationResult = { res: true, msg: '' };
    if (val === null || val === undefined) {
      validationResult.res = false;
      validationResult.msg = "Invalid date/time specified";
    }

    // TODO: do validation to make sure the updated time is valid
    setScanMessageEditModalUpdatedValid(validationResult.res);
    setScanMessageEditModalUpdatedErrMessage(validationResult.msg);

    // force user to click the validate button
    setScanMessageEditModalUpdateButtonEnabled(false);
  };

  const handleScanMessageAddModalIPAddressChanged = (val) => {
    setScanMessageAddModalIPAddress(val);

    // do validation to make sure the ip address entered is valid
    let validationResult = isValidScanMsgIPValue(val);
    setScanMessageAddModalIPAddressValid(validationResult.res);
    setScanMessageAddModalIPAddressErrMessage(validationResult.msg);

    // force user to click the validate button
    setScanMessageAddModalAddButtonEnabled(false);
  };

  const handleScanMessageAddModalSensorLocationChanged = (val) => {
    setScanMessageAddModalIAPLocation(val);

    // do validation to make sure the sensor location entered is valid
    // let validationResult = isValidScanMsgSensorLocationValue(val);
    // setScanMessageAddModalIAPLocationValid(validationResult.res);
    // setScanMessageAddModalIAPLocationErrMessage(validationResult.msg);

    // force user to click the validate button
    setScanMessageAddModalAddButtonEnabled(false);
  };

  const handleScanMessageAddModalReceivedChanged = (val) => {
    setScanMessageAddModalReceived(val);

    let validationResult = { res: true, msg: '' };
    if (val === null || val === undefined) {
      validationResult.res = false;
      validationResult.msg = "Invalid date/time specified";
    }

    // TODO: do validation to make sure the received time is valid
    setScanMessageAddModalReceivedValid(validationResult.res);
    setScanMessageAddModalReceivedErrMessage(validationResult.msg);

    // force user to click the validate button
    setScanMessageAddModalAddButtonEnabled(false);
  };

  const handleScanMessageAddModalUpdatedChanged = (val) => {
    setScanMessageAddModalUpdated(val);

    let validationResult = { res: true, msg: '' };
    if (val === null || val === undefined) {
      validationResult.res = false;
      validationResult.msg = "Invalid date/time specified";
    }

    // TODO: do validation to make sure the updated time is valid
    setScanMessageAddModalUpdatedValid(validationResult.res);
    setScanMessageAddModalUpdatedErrMessage(validationResult.msg);

    // force user to click the validate button
    setScanMessageEditModalUpdateButtonEnabled(false);
  };

  const handleScanMessageAddModalDisableAddbutton = (val) => {
    setScanMessageAddModalAddButtonEnabled(false);
  }

  const handleScanMessageEditModalDisableUpdatebutton = (val) => {
    setScanMessageEditModalUpdateButtonEnabled(false);
  }

  const geFreshScanContainerSearchTerm = () => {
    return freshScanContainersSearchTerm;
  };

  const getSanitizedScanContainerSearchFieldContent = () => {
    let ret = geFreshScanContainerSearchTerm();
    return encodeURIComponent(ret);
  };

  const fetchScanContainersData = async (clearSelection) => {
    const sanitizedSearchTerm = getSanitizedScanContainerSearchFieldContent();
    const orderPrefix = (scanContainersOrder === 'desc' ? '-' : '');

    await fetch(scanContainerUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + scanContainersOffset
      + '&page=' + (scanContainersPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + orderPrefix + scanContainersOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setScanContainersTotal(respData.count);
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createScanContainersRowData(entry['id'],
            entry['name'],
            entry['subnet_count'],
            entry['total_addresses'],
            entry['ip_version'],));
        }

        setScanContainersRows(tmpRows);

        if (clearSelection) {
          setScanContainersSelected([]);
        }
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const fetchScanContainerWidgetData = async (id) => {
    await fetch(scanContainerUrl +
      id + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setScanContainerEditWidgetContainerName(respData['name']);
        setScanContainerEditWidgetContainerDescription(respData['description']);
        setSelectedScanContainersIPVersion(respData['ip_version']);

        // get initial list of subnets
        let tmpSubnetRows = [];
        for (let i = 0; i < respData.subnets.length; i++) {
          let entry = respData.subnets[i];
          tmpSubnetRows.push(createScanContainerSubnetsRowData(
            entry['id'], entry['address']));
        }

        setScanContainerEditWidgetSubnetTotal(tmpSubnetRows.length);
        setScanContainerEditWidgetSubnetRows(tmpSubnetRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleScanContainerSelected = (id) => {
    if (id !== 0) {
      setAddScanContainerButtonEnabled(false);
      setDeleteScanContainerButtonEnabled(true);

      // selecting a scan container will populate the SelectedContainerEditWidget component on this page
      fetchScanContainerWidgetData(id);
    }
    else {
      setAddScanContainerButtonEnabled(true);
      setDeleteScanContainerButtonEnabled(false);
    }
    setSelectedScanContainerId(id);
  };

  const handleScanContainerSubnetSelected = (id) => {
    if (id !== 0) {
      setScanContainerEditWidgetAddSubnetButtonEnabled(false);
      setScanContainerEditWidgeDeleteSubnetButtonEnabled(true);
    }
    else {
      setScanContainerEditWidgetAddSubnetButtonEnabled(true);
      setScanContainerEditWidgeDeleteSubnetButtonEnabled(false);
    }
    setSelectedScanContainerSubnet(id);
  };

  const handleAddScanContainerButtonClick = (event) => {
    // disable any current selection
    handleScanContainerSelected(0);

    setScanContainerAddModalName('New Scan Container Name');
    // force user to click validate
    setScanContainerAddModalAddButtonEnabled(false);

    setScanContainerAddModalOpen(true);
  };

  const deleteScanContainer = async (refreshCallBack) => {
    await fetch(scanContainerUrl + selectedScanContainerId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        setDeleteScanContainerButtonEnabled(false);
        setAddScanContainerButtonEnabled(true);
        handleScanContainerSelected(0);
        setScanContainersSelected([]);
        refreshCallBack();
      });
  };

  const handleDeleteScanContainerButtonClick = (refreshCallBack) => {
    deleteScanContainer(refreshCallBack);
  };

  const handleScanContainerAddModalClose = () => {
    setScanContainerAddModalOpen(false);

    setScanContainerAddModalNameErrMessage('');
    setDeleteScanContainerButtonEnabled(false);

    fetchScanContainersData(true);
  };

  const handleScanContainerAddSubnetModalClose = () => {
    setScanContainerAddSubnetModalOpen(false);
  };

  const handleScanContainerAddModalNameChanged = (val) => {

    setScanContainerAddModalName(val);

    // make sure the name entered is valid
    let validationResult = isValidScanContainerNameValue(val);

    if (validationResult.res) {
      // name is valid, check that it's not in use
      scanContainerNameIsInUse(scanContainerUrl,
        keycloak.token,
        selectedScanContainerId === 0 ? null : selectedScanContainerId,
        val,
        setScanContainerAddModalNameValid,
        setScanContainerAddModalNameErrMessage);
    }
    else {
      // name wasn't even valid
      setScanContainerAddModalNameValid(validationResult.res);
      setScanContainerAddModalNameErrMessage(validationResult.msg);
    }

    // force user to click the validate button
    setScanContainerAddModalAddButtonEnabled(false);
  };

  const handleScanContainerAddSubnetModalSubnetAddressChanged = (val) => {

    setScanContainerAddSubnetModalSubnetAddress(val);

    // // make sure the subnet entered is a valid cidr block
    let validationResult = isValidScanMsgCidrValue(val);
    let versionResult = isCorrectIPVersion(val, selectedScanContainersIPVersion);

    setScanContainerAddSubnetModalSubnetAddressValid(validationResult.res && versionResult.res);
    let finalValidationMessage = !validationResult.res ? validationResult.msg : versionResult.msg;
    setScanContainerAddSubnetModalSubnetAddressErrMessage(finalValidationMessage);

    // // force user to click the validate button
    setScanContainerAddSubnetModalAddButtonEnabled(false);
  };

  const updateScanContainerDetails = async () => {
    let patchData = {
      name: scanContainerEditWidgetContainerName,
      description: scanContainerEditWidgetContainerDescription,
      subnets: []

    };
    await fetch(scanContainerUrl +
      selectedScanContainerId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchScanContainersData(false);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleScanContainerEditWidgetUpdateNameButtonClick = (event) => {
    updateScanContainerDetails();
  };

  const handleScanContainerEditWidgetUpdateDescriptionButtonClick = (event) => {
    updateScanContainerDetails();
  };

  const handleScanContainerEditWidgetValidateNameButtonClick = (event) => {
    let shouldEnableUpdateNameButton = true;

    if (!scanContainerEditWidgetContainerNameValid) {
      shouldEnableUpdateNameButton = false;
    }

    setScanContainerEditWidgetUpdateContainerNameButtonEnabled(shouldEnableUpdateNameButton);
  };

  const handleScanContainerEditWidgetContainerNameChanged = (val) => {

    setScanContainerEditWidgetContainerName(val);

    // make sure the name entered is valid
    let validationResult = isValidScanContainerNameValue(val);

    if (validationResult.res) {
      // name is valid, check that it's not in use
      scanContainerNameIsInUse(scanContainerUrl,
        keycloak.token,
        selectedScanContainerId === 0 ? null : selectedScanContainerId,
        val,
        setScanContainerEditWidgetContainerNameValid,
        setScanContainerEditWidgetContainerNameErrorMessage);

    }
    else {
      // name wasn't even valid
      setScanContainerEditWidgetContainerNameValid(validationResult.res);
      setScanContainerEditWidgetContainerNameErrorMessage(validationResult.msg);
    }

    // force user to click the validate button
    setScanContainerEditWidgetUpdateContainerNameButtonEnabled(false);
  };

  const handleScanContainerEditWidgetContainerDescriptionChanged = (val) => {

    setScanContainerEditWidgetContainerDescription(val);
  };

  const handleScanContainerAddModalValidateButtonClicked = () => {
    let shouldEnableUpdateButton = true;

    // check that all the fields have valid values

    if (!scanContainerAddModalNameValid) {
      shouldEnableUpdateButton = false;
    }

    setScanContainerAddModalAddButtonEnabled(shouldEnableUpdateButton);
  };

  const handleScanContainerAddSubnetModalValidateButtonClicked = () => {
    let shouldEnableUpdateButton = true;

    // check that all the fields have valid values

    if (!scanContainerAddSubnetModalSubnetAddressValid) {
      shouldEnableUpdateButton = false;
    }

    setScanContainerAddSubnetModalAddButtonEnabled(shouldEnableUpdateButton);
  };

  const addScanContainer = async () => {
    let postData = {
      name: scanContainerAddModalName,
      ip_version: selectedScanContainersIPVersion,
      subnets: []
    };
    await fetch(scanContainerUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchScanContainersData(true);
        setDeleteScanContainerButtonEnabled(false);
        setAddScanContainerButtonEnabled(true);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleScanContainerAddModalAddButtonClicked = (val) => {
    addScanContainer();
    handleScanContainerAddModalClose();
  };

  const addScanContainerSubnet = async () => {
    let patchData = {
      subnets: [{ address: scanContainerAddSubnetModalSubnetAddress }]
    };

    await fetch(scanContainerUrl +
      selectedScanContainerId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchScanContainerWidgetData(selectedScanContainerId);
        fetchScanContainersData(false);
        setScanContainerEditWidgeDeleteSubnetButtonEnabled(false);
        setScanContainerEditWidgetAddSubnetButtonEnabled(true);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleScanContainerAddSubnetModalAddButtonClicked = (val) => {
    addScanContainerSubnet();
    handleScanContainerAddSubnetModalClose();
  };

  const handleScanContainerEditWidgetAddSubnetButtonClicked = (val) => {
    // disable any current selection
    handleScanContainerSubnetSelected(0);

    // set the fields in the add scan container subnet modal
    setScanContainerAddSubnetModalSubnetAddress('');

    // force user to click validate
    setScanContainerAddSubnetModalAddButtonEnabled(false);
    setScanContainerAddSubnetModalOpen(true);
  };

  const removeScanContainerSubnet = async () => {
    // since selectedScanContainerSubnet is an id and this PATCH
    // call needs the address the id is associated with, we'll
    // loop through scanContainerEditWidgetSubnetRows and find the entry with
    // the selected id

    let subnetAddress = null;

    for (let i = 0; i < scanContainerEditWidgetSubnetRows.length; i++) {
      let row = scanContainerEditWidgetSubnetRows[i];
      if (row.id === selectedScanContainerSubnet) {
        subnetAddress = row.address;
        break;
      }
    }

    if (subnetAddress === null) {
      return;
    }

    let patchData = {
      subnets: [{ address: subnetAddress }]
    };
    await fetch(containerSubnetRemoveUrl +
      selectedScanContainerId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchScanContainerWidgetData(selectedScanContainerId);
        fetchScanContainersData(false);
        setScanContainerEditWidgeDeleteSubnetButtonEnabled(false);
        setScanContainerEditWidgetAddSubnetButtonEnabled(true);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleScanContainerEditWidgetDeleteSubnetButtonClicked = (event) => {
    removeScanContainerSubnet();
  };

  const handleScanPolicyAddModalClose = () => {
    setScanPolicyAddModalOpen(false);
    setEditScanPolicyButtonEnabled(false);
    setDeleteScanPolicyButtonEnabled(false);

    // clear modal data
    setScanPolicyAddModalName('New Scan Policy');
    setScanPolicyAddModalNameValid(false);
    setScanPolicyAddModalNameErrMessage('Pick a Scan Policy Name');
    setScanPolicyAddModalDescription('New Scan Policy Description');
    setScanPolicyAddModalActive(false);
    setScanPolicyAddModalPriority(1);
    setScanPolicyAddModalPriorityValid(false);
    setScanPolicyAddModalPriorityErrMessage('Pick a Priority');
    setScanPolicyAddModalSourceContainer(0);
    setScanPolicyAddModalDestinationContainer(0);
    setSelectedScanPolicyIPVersion(4);

    fetchScanPoliciesData();
  };

  const handleScanPolicyEditModalClose = () => {
    setScanPolicyEditModalOpen(false);
    setEditScanPolicyButtonEnabled(false);
    setDeleteScanPolicyButtonEnabled(false);

    // clear modal data
    setScanPolicyEditModalName('');
    setScanPolicyEditModalNameValid(true);
    setScanPolicyEditModalNameErrMessage('');
    setScanPolicyEditModalDescription('');
    setScanPolicyEditModalActive(false);
    setScanPolicyEditModalPriority(1);
    setScanPolicyEditModalPriorityValid(true);
    setScanPolicyEditModalPriorityErrMessage('');
    setScanPolicyEditModalSourceContainer(0);
    setScanPolicyEditModalDestinationContainer(0);

    fetchScanPoliciesData();
  };


  const handleScanPolicyAddModalNameChanged = (val) => {

    setScanPolicyAddModalName(val);

    // make sure the name entered is valid
    let validationResult = isValidScanPolicyNameValue(val);

    if (validationResult.res) {
      // name is valid, check that it's not in use
      scanPolicyNameIsInUse(scanPoliciesUrl,
        keycloak.token,
        selectedScanPolicyId === 0 ? null : selectedScanPolicyId,
        val,
        setScanPolicyAddModalNameValid,
        setScanPolicyAddModalNameErrMessage);
    }
    else {
      // name wasn't even valid
      setScanPolicyAddModalNameValid(validationResult.res);
      setScanPolicyAddModalNameErrMessage(validationResult.msg);
    }

    // force user to click the validate button
    setScanPolicyAddModalAddButtonEnabled(false);
  };

  const handleScanPolicyEditModalNameChanged = (val) => {

    setScanPolicyEditModalName(val);

    // make sure the name entered is valid
    let validationResult = isValidScanPolicyNameValue(val);

    if (validationResult.res) {
      // name is valid, check that it's not in use
      scanPolicyNameIsInUse(scanPoliciesUrl,
        keycloak.token,
        selectedScanPolicyId === 0 ? null : selectedScanPolicyId,
        val,
        setScanPolicyEditModalNameValid,
        setScanPolicyEditModalNameErrMessage);
    }
    else {
      // name wasn't even valid
      setScanPolicyEditModalNameValid(validationResult.res);
      setScanPolicyEditModalNameErrMessage(validationResult.msg);
    }

    // force user to click the validate button
    setScanPolicyEditModalUpdateButtonEnabled(false);
  };

  const handleScanPolicyAddModalActiveChanged = (val) => {

    setScanPolicyAddModalActive(val);

    // force user to click the validate button
    setScanPolicyAddModalAddButtonEnabled(false);
  };

  const handleScanPolicyEditModalActiveChanged = (val) => {

    setScanPolicyEditModalActive(val);

    // force user to click the validate button
    setScanPolicyEditModalUpdateButtonEnabled(false);
  };

  const handleScanPolicyAddModalPriorityChanged = (val) => {

    setScanPolicyAddModalPriority(val);

    // make sure the priority is an int
    let priorityValidationResult = isValidPolicyPriorityValue(val);

    if (priorityValidationResult.res) {
      // now check that there is not another policy defined on the server with this same priority 
      isPolicyPriorityInUse(scanPoliciesUrl, keycloak.token, selectedScanPolicyId, val, setScanPolicyAddModalPriorityValid,
        setScanPolicyAddModalPriorityErrMessage);
    }
    else {
      // the priority entered wasn't valid
      setScanPolicyAddModalPriorityValid(priorityValidationResult.res);
      setScanPolicyAddModalPriorityErrMessage(priorityValidationResult.msg);
    }

    // force user to click the validate button
    setScanPolicyAddModalAddButtonEnabled(false);
  };

  const handleScanPolicyEditModalPriorityChanged = (val) => {

    setScanPolicyEditModalPriority(val);

    // make sure the priority is an int
    let priorityValidationResult = isValidPolicyPriorityValue(val);

    if (priorityValidationResult.res) {
      // now check that there is not another policy defined on the server with this same priority 
      isPolicyPriorityInUse(scanPoliciesUrl, keycloak.token, selectedScanPolicyId, val, setScanPolicyEditModalPriorityValid,
        setScanPolicyEditModalPriorityErrMessage);
    }
    else {
      // the priority entered wasn't valid
      setScanPolicyEditModalPriorityValid(priorityValidationResult.res);
      setScanPolicyEditModalPriorityErrMessage(priorityValidationResult.msg);
    }

    // force user to click the validate button
    setScanPolicyEditModalUpdateButtonEnabled(false);
  };

  const handleScanPolicyAddModalSourceContainerChanged = (val) => {

    setScanPolicyAddModalSourceContainer(val);

    // force user to click the validate button
    setScanPolicyAddModalAddButtonEnabled(false);
  };

  const handleScanPolicyEditModalSourceContainerChanged = (val) => {

    setScanPolicyEditModalSourceContainer(val);

    // force user to click the validate button
    setScanPolicyEditModalUpdateButtonEnabled(false);
  };

  const handleScanPolicyAddModalDestinationContainerChanged = (val) => {

    setScanPolicyAddModalDestinationContainer(val);

    // force user to click the validate button
    setScanPolicyAddModalAddButtonEnabled(false);
  };

  const handleScanPolicyEditModalDestinationContainerChanged = (val) => {

    setScanPolicyEditModalDestinationContainer(val);

    // force user to click the validate button
    setScanPolicyEditModalUpdateButtonEnabled(false);
  };

  const addScanPolicy = async () => {
    let postData = {
      name: scanPolicyAddModalName,
      description: scanPolicyAddModalDescription,
      active: scanPolicyAddModalActive,
      priority: scanPolicyAddModalPriority,
      source_container_id: parseInt(scanPolicyAddModalSourceContainer),
      destination_container_id: parseInt(scanPolicyAddModalDestinationContainer),
      ip_version: scanPolicyAddModalIPVersion,
    };
    await fetch(scanPoliciesUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        // refresh currently displayed data
        fetchScanPoliciesData();
      });
  };

  const updateScanPolicy = async () => {
    let patchData = {
      name: scanPolicyEditModalName,
      description: scanPolicyEditModalDescription,
      active: scanPolicyEditModalActive,
      priority: scanPolicyEditModalPriority,
      source_container_id: parseInt(scanPolicyEditModalSourceContainer),
      destination_container_id: parseInt(scanPolicyEditModalDestinationContainer),
      ip_version: scanPolicyEditModalIPVersion,
    };
    await fetch(scanPoliciesUrl
      + selectedScanPolicyId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        // refresh currently displayed data
        fetchScanPoliciesData();
      });
  };

  const handleScanPolicyAddModalAddButtonClicked = (val) => {
    addScanPolicy();
    handleScanPolicyAddModalClose();
  };

  const handleScanPolicyEditModalAddButtonClicked = (val) => {
    updateScanPolicy();
    handleScanPolicyEditModalClose();
  };

  const handleScanPolicyAddModalValidateButtonClicked = (val) => {
    let shouldEnableAddButton = true;

    //
    // check that all the fields have valid values

    // make sure the name was considered valid
    if (!scanPolicyAddModalNameValid) {
      shouldEnableAddButton = false;
      setScanPolicyAddModalValidationMessage('Name is invalid or in use');
    }

    // make sure the priority was considered valid
    if (!scanPolicyAddModalPriorityValid) {
      shouldEnableAddButton = false;
      setScanPolicyAddModalValidationMessage('Priority is invalid or in use');
    }

    if (scanPolicyAddModalDestinationContainer === scanPolicyAddModalSourceContainer) {
      // either user has not chosen either source or destination, or they've chosen
      // the same source and destination
      shouldEnableAddButton = false;
      setScanPolicyAddModalValidationMessage('Source and destination containers must be different');
    }

    if (scanPolicyAddModalSourceContainer === 0) {
      // user has not picked a source container
      shouldEnableAddButton = false;
      setScanPolicyAddModalValidationMessage('Source container must be chosen');
    }

    if (scanPolicyAddModalDestinationContainer === 0) {
      // user has not picked a destination container
      shouldEnableAddButton = false;
      setScanPolicyAddModalValidationMessage('Destination container must be chosen');
    }

    if (shouldEnableAddButton) {
      // set the validation message back
      setScanPolicyAddModalValidationMessage('Policy is valid');
    }

    setScanPolicyAddModalAddButtonEnabled(shouldEnableAddButton);
  };

  const handleScanPolicyEditModalValidateButtonClicked = (val) => {
    let shouldEnableUpdateButton = true;

    //
    // check that all the fields have valid values

    // make sure the name was considered valid
    if (!scanPolicyEditModalNameValid) {
      shouldEnableUpdateButton = false;
      setScanPolicyEditModalValidationMessage('Name is invalid or in use');
    }

    // make sure the priority was considered valid
    if (!scanPolicyEditModalPriorityValid) {
      shouldEnableUpdateButton = false;
      setScanPolicyEditModalValidationMessage('Priority is invalid or in use');
    }

    if (scanPolicyEditModalDestinationContainer === scanPolicyEditModalSourceContainer) {
      // either user has not chosen either source or destination, or they've chosen
      // the same source and destination
      shouldEnableUpdateButton = false;
      setScanPolicyEditModalValidationMessage('Source and destination containers must be different');
    }

    if (scanPolicyEditModalSourceContainer === 0) {
      // user has not picked a source container
      shouldEnableUpdateButton = false;
      setScanPolicyEditModalValidationMessage('Source container must be chosen');
    }

    if (scanPolicyEditModalDestinationContainer === 0) {
      // user has not picked a destination container
      shouldEnableUpdateButton = false;
      setScanPolicyEditModalValidationMessage('Destination container must be chosen');
    }

    if (shouldEnableUpdateButton) {
      // set the validation message back
      setScanPolicyEditModalValidationMessage('Policy changes are valid');
    }

    setScanPolicyEditModalUpdateButtonEnabled(shouldEnableUpdateButton);
  };

  const getScanSubnetsSearchTerm = () => {
    return freshScanSubnetsSearchTerm;
  };

  const getSanitizedScanSubnetSearchFieldContent = () => {
    let ret = getScanSubnetsSearchTerm();
    return encodeURIComponent(ret);
  };

  const fetchScanSubnetsData = async () => {
    const sanitizedSearchTerm = getSanitizedScanSubnetSearchFieldContent();
    const orderPrefix = (scanSubnetsOrder === 'desc' ? '-' : '');

    const ipFilter = searchScanSubnetsVersion;

    let ssUrl = scanSubnetsUrl;
    if (ipFilter === 'Both') {
      ssUrl = scanSubnetsUrl;
    } else if (ipFilter === 'IPv4') {
      ssUrl = scanSubnetsV4Url;
    } else if (ipFilter === 'IPv6') {
      ssUrl = scanSubnetsV6Url;
    }

    await fetch(ssUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + scanSubnetsOffset
      + '&page=' + (scanSubnetsPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + orderPrefix + scanSubnetsOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        //   {
        //     "count": 91,
        //     "next": "http://web:8000/api/scan_subnet/?limit=1&offset=1",
        //     "previous": null,
        //     "results": [
        //         {
        //             "id": 1,
        //             "address": "204.115.183.4/32",
        //             "containers": [
        //                 "Approved DoD Scanners"
        //             ]
        //         }
        //     ]
        // }

        setScanSubnetsTotal(respData.count);
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createScanSubnetsRowData(entry['id'],
            entry['address'],
            entry['containers']));
        }

        setScanSubnetsSelected([]);
        setScanSubnetsRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleScanSubnetSelected = (id) => {
    setSelectedScanSubnetId(id);
    // if (id !== 0) {
    //   setEditScanSubnetButtonEnabled(true);
    //   setDeleteScanSubnetButtonEnabled(true);
    // }
    // else {
    //   setEditScanSubnetButtonEnabled(false);
    //   setDeleteScanSubnetButtonEnabled(false);
    // }
  };

  const getFreshScanWhitelistsSearchTerm = () => {
    return freshScanWhitelistsSearchTerm;
  };

  const getSanitizedScanWhitelistsSearchFieldContent = () => {
    let ret = getFreshScanWhitelistsSearchTerm();
    return encodeURIComponent(ret);
  };

  const fetchScanWhitelistsData = async () => {
    const sanitizedSearchTerm = getSanitizedScanWhitelistsSearchFieldContent();
    const orderPrefix = (scanWhitelistsOrder === 'desc' ? '-' : '');

    const ipFilter = searchScanWhitelistVersion;

    let swUrl = scanAlertWhitelistUrl;
    if (ipFilter === 'Both') {
      swUrl = scanAlertWhitelistUrl;
    } else if (ipFilter === 'IPv4') {
      swUrl = scanWhitelistV4Url;
    } else if (ipFilter === 'IPv6') {
      swUrl = scanWhitelistV6Url;
    }

    await fetch(swUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + scanWhitelistsOffset
      + '&page=' + (scanWhitelistsPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + orderPrefix + scanWhitelistsOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setScanWhitelistsTotal(respData.count);
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createScanWhitelistsRowData(entry['id'],
            entry['subnet'],
            entry['start'],
            entry['end'],
            entry['enabled'],
            entry['weekdays'],
            entry['comments']));
        }

        setScanWhitelistsSelected([]);
        setScanWhitelistsRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const fetchScanWhitelistModalData = async () => {
    await fetch(scanAlertWhitelistUrl
      + selectedScanWhitelistId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        //   {
        //     "id": 1,
        //     "subnet": "192.168.57.0/24",
        //     "created": "2023-01-02T20:17:01.691764Z",
        //     "start": "20:16:48",
        //     "end": "20:16:50",
        //     "enabled": true,
        //     "weekdays": "M,Tu,W,Th,F,Sa,Su",
        //     "comments": ""
        // }
        setScanWhitelistEditModalSubnet(respData['subnet']);
        setScanWhitelistEditModalStart(dayjs.utc(respData['start'], 'HH:mm:ss'));
        setScanWhitelistEditModalEnd(dayjs.utc(respData['end'], 'HH:mm:ss'));
        setScanWhitelistEditModalEnabled(respData['enabled']);
        setScanWhitelistEditModalComments(respData['comments']);

        let weekdays = getWeekdaysFromString(respData['weekdays']);
        setScanWhitelistEditModalMonday(weekdays.monday);
        setScanWhitelistEditModalTuesday(weekdays.tuesday);
        setScanWhitelistEditModalWednesday(weekdays.wednesday);
        setScanWhitelistEditModalThursday(weekdays.thursday);
        setScanWhitelistEditModalFriday(weekdays.friday);
        setScanWhitelistEditModalSaturday(weekdays.saturday);
        setScanWhitelistEditModalSunday(weekdays.sunday);

        setScanWhitelistEditModalValidationmessage("Whitelist Settings Are Valid");
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleScanWhitelistSelected = (id) => {
    setSelectedScanWhitelistId(id);
    if (id !== 0) {
      setEditScanWhitelistButtonEnabled(true);
      setDeleteScanWhitelistButtonEnabled(true);
    }
    else {
      setEditScanWhitelistButtonEnabled(false);
      setDeleteScanWhitelistButtonEnabled(false);
    }
  };

  const handleAddScanWhitelistButtonClick = (event) => {
    // disable any current selection
    handleScanWhitelistSelected(0);

    setScanWhitelistAddModalSubnet('');
    setScanWhitelistAddModalSubnetValid(false);
    setScanWhitelistAddModalSubnetErrMessage("Please enter a CIDR block");
    setScanWhitelistAddModalComments('New Scan Whitelist');
    setScanWhitelistAddModalEnabled(false);
    setScanWhitelistAddModalStart(dayjs.utc(new Date().toLocaleString()));
    setScanWhitelistAddModalEnd(dayjs.utc(new Date(new Date().setHours(new Date().getHours() + 2))));
    setScanWhitelistAddModalMonday(false);
    setScanWhitelistAddModalTuesday(false);
    setScanWhitelistAddModalWednesday(false);
    setScanWhitelistAddModalThursday(false);
    setScanWhitelistAddModalFriday(false);
    setScanWhitelistAddModalSaturday(false);
    setScanWhitelistAddModalSunday(false);

    setScanWhitelistAddModalValidationmessage("Whitelist is incomplete");

    // force user to click validate
    setScanWhitelistAddModalAddButtonEnabled(false);

    setScanWhitelistAddModalOpen(true);
  };

  const handleEditScanWhitelistButtonClick = (event) => {
    fetchScanWhitelistModalData();
    setScanWhitelistEditModalOpen(true);
  };

  const deleteScanWhitelist = async (refreshCallBack) => {
    await fetch(scanAlertWhitelistUrl
      + selectedScanWhitelistId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        refreshCallBack();

        setEditScanWhitelistButtonEnabled(false);
        setDeleteScanWhitelistButtonEnabled(false);
      });
  };

  const handleDeleteScanWhitelistButtonClick = (refreshCallBack) => {
    deleteScanWhitelist(fetchScanWhitelistsData);
  };

  const handleScanWhitelistAddModalClose = () => {
    setScanWhitelistAddModalOpen(false);
    setEditScanWhitelistButtonEnabled(false);
    setDeleteScanWhitelistButtonEnabled(false);

    fetchScanWhitelistsData();
  };

  const handleScanWhitelistEditModalClose = () => {
    setScanWhitelistEditModalOpen(false);
    setEditScanWhitelistButtonEnabled(false);
    setDeleteScanWhitelistButtonEnabled(false);
    setScanWhitelistEditModalUpdateButtonEnabled(false);
    fetchScanWhitelistsData();
  };

  const handleScanWhitelistAddModalAddButtonClicked = (val) => {
    addScanWhitelist();
    handleScanWhitelistAddModalClose();
  };

  const updateScanWhitelist = async () => {
    let patchData = {
      subnet: scanWhitelistEditModalSubnet,
      enabled: scanWhitelistEditModalEnabled,
      comments: scanWhitelistEditModalComments,
      // these are dayjs objects so we can call .toISOString()
      start: scanWhitelistEditModalStart.toISOString(),
      // these are dayjs objects so we can call .toISOString()
      end: scanWhitelistEditModalEnd.toISOString(),
      weekdays: getEnabledWeekdaysString(
        scanWhitelistEditModalMonday,
        scanWhitelistEditModalTuesday,
        scanWhitelistEditModalWednesday,
        scanWhitelistEditModalThursday,
        scanWhitelistEditModalFriday,
        scanWhitelistEditModalSaturday,
        scanWhitelistEditModalSunday
      )
    };
    await fetch(scanAlertWhitelistUrl
      + selectedScanWhitelistId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        // refresh currently displayed data
        fetchScanWhitelistsData();
      });
  };

  const handleScanWhitelistEditModalUpdateButtonClicked = (val) => {
    updateScanWhitelist();
    handleScanWhitelistEditModalClose();
  };

  const handleScanWhitelistAddModalValidateButtonClicked = (val) => {
    let shouldEnableAddButton = true;

    // check that all the fields have valid values

    if (!scanWhitelistAddModalSubnetValid) {
      shouldEnableAddButton = false;
      setScanWhitelistAddModalValidationmessage("Whitelist Subnet is Invalid");
    }

    //  check that start time is valid
    let startValid = scanWhitelistAddModalStart != null
      && scanWhitelistAddModalStart !== undefined
      && scanWhitelistAddModalStart.isValid();
    // check that end time is valid
    let endValid = scanWhitelistAddModalEnd !== null
      && scanWhitelistAddModalEnd !== undefined
      && scanWhitelistAddModalEnd.isValid();

    if (startValid && endValid) {
      // check that end time is greater than start
      if (scanWhitelistAddModalEnd.isBefore(scanWhitelistAddModalStart)) {
        shouldEnableAddButton = false;
        setScanWhitelistAddModalValidationmessage("Start time must be before end time");
        setScanWhitelistAddModalAddButtonEnabled(shouldEnableAddButton);
        return;
      }
    }
    else {
      shouldEnableAddButton = false;
      setScanWhitelistAddModalValidationmessage("Start and end times must be valid");
      setScanWhitelistAddModalAddButtonEnabled(shouldEnableAddButton);
      return;
    }


    let atLeastOneDaySelected = false;
    if (scanWhitelistAddModalMonday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistAddModalTuesday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistAddModalWednesday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistAddModalThursday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistAddModalFriday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistAddModalSaturday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistAddModalSunday) {
      atLeastOneDaySelected = true;
    }

    // if the whitelist is enabled, we must have at least one day selected
    if (scanWhitelistAddModalEnabled && !atLeastOneDaySelected) {
      shouldEnableAddButton = false;
      setScanWhitelistAddModalValidationmessage("For enabled whitelist, At least one day must be selected");
    }

    if (shouldEnableAddButton) {
      setScanWhitelistAddModalValidationmessage("Whitelist Settings Are Valid");
    }
    setScanWhitelistAddModalAddButtonEnabled(shouldEnableAddButton);
  };

  const handleScanWhitelistEditModalValidateButtonClicked = (val) => {
    let shouldEnableUpdateButton = true;

    // check that all the fields have valid values

    if (!scanWhitelistEditModalSubnetValid) {
      shouldEnableUpdateButton = false;
      setScanWhitelistEditModalValidationmessage("Whitelist Subnet is Invalid");
    }

    //  check that start time is valid
    let startValid = scanWhitelistEditModalStart !== null
      && scanWhitelistEditModalStart !== undefined
      && scanWhitelistEditModalStart.isValid();
    // check that end time is valid
    let endValid = scanWhitelistEditModalEnd !== null
      && scanWhitelistEditModalEnd !== undefined
      && scanWhitelistEditModalEnd.isValid();

    if (startValid && endValid) {
      // check that end time is greater than start
      if (scanWhitelistEditModalEnd.isBefore(scanWhitelistEditModalStart)) {
        shouldEnableUpdateButton = false;
        setScanWhitelistEditModalValidationmessage("Start time must be before end time");
        setScanWhitelistEditModalUpdateButtonEnabled(shouldEnableUpdateButton);
        return;
      }
    }
    else {
      shouldEnableUpdateButton = false;
      setScanWhitelistEditModalValidationmessage("Start and end times must be valid");
      setScanWhitelistEditModalUpdateButtonEnabled(shouldEnableUpdateButton);
      return;
    }

    let atLeastOneDaySelected = false;
    if (scanWhitelistEditModalMonday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistEditModalTuesday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistEditModalWednesday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistEditModalThursday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistEditModalFriday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistEditModalSaturday) {
      atLeastOneDaySelected = true;
    }

    if (scanWhitelistEditModalSunday) {
      atLeastOneDaySelected = true;
    }

    // if the whitelist is enabled, we must have at least one day selected
    if (scanWhitelistEditModalEnabled && !atLeastOneDaySelected) {
      shouldEnableUpdateButton = false;
      setScanWhitelistEditModalValidationmessage("For enabled whitelist, At least one day must be selected");
    }

    if (shouldEnableUpdateButton) {
      setScanWhitelistEditModalValidationmessage("Whitelist Settings Are Valid");
    }
    setScanWhitelistEditModalUpdateButtonEnabled(shouldEnableUpdateButton);
  };

  const handleScanWhitelistAddModalSubnetChanged = (val) => {
    setScanWhitelistAddModalSubnet(val);

    // make sure the subnet they entered is a valid cidr subnet
    let subnetValidationResult = isAnyValidCidr(val);

    setScanWhitelistAddModalSubnetValid(subnetValidationResult.res);
    setScanWhitelistAddModalSubnetErrMessage(subnetValidationResult.msg);

    // force user to click the validate button
    setScanWhitelistAddModalAddButtonEnabled(false);
  };

  const handleScanWhitelistAddModalEnabledChanged = (val) => {
    setScanWhitelistAddModalEnabled(val);

    // force user to click the validate button
    setScanWhitelistAddModalAddButtonEnabled(false);
  };

  const handleScanWhitelistAddModalMondayChanged = (val) => {
    setScanWhitelistAddModalMonday(val);

    // force user to click the validate button
    setScanWhitelistAddModalAddButtonEnabled(false);
  };

  const handleScanWhitelistAddModalTuesdayChanged = (val) => {
    setScanWhitelistAddModalTuesday(val);

    // force user to click the validate button
    setScanWhitelistAddModalAddButtonEnabled(false);
  };

  const handleScanWhitelistAddModalWednesdayChanged = (val) => {
    setScanWhitelistAddModalWednesday(val);

    // force user to click the validate button
    setScanWhitelistAddModalAddButtonEnabled(false);
  };

  const handleScanWhitelistAddModalThursdayChanged = (val) => {
    setScanWhitelistAddModalThursday(val);

    // force user to click the validate button
    setScanWhitelistAddModalAddButtonEnabled(false);
  };

  const handleScanWhitelistAddModalFridayChanged = (val) => {
    setScanWhitelistAddModalFriday(val);

    // force user to click the validate button
    setScanWhitelistAddModalAddButtonEnabled(false);
  };

  const handleScanWhitelistAddModalSaturdayChanged = (val) => {
    setScanWhitelistAddModalSaturday(val);

    // force user to click the validate button
    setScanWhitelistAddModalAddButtonEnabled(false);
  };

  const handleScanWhitelistAddModalSundayChanged = (val) => {
    setScanWhitelistAddModalSunday(val);

    // force user to click the validate button
    setScanWhitelistAddModalAddButtonEnabled(false);
  };

  const handleScanWhitelistEditModalMondayChanged = (val) => {
    setScanWhitelistEditModalMonday(val);

    // force user to click the validate button
    setScanWhitelistEditModalUpdateButtonEnabled(false);
  };

  const handleScanWhitelistEditModalTuesdayChanged = (val) => {
    setScanWhitelistEditModalTuesday(val);

    // force user to click the validate button
    setScanWhitelistEditModalUpdateButtonEnabled(false);
  };

  const handleScanWhitelistEditModalWednesdayChanged = (val) => {
    setScanWhitelistEditModalWednesday(val);

    // force user to click the validate button
    setScanWhitelistEditModalUpdateButtonEnabled(false);
  };

  const handleScanWhitelistEditModalThursdayChanged = (val) => {
    setScanWhitelistEditModalThursday(val);

    // force user to click the validate button
    setScanWhitelistEditModalUpdateButtonEnabled(false);
  };

  const handleScanWhitelistEditModalFridayChanged = (val) => {
    setScanWhitelistEditModalFriday(val);

    // force user to click the validate button
    setScanWhitelistEditModalUpdateButtonEnabled(false);
  };

  const handleScanWhitelistEditModalSaturdayChanged = (val) => {
    setScanWhitelistEditModalSaturday(val);

    // force user to click the validate button
    setScanWhitelistEditModalUpdateButtonEnabled(false);
  };

  const handleScanWhitelistEditModalSundayChanged = (val) => {
    setScanWhitelistEditModalSunday(val);

    // force user to click the validate button
    setScanWhitelistEditModalUpdateButtonEnabled(false);
  };

  const handleScanWhitelistEditModalEnabledChanged = (val) => {
    setScanWhitelistEditModalEnabled(val);

    // force user to click the validate button
    setScanWhitelistEditModalUpdateButtonEnabled(false);
  };

  const handleScanWhitelistEditModalSubnetChanged = (val) => {
    setScanWhitelistEditModalSubnet(val);

    // make sure the subnet they entered is a valid cidr subnet
    let subnetValidationResult = isAnyValidCidr(val);

    setScanWhitelistEditModalSubnetValid(subnetValidationResult.res);
    setScanWhitelistEditModalSubnetErrMessage(subnetValidationResult.msg);

    // force user to click the validate button
    setScanWhitelistEditModalUpdateButtonEnabled(false);
  };

  const addScanWhitelist = async () => {
    let postData = {
      subnet: scanWhitelistAddModalSubnet,
      created: dayjs.utc(new Date()).toLocaleString(),
      enabled: scanWhitelistAddModalEnabled,
      comments: scanWhitelistAddModalComments,
      // these are dayjs objects so we can call .format()
      start: scanWhitelistAddModalStart.format("HH:mm:ssZZ"),
      // these are dayjs objects so we can call .format()
      end: scanWhitelistAddModalEnd.format("HH:mm:ssZZ"),
      weekdays: getEnabledWeekdaysString(
        scanWhitelistAddModalMonday,
        scanWhitelistAddModalTuesday,
        scanWhitelistAddModalWednesday,
        scanWhitelistAddModalThursday,
        scanWhitelistAddModalFriday,
        scanWhitelistAddModalSaturday,
        scanWhitelistAddModalSunday
      )
    };
    await fetch(scanAlertWhitelistUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        // refresh currently displayed data
        fetchScanWhitelistsData();
      });
  };

  const fetchScanPolicyModalData = async () => {
    await fetch(scanPoliciesUrl
      + selectedScanPolicyId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        //   {
        //     "id": 2,
        //     "name": "foobar",
        //     "active": true,
        //     "description": "This is my scan policy",
        //     "priority": 1,
        //     "source_container_id": 9,
        //     "source_container": {
        //         "id": 9,
        //         "name": "Spare 2",
        //         "description": "",
        //         "subnets": [

        //         ],
        //         "subnet_count": 0,
        //         "total_addresses": 0
        //     },
        //     "destination_container_id": 7,
        //     "destination_container": {
        //         "id": 7,
        //         "name": "Destination Container for ALL DoD",
        //         "description": "",
        //         "subnets": [
        //             {
        //                 "id": 91,
        //                 "address": "0.0.0.0/0"
        //             }
        //         ],
        //         "subnet_count": 1,
        //         "total_addresses": 4294967296
        //     }
        // }

        setScanPolicyEditModalName(respData['name']);
        setScanPolicyEditModalDescription(respData['description']);
        setScanPolicyEditModalActive(respData['active']);
        setScanPolicyEditModalPriority(respData['priority']);
        setScanPolicyEditModalSourceContainer(respData['source_container_id']);
        setScanPolicyEditModalDestinationContainer(respData['destination_container_id']);
        setScanPolicyEditModalIPVersion(respData['ip_version']);
        setScanPolicyEditModalValidationMessage('Policy is valid');
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const fetchScanPoliciesData = () => {
    // todo, do URLencoding on this to avoid any wonky input
    const orderPrefix = (scanPoliciesOrder === 'desc' ? '-' : '');

    fetch(scanPoliciesUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + scanPoliciesOffset
      + '&page=' + (scanPoliciesPage + 1)
      + '&ordering=' + orderPrefix + scanPoliciesOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {

        // {
        //     "count": 2,
        //     "next": null,
        //     "previous": null,
        //     "results": [
        //         {
        //             "id": 2,
        //             "name": "foobar",
        //             "active": true,
        //             "description": "This is my scan policy",
        //             "priority": 1,
        //             "source_container_id": 9,
        //             "source_container": {
        //                 "id": 9,
        //                 "name": "Spare 2",
        //                 "description": "",
        //                 "subnets": [

        //                 ],
        //                 "subnet_count": 0,
        //                 "total_addresses": 0
        //             },
        //             "destination_container_id": 7,
        //             "destination_container": {
        //                 "id": 7,
        //                 "name": "Destination Container for ALL DoD",
        //                 "description": "",
        //                 "subnets": [
        //                     {
        //                         "id": 91,
        //                         "address": "0.0.0.0/0"
        //                     }
        //                 ],
        //                 "subnet_count": 1,
        //                 "total_addresses": 4294967296
        //             }
        //         },
        //     ]
        // }

        setScanPoliciesTotal(respData.count);
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createScanPoliciesRowData(
            entry['id'],
            entry['name'],
            entry['active'],
            entry['priority'],
            entry['ip_version'],
            entry['source_container'].name,
            entry['destination_container'].name),);
        }

        setScanPoliciesSelected([]);
        setScanPoliciesRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleScanPolicySelected = (id) => {
    setSelectedScanPolicyId(id);
    if (id !== 0) {
      setEditScanPolicyButtonEnabled(true);
      setDeleteScanPolicyButtonEnabled(true);
    }
    else {
      setEditScanPolicyButtonEnabled(false);
      setDeleteScanPolicyButtonEnabled(false);
    }
  };

  /**
   * Fetch a list of scan containers to use as container options for add/edit 
   * scan policy modal select components
   */
  const fetchScanContainerOptionsData = async (
    modalOpenSetterCallback,
    modalContainerOptions4SetterCallback,
    modalContainerOptions6SetterCallback,
    shouldFetchModalData) => {
    await fetch(scanContainerUrl
      + '?limit=300&ording=name', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        let tmpRows4 = [];
        let tmpRows6 = [];

        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          if (entry['ip_version'] === 4) {
            tmpRows4.push({
              id: entry['id'],
              name: entry['name']
            });
          } else {
            tmpRows6.push({
              id: entry['id'],
              name: entry['name']
            });
          }
        }

        modalContainerOptions4SetterCallback(tmpRows4);
        modalContainerOptions6SetterCallback(tmpRows6);

        // open the add or edit modal
        modalOpenSetterCallback(true);

        if (shouldFetchModalData) {
          fetchScanPolicyModalData();
        }
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleAddScanPolicyButtonClick = (event) => {
    // disable any current selection
    handleScanPolicySelected(0);

    // force user to click validate
    setScanPolicyAddModalAddButtonEnabled(false);

    setScanPolicyAddModalName('New Policy Name');
    setScanPolicyAddModalActive(false);
    setScanPolicyAddModalPriority(1);
    setScanPolicyAddModalIPVersion(4);
    fetchScanContainerOptionsData(setScanPolicyAddModalOpen,
      setScanPolicyAddModalContainer4Options, setScanPolicyAddModalContainer6Options, false);
  };

  const handleEditScanPolicyButtonClick = (event) => {
    fetchScanContainerOptionsData(setScanPolicyEditModalOpen,
      setScanPolicyEditModalContainer4Options, setScanPolicyEditModalContainer6Options, true);
  };

  const deleteScanPolicy = async (refreshCallBack) => {
    await fetch(scanPoliciesUrl
      + selectedScanPolicyId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        refreshCallBack();

        setEditScanPolicyButtonEnabled(false);
        setDeleteScanPolicyButtonEnabled(false);
      });
  };

  const handleDeleteScanPolicyButtonClick = (refreshCallBack) => {
    deleteScanPolicy(refreshCallBack);
  };

  const handleScanMessagesSearchTermChanged = (val) => {
    setScanMessagesSearchTerm(val);
    freshScanMessagesSearchTerm = val;
  };

  const handleScanContainersSearchTermChanged = (val) => {
    setScanContainersSearchTerm(val);
    freshScanContainersSearchTerm = val;
  };

  const handleScanWhitelistsSearchTermChanged = (val) => {
    setScanWhitelistsSearchTerm(val);
    freshScanWhitelistsSearchTerm = val;
  };

  const handleScanSubnetsSearchTermChanged = (val) => {
    setScanSubnetsSearchTerm(val);
    freshScanSubnetsSearchTerm = val;
  };

  const handleScanMessagesRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setScanMessagesRefreshNotificationOpen(false);
  };

  const displayScanMessagesRefreshStarted = (event) => {
    setScanMessagesRefreshNotificationOpen(true);
  };

  const handleScanMessagesRefreshButtonClick = (event) => {
    fetchScanMessagesData();
    displayScanMessagesRefreshStarted();
  };

  const handleScanContainersRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setScanContainersRefreshNotificationOpen(false);
  };

  const displayScanContainersRefreshStarted = (event) => {
    setScanContainersRefreshNotificationOpen(true);
  };

  const handleScanContainersRefreshButtonClick = (event) => {
    displayScanContainersRefreshStarted();
    setScanContainersSelected([]);
    handleScanContainerSubnetSelected(0);
    fetchScanContainersData(true);
  };

  const handleScanPoliciesRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setScanPoliciesRefreshNotificationOpen(false);
  };

  const displayScanPoliciesRefreshStarted = (event) => {
    setScanPoliciesRefreshNotificationOpen(true);
  };

  const handleScanPoliciesRefreshButtonClick = (event) => {
    fetchScanPoliciesData();
    displayScanPoliciesRefreshStarted();
  };

  const handleScanSubnetsRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setScanSubnetsRefreshNotificationOpen(false);
  };

  const displayScanSubnetsRefreshStarted = (event) => {
    setScanSubnetsRefreshNotificationOpen(true);
  };

  const handleScanSubnetsRefreshButtonClick = (event) => {
    fetchScanSubnetsData();
    displayScanSubnetsRefreshStarted();
  };

  const handleScanWhitelistsRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setScanWhitelistsRefreshNotificationOpen(false);
  };

  const displayScanWhitelistsRefreshStarted = (event) => {
    setScanSubnetsRefreshNotificationOpen(true);
  };

  const handleScanWhitelistsRefreshButtonClick = (event) => {
    fetchScanWhitelistsData();
    displayScanWhitelistsRefreshStarted();
  };

  const getScanWhitelistsOrderBy = () => {
    return scanWhitelistsOrderBy;
  };

  const getScanWhitelistsOrder = () => {
    return scanWhitelistsOrder;
  };

  const getScanSubnetsOrderBy = () => {
    return scanSubnetsOrderBy;
  };

  const getScanSubnetsOrder = () => {
    return scanSubnetsOrder;
  };

  const getScanPoliciesOrderBy = () => {
    return scanPoliciesOrderBy;
  };

  const getScanPoliciesOrder = () => {
    return scanPoliciesOrder;
  };

  const getScanContainersOrderBy = () => {
    return scanContainersOrderBy;
  };

  const getScanContainersOrder = () => {
    return scanContainersOrder;
  };

  const getScanMessagesOrderBy = () => {
    return scanMessagesOrderBy;
  };

  const getScanMessagesOrder = () => {
    return scanMessagesOrder;
  };

  const getScanPolicyAddModalContainer4Options = () => {
    return scanPolicyAddModalContainer4Options;
  };

  const getScanPolicyAddModalContainer6Options = () => {
    return scanPolicyAddModalContainer6Options;
  };

  const getScanPolicyAddModalIPVersion = () => {
    return scanPolicyAddModalIPVersion;
  };

  const getScanPolicyEditModalIPVersion = () => {
    return scanPolicyEditModalIPVersion;
  };

  const getScanPolicyEditModalContainer4Options = () => {
    return scanPolicyEditModalContainer4Options;
  };

  const getScanPolicyEditModalContainer6Options = () => {
    return scanPolicyEditModalContainer6Options;
  };

  // console.log("hasScanPilotRead = " + hasScanPilotRead);

  return (
    <Box sx={{ width: '100%' }} id="scan-top-level-box">
      <Snackbar
        open={scanMessagesRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleScanMessagesRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {scanMessagesRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={scanContainersRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleScanContainersRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {scanContainersRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={scanPoliciesRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleScanPoliciesRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {scanPoliciesRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={scanSubnetsRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleScanSubnetsRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {scanSubnetsRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={fetchErrorMessageOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleFetchErrorClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {fetchErrorMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={scanWhitelistsRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleScanWhitelistsRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {scanWhitelistsRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <EditScanMessageDialog
        open={scanMessageEditModalOpen}
        onClose={handleScanMessageEditModalClose}
        ip={scanMessageEditModalIPAddress}
        iapLocation={scanMessageEditModalIAPLocation}
        received={scanMessageEditModalReceived}
        receivedSetter={handleScanMessageEditModalReceivedChanged}
        receivedValid={scanMessageEditModalReceivedValid}
        receivedErrMessage={scanMessageEditModalReceivedErrMessage}
        updated={scanMessageEditModalUpdated}
        updatedSetter={handleScanMessageEditModalUpdatedChanged}
        updatedValid={scanMessageEditModalUpdatedValid}
        updatedErrMessage={scanMessageEditModalUpdatedErrMessage}
        updateButtonEnabled={scanMessageEditModalUpdateButtonEnabled}
        updateButtonEnabledSetter={handleScanMessageEditModalDisableUpdatebutton}
        updateButtonClickListener={handleScanMessageEditModalUpdateButtonClicked}
        validateButtonClickListener={handleScanMessageEditModalValidateButtonClicked}
      />
      <AddScanMessageDialog
        open={scanMessageAddModalOpen}
        onClose={handleScanMessageAddModalClose}
        ip={scanMessageAddModalIPAddress}
        ipSetter={handleScanMessageAddModalIPAddressChanged}
        ipValid={scanMessageAddModalIPAddressValid}
        ipErrMessage={scanMessageAddModalIPAddressErrMessage}
        iapLocation={scanMessageAddModalIAPLocation}
        iapLocationSetter={handleScanMessageAddModalSensorLocationChanged}
        iapLocationValid={scanMessageAddModalIAPLocationValid}
        iapLocationErrMessage={scanMessageAddModalIAPLocationErrMessage}
        received={scanMessageAddModalReceived}
        receivedSetter={handleScanMessageAddModalReceivedChanged}
        receivedValid={scanMessageAddModalReceivedValid}
        receivedErrMessage={scanMessageAddModalReceivedErrMessage}
        updated={scanMessageAddModalUpdated}
        updatedSetter={handleScanMessageAddModalUpdatedChanged}
        updatedValid={scanMessageAddModalUpdatedValid}
        updatedErrMessage={scanMessageAddModalUpdatedErrMessage}
        addButtonEnabled={scanMessageAddModalAddButtonEnabled}
        addButtonEnabledSetter={handleScanMessageAddModalDisableAddbutton}
        addButtonClickListener={handleScanMessageAddModalAddButtonClicked}
        validateButtonClickListener={handleScanMessageAddModalValidateButtonClicked}
        iapLocationOptions={iapLocationOptions}
      />
      <AddScanContainerDialog
        open={scanContainerAddModalOpen}
        onClose={handleScanContainerAddModalClose}
        name={scanContainerAddModalName}
        nameSetter={handleScanContainerAddModalNameChanged}
        nameValid={scanContainerAddModalNameValid}
        nameErrMessage={scanContainerAddModalNameErrMessage}
        ipVersion={selectedScanContainersIPVersion}
        ipVersionSetter={setSelectedScanContainersIPVersion}
        addButtonEnabled={scanContainerAddModalAddButtonEnabled}
        addButtonClickListener={handleScanContainerAddModalAddButtonClicked}
        validateButtonClickListener={handleScanContainerAddModalValidateButtonClicked}
      />
      <AddScanContainerSubnetDialog
        open={scanContainerAddSubnetModalOpen}
        onClose={handleScanContainerAddSubnetModalClose}
        subnetAddress={scanContainerAddSubnetModalSubnetAddress}
        subnetAddressSetter={handleScanContainerAddSubnetModalSubnetAddressChanged}
        subnetAddressValid={scanContainerAddSubnetModalSubnetAddressValid}
        subnetAddressErrMessage={scanContainerAddSubnetModalSubnetAddressErrMessage}
        addButtonEnabled={scanContainerAddSubnetModalAddButtonEnabled}
        addButtonClickListener={handleScanContainerAddSubnetModalAddButtonClicked}
        validateButtonClickListener={handleScanContainerAddSubnetModalValidateButtonClicked}
        containerIPVersion={selectedScanContainersIPVersion}
      />
      <AddScanPolicyDialog
        open={scanPolicyAddModalOpen}
        onClose={handleScanPolicyAddModalClose}
        name={scanPolicyAddModalName}
        nameSetter={handleScanPolicyAddModalNameChanged}
        nameValid={scanPolicyAddModalNameValid}
        nameErrMessage={scanPolicyAddModalNameErrMessage}
        description={scanPolicyAddModalDescription}
        descriptionSetter={setScanPolicyAddModalDescription}
        active={scanPolicyAddModalActive}
        activeSetter={handleScanPolicyAddModalActiveChanged}
        priority={scanPolicyAddModalPriority}
        prioritySetter={handleScanPolicyAddModalPriorityChanged}
        priorityValid={scanPolicyAddModalPriorityValid}
        priorityErrMessage={scanPolicyAddModalPriorityErrMessage}
        sourceContainer={scanPolicyAddModalSourceContainer}
        sourceContainerSetter={handleScanPolicyAddModalSourceContainerChanged}
        destinationContainer={scanPolicyAddModalDestinationContainer}
        destinationContainerSetter={handleScanPolicyAddModalDestinationContainerChanged}
        container4Options={getScanPolicyAddModalContainer4Options}
        container6Options={getScanPolicyAddModalContainer6Options}
        addButtonEnabled={scanPolicyAddModalAddButtonEnabled}
        addButtonClickListener={handleScanPolicyAddModalAddButtonClicked}
        validateButtonClickListener={handleScanPolicyAddModalValidateButtonClicked}
        validationMessage={scanPolicyAddModalValidationMessage}
        ipVersion={getScanPolicyAddModalIPVersion}
        ipVersionSetter={setScanPolicyAddModalIPVersion}
      />
      <EditScanPolicyDialog
        open={scanPolicyEditModalOpen}
        onClose={handleScanPolicyEditModalClose}
        name={scanPolicyEditModalName}
        nameSetter={handleScanPolicyEditModalNameChanged}
        nameValid={scanPolicyEditModalNameValid}
        nameErrMessage={scanPolicyEditModalNameErrMessage}
        description={scanPolicyEditModalDescription}
        descriptionSetter={setScanPolicyEditModalDescription}
        active={scanPolicyEditModalActive}
        activeSetter={handleScanPolicyEditModalActiveChanged}
        priority={scanPolicyEditModalPriority}
        prioritySetter={handleScanPolicyEditModalPriorityChanged}
        priorityValid={scanPolicyEditModalPriorityValid}
        priorityErrMessage={scanPolicyEditModalPriorityErrMessage}
        sourceContainer={scanPolicyEditModalSourceContainer}
        sourceContainerSetter={handleScanPolicyEditModalSourceContainerChanged}
        destinationContainer={scanPolicyEditModalDestinationContainer}
        destinationContainerSetter={handleScanPolicyEditModalDestinationContainerChanged}
        container4Options={getScanPolicyEditModalContainer4Options}
        container6Options={getScanPolicyEditModalContainer6Options}
        updateButtonEnabled={scanPolicyEditModalUpdateButtonEnabled}
        updateButtonClickListener={handleScanPolicyEditModalAddButtonClicked}
        validateButtonClickListener={handleScanPolicyEditModalValidateButtonClicked}
        validationMessage={scanPolicyEditModalValidationMessage}
        ipVersion={getScanPolicyEditModalIPVersion}
        ipVersionSetter={setScanPolicyEditModalIPVersion}
      />
      <AddScanWhitelistDialog
        open={scanWhitelistAddModalOpen}
        onClose={handleScanWhitelistAddModalClose}
        addButtonEnabled={scanWhitelistAddModalAddButtonEnabled}
        subnet={scanWhitelistAddModalSubnet}
        subnetSetter={handleScanWhitelistAddModalSubnetChanged}
        subnetValid={scanWhitelistAddModalSubnetValid}
        subnetErrMessage={scanWhitelistAddModalSubnetErrMessage}
        enabled={scanWhitelistAddModalEnabled}
        enabledSetter={handleScanWhitelistAddModalEnabledChanged}
        comments={scanWhitelistAddModalComments}
        commentsSetter={setScanWhitelistAddModalComments}
        start={scanWhitelistAddModalStart}
        startSetter={setScanWhitelistAddModalStart}
        end={scanWhitelistAddModalEnd}
        endSetter={setScanWhitelistAddModalEnd}
        monday={scanWhitelistAddModalMonday}
        mondaySetter={handleScanWhitelistAddModalMondayChanged}
        tuesday={scanWhitelistAddModalTuesday}
        tuesdaySetter={handleScanWhitelistAddModalTuesdayChanged}
        wednesday={scanWhitelistAddModalWednesday}
        wednesdaySetter={handleScanWhitelistAddModalWednesdayChanged}
        thursday={scanWhitelistAddModalThursday}
        thursdaySetter={handleScanWhitelistAddModalThursdayChanged}
        friday={scanWhitelistAddModalFriday}
        fridaySetter={handleScanWhitelistAddModalFridayChanged}
        saturday={scanWhitelistAddModalSaturday}
        saturdaySetter={handleScanWhitelistAddModalSaturdayChanged}
        sunday={scanWhitelistAddModalSunday}
        sundaySetter={handleScanWhitelistAddModalSundayChanged}
        addButtonClickListener={handleScanWhitelistAddModalAddButtonClicked}
        validateButtonClickListener={handleScanWhitelistAddModalValidateButtonClicked}
        validationMessage={scanWhitelistAddModalValidationMessage}
      />

      <EditScanWhitelistDialog
        open={scanWhitelistEditModalOpen}
        onClose={handleScanWhitelistEditModalClose}
        updateButtonEnabled={scanWhitelistEditModalUpdateButtonEnabled}
        subnet={scanWhitelistEditModalSubnet}
        subnetSetter={handleScanWhitelistEditModalSubnetChanged}
        subnetValid={scanWhitelistEditModalSubnetValid}
        subnetErrMessage={scanWhitelistEditModalSubnetErrMessage}
        enabled={scanWhitelistEditModalEnabled}
        enabledSetter={handleScanWhitelistEditModalEnabledChanged}
        comments={scanWhitelistEditModalComments}
        commentsSetter={setScanWhitelistEditModalComments}
        start={scanWhitelistEditModalStart}
        startSetter={setScanWhitelistEditModalStart}
        end={scanWhitelistEditModalEnd}
        endSetter={setScanWhitelistEditModalEnd}
        monday={scanWhitelistEditModalMonday}
        mondaySetter={handleScanWhitelistEditModalMondayChanged}
        tuesday={scanWhitelistEditModalTuesday}
        tuesdaySetter={handleScanWhitelistEditModalTuesdayChanged}
        wednesday={scanWhitelistEditModalWednesday}
        wednesdaySetter={handleScanWhitelistEditModalWednesdayChanged}
        thursday={scanWhitelistEditModalThursday}
        thursdaySetter={handleScanWhitelistEditModalThursdayChanged}
        friday={scanWhitelistEditModalFriday}
        fridaySetter={handleScanWhitelistEditModalFridayChanged}
        saturday={scanWhitelistEditModalSaturday}
        saturdaySetter={handleScanWhitelistEditModalSaturdayChanged}
        sunday={scanWhitelistEditModalSunday}
        sundaySetter={handleScanWhitelistEditModalSundayChanged}
        updateButtonClickListener={handleScanWhitelistEditModalUpdateButtonClicked}
        validateButtonClickListener={handleScanWhitelistEditModalValidateButtonClicked}
        validationMessage={scanWhitelistEditModalValidationMessage}
      />
      <AddScanPilotDialog
        open={scanPilotModalOpen}
        onClose={handleScanPilotModalClose}
        fetchData={fetchScanPilotData}
      />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} id="scan-tabs-box">
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Scan Tab Panel">
          {hasScanMessagesRead ? <Tab label="Scan Messages" {...a11yProps(tabIndexMap.get("hasScanMessagesRead"))} /> : ''}
          {hasScanContainersRead ? <Tab label="Scan Containers" {...a11yProps(tabIndexMap.get("hasScanContainersRead"))} /> : ''}
          {hasScanPoliciesRead ? <Tab label="Scan Policies" {...a11yProps(tabIndexMap.get("hasScanPoliciesRead"))} /> : ''}
          {hasScanSubnetsRead ? <Tab label="Scan Subnets" {...a11yProps(tabIndexMap.get("hasScanSubnetsRead"))} /> : ''}
          {hasScanWhitelistsRead ? <Tab label="Scan Whitelists" {...a11yProps(tabIndexMap.get("hasScanWhitelistsRead"))} /> : ''}
          {hasScanPilotRead ? <Tab label="DISA Scan Pilot" {...a11yProps(tabIndexMap.get("hasScanPilotRead"))} /> : ''}
        </Tabs>
      </Box>
      {hasScanMessagesRead ?
        <TabPanel value={tabIndex} index={tabIndexMap.get("hasScanMessagesRead")}>
          <ScanMessagesTab
            handleRowSelected={handleScannerMessageSelected}
            addButtonEnabled={addScanMessageButtonEnabled}
            addButtonClickHandler={handleAddScanMessageButtonClick}
            editButtonEnabled={editScanMessageButtonEnabled}
            editButtonClickHandler={handleEditScanMessageButtonClick}
            deleteButtonEnabled={deleteScanMessageButtonEnabled}
            deleteButtonClickHandler={handleDeleteScanMessageButtonClick}
            order={getScanMessagesOrder}
            orderSetter={setScanMessagesOrder}
            orderBy={getScanMessagesOrderBy}
            orderBySetter={setScanMessagesOrderBy}
            selected={scanMessagesSelected}
            selectedSetter={setScanMessagesSelected}
            offset={scanMessagesOffset}
            offsetSetter={setScanMessagesOffset}
            page={scanMessagesPage}
            pageSetter={setScanMessagesPage}
            rows={scanMessagesRows}
            total={scanMessagesTotal}
            totalSetter={setScanMessagesTotal}
            searchTerm={scanMessagesSearchTerm}
            searchTermSetter={handleScanMessagesSearchTermChanged}
            rowsPerPage={rowsPerPage}
            searchIAPLocation={scanMessagesSearchIAPLocation}
            searchIAPLocationSetter={setScanMessagesSearchIAPLocation}
            fetchData={fetchScanMessagesData}
            defaultIAPSearchOption={defaultIAPSearchOption}
            iapLocationOptions={iapLocationOptions}
            searchReceivedFloor={scanMessagesSearchReceivedFloor}
            searchReceivedFloorSetter={handleScanMessagesSearchReceivedFloorChanged}
            searchReceivedCeiling={scanMessagesSearchReceivedCeiling}
            searchReceivedCeilingSetter={handleScanMessagesSearchReceivedCeilingChanged}
            searchUpdatedFloor={scanMessagesSearchUpdatedFloor}
            searchUpdatedFloorSetter={handleScanMessagesSearchUpdatedFloorChanged}
            searchUpdatedCeiling={scanMessagesSearchUpdatedCeiling}
            searchUpdatedCeilingSetter={handleScanMessagesSearchUpdatedCeilingChanged}
            refreshButtonClickHandler={handleScanMessagesRefreshButtonClick}
            searchScanMessageVersionSetter={handleScanMessageVersionTermChanged}
            defaultScanMessageSearchOption={defaultIpSearchOption}
            searchScanMessageVersion={searchScanMessageVersion}
            scanMessageSearchOptions={scanIpSearchOptions}
            setScanMessageSwitch={setScanMessageSwitch}
            scanMessageSwitch={scanMessageSwitch}
          />
        </TabPanel> : ''
      }
      {hasScanContainersRead ?
        <TabPanel value={tabIndex} index={tabIndexMap.get("hasScanContainersRead")}>
          <ScanContainersTab
            handleScanContainerRowSelected={handleScanContainerSelected}
            addScanContainerButtonEnabled={addScanContainerButtonEnabled}
            addBScanContaineruttonClickHandler={handleAddScanContainerButtonClick}
            deleteButtonEnabled={deleteScanContainerButtonEnabled}
            deleteButtonClickHandler={handleDeleteScanContainerButtonClick}
            scanContainersOrder={getScanContainersOrder}
            scanContainersOrderSetter={setScanContainersOrder}
            scanContainersOrderBy={getScanContainersOrderBy}
            scanContainersOrderBySetter={setScanContainersOrderBy}
            selectedContainers={scanContainersSelected}
            selectedContainersSetter={setScanContainersSelected}
            scanContainersOffset={scanContainersOffset}
            scanContainersOffsetSetter={setScanContainersOffset}
            scanContainersPage={scanContainersPage}
            scanContainersPageSetter={setScanContainersPage}
            scanContainersRows={scanContainersRows}
            scanContainersTotal={scanContainersTotal}
            scanContainersTotalSetter={setScanContainersTotal}
            searchTerm={scanContainersSearchTerm}
            searchTermSetter={handleScanContainersSearchTermChanged}
            scanContainersRowsPerPage={rowsPerPage}
            fetchContainersData={fetchScanContainersData}
            selectedContainerId={selectedScanContainerId}
            addSubnetButtonEnabled={scanContainerEditWidgetAddSubnetButtonEnabled}
            addSubnetButtonClickListener={handleScanContainerEditWidgetAddSubnetButtonClicked}
            deleteSubnetButtonEnabled={scanContainerEditWidgetDeleteSubnetButtonEnabled}
            deleteSubnetButtonClickListener={handleScanContainerEditWidgetDeleteSubnetButtonClicked}
            updateNameButtonEnabled={scanContainerEditWidgetUpdateContainerNameButtonEnabled}
            updateNameButtonClickListener={handleScanContainerEditWidgetUpdateNameButtonClick}
            validateNameButtonEnabled={scanContainerEditWidgetValidateContainerNameButtonEnabled}
            validateNameButtonClickListener={handleScanContainerEditWidgetValidateNameButtonClick}
            subnetsOrder={scanContainerEditWidgetSubnetsOrder}
            subnetsOrderSetter={setScanContainerEditWidgetSubnetsOrder}
            subnetsOrderBy={scanContainerEditWidgetSubnetsOrderBy}
            subnetsOrderBySetter={setScanContainerEditWidgetSubnetsOrderBy}
            selectedSubnets={scanContainerEditWidgetSelectedSubnets}
            selectedSubnetsSetter={setScanContainerEditWidgetSelectedSubnets}
            containerName={scanContainerEditWidgetContainerName}
            containerNameSetter={handleScanContainerEditWidgetContainerNameChanged}
            containerNameValid={scanContainerEditWidgetContainerNameValid}
            containerNameErrorMessage={scanContainerEditWidgetContainerNameErrorMessage}
            ipVersion={selectedScanContainersIPVersion}
            ipVersionSetter={setSelectedScanContainersIPVersion}
            subnetRows={scanContainerEditWidgetSubnetRows}
            subnetTotal={scanContainerEditWidgetSubnetTotal}
            handleSubnetSelected={handleScanContainerSubnetSelected}
            containerDescription={scanContainerEditWidgetContainerDescription}
            containerDescriptionSetter={handleScanContainerEditWidgetContainerDescriptionChanged}
            updateDescriptionButtonEnabled={scanContainerEditWidgetUpdateContainerDescriptionButtonEnabled}
            updateDescriptionButtonClickListener={handleScanContainerEditWidgetUpdateDescriptionButtonClick}
            refreshButtonClickHandler={handleScanContainersRefreshButtonClick}
          />
        </TabPanel> : ''
      }
      {hasScanPoliciesRead ?
        <TabPanel value={tabIndex} index={tabIndexMap.get("hasScanPoliciesRead")}>
          <ScanPoliciesTab
            handleRowSelected={handleScanPolicySelected}
            addButtonEnabled={addScanPolicyButtonEnabled}
            addButtonClickHandler={handleAddScanPolicyButtonClick}
            editButtonEnabled={editScanPolicyButtonEnabled}
            editButtonClickHandler={handleEditScanPolicyButtonClick}
            deleteButtonEnabled={deleteScanPolicyButtonEnabled}
            deleteButtonClickHandler={handleDeleteScanPolicyButtonClick}
            order={getScanPoliciesOrder}
            orderSetter={setScanPoliciesOrder}
            orderBy={getScanPoliciesOrderBy}
            orderBySetter={setScanPoliciesOrderBy}
            selected={scanPoliciesSelected}
            selectedSetter={setScanPoliciesSelected}
            offset={scanPoliciesOffset}
            offsetSetter={setScanPoliciesOffset}
            page={scanPoliciesPage}
            pageSetter={setScanPoliciesPage}
            rows={scanPoliciesRows}
            total={scanPoliciesTotal}
            rowsPerPage={rowsPerPage}
            fetchData={fetchScanPoliciesData}
            refreshButtonClickHandler={handleScanPoliciesRefreshButtonClick}
          />
        </TabPanel> : ''
      }
      {hasScanSubnetsRead ?
        <TabPanel value={tabIndex} index={tabIndexMap.get("hasScanSubnetsRead")}>
          <ScanSubnetsTab
            handleRowSelected={handleScanSubnetSelected}
            order={getScanSubnetsOrder}
            orderSetter={setScanSubnetsOrder}
            orderBy={getScanSubnetsOrderBy}
            orderBySetter={setScanSubnetsOrderBy}
            selected={scanSubnetsSelected}
            selectedSetter={setScanSubnetsSelected}
            offset={scanSubnetsOffset}
            offsetSetter={setScanSubnetsOffset}
            page={scanSubnetsPage}
            pageSetter={setScanSubnetsPage}
            rows={scanSubnetsRows}
            total={scanSubnetsTotal}
            totalSetter={setScanSubnetsTotal}
            searchTerm={scanSubnetsSearchTerm}
            searchTermSetter={handleScanSubnetsSearchTermChanged}
            rowsPerPage={rowsPerPage}
            fetchData={fetchScanSubnetsData}
            refreshButtonClickHandler={handleScanSubnetsRefreshButtonClick}
            searchScanSubnetsVersionSetter={handleScanSubnetsVersionTermChanged}
            defaultScanSubnetsSearchOption={defaultIpSearchOption}
            searchScanSubnetsVersion={searchScanSubnetsVersion}
            scanSubnetsSearchOptions={scanIpSearchOptions} />
        </TabPanel> : ''
      }
      {hasScanWhitelistsRead ?
        <TabPanel value={tabIndex} index={tabIndexMap.get("hasScanWhitelistsRead")}>
          <ScanWhitelistsTab
            handleRowSelected={handleScanWhitelistSelected}
            addButtonEnabled={addScanWhitelistButtonEnabled}
            addButtonClickHandler={handleAddScanWhitelistButtonClick}
            editButtonEnabled={editScanWhitelistButtonEnabled}
            editButtonClickHandler={handleEditScanWhitelistButtonClick}
            deleteButtonEnabled={deleteScanWhitelistButtonEnabled}
            deleteButtonClickHandler={handleDeleteScanWhitelistButtonClick}
            order={getScanWhitelistsOrder}
            orderSetter={setScanWhitelistsOrder}
            orderBy={getScanWhitelistsOrderBy}
            orderBySetter={setScanWhitelistsOrderBy}
            selected={scanWhitelistsSelected}
            selectedSetter={setScanWhitelistsSelected}
            offset={scanWhitelistsOffset}
            offsetSetter={setScanWhitelistsOffset}
            page={scanWhitelistsPage}
            pageSetter={setScanWhitelistsPage}
            rows={scanWhitelistsRows}
            total={scanWhitelistsTotal}
            totalSetter={setScanWhitelistsTotal}
            searchTerm={scanWhitelistsSearchTerm}
            searchTermSetter={handleScanWhitelistsSearchTermChanged}
            rowsPerPage={rowsPerPage}
            fetchData={fetchScanWhitelistsData}
            refreshButtonClickHandler={handleScanWhitelistsRefreshButtonClick}
            defaultScanWhitelistSearchOption={defaultIpSearchOption}
            searchScanWhitelistVersion={searchScanWhitelistVersion}
            scanWhitelistSearchOptions={scanIpSearchOptions}
            searchScanWhitelistVersionSetter={handleScanWhitelistVersionTermChanged}
          />
        </TabPanel> : ''
      }
      {hasScanPilotRead ?
        <TabPanel value={tabIndex} index={tabIndexMap.get("hasScanPilotRead")}>
          <ScanPilotTab
            onSelectRowId={handleScanPilotRowSelection}
            order={getScanPilotOrder}
            orderSetter={setScanPilotOrder}
            orderBy={getScanPilotOrderBy}
            orderBySetter={setScanPilotOrderBy}
            scanScanPilotSwitchSetter={setScanPilotSwitch}
            selected={scanPilotSelected}
            selectedSetter={setScanPilotSelected}
            offset={scanPilotOffset}
            offsetSetter={setScanPilotOffset}
            page={scanPilotPage}
            pageSetter={setScanPilotPage}
            rows={scanPilotRows}
            total={scanPilotTotal}
            totalSetter={setScanPilotTotal}
            rowsPerPage={rowsPerPage}
            refreshButtonClickHandler={handleScanPilotRefreshButtonClick}
            handleRowSelected={handleScanPilotSelected}
            fetchData={fetchScanPilotData}
            addButtonClickListener={handleAddScanPilotButtonClick}
            deleteButtonEnabled={deleteScanPilotButtonEnabled}
            deleteButtonClickHandler={handleDeleteScanPilotButtonClick}
            deleteButtonToggle={setDeleteScanPilotButtonEnabled}
            uploadButtonEnabled={uploadScanPilotButtonEnabled}
            uploadButtonEnabledSetter={setUploadScanPilotButtonEnabled}
            searchTerm={scanPilotSearchTerm}
            searchTermSetter={handleScanPilotSearchTermChanged}
            ipVersionOptions={scanIpSearchOptions}
            defaultSearchOption={defaultIpSearchOption}
            searchIPVersion={scanPilotIPVersion}
            searchIPVersionSetter={setScanPilotIPVersion} />
        </TabPanel> : ''
      }
    </Box>
  );
}
