import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function EditAntispoofingDialog(props) {
    const { open, onClose,
        subnet,
        subnetSetter,
        subnetValid,
        subnetErrorMessage,
        comments,
        commentsSetter,
        updateButtonEnabled,
        updateButtonClickHandler,
        ruleValidationStatusMessage,
        performValidation
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };
    const handleUpdateButtonClick = (event) => {
        updateButtonClickHandler();
    };

    const handleSubnetChanged = (event) => {
        subnetSetter(event.target.value);
    };

    const handleCommentsChanged = (event) => {
        commentsSetter(event.target.value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Antispoofing Subnet</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        {...(subnetValid !== true && { error: undefined })}
                        helperText={subnetErrorMessage}
                        label="Subnet"
                        variant="standard"
                        size="small"
                        width="480px"
                        value={subnet}
                        onChange={handleSubnetChanged}
                        id="antispoofing-tab-edit-modal-dialog-subnet-field"
                        style={{marginTop: '15px'}}
                    />
                    <TextField
                        label="Comments"
                        variant="standard"
                        size="small"
                        width="480px"
                        value={comments}
                        onChange={handleCommentsChanged}
                        id="antispoofing-tab-edit-modal-dialog-comments-field"
                    />
                    <TextField
                        label="Validation Status"
                        variant="standard"
                        size="small"
                        width="480px"
                        value={ruleValidationStatusMessage}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="antispoofing-tab-edit-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="antispoofing-tab-edit-modal-dialog-validate-button"
                            variant="contained"
                            onClick={performValidation}
                        >Validate</Button>
                        <Button id="antispoofing-tab-edit-modal-dialog-update-button"
                            variant="contained"
                            onClick={handleUpdateButtonClick}
                            disabled={!updateButtonEnabled}
                        >Update</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
