import React from 'react';
import { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
    getPossibleTasknames
}
    from '../../../../common/validation/job/JobValidator';

export default function JobDialog(props) {
    const { onClose, open,
        name, nameSetter, jobModalNameValid, jobModalNameErrorMsg,
        task, taskSetter, enabled, enabledSetter, lastRun,
        totalRunCount, dateChanged,
        crontabId, crontabIdSetter, intervalId, intervalIdSetter,
        oneOff, oneOffSetter,
        updateButtonEnabled, onUpdateButtonClick,
        crontabOptions, intervalOptions, schedTaskData, fetchCeleryScheduleModalData } = props;
    
    const [schedTasks, setSchedTasks] = useState(null);

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleNameContentChange = (event) => {
        nameSetter(event.target.value);
    };

    const handleEnabledChange = (event) => {
        enabledSetter(event.target.checked);
    };

    const handleLastRunChange = (event) => {
        // don't do anything with typed input, making this
        // field read-only
    };

    const handleTotalRunCountChange = (event) => {
        // don't do anything with typed input, making this
        // field read-only
    };

    const handleOnOffChange = (event) => {
        oneOffSetter(event.target.checked);
    };

    const handleCrontabSelection = (event) => {
        crontabIdSetter(event.target.value)
    };

    const handleIntervalSelection = (event) => {
        intervalIdSetter(event.target.value)
    };

    let taskNameChoices = getPossibleTasknames();

    const ataskNameChoices = () => {
        fetchCeleryScheduleModalData()
    }

    useEffect(() => {
        ataskNameChoices()
    }, [])

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Job</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        {...(jobModalNameValid !== true && { error: undefined })}
                        helperText={jobModalNameErrorMsg}
                        label="Name"
                        variant="outlined"
                        size="medium"
                        width="480px"
                        value={name}
                        onChange={handleNameContentChange}
                        style={{marginTop: '15px'}}
                    />
                    <FormControl>
                        <InputLabel id="job-tab-modal-dialog-task-select-label">Task</InputLabel>
                        <Select
                            labelId="job-tab-modal-dialog-task-select-label"
                            id="job-tab-modal-dialog-task-select"
                            label="Task"
                            onChange={taskSetter}
                            defaultValue={taskNameChoices[0]}
                            value={task}
                        >
                            {schedTaskData.map((taskName) => {
                                let isSelected = taskName === task;
                                return (
                                    <MenuItem selected={isSelected} value={taskName}>{taskName}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={enabled}
                            onChange={handleEnabledChange}
                        />} label="Enabled" />
                    </FormGroup>
                    <TextField
                        label="Last Run"
                        variant="outlined"
                        size="medium"
                        value={lastRun}
                        onChange={handleLastRunChange}
                    />
                    <TextField
                        label="Total Run Count"
                        variant="outlined"
                        size="medium"
                        value={totalRunCount}
                        onChange={handleTotalRunCountChange}
                    />
                    <TextField
                        label="Date Updated"
                        variant="outlined"
                        size="medium"
                        value={dateChanged}
                    />
                    <div class="job-tab-modal-dialog-schedule-type-container">
                        {/* // user will be required to select either an interval or a crontab, but not both */}
                        <FormControl id="job-tab-modal-dialog-crontab-select-form-control">
                            <InputLabel id="job-tab-modal-dialog-crontab-select-label">Cron Expression</InputLabel>
                            <Select
                                labelId="job-tab-modal-dialog-crontab-select-label"
                                id="job-tab-modal-dialog-crontab-select"
                                label="Cron Expression"
                                onChange={handleCrontabSelection}
                                defaultValue={'0'}
                                value={crontabId}
                            >
                                <MenuItem value="0">Not Selected</MenuItem>
                                {crontabOptions.map((opt) => {
                                    return (
                                        <MenuItem selected={opt.id === crontabId} value={opt.id}>{opt.cron_expression}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl id="job-tab-modal-dialog-interval-select-form-control">
                            <InputLabel id="job-tab-modal-dialog-interval-select-label">Interval</InputLabel>
                            <Select
                                labelId="job-tab-modal-dialog-interval-select-label"
                                id="job-tab-modal-dialog-interval-select"
                                label="Interval"
                                onChange={handleIntervalSelection}
                                defaultValue={'0'}
                                value={intervalId}
                            >
                                <MenuItem value="0">Not Selected</MenuItem>
                                {intervalOptions.map((opt) => {
                                    return (
                                        <MenuItem selected={opt.id === intervalId} value={opt.id}>{opt.interval}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormGroup id="job-tab-modal-dialog-one-off-form-group">
                            <FormControlLabel control={<Checkbox
                                id="job-tab-modal-dialog-one-off-checkbox"
                                checked={oneOff}
                                onChange={handleOnOffChange}
                            />} label="One Off" />
                        </FormGroup>
                    </div>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="job-tab-modal-dialog-close-button" variant="contained" onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="job-tab-modal-dialog-update-button"
                            variant="contained"
                            onClick={onUpdateButtonClick}
                            disabled={!updateButtonEnabled()}
                        >Update</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}