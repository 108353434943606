import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import {
	LOGI_ROLE_DYNAMIC_PREFS_READ,
	LOGI_ROLE_DYNAMIC_PREFS_WRITE
} from '../../../common/LogiRoles';
import DynamicPropEditorDialog from './modals/DynamicPropEditorDialog';
import DynamicPreferencesTab from './DynamicPreferencesTab';
import { isValidDynamicPreferenceValue }
	from '../../../common/validation/dynamicprefs/DynamicPreferencesValidator';
import "./DynamicPreferencesTable.css";
import { fetchErrorMessage } from '../../mainpage';

const rootFetchUrl = '/middleware/api/globalpreferences/';
const rowsPerPage = 20;


// if an entry doesn't exist in rowDefaultValues, use this
const unknownNameEntry = { default_value: "0", helper_text: "Unhandled preferences entry" };

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`globalpreferences-tabpanel-${index}`}
			aria-labelledby={`globalpreferences-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `globalpreferences-tab-${index}`,
		'aria-controls': `globalpreferences-tabpanel-${index}`,
	};
}

function createData(id, name, helper_text, default_value, raw_value) {
	return {
		id,
		name,
		helper_text,
		default_value,
		raw_value
	};
}

// used so reset button doesn't need to be hit twice
let freshSearchTerm = '';

function DynamicPreferencesComponent() {

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	const [tabIndex, setTabIndex] = React.useState(0);

	// snackbar state vars
	const [snackState,] = React.useState({
		snackbarVertical: 'bottom',
		snackbarHorizontal: 'center',
	});

	const { snackbarHorizontal, snackbarVertical } = snackState;

	const [dynamicPreferencesRefreshNotificationOpen, setDynamicPreferencesRefreshNotificationOpen] = useState(false);
	const dynamicPreferencesRefreshNotificationMessage = "Dynamic Preferences Data Refreshed";
	const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
	const handleFetchErrorClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setFetchErrorMessageOpen(false);
	};

	const { keycloak } = useKeycloak();

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('verbose_name');
	const [selected, setSelected] = useState([]);
	const [offset, setOffset] = useState(0);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState(0);
	const [, setSearchTerm] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [editButtonEnabled, setEditButtonEnabled] = useState(false);
	const [selectedId, setSelectedId] = useState(0);
	const [selectedName, setSelectedName] = useState('');
	const [selectedHelperText, setSelectedHelperText] = useState('');
	const [selectedDefaultValue, setSelectedDefaultValue] = useState('');
	const [selectedRawValue, setSelectedRawValue] = useState('');
	const [selectedRawValueValid, setSelectedRawValueValid] = useState(true);
	const [selectedRawValueErrorMessage, setSelectedRawValueErrorMessage] = useState('');
	const [dynamicPrefsSwitch, setDynamicPrefsSwitch] = useState(false);

	useEffect(() => {
		fetchData();
	}, [dynamicPrefsSwitch])

	let hasDynamicPrefsRead = keycloak.hasRealmRole(LOGI_ROLE_DYNAMIC_PREFS_READ);

	const getFreshSearchTerm = () => {
		return freshSearchTerm;
	};

	const getSanitizedSearchFieldContent = () => {
		let ret = encodeURIComponent(freshSearchTerm);
		return ret;
	};

	const fetchData = () => {
		// todo, do URLencoding on this to avoid any wonky input
		const sanitizedSearchTerm = getSanitizedSearchFieldContent();
		const orderPrefix = (order === 'desc' ? '-' : '');

		fetch(rootFetchUrl
			+ '?limit=' + rowsPerPage
			+ '&offset=' + offset
			+ '&page=' + (page + 1)
			+ (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
			+ '&ordering=' + orderPrefix + orderBy, {
			method: 'GET',
			headers: {
				'access-token': keycloak.token
			},
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				setTotal(respData.count);

				let tmpRows = [];
				for (let i = 0; i < respData.results.length; i++) {
					let entry = respData.results[i];

					tmpRows.push(createData(entry['id'], entry['verbose_name'], entry['help_text'], entry['default'] + "", entry['raw_value']));
				}

				setSelectedId(0);
				setSelectedName('');
				setSelectedDefaultValue('');
				setSelected([]);
				setRows(tmpRows);
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
		setDynamicPrefsSwitch(false);
	};

	const fetchModalData = () => {
		fetch(rootFetchUrl
			+ selectedId + '/', {
			method: 'GET',
			headers: {
				'access-token': keycloak.token
			},
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				setSelectedId(respData['id']);
				setSelectedName(respData['verbose_name']);
				setSelectedRawValue(respData['raw_value']);
				// setSelectedDefaultValue(respData['unix_timestamp']);

				// set default value and helper text from local data
				let localData = respData;
				if (localData !== null) {
					// there was an entry in rowDefaultValues for this name
					setSelectedDefaultValue(localData.default);
					setSelectedHelperText(localData.help_text);
				}
				else {
					// unhandled name
					// use catch-all values
					setSelectedDefaultValue(unknownNameEntry.default_value);
					setSelectedHelperText(unknownNameEntry.helper_text);
				}
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
	};

	const updatePrefEntry = async () => {
		let patchData = {
			id: selectedId,
			raw_value: selectedRawValue
		};
		await fetch(rootFetchUrl
			+ selectedId + '/', {
			method: 'PATCH',
			headers: {
				'access-token': keycloak.token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(patchData)
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				setSelectedId(respData['id']);
				setSelectedName(respData['verbose_name']);
				setSelectedRawValue(respData['raw_value']);

				// set default value and helper text from local data
				let localData = respData;
				if (localData !== null) {
					// there was an entry in rowDefaultValues for this name
					setSelectedDefaultValue(localData.default);
					setSelectedHelperText(localData.help_text);
				}
				else {
					// unhandled name
					// use catch-all values
					setSelectedDefaultValue(unknownNameEntry.default_value);
					setSelectedHelperText(unknownNameEntry.helper_text);
				}
				handleModalClose();
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
	};

	const handleModalOpen = () => {
		fetchModalData();
		setModalOpen(true);
	}

	const handleModalClose = () => {
		setModalOpen(false);
		setSelected([]);
		setEditButtonEnabled(false);
		fetchData();
	};

	const handleEditRowButtonClick = () => {
		handleModalOpen();
	};

	const handleRawValueChanged = (value) => {
		let result = isValidDynamicPreferenceValue(selectedName, value);

		setSelectedRawValue(value)
		setSelectedRawValueValid(result.res);
		setSelectedRawValueErrorMessage(result.msg);
	};

	const updateButtonEnabled = () => {
		let ret = true;

		if (!selectedRawValueValid) {
			ret = false;
		}

		// check if they have correct role
		let hasDynamicPrefsWrite = keycloak.hasRealmRole(LOGI_ROLE_DYNAMIC_PREFS_WRITE);
		if (!hasDynamicPrefsWrite) {
			// user does not have write role, so do not enable the update button
			ret = false;
		}

		return ret;
	};

	const handleUpdateButtonClick = (event) => {
		updatePrefEntry();
	};

	const handleSingleSelection = (val) => {
		setSelectedId(val);

		if (val !== null) {
			setEditButtonEnabled(true);
		}
		else {
			setEditButtonEnabled(false);
		}
	};

	const handleSearchTermChanged = (val) => {
		setSearchTerm(val);
		freshSearchTerm = val;
	};

	const handleDynamicPreferencesRefreshNotificationClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setDynamicPreferencesRefreshNotificationOpen(false);
	};

	const displayDynamicPreferencesRefreshStarted = (event) => {
		setDynamicPreferencesRefreshNotificationOpen(true);
	};

	const handleDynamicPreferencesRefreshButtonClick = (event) => {
		fetchData();
		displayDynamicPreferencesRefreshStarted();
	};

	const getOrderBy = () => {
		return orderBy;
	}

	const getOrder = () => {
		return order;
	}

	if (hasDynamicPrefsRead) {
		return (
			<Box sx={{ width: '100%' }} id="globalpreferences-top-level-box">
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Snackbar
						open={dynamicPreferencesRefreshNotificationOpen}
						autoHideDuration={notificationAutoclose}
						onClose={handleDynamicPreferencesRefreshNotificationClosed}
						anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
					>
						<MuiAlert
							className="logi-snackbar-notification-message"
							severity="info"
							variant="filled"
							sx={{ width: '100%' }}>
							{dynamicPreferencesRefreshNotificationMessage}
						</MuiAlert>
					</Snackbar>
					<Snackbar
						open={fetchErrorMessageOpen}
						autoHideDuration={notificationAutoclose}
						onClose={handleFetchErrorClosed}
						anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
					>
						<MuiAlert
							className="logi-snackbar-notification-message"
							severity="info"
							variant="filled"
							sx={{ width: '100%' }}>
							{fetchErrorMessage}
						</MuiAlert>
					</Snackbar>
					<DynamicPropEditorDialog
						open={modalOpen}
						onClose={handleModalClose}
						selectedName={selectedName}
						selectedHelperText={selectedHelperText}
						selectedDefaultValue={selectedDefaultValue}
						selectedRawValue={selectedRawValue}
						selectedRawValueValid={selectedRawValueValid}
						selectedRawValueSetter={handleRawValueChanged}
						selectedRawValueErrorMessage={selectedRawValueErrorMessage}
						updateButtonEnabled={updateButtonEnabled}
						updateButtonClickHandler={handleUpdateButtonClick}
					/>
					<Tabs value={tabIndex} onChange={handleTabChange} aria-label="Dynamic Preferences Tab Panel">
						<Tab label="Dynamic Preferences" {...a11yProps(0)} />
					</Tabs>
				</Box>
				<TabPanel value={tabIndex} index={0}>
					<DynamicPreferencesTab
						editButtonEnabled={editButtonEnabled}
						editButtonClickListener={handleEditRowButtonClick}
						singleSelectionHandler={handleSingleSelection}
						order={getOrder}
						orderSetter={setOrder}
						orderBy={getOrderBy}
						orderBySetter={setOrderBy}
						setDynamicPrefsSwitch={setDynamicPrefsSwitch}
						page={page}
						pageSetter={setPage}
						offset={offset}
						offsetSetter={setOffset}
						selected={selected}
						selectedSetter={setSelected}
						searchTerm={getFreshSearchTerm()}
						searchTermSetter={handleSearchTermChanged}
						rows={rows}
						total={total}
						fetchData={fetchData}
						rowsPerPage={rowsPerPage}
						refreshButtonClickHandler={handleDynamicPreferencesRefreshButtonClick}
					/>
				</TabPanel>
			</Box>
		);
	}
	else {
		return (<div />);
	}
};

export default DynamicPreferencesComponent;