export function isValidScanPolicyNameValue(input) {
    let res = true;
    let msg = "";
    let ret = { res: res, msg: msg };


    if (input === null) {
        // null input is not at all valid
        ret.res = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.res = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }
    return ret;
}

/**
 * Check if the name the user wants as the scan policy name is already in use.
 * If it is, they'll have to choose another name.
 * 
 * @param {*} baseUrl Base url to fetch scan policies with
 * @param {*} keycloakToken Our keycloak token to authenticate with
 * @param {*} id If supplied, it means we are editing an existing scan policy and if a result comes for this id we'll allow it since it's updating the name on the same scan policy
 * @param {*} name The scan policy name we are checking is in use or not
 * @param {*} resultCallback A function that we'll call with the final result of true or false
 * 
 * @returns true if the name is already in use by an existing scan policy, false otherwise
 */
export function scanPolicyNameIsInUse(baseUrl, keycloakToken, id, name, fieldIsValidCallback,
    fieldErrorMessageCallback) {

    // build the url
    let fetchUrl = baseUrl + "?name=" + encodeURIComponent(name);

    fetch(fetchUrl, {
        method: 'GET',
        headers: {
            'access-token': keycloakToken
        },
    })
        .then((response) => response.json())
        .then((respData) => {
            let existingId = null
            let inUse = false;

            if (respData.results.length > 0) {
                // should be only one result
                let entry = respData.results[0];
                existingId = entry['id'];
            }
            if (id !== null) {
                // we are editing an existing scan policy, so if existingId != null but equals
                // the id of the scan policy we are editing, it's no issue
                if (existingId !== null) {
                    if (existingId !== id) {
                        // another scan policy has this name so we must force the user to pick something else
                        inUse = true;
                    }
                    else {
                        // since this name exists on the scan policy we are editing, we'll not say the name is already in use
                        // by another scan policy
                    }
                }
                else {
                    // there is no scan policy with this name, all good
                }
            }
            else {
                // since id is null here, we are likely adding a new scan policy that doesn't have an id yet
                // we we are simply interested if any scan policy exists with this name already
                if (existingId !== null) {
                    // an existing scan policy has this name already
                    inUse = true;
                }
            }

            if (inUse) {
                fieldIsValidCallback(false);
                fieldErrorMessageCallback("This name is in use by another scan policy, please pick another name");
            }
            else {
                fieldIsValidCallback(true);
                fieldErrorMessageCallback("");
            }
        });
}

/**
 * Check that the input value is a number suitable for being used as a policy priority
 * 
 * @param {*} inputVal 
 */
export function isValidPolicyPriorityValue(input) {
    let ret = { res: true, msg: "" };

    const min = 0;
    const max = 99;

    if (input === null) {
        // null input is not at all valid
        ret.res = false;
        ret.msg = "Blank values not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.res = false;
        ret.msg = "Blank values not allowed";
        return ret;
    }

    if (isNaN(input)) {
        // not a number, so not valid
        ret.res = false;
        ret.msg = "Input is not a number";
    }
    else {
        // we have a number, validate it's within a reasonable range
        let parsedNum = parseInt(trimmedInput);

        if (parsedNum < min || parsedNum > max) {
            // outside our allowed range
            ret.res = false;
            ret.msg = "Input must be a number in the range " + min + "-" + max;
        }
    }

    return ret;
}

/**
 * Check if the priority the user wants as the scan policy priority is already in use.
 * If it is, they'll have to choose another priority.
 * 
 * @param {*} baseUrl Base url to fetch scan policies with
 * @param {*} keycloakToken Our keycloak token to authenticate with
 * @param {*} id If supplied, it means we are editing an existing scan policy and if a result comes for this id we'll allow it since it's updating the priority on the same scan policy
 * @param {*} priority The scan policy priority we are checking is in use or not
 * @param {*} resultCallback A function that we'll call with the final result of true or false
 * 
 * @returns true if the priority is already in use by an existing scan policy, false otherwise
 */
export function isPolicyPriorityInUse(baseUrl, keycloakToken, id, priority, fieldIsValidCallback,
    fieldErrorMessageCallback) {

    // build the url
    let fetchUrl = baseUrl + "?priority=" + encodeURIComponent(priority);

    fetch(fetchUrl, {
        method: 'GET',
        headers: {
            'access-token': keycloakToken
        },
    })
        .then((response) => response.json())
        .then((respData) => {
            let existingId = null
            let inUse = false;

            if (respData.results.length > 0) {
                // should be only one result
                let entry = respData.results[0];
                existingId = entry['id'];
            }
            if (id !== null) {
                // we are editing an existing scan policy, so if existingId != null but equals
                // the id of the scan policy we are editing, it's no issue
                if (existingId !== null) {
                    if (existingId !== id) {
                        // another scan policy has this priority so we must force the user to pick something else
                        inUse = true;
                    }
                    else {
                        // since this priority exists on the scan policy we are editing, we'll not say the priority is already in use
                        // by another scan policy
                    }
                }
                else {
                    // there is no scan policy with this priority, all good
                }
            }
            else {
                // since id is null here, we are likely adding a new scan policy that doesn't have an id yet
                // we we are simply interested if any scan policy exists with this priority already
                if (existingId !== null) {
                    // an existing scan policy has this priority already
                    inUse = true;
                }
            }

            if (inUse) {
                fieldIsValidCallback(false);
                fieldErrorMessageCallback("This priority is in use by another scan policy, please pick another priority");
            }
            else {
                fieldIsValidCallback(true);
                fieldErrorMessageCallback("");
            }
        });
}
