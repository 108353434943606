import IPCIDR from "ip-cidr";
export const PC_DIRECTION_INBOUND = "inbound";
export const PC_DIRECTION_OUTBOUND = "outbound";

export const PC_ENDPOINT_SOURCE = "source";
export const PC_ENDPOINT_DEST = "destination";

/**
 * 
 * @returns an array with values suitable for populating a Select component with available IP versions
 */
export function getIPVersions() {
    let ret = [];

    ret.push({ key: "4", value: "IPv4" });
    ret.push({ key: "6", value: "IPv6" });

    return ret;
}

/**
 * Returns an array of what is considered valid values for
 * the direction field of a manual rule.
 * 
 * @returns Array of valid directions
 */
export function getValidDirections() {
    let ret = [];

    ret.push(PC_DIRECTION_INBOUND);
    ret.push(PC_DIRECTION_OUTBOUND);
    return ret;
}

/**
 * Returns an array of what is considered valid values for
 * the direction field of a manual rule.
 * 
 * @returns Array of valid directions
 */
export function getValidEndpoints() {
    let ret = [];

    ret.push(PC_ENDPOINT_SOURCE);
    ret.push(PC_ENDPOINT_DEST);

    return ret;
}

/**
 * Validate that the input would be valid as a traffic direction
 * @param {*} input 
 */
export function isValidDirection(input) {
    let ret = { res: true, msg: "" };

    if (input === null || input === undefined) {
        // null input is considered "any" port
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is considered "any" port
        return ret;
    }

    let validDirections = getValidDirections();
    if (validDirections.indexOf(trimmedInput) === -1) {
        // supplied input was not in our array of valid
        // directions
        ret.res = false;
        ret.msg = "Direction must be one of: " + PC_DIRECTION_INBOUND
            + ", " + PC_DIRECTION_OUTBOUND;
    }
    return ret;
}

/**
 * Validate that the input is a valid CIDR network block of the ip version
 * specified.
 * 
 * @param {*} ipVersion ip version the input must be a cidr block for
 * @param {*} input the value to be checked
 * @param {*} protocol optional protocol that may trigger additional validation if supplied
 */
export function isValidIpVersionCidr(ipVersion, input, protocol) {
    let ret = { res: true, msg: "" };

    if (input === null || input === undefined) {
        // null input is considered "any"
        return ret;
    }

    let trimmedInput = input;
    if (trimmedInput === '') {
        // blank input is considered "any" port
        return ret;
    }

    if (trimmedInput === 'any') {
        // any is also valid
        return ret;
    }

    // use ip-cidr so we can check the input is a cidr block and if so
    // what ip version
    // const isCidr = require("is-cidr");

    let cidrResult;

    let ipVersionInt = parseInt(ipVersion);

    try {
        cidrResult = IPCIDR.isValidCIDR(trimmedInput);
        if (cidrResult) {
            // it says it's a valid IPv4 or IPv6 CIDR block, we still need to check some things
            const parsed = new IPCIDR(trimmedInput);
            const address = parsed.address;
            const addressStart = parsed.addressStart;

            const addrBytes = JSON.stringify(address.parsedAddress);
            const addrStartBytes = JSON.stringify(addressStart.parsedAddress);

            switch (ipVersionInt) {
                case 4:
                    if (!address.v4) {
                        // the cidr block is not IPv4
                        ret.res = false;
                        ret.msg = "The cidr block specifed is not IPv4";
                        return ret;
                    }
                    if (addrBytes !== addrStartBytes) {
                        // the cidr block specified has host bits set
                        ret.res = false;
                        ret.msg = "The cidr block has host bits specified";
                    }

                    if (protocol !== null && protocol !== undefined) {
                        // protocol was supplied so do additional validation
                        if (protocol !== "IP") {
                            // protocols other than IP require a single ip, e.g. /32 or /128
                            if (address.subnet !== "/32") {
                                ret.res = false;
                                ret.msg = "The protocol IP rquires cidr block to have mask of /32";
                            }
                        }
                    }
                    break;
                case 6:
                    if (address.v4) {
                        // the cidr block is not IPv6
                        ret.res = false;
                        ret.msg = "The cidr block specifed is not IPv6";
                        return ret;
                    }
                    if (addrBytes !== addrStartBytes) {
                        // the cidr block specified has host bits set
                        ret.res = false;
                        ret.msg = "The cidr block has host bits specified";
                    }
                    if (protocol !== null && protocol !== undefined) {
                        // protocol was supplied so do additional validation
                        if (protocol !== "IP") {
                            // protocols other than IP require a single ip, e.g. /32 or /128
                            if (address.subnet !== "/128") {
                                ret.res = false;
                                ret.msg = "The protocol IP rquires cidr block to have mask of /128";
                            }
                        }
                    }
                    break;
                default:
                    // address is not v4 or v6
                    ret.res = false;
                    ret.msg = "The ip block entered is not neither IPv4 or IPv6";
            }
        } else {
            // input couldn't be parsed as a cidr block
            ret.res = false;
            ret.msg = "The value entered isn't a valid cidr block";
        }
    } catch {
        // input couldn't be parsed as a cidr block
        ret.res = false;
        ret.msg = "The value entered couldn't be parsed as a cidr block";
    }

    return ret;
}


export async function checkDuplicateManualRuleRow
    (fetchUrl, keycloakToken, modalRuleId,
        modalRuleSourceAddress,
        modalRuleDestAddress, modalRuleDirection,
        modalRuleValidCallBack,
        modalRuleValidMessageCallback
    ) {

    await fetch(fetchUrl
        + '?limit=1000', {
        method: 'GET',
        headers: {
            'access-token': keycloakToken
        },
    })
        .then((response) => response.json())
        .then((respData) => {

            let ruleValid = true;
            let ruleValidMessage = "Rule is valid";


            let modalCompareString =
                `${modalRuleSourceAddress}${modalRuleDestAddress}`
                + `${modalRuleDirection}`;

            for (let i = 0; i < respData.results.length; i++) {
                let entry = respData.results[i];
                let existingRuleId = entry['id'];
                let existingRuleSourceAddress = entry['source_address'];
                let existingRuleDestAddress = entry['destination_address'];
                let existingRuleDirection = entry['direction'];

                let existingCompareString =
                    `${existingRuleSourceAddress}${existingRuleDestAddress}`
                    + `${existingRuleDirection}`;

                // db has this constraint
                // (source_address, destination_address, protocol, source_port, destination_port, direction, action)

                if (modalCompareString === existingCompareString) {
                    // we have a rule in db that's exactly the same
                    // as this one
                    // let's check the id
                    if (modalRuleId === null) {
                        // this is an add, not an edit, so we definitely shouldn't
                        // allow this one to be added
                        ruleValid = false;
                        ruleValidMessage = "This rule conflicts with existing rule " + existingRuleId;
                        break;
                    }
                    else {
                        // user is editing an existing rule
                        // check if modelRuleId is equal to existingRuleId
                        // If so, the user is probably editing this rule so do not consider this
                        // invalid
                        if (modalRuleId === existingRuleId) {
                            // this is ok
                        }
                        else {
                            // this rule would conflict
                            ruleValid = false;
                            ruleValidMessage = "This rule chagnes would conflict with existing rule " + existingRuleId;
                            break;
                        }
                    }
                }
            }
            modalRuleValidCallBack(ruleValid);
            modalRuleValidMessageCallback(ruleValidMessage);
        });
};
