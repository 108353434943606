import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols
}
    from '../../common/table/TableHelper';
import SingleSelectTableHead from '../../common/table/SingleSelectTableHead';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import {
    LOGI_ROLE_MANUAL_RULES_ADD,
    LOGI_ROLE_MANUAL_RULES_DELETE,
    LOGI_ROLE_MANUAL_RULES_EDIT,
    LOGI_ROLE_MANUAL_RULES_READ,
} from '../../common/LogiRoles';
import { coalesce } from '../../common/utility/DataUtil';

const NULL_ADDRESS_PORT_PROTOCOL_REPLACEMENT = "any";

const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Id',
        hide: true,
    },
    {
        id: 'ip_version',
        numeric: true,
        disablePadding: false,
        label: 'IP version',
        hide: false,
    },
    {
        id: 'protocol',
        numeric: false,
        disablePadding: true,
        label: 'Protocol',
        hide: false,
    },
    {
        id: 'source_address',
        numeric: true,
        disablePadding: true,
        label: 'SRC Address',
        hide: false,
    },
    {
        id: 'source_port',
        numeric: true,
        disablePadding: true,
        label: 'SRC Port',
        hide: false,
    },
    {
        id: 'destination_address',
        numeric: false,
        disablePadding: true,
        label: 'DST Address',
        hide: false,
    },
    {
        id: 'destination_port',
        numeric: true,
        disablePadding: true,
        label: 'DST Port',
        hide: false,
    },
    {
        id: 'bidirectional',
        numeric: false,
        disablePadding: true,
        label: 'Bidirectional',
        hide: false,
    },
    {
        id: 'direction',
        numeric: false,
        disablePadding: true,
        label: 'Direction',
        hide: false,
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: true,
        label: 'Action',
        hide: false,
    },
    {
        id: 'created',
        numeric: false,
        disablePadding: true,
        label: 'Created',
        hide: false,
    },
];

const hiddenCols = populateHiddenCols(headCells);

const getRowCells = (row, labelId, isItemSelected, handleClick) => {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("ip_version") ? (<TableCell align="left">{row.ip_version}</TableCell>) : ''}
            {!hiddenCols.has("protocol") ? (<TableCell align="left">{coalesce(row.protocol, NULL_ADDRESS_PORT_PROTOCOL_REPLACEMENT)}</TableCell>) : ''}
            {!hiddenCols.has("source_address") ? (<TableCell align="left">{coalesce(row.source_address, NULL_ADDRESS_PORT_PROTOCOL_REPLACEMENT)}</TableCell>) : ''}
            {!hiddenCols.has("source_port") ? (<TableCell align="left">{coalesce(row.source_port, NULL_ADDRESS_PORT_PROTOCOL_REPLACEMENT)}</TableCell>) : ''}
            {!hiddenCols.has("destination_address") ? (<TableCell align="left">{coalesce(row.destination_address, NULL_ADDRESS_PORT_PROTOCOL_REPLACEMENT)}</TableCell>) : ''}
            {!hiddenCols.has("destination_port") ? (<TableCell align="left">{coalesce(row.destination_port, NULL_ADDRESS_PORT_PROTOCOL_REPLACEMENT)}</TableCell>) : ''}
            {!hiddenCols.has("bidirectional") ? (<TableCell align="left">{row.bidirectional ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("direction") ? (<TableCell align="left">{row.direction}</TableCell>) : ''}
            {!hiddenCols.has("action") ? (<TableCell align="left">{row.action}</TableCell>) : ''}
            {!hiddenCols.has("created") ? (<TableCell align="left">{row.created}</TableCell>) : ''}
        </TableRow>
    )
};

function FilterWidget(props) {
    const { searchTerm,
        onSearchClick,
        onResetClick,
        onSearchTermChange,
        onSearchMrVersionChange,
        defaultMrSearchOption,
        searchMrVersion,
        mrSearchOptions
    } = props;

    return (
        <form onSubmit={onSearchClick}>
            <div id="manualrules-filter-widget-div" class="manualrules-filter-widget-div">
                <div class="manualrules-filter-widget-body-div">
                    <Stack spacing={2} direction="column">
                        <Stack id="manualrules-filter-widget-control-stack" spacing={2} direction="row">
                            <TextField id="manualrules-filter-widget-search-input"
                                label="Search"
                                variant="outlined"
                                size="medium"
                                value={searchTerm}
                                onChange={onSearchTermChange}
                                sx={{ m: 1, minWidth: 100, maxWidth: 410 }}
                                fullWidth
                            />
                            <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 175 }}>
                                <InputLabel id="mr-filter-widget-iap-location-select-label">IP Filter</InputLabel>
                                <Select
                                    labelId="mr-filter-widget-select-label"
                                    id="mr-table-edit-modal-dialog-iap-location-select"
                                    label="IP Filter"
                                    onChange={onSearchMrVersionChange}
                                    defaultValue={defaultMrSearchOption}
                                    value={searchMrVersion}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultMrSearchOption === searchMrVersion ? true : false}
                                        value={defaultMrSearchOption}>{defaultMrSearchOption}</MenuItem>
                                    {mrSearchOptions.map((ipVersion) => {
                                        let isSelected = ipVersion === searchMrVersion;
                                        return (
                                            <MenuItem selected={isSelected} value={ipVersion}>{ipVersion}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack spacing={2} direction="row">
                            <Button id="manualrules-filter-widget-search-button" variant="contained" button type="submit">Search</Button>
                            <Button id="manualrules-filter-widget-reset-button" variant="contained" onClick={onResetClick}>Reset</Button>
                        </Stack>
                    </Stack>
                </div>
            </div>
        </form>
    );
};

export default function ManualRulesTab(props) {
    const {
        handleRowSelected,
        addButtonEnabled,
        addButtonClickHandler,
        editButtonEnabled,
        editButtonClickHandler,
        deleteButtonEnabled,
        deleteButtonClickHandler,
        order,
        orderSetter,
        orderBy,
        orderBySetter,
        selected,
        selectedSetter,
        offset,
        offsetSetter,
        page,
        pageSetter,
        rows,
        total,
        totalSetter,
        searchTerm,
        searchTermSetter,
        rowsPerPage,
        fetchData,
        refreshButtonClickHandler,
        searchMrVersionSetter,
        defaultMrSearchOption,
        searchMrVersion,
        mrSearchOptions
    } = props;

    const { keycloak } = useKeycloak();

    let hasManualRulesRead = keycloak.hasRealmRole(LOGI_ROLE_MANUAL_RULES_READ);
    let hasManualRulesAdd = keycloak.hasRealmRole(LOGI_ROLE_MANUAL_RULES_ADD);
    let hasManualRulesEdit = keycloak.hasRealmRole(LOGI_ROLE_MANUAL_RULES_EDIT);
    let hasManualRulesDelete = keycloak.hasRealmRole(LOGI_ROLE_MANUAL_RULES_DELETE);

    useEffect(() => {
        handleResetButtonClick();
        // fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy() === property && order === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        fetchData();
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleSearchTermChange = (event) => {
        searchTermSetter(event.target.value);
    };
    const handleSearchButtonClick = (event) => {
        event.preventDefault();
        fetchData();
    };

    function handleResetButtonClick(event) {
        offsetSetter(0);
        pageSetter(0);
        totalSetter(0);
        orderSetter('asc');
        orderBySetter('source_address');
        searchTermSetter('');
        selectedSetter([]);
        handleRowSelected(0);
        searchMrVersionSetter("Both");
        fetchData();
    }

    const handleChangePage = (event, newPage) => {

        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        selectedSetter([]);
        handleRowSelected(0);
        pageSetter(newPage);
        // fetch the next page's data
        fetchData();
    };

    const handleRowClick = (event, id) => {
        if (selected[0] === id) {
            handleRowSelected(0);
            selectedSetter([]);
        } else {
            let newSelected = [id];
            handleRowSelected(id);
            selectedSetter(newSelected);
        }
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };


    const handleDeleteManualRuleClick = (event) => {
        deleteButtonClickHandler(fetchData);
    };

    const handleSearchMrVersionChange = (event) => {
        // console.log("Version change: ", event.target.value);
        searchMrVersionSetter(event.target.value);
    };

    if (hasManualRulesRead) {
        return (
            <div id="manual-rules-table-top-level-div">
                <div id="manual-rules-table-button-bar-div">
                    <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Manual Rules Search / Filter</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2} direction="column">
                                <Stack spacing={2} direction="row">
                                    <Button
                                        id="manual-rules-table-refresh-button"
                                        variant="contained"
                                        onClick={refreshButtonClickHandler}>Refresh</Button>
                                </Stack>
                                <FilterWidget
                                    searchTerm={searchTerm}
                                    onSearchClick={handleSearchButtonClick}
                                    onResetClick={handleResetButtonClick}
                                    onSearchTermChange={handleSearchTermChange}
                                    onSearchMrVersionChange={handleSearchMrVersionChange}
                                    defaultMrSearchOption={defaultMrSearchOption}
                                    searchMrVersion={searchMrVersion}
                                    mrSearchOptions={mrSearchOptions}
                                />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div id="manual-rules-table-div">
                    <Stack spacing={2} direction="row">
                        <Box sx={{ width: '100%' }} id="manual_table_box">
                            <TableContainer>
                                <Table
                                    sx={{ width: '100%' }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    id="manual-rules-table"
                                >
                                    <SingleSelectTableHead
                                        numSelected={selected.length}
                                        order={order()}
                                        orderBy={orderBy()}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                        fetchMethod={fetchData}
                                        id="manual-rules-table-head"
                                    />
                                    <TableBody id="manual-rules-table-body">
                                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                        {rows.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `manual-rules-table-checkbox-${index}`;

                                            return (
                                                getRowCells(row, labelId, isItemSelected, handleRowClick)
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    // height: 33 * emptyRows,
                                                    height: 33,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[rowsPerPage]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                labelDisplayedRows={defaultLabelDisplayedRows}
                            />
                            <EnhancedTableToolbar numSelected={selected.length} />
                        </Box>
                        <div class="manual-rules-table-buttons-div">
                            <Stack spacing={2} direction="column">
                                {hasManualRulesAdd ? (<Button id="manual-rules-table-add-button"
                                    variant="contained"
                                    onClick={addButtonClickHandler}
                                    disabled={!addButtonEnabled}>Add</Button>) : (<p />)}
                                {hasManualRulesEdit ? (<Button id="manual-rules-table-edit-button"
                                    variant="contained"
                                    onClick={editButtonClickHandler}
                                    disabled={!editButtonEnabled}>Edit</Button>) : (<p />)}
                                {hasManualRulesDelete ? (<Button id="manual-rules-table-delete-button"
                                    variant="contained"
                                    onClick={handleDeleteManualRuleClick}
                                    disabled={!deleteButtonEnabled}>Delete</Button>) : (<p />)}
                            </Stack>
                        </div>
                    </Stack>
                </div>
            </div>
        );
    }
    else {
        return (<div />);
    }
}
