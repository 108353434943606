import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function ConfirmBulkBlockAsnsDialog(props) {
    const {
        open,
        asnsToBlock,
        onCancel,
        onClose,
        onConfirm
    } = props;

    const handleCancelButtonClick = (event) => {
        onCancel();
    };

    const handleConfirmButtonClick = (event) => {
        onConfirm();
    };

    const handleModalClose = (event) => {
        onClose();
    };

    const asnSetAsArray = () => {
        let asnList = [];
        asnsToBlock().forEach((asn) => {
            asnList.push(asn);
        });
        asnList.sort();
        return asnList;
    };

    return (
        <Dialog onClose={handleModalClose} open={open}>
            <DialogTitle>Block these ASNs?</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <div height="1rem" />
                    <p>You are about to block the following AS Numbers.  Are you sure?</p>
                    <ul id="asn-tab-confirm-block-modal-dialog-id-list-ul">
                        {asnSetAsArray().map((asn) => { return (<li>{asn}</li>) })}
                    </ul>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="asn-tab-confirm-block-modal-dialog-add-button"
                            variant="contained"
                            onClick={handleConfirmButtonClick}>Confirm</Button>
                        <Button id="asn-tab-confirm-block-modal-dialog-cancel-button"
                            variant="contained"
                            onClick={handleCancelButtonClick}>Cancel</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
