import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { LOGI_ROLE_COUNTRIES_PLUS_READ } from '../../../common/LogiRoles';
import CountriesPlusTab from './CountriesPlusTab';
import CountryPlusDialog from './modals/CountryPlusDialog';
import "./CountriesPlusTable.css";
import { fetchErrorMessage } from '../../mainpage';

const rowsPerPage = 20;
const rootFetchUrl = '/middleware/api/countriesplus/';

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`countriesplus-tabpanel-${index}`}
			aria-labelledby={`countriesplus-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `countriesplus-tab-${index}`,
		'aria-controls': `countriesplus-tabpanel-${index}`,
	};
}

function createCountryRowData(iso, iso3, iso_numeric, fips, name, capital, area, population, continent, tld, currency_code,
	currency_symbol, currency_name, phone, postal_code_format, postal_code_regex, languages, geonameid,
	neighbors, equivalent_fips_code) {
	return {
		iso,
		iso3,
		iso_numeric,
		fips,
		name,
		capital,
		area,
		population,
		continent,
		tld,
		currency_code,
		currency_symbol,
		currency_name,
		phone,
		postal_code_format,
		postal_code_regex,
		languages,
		geonameid,
		neighbors,
		equivalent_fips_code
	};
}

// needed so that reset button doesn't have to be clicked twice
let freshSearchTerm = '';

function CountriesPlusComponent(props) {

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	const [tabIndex, setTabIndex] = React.useState(0);

	// snackbar state vars
	const [snackState,] = React.useState({
		snackbarVertical: 'bottom',
		snackbarHorizontal: 'center',
	});

	const { snackbarHorizontal, snackbarVertical } = snackState;

	const [countriesPlusRefreshNotificationOpen, setCountriesPlusRefreshNotificationOpen] = useState(false);
	const countriesPlusRefreshNotificationMessage = "Countries Plus Data Refreshed";
	const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
	const handleFetchErrorClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setFetchErrorMessageOpen(false);
	};

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const [selected, setSelected] = useState([]);
	const [offset, setOffset] = useState(0);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState(0);
	const [, setSearchTerm] = useState('');

	// selected country related
	const [modalOpen, setModalOpen] = useState(false);
	const [viewButtonEnabled, setViewButtonEnabled] = useState(false);
	const [selectedIso, setSelectedIso] = useState('');
	const [selectedIso3, setSelectedIso3] = useState('');
	const [selectedIsoNumeric, setSelectedIsoNumeric] = useState(0);
	const [selectedFips, setSelectedFips] = useState('');
	const [selectedName, setSelectedName] = useState('');
	const [selectedCapital, setSelectedCapital] = useState('');
	const [selectedArea, setSelectedArea] = useState(0);
	const [selectedPopulation, setSelectedPopulation] = useState(0);
	const [selectedContinent, setSelectedContinent] = useState('');
	const [selectedTld, setSelectedTld] = useState('');
	const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(0);
	const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState('');
	const [selectedCurrencyName, setSelectedCurrencyName] = useState('');
	const [selectedPhone, setSelectedPhone] = useState(0);
	const [selectedPostalCodePattern, setSelectedPostalCodePattern] = useState('');
	const [selectedPostalCodeRegex, setSelectedPostalCodeRegex] = useState('');
	const [selectedLanguages, setSelectedLanguages] = useState('');
	const [selectedNeighbours, setSelectedNeighbours] = useState('');

	// setting up a global search state var
	const [searchSwitch, setSearchSwitch] = useState(false)

	useEffect(() => {
		fetchData();
	}, [searchSwitch])

	const { keycloak } = useKeycloak();

	const hasCountriesPlusRead = keycloak.hasRealmRole(LOGI_ROLE_COUNTRIES_PLUS_READ);

	const getFreshSearchTerm = () => {
		// TODO: we might need to url-encode the search term here
		return freshSearchTerm;
	};

	const getSanitizedSearchFieldContent = () => {
		let ret = getFreshSearchTerm();
		// TODO: we might need to url-encode the search term here
		return ret;
	};

	const fetchData = async () => {
		// todo, do URLencoding on this to avoid any wonky input
		const sanitizedSearchTerm = getSanitizedSearchFieldContent();
		const orderPrefix = (order === 'desc' ? '-' : '');

		await fetch(rootFetchUrl
			+ '?limit=' + rowsPerPage
			+ '&offset=' + offset
			+ '&page=' + (page + 1)
			+ (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
			+ '&ordering=' + orderPrefix + orderBy, {
			method: 'GET',
			headers: {
				'access-token': keycloak.token
			},
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				setTotal(respData.count);

				let tmpRows = [];
				for (let i = 0; i < respData.results.length; i++) {
					let entry = respData.results[i];
					tmpRows.push(createCountryRowData(entry['iso'], entry['iso3'], entry['iso_numeric'], entry['fips'],
						entry['name'], entry['capital'], entry['area'], entry['population'], entry['continent'],
						entry['tld'], entry['currency_code'], entry['currency_symbol'], entry['currency_name'],
						entry['phone'], entry['postal_code_format'], entry['postal_code_regex'],
						entry['languages'], entry['geonameid'], entry['neighbors'], entry['equivalent_fips_code']));
				}

				setSelected([]);
				setRows(tmpRows);
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
		setSearchSwitch(false)
	};

	const fetchDataForSelected = async () => {
		await fetch(rootFetchUrl + selectedIso + '/'
			, {
				method: 'GET',
				headers: {
					'access-token': keycloak.token
				},
			})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				setSelectedIso(respData['iso']);
				setSelectedIso3(respData['iso3']);
				setSelectedIsoNumeric(respData['iso_numeric']);
				setSelectedFips(respData['fips']);
				setSelectedName(respData['name']);
				setSelectedCapital(respData['capital']);
				setSelectedArea(respData['area']);
				setSelectedPopulation(respData['population']);
				setSelectedContinent(respData['continent']);
				setSelectedTld(respData['tld']);
				setSelectedCurrencyCode(respData['currency_code']);
				setSelectedCurrencySymbol(respData['currency_symbol']);
				setSelectedCurrencyName(respData['currency_name']);
				setSelectedPhone(respData['phone']);
				setSelectedPostalCodePattern(respData['postal_code_format']);
				setSelectedPostalCodeRegex(respData['postal_code_regex']);
				setSelectedLanguages(respData['languages']);
				// geonameid
				setSelectedNeighbours(respData['neighbors']);
				// equivalent_fips_code
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setViewButtonEnabled(false);
		setSelected([]);
	};

	const handleViewRowButtonClick = (event) => {
		fetchDataForSelected();
		setModalOpen(true);
	};

	const handleSearchTermChange = (val) => {
		setSearchTerm(val);
		freshSearchTerm = val;
	};

	const handleSingleSelection = (val) => {
		setSelectedIso(val);

		if (val !== null) {
			setViewButtonEnabled(true)
		}
		else {
			setViewButtonEnabled(false);
		}
	};

	const handleCountriesPlusRefreshNotificationClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setCountriesPlusRefreshNotificationOpen(false);
	};

	const displayCountriesPlusRefreshStarted = (event) => {
		setCountriesPlusRefreshNotificationOpen(true);
	};

	const handleCountriesPlusRefreshButtonClick = (event) => {
		fetchData();
		displayCountriesPlusRefreshStarted();
	};

	const getOrderBy = () => {
		return orderBy;
	}

	const getOrder = () => {
		return order;
	}

	if (hasCountriesPlusRead) {
		return (
			<Box sx={{ width: '100%' }} id="countriesplus-top-level-box">
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Snackbar
						open={countriesPlusRefreshNotificationOpen}
						autoHideDuration={notificationAutoclose}
						onClose={handleCountriesPlusRefreshNotificationClosed}
						anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
					>
						<MuiAlert
							className="logi-snackbar-notification-message"
							severity="info"
							variant="filled"
							sx={{ width: '100%' }}>
							{countriesPlusRefreshNotificationMessage}
						</MuiAlert>
					</Snackbar>
					<Snackbar
						open={fetchErrorMessageOpen}
						autoHideDuration={notificationAutoclose}
						onClose={handleFetchErrorClosed}
						anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
					>
						<MuiAlert
							className="logi-snackbar-notification-message"
							severity="info"
							variant="filled"
							sx={{ width: '100%' }}>
							{fetchErrorMessage}
						</MuiAlert>
					</Snackbar>
					<CountryPlusDialog
						open={modalOpen}
						onClose={handleModalClose}
						iso={selectedIso}
						iso3={selectedIso3}
						iso_numeric={selectedIsoNumeric}
						fips={selectedFips}
						name={selectedName}
						capital={selectedCapital}
						area={selectedArea}
						population={selectedPopulation}
						continent={selectedContinent}
						tld={selectedTld}
						currency_code={selectedCurrencyCode}
						currency_symbol={selectedCurrencySymbol}
						currency_name={selectedCurrencyName}
						phone={selectedPhone}
						postal_code_format={selectedPostalCodePattern}
						postal_code_regex={selectedPostalCodeRegex}
						languages={selectedLanguages}
						neighbours={selectedNeighbours}
					/>
					<Tabs value={tabIndex} onChange={handleTabChange} aria-label="Countries Plus Tab Panel">
						<Tab label="Countries Plus" {...a11yProps(0)} />
					</Tabs>
				</Box>
				<TabPanel value={tabIndex} index={0}>
					<CountriesPlusTab
						viewButtonEnabled={viewButtonEnabled}
						viewButtonClickListener={handleViewRowButtonClick}
						singleSelectionHandler={handleSingleSelection}
						order={getOrder}
						orderSetter={setOrder}
						orderBy={getOrderBy}
						orderBySetter={setOrderBy}
						page={page}
						pageSetter={setPage}
						offset={offset}
						offsetSetter={setOffset}
						selected={selected}
						setSearchSwitch={setSearchSwitch}
						selectedSetter={setSelected}
						searchTerm={getFreshSearchTerm()}
						searchTermSetter={handleSearchTermChange}
						rows={rows}
						total={total}
						fetchData={fetchData}
						rowsPerPage={rowsPerPage}
						refreshButtonClickHandler={handleCountriesPlusRefreshButtonClick}
					/>
				</TabPanel>
			</Box>
		);
	}
	else {
		return (<div />);
	}
};

export default CountriesPlusComponent;
