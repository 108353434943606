import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols
}
    from '../../common/table/TableHelper';
import PaginatedTableHead from '../../common/table/PaginatedTableHead';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import { LOGI_ROLE_ASN_BLOCK_ADD } from '../../common/LogiRoles';
import { LOGI_ROLE_ASN_FETCH } from '../../common/LogiRoles';

// const asnSubnetsTabSelectedAsns = new Map();

const headCells = [
    {
        id: 'ip_address',
        numeric: false,
        disablePadding: true,
        label: 'IP Address',
        hide: false,
    },
    {
        id: 'organization',
        numeric: false,
        disablePadding: true,
        label: 'Organization',
        hide: false,
    },
    {
        id: 'isp',
        numeric: false,
        disablePadding: true,
        label: 'ISP',
        hide: false,
    },
    {
        id: 'autonomous_system_number',
        numeric: true,
        disablePadding: true,
        label: 'Autonomous System Number',
        hide: false,
    },
    {
        id: 'autonomous_system_organization',
        numeric: false,
        disablePadding: true,
        label: 'Autonomous System Organization',
        hide: false,
    },
    {
        id: 'source',
        numeric: false,
        disablePadding: true,
        label: 'Source',
        hide: false,
    },
];

const hiddenCols = populateHiddenCols(headCells);

function getRowCells(row, labelId, isItemSelected, handleClick) {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.ip_address}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("ip_address") ? (<TableCell component="td" id={labelId} scope="row" padding="none">{row.ip_address}</TableCell>) : ''}
            {!hiddenCols.has("organization") ? (<TableCell align="left">{row.organization}</TableCell>) : ''}
            {!hiddenCols.has("isp") ? (<TableCell align="left">{row.isp}</TableCell>) : ''}
            {!hiddenCols.has("autonomous_system_number") ? (<TableCell align="left">{row.autonomous_system_number}</TableCell>) : ''}
            {!hiddenCols.has("autonomous_system_organization") ? (<TableCell align="left">{row.autonomous_system_organization}</TableCell>) : ''}
            {!hiddenCols.has("source") ? (<TableCell align="left">{row.source}</TableCell>) : ''}
        </TableRow>
    )
}

function FilterWidget(props) {
    const { asnFetchRunning,
        searchTerm,
        onSearchClick,
        onResetClick,
        onSearchTermChange,
        onSearchAsnVersionChange,
        defaultAsnSearchOption,
        searchAsnVersion,
        asnSearchOptions,
        searchFilter,
        defaultAsnSearchFilterOption,
        onSearchAsnSearchFilterChange,
        asnSearchFilterOptions,
        onSourceChange,
        sourceFilter,
        sourceOptions,
        defaultAsnSourceOption,
    } = props;

    return (
        <form onSubmit={onSearchClick}>
            <div id="autonomous-system-number-filter-widget-div" class="asn-subnets-filter-widget-div">
                <div class="autonomous-system-number-filter-widget-body-div">
                    <Stack spacing={2} direction="column">
                        <Stack id="autonomous-system-number-filter-widget-control-stack" spacing={2} direction="row">
                            <TextField id="autonomous-system-number-filter-widget-search-input"
                                label="Search"
                                variant="outlined"
                                size="medium"
                                value={searchTerm}
                                onChange={onSearchTermChange}
                                sx={{ m: 1, minWidth: 100, maxWidth: 410 }}
                                fullWidth
                            />
                            <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 175 }}>
                                <InputLabel id="asn-filter-widget-search-filter-select-label">Search Filter</InputLabel>
                                <Select
                                    labelId="asn-filter-widget-search-filter-select-label"
                                    id="asn-filter-widget-search-filter-select"
                                    label="Search Filter"
                                    onChange={onSearchAsnSearchFilterChange}
                                    defaultValue={defaultAsnSearchFilterOption}
                                    value={searchFilter}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultAsnSearchFilterOption === searchFilter ? true : false}
                                        value={defaultAsnSearchFilterOption}>{defaultAsnSearchFilterOption}</MenuItem>
                                    {asnSearchFilterOptions.map((searchCol) => {
                                        let isSelected = searchCol === searchFilter;
                                        return (
                                            <MenuItem selected={isSelected} value={searchCol}>{searchCol}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 175 }}>
                                <InputLabel id="asn-filter-widget-ip-filter-select-label">IP Filter</InputLabel>
                                <Select
                                    labelId="asn-filter-widget-ip-filter-select-label"
                                    id="asn-filter-widget-ip-filter-select"
                                    label="IP Filter"
                                    onChange={onSearchAsnVersionChange}
                                    defaultValue={defaultAsnSearchOption}
                                    value={searchAsnVersion}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultAsnSearchOption === searchAsnVersion ? true : false}
                                        value={defaultAsnSearchOption}>{defaultAsnSearchOption}</MenuItem>
                                    {asnSearchOptions.map((ipVersion) => {
                                        let isSelected = ipVersion === searchAsnVersion;
                                        return (
                                            <MenuItem selected={isSelected} value={ipVersion}>{ipVersion}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 175 }}>
                                <InputLabel id="asn-filter-widget-source-filter-select-label">Source Filter</InputLabel>
                                <Select
                                    labelId="asn-filter-widget-source-filter-select-label"
                                    id="asn-filter-widget-source-filter-select"
                                    label="Source Filter"
                                    onChange={onSourceChange}
                                    defaultValue={defaultAsnSourceOption}
                                    value={sourceFilter}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultAsnSourceOption === sourceFilter ? true : false}
                                        value={defaultAsnSourceOption}>{defaultAsnSourceOption}</MenuItem>
                                    {sourceOptions.map((sourceCol) => {
                                        let isSelected = sourceCol === sourceFilter;
                                        return (
                                            <MenuItem selected={isSelected} value={sourceCol}>{sourceCol}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack spacing={2} direction="row">
                            {asnFetchRunning ?
                                <Button id="autonomous-system-number-filter-widget-search-button" variant="contained" button type="submit">Search</Button> :
                                <Button id="autonomous-system-number-filter-widget-search-button" variant="contained" disabled button type="submit">Search</Button>
                            }
                            <Button id="autonomous-system-number-filter-widget-reset-button" variant="contained" onClick={onResetClick}>Reset</Button>
                        </Stack>
                    </Stack>
                </div>
            </div>
        </form>
    );
};

export default function AutonomousSystemNumbersTab(props) {
    const {
        asnFetchRunning,
        rowSelectionChanged,
        blockButtonEnabled,
        blockButtonClickListener,
        order,
        orderSetter,
        orderBy,
        orderBySetter,
        offset,
        offsetSetter,
        page,
        pageSetter,
        rows,
        rowSetter,
        total,
        totalSetter,
        searchAsnVersion,
        asnSearchOptions,
        defaultAsnSearchOption,
        searchAsnVersionSetter,
        selected,
        selectedSetter,
        searchTerm,
        searchTermSetter,
        setAsnSwitch,
        fetchData,
        rowsPerPage,
        refreshButtonClickHandler,
        dbpull,
        adhocPollButtonClickListener,
        asnSubnetsTabSelectedAsns,
        searchFilter,
        searchFilterSetter,
        defaultAsnSearchFilterOption,
        asnSearchFilterOptions,
        asnSearchFilterChange,
        sourceOptions,
        defaultAsnSourceOption,
        sourceFilterSetter,
        sourceFilter
    } = props;

    const { keycloak } = useKeycloak();
    const hasASNBlockAdd = keycloak.hasRealmRole(LOGI_ROLE_ASN_BLOCK_ADD);
    const hasAsnFetch = keycloak.hasRealmRole(LOGI_ROLE_ASN_FETCH);

    useEffect(() => {
        handleResetButtonClick();
        // fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy() === property && order() === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        setAsnSwitch(true);
    };

    const isSelected = (ip_address) => {
        return selected.indexOf(ip_address) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleChangePage = (event, newPage) => {
        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        selectedSetter([]);
        pageSetter(newPage);
        setAsnSwitch();
    };

    const handleSelectAllClick = (event) => {
        let distinctAsns = new Set();

        let newSelected = [];

        if (event.target.checked) {
            rows.forEach((row) => {
                newSelected.push(row.ip_address);

                if (row.autonomous_system_number !== null && !distinctAsns.has(row.autonomous_system_number)) {
                    distinctAsns.add(row.autonomous_system_number);
                }
            });

            selectedSetter(newSelected);
            rowSelectionChanged(newSelected, distinctAsns);
            return;
        }
        selectedSetter([]);
        rowSelectionChanged([], distinctAsns);
    };

    const handleRowClick = (event, row) => {

        const selectedIndex = selected.indexOf(row.ip_address);
        let newSelected = [];

        // The list of distinct asns in the current selection are in asnSubnetsTabSelectedAsns which
        // is a map of asn -> one or more subnets that map to that asn
        // since we are selecting/deselecting a row we'll have to update
        // asnSubnetsTabSelectedAsns accordingly if the row being selected/deslected
        // is or is not the only one mapped to the asn.
        let itemBeingUnselected = true;

        if (selectedIndex === -1) {
            // this subnet hasn't been added before, so we'll add it
            itemBeingUnselected = false;
            newSelected = newSelected.concat(selected, row.ip_address);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (itemBeingUnselected) {
            if (row.autonomous_system_number !== null) {
                // since the item is being removed we need to see what changes need
                // to be made to the map of asn -> associated subnets
                let newSubnetArray = [];
                let existingSubnetArray = asnSubnetsTabSelectedAsns.get(row.autonomous_system_number);

                // loop through and write out any existing elements EXCEPT for the one being unselected
                // to newSubnetArray
                for (const asn of existingSubnetArray) {
                    if (asn !== row.ip_address) {
                        // this is not the entry being unselected so copy to new array
                        newSubnetArray.push(asn);
                    }
                }

                // check the length of newSubnetArray
                if (newSubnetArray.length === 0) {
                    // if it's 0, remove the entry for this asn from the map
                    asnSubnetsTabSelectedAsns.delete(row.autonomous_system_number);
                }
                else {
                    // put the new subnet array back in the map
                    asnSubnetsTabSelectedAsns.set(row.autonomous_system_number, newSubnetArray);
                }
            }
        }
        else {
            // item being selected
            if (row.autonomous_system_number !== null) {
                if (asnSubnetsTabSelectedAsns.has(row.autonomous_system_number)) {
                    // the map contains this asn, so we need to add this subnet
                    let subnetArray = asnSubnetsTabSelectedAsns.get(row.autonomous_system_number);
                    subnetArray.push(row.ip_address);
                }
                else {
                    // the map of asns doesn't already have this asn
                    // so insert a new key with a value of a one element array of the
                    // subnet/ip_address 
                    asnSubnetsTabSelectedAsns.set(row.autonomous_system_number, [row.ip_address]);
                }
            }
        }

        // loop through the keys in map and use these to populate a distinct set of asn numbers
        let distinctAsns = new Set();
        for (const key of asnSubnetsTabSelectedAsns.keys()) {
            distinctAsns.add(key);
        }

        rowSelectionChanged(newSelected, distinctAsns)
        selectedSetter(newSelected);
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    const handleSearchTermChange = (event) => {
        searchTermSetter(event.target.value);
    };

    const handleSearchButtonClick = (event) => {
        event.preventDefault();
        offsetSetter(0);
        pageSetter(0);
        totalSetter(0);
        orderSetter('asc');
        orderBySetter('ip_address');
        rowSelectionChanged([], new Set());
        selectedSetter([]);
        rowSetter([]);
        fetchData();
    };

    const handleResetButtonClick = (event) => {
        offsetSetter(0);
        pageSetter(0);
        totalSetter(0);
        orderSetter('asc');
        orderBySetter('ip_address');
        searchTermSetter('');
        rowSelectionChanged([], new Set());
        selectedSetter([]);
        searchAsnVersionSetter('Both');
        searchFilterSetter('IP Address');
        sourceFilterSetter('Any');
        rowSetter([]);
        fetchData();
    };

    const handleSearchAsnVersionChange = (event) => {
        // console.log("Version change: ", event.target.value);
        searchAsnVersionSetter(event.target.value);
    };

    const handleAsnSearchFilterChange = (event) => {
        // console.log("Version change: ", event.target.value);
        searchFilterSetter(event.target.value);
    };

    const handleAsnSourceChange = (event) => {
        // console.log("Version change: ", event.target.value);
        sourceFilterSetter(event.target.value);
    };


    return (
        <div id="autonomous-system-number-table-top-level-div">
            <div id="autonomous-system-number-table-button-bar-div">
                <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>ASN Filter / Search</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2} direction="column">
                            <Stack spacing={2} direction="row">
                                <Button
                                    id="asn-table-refresh-button"
                                    variant="contained"
                                    onClick={refreshButtonClickHandler}>Refresh</Button>
                                {hasAsnFetch === true ? (<Button id="asn-adhoc-refresh-button"
                                    variant="contained"
                                    onClick={adhocPollButtonClickListener}>Adhoc ASN/RIPE Pull</Button>) : ('')}
                                <Stack spacing={2} direction="row">
                                    <span style={{ 'padding': '10px', 'margin': '10px' }}>Last Pull info: </span>
                                    <Alert severity="info">{dbpull}</Alert>
                                </Stack>
                            </Stack>
                            <FilterWidget
                                asnFetchRunning={asnFetchRunning}
                                searchTerm={searchTerm}
                                onSearchClick={handleSearchButtonClick}
                                onResetClick={handleResetButtonClick}
                                onSearchTermChange={handleSearchTermChange}
                                onSearchAsnVersionChange={handleSearchAsnVersionChange}
                                searchAsnVersion={searchAsnVersion}
                                asnSearchOptions={asnSearchOptions}
                                searchFilter={searchFilter}
                                defaultAsnSearchFilterOption={defaultAsnSearchFilterOption}
                                onSearchAsnSearchFilterChange={handleAsnSearchFilterChange}
                                onSourceChange={handleAsnSourceChange}
                                asnSearchFilterOptions={asnSearchFilterOptions}
                                sourceOptions={sourceOptions}
                                sourceFilter={sourceFilter}
                                defaultAsnSourceOption={defaultAsnSourceOption}
                                defaultAsnSearchOption={defaultAsnSearchOption}
                                dbpull={dbpull} />
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div id="autonomous-system-number-table-div">
                <Stack spacing={2} direction="row">
                    <Box sx={{ width: '100%' }} id="autonomous-system-number-table-box">
                        <TableContainer style={{ overflow: 'hidden' }}>
                            <Table
                                sx={{ width: '100%' }}
                                style={{ overflow: 'hidden' }}
                                aria-labelledby="tableTitle"
                                size={'small'}
                                id="autonomous-system-number-table"
                            >
                                <PaginatedTableHead
                                    numSelected={selected.length}
                                    order={order()}
                                    orderBy={orderBy()}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                    fetchMethod={fetchData}
                                    id="autonomous-system-number-table-head"
                                />
                                <TableBody id="autonomous-system-number-table-body">
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                    {rows.map((row, index) => {
                                        const isItemSelected = isSelected(row.ip_address);
                                        const labelId = `autonomous-system-number-table-checkbox-${index}`;

                                        return (
                                            getRowCells(row, labelId, isItemSelected, handleRowClick)
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                // height: 33 * emptyRows,
                                                height: 33,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            labelDisplayedRows={defaultLabelDisplayedRows} />
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </Box>
                    <div class="autonomous-system-number-table-buttons-div">
                        {hasASNBlockAdd ? (<Stack spacing={2} direction="column">
                            <Button id="autonomous-system-number-table-block-button"
                                variant="contained"
                                onClick={blockButtonClickListener}
                                disabled={!blockButtonEnabled}>Block</Button>
                        </Stack>) : (<p />)}
                    </div>
                </Stack>
            </div>
        </div>
    );
}
