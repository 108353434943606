import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols
}
    from '../../../common/table/TableHelper';
import EnhancedTableToolbar from '../../../common/table/EnhancedTableToolbar';
import {
    LOGI_ROLE_LICENSE_KEYS_READ,
    LOGI_ROLE_LICENSE_KEYS_WRITE
} from '../../../common/LogiRoles';
import SingleSelectTableHead from '../../../common/table/SingleSelectTableHead';

const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Id',
        hide: true,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
        hide: false,
    },
    {
        id: 'license_key',
        numeric: false,
        disablePadding: true,
        label: 'License Key',
        hide: false,
    },
    {
        id: 'updated',
        numeric: true,
        disablePadding: true,
        label: 'Updated',
        hide: false,
    },
];

const hiddenCols = populateHiddenCols(headCells);

function getRowCells(row, labelId, isItemSelected, handleClick) {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.name}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("name") ? (<TableCell component="td" id={labelId} scope="row" padding="none">{row.name}</TableCell>) : ''}
            {!hiddenCols.has("license_key") ? (<TableCell align="left">
                <TextField
                    type="password"
                    value={row.license_key}
                    label=""
                    defaultValue={row.license_key}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
            </TableCell>) : ''}
            {!hiddenCols.has("license_key") ? (<TableCell align="left">{row.updated}</TableCell>) : ''}
        </TableRow>
    )
}

export default function LicenseKeysTab(props) {
    const {
        editButtonEnabled,
        editButtonClickListener,
        onSelectRowId,
        editButtonToggle,
        order,
        orderSetter,
        orderBy,
        orderBySetter,
        selected,
        selectedSetter,
        selectedLicenseNameSetter,
        rows,
        fetchData,
        refreshButtonClickHandler
    } = props;

    const { keycloak } = useKeycloak();
    const hasLicenseKeysRead = keycloak.hasRealmRole(LOGI_ROLE_LICENSE_KEYS_READ);
    const hasLicenseKeysWrite = keycloak.hasRealmRole(LOGI_ROLE_LICENSE_KEYS_WRITE);

    useEffect(() => {
        fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        orderBySetter(property);
    };

    const handleRowClick = (event, row) => {
        if (selected[0] === row.name) {
            onSelectRowId(0);
            selectedLicenseNameSetter('');
            editButtonToggle(false);
            selectedSetter([]);
        } else {
            let newSelected = [row.name];
            onSelectRowId(row.id);
            selectedLicenseNameSetter(row.name);
            editButtonToggle(true);
            selectedSetter(newSelected);
        }
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    if (hasLicenseKeysRead) {
        return (
            <div id="license-keys-table-top-level-div">
                <div id="license-keys-table-button-bar-div">
                <Accordion style={{'borderRadius': '10px'}} defaultExpanded={true}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>License Keys Refresh</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={2} direction="row">
                        <Button
                            id="license-keys-table-refresh-button"
                            variant="contained"
                            onClick={refreshButtonClickHandler}>Refresh</Button>
                    </Stack>
                    </AccordionDetails>
                </Accordion>
                </div>
                <div id="license-keys-table-div">
                    <Stack spacing={2} direction="row">
                        <Box sx={{ width: '100%' }} id="license-keys-table-box">
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    id="license-keys-table"
                                >
                                    <SingleSelectTableHead
                                        numSelected={selected.length}
                                        order={order()}
                                        orderBy={orderBy()}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                        fetchMethod={fetchData}
                                        id="license-keys-table-head"
                                    />
                                    <TableBody id="license-keys-table-body">
                                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                        {rows.map((row, index) => {
                                            const isItemSelected = isSelected(row.name);
                                            const labelId = `license-keys-table-checkbox-${index}`;

                                            return (
                                                getRowCells(row, labelId, isItemSelected, handleRowClick)
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    // height: 33 * emptyRows,
                                                    height: 33,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div class="license-keys-table-footer-div" />
                            <EnhancedTableToolbar numSelected={selected.length} />
                        </Box>
                        <div class="license-keys-table-table-buttons-div">
                            {hasLicenseKeysWrite && (
                                <Button id="license-keys-table-edit-button"
                                    variant="contained"
                                    onClick={editButtonClickListener}
                                    disabled={!editButtonEnabled}>Edit</Button>
                            )
                            }
                        </div>
                    </Stack>
                </div>
            </div>
        );
    }
    else {
        return (<div />);
    }
}