/**
 * These roles must be defined in keycloak and either assigned to a group the user is part of
 * or they can be assigned directly to a user.
 */
export const LOGI_ROLE_ADMIN_FUNCTIONS = "logi-role-admin-functions";

export const LOGI_ROLE_CONTENT_READ = "logi-role-content-read";
export const LOGI_ROLE_CONTENT_UPLOAD = "logi-role-content-upload";
export const LOGI_ROLE_CONTENT_DOWNLOAD = "logi-role-content-download";

export const LOGI_ROLE_API_KEYS_READ = "logi-role-api-keys-read";
export const LOGI_ROLE_API_KEYS_ADD = "logi-role-api-keys-add";
export const LOGI_ROLE_API_KEYS_EDIT = "logi-role-api-keys-edit";
export const LOGI_ROLE_API_KEYS_DELETE = "logi-role-api-keys-delete";

export const LOGI_ROLE_ASN_FETCH = "logi-role-asn-fetch";
export const LOGI_ROLE_ASN_READ = "logi-role-asn-read";
export const LOGI_ROLE_ASN_BLOCK_ADD = "logi-role-asn-block-add";
export const LOGI_ROLE_ASN_BLOCK_DELETE = "logi-role-asn-block-delete";
export const LOGI_ROLE_ASN_EXCEPTIONS_READ = "logi-role-asn-exceptions-read";
export const LOGI_ROLE_ASN_EXCEPTIONS_ADD = "logi-role-asn-exceptions-add";
export const LOGI_ROLE_ASN_EXCEPTIONS_DELETE = "logi-role-asn-exceptions-delete";

export const LOGI_ROLE_ANON_FETCH = "logi-role-anon-fetch";
export const LOGI_ROLE_ANON_READ = "logi-role-anon-read";
export const LOGI_ROLE_ANON_SETTINGS_READ = "logi-role-anon-settings-read";
export const LOGI_ROLE_ANON_SETTINGS_EDIT = "logi-role-anon-settings-edit";

export const LOGI_ROLE_ANON_EXCEPTIONS_READ = "logi-role-anon-exceptions-read";
export const LOGI_ROLE_ANON_EXCEPTIONS_ADD = "logi-role-anon-exceptions-add";
export const LOGI_ROLE_ANON_EXCEPTIONS_DELETE = "logi-role-anon-exceptions-delete";

export const LOGI_ROLE_MAXMIND_EXCEPTIONS_READ = "logi-role-maxmind-exceptions-read";
export const LOGI_ROLE_MAXMIND_EXCEPTIONS_ADD = "logi-role-maxmind-exceptions-add";
export const LOGI_ROLE_MAXMIND_EXCEPTIONS_DELETE = "logi-role-maxmind-exceptions-delete";

export const LOGI_ROLE_BLL_ADVERSARY_RECONNAISSANCE_READ = "logi-role-bll-threat-actor-read";
export const LOGI_ROLE_BLL_ADVERSARY_RECONNAISSANCE_FETCH = "logi-role-bll-threat-actor-fetch";

export const LOGI_ROLE_BLL_FETCH = "logi-role-bll-fetch";
export const LOGI_ROLE_BLL_READ = "logi-role-bll-read";
export const LOGI_ROLE_BLOCKED_BLL_READ = "logi-role-blocked-bll-read";
export const LOGI_ROLE_BLOCKED_BLL_EDIT = "logi-role-blocked-bll-edit";

export const LOGI_ROLE_BLL_EXCEPTIONS_READ = "logi-role-bll-exceptions-read";
export const LOGI_ROLE_BLL_EXCEPTIONS_ADD = "logi-role-bll-exceptions-add";
export const LOGI_ROLE_BLL_EXCEPTIONS_DELETE = "logi-role-bll-exceptions-delete";

export const LOGI_ROLE_BLL_MANUAL_BLOCK_READ = "logi-role-bll-manual-block-read";
export const LOGI_ROLE_BLL_MANUAL_BLOCK_ADD = "logi-role-bll-manual-block-add";
export const LOGI_ROLE_BLL_MANUAL_BLOCK_DELETE = "logi-role-bll-manual-block-delete";

export const LOGI_ROLE_CELERY_RESULTS_READ = "logi-role-celery-results-read";
export const LOGI_ROLE_CELERY_RESULTS_WRITE = "logi-role-celery-results-write";

export const LOGI_ROLE_COUNTRIES_PLUS_READ = "logi-role-countries-plus-read";
export const LOGI_ROLE_COUNTRIES_PLUS_WRITE = "logi-role-countries-plus-write";

export const LOGI_ROLE_DYNAMIC_PREFS_READ = "logi-role-dynamic-preferences-read";
export const LOGI_ROLE_DYNAMIC_PREFS_WRITE = "logi-role-dynamic-preferences-write";

export const LOGI_ROLE_GEO_READ = "logi-role-geo-read";
export const LOGI_ROLE_GEO_BlOCKED_COUNTRY_ADD = "logi-role-geo-blocked-country-add";
export const LOGI_ROLE_GEO_BlOCKED_COUNTRY_DELETE = "logi-role-geo-blocked-country-delete";

export const LOGI_ROLE_LICENSE_KEYS_READ = "logi-role-license-keys-read";
export const LOGI_ROLE_LICENSE_KEYS_WRITE = "logi-role-license-keys-write";

export const LOGI_ROLE_ANTISPOOFING_RULES_READ = "logi-role-antispoofing-rules-read";
export const LOGI_ROLE_ANTISPOOFING_RULES_ADD = "logi-role-antispoofing-rules-add";
export const LOGI_ROLE_ANTISPOOFING_RULES_EDIT = "logi-role-antispoofing-rules-edit";
export const LOGI_ROLE_ANTISPOOFING_RULES_DELETE = "logi-role-antispoofing-rules-delete";

export const LOGI_ROLE_MANUAL_RULES_READ = "logi-role-manual-rules-read";
export const LOGI_ROLE_MANUAL_RULES_ADD = "logi-role-manual-rules-add";
export const LOGI_ROLE_MANUAL_RULES_EDIT = "logi-role-manual-rules-edit";
export const LOGI_ROLE_MANUAL_RULES_DELETE = "logi-role-manual-rules-delete";

export const LOGI_ROLE_MAXMIND_FETCH = "logi-role-maxmind-fetch";

export const LOGI_ROLE_NFTABLES_RULES_READ = "logi-role-nftables-rules-read";
export const LOGI_ROLE_NFTABLES_RULES_WRITE = "logi-role-nftables-rules-write";

export const LOGI_ROLE_SCANNER_MESSAGES_READ = "logi-role-scanner-messages-read";
export const LOGI_ROLE_SCANNER_MESSAGES_ADD = "logi-role-scanner-messages-add";
export const LOGI_ROLE_SCANNER_MESSAGES_EDIT = "logi-role-scanner-messages-edit";
export const LOGI_ROLE_SCANNER_MESSAGES_DELETE = "logi-role-scanner-messages-delete";


export const LOGI_ROLE_SCAN_PILOT_READ = "logi-role-scan-pilot-read"
export const LOGI_ROLE_SCAN_PILOT_ADD = "logi-role-scan-pilot-add"
export const LOGI_ROLE_SCAN_PILOT_DELETE = "logi-role-scan-pilot-delete"

export const LOGI_ROLE_SCAN_CONTAINERS_READ = "logi-role-scan-containers-read";
export const LOGI_ROLE_SCAN_CONTAINERS_ADD = "logi-role-scan-containers-add";
export const LOGI_ROLE_SCAN_CONTAINERS_EDIT = "logi-role-scan-containers-edit";
export const LOGI_ROLE_SCAN_CONTAINERS_DELETE = "logi-role-scan-containers-delete";

export const LOGI_ROLE_SCAN_SUBNETS_READ = "logi-role-scan-subnets-read";

export const LOGI_ROLE_SCAN_POLICIES_READ = "logi-role-scan-policies-read";
export const LOGI_ROLE_SCAN_POLICIES_ADD = "logi-role-scan-policies-add";
export const LOGI_ROLE_SCAN_POLICIES_EDIT = "logi-role-scan-policies-edit";
export const LOGI_ROLE_SCAN_POLICIES_DELETE = "logi-role-scan-policies-delete";

export const LOGI_ROLE_SCAN_WHITELISTS_READ = "logi-role-scan-whitelists-read";
export const LOGI_ROLE_SCAN_WHITELISTS_ADD = "logi-role-scan-whitelists-add";
export const LOGI_ROLE_SCAN_WHITELISTS_EDIT = "logi-role-scan-whitelists-edit";
export const LOGI_ROLE_SCAN_WHITELISTS_DELETE = "logi-role-scan-whitelists-delete";

export const LOGI_ROLE_SCHEDULED_TASKS_READ = "logi-role-scheduled-tasks-read";
export const LOGI_ROLE_SCHEDULED_TASKS_WRITE = "logi-role-scheduled-tasks-write";

export const LOGI_ROLE_SCHEDULED_TASKS_CRON_ADD = "logi-role-scheduled-tasks-cron-add";
export const LOGI_ROLE_SCHEDULED_TASKS_CRON_EDIT = "logi-role-scheduled-tasks-cron-edit";
export const LOGI_ROLE_SCHEDULED_TASKS_CRON_DELETE = "logi-role-scheduled-tasks-cron-delete";

export const LOGI_ROLE_SCHEDULED_TASKS_INTERVAL_ADD = "logi-role-scheduled-tasks-interval-add";
export const LOGI_ROLE_SCHEDULED_TASKS_INTERVAL_EDIT = "logi-role-scheduled-tasks-interval-edit";
export const LOGI_ROLE_SCHEDULED_TASKS_INTERVAL_DELETE = "logi-role-scheduled-tasks-interval-delete";

export const LOGI_ROLE_SCHEDULED_TASKS_JOB_ADD = "logi-role-scheduled-tasks-job-add";
export const LOGI_ROLE_SCHEDULED_TASKS_JOB_EDIT = "logi-role-scheduled-tasks-job-edit";
export const LOGI_ROLE_SCHEDULED_TASKS_JOB_DELETE = "logi-role-scheduled-tasks-job-delete";

export const LOGI_ROLE_REPORTS_READ = "logi-role-reports-read";
export const LOGI_ROLE_REPORTS_WRITE = "logi-role-reports-write";

export const LOGI_ROLE_API_READ = "logi-role-api-read";

export const LOGI_ROLE_REPORT_RECIPIENTS_READ = "logi-role-report-recipients-read";
export const LOGI_ROLE_REPORT_RECIPIENTS_ADD = "logi-role-report-recipients-add";
export const LOGI_ROLE_REPORT_RECIPIENTS_EDIT = "logi-role-report-recipients-edit";
export const LOGI_ROLE_REPORT_RECIPIENTS_DELETE = "logi-role-report-recipients-delete";

export const LOGI_ROLE_REPORTABLE_COUNTRIES_READ = "logi-role-reportable-countries-read";
export const LOGI_ROLE_REPORTABLE_COUNTRIES_ADD = "logi-role-reportable-countries-add";
export const LOGI_ROLE_REPORTABLE_COUNTRIES_DELETE = "logi-role-reportable-countries-delete";

export const LOGI_ROLE_PCAP_READ = "logi-role-pcap-read";
export const LOGI_ROLE_PCAP_ADD = "logi-role-pcap-add";
export const LOGI_ROLE_PCAP_DELETE = "logi-role-pcap-delete";
