import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function AddReportableCountryDialog(props) {
  const {
    open,
    countryChosenSetter,
    onCancel,
    onAdd,
    countryOptions,
    addButtonDisabled
  } = props;

  const [chosenCountryId, setChosenCountryId] = useState(countryOptions.length > 0 ? countryOptions[0].country_id : "");

  const handleCancelButtonClick = (event) => {
    onCancel();
  };

  const handleAddButtonClick = (event) => {
    onAdd(chosenCountryId);
  };

  const handleCountryChosen = (event) => {
    // value will be country_code|country_name
    setChosenCountryId(event.target.value);
    countryChosenSetter(event.target.value);
  };

  const handleModalClose = (event) => {
    onCancel();
  };

  return (
    <Dialog onClose={handleModalClose} open={open}>
      <DialogTitle>Add Blocked Country</DialogTitle>
      <div class="modal-dialog-content-div">
        <Stack spacing={2} direction="column">
          <div height="1rem" />
          <FormControl id="reportable-country-tab-modal-dialog-country-form-control">
            <InputLabel id="reportable-country-tab-modal-dialog-country-select-label">Country to Block</InputLabel>
            <Select
              labelId="reportable-country-tab-modal-dialog-country-select-label"
              id="reportable-country-tab-modal-dialog-country-select"
              label="Country"
              onChange={handleCountryChosen}
            >
              {countryOptions.map((entry) => {
                return (
                  <MenuItem id="add-reportable-country" 
                    value={entry.country_id + "|" + entry.country}>{entry.country}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Button id="reportable-country-tab-modal-dialog-add-button"
              variant="contained"
              disabled={addButtonDisabled}
              onClick={handleAddButtonClick}>Add</Button>
            <Button id="reportable-country-tab-modal-dialog-cancel-button"
              variant="contained"
              onClick={handleCancelButtonClick}>Cancel</Button>
          </Stack>
        </Stack>
      </div>
    </Dialog>
  );
}
