import { useCallback, useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { FormControl } from '@mui/material';
import { isValidIpVersionCidr } from '../../common/validation/analysis/AnalysisValidator';
import { isIPv4, isIPv6 } from 'is-ip';
import isCidr from "is-cidr";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function BlockedIPSearchTab(props) {

    const {
        srcIp,
        srcIpSetter,
        fetchNftData,
        ipVersion,
        ipVersionSetter,
        matchStatus,
        messageSwitch,
        setMessageSwitch,
        rulesetSelect,
        setRulesetSelect,
        handleIpCheckButtonClickListener,
        nftRulesRows,
        LATEST_RULESET
    } = props;


    useEffect(() => {
        checkIpExists()
    })

    useEffect(() => {
        fetchNftData();
        // console.log("Set Rule Select Daves: ", rulesetSelect);
    }, [])

    const [showSubmit, setShowSubmit] = useState(false);
    const [displayMess, setDisplayMess] = useState('Run the check and results will appear here.');


    const CheckConnection = useCallback(() => {
        if (showSubmit === true) {
            // console.log('Check Connection True.')
            return (
                <Button sx={{ width: 175 }}
                    id="connections-check-connection-button"
                    variant="contained"
                    onClick={handleIpCheckButtonClickListener}
                >Check Connection</Button>
            )
        } else {
            // console.log('Check Connection False.')
            return (
                <Button sx={{ width: 175 }}
                    id="connections-check-connection-button"
                    variant="contained"
                    onClick={handleIpCheckButtonClickListener}
                    disabled='true'
                >Check Connection</Button>
            )
        }
    }, [showSubmit, handleIpCheckButtonClickListener])


    useEffect(() => {
        CheckConnection()
    }, [showSubmit, CheckConnection])


    const handleVersionChange = (event) => {
        // console.log("Version: ", event.target.value);
        ipVersionSetter(event.target.value);
        checkIpExists();
    };

    const handleRulesetChange = (event) => {
        // console.log("Ruleset: ", event.target.value);
        setRulesetSelect(event.target.value);
    }

    const handleSrcIpChange = (event) => {
        // console.log("Source IP: ", event.target.value);
        srcIpSetter(event.target.value);
    }

    const getDisplayMessage = (ms) => {
        // console.log("Running getDisplayMessage")

        if (ms === undefined) {
            return "The connection did not match any rules and would be allowed";
        }
        else {
            return "Connection would be dropped by " + ms;
        }
    }

    const initialMessage = () => {
        return displayMess;
    }

    const checkIpExists = () => {
        // console.log('checkIpExists')
        if ((srcIp.length > 0)) {
            const srcIpValid = isValidIpVersionCidr(srcIp);
            if ((srcIpValid.res === true)) {
                // console.log("Inside first if.")
                // console.log("isIPv4: ", isIPv4(srcIp))
                if ((ipVersion === 'IPv4') && ((isIPv4(srcIp) === true || isCidr.v4(srcIp)))) {
                    setShowSubmit(true);
                } else if ((ipVersion === 'IPv6') && ((isIPv6(srcIp) === true) || isCidr.v6(srcIp))) {
                    setShowSubmit(true);
                } else {
                    // console.log("Else ran")
                    setShowSubmit(false);
                    setMessageSwitch(false);
                }
            } else {
                setShowSubmit(false);
                setMessageSwitch(false);
            }
        } else {
            // console.log("IP's don't exist.")
            setShowSubmit(false);
            setMessageSwitch(false);
        }
    }

    const handleFormReset = () => {
        srcIpSetter('');
        ipVersionSetter('IPv4');
        setMessageSwitch(false);
        setDisplayMess('Run the check and results will appear here.');
        setRulesetSelect(LATEST_RULESET);
    }

    function ResetButton() {
        return (
            <Button sx={{ width: 125 }}
                id="connections-reset-button"
                variant="contained"
                onClick={handleFormReset}
            >Reset Form</Button>
        )
    }

    const enterSearch = (event) => {
        event.preventDefault();
        if (event.key === "Enter") {
            handleIpCheckButtonClickListener();
        }
    };

    // console.log("MessageSwitch: ", messageSwitch)

    return (
        <div style={{ 'verticalAlign': 'top', 'width': '850px' }} className='analysis-connections-container'>
            <div className='analysis-connections-filter'>
                <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Analysis Filter</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2} direction="column" style={{ 'width': '800px' }}>
                            <Stack direction="row" style={{ 'width': '800px' }}>
                                <TextField
                                    label="IP Address"
                                    variant="outlined"
                                    size="medium"
                                    value={srcIp}
                                    onChange={handleSrcIpChange}
                                    onKeyUp={enterSearch}
                                    autoComplete='off'
                                    autoFocus="true"
                                    id="connections-src-ip-address"
                                    style={{ margin: '10px', width: '550px' }}
                                />
                            </Stack>
                            <Stack direction="row">
                                <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 275 }}>
                                    <TextField
                                        labelId="connections-version-select-label"
                                        id="connections-ip-version-select"
                                        value={ipVersion}
                                        onChange={handleVersionChange}
                                        select // tell TextField to render select
                                        label="Version"
                                        autoWidth
                                    >
                                        <MenuItem value={"IPv4"} key={1}>IPv4</MenuItem>
                                        <MenuItem value={"IPv6"} key={2}>IPv6</MenuItem>
                                    </TextField>
                                </FormControl>
                            </Stack>
                            <Stack direction="row">
                                <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 275 }}>
                                    <TextField
                                        labelId="connections-ruleset-select-label"
                                        id="connections-ruleset-select"
                                        value={rulesetSelect}
                                        onChange={handleRulesetChange}
                                        select
                                        label="Ruleset"
                                        autowidth
                                        placeholder="More stuff goes here"
                                    >
                                        <MenuItem value={LATEST_RULESET}>Latest Ruleset</MenuItem>
                                        {nftRulesRows !== [] ?
                                            nftRulesRows.map((item) => {
                                                return (
                                                    <MenuItem value={item['id']}>{item['created']}</MenuItem>
                                                )
                                            })
                                            :
                                            <div>Stuff goes here</div>
                                        }
                                    </TextField>
                                </FormControl>
                            </Stack>
                            <Stack direction='row'>
                                <CheckConnection style={{ margin: ' 20px' }} />
                                <ResetButton style={{ margin: ' 20px' }} />
                            </Stack>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='analysis-connections-content'>
                <Stack spacing={1} direction="column">
                    {messageSwitch ?
                        <Alert
                            id="connections-status-div"
                            style={{ width: 550, margin: '10px' }}
                            severity="primary">{getDisplayMessage(matchStatus) ? getDisplayMessage(matchStatus) : initialMessage()}</Alert>
                        :
                        <Alert
                            id="connections-status-div"
                            style={{ width: 550, margin: '10px' }}
                            severity="primary">Run the check and results will appear here.</Alert>
                    }
                </Stack>
            </div>
        </div>
    )
}