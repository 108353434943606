import React, { useState, useEffect } from 'react';
import {
    LOGI_ROLE_ADMIN_FUNCTIONS, LOGI_ROLE_API_KEYS_READ,
    LOGI_ROLE_CELERY_RESULTS_READ, LOGI_ROLE_CONTENT_READ, LOGI_ROLE_DYNAMIC_PREFS_READ,
    LOGI_ROLE_LICENSE_KEYS_READ, LOGI_ROLE_SCHEDULED_TASKS_READ
} from '../common/LogiRoles';
import { LOGI_ROLE_ASN_READ } from '../common/LogiRoles';
import { LOGI_ROLE_ANON_READ } from '../common/LogiRoles';
import { LOGI_ROLE_BLL_READ } from '../common/LogiRoles';
import { LOGI_ROLE_GEO_READ } from '../common/LogiRoles';
import { LOGI_ROLE_MANUAL_RULES_READ } from '../common/LogiRoles';
import { LOGI_ROLE_SCANNER_MESSAGES_READ } from '../common/LogiRoles';
import { LOGI_ROLE_NFTABLES_RULES_READ } from '../common/LogiRoles';
import { LOGI_ROLE_COUNTRIES_PLUS_READ } from '../common/LogiRoles';
import { LOGI_ROLE_REPORTS_READ } from '../common/LogiRoles';
import { LOGI_ROLE_PCAP_READ } from '../common/LogiRoles';
import { Link } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SecurityIcon from '@mui/icons-material/Security';
import PublicIcon from '@mui/icons-material/Public';
import RadarIcon from '@mui/icons-material/Radar';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import LockIcon from '@mui/icons-material/Lock';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import NumbersIcon from '@mui/icons-material/Numbers';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TaskIcon from '@mui/icons-material/Task';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ApiIcon from '@mui/icons-material/Api';
import KeyIcon from '@mui/icons-material/Key';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import '../App.css';
import './sidenav.css'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchErrorMessage } from './mainpage';

const versionUrl = '/middleware/api/v3/version_number/';

export default function Sidenavs(props) {
    const { keycloak } = useKeycloak();
    const firewallSettingsOpen = props.firewallSettingsOpen;
    const adminOpen = props.adminOpen;

    const sidenavTheme = createTheme({
        palette: {
            navIconColor: {
                main: '#FFFFFF',
                contrastText: '#fff',
            },
            selectedNavIconColor: {
                main: '#4287F5',
                contrastText: '#fff',
            },
        },
    });

    const [nestedFirewallListOpen, setNestedFirewallListOpen] = useState(firewallSettingsOpen); // changing from true.
    const [nestedAdminListOpen, setNestedAdminListOpen] = useState(adminOpen); // changing from true.
    const [version, setVersion] = useState('No version available');
    const unselectedColor = "navIconColor";
    const selectedColor = "selectedNavIconColor";
    const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
    /* Number of milliseconds to show notifications */
    const notificationAutoclose = 5000;

    const handleFetchErrorClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFetchErrorMessageOpen(false);
    };

    useEffect(() => {
        fetch(versionUrl, {
            method: 'GET',
            headers: {
                'access-token': keycloak.token
            },
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.text();
        })
            .then(resp => {
                setVersion(resp)
                document.title = "Logi v" + resp
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    }, [keycloak])

    useEffect(() => {
        if (version === 'Version v' || version === '') {
            var datex = new Date();
            console.log('Session is stale at: ', datex)
            keycloak.logout();
        }
    }, [version])

    const handleButtonClick = (event) => {
        if (event === null) {
            return;
        }

        if (event.currentTarget === null) {
            return;
        }

        let lookedUpLink = null;

        switch (event.currentTarget.id) {
            case 'sidenav-asn-button':
                lookedUpLink = document.getElementById('sidenav-asn-link');
                lookedUpLink.click();
                break;
            case 'sidenav-anon-button':
                lookedUpLink = document.getElementById('sidenav-anon-link');
                lookedUpLink.click();
                break;
            case 'sidenav-bll-button':
                lookedUpLink = document.getElementById('sidenav-bll-link');
                lookedUpLink.click();
                break;
            case 'sidenav-geoip-button':
                lookedUpLink = document.getElementById('sidenav-geoip-link');
                lookedUpLink.click();
                break;
            case 'sidenav-pcap-button':
                lookedUpLink = document.getElementById('sidenav-pcap-link');
                lookedUpLink.click();
                break;
            case 'sidenav-mrules-button':
                lookedUpLink = document.getElementById('sidenav-mrules-link');
                lookedUpLink.click();
                break;
            case 'sidenav-scannermessages-button':
                lookedUpLink = document.getElementById('sidenav-scannermessages-link');
                lookedUpLink.click();
                break;
            case 'sidenav-nftablesrules-button':
                lookedUpLink = document.getElementById('sidenav-nftablesrules-link');
                lookedUpLink.click();
                break;
            case 'sidenav-countriesplus-button':
                lookedUpLink = document.getElementById('sidenav-countriesplus-link');
                lookedUpLink.click();
                break;
            case 'sidenav-admin-button':
                lookedUpLink = document.getElementById('sidenav-admin-link');
                lookedUpLink.click();
                break;
            case 'sidenav-contactus-button':
                lookedUpLink = document.getElementById('sidenav-contactus-link');
                lookedUpLink.click();
                break;
            case 'sidenav-celeryresults-button':
                lookedUpLink = document.getElementById('sidenav-celeryresults-link');
                lookedUpLink.click();
                break;
            case 'sidenav-dynamicprefs-button':
                lookedUpLink = document.getElementById('sidenav-dynamicprefs-link');
                lookedUpLink.click();
                break;
            case 'sidenav-reports-button':
                lookedUpLink = document.getElementById('sidenav-reports-link');
                lookedUpLink.click();
                break;
            case 'sidenav-licensekeys-button':
                lookedUpLink = document.getElementById('sidenav-licensekeys-link');
                lookedUpLink.click();
                break;
            case 'sidenav-apikeys-button':
                lookedUpLink = document.getElementById('sidenav-apikeys-link');
                lookedUpLink.click();
                break;
            case 'sidenav-scheduledtasks-button':
                lookedUpLink = document.getElementById('sidenav-scheduledtasks-link');
                lookedUpLink.click();
                break;
            case 'sidenav-contentupload-button':
                lookedUpLink = document.getElementById('sidenav-contentupload-link');
                lookedUpLink.click();
                break;
            case 'sidenav-analysis-button':
                lookedUpLink = document.getElementById('sidenav-analysis-link');
                lookedUpLink.click();
                break;
            default:
        }
    };


    let hasAdminFunctions = keycloak.hasRealmRole(LOGI_ROLE_ADMIN_FUNCTIONS);
    let hasASNRead = keycloak.hasRealmRole(LOGI_ROLE_ASN_READ);
    let hasAnonRead = keycloak.hasRealmRole(LOGI_ROLE_ANON_READ);
    let hasBLLRead = keycloak.hasRealmRole(LOGI_ROLE_BLL_READ);
    let hasGEORead = keycloak.hasRealmRole(LOGI_ROLE_GEO_READ);
    let hasManualRulesRead = keycloak.hasRealmRole(LOGI_ROLE_MANUAL_RULES_READ);
    let hasScannerMessagesRead = keycloak.hasRealmRole(LOGI_ROLE_SCANNER_MESSAGES_READ);
    let hasNftablesRulesRead = keycloak.hasRealmRole(LOGI_ROLE_NFTABLES_RULES_READ);
    let hasCountriesPlusRead = keycloak.hasRealmRole(LOGI_ROLE_COUNTRIES_PLUS_READ);
    let hasCeleryResultsRead = keycloak.hasRealmRole(LOGI_ROLE_CELERY_RESULTS_READ);
    let hasDynamicPrefsRead = keycloak.hasRealmRole(LOGI_ROLE_DYNAMIC_PREFS_READ);
    let hasApiKeysRead = keycloak.hasRealmRole(LOGI_ROLE_API_KEYS_READ);
    let hasLicenseKeysRead = keycloak.hasRealmRole(LOGI_ROLE_LICENSE_KEYS_READ);
    let hasScheduledTasksRead = keycloak.hasRealmRole(LOGI_ROLE_SCHEDULED_TASKS_READ);
    let hasReportsRead = keycloak.hasRealmRole(LOGI_ROLE_REPORTS_READ);
    let hasContentUploadRead = keycloak.hasRealmRole(LOGI_ROLE_CONTENT_READ);
    let hasPcapRead = keycloak.hasRealmRole(LOGI_ROLE_PCAP_READ)

    const handleFirewallListButtonClicked = () => {
        setNestedFirewallListOpen(!nestedFirewallListOpen);
    };

    const handleAdministrativeListButtonClicked = () => {
        setNestedAdminListOpen(!nestedAdminListOpen);
    };

    const [snackState,] = React.useState({
        snackbarVertical: 'bottom',
        snackbarHorizontal: 'center',
    });

    const { snackbarHorizontal, snackbarVertical } = snackState;

    return (
        <Box sx={{ width: '18rem' }} id="sidenav_list_box">
            <List
                sx={{ width: '100%', maxWidth: 360, }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader"></ListSubheader>
                }
            >
                <ListItemButton id="sidenav-firewall-settings-button" onClick={handleFirewallListButtonClicked}>
                    <ThemeProvider theme={sidenavTheme}>
                        <ListItemIcon>
                            <SecurityIcon color="navIconColor" />
                        </ListItemIcon>
                    </ThemeProvider>
                    <ListItemText primary="Firewall Settings" />
                    {nestedFirewallListOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={nestedFirewallListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {hasGEORead ? (<ListItemButton
                            id="sidenav-geoip-button"
                            onClick={(event) => {
                                handleButtonClick(event);
                            }
                            }
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <PublicIcon
                                        {...(window.location.pathname === "/geoippage" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>

                                <ListItemText
                                    {...(window.location.pathname === "/geoippage" && { class: "selected-sidenav-list-item-text" })}
                                    primary="Geo-IP" />
                            </ThemeProvider>
                            <Link id="sidenav-geoip-link" hidden class="snlink" to="/geoippage" />
                        </ListItemButton>) : ''}
                        {hasASNRead ? (<ListItemButton
                            id="sidenav-asn-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <NumbersIcon {...(window.location.pathname === "/asnpage" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/asnpage" && { class: "selected-sidenav-list-item-text" })}
                                primary="ASN" />
                            <Link id="sidenav-asn-link" hidden class="snlink" to="/asnpage" />
                        </ListItemButton>) : ''}
                        {hasAnonRead ? (<ListItemButton
                            id="sidenav-anon-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <ShuffleIcon
                                        {...(window.location.pathname === "/anonpage" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/anonpage" && { class: "selected-sidenav-list-item-text" })}
                                primary="Anonymous" />
                            <Link id="sidenav-anon-link" hidden class="snlink" to="/anonpage" />
                        </ListItemButton>) : ''}
                        {hasScannerMessagesRead ? (<ListItemButton
                            id="sidenav-scannermessages-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <RadarIcon {...(window.location.pathname === "/scans" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/scans" && { class: "selected-sidenav-list-item-text" })}
                                primary="Scan" />
                            <Link id="sidenav-scannermessages-link" hidden class="snlink" to="/scans" />
                        </ListItemButton>) : ''}
                        {hasManualRulesRead ? (<ListItemButton
                            id="sidenav-mrules-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <KeyboardIcon {...(window.location.pathname === "/manualrules" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/manualrules" && { class: "selected-sidenav-list-item-text" })}
                                primary="Manual" />
                            <Link id="sidenav-mrules-link" hidden class="snlink" to="/manualrules" />
                        </ListItemButton>) : ''}
                        {hasBLLRead ? (<ListItemButton
                            id="sidenav-bll-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <LockIcon {...(window.location.pathname === "/bllpage" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/bllpage" && { class: "selected-sidenav-list-item-text" })}
                                primary="BLL" />
                            <Link id="sidenav-bll-link" hidden class="snlink" to="/bllpage" />
                        </ListItemButton>) : ''}
                        {hasPcapRead ? (<ListItemButton
                            id="sidenav-pcap-button"
                            onClick={(event) => {
                                handleButtonClick(event);
                            }
                            }
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <PublicIcon
                                        {...(window.location.pathname === "/pcap" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>

                                <ListItemText
                                    {...(window.location.pathname === "/pcap" && { class: "selected-sidenav-list-item-text" })}
                                    primary="PCAP" />
                            </ThemeProvider>
                            <Link id="sidenav-pcap-link" hidden class="snlink" to="/pcap" />
                        </ListItemButton>) : ''}
                        {hasNftablesRulesRead ? (<ListItemButton
                            id="sidenav-nftablesrules-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <ListAltIcon {...(window.location.pathname === "/nftables" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/nftables" && { class: "selected-sidenav-list-item-text" })}
                                primary="NFT Rules" />
                            <Link id="sidenav-nftablesrules-link" hidden class="snlink" to="/nftables" />
                        </ListItemButton>) : ''}
                        <ListItemButton
                            id='sidenav-analysis-button'
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}
                        >
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <AccountTreeIcon {...(window.location.pathname === "/analysis" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/analysis" && { class: "selected-sidenav-list-item-text" })}
                                primary="Analysis" />
                            <Link id="sidenav-analysis-link" hidden class="snlink" to="/analysis" />
                        </ListItemButton>
                    </List>
                </Collapse>
                {hasAdminFunctions ? (<ListItemButton id="sidenav-administration-button" onClick={handleAdministrativeListButtonClicked}>
                    <ThemeProvider theme={sidenavTheme}>
                        <ListItemIcon>
                            <AdminPanelSettingsIcon color="navIconColor" />
                        </ListItemIcon>
                    </ThemeProvider>
                    <ListItemText primary="Administration" />
                    {nestedAdminListOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>) : ''}
                {hasAdminFunctions ? (<Collapse in={nestedAdminListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {hasCeleryResultsRead ? (<ListItemButton
                            id="sidenav-celeryresults-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <TaskIcon {...(window.location.pathname === "/celeryresults" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/celeryresults" && { class: "selected-sidenav-list-item-text" })}
                                primary="Celery Results" />
                            <Link id="sidenav-celeryresults-link" hidden class="snlink" to="/celeryresults" />
                        </ListItemButton>) : ''}
                        {hasCountriesPlusRead ? (<ListItemButton
                            id="sidenav-countriesplus-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <TravelExploreIcon {...(window.location.pathname === "/countriesplus" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/countriesplus" && { class: "selected-sidenav-list-item-text" })}
                                primary="Countries Plus" />
                            <Link id="sidenav-countriesplus-link" hidden class="snlink" to="/countriesplus" />
                        </ListItemButton>) : ''}
                        {hasDynamicPrefsRead ? (<ListItemButton
                            id="sidenav-dynamicprefs-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <DynamicFeedIcon {...(window.location.pathname === "/dynamicpreferences" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/dynamicpreferences" && { class: "selected-sidenav-list-item-text" })}
                                primary="Dynamic Preferences" />
                            <Link id="sidenav-dynamicprefs-link" hidden class="snlink" to="/dynamicpreferences" />
                        </ListItemButton>) : ''}
                        {hasApiKeysRead ? (<ListItemButton
                            id="sidenav-apikeys-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <ApiIcon {...(window.location.pathname === "/apikeys" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/apikeys" && { class: "selected-sidenav-list-item-text" })}
                                primary="API Keys" />
                            <Link id="sidenav-apikeys-link" hidden class="snlink" to="/apikeys" />
                        </ListItemButton>) : ''}
                        {hasLicenseKeysRead ? (<ListItemButton
                            id="sidenav-licensekeys-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <KeyIcon {...(window.location.pathname === "/licensekeys" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/licensekeys" && { class: "selected-sidenav-list-item-text" })}
                                primary="License Keys" />
                            <Link id="sidenav-licensekeys-link" hidden class="snlink" to="/licensekeys" />
                        </ListItemButton>) : ''}
                        {hasScheduledTasksRead ? (<ListItemButton
                            id="sidenav-scheduledtasks-button"
                            onClick={handleButtonClick}
                            sx={{ pl: 4 }}>
                            <ThemeProvider theme={sidenavTheme}>
                                <ListItemIcon>
                                    <CalendarMonthIcon {...(window.location.pathname === "/scheduledtasks" ? { color: selectedColor } : { color: unselectedColor })} />
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText
                                {...(window.location.pathname === "/scheduledtasks" && { class: "selected-sidenav-list-item-text" })}
                                primary="Scheduled Tasks" />
                            <Link id="sidenav-scheduledtasks-link" hidden class="snlink" to="/scheduledtasks" />
                        </ListItemButton>) : ''}
                    </List>
                </Collapse>) : ''}
                <List>
                    {hasContentUploadRead ? (<ListItemButton
                        id="sidenav-contentupload-button"
                        onClick={handleButtonClick}
                    >
                        <ThemeProvider theme={sidenavTheme}>
                            <ListItemIcon>
                                <DynamicFeedIcon {...(window.location.pathname === "/contentupload" ? { color: selectedColor } : { color: unselectedColor })} />
                            </ListItemIcon>
                        </ThemeProvider>
                        <ListItemText
                            {...(window.location.pathname === "/contentupload" && { class: "selected-sidenav-list-item-text" })}
                            primary="Content Upload" />
                        <Link id="sidenav-contentupload-link" hidden class="snlink" to="/contentupload" />
                    </ListItemButton>) : ''}

                    {hasReportsRead ? (<ListItemButton
                        id="sidenav-reports-button"
                        onClick={handleButtonClick}
                    >
                        <ThemeProvider theme={sidenavTheme}>
                            <ListItemIcon>
                                <AssessmentIcon {...(window.location.pathname === "/reports" ? { color: selectedColor } : { color: unselectedColor })} />
                            </ListItemIcon>
                        </ThemeProvider>
                        <ListItemText
                            {...(window.location.pathname === "/reports" && { class: "selected-sidenav-list-item-text" })}
                            primary="Reports" />
                        <Link id="sidenav-reports-link" hidden class="snlink" to="/reports" />
                    </ListItemButton>) : ''}
                    <ListItemButton
                        id="sidenav-contactus-button"
                        onClick={handleButtonClick}>
                        <ThemeProvider theme={sidenavTheme}>
                            <ListItemIcon>
                                <ContactPageIcon {...(window.location.pathname === "/contactus" ? { color: selectedColor } : { color: unselectedColor })} />
                            </ListItemIcon>
                        </ThemeProvider>
                        <ListItemText
                            {...(window.location.pathname === "/contactus" && { class: "selected-sidenav-list-item-text" })}
                            primary="Contact Us" />
                        <Link id="sidenav-contactus-link" hidden class="snlink" to="/contactus" />
                    </ListItemButton>
                </List>
                <List>
                    <ListItem
                        id="sidnav-version-button">
                        <ThemeProvider theme={sidenavTheme}>
                            <ListItemIcon>
                                <ApiIcon {...(window.location.pathname === "/reports" ? { color: unselectedColor } : { color: unselectedColor })} />
                            </ListItemIcon>
                        </ThemeProvider>
                        <ListItemText primary={"Version v" + version || "Version not available."} className="sidenav-list-item-text"></ListItemText>
                    </ListItem>
                </List>
            </List>
            <Snackbar
                open={fetchErrorMessageOpen}
                autoHideDuration={notificationAutoclose}
                onClose={handleFetchErrorClosed}
                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
            >
                <MuiAlert
                    className="logi-snackbar-notification-message"
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {fetchErrorMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    )
}
// export default Sidenavs;
