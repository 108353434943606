import IPCIDR from "ip-cidr";
export const MR_DIRECTION_INBOUND = "inbound";
export const MR_DIRECTION_OUTBOUND = "outbound";

export const MR_ACTION_ALLOW = "allow";
export const MR_ACTION_DROP = "drop";

/**
 * 
 * @returns an array with values suitable for populating a Select component with available IP versions
 */
export function getIPVersions() {
    let ret = [];

    ret.push({ key: "4", value: "IPv4" });
    ret.push({ key: "6", value: "IPv6" });

    return ret;
}

/**
 * Returns an array of what is considered valid values for
 * the direction field of a manual rule.
 * 
 * @returns Array of valid directions
 */
export function getValidDirections() {
    let ret = [];

    ret.push(MR_DIRECTION_INBOUND);
    ret.push(MR_DIRECTION_OUTBOUND);

    return ret;
}

/**
 * Returns an array of what is considered valid values for
 * the direction field of a manual rule.
 * 
 * @returns Array of valid directions
 */
export function getValidActions() {
    let ret = [];

    ret.push(MR_ACTION_ALLOW);
    ret.push(MR_ACTION_DROP);

    return ret;
}

/**
 * Returns an array of what is considered valid values for
 * the direction field of a manual rule.
 *
 * This list was gathered from /etc/protocols on
 * a Rocky 9.1 host on 2022-12-02
 *  
 * @returns Array of valid protocols
 */
export function getValidProtocols() {
    let ret = [];

    // ret.push("3PC");
    ret.push("AH");
    // ret.push("A/N");
    // ret.push("ARGUS");
    // ret.push("ARIS");
    // ret.push("AX.25");
    // ret.push("BBN-RCC-MON");
    // ret.push("BNA");
    // ret.push("BR-SAT-MON");
    // ret.push("CBT");
    // ret.push("CFTP");
    // ret.push("CHAOS");
    // ret.push("Compaq-Peer");
    // ret.push("CPHB");
    // ret.push("CPNX");
    // ret.push("CRTP");
    // ret.push("CRUDP");
    // ret.push("DCCP");
    // ret.push("DCN-MEAS");
    // ret.push("DDP");
    // ret.push("DDX");
    // ret.push("DGP");
    // ret.push("DSR");
    // ret.push("EGP");
    // ret.push("EIGRP");
    // ret.push("EMCON");
    // ret.push("ENCAP");
    ret.push("ESP");
    // ret.push("ETHERIP");
    // ret.push("FC");
    // ret.push("FIRE");
    // ret.push("GGP");
    // ret.push("GMTP");
    // ret.push("GRE");
    // ret.push("HIP");
    // ret.push("HMP");
    // ret.push("HOPOPT");
    // ret.push("IATP");
    // ret.push("ICMP");
    // ret.push("IDPR");
    // ret.push("IDPR-CMTP");
    // ret.push("IDRP");
    // ret.push("IFMP");
    // ret.push("IGMP");
    // ret.push("IGP");
    // ret.push("IL");
    // ret.push("I-NLSP");
    ret.push("IP");
    // ret.push("IPComp");
    // ret.push("IPCV");
    // ret.push("IPIP");
    // ret.push("IPLT");
    // ret.push("IPPC");
    // ret.push("IPv4");
    // ret.push("IPv6");
    // ret.push("IPv6-Auth");
    // ret.push("IPv6-Crypt");
    // ret.push("IPv6-Frag");
    // ret.push("IPv6-ICMP");
    // ret.push("IPv6-NoNxt");
    // ret.push("IPv6-Opts");
    // ret.push("IPv6-Route");
    // ret.push("IPX-in-IP");
    // ret.push("IRTP");
    // ret.push("ISIS");
    // ret.push("ISO-IP");
    // ret.push("ISO-TP4");
    // ret.push("KRYPTOLAN");
    // ret.push("L2TP");
    // ret.push("LARP");
    // ret.push("LEAF-1");
    // ret.push("LEAF-2");
    // ret.push("manet");
    // ret.push("MERIT-INP");
    // ret.push("MFE-NSP");
    // ret.push("MICP");
    // ret.push("MOBILE");
    // ret.push("Mobility-Header");
    // ret.push("MPLS-in-IP");
    // ret.push("MTP");
    // ret.push("MUX");
    // ret.push("NARP");
    // ret.push("NETBLT");
    // ret.push("NSFNET-IGP");
    // ret.push("NVP-II");
    // ret.push("OSPFIGP");
    // ret.push("PGM");
    // ret.push("PIM");
    // ret.push("PIPE");
    // ret.push("PNNI");
    // ret.push("PRM");
    // ret.push("PTP");
    // ret.push("PUP");
    // ret.push("PVP");
    // ret.push("QNX");
    // ret.push("RDP");
    // ret.push("ROHC");
    // ret.push("RSVP");
    // ret.push("RSVP-E2E-IGNORE");
    // ret.push("RVD");
    // ret.push("SAT-EXPAK");
    // ret.push("SAT-MON");
    // ret.push("SCC-SP");
    // ret.push("SCPS");
    // ret.push("SCTP");
    // ret.push("SDRP");
    // ret.push("SECURE-VMTP");
    // ret.push("Shim6");
    // ret.push("SKIP");
    // ret.push("SM");
    // ret.push("SMP");
    // ret.push("SNP");
    // ret.push("Sprite-RPC");
    // ret.push("SPS");
    // ret.push("SRP");
    // ret.push("SSCOPMCE");
    // ret.push("ST");
    // ret.push("STP");
    // ret.push("SUN-ND");
    // ret.push("SWIPE");
    // ret.push("TCF");
    ret.push("TCP");
    // ret.push("TLSP");
    // ret.push("TP++");
    // ret.push("TRUNK-1");
    // ret.push("TRUNK-2");
    // ret.push("TTP");
    ret.push("UDP");
    // ret.push("UDPLite");
    // ret.push("UTI");
    // ret.push("VINES");
    // ret.push("VISA");
    // ret.push("VMTP");
    // ret.push("VRRP");
    // ret.push("WB-EXPAK");
    // ret.push("WB-MON");
    // ret.push("WESP");
    // ret.push("WSN");
    // ret.push("XNET");
    // ret.push("XNS-IDP");
    // ret.push("XTP");

    return ret;
}

/**
 * Validate that the input would be valid as a TCP/IP port number
 * A null/blank string as input will be valid as any port
 * @param {*} input 
 */
export function isValidPortNumber(input) {
    let ret = { res: true, msg: "" };

    const min = 1;
    const max = 65535;

    if (input === null || input === undefined) {
        // null input is considered "any" port
        return ret;
    }

    let trimmedInput = "";

    if (typeof input == 'number') {
        trimmedInput = input + "";
    }
    else {
        trimmedInput = input.trim();
    }

    if (trimmedInput === '') {
        // blank input is considered "any" port
        return ret;
    }


    if ('any' === trimmedInput) {
        // consider the string "any" to be acceptable
        return ret;
    }

    if (isNaN(trimmedInput)) {
        // the trimmed input is not a number so this can't at all be valid
        ret.res = false;
        ret.msg = "The input must be a valid number";
        return ret;
    }
    else {
        // we have a number, just do a simple range check
        let parsedNum = parseInt(trimmedInput);
        if (parsedNum < min || parsedNum > max) {
            // outside our allowed range
            ret.res = false;
            ret.msg = "single number is outside allowed range " + max + "-" + max;
            return ret;
        }
    }
    return ret;
}

/**
 * Validate that the input would be valid as a traffic direction
 * @param {*} input 
 */
export function isValidDirection(input) {
    let ret = { res: true, msg: "" };

    if (input === null || input === undefined) {
        // null input is considered "any" port
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is considered "any" port
        return ret;
    }

    let validDirections = getValidDirections();
    if (validDirections.indexOf(trimmedInput) === -1) {
        // supplied input was not in our array of valid
        // directions
        ret.res = false;
        ret.msg = "Direction must be one of: " + MR_DIRECTION_INBOUND
            + ", " + MR_DIRECTION_OUTBOUND;
    }
    return ret;
}

/**
 * Validate that the input is a valid CIDR network block of the ip version
 * specified.
 * 
 * @param {*} ipVersion ip version the input must be a cidr block for
 * @param {*} input the value to be checked
 * @param {*} protocol optional protocol that may trigger additional validation if supplied
 */
export function isValidIpVersionCidr(ipVersion, input, protocol) {
    let ret = { res: true, msg: "" };

    if (input === null || input === undefined) {
        // null input is considered "any"
        return ret;
    }

    let trimmedInput = input;
    if (trimmedInput === '') {
        // blank input is considered "any" port
        return ret;
    }

    if (trimmedInput === 'any') {
        // any is also valid
        return ret;
    }

    // use ip-cidr so we can check the input is a cidr block and if so
    // what ip version
    // const isCidr = require("is-cidr");

    let cidrResult;

    let ipVersionInt = parseInt(ipVersion);

    try {
        cidrResult = IPCIDR.isValidCIDR(trimmedInput);
        if (cidrResult) {
            // it says it's a valid IPv4 or IPv6 CIDR block, we still need to check some things
            const parsed = new IPCIDR(trimmedInput);
            const address = parsed.address;
            const addressStart = parsed.addressStart;

            const addrBytes = JSON.stringify(address.parsedAddress);
            const addrStartBytes = JSON.stringify(addressStart.parsedAddress);

            switch (ipVersionInt) {
                case 4:
                    if (!address.v4) {
                        // the cidr block is not IPv4
                        ret.res = false;
                        ret.msg = "The cidr block specifed is not IPv4";
                        return ret;
                    }
                    if (addrBytes !== addrStartBytes) {
                        // the cidr block specified has host bits set
                        ret.res = false;
                        ret.msg = "The cidr block has host bits specified";
                    }

                    if (protocol !== null && protocol !== undefined) {
                        // protocol was supplied so do additional validation
                        if (protocol !== "IP") {
                            // protocols other than IP require a single ip, e.g. /32 or /128
                            if (address.subnet !== "/32") {
                                ret.res = false;
                                ret.msg = "The protocol IP rquires cidr block to have mask of /32";
                            }
                        }
                    }
                    break;
                case 6:
                    if (address.v4) {
                        // the cidr block is not IPv6
                        ret.res = false;
                        ret.msg = "The cidr block specifed is not IPv6";
                        return ret;
                    }
                    if (addrBytes !== addrStartBytes) {
                        // the cidr block specified has host bits set
                        ret.res = false;
                        ret.msg = "The cidr block has host bits specified";
                    }
                    if (protocol !== null && protocol !== undefined) {
                        // protocol was supplied so do additional validation
                        if (protocol !== "IP") {
                            // protocols other than IP require a single ip, e.g. /32 or /128
                            if (address.subnet !== "/128") {
                                ret.res = false;
                                ret.msg = "The protocol IP rquires cidr block to have mask of /128";
                            }
                        }
                    }
                    break;
                default:
                    // address is not v4 or v6
                    ret.res = false;
                    ret.msg = "The ip block entered is not neither IPv4 or IPv6";
            }
        } else {
            // input couldn't be parsed as a cidr block
            ret.res = false;
            ret.msg = "The value entered isn't a valid cidr block";
        }
    } catch {
        // input couldn't be parsed as a cidr block
        ret.res = false;
        ret.msg = "The value entered couldn't be parsed as a cidr block";
    }

    return ret;
}


export async function checkDuplicateManualRuleRow
    (fetchUrl, keycloakToken, modalRuleId, modalRuleProtocol,
        modalRuleSourceAddress, modalRuleSourcePort,
        modalRuleDestAddress, modalRuleDestPort, modalRuleDirection,
        modalRuleAction,
        modalRuleValidCallBack,
        modalRuleValidMessageCallback
    ) {

    await fetch(fetchUrl
        + '?limit=1000', {
        method: 'GET',
        headers: {
            'access-token': keycloakToken
        },
    })
        .then((response) => response.json())
        .then((respData) => {

            let ruleValid = true;
            let ruleValidMessage = "Rule is valid";


            let modalCompareString =
                `${modalRuleSourceAddress}${modalRuleDestAddress}`
                + `${modalRuleProtocol}${modalRuleSourcePort}`
                + `${modalRuleDestPort}${modalRuleDirection}`
                + `${modalRuleAction}`;

            for (let i = 0; i < respData.results.length; i++) {
                let entry = respData.results[i];
                let existingRuleId = entry['id'];
                let existingRuleProtocol = entry['protocol'];
                let existingRuleSourceAddress = entry['source_address'];
                let existingRuleSourcePort = entry['source_port'];
                let existingRuleDestAddress = entry['destination_address'];
                let existingRuleDestPort = entry['destination_port'];
                let existingRuleDirection = entry['direction'];
                let existingRuleAction = entry['action'];

                let existingCompareString =
                    `${existingRuleSourceAddress}${existingRuleDestAddress}${existingRuleProtocol}`
                    + `${existingRuleSourcePort}${existingRuleDestPort}${existingRuleDirection}`
                    + `${existingRuleAction}`;

                // db has this constraint
                // (source_address, destination_address, protocol, source_port, destination_port, direction, action)

                if (modalCompareString === existingCompareString) {
                    // we have a rule in db that's exactly the same
                    // as this one
                    // let's check the id
                    if (modalRuleId === null) {
                        // this is an add, not an edit, so we definitely shouldn't
                        // allow this one to be added
                        ruleValid = false;
                        ruleValidMessage = "This rule conflicts with existing rule " + existingRuleId;
                        break;
                    }
                    else {
                        // user is editing an existing rule
                        // check if modelRuleId is equal to existingRuleId
                        // If so, the user is probably editing this rule so do not consider this
                        // invalid
                        if (modalRuleId === existingRuleId) {
                            // this is ok
                        }
                        else {
                            // this rule would conflict
                            ruleValid = false;
                            ruleValidMessage = "This rule chagnes would conflict with existing rule " + existingRuleId;
                            break;
                        }
                    }
                }
            }
            modalRuleValidCallBack(ruleValid);
            modalRuleValidMessageCallback(ruleValidMessage);
        });
};
