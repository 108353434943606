import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useKeycloak } from '@react-keycloak/web';
import CeleryResultsTab from "./CeleryResultsTab";
import "./CeleryResultsTable.css";
import ViewCeleryResultDialog from './modals/ViewCeleryResultDialog';
import { coalesce } from '../../../common/utility/DataUtil';
import { fetchErrorMessage } from '../../mainpage';

const rootFetchUrl = '/middleware/api/taskresult/';
const rowsPerPage = 20;


/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`celery-results-tabpanel-${index}`}
			aria-labelledby={`celery-results-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `celery-results-tab-${index}`,
		'aria-controls': `celery-results-tabpanel-${index}`,
	};
}

function createCeleryResultsRowData(id, task_id, periodic_task_name, date_created, date_done, status) {
	return {
		id,
		task_id,
		periodic_task_name,
		date_created,
		date_done,
		status,
	};
}

// needed so that reset button doesn't have to be clicked twice
let freshSearchTerm = '';

function CeleryComponent() {
	const { keycloak } = useKeycloak();

	const [tabIndex, setTabIndex] = React.useState(0);

	// snackbar state vars
	const [snackState,] = React.useState({
		snackbarVertical: 'bottom',
		snackbarHorizontal: 'center',
	});

	const { snackbarHorizontal, snackbarVertical } = snackState;

	const [celeryResultsRefreshNotificationOpen, setCeleryResultsRefreshNotificationOpen] = useState(false);
	const celeryResultsRefreshNotificationMessage = "Celery Results Data Refreshed";
	const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
	const handleFetchErrorClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setFetchErrorMessageOpen(false);
	};

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const [selected, setSelected] = useState([]);
	const [offset, setOffset] = useState(0);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [viewButtonEnabled, setViewButtonEnabled] = useState(false);

	const [selectedId, setSelectedId] = useState(0);
	const [selectedTaskId, setSelectedTaskId] = useState('N/A');
	const [selectedPeriodicTaskName, setSelectedPeriodicTaskName] = useState('N/A');
	const [selectedDateCreated, setSelectedDateCreated] = useState('N/A');
	const [selectedDateDone, setSelectedDateDone] = useState('N/A');
	const [selectedStatus, setSelectedStatus] = useState('N/A');
	const [celerySwitch, setCelerySwitch] = useState(false);

	useEffect(() => {
		fetchData();
	}, [celerySwitch])

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	const getFreshSearchTerm = () => {
		return freshSearchTerm;
	};

	const getSanitizedSearchFieldContent = () => {
		let ret = getFreshSearchTerm();
		// TODO: we might need to url-encode the search term here
		return ret;
	};

	const fetchData = () => {
		// todo, do URLencoding on this to avoid any wonky input
		const sanitizedSearchTerm = getSanitizedSearchFieldContent();
		const orderPrefix = (order === 'desc' ? '-' : '');

		fetch(rootFetchUrl
			+ '?limit=' + rowsPerPage
			+ '&offset=' + offset
			+ '&page=' + (page + 1)
			+ (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
			+ '&ordering=' + orderPrefix + orderBy, {
			method: 'GET',
			headers: {
				'access-token': keycloak.token
			},
		}).then((response) => {
			if (!response.ok) throw new Error(response.status);
			else return response.json();
		})
			.then((respData) => {
				setTotal(respData.count);

				let tmpRows = [];
				for (let i = 0; i < respData.results.length; i++) {
					let entry = respData.results[i];
					tmpRows.push(createCeleryResultsRowData(entry['id'], entry['task_id'], coalesce(entry['periodic_task_name'], "-"),
						coalesce(entry['date_created'], "-"), coalesce(entry['date_done'], "-"),
						coalesce(entry['status'], "-")));
				}

				setSelectedId(0);
				setSelectedPeriodicTaskName('N/A');
				setSelectedTaskId('N/A');
				setSelectedDateCreated('N/A');
				setSelectedDateDone('N/A');
				setSelectedStatus('-');
				setSelected([]);
				setRows(tmpRows);
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
		setCelerySwitch(false);
	};

	const fetchModalData = () => {
		fetch(rootFetchUrl
			+ selectedId + '/', {
			method: 'GET',
			headers: {
				'access-token': keycloak.token
			},
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				setSelectedId(respData['id']);
				setSelectedTaskId(respData['task_id']);
				setSelectedPeriodicTaskName(coalesce(respData['periodic_task_name'], "-"));
				setSelectedDateCreated(coalesce(respData['date_created'], "-"));
				setSelectedDateDone(coalesce(respData['date_done'], "-"));
				setSelectedStatus(coalesce(respData['status'], "-"));
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
	};

	const handleSearchTermChanged = (val) => {
		setSearchTerm(val);
		freshSearchTerm = val;
	};

	const handleViewCeleryResultButtonClick = () => {
		fetchModalData();
		setModalOpen(true);
	}

	const handleModalClose = () => {
		setModalOpen(false);
		setSelected([]);
		handleCeleryResultSelected(0);
		setViewButtonEnabled(false);
	};

	const handleCeleryResultSelected = (val) => {
		setSelectedId(val);
	};

	const handleCeleryResultsRefreshNotificationClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setCeleryResultsRefreshNotificationOpen(false);
	};

	const displayCeleryResultsRefreshStarted = (event) => {
		setCeleryResultsRefreshNotificationOpen(true);
	};

	const handleCeleryResultsRefreshButtonClick = (event) => {
		fetchData();
		displayCeleryResultsRefreshStarted();
	};

	const getOrderBy = () => {
		return orderBy;
	}

	const getOrder = () => {
		return order;
	}

	return (
		<Box sx={{ width: '100%' }} id="celery-results-top-level-box">
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Snackbar
					open={celeryResultsRefreshNotificationOpen}
					autoHideDuration={notificationAutoclose}
					onClose={handleCeleryResultsRefreshNotificationClosed}
					anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
				>
					<MuiAlert
						className="logi-snackbar-notification-message"
						severity="info"
						variant="filled"
						sx={{ width: '100%' }}>
						{celeryResultsRefreshNotificationMessage}
					</MuiAlert>
				</Snackbar>
				<Snackbar
					open={fetchErrorMessageOpen}
					autoHideDuration={notificationAutoclose}
					onClose={handleFetchErrorClosed}
					anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
				>
					<MuiAlert
						className="logi-snackbar-notification-message"
						severity="info"
						variant="filled"
						sx={{ width: '100%' }}>
						{fetchErrorMessage}
					</MuiAlert>
				</Snackbar>
				<ViewCeleryResultDialog
					open={modalOpen}
					onClose={handleModalClose}
					selectedTaskId={selectedTaskId}
					selectedPeriodicTaskName={selectedPeriodicTaskName}
					selectedDateDone={selectedDateDone}
					selectedDateCreated={selectedDateCreated}
					selectedStatus={selectedStatus}
				/>
				<Tabs value={tabIndex} onChange={handleTabChange} aria-label="Celery Results Tab Panel">
					<Tab label="Celery Results" {...a11yProps(0)} />
				</Tabs>
			</Box>
			<TabPanel value={tabIndex} index={0}>
				<CeleryResultsTab
					viewButtonEnabled={viewButtonEnabled}
					viewButtonClickListener={handleViewCeleryResultButtonClick}
					onSelectRowId={handleCeleryResultSelected}
					viewButtonToggle={setViewButtonEnabled}
					order={getOrder}
					orderSetter={setOrder}
					orderBy={getOrderBy}
					orderBySetter={setOrderBy}
					setCelerySwitch={setCelerySwitch}
					selected={selected}
					selectedSetter={setSelected}
					offset={offset}
					offsetSetter={setOffset}
					page={page}
					pageSetter={setPage}
					rows={rows}
					total={total}
					totalSetter={setTotal}
					searchTerm={searchTerm}
					searchTermSetter={handleSearchTermChanged}
					fetchData={fetchData}
					rowsPerPage={rowsPerPage}
					refreshButtonClickHandler={handleCeleryResultsRefreshButtonClick}
				/>
			</TabPanel>
		</Box>
	);

};

export default CeleryComponent;