import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useKeycloak } from '@react-keycloak/web';
import LicenseKeysTab from './LicenseKeysTab';
import './LicenseKeysTable.css';
import { LOGI_ROLE_LICENSE_KEYS_READ } from '../../../common/LogiRoles';
import EditLicenseKeyDialog from './modals/EditLicenseKeyDialog';
import { Typography } from '@mui/material';
import { fetchErrorMessage } from '../../mainpage';

const RESPONSE_KEY__BLL = 'BlackLotusLicenseSettings';
const RESPONSE_KEY__MAXMIND = 'MaxmindLicenseSettings';
const ROW_KEY__BLL = 'BlackLotusLabs';
const ROW_KEY__MAXMIND = 'Maxmind';

const rootFetchUrl = '/middleware/api/licensekeys/';
const bllFetchUrl = '/middleware/api/bll_license/';
const bllLicenseValidatorUrl = '/middleware/api/bll_license_validator/';
const maxmindFetchUrl = '/middleware/api/max_license/';
const maxmindLicenseValidatorUrl = '/middleware/api/max_license_validator/';

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`license-keys-tabpanel-${index}`}
			aria-labelledby={`license-keys-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `license-keys-tab-${index}`,
		'aria-controls': `license-keys-tabpanel-${index}`,
	};
}

function createData(name, id, license_key, updated) {
	return {
		name,
		id,
		license_key,
		updated,
	};
}

function responseDataToRows(responseData) {

	let ret = [];
	let bllKeyInfo = responseData[RESPONSE_KEY__BLL];

	if (bllKeyInfo) {
		ret.push(createData(ROW_KEY__BLL, bllKeyInfo[0].id, bllKeyInfo[0].license_key, bllKeyInfo[0].updated));
	}
	else {
		ret.push(createData(ROW_KEY__BLL, 0, 'NotSet', 'NotSet'));
	}

	let maxmindKeyInfo = responseData[RESPONSE_KEY__MAXMIND];

	if (maxmindKeyInfo) {
		ret.push(createData(ROW_KEY__MAXMIND, maxmindKeyInfo[0].id, maxmindKeyInfo[0].license_key, maxmindKeyInfo[0].updated));
	}
	else {
		ret.push(createData(ROW_KEY__MAXMIND, 0, 'NotSet', 'NotSet'));
	}

	return ret;
};

function LicenseMgmtComponent(props) {

	const { keycloak } = useKeycloak();

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	const [tabIndex, setTabIndex] = React.useState(0);

	// snackbar state vars
	const [snackState,] = React.useState({
		snackbarVertical: 'bottom',
		snackbarHorizontal: 'center',
	});

	const { snackbarHorizontal, snackbarVertical } = snackState;

	const [licenseKeysRefreshNotificationOpen, setLicenseKeysRefreshNotificationOpen] = useState(false);
	const licenseKeysRefreshNotificationMessage = "License Keys Data Refreshed";
	const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
	const handleFetchErrorClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setFetchErrorMessageOpen(false);
	};

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const [selected, setSelected] = useState([]);
	const [rows, setRows] = useState([]);
	const [selectedLicenseName, setSelectedLicenseName] = useState('');
	const [selectedLicenseId, setSelectedLicenseId] = useState(0);
	const [editButtonEnabled, setEditButtonEnabled] = useState(false);

	// modal related vars
	const [modalOpen, setModalOpen] = useState(false);
	const [modalLicenseKeyTextFieldContent, setModalLicenseKeyTextFieldContent] = useState('N/A');
	const [modalUpdatedTextFieldContent, setModalUpdatedTextFieldContent] = useState('N/A');
	const [updateButtonEnabled, setUpdateButtonEnabled] = useState(false);
	const [verifyButtonEnabled,] = useState(true);
	const [verificationResultText, setVerificationResultText] = useState("");

	const hasLicenseKeysRead = keycloak.hasRealmRole(LOGI_ROLE_LICENSE_KEYS_READ);

	const fetchModalData = async () => {
		let myurl = null;
		switch (selectedLicenseName) {
			case ROW_KEY__BLL:
				myurl = bllFetchUrl
				break;
			case ROW_KEY__MAXMIND:
				myurl = maxmindFetchUrl;
				break;
			default:
				break;
		}

		if (myurl === null) {
			return;
		}

		await fetch(myurl
			+ selectedLicenseId + '/', {
			method: 'GET',
			headers: {
				'access-token': keycloak.token
			},
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				setModalLicenseKeyTextFieldContent(respData['license_key']);
				setModalUpdatedTextFieldContent(respData['updated']);
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
	};

	const updateLicenseKey = async () => {
		let myurl = null;
		switch (selectedLicenseName) {
			case ROW_KEY__BLL:
				myurl = bllFetchUrl
				break;
			case ROW_KEY__MAXMIND:
				myurl = maxmindFetchUrl;
				break;
			default:
				break;
		}

		if (myurl === null) {
			return;
		}
		let patchData = { license_key: modalLicenseKeyTextFieldContent };
		await fetch(myurl + selectedLicenseId + '/', {
			method: 'PATCH',
			headers: {
				'access-token': keycloak.token,
				'Content-Type': 'application/json'
			},
			// TODO: sanitize this value the user typed in !
			body: JSON.stringify(patchData)
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				setModalLicenseKeyTextFieldContent(respData['license_key']);
				setModalUpdatedTextFieldContent(respData['updated']);
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
	};

	const fetchData = async () => {
		await fetch(rootFetchUrl, {
			method: 'GET',
			headers: {
				'access-token': keycloak.token
			},
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				let tmpRows = responseDataToRows(respData);
				setRows(tmpRows);
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
	};

	const handleModalClose = (value) => {
		setModalOpen(false);
		setEditButtonEnabled(false)
		setSelectedLicenseId(0);
		setSelectedLicenseName('');
		setSelected([]);
	};

	const handleEditRowButtonClick = () => {
		fetchModalData();
		setModalOpen(true);
	};

	const handleLicenseKeyIdSelected = (val) => {
		setSelectedLicenseId(val);
	};

	const handleUpdateButtonClick = (event) => {
		updateLicenseKey();
	};

	const handleLicenseKeyTextFieldChanged = (event) => {
		setUpdateButtonEnabled(true);
		setModalLicenseKeyTextFieldContent(event.target.value);
	};

	const validateLicenseKey = async () => {
		let myurl = null;
		switch (selectedLicenseName) {
			case ROW_KEY__BLL:
				myurl = bllLicenseValidatorUrl;
				break;
			case ROW_KEY__MAXMIND:
				myurl = maxmindLicenseValidatorUrl;
				break;
			default:
				break;
		}

		if (myurl === null) {
			return;
		}

		let postData = { license_key: modalLicenseKeyTextFieldContent };
		await fetch(myurl, {
			method: 'POST',
			headers: {
				'access-token': keycloak.token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(postData)
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				// response looks like
				// {
				//     "license_key": [
				//         "Valid"
				//     ]
				// }

				// license_key will be a 1 element array
				let resultArray = respData['license_key'];
				let result = resultArray[0];

				if (result === "Valid") {
					setVerificationResultText("Valid");
					setUpdateButtonEnabled(true);
				}
				else {
					setVerificationResultText("License key invalid!");
					setUpdateButtonEnabled(false);
				}
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
	};

	const handleVerifyButtonClick = (event) => {
		validateLicenseKey();
	};

	const handleLicenseKeysRefreshNotificationClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setLicenseKeysRefreshNotificationOpen(false);
	};

	const displayLicenseKeysRefreshStarted = (event) => {
		setLicenseKeysRefreshNotificationOpen(true);
	};

	const handleLicenseKeysRefreshButtonClick = (event) => {
		setSelected([]);
		fetchData();
		displayLicenseKeysRefreshStarted();
	};

	const getOrderBy = () => {
		return orderBy;
	}

	const getOrder = () => {
		return order;
	}

	if (hasLicenseKeysRead) {
		return (
			<Box sx={{ width: '100%' }} id="license-keys-top-level-box">
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Snackbar
						open={licenseKeysRefreshNotificationOpen}
						autoHideDuration={notificationAutoclose}
						onClose={handleLicenseKeysRefreshNotificationClosed}
						anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
					>
						<MuiAlert
							className="logi-snackbar-notification-message"
							severity="info"
							variant="filled"
							sx={{ width: '100%' }}>
							{licenseKeysRefreshNotificationMessage}
						</MuiAlert>
					</Snackbar>
					<Snackbar
						open={fetchErrorMessageOpen}
						autoHideDuration={notificationAutoclose}
						onClose={handleFetchErrorClosed}
						anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
					>
						<MuiAlert
							className="logi-snackbar-notification-message"
							severity="info"
							variant="filled"
							sx={{ width: '100%' }}>
							{fetchErrorMessage}
						</MuiAlert>
					</Snackbar>
					<EditLicenseKeyDialog
						selectedLicenseName={selectedLicenseName}
						open={modalOpen}
						onClose={handleModalClose}
						keycloak={keycloak}
						licenseKeyTextFieldContent={modalLicenseKeyTextFieldContent}
						licenseKeyTextFieldChangeListener={handleLicenseKeyTextFieldChanged}
						updateButtonEnabled={updateButtonEnabled}
						updatedTextFieldContent={modalUpdatedTextFieldContent}
						updateButtonClickHandler={handleUpdateButtonClick}
						verifyButtonEnabled={verifyButtonEnabled}
						verificationResultText={verificationResultText}
						verifyButtonClickHandler={handleVerifyButtonClick}
					/>
					<Tabs value={tabIndex} onChange={handleTabChange} aria-label="License Keys Tab Panel">
						<Tab label="License Keys" {...a11yProps(0)} />
					</Tabs>
				</Box>
				<TabPanel value={tabIndex} index={0}>
					<LicenseKeysTab
						editButtonEnabled={editButtonEnabled}
						editButtonClickListener={handleEditRowButtonClick}
						onSelectRowId={handleLicenseKeyIdSelected}
						editButtonToggle={setEditButtonEnabled}
						order={getOrder}
						orderSetter={setOrder}
						orderBy={getOrderBy}
						orderBySetter={setOrderBy}
						selected={selected}
						selectedSetter={setSelected}
						selectedLicenseNameSetter={setSelectedLicenseName}
						rows={rows}
						fetchData={fetchData}
						refreshButtonClickHandler={handleLicenseKeysRefreshButtonClick}
					/>
				</TabPanel>
			</Box>
		);
	}
	else {
		return (<div />);
	}
};

export default LicenseMgmtComponent;
