import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useKeycloak } from '@react-keycloak/web';
import { coalesce } from '../../common/utility/DataUtil'
import RuleTab from './PcapRuleTab';
import PcapAddRuleDialog from './modals/AddPcapRuleDialog';
import PcapEditRuleDialog from './modals/EditPcapRuleDialog';

import './PcapComponent.css';
import { fetchErrorMessage } from '../mainpage';
import {
  PC_DIRECTION_INBOUND
}
  from '../../common/validation/pcap/PcapValidator';

const rowsPerPage = 20;

const rulesUrl = '/middleware/api/pcap_rules/';
const rulesIPv4Url = '/middleware/api/pcap_rules_v4/';
const rulesIPv6Url = '/middleware/api/pcap_rules_v6/';


/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

const defaultPcapSearchOption = "Both";

const pcapSearchOptions = [
  "IPv4",
  "IPv6"
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`pcap-ip-addresses-tabpanel-${index}`}
      aria-labelledby={`pcap-ip-addresses-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `pcap-ip-addresses-tab-${index}`,
    'aria-controls': `pcap-ip-addresses-tabpanel-${index}`,
  };
}

function createPcapRuleRowData(id, sourceAddress, destinationAddress, bidirectional, direction, created) {
  return {
    id,
    sourceAddress,
    destinationAddress,
    bidirectional,
    direction,
    created
  };
}


// needed so that you don't have to click the refresh button 
let freshpcapRuleSearchTerm = '';

export default function PcapComponent() {
  const { keycloak } = useKeycloak();

  const [tabIndex, setTabIndex] = React.useState(0);

  // snackbar state vars
  const [snackState,] = React.useState({
    snackbarVertical: 'bottom',
    snackbarHorizontal: 'center',
  });

  const { snackbarHorizontal, snackbarVertical } = snackState;

  const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
  const handleFetchErrorClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFetchErrorMessageOpen(false);
  };

  // rules pcap tab state vars
  const [pcapRuleOrder, setPcapRuleOrder] = useState('asc');
  const [pcapRuleOrderBy, setPcapRuleOrderBy] = useState('sourceAddress');
  const [ruleTableData, setRuleTableData] = useState([]);
  const [pcapRuleAddModalOpen, setPcapRuleAddModalOpen] = useState(false);
  const [pcapRuleEditModalOpen, setPcapRuleEditModalOpen] = useState(false);
  const [deleteRuleButtonEnabled, setDeleteRuleButtonEnabled] = useState(false);
  const [editRuleButtonEnabled, setEditRuleButtonEnabled] = useState(false);
  const [uploadRuleButtonEnabled, setUploadRuleButtonEnabled] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState(0);
  const [pcapRuleOffset, setPcapRuleOffset] = useState(0);
  const [pcapRulePage, setPcapRulePage] = useState(0);
  const [pcapRuleSelected, setPcapRuleSelected] = useState([]);
  const [pcapRuleRows, setPcapRuleRows] = useState([]);
  const [pcapRuleTotal, setPcapRuleTotal] = useState(0);
  const [, setSelectedRulePcapId] = useState(0);
  const [, setSelectedRulePcapRule] = useState('');
  const [, setSelectedRulePcapCreated] = useState('');
  const [pcapRuleSearchTerm, setpcapRuleSearchTerm] = useState('');
  const [, setViewPcapRuleButtonEnabled] = useState(false);
  const [searchpcapVersion, setSearchpcapVersion] = useState(defaultPcapSearchOption);
  const [pcapRuleSwitch, setPcapRuleSwitch] = useState(false);
  const [pcapRuleRefreshNotificationOpen, setPcapRuleRefreshNotificationOpen] = useState(false);
  const pcapRuleRefreshNotificationMessage = "PCAP Rule Data Refreshed";

  const [pcapRuleEditModalSourceAddress, setPcapRuleEditModalSourceAddress] = useState("");
  const [pcapRuleEditModalDestinationAddress, setPcapRuleEditModalDestinationAddress] = useState("");
  const [pcapRuleEditModalBidirectional, setPcapRuleEditModalBidirectional] = useState(true);
  const [pcapRuleEditModalDirection, setPcapRuleEditModalDirection] = useState(PC_DIRECTION_INBOUND);
  const [pcapRuleEditModalDuration, setPcapRuleEditModalDuration] = useState("-");

  const [pcapRuleAddModalSourceAddress, setPcapRuleAddModalSourceAddress] = useState("");
  const [pcapRuleAddModalDestinationAddress, setPcapRuleAddModalDestinationAddress] = useState("");
  const [pcapRuleAddModalBidirectional, setPcapRuleAddModalBidirectional] = useState(true);
  const [pcapRuleAddModalDirection, setPcapRuleAddModalDirection] = useState(PC_DIRECTION_INBOUND);
  const [pcapRuleAddModalDuration, setPcapRuleAddModalDuration] = useState("-");


  // Switches useEffects go below.
  useEffect(() => {
    fetchPcapRuleData();
  }, [pcapRuleSwitch])


  const handlePcapRuleAddModalClose = () => {
    setPcapRuleAddModalOpen(false);
    setEditRuleButtonEnabled(false)
    setDeleteRuleButtonEnabled(false)
    fetchPcapRuleData()
  }

  const handlePcapRuleEditModalClose = () => {
    setPcapRuleEditModalOpen(false);
    setEditRuleButtonEnabled(false)
    setDeleteRuleButtonEnabled(false)
    fetchPcapRuleData()
  }

  const handleAddPcapRuleButtonClick = (event) => {
    // display modal
    setPcapRuleAddModalOpen(true);
  };

  const handleEditPcapRuleButtonClick = (event) => {
    // fetch modal data
    fetchPcapRuleModalData();
    // display modal
    setPcapRuleEditModalOpen(true);
  };

  const handlePcapRuleRowSelection = (val) => {
    setSelectedRulePcapId(val);
    setSelectedRulePcapRule("");
    setSelectedRulePcapCreated("");
    setSelectedRuleId(val);
  };

  const getFreshpcapRuleSearchTerm = () => {
    return freshpcapRuleSearchTerm;
  }

  const getSanitizedpcapRuleSearchFieldContent = () => {
    let ret = getFreshpcapRuleSearchTerm();
    return encodeURIComponent(ret);
  }

  const fetchPcapRuleData = () => {
    const sanitizedSearchTerm = getSanitizedpcapRuleSearchFieldContent();
    const ipFilter = searchpcapVersion;
    // console.log("IP Filter: ", ipFilter);
    const ruleOrderPrefix = (pcapRuleOrder === 'desc' ? '-' : '');
    let pcapRuleUrl = rulesUrl;
    if (ipFilter === 'Both') {
      pcapRuleUrl = rulesUrl;
      // console.log("It's Both.");
    } else if (ipFilter === 'IPv4') {
      pcapRuleUrl = rulesIPv4Url;
      // console.log("It's IPv4");
    } else if (ipFilter === 'IPv6') {
      pcapRuleUrl = rulesIPv6Url;
      // console.log("It's IPv6");
    }
    // console.log("What URL: ", pcapRuleUrl)

    fetch(pcapRuleUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + pcapRuleOffset
      + '&page=' + (pcapRulePage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + ruleOrderPrefix + pcapRuleOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setPcapRuleTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createPcapRuleRowData(entry['id'],
            entry['source_address'],
            entry['destination_address'],
            entry['bidirectional'],
            entry['direction'],
            coalesce(entry['created'], "-")));
        }

        setPcapRuleSelected([]);
        setPcapRuleRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setPcapRuleSwitch(false);
  };

  const fetchPcapRuleModalData = () => {
    fetch(rulesUrl
      + selectedRuleId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setPcapRuleEditModalSourceAddress(respData['source_address']);
        setPcapRuleEditModalBidirectional(respData['bidirectional']);
        setPcapRuleEditModalDirection(respData['direction']);
        setPcapRuleEditModalDestinationAddress(respData['destination_address']);
        setPcapRuleEditModalDuration(coalesce(respData['created'], "-"))
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };


  const handlepcapRuleVersionTermChanged = (val) => {
    setSearchpcapVersion(val);
  };

  const handlepcapRuleSearchTermChanged = (val) => {
    setpcapRuleSearchTerm(val);
    freshpcapRuleSearchTerm = val;
  };

  const handlePcapRuleAddSourceAddressChanged = (val) => {
    setPcapRuleAddModalSourceAddress(val);
  };

  const handlePcapRuleAddModalBidirectionalChanged = (val) => {
    setPcapRuleAddModalBidirectional(val);
  };

  const handlePcapRuleAddModalDirectionChanged = (val) => {
    setPcapRuleAddModalDirection(val);
  };

  const handlePcapRuleAddModalDestinationAddressChanged = (val) => {
    setPcapRuleAddModalDestinationAddress(val);
  };

  const handlePcapRuleEditSourceAddressChanged = (val) => {
    setPcapRuleEditModalSourceAddress(val);
  };

  const handlePcapRuleEditModalBidirectionalChanged = (val) => {
    setPcapRuleEditModalBidirectional(val);
  };

  const handlePcapRuleEditModalDirectionChanged = (val) => {
    setPcapRuleEditModalDirection(val);
  };

  const handlePcapRuleEditModalDestinationAddressChanged = (val) => {
    setPcapRuleEditModalDestinationAddress(val);
  };

  const handlePcapRuleRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setPcapRuleRefreshNotificationOpen(false);
  };

  const displayPcapRuleRefreshStarted = (event) => {
    setPcapRuleRefreshNotificationOpen(true);
  };

  const handleRulePcapRefreshButtonClick = (event) => {
    fetchPcapRuleData();
    displayPcapRuleRefreshStarted();
  };

  const getPcapRuleOrder = () => {
    return pcapRuleOrder;
  }

  const getPcapRuleCollectionOrderBy = () => {
    return pcapRuleOrderBy;
  }

  const handleRuleSelected = (id) => {
    setSelectedRuleId(id);
    if (id !== 0) {
      setDeleteRuleButtonEnabled(true);
    }
    else {
      setDeleteRuleButtonEnabled(false);
    }
  };


  const deleteRule = (refreshCallBack) => {
    fetch(rulesUrl
      + selectedRuleId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        setDeleteRuleButtonEnabled(false);
        refreshCallBack();
      });
  };

  const handleDeleteRuleButtonClick = (refreshCallBack) => {
    deleteRule(refreshCallBack);
  };

  const handleToggleViewPcapManualBlockButtonVisibility = (val) => {
    setViewPcapRuleButtonEnabled(val);
  };

  return (
    <Box sx={{ width: '100%' }} id="pcap-ip-addresses-top-level-box">
      <Snackbar
        open={fetchErrorMessageOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleFetchErrorClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {fetchErrorMessage}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={pcapRuleRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handlePcapRuleRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {pcapRuleRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <PcapAddRuleDialog
        open={pcapRuleAddModalOpen}
        onClose={handlePcapRuleAddModalClose}
        fetchData={fetchPcapRuleData}
        sourceAddress={pcapRuleAddModalSourceAddress}
        sourceAddressSetter={handlePcapRuleAddSourceAddressChanged}
        bidirectional={pcapRuleAddModalBidirectional}
        bidirectionalSetter={handlePcapRuleAddModalBidirectionalChanged}
        direction={pcapRuleAddModalDirection}
        directionSetter={handlePcapRuleAddModalDirectionChanged}
        destinationAddress={pcapRuleAddModalDestinationAddress}
        destinationAddressSetter={handlePcapRuleAddModalDestinationAddressChanged}
      />
      <PcapEditRuleDialog
        selectedId={selectedRuleId}
        open={pcapRuleEditModalOpen}
        onClose={handlePcapRuleEditModalClose}
        fetchData={fetchPcapRuleData}
        sourceAddress={pcapRuleEditModalSourceAddress}
        sourceAddressSetter={handlePcapRuleEditSourceAddressChanged}
        bidirectional={pcapRuleEditModalBidirectional}
        bidirectionalSetter={handlePcapRuleEditModalBidirectionalChanged}
        direction={pcapRuleEditModalDirection}
        directionSetter={handlePcapRuleEditModalDirectionChanged}
        destinationAddress={pcapRuleEditModalDestinationAddress}
        destinationAddressSetter={handlePcapRuleEditModalDestinationAddressChanged}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} aria-label="PCAP Tab Panel">
          <Tab label="Targeted PCAP" {...a11yProps(0)} />
          {/* <Tab label="Collection Names" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <RuleTab
          onSelectRowId={handlePcapRuleRowSelection}
          order={getPcapRuleOrder}
          orderSetter={setPcapRuleOrder}
          orderBy={getPcapRuleCollectionOrderBy}
          orderBySetter={setPcapRuleOrderBy}
          setPcapRuleSwitch={setPcapRuleSwitch}
          selected={pcapRuleSelected}
          selectedSetter={setPcapRuleSelected}
          offset={pcapRuleOffset}
          offsetSetter={setPcapRuleOffset}
          page={pcapRulePage}
          pageSetter={setPcapRulePage}
          rows={pcapRuleRows}
          total={pcapRuleTotal}
          totalSetter={setPcapRuleTotal}
          rowsPerPage={rowsPerPage}
          refreshButtonClickHandler={handleRulePcapRefreshButtonClick}
          handleRowSelected={handleRuleSelected}
          fetchData={fetchPcapRuleData}
          rulesTableData={ruleTableData}
          setRulesTableData={setRuleTableData}
          addButtonClickListener={handleAddPcapRuleButtonClick}
          editButtonClickListener={handleEditPcapRuleButtonClick}
          editButtonEnabled={editRuleButtonEnabled}
          editButtonToggle={setEditRuleButtonEnabled}
          deleteButtonEnabled={deleteRuleButtonEnabled}
          deleteButtonClickHandler={handleDeleteRuleButtonClick}
          deleteButtonToggle={setDeleteRuleButtonEnabled}
          uploadButtonEnabled={uploadRuleButtonEnabled}
          uploadButtonEnabledSetter={setUploadRuleButtonEnabled}
          viewButtonToggle={handleToggleViewPcapManualBlockButtonVisibility}
          searchTerm={pcapRuleSearchTerm}
          searchTermSetter={handlepcapRuleSearchTermChanged}
          pcapSearchOptions={pcapSearchOptions}
          defaultPcapSearchOption={defaultPcapSearchOption}
          searchPcapVersion={searchpcapVersion}
          searchPcapVersionSetter={handlepcapRuleVersionTermChanged}
        />
      </TabPanel>
    </Box>
  );
}
