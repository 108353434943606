import React from 'react';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function EditScanPolicyDialog(props) {
    const {
        onClose,
        open,
        name,
        nameSetter,
        nameValid,
        nameErrMessage,
        description,
        descriptionSetter,
        active,
        activeSetter,
        priority,
        prioritySetter,
        priorityValid,
        priorityErrMessage,
        sourceContainer,
        sourceContainerSetter,
        destinationContainer,
        destinationContainerSetter,
        container4Options,
        container6Options,
        updateButtonEnabled,
        updateButtonClickListener,
        validateButtonClickListener,
        validationMessage,
        ipVersion,
        ipVersionSetter,
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleNameContentChange = (event) => {
        nameSetter(event.target.value);
    };

    const handleDescriptionContentChange = (event) => {
        descriptionSetter(event.target.value);
    };

    const handlePriorityContentChange = (event) => {
        prioritySetter(event.target.value);
    };

    const handleActiveChange = (event) => {
        activeSetter(event.target.checked);
    };

    const handleSourceContainerChange = (event) => {
        sourceContainerSetter(event.target.value);
    };

    const handleDestinationContainerChange = (event) => {
        destinationContainerSetter(event.target.value);
    };

    const handleUpdateButtonClicked = (event) => {
        updateButtonClickListener();
    };

    const handlValidateButtonClicked = (event) => {
        validateButtonClickListener();
    };

    //const [containerOptions, setContainerOptions] = useState({ ipVersion } == 4 ? container4Options() : container6Options());

    const handleIPVersionChange = (event) => {
        let val = event.target.value
        ipVersionSetter(val);
        // setContainerOptions(val === 4 ? container4Options() : container6Options());
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Scan Policy</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        {...(nameValid !== true && { error: undefined })}
                        helperText={nameErrMessage}
                        label="Scan Policy Name"
                        variant="outlined"
                        size="medium"
                        value={name}
                        onChange={handleNameContentChange}
                        style={{marginTop: '15px'}}
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        size="medium"
                        value={description}
                        multiline
                        minRows="5"
                        onChange={handleDescriptionContentChange}
                    />
                    <TextField
                        {...(priorityValid !== true && { error: undefined })}
                        helperText={priorityErrMessage}
                        label="Priority"
                        variant="outlined"
                        size="medium"
                        value={priority}
                        onChange={handlePriorityContentChange}
                    />
                    <FormControl>
                        <InputLabel id="scan-policy-table-edit-modal-dialog-ip-version-select-label">IP Version</InputLabel>
                        <Select
                            labelId="scan-policy-table-edit-modal-dialog-ip-version-select-label"
                            id="scan-policy-table-edit-modal-dialog-ip-version-select"
                            label="IP Version"
                            onChange={handleIPVersionChange}
                            defaultValue={4}
                            value={ipVersion()}
                        >
                            {[4, 6].map((ipv) => {
                                let isSelected = ipVersion() === ipv;
                                return (
                                    <MenuItem selected={isSelected} value={ipv}>{ipv}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={active}
                            onChange={handleActiveChange}
                            id="scan-policy-table-edit-modal-dialog-scan-policy-active-checkbox"
                        />} label="Active" />
                    </FormGroup>
                    <FormControl>
                        <InputLabel id="scan-policy-table-edit-modal-dialog-source-container-select-label">Source Container</InputLabel>
                        <Select
                            labelId="scan-policy-table-edit-modal-dialog-source-container-select-label"
                            id="scan-policy-table-edit-modal-dialog-source-container-select"
                            label="Source Container"
                            onChange={handleSourceContainerChange}
                            value={sourceContainer}
                        >                            
                            {
                                (ipVersion() === 4 ? container4Options() : container6Options()).map((container) => {
                                let isSelected = container.id === sourceContainer;
                                return (
                                    <MenuItem selected={isSelected} value={container.id}>{container.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="scan-policy-table-edit-modal-dialog-destination-container-select-label">Destination Container</InputLabel>
                        <Select
                            labelId="scan-policy-table-edit-modal-dialog-destination-container-select-label"
                            id="scan-policy-table-edit-modal-dialog-destination-container-select"
                            label="Destination Container"
                            onChange={handleDestinationContainerChange}
                            value={destinationContainer}
                        >
                            {(ipVersion() === 4 ? container4Options() : container6Options()).map((container) => {
                                let isSelected = container.id === destinationContainer;
                                return (
                                    <MenuItem selected={isSelected} value={container.id}>{container.name}</MenuItem>
                                )
                            })}
                        </Select>
                        <TextField
                            label="Validation Status"
                            variant="outlined"
                            size="medium"
                            value={validationMessage}
                        />
                    </FormControl>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="scan-policy-table-edit-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="scan-policy-table-edit-modal-dialog-validate-button"
                            variant="contained"
                            onClick={handlValidateButtonClicked}>Validate</Button>
                        <Button id="scan-policy-table-edit-modal-dialog-update-button"
                            variant="contained"
                            onClick={handleUpdateButtonClicked}
                            disabled={!updateButtonEnabled}>Update</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}  