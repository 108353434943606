import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols
}
    from '../../../common/table/TableHelper';
import EnhancedTableToolbar from '../../../common/table/EnhancedTableToolbar';
import SingleSelectTableHead from '../../../common/table/SingleSelectTableHead';
import {
    LOGI_ROLE_SCHEDULED_TASKS_CRON_ADD,
    LOGI_ROLE_SCHEDULED_TASKS_CRON_DELETE,
    LOGI_ROLE_SCHEDULED_TASKS_CRON_EDIT
} from '../../../common/LogiRoles';

const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Id',
        hide: true
    },
    {
        id: 'minute',
        numeric: false,
        disablePadding: true,
        label: 'Minute',
        hide: true
    },
    {
        id: 'hour',
        numeric: false,
        disablePadding: true,
        label: 'Hour',
        hide: true
    },
    {
        id: 'day_of_week',
        numeric: false,
        disablePadding: true,
        label: 'Day of Week',
        hide: true
    },
    {
        id: 'day_of_month',
        numeric: false,
        disablePadding: true,
        label: 'Day of Month',
        hide: true
    },
    {
        id: 'month_of_year',
        numeric: false,
        disablePadding: true,
        label: 'Month of Year',
        hide: true
    },
    // this will be a calculated value based on the fields above
    {
        id: 'cron_expression',
        numeric: false,
        disablePadding: true,
        label: 'Cron Expression',
        hide: false
    },
];

const hiddenCols = populateHiddenCols(headCells);

function createCronExpression(minute, hour, day_of_week, day_of_month, month_of_year, timezone) {
    let ret = minute + ' '
        + hour + ' '
        + day_of_week + ' '
        + day_of_month + ' '
        + month_of_year + ' '
        + timezone;
    return ret;
}

function getRowCells(row, labelId, isItemSelected, handleClick) {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("id") ? (<TableCell component="td" id={labelId} scope="row" padding="none">{row.id}</TableCell>) : ''}
            <TableCell align="left">{createCronExpression(row.minute, row.hour, row.day_of_week, row.day_of_month, row.month_of_year, row.timezone)}</TableCell>
        </TableRow>
    )
}

export default function CronSchedulesTab(props) {
    const { addButtonEnabled,
        addButtonClickListener,
        editButtonEnabled,
        editButtonClickListener,
        deleteButtonEnabled,
        deleteButtonClickListener,
        toggleCronData,
        onSelectRowId,
        editButtonToggle,
        deleteButtonToggle,
        setCronSwitch,
        order,
        orderSetter,
        orderBy,
        orderBySetter,
        selected,
        selectedSetter,
        offset,
        offsetSetter,
        page,
        pageSetter,
        rows,
        total,
        rowsPerPage,
        fetchData,
        refreshButtonClickHandler
    } = props;

    const { keycloak } = useKeycloak();
    const hasCronAdd = keycloak.hasRealmRole(LOGI_ROLE_SCHEDULED_TASKS_CRON_ADD);
    const hasCronEdit = keycloak.hasRealmRole(LOGI_ROLE_SCHEDULED_TASKS_CRON_EDIT);
    const hasCronDelete = keycloak.hasRealmRole(LOGI_ROLE_SCHEDULED_TASKS_CRON_DELETE);

    useEffect(() => {
        fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy() === property && order() === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        toggleCronData();
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleChangePage = (event, newPage) => {

        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        selectedSetter([]);
        pageSetter(newPage);
        setCronSwitch(true);
    };

    const handleRowClick = (event, id) => {
        if (selected[0] === id) {
            onSelectRowId(0);
            editButtonToggle(false);
            deleteButtonToggle(false);
            selectedSetter([]);
        } else {
            let newSelected = [id];
            onSelectRowId(id);
            editButtonToggle(true);
            deleteButtonToggle(true);
            selectedSetter(newSelected);
        }
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    const handleAddButtonClick = (event) => {
        addButtonClickListener(fetchData);
    };

    const handleDeleteButtonClick = (event) => {
        // TODO, do a check if this can actually be deleted(because other things might be referencing it)
        // and maybe a confirm dialog to verify this is what the user wants to do.
        // these could be combined into one dialog
        deleteButtonClickListener(fetchData);
    };

    return (
        <div id="scheduled-tasks-cron-expressions-table-top-level-div">
            <div id="scheduled-tasks-cron-expressions-table-button-bar-div">
            <Accordion style={{'borderRadius': '10px'}} defaultExpanded={true}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Cron Schedules Refresh</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Stack spacing={2} direction="row">
                    <Button
                        id="scheduled-tasks-cron-expressions-table-refresh-button"
                        variant="contained"
                        onClick={refreshButtonClickHandler}>Refresh</Button>
                </Stack>
                </AccordionDetails>
                </Accordion>
            </div>
            <div id="scheduled-tasks-cron-expressions-table-div">
                <Stack spacing={2} direction="row">
                    <Box sx={{ width: '50%' }} id="scheduled-tasks-cron-expressions-table-box">
                        <TableContainer>
                            <Table
                                sx={{ width: '100%' }}
                                aria-labelledby="tableTitle"
                                size={'small'}
                                id="scheduled-tasks-cron-expressions-table"
                            >
                                <SingleSelectTableHead
                                    numSelected={selected.length}
                                    order={order()}
                                    orderBy={orderBy()}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                    fetchMethod={fetchData}
                                    id="scheduled-tasks-cron-expressions-table-head"
                                />
                                <TableBody id="scheduled-tasks-cron-expressions-table-body">
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                    {rows.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `scheduled-tasks-cron-expressions-table-checkbox-${index}`;

                                        return (
                                            getRowCells(row, labelId, isItemSelected, handleRowClick)
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                // height: 33 * emptyRows,
                                                height: 33,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            labelDisplayedRows={defaultLabelDisplayedRows}
                        />
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </Box>
                    <div class="scheduled-tasks-cron-expressions-table-buttons-div">
                        <Stack spacing={2} direction="column">
                            {hasCronAdd ? (
                                <Button id="scheduled-tasks-cron-expressions-table-add-button"
                                    variant="contained"
                                    onClick={handleAddButtonClick}
                                    disabled={!addButtonEnabled}>Add</Button>) : ('')}
                            {hasCronEdit ? (
                                <Button id="scheduled-tasks-cron-expressions-table-edit-button"
                                    variant="contained"
                                    onClick={editButtonClickListener}
                                    disabled={!editButtonEnabled}>Edit</Button>) : ('')}
                            {hasCronDelete ? (
                                <Button id="scheduled-tasks-cron-expressions-table-delete-button"
                                    variant="contained"
                                    onClick={handleDeleteButtonClick}
                                    disabled={!deleteButtonEnabled}>Delete</Button>) : ('')}
                        </Stack>
                    </div>
                </Stack>
            </div>
        </div>
    );
}
