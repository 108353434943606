import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useKeycloak } from '@react-keycloak/web';
import {
    populateHiddenCols
}
    from '../../common/table/TableHelper';
import './GeoIPComponent.css';
import SingleSelectTableHead from '../../common/table/SingleSelectTableHead';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import { LOGI_ROLE_MAXMIND_FETCH } from '../../common/LogiRoles';
import { fetchErrorMessage } from '../mainpage';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const maxmindAdhocRefreshUrl = '/middleware/api/v3/maxmind_adhoc_refresh/';
const lastDbUpdateURL = "/middleware/api/bll_blacklotuslabsipaddress/?ppage=1&ppage_size=10&pager=custom2";

const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Id',
        hide: true,
    },
    {
        id: 'subnet',
        numeric: false,
        disablePadding: true,
        label: 'Subnet',
        hide: false,
    },
    {
        id: 'country',
        numeric: false,
        disablePadding: true,
        label: 'Country',
        hide: false,
    },
    {
        id: 'registered_country',
        numeric: true,
        disablePadding: true,
        label: 'Registered Country',
        hide: false,
    },
    {
        id: 'represented_country',
        numeric: true,
        disablePadding: true,
        label: 'Represented Country',
        hide: false,
    },
    {
        id: 'is_anycast',
        numeric: false,
        disablePadding: true,
        label: 'Routing Type',
        hide: false,
    },
];

const hiddenCols = populateHiddenCols(headCells);

function getRowCells(row, labelId, isItemSelected, handleClick) {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("id") ? (<TableCell component="td" id={labelId} scope="row" padding="none">{row.id}</TableCell>) : ''}
            {!hiddenCols.has("subnet") ? (<TableCell align="left">{row.subnet}</TableCell>) : ''}
            {!hiddenCols.has("country") ? (<TableCell align="left">{row.country}</TableCell>) : ''}
            {!hiddenCols.has("registered_country") ? (<TableCell align="left">{row.registered_country}</TableCell>) : ''}
            {!hiddenCols.has("represented_country") ? (<TableCell align="left">{row.represented_country}</TableCell>) : ''}
            {!hiddenCols.has("is_anycast") ? (<TableCell align="left">{row.is_anycast ? "Anycast" : "Unicast"}</TableCell>) : ''}
        </TableRow>
    )
}

function FilterWidget(props) {
    const { searchTerm, onSearchClick, onResetClick, onSearchTermChange, dbpull, hasMaxmindFetch,
        defaultMaxmindSearchOption, searchMaxmindVersion, maxmindSearchOptions, onSearchMaxmindVersionChange,
        defaultMaxmindAnycastOption, searchMaxmindAnycastSetter, searchMaxmindAnycast, maxmindAnycastOptions } = props;
    return (
        <form onSubmit={onSearchClick}>
            <div id="geoip-country-subnets-filter-widget-div" class="geoip-filter-widget-div">
                <div class="geoip-country-subnets-filter-widget-body-div">
                    <Stack spacing={2} direction="column">
                        <Stack id="geoip-country-subnets-filter-widget-control-stack" spacing={2} direction="row">
                            <TextField id="geoip-country-subnets-filter-widget-search-input"
                                label="Search"
                                variant="outlined"
                                size="medium"
                                value={searchTerm}
                                onChange={onSearchTermChange}
                                sx={{ m: 1, minWidth: 100, maxWidth: 410 }}
                                fullWidth
                            />
                            <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 175 }}>
                                <InputLabel id="geoip-country-subnets-filter-widget-ip-filter-select-label">IP Filter</InputLabel>
                                <Select
                                    labelId="geoip-country-subnets-filter-widget-ip-filter-select-label"
                                    id="geoip-country-subnets-filter-widget-ip-filter-select"
                                    label="IP Filter"
                                    onChange={onSearchMaxmindVersionChange}
                                    defaultValue={defaultMaxmindSearchOption}
                                    value={searchMaxmindVersion}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultMaxmindSearchOption === searchMaxmindVersion ? true : false}
                                        value={defaultMaxmindSearchOption}>{defaultMaxmindSearchOption}</MenuItem>
                                    {maxmindSearchOptions.map((ipVersion) => {
                                        let isSelected = ipVersion === searchMaxmindVersion;
                                        return (
                                            <MenuItem selected={isSelected} value={ipVersion}>{ipVersion}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 175 }}>
                                <InputLabel id="geoip-country-subnets-filter-widget-anycast-filter-select-label">Routing Type</InputLabel>
                                <Select
                                    label="Routing Type"
                                    onChange={searchMaxmindAnycastSetter}
                                    defaultValue={defaultMaxmindAnycastOption}
                                    value={searchMaxmindAnycast}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultMaxmindAnycastOption === searchMaxmindAnycast ? true : false}
                                        value={defaultMaxmindAnycastOption}>{defaultMaxmindAnycastOption}</MenuItem>
                                    {maxmindAnycastOptions.map((anycast) => {
                                        let isSelected = anycast === searchMaxmindAnycast;
                                        return (
                                            <MenuItem selected={isSelected} value={anycast}>{anycast}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack spacing={2} direction="row">
                            <Button id="geoip-country-subnets-filter-widget-search-button" variant="contained" button type="submit">Search</Button>
                            <Button id="geoip-country-subnets-filter-widget-reset-button" variant="contained" onClick={onResetClick}>Reset</Button>
                        </Stack>
                    </Stack>
                </div>
            </div>
        </form>
    );
};

export default function CountrySubnetsTab(props) {
    const {
        defaultMaxmindSearchOption,
        displayAdhocPollStartedHandler,
        fetchData,
        maxmindSearchOptions,
        maxmindAnycastOptions,
        offset,
        offsetSetter,
        onSelectRowId,
        selectRowId,
        order,
        orderBy,
        orderBySetter,
        orderSetter,
        page,
        pageSetter,
        rows,
        rowsPerPage,
        refreshButtonClickHandler,
        searchMaxmindVersion,
        searchMaxmindVersionSetter,
        setCountrySubnetSearchSwitch,
        defaultMaxmindAnycastOption,
        searchMaxmindAnycast,
        searchMaxmindAnycastSetter,
        searchTerm,
        searchTermSetter,
        selected,
        selectedSetter,
        total,
        totalSetter,
        viewButtonClickListener,
        viewButtonEnabled,
        viewButtonToggle,
    } = props;

    useEffect(() => {
        handleResetButtonClick();
        // fetchData();
        getLastDbUpdate();
    }, []);

    // keycloak state var
    const { keycloak } = useKeycloak();

    // maxmind state vars
    const [dbpull, setDbpull] = useState('Last database update not available.')

    const hasMaxmindFetch = keycloak.hasRealmRole(LOGI_ROLE_MAXMIND_FETCH);
    const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
    /* Number of milliseconds to show notifications */
    const notificationAutoclose = 5000;

    // snackbar state vars
    const [snackState,] = React.useState({
        snackbarVertical: 'bottom',
        snackbarHorizontal: 'center',
    });

    const { snackbarHorizontal, snackbarVertical } = snackState;

    const handleFetchErrorClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFetchErrorMessageOpen(false);
    };

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy() === property && order() === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        setCountrySubnetSearchSwitch(true);
        // fetchData();
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleChangePage = (event, newPage) => {

        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        selectedSetter([]);
        pageSetter(newPage);
        viewButtonToggle(false);
        setCountrySubnetSearchSwitch(true);
        // fetchData();
    };

    const handleRowClick = (event, id) => {
        if (selectRowId === id) {
            // console.log("If Row id: ", id);
            onSelectRowId(0);
            viewButtonToggle(false);
            selectedSetter([]);
        } else {
            // console.log("Else Row id: ", id);
            let newSelected = [id];
            onSelectRowId(id);
            viewButtonToggle(true);
            selectedSetter(newSelected);
        }
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    const handleSearchTermChange = (event) => {
        searchTermSetter(event.target.value);
    };

    const handleSearchButtonClick = (event) => {
        event.preventDefault();
        fetchData();
    };

    function handleResetButtonClick(event) {
        offsetSetter(0);
        pageSetter(0);
        totalSetter(0);
        orderSetter('asc');
        orderBySetter('subnet');
        searchTermSetter('');
        selectedSetter([]);
        viewButtonToggle(false);
        searchMaxmindVersionSetter(searchMaxmindVersion)
        fetchData();
        searchMaxmindVersionSetter("Both")
        searchMaxmindAnycastSetter("Both")
    }

    const handleSearchMaxmindVersionChange = (event) => {
        // console.log("Version change: ", event.target.value);
        searchMaxmindVersionSetter(event.target.value);
    };

    const handleSearchMaxmindAnyCastChange = (event) => {
        // console.log("Version change: ", event.target.value);
        searchMaxmindAnycastSetter(event.target.value);
    };

    const startAdhocMaxmindPull = () => {
        fetch(maxmindAdhocRefreshUrl, {
            method: 'GET',
            headers: {
                'access-token': keycloak.token
            }
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.text();
            })
            .then((response) => {
                displayAdhocPollStartedHandler();
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    }

    const getLastDbUpdate = () => {
        fetch(lastDbUpdateURL, {
            method: 'GET',
            headers: {
                'access-token': keycloak.token
            }
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((response) => {
                let result;
                result = response['last_updated'].find((tk) => tk.includes('Geo-IP'))
                setDbpull(result);
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    }

    return (
        <div id="geoip-country-subnets-table-top-level-div">
            <div id="geoip-country-subnets-table-button-bar-div">
                <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Country Subnets Filter / Search</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2} direction="column">
                            <Stack spacing={2} direction="row">
                                <Button
                                    id="geoip-country-subnets-table-refresh-button"
                                    variant="contained"
                                    onClick={refreshButtonClickHandler}>Refresh</Button>

                                {hasMaxmindFetch === true ? (<Button id="geoip-country-subnets-adhoc-refresh-button"
                                    variant="contained"
                                    onClick={startAdhocMaxmindPull}>Adhoc Maxmind Pull</Button>) : ('')}
                                {hasMaxmindFetch === true ? (
                                    <Stack spacing={2} direction="row">
                                        <span style={{ 'padding': '10px', 'margin': '10px' }}>Last Pull info: </span>
                                        <Alert severity="info">{dbpull}</Alert>
                                    </Stack>) : ('')}
                            </Stack>
                            <FilterWidget
                                searchTerm={searchTerm}
                                onSearchClick={handleSearchButtonClick}
                                onResetClick={handleResetButtonClick}
                                onSearchTermChange={handleSearchTermChange}
                                dbpull={dbpull}
                                hasMaxmindFetch={hasMaxmindFetch}
                                onSearchMaxmindVersionChange={handleSearchMaxmindVersionChange}
                                searchMaxmindVersion={searchMaxmindVersion}
                                maxmindSearchOptions={maxmindSearchOptions}
                                maxmindAnycastOptions={maxmindAnycastOptions}
                                searchMaxmindAnycast={searchMaxmindAnycast}
                                defaultMaxmindSearchOption={defaultMaxmindSearchOption}
                                defaultMaxmindAnycastOption={defaultMaxmindAnycastOption}
                                searchMaxmindAnycastSetter={handleSearchMaxmindAnyCastChange} />
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div id="geoip-country-subnets-table-div">
                <Stack spacing={2} direction="row">
                    <Box sx={{ width: '100%' }} id="geoip-country-subnets-table-box">
                        <TableContainer>
                            <Table
                                sx={{ width: '100%' }}
                                aria-labelledby="tableTitle"
                                size={'small'}
                                id="geoip-country-subnets-table"
                            >
                                <SingleSelectTableHead
                                    numSelected={selected.length}
                                    order={order()}
                                    orderBy={orderBy()}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                    fetchMethod={fetchData}
                                    id="geoip-country-subnets-table-head"
                                />
                                <TableBody id="geoip-country-subnets-table-body">
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                    {rows.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `geoip-country-subnets-table-checkbox-${index}`;

                                        return (
                                            getRowCells(row, labelId, isItemSelected, handleRowClick)
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                // height: 33 * emptyRows,
                                                height: 33,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            labelDisplayedRows={defaultLabelDisplayedRows}
                        />
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </Box>
                    <div class="geoip-country-subnets-table-buttons-div">
                        <Stack spacing={2} direction="column">
                            <Button id="geoip-country-subnets-table-view-button"
                                variant="contained"
                                onClick={viewButtonClickListener}
                                disabled={!viewButtonEnabled}>View</Button>
                        </Stack>
                    </div>
                </Stack>
                <Snackbar
                    open={fetchErrorMessageOpen}
                    autoHideDuration={notificationAutoclose}
                    onClose={handleFetchErrorClosed}
                    anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
                >
                    <MuiAlert
                        className="logi-snackbar-notification-message"
                        severity="info"
                        variant="filled"
                        sx={{ width: '100%' }}>
                        {fetchErrorMessage}
                    </MuiAlert>
                </Snackbar>
            </div>
        </div>
    );
}
