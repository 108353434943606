import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols
}
    from '../../common/table/TableHelper';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import {
    LOGI_ROLE_SCAN_POLICIES_ADD,
    LOGI_ROLE_SCAN_POLICIES_DELETE,
    LOGI_ROLE_SCAN_POLICIES_EDIT,
    LOGI_ROLE_SCAN_POLICIES_READ
} from '../../common/LogiRoles';
import SingleSelectTableHead from '../../common/table/SingleSelectTableHead';

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
        hide: true,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Policy Name',
        hide: false,
    },
    {
        id: 'active',
        numeric: false,
        disablePadding: true,
        label: 'Enabled',
        hide: false,
    },
    {
        id: 'priority',
        numeric: false,
        disablePadding: true,
        label: 'Priority',
        hide: false,
    },
    {
        id: 'source_container',
        numeric: false,
        disablePadding: true,
        label: 'Source Container',
        hide: false,
    },
    {
        id: 'destination_container',
        numeric: false,
        disablePadding: true,
        label: 'Destination Container',
        hide: false,
    },
    {
        id: 'ip_version',
        numeric: true,
        disablePadding: true,
        label: 'IP Version',
        hide: false,
    },
];

const hiddenCols = populateHiddenCols(headCells);

const getRowCells = (row, labelId, isItemSelected, handleClick) => {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("name") ? (<TableCell align="left">{row.name}</TableCell>) : ''}
            {!hiddenCols.has("active") ? (<TableCell align="left">{row.active ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("priority") ? (<TableCell align="left">{row.priority}</TableCell>) : ''}
            {!hiddenCols.has("source_container") ? (<TableCell align="left">{row.source_container}</TableCell>) : ''}
            {!hiddenCols.has("destination_container") ? (<TableCell align="left">{row.destination_container}</TableCell>) : ''}
            {!hiddenCols.has("ip_version") ? (<TableCell align="left">{row.ip_version}</TableCell>) : ''}
        </TableRow>
    )
};

export default function ScanPoliciesTab(props) {
    const {
        handleRowSelected,
        addButtonEnabled,
        addButtonClickHandler,
        editButtonEnabled,
        editButtonClickHandler,
        deleteButtonEnabled,
        deleteButtonClickHandler,
        order,
        orderSetter,
        orderBy,
        orderBySetter,
        selected,
        selectedSetter,
        offset,
        offsetSetter,
        page,
        pageSetter,
        rows,
        total,
        rowsPerPage,
        fetchData,
        refreshButtonClickHandler
    } = props;

    const { keycloak } = useKeycloak();

    const hasScanPolicyRead = keycloak.hasRealmRole(LOGI_ROLE_SCAN_POLICIES_READ);
    const hasScanPolicyAdd = keycloak.hasRealmRole(LOGI_ROLE_SCAN_POLICIES_ADD);
    const hasScanPolicyEdit = keycloak.hasRealmRole(LOGI_ROLE_SCAN_POLICIES_EDIT);
    const hasScanPolicyDelete = keycloak.hasRealmRole(LOGI_ROLE_SCAN_POLICIES_DELETE);

    useEffect(() => {
        handleResetButtonClick();
        // fetchData();
    }, []);

    function handleResetButtonClick(event) {
        selectedSetter([]);
        handleRowSelected(0);
        fetchData();
    }

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy === property && order === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        fetchData();
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleChangePage = (event, newPage) => {

        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        selectedSetter([]);
        handleRowSelected(0);
        pageSetter(newPage);
        // fetch the next page's data
        fetchData();
    };
    const handleRowClick = (event, id) => {
        if (id === selected[0]) {
            handleRowSelected(0);
            selectedSetter([]);
        } else {
            let newSelected = [id];
            handleRowSelected(id);
            selectedSetter(newSelected);
        }
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    const handleDeleteScanMessageClick = (event) => {
        deleteButtonClickHandler(fetchData);
    };

    if (hasScanPolicyRead) {
        return (
            <div id="scan-policies-table-top-level-div">
                    <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Scan Policies Refresh</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2} direction="column">
                                <Stack spacing={2} direction="row">
                                    <Button
                                        id="scan-policies-table-refresh-button"
                                        variant="contained"
                                        onClick={refreshButtonClickHandler}>Refresh</Button>
                                </Stack>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Stack spacing={2} direction="row">
                        <Box sx={{ width: '100%' }} id="scan-policies-table-box">
                            <TableContainer>
                                <Table
                                    sx={{ width: '100%' }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    id="scan-policies-table"
                                >
                                    <SingleSelectTableHead
                                        numSelected={selected.length}
                                        order={order()}
                                        orderBy={orderBy()}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        headCells={headCells}
                                        fetchMethod={fetchData}
                                        id="scan-policies-table-head"
                                    />
                                    <TableBody id="scan-policies-table-body">
                                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                        {rows.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `scan-policies-table-checkbox-${index}`;

                                            return (
                                                getRowCells(row, labelId, isItemSelected, handleRowClick)
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    // height: 33 * emptyRows,
                                                    height: 33,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[rowsPerPage]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                labelDisplayedRows={defaultLabelDisplayedRows}
                            />
                            <EnhancedTableToolbar numSelected={selected.length} />
                        </Box>
                        <div class="scan-policies-table-buttons-div">
                            {/*  */}
                            <Stack spacing={2} direction="column">
                                {hasScanPolicyAdd ? (<Button id="scan-policies-table-add-button"
                                    variant="contained"
                                    onClick={addButtonClickHandler}
                                    disabled={!addButtonEnabled}>Add</Button>) : (<p />)}
                                {hasScanPolicyEdit ? (<Button id="scan-policies-table-edit-button"
                                    variant="contained"
                                    onClick={editButtonClickHandler}
                                    disabled={!editButtonEnabled}>Edit</Button>) : (<p />)}
                                {hasScanPolicyDelete ? (<Button id="scan-policies-table-delete-button"
                                    variant="contained"
                                    onClick={handleDeleteScanMessageClick}
                                    disabled={!deleteButtonEnabled}>Delete</Button>) : (<p />)}
                            </Stack>
                        </div>
                    </Stack>
            </div>
        );
    }
    else {
        return (<div />)
    }
}
