import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useKeycloak } from '@react-keycloak/web';
import { coalesce } from '../../common/utility/DataUtil'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CountrySubnetsTab from './CountrySubnetsTab';
import BlockedCountriesTab from './BlockedCountriesTab';
import CountrySubnetDialog from './modals/CountrySubnetDialog';
import AddBlockedCountryDialog from './modals/AddBlockedCountryDialog';
import MaxmindExceptionTab from './MaxmindExceptions';
import MaxmindAddExceptionsDialog from './modals/AddMaxmindExceptionsDialog';
import './GeoIPComponent.css';
import { fetchErrorMessage } from '../mainpage';

const rowsPerPage = 20;

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

const countrySubnetUrl = '/middleware/api/max_maxmindsubnet/';
const countrySubnetV4Url = '/middleware/api/maxmind_subnets_v4/';
const countrySubnetV6Url = '/middleware/api/maxmind_subnets_v6/';
const blockedCountryUrl = '/middleware/api/max_blockedcountry/';
const exceptionsUrl = '/middleware/api/maxmind_exceptionsubnets/';
const exceptionsIPv4Url = '/middleware/api/maxmind_exceptionsubnets_v4/';
const exceptionsIPv6Url = '/middleware/api/maxmind_exceptionsubnets_v6/';

// limit the results of blocked countries query used
// to populate countryOptions
const blockedCountryLimit = 300;

const countriesyUrl = '/middleware/api/max_countries/';

const defaultMaxmindSearchOption = "Both";

const defaultMaxmindAnycastOption = "Both";

const maxmindSearchOptions = [
  "IPv4",
  "IPv6"
];

const maxmindAnycastOptions = [
  "Anycast",
  "Unicast"
];

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref}  {...props} />;
// });

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`geoip-country-subnets-tabpanel-${index}`}
      aria-labelledby={`geoip-country-subnets-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `geoip-country-subnets-tab-${index}`,
    'aria-controls': `geoip-country-subnets-tabpanel-${index}`,
  };
}

function createCountrySubnetRowData(id, subnet, country, registered_country, represented_country, is_anycast) {
  return {
    id,
    subnet,
    country,
    registered_country,
    represented_country,
    is_anycast,
  };
}

function createBlockedCountryData(country_id, country) {
  return {
    country_id,
    country
  };
}

// needed so that reset button doesn't have to be clicked twice
let freshCountrySubnetsSearchTerm = '';
let freshGeoIpExceptionSearchTerm = '';

export default function GeoIPComponent() {

  const { keycloak } = useKeycloak();

  const [tabIndex, setTabIndex] = React.useState(0);

  // snackbar state vars
  const [snackState,] = React.useState({
    snackbarVertical: 'bottom',
    snackbarHorizontal: 'center',
  });

  const { snackbarHorizontal, snackbarVertical } = snackState;

  const [countrySubnetRefreshNotificationOpen, setCountrySubnetRefreshNotificationOpen] = useState(false);
  const countrySubnetRefreshNotificationMessage = "Country Subnet Data Refreshed";
  const [adhocPollNotificationOpen, setAdhocPollNotificationOpen] = useState(false);
  const adhocPollNotificationMessage = "A Poll of Country Data Has Been Started";
  const [blockedCountryRefreshNotificationOpen, setBlockedCountryRefreshNotificationOpen] = useState(false);
  const blockedCountryRefreshNotificationMessage = "Blocked Country Data Refreshed";
  const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
  const handleFetchErrorClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFetchErrorMessageOpen(false);
  };

  // country subnet tab state vars
  const [countrySubnetOrder, setCountrySubnetOrder] = useState('asc');
  const [countrySubnetOrderBy, setCountrySubnetOrderBy] = useState('subnet');
  const [countrySubnetSelected, setCountrySubnetSelected] = useState([]);
  const [countrySubnetOffset, setCountrySubnetOffset] = useState(0);
  const [countrySubnetPage, setCountrySubnetPage] = useState(0);
  const [countrySubnetRows, setCountrySubnetRows] = useState([]);
  const [countrySubnetTotal, setCountrySubnetTotal] = useState(0);
  const [countrySubnetSearchTerm, setCountrySubnetSearchTerm] = useState('');
  const [searchMaxmindVersion, setSearchMaxmindVersion] = useState(defaultMaxmindSearchOption);
  const [searchMaxmindAnycast, setSearchMaxmindAnycast] = useState(defaultMaxmindAnycastOption);
  const [countrySubnetSearchSwitch, setCountrySubnetSearchSwitch] = useState(false);

  // country subnet modal related
  const [viewCountrySubnetButtonEnabled, setViewCountrySubnetButtonEnabled] = useState(false);
  const [countrySubnetModalOpen, setCountrySubnetModalOpen] = useState(false);
  const [selectedCountrySubnetId, setSelectedCountrySubnetId] = useState(0);
  const [selectedCountrySubnetNetwork, setSelectedCountrySubnetNetwork] = useState('');
  const [selectedCountrySubnetCountry, setSelectedCountrySubnetCountry] = useState('');
  const [selectedCountrySubnetRegisteredCountry, setSelectedCountrySubnetRegisteredCountry] = useState('');
  const [selectedCountrySubnetRepresentedCountry, setSelectedCountrySubnetRepresentedCountry] = useState('');

  // blocked country tab state vars
  const [blockedCountryOrder, setBlockedCountryOrder] = useState('asc');
  const [blockedCountryOrderBy, setBlockedCountryOrderBy] = useState('country');
  const [blockedCountrySelected, setBlockedCountrySelected] = useState([]);
  const [blockedCountryOffset, setBlockedCountryOffset] = useState(0);
  const [blockedCountryPage, setBlockedCountryPage] = useState(0);
  const [blockedCountryRows, setBlockedCountryRows] = useState([]);
  const [blockedCountryTotal, setBlockedCountryTotal] = useState(0);
  const [blockedCountrySearchSwitch, setBlockedCountrySearchSwitch] = useState(false);

  // blocked country modal related
  const [addBlockedCountryButtonEnabled,] = useState(true);
  const [deleteBlockedCountryButtonEnabled, setDeleteBlockedCountryButtonEnabled] = useState(false);
  const [addBlockedCountryModalOpen, setAddBlockedCountryModalOpen] = useState(false);
  const [, setSelectedBlockedCountryName] = useState('');
  const [selectedBlockedCountryCode, setSelectedBlockedCountryCode] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [currentlyBlockedCountries, setCurrentlyBlockedCountries] = useState([]);
  const [blockedCountryModalAddButtonDisabled, setBlockedCountryModalAddButtonDisabled] = useState(true);
  const [, setBlockedCountryModalCountryChosen] = useState('');

  // exception geoip tab state vars
  const [selectedExceptionGeoIpId, setSelectedExceptionGeoIpId] = useState(0);
  const [selectedExceptionGeoIpSubnet, setSelectedExceptionGeoIpSubnet] = useState('');
  const [selectedExceptionGeoIpCreated, setSelectedExceptionGeoIpCreated] = useState('');
  const [selectedExceptionId, setSelectedExceptionId] = useState(0);
  const [exceptionGeoIpOrder, setExceptionGeoIpOrder] = useState('asc');
  const [exceptionGeoIpOrderBy, setExceptionGeoIpOrderBy] = useState('subnet');
  const [exceptionTableData, setExceptionTableData] = useState([]);
  const [geoIpExceptionsSwitch, setGeoIpExceptionsSwitch] = useState(false);
  const [exceptionGeoIpSelected, setExceptionGeoIpSelected] = useState([]);
  const [exceptionGeoIpOffset, setExceptionGeoIpOffset] = useState(0);
  const [exceptionGeoIpPage, setExceptionGeoIpPage] = useState(0);
  const [exceptionGeoIpTotal, setExceptionGeoIpTotal] = useState(0);
  const [exceptionGeoIpRows, setExceptionGeoIpRows] = useState([]);
  const [exceptionGeoIpRefreshNotificationOpen, setExceptionGeoIpRefreshNotificationOpen] = useState(false);
  const [deleteExceptionsButtonEnabled, setDeleteExceptionsButtonEnabled] = useState(false);
  const [uploadExceptionsButtonEnabled, setUploadExceptionsButtonEnabled] = useState(false);
  const [searchGeoIpExceptionVersion, setSearchGeoIpExceptionVersion] = useState(defaultMaxmindSearchOption);
  const [viewGeoIpExceptionButtonEnabled, setViewGeoIpExceptionButtonEnabled] = useState(false);
  const [geoIpExceptionSearchTerm, setGeoIpExceptionSearchTerm] = useState('');
  const [geoIpExceptionsModalOpen, setGeoIpExceptionsModalOpen] = useState(false);

  useEffect(() => {
    fetchCountrySubnetData()
  }, [countrySubnetSearchSwitch])

  useEffect(() => {
    fetchBlockedCountryData()
  }, [blockedCountrySearchSwitch])

  const handleGeoIpExceptionRowSelection = (val) => {
    setSelectedExceptionGeoIpId(val);
    setSelectedExceptionGeoIpSubnet("");
    setSelectedExceptionGeoIpCreated("");
    setSelectedExceptionId(val);
  };

  const getExceptionGeoIpOrder = () => {
    return exceptionGeoIpOrder;
  }

  const getExceptionGeoIpCollectionOrderBy = () => {
    return exceptionGeoIpOrderBy;
  }

  const displayExceptionGeoIpRefreshStarted = (event) => {
    setExceptionGeoIpRefreshNotificationOpen(true);
  };

  const handleExceptionGeoIpRefreshButtonClick = (event) => {
    fetchExceptionGeoIpData();
    displayExceptionGeoIpRefreshStarted();
  };

  const handleAddGeoIpExceptionsButtonClick = (event) => {
    // fetch modal data
    //fetchBlockedBLLCollectionModalData();
    // display modal
    setGeoIpExceptionsModalOpen(true);
  };

  const handleExceptionsSelected = (id) => {
    setSelectedExceptionId(id);
    if (id !== 0) {
      setDeleteExceptionsButtonEnabled(true);
    }
    else {
      setDeleteExceptionsButtonEnabled(false);
    }
  };

  const getFreshGeoIpExceptionSearchTerm = () => {
    return freshGeoIpExceptionSearchTerm;
  }

  const getSanitizedGeoIpExceptionSearchFieldContent = () => {
    let ret = getFreshGeoIpExceptionSearchTerm();
    return encodeURIComponent(ret);
  }

  function createExceptionGeoIpRowData(id, subnet, created) {
    return {
      id,
      subnet,
      created
    };
  }

  const handleGeoIpExceptionVersionTermChanged = (val) => {
    setSearchGeoIpExceptionVersion(val);
  };

  const handleDeleteExceptionButtonClick = (refreshCallBack) => {
    deleteException(refreshCallBack);
  };

  const handleMaxmindExceptionsModalClose = () => {
    setGeoIpExceptionsModalOpen(false);
  }

  const deleteException = (refreshCallBack) => {
    fetch(exceptionsUrl
      + selectedExceptionId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        setDeleteExceptionsButtonEnabled(false);
        refreshCallBack();
      });
  };

  const handleToggleViewGeoIpExceptionButtonVisibility = (val) => {
    setViewGeoIpExceptionButtonEnabled(val);
  };

  const handleGeoIpExceptionSearchTermChanged = (val) => {
    setGeoIpExceptionSearchTerm(val);
    freshGeoIpExceptionSearchTerm = val;
  };

  const fetchExceptionGeoIpData = () => {
    const sanitizedSearchTerm = getSanitizedGeoIpExceptionSearchFieldContent();
    const ipFilter = searchGeoIpExceptionVersion;
    // console.log("IP Filter: ", ipFilter);
    const exceptionOrderPrefix = (exceptionGeoIpOrder === 'desc' ? '-' : '');
    let geoIpExceptionsUrl = exceptionsUrl;
    if (ipFilter === 'Both') {
      geoIpExceptionsUrl = exceptionsUrl;
      // console.log("It's Both.");
    } else if (ipFilter === 'IPv4') {
      geoIpExceptionsUrl = exceptionsIPv4Url;
      // console.log("It's IPv4");
    } else if (ipFilter === 'IPv6') {
      geoIpExceptionsUrl = exceptionsIPv6Url;
      // console.log("It's IPv6");
    }
    // console.log("What URL: ", bllExceptionsUrl)

    fetch(exceptionsUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + exceptionGeoIpOffset
      + '&page=' + (exceptionGeoIpPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + exceptionOrderPrefix + exceptionGeoIpOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setExceptionGeoIpTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createExceptionGeoIpRowData(entry['id'],
            entry['subnet'],
            coalesce(entry['created'], "-")));
        }

        setExceptionGeoIpSelected([]);
        setExceptionGeoIpRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setGeoIpExceptionsSwitch(false);
  };

  const handleBlockedCountryAddModalClose = () => {
    setAddBlockedCountryModalOpen(false);
    setDeleteBlockedCountryButtonEnabled(false);
    setSelectedBlockedCountryName('');
    setSelectedBlockedCountryName('');
    setSelectedBlockedCountryCode('');
    setBlockedCountryModalAddButtonDisabled(true);
  };

  const handleMaxmindVersionTermChanged = (val) => {
    setSearchMaxmindVersion(val);
  };

  const handleMaxindAnycastChanged = (val) => {
    setSearchMaxmindAnycast(val);
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleToggleViewCountrySubnetButtonVisibility = (val) => {
    setViewCountrySubnetButtonEnabled(val);
  };

  const fetchCountrySubnetModalData = () => {
    fetch(countrySubnetUrl
      + selectedCountrySubnetId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setSelectedCountrySubnetId(respData['id']);
        setSelectedCountrySubnetNetwork(respData['subnet']);
        setSelectedCountrySubnetCountry(coalesce(respData['country'], "-"));
        setSelectedCountrySubnetRegisteredCountry(coalesce(respData['registered_country'], "-"));
        setSelectedCountrySubnetRepresentedCountry(coalesce(respData['represented_country'], "-"));
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleViewCountrySubnetButtonClick = (event) => {
    if (!countrySubnetModalOpen) {
      // fetch the crontab data for display in model
      fetchCountrySubnetModalData();
      setCountrySubnetModalOpen(true);
    }
  };

  const handleCountrySubnetModalClose = (val) => {
    setCountrySubnetModalOpen(val);
    setCountrySubnetSelected([]);
    setViewCountrySubnetButtonEnabled(false);
  };

  /**
   * Populate a list of available countries to block
   * that excludes the ones currently blocked.
   */
  const populateAvailableCountries = async () => {

    // first fetch list of currently blocked countries
    await fetch(blockedCountryUrl
      + '?limit=' + blockedCountryLimit, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(entry['country_id']);
        }
        setCurrentlyBlockedCountries(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });

    await fetch(countriesyUrl
      + '?limit=300', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          let existingIndex = currentlyBlockedCountries.indexOf(entry['pk']);
          if (existingIndex === -1) {
            tmpRows.push({ country_id: entry['pk'], country: entry['name'] });
          }
        }
        setCountryOptions(tmpRows);

        // we need to prune the countries that are already blocked from country options
        // open dialog for adding a new blocked country
        setAddBlockedCountryModalOpen(true);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const deleteBlockedCountry = async (fetchFunctionCallback) => {
    await fetch(blockedCountryUrl
      + selectedBlockedCountryCode + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        fetchFunctionCallback();
      });
  };

  const handleDeleteBlockedCountryButtonClick = (fetchFunctionCallback) => {
    // remove the entry for selectedBlockedCountry
    deleteBlockedCountry(fetchFunctionCallback);
  };

  const handleToggleDeleteBlockedCountryButtonVisibility = (val) => {
    setDeleteBlockedCountryButtonEnabled(val);
  };

  const handleBlockedCountryModalCancelled = () => {
    // close the dialog without doing anything
    setBlockedCountryModalAddButtonDisabled(true);
    setAddBlockedCountryModalOpen(false);
  };

  const addBlockedCountry = async (chosenCountryCode, chosenCountryName) => {
    let postData = {
      country_id: chosenCountryCode,
      country: chosenCountryName
    };
    await fetch(blockedCountryUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchBlockedCountryData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  /**
   * Called when the Add button on the blocked countries tab is clicked. 
   */
  const handleAddBlockedCountryButtonClick = () => {
    populateAvailableCountries();
  };

  /**
   * Called from the add blocked country modal when a user chooses a country to add and clicks Add button
   * 
   * @param {*} chosenCountry This will be country_code|country_name since these are both needed for the add
   */
  const handleBlockedCountryModalAdd = (chosenCountry) => {

    // split on the pipe('|') char
    let parts = chosenCountry.split("|");

    addBlockedCountry(parts[0], parts[1]);

    // close the dialog
    setAddBlockedCountryModalOpen(false);
    setBlockedCountryModalAddButtonDisabled(true);
  };

  const handleBlockedCountrySelected = (row) => {
    if (row !== null) {
      setSelectedBlockedCountryCode(row.country_id);
      setSelectedBlockedCountryName(row.country);
      setBlockedCountryModalAddButtonDisabled(false);
    }
    else {
      setSelectedBlockedCountryCode('');
      setSelectedBlockedCountryName('');
      setBlockedCountryModalAddButtonDisabled(true);
    }
  };

  const handleBlockedCountryModalCountryChosen = (chosenCountryId) => {
    setBlockedCountryModalCountryChosen(chosenCountryId);
    if (chosenCountryId !== '') {
      setBlockedCountryModalAddButtonDisabled(false);
    }
    else {
      setBlockedCountryModalAddButtonDisabled(true);
    }
  };

  const getCountrySubnetSearchTerm = () => {
    return freshCountrySubnetsSearchTerm;
  };

  const getSanitizedCountrySubnetSearchFieldContent = () => {
    let ret = getCountrySubnetSearchTerm();
    return encodeURIComponent(ret);
  };

  const fetchCountrySubnetData = async () => {
    const sanitizedSearchTerm = getSanitizedCountrySubnetSearchFieldContent();
    const ipFilter = searchMaxmindVersion;
    const orderPrefix = (countrySubnetOrder === 'desc' ? '-' : '');

    let maxmindUrl = countrySubnetUrl;

    if (ipFilter === 'Both') {
      maxmindUrl = countrySubnetUrl;
    } else if (ipFilter === 'IPv4') {
      maxmindUrl = countrySubnetV4Url;
    } else if (ipFilter === 'IPv6') {
      maxmindUrl = countrySubnetV6Url;
    }

    let anycast = '';
    if (searchMaxmindAnycast === 'Unicast') {
      anycast = '&is_anycast=false';
    } else if (searchMaxmindAnycast === 'Anycast') {
      anycast = '&is_anycast=true';
    } else if (searchMaxmindAnycast === 'Both') {
      anycast = '';
    }
    // console.log("Anycast value: ", searchMaxmindAnycast)
    // console.log("Anycast: ", anycast)

    await fetch(maxmindUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + countrySubnetOffset
      + '&page=' + (countrySubnetPage + 1)
      + anycast
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + orderPrefix + countrySubnetOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setCountrySubnetTotal(respData.count);
        // console.log("Datax: ", respData);
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          // console.log("Entry: ", entry)
          tmpRows.push(createCountrySubnetRowData(
            entry['id'],
            entry['subnet'],
            coalesce(entry['country'], "-"),
            coalesce(entry['registered_country'], "-"),
            coalesce(entry['represented_country'], "-"),
            entry['is_anycast'],));
        }

        setCountrySubnetSelected([]);
        setCountrySubnetRows(tmpRows);
        // console.log('Datax: ', tmpRows)
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setCountrySubnetSearchSwitch(false)
  };

  const fetchBlockedCountryData = async () => {
    const orderPrefix = (blockedCountryOrder === 'desc' ? '-' : '');

    await fetch(blockedCountryUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + blockedCountryOffset
      + '&page=' + (blockedCountryPage + 1)
      + '&ordering=' + orderPrefix + blockedCountryOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setBlockedCountryTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createBlockedCountryData(entry['country_id'], entry['country']));
        }

        setBlockedCountrySelected([]);
        setBlockedCountryRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setBlockedCountrySearchSwitch(false)
  };

  const handleCountrySubnetSearchTermChanged = (val) => {
    setCountrySubnetSearchTerm(val);
    freshCountrySubnetsSearchTerm = val;
  };

  const displayCountrySubnetRefreshStarted = (event) => {
    setCountrySubnetRefreshNotificationOpen(true);
  };

  const handleCountrySubnetsRefreshButtonClick = (event) => {
    fetchCountrySubnetData();
    displayCountrySubnetRefreshStarted();
  };

  const handleCountrySubnetsRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setCountrySubnetRefreshNotificationOpen(false);
  };

  const handleDisplayAdhocPollStarted = (event) => {
    setAdhocPollNotificationOpen(true);
  };

  const handleAdhocPollNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAdhocPollNotificationOpen(false);
  };

  const displayBlockedCountryRefreshStarted = (event) => {
    setBlockedCountryRefreshNotificationOpen(true);
  };

  const handleBlockedCountryRefreshButtonClick = (event) => {
    fetchBlockedCountryData();
    displayBlockedCountryRefreshStarted();
  };

  const handleBlockedCountryRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setBlockedCountryRefreshNotificationOpen(false);
  };

  const getBlockedCountryOrderBy = () => {
    return blockedCountryOrderBy;
  }

  const getBlockedCountryOrder = () => {
    return blockedCountryOrder;
  }

  const getCountrySubnetOrderBy = () => {
    return countrySubnetOrderBy;
  }

  const getCountrySubnetOrder = () => {
    return countrySubnetOrder;
  }

  return (
    <Box sx={{ width: '100%' }} id="geoip-country-subnets-top-level-box">
      <Snackbar
        open={countrySubnetRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleCountrySubnetsRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {countrySubnetRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={adhocPollNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleAdhocPollNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {adhocPollNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={blockedCountryRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleBlockedCountryRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {blockedCountryRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={fetchErrorMessageOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleFetchErrorClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {fetchErrorMessage}
        </MuiAlert>
      </Snackbar>
      <CountrySubnetDialog
        open={countrySubnetModalOpen}
        onClose={handleCountrySubnetModalClose}
        selectedCountrySubnetNetwork={selectedCountrySubnetNetwork}
        selectedCountrySubnetCountry={selectedCountrySubnetCountry}
        selectedCountrySubnetRegisteredCountry={selectedCountrySubnetRegisteredCountry}
        selectedCountrySubnetRepresentedCountry={selectedCountrySubnetRepresentedCountry}
      />
      <AddBlockedCountryDialog
        open={addBlockedCountryModalOpen}
        onClose={handleBlockedCountryAddModalClose}
        countryChosenSetter={handleBlockedCountryModalCountryChosen}
        onCancel={handleBlockedCountryModalCancelled}
        onAdd={handleBlockedCountryModalAdd}
        countryOptions={countryOptions}
        addButtonDisabled={blockedCountryModalAddButtonDisabled}
      />
      <MaxmindAddExceptionsDialog
        open={geoIpExceptionsModalOpen}
        onClose={handleMaxmindExceptionsModalClose}
        fetchData={fetchExceptionGeoIpData}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="GeoIP Tab Panel">
          <Tab label="Country Subnets" {...a11yProps(0)} />
          <Tab label="Blocked Countries" {...a11yProps(1)} />
          <Tab label="Exceptions" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <CountrySubnetsTab
          viewButtonEnabled={viewCountrySubnetButtonEnabled}
          viewButtonClickListener={handleViewCountrySubnetButtonClick}
          onSelectRowId={setSelectedCountrySubnetId}
          selectRowId={selectedCountrySubnetId}
          viewButtonToggle={handleToggleViewCountrySubnetButtonVisibility}
          order={getCountrySubnetOrder}
          orderSetter={setCountrySubnetOrder}
          orderBy={getCountrySubnetOrderBy}
          orderBySetter={setCountrySubnetOrderBy}
          setCountrySubnetSearchSwitch={setCountrySubnetSearchSwitch}
          selected={countrySubnetSelected}
          selectedSetter={setCountrySubnetSelected}
          offset={countrySubnetOffset}
          offsetSetter={setCountrySubnetOffset}
          page={countrySubnetPage}
          pageSetter={setCountrySubnetPage}
          rows={countrySubnetRows}
          total={countrySubnetTotal}
          totalSetter={setCountrySubnetTotal}
          searchTerm={countrySubnetSearchTerm}
          searchTermSetter={handleCountrySubnetSearchTermChanged}
          fetchData={fetchCountrySubnetData}
          rowsPerPage={rowsPerPage}
          refreshButtonClickHandler={handleCountrySubnetsRefreshButtonClick}
          displayAdhocPollStartedHandler={handleDisplayAdhocPollStarted}
          maxmindSearchOptions={maxmindSearchOptions}
          maxmindAnycastOptions={maxmindAnycastOptions}
          defaultMaxmindSearchOption={defaultMaxmindSearchOption}
          searchMaxmindVersion={searchMaxmindVersion}
          defaultMaxmindAnycastOption={defaultMaxmindAnycastOption}
          searchMaxmindAnycast={searchMaxmindAnycast}
          searchMaxmindAnycastSetter={handleMaxindAnycastChanged}
          searchMaxmindVersionSetter={handleMaxmindVersionTermChanged} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <BlockedCountriesTab
          addButtonEnabled={addBlockedCountryButtonEnabled}
          addButtonClickListener={handleAddBlockedCountryButtonClick}
          deleteButtonEnabled={deleteBlockedCountryButtonEnabled}
          deleteButtonClickListener={handleDeleteBlockedCountryButtonClick}
          onSelectRow={handleBlockedCountrySelected}
          selectRowId={selectedBlockedCountryCode}
          deleteButtonToggle={handleToggleDeleteBlockedCountryButtonVisibility}
          order={getBlockedCountryOrder}
          orderSetter={setBlockedCountryOrder}
          orderBy={getBlockedCountryOrderBy}
          orderBySetter={setBlockedCountryOrderBy}
          setBlockedCountrySearchSwitch={setBlockedCountrySearchSwitch}
          selected={blockedCountrySelected}
          selectedSetter={setBlockedCountrySelected}
          offset={blockedCountryOffset}
          offsetSetter={setBlockedCountryOffset}
          page={blockedCountryPage}
          pageSetter={setBlockedCountryPage}
          rows={blockedCountryRows}
          total={blockedCountryTotal}
          fetchData={fetchBlockedCountryData}
          rowsPerPage={rowsPerPage}
          refreshButtonClickHandler={handleBlockedCountryRefreshButtonClick}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <MaxmindExceptionTab
          onSelectRowId={handleGeoIpExceptionRowSelection}
          order={getExceptionGeoIpOrder}
          orderSetter={setExceptionGeoIpOrder}
          orderBy={getExceptionGeoIpCollectionOrderBy}
          orderBySetter={setExceptionGeoIpOrderBy}
          setAsnExceptionsSwitch={setGeoIpExceptionsSwitch}
          selected={exceptionGeoIpSelected}
          selectedSetter={setExceptionGeoIpSelected}
          offset={exceptionGeoIpOffset}
          offsetSetter={setExceptionGeoIpOffset}
          page={exceptionGeoIpPage}
          pageSetter={setExceptionGeoIpPage}
          rows={exceptionGeoIpRows}
          total={exceptionGeoIpTotal}
          totalSetter={setExceptionGeoIpTotal}
          rowsPerPage={rowsPerPage}
          refreshButtonClickHandler={handleExceptionGeoIpRefreshButtonClick}
          handleRowSelected={handleExceptionsSelected}
          fetchData={fetchExceptionGeoIpData}
          exceptionTableData={exceptionTableData}
          setExceptionTableData={setExceptionTableData}
          addButtonClickListener={handleAddGeoIpExceptionsButtonClick}
          deleteButtonEnabled={deleteExceptionsButtonEnabled}
          deleteButtonClickHandler={handleDeleteExceptionButtonClick}
          deleteButtonToggle={setDeleteExceptionsButtonEnabled}
          uploadButtonEnabled={uploadExceptionsButtonEnabled}
          uploadButtonEnabledSetter={setUploadExceptionsButtonEnabled}
          viewButtonToggle={handleToggleViewGeoIpExceptionButtonVisibility}
          searchTerm={geoIpExceptionSearchTerm}
          searchTermSetter={handleGeoIpExceptionSearchTermChanged}
          asnSearchOptions={maxmindSearchOptions}
          defaultAsnSearchOption={defaultMaxmindSearchOption}
          searchAsnVersion={searchGeoIpExceptionVersion}
          searchAsnVersionSetter={handleGeoIpExceptionVersionTermChanged}
        />
      </TabPanel>
    </Box>
  );
}
