import IPCIDR from "ip-cidr";
/**
 * Validate that the input is a valid CIDR network block of the ip version
 * specified.
 * 
 * @param {*} ipVersion ip version the input must be a cidr block for
 * @param {*} input 
 */
export function isValidIpVersionCidr(input) {
    let ret = { res: false, msg: "" };

    if (input === null || input === undefined) {
        // null input is considered "any"
        return ret;
    }

    let trimmedInput = input;
    if (trimmedInput === '') {
        // blank input is considered "any" port
        return ret;
    }

    // use ip-cidr so we can check the input is a cidr block and if so
    // what ip version
    // const isCidr = require("is-cidr");

    let cidrResult;

    try {
        // cidrResult = isCidr.v4(trimmedInput);
        cidrResult = IPCIDR.isValidAddress(trimmedInput);
        // console.log('cidrResult: ', cidrResult)

        if (cidrResult) {
            ret.res = true;
            ret.msg = "Looks like a valid IPv4 or IPv6 CIDR block or IP address.";
            return ret;
        } else {
            ret.res = false;
            ret.message = "Not a valid IPv4 or IPv6 CIDR block or IP Address.";
            return ret;
        }
    } catch {
        ret.res = false;
        ret.message = "This is not a valid CIDR block or IP address or something else happened."
        return ret;
    }
}
