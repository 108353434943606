import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

export default function ScanContainerFilterWidget(props) {
    const { searchTerm, onSearchClick, onResetClick, onSearchTermChange } = props;

    return (
        <form onSubmit={onSearchClick}>
            <div id="scan-containers-filter-widget-div" class="scan-containers-filter-widget-div">
                <div class="scan-containers-filter-widget-body-div">
                    <Stack id="scan-containers-filter-widget-control-stack" spacing={2} direction="column">
                        <TextField id="scan-containers-filter-widget-search-input"
                            label="Search"
                            variant="outlined"
                            size="medium"
                            value={searchTerm}
                            onChange={onSearchTermChange}
                            sx={{ m: 1, minWidth: 100, maxWidth: 410 }}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <Button id="scan-containers-filter-widget-search-button" variant="contained" button type="submit">Search</Button>
                        <Button id="scan-containers-filter-widget-reset-button" variant="contained" onClick={onResetClick}>Reset</Button>
                    </Stack>
                </div>
            </div>
        </form>
    );
};
