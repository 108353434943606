import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function BlockedBLLCollectionDialog(props) {
    let { open, onClose,
        selectedBlockedBLLCollectionName,
        selectedBlockedBLLCollectionEnabled,
        selectedBlockedBLLCollectionEnabledSetter,
        selectedBlockedBLLCollectionConfidence,
        selectedBlockedBLLCollectionConfidenceSetter,
        selectedBlockedBLLCollectionConfidenceValid,
        selectedBlockedBLLCollectionConfidenceErrorMessage,
        selectedBlockedBLLCollectionUpdateButtonEnabled,
        selectedBlockedBLLCollectionUpdateButtonClickListener,
        selectedBlockedBLLCollectionValidateButtonEnabled,
        selectedBlockedBLLCollectionValidateButtonClickListener
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const readOnlyTextFieldHandler = (event) => {
        // don't do anything with typed input, making this
        // field read-only
    };

    const handleConfidenceChanged = (event) => {
        selectedBlockedBLLCollectionConfidenceSetter(event.target.value);
    };
    const handleEnabledChanged = (event) => {
        selectedBlockedBLLCollectionEnabledSetter(event.target.checked);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>BLocked BLL Collection Settings</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        label="Collection Name"
                        variant="outlined"
                        size="small"
                        // width="480px"
                        value={selectedBlockedBLLCollectionName}
                        onChange={readOnlyTextFieldHandler}
                        id="blocked-bll-collections-tab-modal-dialog-collection-name-field"
                        style={{marginTop: '15px'}}
                    />
                    <TextField
                        {...(selectedBlockedBLLCollectionConfidenceValid !== true && { error: undefined })}
                        helperText={selectedBlockedBLLCollectionConfidenceErrorMessage}
                        label="Confidence"
                        variant="outlined"
                        size="small"
                        // width="480px"
                        value={selectedBlockedBLLCollectionConfidence}
                        onChange={handleConfidenceChanged}
                        id="blocked-bll-collections-tab-modal-dialog-confidence-field"
                    />
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={selectedBlockedBLLCollectionEnabled}
                            onChange={handleEnabledChanged}
                            id="blocked-bll-collections-tab-modal-dialog-enabled-field"
                        />} label="Enabled" />
                    </FormGroup>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="blocked-bll-collections-tab-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="blocked-bll-collections-tab-modal-dialog-validate-button"
                            variant="contained"
                            disabled={!selectedBlockedBLLCollectionValidateButtonEnabled ? true : false}
                            onClick={selectedBlockedBLLCollectionValidateButtonClickListener}>Validate</Button>
                        <Button id="blocked-bll-collections-tab-modal-dialog-update-button"
                            variant="contained"
                            disabled={!selectedBlockedBLLCollectionUpdateButtonEnabled ? true : false}
                            onClick={selectedBlockedBLLCollectionUpdateButtonClickListener}>Update</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
