import Box from '@mui/material/Box';
import Navs from '../../components/topnav';
import Sidenavs from '../../components/sidenav';
import CountriesPlusComponent from '../../components/Admin/CountriesPlus/CountriesPlusComponent';
import { LOGI_ROLE_COUNTRIES_PLUS_READ } from '../../common/LogiRoles';
import { useKeycloak } from '@react-keycloak/web';
import "./CountriesPlusPage.css";

function CountriesPlusPage() {
	const { keycloak } = useKeycloak();

	let hasCountriesPlusRead = keycloak.hasRealmRole(LOGI_ROLE_COUNTRIES_PLUS_READ);
	if (keycloak.idTokenParsed) {
		return (
			<div className='App'>
				<Navs />
				<table className='mptable'>
					<tr>
						<td className='sidenavtd'>
							<Box sx={{ position: 'sticky', top: 0 }}>
								<Sidenavs firewallSettingsOpen={false} adminOpen={true}/>
							</Box>
						</td>
						<td class="countriesplus-page-route-td">
							{hasCountriesPlusRead ? (<Box sx={{ height: `100%` }}>
								<div class="countriesplus-page-div">
									<CountriesPlusComponent />
								</div>
							</Box>) : (<p>You do not have permission to view this page</p>)}
						</td>
					</tr>
				</table>
			</div>
		);
	} else {

	}
};

export default CountriesPlusPage