import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';

export default function ViewNFTablesRulesetDetailsDialog(props) {
  const { open, onClose, selectedCreated,
    selectedSha1, selectedUnixTimestamp } = props;
  const handleClose = () => {
    onClose();
  };

  const handleCloseButtonClick = (event) => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Generated NFTables Ruleset</DialogTitle>
      <div class="modal-dialog-content-div">
        <Stack spacing={2} direction="column">
          <TextField
            label="Created"
            variant="outlined"
            size="medium"
            value={selectedCreated}
            style={{marginTop: '15px'}}
          />
          <TextField
            label="SHA1"
            variant="outlined"
            size="medium"
            value={selectedSha1}
            width="512px"
          />
          <TextField
            label="UNIX Timestamp"
            variant="outlined"
            size="medium"
            value={selectedUnixTimestamp}
          />
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Button id="nftrules-table-modal-dialog-close-button" variant="contained" onClick={handleCloseButtonClick}>Close</Button>
          </Stack>
        </Stack>
      </div>
    </Dialog>
  );
}
