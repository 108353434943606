import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import {
    getIPVersions,
    getValidActions,
    getValidDirections,
    getValidProtocols,
    MR_ACTION_ALLOW,
    MR_DIRECTION_INBOUND
}
    from '../../../common/validation/manual_rules/ManualRulesValidator';

export default function AddManualRuleDialog(props) {
    const { open,
        onClose,
        ipVersion,
        ipVersionSetter,
        protocol,
        protocolSetter,
        srcAddress,
        srcAddressSetter,
        srcAddressValid,
        srcAddressErrorMessage,
        srcPort,
        srcPortSetter,
        srcPortValid,
        srcPortErrorMessage,
        dstAddress,
        dstAddressSetter,
        dstAddressValid,
        dstAddressErrorMessage,
        dstPort,
        dstPortSetter,
        dstPortValid,
        dstPortErrorMessage,
        bidirectional,
        bidirectionalSetter,
        direction,
        directionSetter,
        action,
        actionSetter,
        comments,
        commentsSetter,
        addButtonEnabled,
        addButtonClickHandler,
        ruleValidationStatusMessage,
        performValidation
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };
    const handleAddButtonClick = (event) => {
        addButtonClickHandler();
    };

    const handleIPVersionChanged = (event) => {
        ipVersionSetter(event.target.value);
    };

    const handleProtocolChanged = (event) => {
        protocolSetter(event.target.value);
    };

    const handleSourceAddressChanged = (event) => {
        srcAddressSetter(event.target.value);
    };

    const handleSourcePortChanged = (event) => {
        srcPortSetter(event.target.value);
    };

    const handleDestAddressChanged = (event) => {
        dstAddressSetter(event.target.value);
    };

    const handleDestPortChanged = (event) => {
        dstPortSetter(event.target.value);
    };

    const handleBidirectionalChanged = (event) => {
        bidirectionalSetter(event.target.checked);
    };

    const handleDirectionChanged = (event) => {
        directionSetter(event.target.value);
    };

    const handleActionChanged = (event) => {
        actionSetter(event.target.value);
    };

    const handleCommentsChanged = (event) => {
        commentsSetter(event.target.value);
    };

    return (
        <Dialog onClose={handleClose} open={open} style={{ marginTop: '20px' }}>
            <DialogTitle>Add Manual Rule</DialogTitle>
            <div class="modal-dialog-content-div" style={{ height: '500px' }}>
                <Stack spacing={2} direction="column">
                    <FormControl id="manual-rules-tab-add-modal-dialog-ip-version-form-control" style={{ marginTop: '20px' }}>
                        <InputLabel id="manual-rules-tab-add-modal-dialog-ip-version-select-label">Choose IP version</InputLabel>
                        <Select
                            labelId="manual-rules-tab-add-modal-dialog-ip-version-select-label"
                            id="manual-rules-tab-add-modal-dialog-ip-version-select"
                            label="Internet Protocol Version"
                            onChange={handleIPVersionChanged}
                            defaultValue="4"
                            value={ipVersion}
                            variant="standard"
                            style={{ marginTop: '25px' }}
                        >
                            {getIPVersions().map((entry) => {
                                return (
                                    <MenuItem value={entry.key}>{entry.value}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl id="manual-rules-tab-add-modal-dialog-protocol-form-control">
                        <InputLabel id="manual-rules-tab-add-modal-dialog-protocol-select-label">Choose Protocol</InputLabel>
                        <Select
                            labelId="manual-rules-tab-add-modal-dialog-protocol-select-label"
                            id="manual-rules-tab-add-modal-dialog-protocol-select"
                            label="Protocol"
                            onChange={handleProtocolChanged}
                            defaultValue="4"
                            value={protocol}
                            variant="standard"
                        >
                            {getValidProtocols().map((prot) => {
                                return (
                                    <MenuItem value={prot}>{prot}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        {...(srcAddressValid !== true && { error: undefined })}
                        helperText={srcAddressErrorMessage}
                        label="Source Address(CIDR Block or &quot;any&quot;)"
                        variant="standard"
                        size="small"
                        width="480px"
                        value={srcAddress}
                        onChange={handleSourceAddressChanged}
                        id="manual-rules-tab-add-modal-dialog-source-address-field"
                        style={{ marginTop: '15px' }}
                    />
                    <TextField
                        {...(srcPortValid !== true && { error: undefined })}
                        helperText={srcPortErrorMessage}
                        label="Source Port"
                        variant="standard"
                        size="small"
                        width="480px"
                        value={srcPort}
                        onChange={handleSourcePortChanged}
                        id="manual-rules-tab-add-modal-dialog-source-port-field"
                    />
                    <TextField
                        {...(dstAddressValid !== true && { error: undefined })}
                        helperText={dstAddressErrorMessage}
                        label="Destination Address(CIDR Block or &quot;any&quot;)"
                        variant="standard"
                        size="small"
                        width="480px"
                        value={dstAddress}
                        onChange={handleDestAddressChanged}
                        id="manual-rules-tab-add-modal-dialog-destination-address-field"
                    />
                    <TextField
                        {...(dstPortValid !== true && { error: undefined })}
                        helperText={dstPortErrorMessage}
                        label="Destination Port"
                        variant="standard"
                        size="small"
                        width="480px"
                        value={dstPort}
                        onChange={handleDestPortChanged}
                        id="manual-rules-tab-add-modal-dialog-destination-port-field"
                    />
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={bidirectional}
                            onChange={handleBidirectionalChanged}
                            id="manual-rules-tab-add-modal-dialog-bidirectional-checkbox"
                        />} label="Bidirectional" />
                    </FormGroup>
                    <FormControl id="manual-rules-tab-add-modal-dialog-direction-form-control">
                        <InputLabel id="manual-rules-tab-add-modal-dialog-direction-select-label">Direction</InputLabel>
                        <Select
                            labelId="manual-rules-tab-add-modal-dialog-direction-select-label"
                            id="manual-rules-tab-add-modal-dialog-direction-select"
                            label="Direction"
                            onChange={handleDirectionChanged}
                            defaultValue={MR_DIRECTION_INBOUND}
                            value={direction}
                            variant="standard"
                        >
                            {getValidDirections().map((dir) => {
                                return (
                                    <MenuItem value={dir}>{dir}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl id="manual-rules-tab-add-modal-dialog-action-form-control">
                        <InputLabel id="manual-rules-tab-add-modal-dialog-action-select-label">Action</InputLabel>
                        <Select
                            labelId="manual-rules-tab-add-modal-dialog-action-select-label"
                            id="manual-rules-tab-add-modal-dialog-action-select"
                            label="Action"
                            onChange={handleActionChanged}
                            defaultValue={MR_ACTION_ALLOW}
                            value={action}
                            variant="standard"
                        >
                            {getValidActions().map((act) => {
                                return (
                                    <MenuItem value={act}>{act}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Comments"
                        variant="standard"
                        size="small"
                        width="480px"
                        value={comments}
                        onChange={handleCommentsChanged}
                        id="manual-rules-tab-add-modal-dialog-comments-field"
                    />

                    <TextField
                        label="Validation Status"
                        variant="standard"
                        size="small"
                        width="480px"
                        value={ruleValidationStatusMessage}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="manual-rules-tab-add-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="manual-rules-tab-add-modal-dialog-validate-button"
                            variant="contained"
                            onClick={performValidation}
                        >Validate</Button>
                        <Button id="manual-rules-tab-add-modal-dialog-add-button"
                            variant="contained"
                            onClick={handleAddButtonClick}
                            disabled={!addButtonEnabled}
                        >Add</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
