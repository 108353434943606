import Box from '@mui/material/Box';
import Navs from '../../components/topnav';
import Sidenavs from '../../components/sidenav';
import ReportsComponent from '../../components/Admin/Reports/ReportsComponent';
import { LOGI_ROLE_REPORTS_READ } from '../../common/LogiRoles';
import { useKeycloak } from '@react-keycloak/web';

function ReportsPage() {
	const { keycloak } = useKeycloak();
	let hasReportsRead = keycloak.hasRealmRole(LOGI_ROLE_REPORTS_READ);
	if (keycloak.idTokenParsed) {
		return (
			<div className='App'>
				<Navs />
				<table className='mptable'>
					<tr>
						<td className='sidenavtd'>
							<Box sx={{ position: 'sticky', top: 0 }}>
								<Sidenavs firewallSettingsOpen={false} adminOpen={false}/>
							</Box>
						</td>
						<td class="reports-page-route-td">
							{hasReportsRead ? (<Box sx={{ height: `100%` }}>
								<div class="reports-page-div">
									<ReportsComponent />
								</div>
							</Box>) : (<p>You do not have permission to view this page</p>)}
						</td>
					</tr>
				</table>
			</div>
		);
	} else {
		window.location.href = '/';
	}
};

export default ReportsPage;