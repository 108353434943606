import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useKeycloak } from '@react-keycloak/web';
import { isCorrectIPVersion } from '../../../common/validation/scan/ScanMsgValidator'
import { fetchErrorMessage } from '../../mainpage';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const scanPilotAddSubnetURL = '/middleware/api/scan_disascanpilot/';

export default function AddScanPilotDialog(props) {
    let { open, onClose, fetchData
    } = props;
    const [subnet, setSubnet] = useState('');
    const { keycloak } = useKeycloak();
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
    /* Number of milliseconds to show notifications */
    const notificationAutoclose = 5000;

    const handleFetchErrorClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFetchErrorMessageOpen(false);
    };

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const setSub = (item) => {
        setSubnet(item)
    }

    const updateSubnetField = (subnet) => {
        setSub(subnet);
        const valid = isCorrectIPVersion(subnet, 6);
        if (valid.res === true) {
            setAddButtonStatus(false);
        } else {
            setAddButtonStatus(true);
        }
    }

    const validateSubnet = async () => {
        const postData = { 'subnet': subnet }
        await fetch(scanPilotAddSubnetURL, {
            method: 'POST',
            headers: {
                'access-token': keycloak.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((respData) => {
                onClose();
                setSubnet('');
                fetchData();
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    }
    // snackbar state vars
    const [snackState,] = React.useState({
        snackbarVertical: 'bottom',
        snackbarHorizontal: 'center',
    });

    const { snackbarHorizontal, snackbarVertical } = snackState;


    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle> DISA Scan Pilot</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        label="Subnet"
                        variant="outlined"
                        size="small"
                        value={subnet}
                        onChange={(evt) => updateSubnetField(evt.target.value)}
                        id="scan-pilot-add-modal-subnet-field"
                        style={{ marginTop: '15px' }}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="scan-pilot-add-modal-add-button"
                            variant='contained'
                            disabled={addButtonStatus} onClick={() => validateSubnet()}>Add</Button>
                        <Button id="scan-pilot-add-modal-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                    </Stack>
                </Stack>
                <Snackbar
                    open={fetchErrorMessageOpen}
                    autoHideDuration={notificationAutoclose}
                    onClose={handleFetchErrorClosed}
                    anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
                >
                    <MuiAlert
                        className="logi-snackbar-notification-message"
                        severity="info"
                        variant="filled"
                        sx={{ width: '100%' }}>
                        {fetchErrorMessage}
                    </MuiAlert>
                </Snackbar>
            </div>
        </Dialog>
    );
}