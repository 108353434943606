import Box from '@mui/material/Box';
import Navs from '../../components/topnav';
import Sidenavs from '../../components/sidenav';
import ScheduledTasksComponent from '../../components/Admin/ScheduledTasks/ScheduledTasksComponent';
import { LOGI_ROLE_SCHEDULED_TASKS_READ } from '../../common/LogiRoles';
import { useKeycloak } from '@react-keycloak/web';
import "./ScheduledTasksPage.css";

function ScheduledTasksPage() {
	const { keycloak } = useKeycloak();

	let hasScheduledTasksRead = keycloak.hasRealmRole(LOGI_ROLE_SCHEDULED_TASKS_READ);
	if (keycloak.idTokenParsed) {
		return (
			<div className='App'>
				<Navs />
				<table className='mptable'>
					<tr>
						<td className='sidenavtd'>
							<Box sx={{ position: 'sticky', top: 0 }}>
								<Sidenavs firewallSettingsOpen={false} adminOpen={true}/>
							</Box>
						</td>
						<td class="scheduled-tasks-page-td">
							{hasScheduledTasksRead ? (<Box sx={{ height: `100%` }}>
								<div class="scheduled-tasks-page-div">
									<ScheduledTasksComponent />
								</div>
							</Box>) : (<p>You do not have permission to view this page</p>)}
						</td>
					</tr>
				</table>
			</div>
		);
	} else {
		window.location.href = '/';
	}
};

export default ScheduledTasksPage