import Box from '@mui/material/Box';
import Navs from '../components/topnav';
import Sidenavs from '../components/sidenav';
import { useKeycloak } from '@react-keycloak/web';
import AnalysisComponent from '../components/Connections/AnalysisComponent';
import '../components/Connections/AnalysisComponent.css';

export default function AnalysisPage() {
    const { keycloak } = useKeycloak();
    if (keycloak.idTokenParsed) {
        return (
            <div className='App'>
                <Navs />
                <table className='mptable'>
                    <tr>
                        <td className='sidenavtd'>
                            <Box sx={{ position: 'sticky', top: 0 }}>
                                <Sidenavs firewallSettingsOpen={true} adminOpen={false}/>
                            </Box>
                        </td>
                        <td class="analysis-component-td">
                            <Box sx={{ height: `100%` }}>
                                <div class="analysis-component-div">
                                    <AnalysisComponent />
                                </div>
                            </Box>
                        </td>
                    </tr>
                </table>
            </div>
        );
    } else {
        window.location.href = '/';
    }
};