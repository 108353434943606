import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useKeycloak } from '@react-keycloak/web';
import { isValidIpVersionCidr } from '../../../common/validation/bll/BLLValidator';
import { fetchErrorMessage } from '../../mainpage';
import {
    getValidDirections,
    PC_DIRECTION_INBOUND
}
    from '../../../common/validation/pcap/PcapValidator';


const PcapRulesAddRuleURL = '/middleware/api/pcap_rules/';

export default function PcapAddRuleDialog(props) {
    let { open, onClose, fetchData,
        sourceAddress,
        sourceAddressSetter,
        bidirectional,
        bidirectionalSetter,
        direction,
        directionSetter,
        destinationAddress,
        destinationAddressSetter,
    } = props;
    const { keycloak } = useKeycloak();
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
    /* Number of milliseconds to show notifications */
    const notificationAutoclose = 5000;

    const handleFetchErrorClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFetchErrorMessageOpen(false);
    };

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const setSourceAddress = (item) => {
        sourceAddressSetter(item)
    }

    const setDestinationAddress = (item) => {
        destinationAddressSetter(item)
    }

    const handleBidirectionalChanged = (event) => {
        bidirectionalSetter(event.target.checked);
    };

    const handleDirectionChanged = (event) => {
        directionSetter(event.target.value);
    };

    const updateSourceAddressField = (sourceAddress) => {
        setSourceAddress(sourceAddress)
        const valid = isValidIpVersionCidr(sourceAddress)
        if (valid.res === true) {
            setAddButtonStatus(false)
        } else {
            setAddButtonStatus(true)
        }
    }

    const updateDestinationAddressField = (destinationAddress) => {
        setDestinationAddress(destinationAddress)
        const valid = isValidIpVersionCidr(destinationAddress)
        if (valid.res === true) {
            setAddButtonStatus(false)
        } else {
            setAddButtonStatus(true)
        }
    }

    const validateRule = async () => {
        const postData = {
            'source_address': sourceAddress,
            'destination_address': destinationAddress,
            'bidirectional': bidirectional,
            'direction': direction,
        }
        await fetch(PcapRulesAddRuleURL, {
            method: 'POST',
            headers: {
                'access-token': keycloak.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((respData) => {
                onClose();
                sourceAddressSetter('');
                fetchData();
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    }
    // snackbar state vars
    const [snackState,] = React.useState({
        snackbarVertical: 'bottom',
        snackbarHorizontal: 'center',
    });

    const { snackbarHorizontal, snackbarVertical } = snackState;

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>PCAP Rule</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        label="Source Address"
                        variant="outlined"
                        size="small"
                        value={sourceAddress}
                        onChange={(evt) => updateSourceAddressField(evt.target.value)}
                        id="pcap-rules-add-modal-source-address-field"
                        style={{ marginTop: '15px' }}
                    />
                    <TextField
                        label="Destination Address"
                        variant="outlined"
                        size="small"
                        value={destinationAddress}
                        onChange={(evt) => updateDestinationAddressField(evt.target.value)}
                        id="pcap-rules-add-modal-destination-address-field"
                        style={{ marginTop: '15px' }}
                    />
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={bidirectional}
                            onChange={handleBidirectionalChanged}
                            id="pcap-rules-tab-add-modal-dialog-bidirectional-checkbox"
                        />} label="Bidirectional" />
                    </FormGroup>
                    <FormControl id="pcap-rules-tab-add-modal-dialog-direction-form-control">
                        <InputLabel id="pcap-rules-tab-add-modal-dialog-direction-select-label">Direction</InputLabel>
                        <Select
                            labelId="pcap-rules-tab-add-modal-dialog-direction-select-label"
                            id="pcap-rules-tab-add-modal-dialog-direction-select"
                            label="Direction"
                            onChange={handleDirectionChanged}
                            defaultValue={PC_DIRECTION_INBOUND}
                            value={direction}
                            variant="standard"
                        >
                            {getValidDirections().map((dir) => {
                                return (
                                    <MenuItem value={dir}>{dir}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="pcap-rules-add-modal-add-button"
                            variant='contained'
                            disabled={addButtonStatus} onClick={() => validateRule()}>Add</Button>
                        <Button id="pcap-rules-add-modal-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                    </Stack>
                </Stack>
                <Snackbar
                    open={fetchErrorMessageOpen}
                    autoHideDuration={notificationAutoclose}
                    onClose={handleFetchErrorClosed}
                    anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
                >
                    <MuiAlert
                        className="logi-snackbar-notification-message"
                        severity="info"
                        variant="filled"
                        sx={{ width: '100%' }}>
                        {fetchErrorMessage}
                    </MuiAlert>
                </Snackbar>
            </div>
        </Dialog>
    );
}
