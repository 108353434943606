import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols
}
    from '../../common/table/TableHelper';
import PaginatedTableHead from '../../common/table/PaginatedTableHead';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { useKeycloak } from '@react-keycloak/web';
import {
    LOGI_ROLE_ASN_BLOCK_ADD,
    LOGI_ROLE_ASN_BLOCK_DELETE,
    LOGI_ROLE_ASN_FETCH
} from '../../common/LogiRoles';

const rowsPerPage = 20;

const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Id',
        hide: true
    },
    {
        id: 'autonomous_system_number',
        numeric: true,
        disablePadding: true,
        label: 'Autonomous System Number',
        hide: false
    }
];

const hiddenCols = populateHiddenCols(headCells);

function getRowCells(row, labelId, isItemSelected, handleClick) {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("id") ? (<TableCell align="left">{row.id}</TableCell>) : ''}
            {!hiddenCols.has("autonomous_system_number") ? (<TableCell align="left">{row.autonomous_system_number}</TableCell>) : ''}
        </TableRow>
    )
}

export default function BlockedAsnTab(props) {
    const {
        addButtonEnabled,
        addButtonClickListener,
        deleteButtonEnabled,
        deleteButtonClickListener,
        rowSelectionChanged,
        order,
        orderSetter,
        orderBy,
        orderBySetter,
        offset,
        offsetSetter,
        page,
        pageSetter,
        rows,
        setBlockedAsnSwitch,
        total,
        selected,
        selectedSetter,
        fetchData,
        refreshButtonClickHandler,
        adhocPollButtonClickListener,
        dbpull
    } = props;

    const { keycloak } = useKeycloak();

    const hasASNBlockAdd = keycloak.hasRealmRole(LOGI_ROLE_ASN_BLOCK_ADD);
    const hasASNBlockDelete = keycloak.hasRealmRole(LOGI_ROLE_ASN_BLOCK_DELETE);
    const hasAsnFetch = keycloak.hasRealmRole(LOGI_ROLE_ASN_FETCH);

    useEffect(() => {
        fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy() === property && order() === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        setBlockedAsnSwitch();
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleChangePage = (event, newPage) => {
        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        selectedSetter([]);
        pageSetter(newPage);
        setBlockedAsnSwitch();
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);

            selectedSetter(newSelected);
            rowSelectionChanged(newSelected);
            return;
        }
        selectedSetter([]);
        rowSelectionChanged([]);
    };

    const handleRowClick = (event, row) => {

        const selectedIndex = selected.indexOf(row.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row.id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        rowSelectionChanged(newSelected);
        selectedSetter(newSelected);
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    const handleDeleteButtonClicked = (event) => {
        deleteButtonClickListener(fetchData);

    };

    const handleAddButtonClicked = (event) => {
        addButtonClickListener();
    };

    return (
        <div id="asn-blocked-asn-table-top-level-div">
            <div id="asn-blocked-asn-table-button-bar-div">
                <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Blocked ASN Refresh</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2} direction="row">
                            <Button id="asn-blocked-asn-refresh-button"
                                variant="contained"
                                onClick={refreshButtonClickHandler}
                                disabled={!addButtonEnabled}
                            // width="1"
                            >Refresh</Button>
                            {hasAsnFetch === true ? (<Button id="asn-adhoc-refresh-button"
                                variant="contained"
                                onClick={adhocPollButtonClickListener}>Adhoc RIPE ASN Sync</Button>) : ('')}
                            <Stack spacing={2} direction="row">
                                <span style={{ 'padding': '10px', 'margin': '10px' }}>Last Pull info: </span>
                                <Alert severity="info">{dbpull}</Alert>
                            </Stack>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div id="asn-blocked-asn-table-div" style={{ overflow: 'hidden' }}>
                <Stack spacing={2} direction="row">
                    <Box
                        sx={{ width: '100%' }}
                        id="asn-blocked-asn-table-box">
                        <TableContainer>
                            <Table
                                sx={{ width: '100%' }}
                                aria-labelledby="tableTitle"
                                size={'small'}
                                id="asn-blocked-asn-table"
                            >
                                <PaginatedTableHead
                                    numSelected={selected.length}
                                    order={order()}
                                    orderBy={orderBy()}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                    fetchMethod={fetchData}
                                    id="asn-blocked-asn-table-head"
                                />
                                <TableBody id="asn-blocked-asn-table-body">
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                    {rows.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `asn-blocked-asn-table-checkbox-${index}`;

                                        return (
                                            getRowCells(row, labelId, isItemSelected, handleRowClick)
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                // height: 33 * emptyRows,
                                                height: 33,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            labelDisplayedRows={defaultLabelDisplayedRows}
                        />
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </Box>
                    <div class="asn-blocked-asn-table-buttons-div">
                        <Stack spacing={2} direction="column">
                            {hasASNBlockAdd ? (<Button id="asn-blocked-asn-table-add-button"
                                variant="contained"
                                onClick={handleAddButtonClicked}
                                disabled={!addButtonEnabled}>Add</Button>) : (<p />)}
                            {hasASNBlockDelete ? (<Button id="asn-blocked-asn-table-delete-button"
                                variant="contained"
                                onClick={handleDeleteButtonClicked}
                                disabled={!deleteButtonEnabled}>Delete</Button>
                            ) : (<p />)}
                        </Stack>
                    </div>
                </Stack>
            </div>
        </div>
    );
}
