/**
 * Validate that the input is a number suitable to be used as an ASN
 * 
 * @param {*} input 
 */

import IPCIDR from "ip-cidr";

import { isIP } from 'is-ip';

export function isValidScanMsgIPValue(input) {
    let valid = true;
    let msg = "";
    let ret = { res: valid, msg: msg };


    if (input === null) {
        // null input is not at all valid
        ret.res = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.res = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }

    if (!isIP(input)) {
        // not a number, so not valid
        ret.res = false;
        ret.msg = "input must be a valid IP";
        return ret;
    }
    return ret;
}

export function isValidScanMsgCidrValue(input) {
    let res = true;
    let msg = "";
    let ret = { res: res, msg: msg };


    if (input === null) {
        // null input is not at all valid
        ret.res = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.res = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }

    try {
        let cidrResult = IPCIDR.isValidCIDR(trimmedInput);

        if (cidrResult) {
            // do additional host bits checking
            const parsed = new IPCIDR(trimmedInput);
            const address = parsed.address;
            const addressStart = parsed.addressStart;

            const addrBytes = JSON.stringify(address.parsedAddress);
            const addrStartBytes = JSON.stringify(addressStart.parsedAddress);

            if (addrBytes !== addrStartBytes) {
                // the cidr block specified has host bits set
                ret.res = false;
                ret.msg = "the cidr block has host bits specified";
            }
        }
        else {
            ret.res = false;
            ret.msg = "input must be a valid IP CIDR block";
        }
    } catch {
        ret.res = false;
        ret.msg = "input must be a valid IP CIDR block";
    }
    return ret;
}
export function isCorrectIPVersion(input, version) {
    let res = true;
    let msg = "";
    let ret = { res: res, msg: msg };


    const isCidr = require("is-cidr");
    switch (version) {
        case 4:
            ret.res = isCidr.v4(input);
            break;
        case 6:
            ret.res = isCidr.v6(input);
            break;
        default:
            break;
    }
    if (!ret.res) {
        // IP Version incorrect
        ret.msg = "input must be IPv" + version;
    }
    return ret;
}



export function isValidScanMsgSensorLocationValue(input) {
    let res = true;
    let msg = "";
    let ret = { valid: res, msg: msg };


    if (input === null) {
        // null input is not at all valid
        ret.valid = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.valid = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }
    return ret;
}

