/**
 * A set of functions that can be used to validate that specific data typed in text fields in the cron expression editor modal is valid for the specific type of field.
 * 
 * The unit tests are run using Jest.
 * I followed this guide:  https://www.freecodecamp.org/news/how-to-start-unit-testing-javascript/
 * 
 * I run the tests in this particular file with this jest command-line:
 * 
 * jest --testPathPattern src/common/validation/cron --testRegex '.+\.js'
 * 
 */

/**
 * Validate that the input would be valid as minute parameter in a cron expression
 * 
 * @param {*} input 
 */
export function isValidCronMinute(input) {
    let ret = true;
    const minSingleNumber = 0;
    const maxSingleNumber = 59;
    const minStepNumber = 1;
    const maxStepNumber = 9999;  // limit to 4 numbers for */n specification

    if (input === null) {
        // null input is not at all valid
        ret = false;
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret = false;
        return ret;
    }

    if ('*' === trimmedInput) {
        // easiest case, a *, so simply return true here
        return ret;
    }

    if (isNaN(input)) {
        // could be a more complex string like */60 or 0,15,30 or 1-7
        // the only valid chars in the minute are 0-9, ",", "-" and "/"
        // so by definition if it has any chars other than these, it's not valid
        let validCharsPattern = '^[0-9\\-,\\*\\/]+$';
        let pMatch = trimmedInput.match(validCharsPattern);
        if (pMatch === null) {
            // no match 
            ret = false;
            return ret;
        }

        // the input string contained only valid chars

        // now we have to split on comma and then see if there are multiple parts to check
        let values = trimmedInput.split(",");
        let valueIndex = null;

        // for each element of the array returned, 
        for (valueIndex in values) {
            // value could be a single number, a range(i.e. 1-7), or something like */5
            let value = values[valueIndex].trim();

            // if the expression contains a - we'll try to validate it as a range            
            let dashLoc = value.indexOf("-");

            // if the expression contains a / we'll try to evaluate as */<digits>
            let slashLoc = value.indexOf("/");

            // if the expression contains neither a - or a / we'll try to evaluate as a single number
            if (dashLoc > -1) {
                // check this as a range
                let rangePattern = '^(?<start>[0-9]{1,2})-(?<end>[0-9]{1,2})$';
                let rangeMatch = value.match(rangePattern);
                if (rangeMatch === null) {
                    ret = false;
                    return ret;
                }

                let start = parseInt(rangeMatch.groups.start);
                let end = parseInt(rangeMatch.groups.end);

                if (start > end) {
                    ret = false;
                    return ret;
                }

                // check that start and end are in allowed range of numbers
                if (start < minSingleNumber || start > maxSingleNumber) {
                    ret = false;
                    return ret;
                }
                if (end < minSingleNumber || end > maxSingleNumber) {
                    ret = false;
                    return ret;
                }
            }
            else if (slashLoc > -1) {
                // check this is a */n, i.e. every n minutes
                let stepPattern = "^\\*\\/(?<num>[0-9]{1,4})$"
                let stepMatch = value.match(stepPattern);
                if (stepMatch === null) {
                    ret = false;
                    return ret;
                }

                let stepNumber = stepMatch.groups.num;
                if (isNaN(stepNumber)) {
                    ret = false;
                    return ret;
                }

                let num = parseInt(stepNumber);

                if (num < minStepNumber || num > maxStepNumber) {
                    ret = false;
                    return ret;
                }
            }
            else {
                // check this as a single number
                if (isNaN(value)) {
                    ret = false;
                    return ret;
                }

                let parsedNum = parseInt(value);
                if (parsedNum < minSingleNumber || parsedNum > maxSingleNumber) {
                    // outside our allowed range
                    ret = false;
                }
            }
        }
    }
    else {
        // we have a number, just do a simple range check
        // for a minute, it can be 0 - 59

        let parsedNum = parseInt(trimmedInput);
        if (parsedNum < minSingleNumber || parsedNum > maxSingleNumber) {
            // outside our allowed range
            ret = false;
        }
    }
    return ret;
}

//
// Minute tests
// test("Returns true for * minute value", () => {
//     expect(isValidCronMinute("*")).toBe(true);
// });
// test("Returns true for 0 minute value", () => {
//     expect(isValidCronMinute("0")).toBe(true);
// });
// test("Returns false for 0,61 minute value", () => {
//     expect(isValidCronMinute("0,61")).toBe(false);
// });
// test("Returns true for 0,15,30,45 minute value", () => {
//     expect(isValidCronMinute("0,15,30,45")).toBe(true);
// });
// test("Returns false for 0- minute value", () => {
//     expect(isValidCronMinute("0-")).toBe(false);
// });
// test("Returns false for 1-0 minute value", () => {
//     expect(isValidCronMinute("1-0")).toBe(false);
// });
// test("Returns false for 61-62 minute value", () => {
//     expect(isValidCronMinute("61-62")).toBe(false);
// });
// test("Returns false for 0-62 minute value", () => {
//     expect(isValidCronMinute("0-62")).toBe(false);
// });
// test("Returns true for 0-7 minute value", () => {
//     expect(isValidCronMinute("0-7")).toBe(true);
// });
// test("Returns true for */137 minute value", () => {
//     expect(isValidCronMinute("*/137")).toBe(true);
// });
// test("Returns false for */0 minute value", () => {
//     expect(isValidCronMinute("*/0")).toBe(false);
// });
// test("Returns false for */10000 minute value", () => {
//     expect(isValidCronMinute("*/10000")).toBe(false);
// });

/**
 * Validate that the input would be valid as hour parameter in a cron expression
 * 
 * @param {*} input 
 */
export function isValidCronHour(input) {
    let ret = true;
    const minSingleNumber = 0;
    const maxSingleNumber = 23;
    const minStepNumber = 1;
    const maxStepNumber = 999;  // limit to 3 numbers for */n specification

    if (input === null) {
        // null input is not at all valid
        ret = false;
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret = false;
        return ret;
    }

    if ('*' === trimmedInput) {
        // easiest case, a *, so simply return true here
        return ret;
    }

    if (isNaN(input)) {
        // could be a more complex string like */60 or 0,15,23 or 1-7
        // the only valid chars in the hour are 0-9, ",", "-" and "/"
        // so by definition if it has any chars other than these, it's not valid
        let validCharsPattern = '^[0-9\\-,\\*\\/]+$';
        let pMatch = trimmedInput.match(validCharsPattern);
        if (pMatch === null) {
            // no match 
            ret = false;
            return ret;
        }

        // the input string contained only valid chars

        // now we have to split on comma and then see if there are multiple parts to check
        let values = trimmedInput.split(",");
        let valueIndex = null;

        // for each element of the array returned, 
        for (valueIndex in values) {
            // value could be a single number, a range(i.e. 1-7), or something like */5
            let value = values[valueIndex].trim();

            // if the expression contains a - we'll try to validate it as a range            
            let dashLoc = value.indexOf("-");

            // if the expression contains a / we'll try to evaluate as */<digits>
            let slashLoc = value.indexOf("/");

            // if the expression contains neither a - or a / we'll try to evaluate as a single number
            if (dashLoc > -1) {
                // check this as a range
                let rangePattern = '^(?<start>[0-9]{1,2})-(?<end>[0-9]{1,2})$';
                let rangeMatch = value.match(rangePattern);
                if (rangeMatch === null) {
                    ret = false;
                    return ret;
                }

                let start = parseInt(rangeMatch.groups.start);
                let end = parseInt(rangeMatch.groups.end);

                if (start > end) {
                    ret = false;
                    return ret;
                }

                // check that start and end are in allowed range of numbers
                if (start < minSingleNumber || start > maxSingleNumber) {
                    ret = false;
                    return ret;
                }
                if (end < minSingleNumber || end > maxSingleNumber) {
                    ret = false;
                    return ret;
                }
            }
            else if (slashLoc > -1) {
                // check this is a */n, i.e. every n hours
                let stepPattern = "^\\*\\/(?<num>[0-9]{1,4})$"
                let stepMatch = value.match(stepPattern);
                if (stepMatch === null) {
                    ret = false;
                    return ret;
                }

                let stepNumber = stepMatch.groups.num;
                if (isNaN(stepNumber)) {
                    ret = false;
                    return ret;
                }

                let num = parseInt(stepNumber);

                if (num < minStepNumber || num > maxStepNumber) {
                    ret = false;
                    return ret;
                }
            }
            else {
                // check this as a single number
                if (isNaN(value)) {
                    ret = false;
                    return ret;
                }

                let parsedNum = parseInt(value);
                if (parsedNum < minSingleNumber || parsedNum > maxSingleNumber) {
                    // outside our allowed range
                    ret = false;
                }
            }
        }
    }
    else {
        // we have a number, just do a simple range check
        // for a minute, it can be 0 - 23

        let parsedNum = parseInt(trimmedInput);
        if (parsedNum < minSingleNumber || parsedNum > maxSingleNumber) {
            // outside our allowed range
            ret = false;
        }
    }
    return ret;
}

//
// Hour tests
// test("Returns true for * hour value", () => {
//     expect(isValidCronHour("*")).toBe(true);
// });
// test("Returns true for 0 hour value", () => {
//     expect(isValidCronHour("0")).toBe(true);
// });
// test("Returns false for 25 hour value", () => {
//     expect(isValidCronHour("25")).toBe(false);
// });
// test("Returns true for 0-7 hour value", () => {
//     expect(isValidCronHour("0-7")).toBe(true);
// });
// test("Returns false for 7-0 hour value", () => {
//     expect(isValidCronHour("7-0")).toBe(false);
// });
// test("Returns true for 0,3,5,7,9 hour value", () => {
//     expect(isValidCronHour("0,3,5,7,9")).toBe(true);
// });
// test("Returns true for */5 hour value", () => {
//     expect(isValidCronHour("*/5")).toBe(true);
// });
// test("Returns true for 3,*/5,1-7 hour value", () => {
//     expect(isValidCronHour("3,*/5,1-7")).toBe(true);
// });
// test("Returns false for */1000 hour value", () => {
//     expect(isValidCronHour("*/1000")).toBe(false);
// });

/**
 * Validate that the input would be valid as day of month parameter in a cron expression
 * 
 * @param {*} input 
 */
export function isValidCronDayOfMonth(input) {
    let ret = true;
    const minSingleNumber = 1;
    const maxSingleNumber = 31;
    const minStepNumber = 2;
    const maxStepNumber = 99;  // limit to 2 numbers for */n specification

    if (input === null) {
        // null input is not at all valid
        ret = false;
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret = false;
        return ret;
    }

    if ('*' === trimmedInput) {
        // easiest case, a *, so simply return true here
        return ret;
    }

    if (isNaN(input)) {
        // could be a more complex string like */60 or 0,15,23 or 1-7
        // the only valid chars in the day are 0-9, ",", "-" and "/"
        // so by definition if it has any chars other than these, it's not valid
        let validCharsPattern = '^[0-9\\-,\\*\\/]+$';
        let pMatch = trimmedInput.match(validCharsPattern);
        if (pMatch === null) {
            // no match 
            ret = false;
            return ret;
        }

        // the input string contained only valid chars

        // now we have to split on comma and then see if there are multiple parts to check
        let values = trimmedInput.split(",");
        let valueIndex = null;

        // for each element of the array returned, 
        for (valueIndex in values) {
            // value could be a single number, a range(i.e. 1-7), or something like */5
            let value = values[valueIndex].trim();

            // if the expression contains a - we'll try to validate it as a range            
            let dashLoc = value.indexOf("-");

            // if the expression contains a / we'll try to evaluate as */<digits>
            let slashLoc = value.indexOf("/");

            // if the expression contains neither a - or a / we'll try to evaluate as a single number
            if (dashLoc > -1) {
                // check this as a range
                let rangePattern = '^(?<start>[0-9]{1,2})-(?<end>[0-9]{1,2})$';
                let rangeMatch = value.match(rangePattern);
                if (rangeMatch === null) {
                    ret = false;
                    return ret;
                }

                let start = parseInt(rangeMatch.groups.start);
                let end = parseInt(rangeMatch.groups.end);

                if (start > end) {
                    ret = false;
                    return ret;
                }

                // check that start and end are in allowed range of numbers
                if (start < minSingleNumber || start > maxSingleNumber) {
                    ret = false;
                    return ret;
                }
                if (end < minSingleNumber || end > maxSingleNumber) {
                    ret = false;
                    return ret;
                }
            }
            else if (slashLoc > -1) {
                // check this is a */n, i.e. every n days
                let stepPattern = "^\\*\\/(?<num>[0-9]{1,4})$"
                let stepMatch = value.match(stepPattern);
                if (stepMatch === null) {
                    ret = false;
                    return ret;
                }

                let stepNumber = stepMatch.groups.num;
                if (isNaN(stepNumber)) {
                    ret = false;
                    return ret;
                }

                let num = parseInt(stepNumber);

                if (num < minStepNumber || num > maxStepNumber) {
                    ret = false;
                    return ret;
                }
            }
            else {
                // check this as a single number
                if (isNaN(value)) {
                    ret = false;
                    return ret;
                }

                let parsedNum = parseInt(value);
                if (parsedNum < minSingleNumber || parsedNum > maxSingleNumber) {
                    // outside our allowed range
                    ret = false;
                }
            }
        }
    }
    else {
        // we have a number, just do a simple range check
        // for a minute, it can be 1 - 31

        let parsedNum = parseInt(trimmedInput);
        if (parsedNum < minSingleNumber || parsedNum > maxSingleNumber) {
            // outside our allowed range
            ret = false;
        }
    }
    return ret;
}

//
// day of month tests
// test("Returns true for * day of month value", () => {
//     expect(isValidCronDayOfMonth("*")).toBe(true);
// });
// test("Returns true for 1 day of month value", () => {
//     expect(isValidCronDayOfMonth("1")).toBe(true);
// });
// test("Returns false for 32 day of month value", () => {
//     expect(isValidCronDayOfMonth("32")).toBe(false);
// });
// test("Returns true for 1,5,7,9 day of month value", () => {
//     expect(isValidCronDayOfMonth("1,5,7,9")).toBe(true);
// });
// test("Returns true for */5 day of month value", () => {
//     expect(isValidCronDayOfMonth("*/5")).toBe(true);
// });
// test("Returns true for */99 day of month value", () => {
//     expect(isValidCronDayOfMonth("*/9")).toBe(true);
// });
// test("Returns true for */5,6,1-2 day of month value", () => {
//     expect(isValidCronDayOfMonth("*/5,6,1-2")).toBe(true);
// });
// test("Returns false for */100 day of month value", () => {
//     expect(isValidCronDayOfMonth("*/100")).toBe(false);
// });

/**
 * Validate that the input would be valid as month of year parameter in a cron expression
 * 
 * @param {*} input 
 */
export function isValidCronMonthOfYear(input) {
    let ret = true;
    const minSingleNumber = 1;
    const maxSingleNumber = 12;
    const minStepNumber = 2;
    const maxStepNumber = 99;  // limit to 2 numbers for */n specification

    if (input === null) {
        // null input is not at all valid
        ret = false;
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret = false;
        return ret;
    }

    if ('*' === trimmedInput) {
        // easiest case, a *, so simply return true here
        return ret;
    }

    // build a map of month to integer
    let months = new Map();
    months.set("JAN", 1);
    months.set("FEB", 2);
    months.set("MAR", 3);
    months.set("APR", 4);
    months.set("MAY", 5);
    months.set("JUN", 6);
    months.set("JUL", 7);
    months.set("AUG", 8);
    months.set("SEP", 9);
    months.set("OCT", 10);
    months.set("NOV", 11);
    months.set("DEC", 12);

    if (isNaN(input)) {
        // could be a more complex string like JAN, */2 or 0,3,7 or 1-7
        // the only valid chars in the month are ABCDEFGJLMNOPRSTUVY, 0-9, ",", "-" and "/"
        // so by definition if it has any chars other than these, it's not valid
        let validCharsPattern = '^[0-9ABCDEFGJLMNOPRSTUVY\\-,\\*\\/]+$';
        let pMatch = trimmedInput.match(validCharsPattern);
        if (pMatch === null) {
            // no match 
            ret = false;
            return ret;
        }

        // the input string contained only valid chars

        // now we have to split on comma and then see if there are multiple parts to check
        let values = trimmedInput.split(",");
        let valueIndex = null;

        // for each element of the array returned, 
        for (valueIndex in values) {
            // value could be a single number, a range(i.e. 1-7), or something like */5
            let value = values[valueIndex].trim();

            // if the expression contains a - we'll try to validate it as a range            
            let dashLoc = value.indexOf("-");

            // if the expression contains a / we'll try to evaluate as */<digits>
            let slashLoc = value.indexOf("/");

            // if the expression contains neither a - or a / we'll try to evaluate as a single number
            if (dashLoc > -1) {
                // check this as a range
                let rangePattern = '^(?<start>[0-9]{1,2}|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)-(?<end>[0-9]{1,2}|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)$';
                let rangeMatch = value.match(rangePattern);
                if (rangeMatch === null) {
                    ret = false;
                    return ret;
                }

                // start and end may be either a number 1-12, or may be a name like JAN
                let startStr = rangeMatch.groups.start;
                let startNum = null;
                let endStr = rangeMatch.groups.end;
                let endNum = null;

                if (!isNaN(startStr)) {
                    // startStr is a numbered month
                    startNum = parseInt(startStr);
                }
                if (!isNaN(endStr)) {
                    // endStr is a numbered month
                    endNum = parseInt(endStr);
                }

                // check that the user hasn't mixed named months and numbered months, i.e JAN-12
                if ((startNum === null && endNum !== null) || (startNum !== null && endNum === null)) {
                    ret = false;
                    return ret;
                }

                // if we got here the start and end are either numbers or names, but not a mix of both
                if (startNum !== null) {
                    // we have numbers, so do some checking
                    if (startNum > endNum) {
                        ret = false;
                        return ret;
                    }

                    // check that start and end are in allowed range of numbers
                    if (startNum < minSingleNumber || startNum > maxSingleNumber) {
                        ret = false;
                        return ret;
                    }
                    if (endNum < minSingleNumber || endNum > maxSingleNumber) {
                        ret = false;
                        return ret;
                    }
                }
                else {
                    // we have named months, check a different way

                    startNum = months.get(startStr);
                    endNum = months.get(endStr);

                    if (startNum > endNum) {
                        ret = false;
                        return ret;
                    }

                    // check that start and end are in allowed range of numbers
                    if (startNum < minSingleNumber || startNum > maxSingleNumber) {
                        ret = false;
                        return ret;
                    }
                    if (endNum < minSingleNumber || endNum > maxSingleNumber) {
                        ret = false;
                        return ret;
                    }
                }
            }
            else if (slashLoc > -1) {
                // check this is a */n, i.e. every n months
                let stepPattern = "^\\*\\/(?<num>[0-9]{1,2})$"
                let stepMatch = value.match(stepPattern);
                if (stepMatch === null) {
                    ret = false;
                    return ret;
                }

                let stepNumber = stepMatch.groups.num;
                if (isNaN(stepNumber)) {
                    ret = false;
                    return ret;
                }

                let num = parseInt(stepNumber);

                if (num < minStepNumber || num > maxStepNumber) {
                    ret = false;
                    return ret;
                }
            }
            else {
                // check this as a single number or named month
                let parsedNum = null;
                if (isNaN(value)) {
                    parsedNum = months.get(value);
                    if (parsedNum === undefined) {
                        ret = false;
                        return ret;
                    }
                }
                else {
                    parsedNum = parseInt(value);
                }

                if (parsedNum < minSingleNumber || parsedNum > maxSingleNumber) {
                    // outside our allowed range
                    ret = false;
                }
            }
        }
    }
    else {
        // we have a number, just do a simple range check
        // for a month, it can be 1 - 12
        let parsedNum = parseInt(trimmedInput);

        if (parsedNum === null) {

            ret = false;
        }
        if (parsedNum < minSingleNumber || parsedNum > maxSingleNumber) {
            // outside our allowed range
            ret = false;
        }
    }
    return ret;
}

//
// month of year tests
// test("Returns true for * month of year value", () => {
//     expect(isValidCronMonthOfYear("*")).toBe(true);
// });
// test("Returns true for 1 month of year value", () => {
//     expect(isValidCronMonthOfYear("1")).toBe(true);
// });
// test("Returns true for JAN month of year value", () => {
//     expect(isValidCronMonthOfYear("JAN")).toBe(true);
// });
// test("Returns false for 13 month of year value", () => {
//     expect(isValidCronMonthOfYear("13")).toBe(false);
// });
// test("Returns false for FOO month of year value", () => {
//     expect(isValidCronMonthOfYear("FOO")).toBe(false);
// });
// test("Returns true for JAN-DEC month of year value", () => {
//     expect(isValidCronMonthOfYear("JAN-DEC")).toBe(true);
// });
// test("Returns true for 1-12 month of year value", () => {
//     expect(isValidCronMonthOfYear("1-12")).toBe(true);
// });
// test("Returns true for 1-2,3-4,5-6 month of year value", () => {
//     expect(isValidCronMonthOfYear("1-2,3-4,5-6")).toBe(true);
// });

/**
 * Validate that the input would be valid as day of week parameter in a cron expression
 * 
 * @param {*} input 
 */
export function isValidCronDayOfWeek(input) {
    let ret = true;
    const minSingleNumber = 0;
    const maxSingleNumber = 6;
    const minStepNumber = 2;
    const maxStepNumber = 1;  // limit to 1 numbers for */n specification

    if (input === null) {
        // null input is not at all valid
        ret = false;
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret = false;
        return ret;
    }

    if ('*' === trimmedInput) {
        // easiest case, a *, so simply return true here
        return ret;
    }

    // build a map of day to integer
    let days = new Map();
    days.set("SUN", 0);
    days.set("MON", 1);
    days.set("TUE", 2);
    days.set("WED", 3);
    days.set("THU", 4);
    days.set("FRI", 5);
    days.set("SAT", 6);

    if (isNaN(input)) {
        // could be a more complex string like MON, */2 or 0,3,6 or 1-6
        // the only valid chars in the day are ADEFHMNORSTU, 0-6, ",", "-" and "/"
        // so by definition if it has any chars other than these, it's not valid
        let validCharsPattern = '^[0-6ADEFHMNORSTU\\-,\\*\\/]+$';
        let pMatch = trimmedInput.match(validCharsPattern);
        if (pMatch === null) {
            // no match 
            ret = false;
            return ret;
        }

        // the input string contained only valid chars

        // now we have to split on comma and then see if there are multiple parts to check
        let values = trimmedInput.split(",");
        let valueIndex = null;

        // for each element of the array returned, 
        for (valueIndex in values) {
            // value could be a single number, a range(i.e. 0-6), or something like */5
            let value = values[valueIndex].trim();

            // if the expression contains a - we'll try to validate it as a range            
            let dashLoc = value.indexOf("-");

            // if the expression contains a / we'll try to evaluate as */<digits>
            let slashLoc = value.indexOf("/");

            // if the expression contains neither a - or a / we'll try to evaluate as a single number
            if (dashLoc > -1) {
                // check this as a range
                let rangePattern = '^(?<start>[0-6]|SUN|MON|TUE|WED|THU|FRI|SAT)-(?<end>[0-6]|SUN|MON|TUE|WED|THU|FRI|SAT)$';
                let rangeMatch = value.match(rangePattern);
                if (rangeMatch === null) {
                    ret = false;
                    return ret;
                }

                // start and end may be either a number 0-6, or may be a name like MON
                let startStr = rangeMatch.groups.start;
                let startNum = null;
                let endStr = rangeMatch.groups.end;
                let endNum = null;

                if (!isNaN(startStr)) {
                    // startStr is a numbered day
                    startNum = parseInt(startStr);
                }
                if (!isNaN(endStr)) {
                    // endStr is a numbered day
                    endNum = parseInt(endStr);
                }

                // check that the user hasn't mixed named days and numbered days, i.e MON-5
                if ((startNum === null && endNum !== null) || (startNum !== null && endNum === null)) {
                    ret = false;
                    return ret;
                }

                // if we got here the start and end are either numbers or names, but not a mix of both
                if (startNum !== null) {
                    // we have numbers, so do some checking
                    if (startNum > endNum) {
                        ret = false;
                        return ret;
                    }

                    // check that start and end are in allowed range of numbers
                    if (startNum < minSingleNumber || startNum > maxSingleNumber) {
                        ret = false;
                        return ret;
                    }
                    if (endNum < minSingleNumber || endNum > maxSingleNumber) {
                        ret = false;
                        return ret;
                    }
                }
                else {
                    // we have named days, check a different way

                    startNum = days.get(startStr);
                    endNum = days.get(endStr);

                    if (startNum > endNum) {
                        ret = false;
                        return ret;
                    }

                    // check that start and end are in allowed range of numbers
                    if (startNum < minSingleNumber || startNum > maxSingleNumber) {
                        ret = false;
                        return ret;
                    }
                    if (endNum < minSingleNumber || endNum > maxSingleNumber) {
                        ret = false;
                        return ret;
                    }
                }
            }
            else if (slashLoc > -1) {
                // check this is a */n, i.e. every n days
                let stepPattern = "^\\*\\/(?<num>[0-6])$"
                let stepMatch = value.match(stepPattern);
                if (stepMatch === null) {
                    ret = false;
                    return ret;
                }

                let stepNumber = stepMatch.groups.num;
                if (isNaN(stepNumber)) {
                    ret = false;
                    return ret;
                }

                let num = parseInt(stepNumber);

                if (num < minStepNumber || num > maxStepNumber) {
                    ret = false;
                    return ret;
                }
            }
            else {
                // check this as a single number or named day
                let parsedNum = null;
                if (isNaN(value)) {
                    parsedNum = days.get(value);
                    if (parsedNum === undefined) {
                        ret = false;
                        return ret;
                    }
                }
                else {
                    parsedNum = parseInt(value);
                }

                if (parsedNum < minSingleNumber || parsedNum > maxSingleNumber) {
                    // outside our allowed range
                    ret = false;
                }
            }
        }
    }
    else {
        // we have a number, just do a simple range check
        // for a day, it can be 0 - 6
        let parsedNum = parseInt(trimmedInput);

        if (parsedNum === null) {

            ret = false;
        }
        if (parsedNum < minSingleNumber || parsedNum > maxSingleNumber) {
            // outside our allowed range
            ret = false;
        }
    }
    return ret;
}

/**
 * Check if the cron expression fields the user wants to use match an existing cron schedule definition.  If the fields match an existing definition,
 * The user will have to change one or more fields.
 * 
 * @param {*} baseUrl Base url to fetch intervals with
 * @param {*} keycloakToken Our keycloak token to authenticate with
 * @param {*} id If supplied, it means we are editing an existing interval and if a result comes for this id we'll allow it since it's updating the same interval
 * @param {*} minute The minute value for cron expression we are checking is in use or not
 * @param {*} hour The hour value for cron expression we are checking is in use or not
 * @param {*} dayOfMonth The dayOfMonth value for cron expression we are checking is in use or not
 * @param {*} monthOfYear The monthOfYear value for cron expression we are checking is in use or not
 * @param {*} dayOfWeek The dayOfMonth value for cron expression we are checking is in use or not
 * @param {*} fieldIsValidCallback A function that we'll call with the final result of true or false
 * @param {*} fieldErrorMessageCallback A function that we'll call to set a final status message
 * 
 * @returns true if an existing interval already exists with the values for every and period set to the same values as supplied to this function, false otherwise
 */
export function isCronExpressionInUse(baseUrl,
    keycloakToken,
    id,
    minute,
    hour,
    dayOfMonth,
    monthOfYear,
    dayOfWeek,
    fieldIsValidCallback,
    fieldErrorMessageCallback) {

    // build the url
    let fetchUrl = baseUrl
        + "?minute=" + encodeURIComponent(minute)
        + "&hour=" + encodeURIComponent(hour)
        + "&day_of_week=" + encodeURIComponent(dayOfWeek)
        + "&day_of_month=" + encodeURIComponent(dayOfMonth)
        + "&month_of_year=" + encodeURIComponent(monthOfYear);

    fetch(fetchUrl, {
        method: 'GET',
        headers: {
            'access-token': keycloakToken
        },
    })
        .then((response) => response.json())
        .then((respData) => {
            let existingId = null
            let inUse = false;

            if (respData.results.length > 0) {
                // should be only one result
                let entry = respData.results[0];
                existingId = entry['id'];
            }

            if (id !== null) {
                // we are editing an existing crontab, so if existingId != null but equals
                // the id of the crontab we are editing, it's no issue
                if (existingId !== null) {
                    if (existingId !== id) {
                        // another crontab has the same values so we must force the user to pick something else
                        inUse = true;
                    }
                    else {
                        // since these fields match crontab we are editing, we'll not say it's in confliect
                    }
                }
                else {
                    // there is existing entry has the same field values, all good
                }
            }
            else {
                // since id is null here, we are likely adding a new crontabthat doesn't have an id yet
                // we we are simply interested if any existing cron entries have exactly the same values
                if (existingId !== null) {
                    // an existing crontab has identical values
                    inUse = true;
                }
            }

            if (inUse) {
                fieldIsValidCallback(false);
                fieldErrorMessageCallback("The values supplied would conflict with an existing entry, please change them to not conflict");
            }
            else {
                fieldIsValidCallback(true);
                fieldErrorMessageCallback("Cron Expression is Valid");
            }
        });
}