import Box from '@mui/material/Box';
import Navs from '../../components/topnav';
import Sidenavs from '../../components/sidenav';
import CeleryComponent from '../../components/Admin/CeleryResults/CeleryComponent';
import { useKeycloak } from '@react-keycloak/web';

function CeleryPage() {
	const { keycloak } = useKeycloak();
	if (keycloak.idTokenParsed) {
		return (
			<div className='App'>
				<Navs />
				<table className='mptable'>
					<tr>
						<td className='sidenavtd'>
							<Box sx={{ position: 'sticky', top: 0 }}>
								<Sidenavs firewallSettingsOpen={false} adminOpen={true}/>
							</Box>
						</td>
						<td class="celery-page-route-td">
							<Box sx={{ height: `100%` }}><div class="celery-page-div">
								<CeleryComponent />
							</div>
							</Box>
						</td>
					</tr>
				</table>
			</div>
		);
	} else {
		window.location.href = '/';
	}
};

export default CeleryPage;