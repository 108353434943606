import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import {
    LOGI_ROLE_GEO_BlOCKED_COUNTRY_ADD,
    LOGI_ROLE_GEO_BlOCKED_COUNTRY_DELETE
} from '../../common/LogiRoles';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols
}
    from '../../common/table/TableHelper';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import SingleSelectTableHead from '../../common/table/SingleSelectTableHead';

const headCells = [
    {
        id: 'country',
        numeric: false,
        disablePadding: true,
        label: 'Blocked Country',
        hide: false,
    }
];

const hiddenCols = populateHiddenCols(headCells);

function getRowCells(row, labelId, isItemSelected, handleClick) {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.country_id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("country") ? (<TableCell align="left">{row.country}</TableCell>) : ''}
        </TableRow>
    )
}

export default function BlockedCountriesTab(props) {
    const
        {
            addButtonEnabled,
            addButtonClickListener,
            deleteButtonEnabled,
            deleteButtonClickListener,
            onSelectRow,
            selectRowId,
            deleteButtonToggle,
            setBlockedCountrySearchSwitch,
            order,
            orderSetter,
            orderBy,
            orderBySetter,
            selected,
            selectedSetter,
            offset,
            offsetSetter,
            page,
            pageSetter,
            rows,
            total,
            fetchData,
            rowsPerPage,
            refreshButtonClickHandler
        } = props;

    const { keycloak } = useKeycloak();

    const hasGeoBlockedCountryAdd = keycloak.hasRealmRole(LOGI_ROLE_GEO_BlOCKED_COUNTRY_ADD);
    const hasGeoBlockedCountryDelete = keycloak.hasRealmRole(LOGI_ROLE_GEO_BlOCKED_COUNTRY_DELETE);

    useEffect(() => {
        fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        console.log("Blocked order orderBy: ", order(), orderBy())
        const isAsc = orderBy() === property && order() === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        setBlockedCountrySearchSwitch(true);
    };

    const isSelected = (country) => {
        return selected.indexOf(country) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleChangePage = (event, newPage) => {
        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        selectedSetter([]);
        pageSetter(newPage);
        deleteButtonToggle(false);
        setBlockedCountrySearchSwitch(true)
    };

    const handleRowClick = (event, row) => {
        if (selectRowId === row.country_id) {
            onSelectRow(null);
            deleteButtonToggle(false);
            selectedSetter([])
        } else {
            let newSelected = [row.country_id];
            onSelectRow(row);
            deleteButtonToggle(true);
            selectedSetter(newSelected);
        }
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    const handleDeleteButtonClicked = (event) => {
        deleteButtonClickListener(fetchData);
        deleteButtonToggle(false);
    };

    const handleAddButtonClicked = (event) => {
        addButtonClickListener();
    };

    return (
        <div id="geoip-blocked-countries-table-top-level-div">
            <div id="geoip-blocked-countries-table-button-bar-div">
                <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Blocked Country Refresh</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2} direction="row">
                            <Button id="geoip-blocked-countries-refresh-button"
                                variant="contained"
                                onClick={refreshButtonClickHandler}
                                disabled={!addButtonEnabled}
                            // width="1"
                            >Refresh</Button>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div id="geoip-blocked-countries-table-div">
                <Stack spacing={2} direction="row">
                    <Box sx={{ width: '100%' }} id="geoip-blocked-countries-table-box">
                        <TableContainer>
                            <Table
                                sx={{ width: '100%' }}
                                aria-labelledby="tableTitle"
                                size={'small'}
                                id="geoip-blocked-countries-table"
                            >
                                <SingleSelectTableHead
                                    numSelected={selected.length}
                                    order={order()}
                                    orderBy={orderBy()}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                    fetchMethod={fetchData}
                                    id="geoip-blocked-countries-table-head"
                                />
                                <TableBody id="geoip-blocked-countries-table-body">
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                    {rows.map((row, index) => {
                                        const isItemSelected = isSelected(row.country_id);
                                        const labelId = `geoip-blocked-countries-table-checkbox-${index}`;

                                        return (
                                            getRowCells(row, labelId, isItemSelected, handleRowClick)
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                // height: 33 * emptyRows,
                                                height: 33,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            labelDisplayedRows={defaultLabelDisplayedRows}
                        />
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </Box>
                    <div class="geoip-blocked-countries-table-buttons-div">
                        <Stack spacing={2} direction="column">
                            {hasGeoBlockedCountryAdd ? (<Button id="geoip-blocked-countries-table-add-button"
                                variant="contained"
                                onClick={handleAddButtonClicked}
                                disabled={!addButtonEnabled}>Add</Button>) : (<p />)}
                            {hasGeoBlockedCountryDelete ? (<Button id="geoip-blocked-countries-table-delete-button"
                                variant="contained"
                                onClick={handleDeleteButtonClicked}
                                disabled={!deleteButtonEnabled}>Delete</Button>) : (<p />)}

                        </Stack>
                    </div>
                </Stack>
            </div>
        </div>
    );
}
