/**
 * Validate that the input is suitable to be used as a job name.  This only checks that the name
 * doesn't have any invalid characters, etc, not that the name isn't already in use
 * by another job.
 * 
 * @param {*} input 
 */
export const PATTERN__VALID_JOB_NAME = "^[a-zA-Z0-9\\ \\-\\_\\.]{3,255}$";
export const PATTERN__VALID_JOB_EXPIRE_SECONDS = "^[0-9]{1,5}$";
/**
 * Return a list of possible task names that can be picked in a job so the user
 * doesn't have to type them.
 * 
 * NOTE:
 * If a new task is added on the backend, it must be added manually here for now or it won't
 * be visible in the list.
 */
export function getPossibleTasknames() {
    let ret = [
        "celery.backend_cleanup",
        "logi.asn_filtering.tasks.download_asn_ripe_data",
        "logi.asn_filtering.tasks.download_parse",
        "logi.blacklotuslabs.tasks.retrieve_bll_database",
        "logi.country_report_manager.tasks.generate_report_and_email",
        "logi.maxmind.tasks.download_parse",
        "logi.maxmind.tasks.download_parse_anonymous",
        "logi.scan_alert.tasks.delete_oldscanmessages",
        "logi.scan_alert.tasks.get_url",
        "logi.scan_alert.tasks.handle_scanner_message",
        "logi.tasks.chain_generate_ruleset",
        "logi.tasks.delete_oldruleset",
        "logi.tasks.delete_oldsessions",
        "logi.tasks.generate_ruleset"
    ];

    return ret;
}

/**
 * Check if the supplied input would be valid as a job name.
 * 
 * @param {*} input 
 * @returns true of the input doesn't contain any invalid characters, false otherwise
 */
export function isValidJobNameValue(input) {
    let ret = true;

    if (input === null) {
        // null input is not at all valid
        ret = false;
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret = false;
        return ret;
    }

    let pMatch = trimmedInput.match(PATTERN__VALID_JOB_NAME);
    if (pMatch === null) {
        ret = false;
    }

    return ret;
}

/**
 * Check if the name the user wants as the job name is already in use.  if it is, they'll have to choose another name.
 * 
 * @param {*} baseUrl Base url to fetch jobs with
 * @param {*} keycloakToken Our keycloak token to authenticate with
 * @param {*} id If supplied, it means we are editing an existing job and if a result comes for this id we'll allow it since it's updating the name on the same job
 * @param {*} name The job name we are checking is in use or not
 * @param {*} resultCallback A function that we'll call with the final result of true or false
 * 
 * @returns true if the name is already in use by an existing job, false otherwise
 */
export function jobNameIsInUse(baseUrl, keycloakToken, id, name, fieldIsValidCallback,
    fieldErrorMessageCallback) {

    // build the url
    let fetchUrl = baseUrl + "?name=" + name;

    fetch(fetchUrl, {
        method: 'GET',
        headers: {
            'access-token': keycloakToken
        },
    })
        .then((response) => response.json())
        .then((respData) => {
            let existingId = null
            let inUse = false;

            if (respData.results.length > 0) {
                // should be only one result
                let entry = respData.results[0];
                existingId = entry['id'];
            }
            if (id !== null) {
                // we are editing an existing job, so if existingId != null but equals
                // the id of the job we are editing, it's no issue
                if (existingId !== null) {
                    if (existingId !== id) {
                        // another job has this name so we must force the user to pick something else
                        inUse = true;
                    }
                    else {
                        // since this name exists on the job we are editing, we'll not say the name is already in use
                        // by another job
                    }
                }
                else {
                    // there is no job with this name, all good
                }
            }
            else {
                // since id is null here, we are likely adding a new job that doesn't have an id yet
                // we we are simply interested if any job exists with this name already
                if (existingId !== null) {
                    // an existing job has this name already
                    inUse = true;
                }
            }

            if (inUse) {
                fieldIsValidCallback(false);
                fieldErrorMessageCallback("This name is in use by another job, please pick another name");
            }
            else {
                fieldIsValidCallback(true);
                fieldErrorMessageCallback("");
            }
        });
}

/**
 * Check if the supplied input would be valid as the expire seconds field
 * of a job.
 * 
 * @param {*} input 
 * @returns 
 */
export function isValidJobExpireSecondsValue(input) {
    let ret = true;
    let msg = "";

    if (input === null) {
        // null input is not at all valid
        ret = false;
        msg = "Null input is not allowed";
        return { ret: ret, msg: msg };
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret = false;
        msg = "Blank input is not allowed";
        return { ret: ret, msg: msg };
    }

    let pMatch = trimmedInput.match(PATTERN__VALID_JOB_EXPIRE_SECONDS);
    if (pMatch === null) {
        ret = false;
        msg = "Input must be a number";
        return { ret: ret, msg: msg };
    }

    const MIN = 0;
    const MAX = 99999;

    // check that the number false within MIN-MAX
    let inputAsNum = parseInt(trimmedInput);
    if (inputAsNum < MIN || inputAsNum > MAX) {
        ret = false;
        msg = "The supplied number falls outside the range  " + MIN + " - " + MAX;
        return { ret: ret, msg: msg };
    }

    return { ret: ret, msg: msg };
}
