import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LOGI_ROLE_ANON_SETTINGS_EDIT, LOGI_ROLE_ANON_SETTINGS_READ } from '../../common/LogiRoles';
import {
    populateHiddenCols
}
    from '../../common/table/TableHelper';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import SingleSelectTableHead from '../../common/table/SingleSelectTableHead';


const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Id',
        hide: true,
    },
    {
        id: 'is_anonymous',
        numeric: false,
        disablePadding: true,
        label: 'Is Anonymous',
        hide: false,
    },
    {
        id: 'is_anonymous_vpn',
        numeric: true,
        disablePadding: true,
        label: 'Is Anonymous VPN',
        hide: false,
    },
    {
        id: 'is_hosting_provider',
        numeric: true,
        disablePadding: true,
        label: 'Is Hosting Provider',
        hide: false,
    },
    {
        id: 'is_public_proxy',
        numeric: true,
        disablePadding: true,
        label: 'Is Public Proxy',
        hide: false,
    },
    {
        id: 'is_tor_exit_node',
        numeric: true,
        disablePadding: true,
        label: 'Is Tor Exit Node',
        hide: false,
    },
    {
        id: 'is_residential_proxy',
        numeric: true,
        disablePadding: true,
        label: 'Is Residential Proxy',
        hide: false,
    },
];

const hiddenCols = populateHiddenCols(headCells);

const getRowCells = (headCells, row, labelId, isItemSelected, handleClick) => {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>

            {!hiddenCols.has("id") ? (<TableCell align="left">{row.id}</TableCell>) : ''}
            {!hiddenCols.has("is_anonymous") ? (<TableCell align="left">{row.is_anonymous ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("is_anonymous_vpn") ? (<TableCell align="left">{row.is_anonymous_vpn ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("is_hosting_provider") ? (<TableCell align="left">{row.is_hosting_provider ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("is_public_proxy") ? (<TableCell align="left">{row.is_public_proxy ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("is_tor_exit_node") ? (<TableCell align="left">{row.is_tor_exit_node ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("is_residential_proxy") ? (<TableCell align="left">{row.is_residential_proxy ? "✔" : "✘"}</TableCell>) : ''}
        </TableRow>
    )
};

export default function AnonymousSettingsTab(props) {
    const {
        selectedSettingsIdSetter,
        editButtonEnabled,
        editButtonClickListener,
        rows,
        selected,
        selectedSetter,
        fetchData,
        refreshButtonClickHandler,
    } = props;

    const { keycloak } = useKeycloak();

    const hasAnonSettingsRead = keycloak.hasRealmRole(LOGI_ROLE_ANON_SETTINGS_READ);
    const hasAnonSettingsEdit = keycloak.hasRealmRole(LOGI_ROLE_ANON_SETTINGS_EDIT);

    useEffect(() => {
        fetchData();
    }, []);

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    const handleRowClick = (event, id) => {

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        // there will only ever be one row in this table
        if (newSelected.length === 1) {
            selectedSettingsIdSetter(newSelected[0]);
        }
        else {
            selectedSettingsIdSetter(0);
        }
        selectedSetter(newSelected);
    };

    const handleEditButtonButtonClick = (event) => {
        editButtonClickListener();
    };

    if (hasAnonSettingsRead) {
        return (
            <div id="anon-settings-table-top-level-div">
                <div id="anon-settings-table-button-bar-div">
                    <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Anonymous Settings Refresh</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2} direction="row">
                                <Button
                                    id="anon-settings-table-refresh-button"
                                    variant="contained"
                                    onClick={refreshButtonClickHandler}>Refresh</Button>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div id="anon-settings-table-div">
                    <Stack spacing={2} direction="row">
                        <Box sx={{ width: '100%' }} id="anon_settings_table_box">
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    id="anon-settings-table"
                                >
                                    <SingleSelectTableHead
                                        headCells={headCells}
                                        id="anon-settings-table-head"
                                    />
                                    <TableBody id="anon-settings-table-body">
                                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                        {rows.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `anon-settings-table-checkbox-${index}`;

                                            return (
                                                getRowCells(headCells, row, labelId, isItemSelected, handleRowClick)
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div class="anon-settings-table-footer-div" />
                        </Box>
                        <div class="anon-settings-table-buttons-div">
                            {hasAnonSettingsEdit ? (<Stack spacing={2} direction="column">
                                <Button id="anon-settings-table-edit-button"
                                    variant="contained"
                                    onClick={handleEditButtonButtonClick}
                                    disabled={!editButtonEnabled}
                                >Edit</Button>
                            </Stack>) : (<p />)}
                        </div>
                    </Stack>
                </div>
            </div>
        );
    }
    else {
        // no permission to read the settings at all
        return (<p />)
    }
}
