export const PATTERN__VALID_REPORT_RECIPIENT_NAME = "^(?![0-9\\s])[^\n\r\t\\<\\>\\/_\\(\\)\\{\\}\\\\]{4,128}$(?<!\\s)$";
export const PATTERN__VALID_EMAIL_ADDRESS = "^[a-z_A-Z\\.\\-0-9]{2,128}@[a-z_A-Z\\.\\-0-9]{3,128}$";

/**
 * Check if the supplied input only contains characters that could be used in a name.
 * Given the wide variety of names and possible language characters this is only validating
 *  that there are no off the wall chars like like <, >, etc and that the input is a minimum length.
 * 
 * @param {string} input 
 * @returns true of the input doesn't contain any invalid characters, false otherwise
 */
export function isValidReportRecipientNameValue(input) {
    let ret = { res: true, msg: "" };

    if (input === null) {
        // null input is not at all valid
        ret.res = false;
        ret.msg = "Blank input not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.res = false;
        ret.msg = "Blank input not allowed";
        return ret;
    }

    let pMatch = trimmedInput.match(PATTERN__VALID_REPORT_RECIPIENT_NAME);
    if (pMatch === null) {
        ret.res = false;
        ret.msg = "Input contains invalid/illegal characters.";
    }

    return ret;
}

/**
 * Check if the supplied input looks like a valid email address
 * 
 * @param {string} input 
 * @returns true of the input looks like a valid email address, false otherwise
 */
export function isValidReportRecipientEmailValue(input) {
    let ret = { res: true, msg: "" };

    if (input === null) {
        // null input is not at all valid
        ret.res = false;
        ret.msg = "Blank input not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.res = false;
        ret.msg = "Blank input not allowed";
        return ret;
    }

    let pMatch = trimmedInput.match(PATTERN__VALID_EMAIL_ADDRESS);
    if (pMatch === null) {
        ret.res = false;
        ret.msg = "Input contains invalid/illegal characters.";
    }

    return ret;
}

/**
 * Check if the email the user has type is already in use by another report recipient.  if it is, they'll have to choose another email.
 * 
 * @param {string} baseUrl Base url to fetch report recipients with
 * @param {string} keycloakToken Our keycloak token to authenticate with
 * @param {*} id If supplied, it means we are editing an existing report recipient and if a result comes for this id we'll allow it since it's updating the email on the same report recipient
 * @param {string} email The email address we are checking is in use or not
 * @param {function} fieldIsValidCallback A function that we'll call with the final result of true or false
 * @param {function} fieldErrorMessageCallback A function used to set the error message
 * @returns true if the email is already in use by an existing report recipient, false otherwise
 */
export function reportRecipientEmailAddressIsInUse(baseUrl, keycloakToken, id, email, fieldIsValidCallback,
    fieldErrorMessageCallback) {

    // build the url
    let fetchUrl = baseUrl + "?email=" + email;

    fetch(fetchUrl, {
        method: 'GET',
        headers: {
            'access-token': keycloakToken
        },
    })
        .then((response) => response.json())
        .then((respData) => {
            let existingId = null
            let inUse = false;

            if (respData.results.length > 0) {
                // should be only one result
                let entry = respData.results[0];
                existingId = entry['id'];
            }
            if (id !== null) {
                // we are editing an existing report recipient, so if existingId != null but equals
                // the id of the report recipient we are editing, it's no issue
                if (existingId !== null) {
                    if (existingId !== id) {
                        // another report recipient has this email so we must force the user to pick something else
                        inUse = true;
                    }
                    else {
                        // since this email exists on the report recipient we are editing, we'll not say the email is already in use
                        // by another report recipient
                    }
                }
                else {
                    // there is no report recipient with this email, all good
                }
            }
            else {
                // since id is null here, we are likely adding a new report recipient that doesn't have an id yet
                // we we are simply interested if any report recipient exists with this email address already
                if (existingId !== null) {
                    // an existing report recipient has this email already
                    inUse = true;
                }
            }

            if (inUse) {
                fieldIsValidCallback(false);
                fieldErrorMessageCallback("This email is in use by another report recipient, please pick another email address");
            }
            else {
                fieldIsValidCallback(true);
                fieldErrorMessageCallback("");
            }
        });
}
