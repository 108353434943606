import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols
}
    from '../../common/table/TableHelper';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import {
    LOGI_ROLE_ANON_READ,
    LOGI_ROLE_MAXMIND_FETCH
} from '../../common/LogiRoles';
import SingleSelectTableHead from '../../common/table/SingleSelectTableHead';

const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Id',
        hide: true,
    },
    {
        id: 'subnet',
        numeric: false,
        disablePadding: true,
        label: 'Subnet',
        hide: false,
    },
    {
        id: 'is_anonymous',
        numeric: false,
        disablePadding: true,
        label: 'Is Anonymous',
        hide: true,
    },
    {
        id: 'is_anonymous_vpn',
        numeric: true,
        disablePadding: true,
        label: 'Is Anonymous VPN',
        hide: false,
    },
    {
        id: 'is_hosting_provider',
        numeric: true,
        disablePadding: true,
        label: 'Is Hosting Provider',
        hide: false,
    },
    {
        id: 'is_public_proxy',
        numeric: true,
        disablePadding: true,
        label: 'Is Public Proxy',
        hide: false,
    },
    {
        id: 'is_tor_exit_node',
        numeric: true,
        disablePadding: true,
        label: 'Is Tor Exit Node',
        hide: false,
    },
    {
        id: 'is_residential_proxy',
        numeric: true,
        disablePadding: true,
        label: 'Is Residential Proxy',
        hide: false,
    },
];

const hiddenCols = populateHiddenCols(headCells);


const getRowCells = (headCells, row, labelId, isItemSelected, handleClick) => {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
            </TableCell>
            {!hiddenCols.has("id") ? (<TableCell align="left">{row.id}</TableCell>) : ''}
            {!hiddenCols.has("subnet") ? (<TableCell align="left">{row.subnet}</TableCell>) : ''}
            {!hiddenCols.has("is_anonymous") ? (<TableCell align="left">{row.is_anonymous ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("is_anonymous_vpn") ? (<TableCell align="left">{row.is_anonymous_vpn ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("is_hosting_provider") ? (<TableCell align="left">{row.is_hosting_provider ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("is_public_proxy") ? (<TableCell align="left">{row.is_public_proxy ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("is_tor_exit_node") ? (<TableCell align="left">{row.is_tor_exit_node ? "✔" : "✘"}</TableCell>) : ''}
            {!hiddenCols.has("is_residential_proxy") ? (<TableCell align="left">{row.is_residential_proxy ? "✔" : "✘"}</TableCell>) : ''}
        </TableRow>
    )
};

function FilterWidget(props) {
    const {
        searchTerm,
        onSearchClick,
        onResetClick,
        onSearchTermChange,
        dbpull,
        subnetsSearchFlagIsAnonymousVPN,
        searchFlagIsAnonymousVPNSetter,
        defaultIsAnonymousVPNOption,
        subnetsSearchFlagIsHostingProvider,
        searchFlagIsHostingProviderSetter,
        defaultIsHostingProviderOption,
        subnetsSearchFlagIsPublicProxy,
        searchFlagIsPublicProxySetter,
        defaultIsPublicProxyOption,
        searchIsTorExitNode,
        searchIsTorExitNodeSetter,
        defaultIsTorExitNodeOption,
        searchIsResidentialProxy,
        searchIsResidentialProxySetter,
        defaultIsResidentialProxyOption,
        onSearchAnonVersionChange,
        defaultAnonSearchOption,
        searchAnonVersion,
        anonSearchOptions,
    } = props;

    const handleIsAnonymousVPNValueChange = (event) => {
        searchFlagIsAnonymousVPNSetter(event.target.value);
    };

    const handleIsHostingProviderValueChange = (event) => {
        searchFlagIsHostingProviderSetter(event.target.value);
    };

    const handleIsPublicProxyValueChange = (event) => {
        searchFlagIsPublicProxySetter(event.target.value);
    };

    const handleIsTorExitNodeValueChange = (event) => {
        searchIsTorExitNodeSetter(event.target.value);
    };

    const handleIsResidentialProxyValueChange = (event) => {
        searchIsResidentialProxySetter(event.target.value);
    };

    return (
        <form onSubmit={onSearchClick}>
            <div id="anon-filter-widget-div" class="anon-filter-widget-div">
                <div class="nftrules-filter-widget-body-div">
                    <Stack spacing={2} direction="column">
                        <Stack id="anon-filter-widget-control-stack" spacing={2} direction="row">
                            <TextField id="anon-filter-widget-search-input"
                                label="Search"
                                variant="outlined"
                                size="medium"
                                value={searchTerm}
                                onChange={onSearchTermChange}
                                sx={{ m: 1, minWidth: 100, maxWidth: 410 }}
                                fullWidth
                            />
                            <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 175 }}>
                                <InputLabel id="anon-filter-widget-iap-location-select-label">IP Filter</InputLabel>
                                <Select
                                    labelId="anon-exception-filter-widget-select-label"
                                    id="anon-exception-table-edit-modal-dialog-iap-location-select"
                                    label="IP Filter"
                                    onChange={onSearchAnonVersionChange}
                                    defaultValue={defaultAnonSearchOption}
                                    value={searchAnonVersion}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultAnonSearchOption === searchAnonVersion ? true : false}
                                        value={defaultAnonSearchOption}>{defaultAnonSearchOption}</MenuItem>
                                    {anonSearchOptions.map((ipVersion) => {
                                        let isSelected = ipVersion === searchAnonVersion;
                                        return (
                                            <MenuItem selected={isSelected} value={ipVersion}>{ipVersion}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack
                            spacing={2}
                            direction="row"
                            alignItems="center"
                        >
                            <FormControl
                                id="anon-filter-widget-is-anonymous-vpn-form-control"
                                sx={{ m: 1, minWidth: 150, maxWidth: 150 }}>
                                <InputLabel id="anon-filter-widget-is-anonymous-vpn-select-label">Is Anonymous VPN</InputLabel>
                                <Select
                                    labelId="anon-filter-widget-is-anonymous-vpn-select-label"
                                    id="anon-filter-widget-is-anonymous-vpn-select"
                                    label="Is Anonymous VPN"
                                    onChange={handleIsAnonymousVPNValueChange}
                                    defaultValue={defaultIsAnonymousVPNOption}
                                    value={subnetsSearchFlagIsAnonymousVPN()}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultIsAnonymousVPNOption === subnetsSearchFlagIsAnonymousVPN() ? true : false}
                                        value={defaultIsAnonymousVPNOption}>{defaultIsAnonymousVPNOption}</MenuItem>
                                    <MenuItem
                                        selected={"true" === subnetsSearchFlagIsAnonymousVPN() ? true : false}
                                        value={"true"}>{"true"}</MenuItem>
                                    <MenuItem
                                        selected={"false" === subnetsSearchFlagIsAnonymousVPN() ? true : false}
                                        value={"false"}>{"false"}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl
                                id="anon-filter-widget-is-hosting-provider-form-control"
                                sx={{ m: 1, minWidth: 150, maxWidth: 150 }}>
                                <InputLabel id="anon-filter-widget-is-hosting-provider-select-label">Is Hosting Provider</InputLabel>
                                <Select
                                    labelId="anon-filter-widget-is-hosting-provider-select-label"
                                    id="anon-filter-widget-is-hosting-provider-select"
                                    label="Is Hosting Provider"
                                    onChange={handleIsHostingProviderValueChange}
                                    defaultValue={defaultIsHostingProviderOption}
                                    value={subnetsSearchFlagIsHostingProvider()}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultIsHostingProviderOption === subnetsSearchFlagIsHostingProvider() ? true : false}
                                        value={defaultIsHostingProviderOption}>{defaultIsHostingProviderOption}</MenuItem>
                                    <MenuItem
                                        selected={"true" === subnetsSearchFlagIsHostingProvider() ? true : false}
                                        value={"true"}>{"true"}</MenuItem>
                                    <MenuItem
                                        selected={"false" === subnetsSearchFlagIsHostingProvider() ? true : false}
                                        value={"false"}>{"false"}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl
                                id="anon-filter-widget-is-public-proxy-form-control"
                                sx={{ m: 1, minWidth: 150, maxWidth: 150 }}>
                                <InputLabel id="anon-filter-widget-is-public-proxy-select-label">Is Public Proxy</InputLabel>
                                <Select
                                    labelId="anon-filter-widget-is-public-proxy-select-label"
                                    id="anon-filter-widget-is-public-proxy-select"
                                    label="Is Public Proxy"
                                    onChange={handleIsPublicProxyValueChange}
                                    defaultValue={defaultIsPublicProxyOption}
                                    value={subnetsSearchFlagIsPublicProxy()}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultIsPublicProxyOption === subnetsSearchFlagIsPublicProxy() ? true : false}
                                        value={defaultIsPublicProxyOption}>{defaultIsPublicProxyOption}</MenuItem>
                                    <MenuItem
                                        selected={"true" === subnetsSearchFlagIsPublicProxy() ? true : false}
                                        value={"true"}>{"true"}</MenuItem>
                                    <MenuItem
                                        selected={"false" === subnetsSearchFlagIsPublicProxy() ? true : false}
                                        value={"false"}>{"false"}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl
                                id="anon-filter-widget-is-tor-exit-node-form-control"
                                sx={{ m: 1, minWidth: 150, maxWidth: 150, marginTop: ".5rem" }}>
                                <InputLabel id="anon-filter-widget-is-tor-exit-node-select-label">Is Tor Exit Node</InputLabel>
                                <Select
                                    labelId="anon-filter-widget-is-tor-exit-node-select-label"
                                    id="anon-filter-widget-is-tor-exit-node-select"
                                    label="Is Tor Exit Node"
                                    onChange={handleIsTorExitNodeValueChange}
                                    defaultValue={defaultIsTorExitNodeOption}
                                    value={searchIsTorExitNode()}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultIsTorExitNodeOption === searchIsTorExitNode() ? true : false}
                                        value={defaultIsTorExitNodeOption}>{defaultIsTorExitNodeOption}</MenuItem>
                                    <MenuItem
                                        selected={"true" === searchIsTorExitNode() ? true : false}
                                        value={"true"}>{"true"}</MenuItem>
                                    <MenuItem
                                        selected={"false" === searchIsTorExitNode() ? true : false}
                                        value={"false"}>{"false"}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl
                                id="anon-filter-widget-is-residential-proxy-form-control"
                                sx={{ m: 1, minWidth: 150, maxWidth: 150, marginTop: ".5rem" }}>
                                <InputLabel id="anon-filter-widget-is-residential-proxy-select-label">Is Residential Proxy</InputLabel>
                                <Select
                                    labelId="anon-filter-widget-is-residential-proxy-select-label"
                                    id="anon-filter-widget-is-residential-proxy-select"
                                    label="Is Residential Proxy"
                                    onChange={handleIsResidentialProxyValueChange}
                                    defaultValue={defaultIsResidentialProxyOption}
                                    value={searchIsResidentialProxy()}
                                    autoWidth
                                >
                                    <MenuItem
                                        selected={defaultIsResidentialProxyOption === searchIsResidentialProxy() ? true : false}
                                        value={defaultIsResidentialProxyOption}>{defaultIsResidentialProxyOption}</MenuItem>
                                    <MenuItem
                                        selected={"true" === searchIsResidentialProxy() ? true : false}
                                        value={"true"}>{"true"}</MenuItem>
                                    <MenuItem
                                        selected={"false" === searchIsResidentialProxy() ? true : false}
                                        value={"false"}>{"false"}</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack spacing={2} direction="row">
                            <Button id="anon-filter-widget-search-button" variant="contained" button type="submit">Search</Button>
                            <Button id="anon-filter-widget-reset-button" variant="contained" onClick={onResetClick}>Reset</Button>
                        </Stack>
                    </Stack>
                </div>
            </div >
        </form>
    );
};

export default function AnonymousSubnetsTab(props) {

    const {
        order,
        orderSetter,
        orderBy,
        orderBySetter,
        selected,
        selectedSetter,
        offset,
        offsetSetter,
        page,
        pageSetter,
        rows,
        total,
        totalSetter,
        searchTerm,
        searchTermSetter,
        fetchData,
        rowsPerPage,
        refreshButtonClickHandler,
        adhocPollButotnClickListener,
        dbpull,
        defaultIsTorExitNodeOption,
        defaultIsResidentialProxyOption,
        subnetsSearchFlagIsAnonymousVPN,
        searchFlagIsAnonymousVPNSetter,
        defaultIsAnonymousVPNOption,
        subnetsSearchFlagIsHostingProvider,
        searchFlagIsHostingProviderSetter,
        defaultIsHostingProviderOption,
        subnetsSearchFlagIsPublicProxy,
        searchFlagIsPublicProxySetter,
        defaultIsPublicProxyOption,
        searchIsTorExitNode,
        searchIsTorExitNodeSetter,
        searchIsResidentialProxy,
        searchIsResidentialProxySetter,
        setAnonSubnetSwitch,
        searchAnonVersion,
        anonSearchOptions,
        defaultAnonSearchOption,
        searchAnonVersionSetter,
    } = props;

    const { keycloak } = useKeycloak();

    const hasAnonymousRead = keycloak.hasRealmRole(LOGI_ROLE_ANON_READ);
    const hasAnonFetch = keycloak.hasRealmRole(LOGI_ROLE_MAXMIND_FETCH);

    useEffect(() => {
        handleResetButtonClick();
        // fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy() === property && order() === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        setAnonSubnetSwitch(true);
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleSearchTermChange = (event) => {
        searchTermSetter(event.target.value);
    };

    const handleSearchButtonClick = (event) => {
        event.preventDefault();
        pageSetter(0);
        fetchData();
    };

    function handleResetButtonClick(event) {
        offsetSetter(0);
        pageSetter(0);
        totalSetter(0);
        orderSetter('asc');
        orderBySetter('subnet');
        searchTermSetter('');
        selectedSetter([]);
        searchFlagIsAnonymousVPNSetter(defaultIsAnonymousVPNOption);
        searchFlagIsHostingProviderSetter(defaultIsHostingProviderOption);
        searchFlagIsPublicProxySetter(defaultIsPublicProxyOption);
        searchIsTorExitNodeSetter(defaultIsTorExitNodeOption);
        searchIsResidentialProxySetter(defaultIsResidentialProxyOption);
        searchAnonVersionSetter('Both')
        fetchData();
    }

    const handleChangePage = (event, newPage) => {
        // console.log('handleChangePage: oldPage=' + page + ' newPage=' + newPage);

        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        // console.log('handleChangePage: oldOffset' + offset + ' newOffset=' + newOffset);
        offsetSetter(newOffset);
        selectedSetter([]);
        pageSetter(newPage);
        setAnonSubnetSwitch(true);
    };

    const handleRowClick = (event, id) => {
        if (selected[0] === id) {
            selectedSetter([])
        } else {
            let newSelected = [id];
            selectedSetter(newSelected);
        }
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    const handleSearchAnonVersionChange = (event) => {
        // console.log("Version change: ", event.target.value);
        searchAnonVersionSetter(event.target.value);
    };

    if (hasAnonymousRead) {
        return (
            <div id="anon-subnets-table-top-level-div">
                <div id="anon-subnets-table-button-bar-div">
                    <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Anonymous Subnets Filter / Search</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2} direction="column">
                                <Stack spacing={2} direction="row">
                                    <Button
                                        id="anon-subnets-table-refresh-button"
                                        variant="contained"
                                        onClick={refreshButtonClickHandler}>Refresh</Button>
                                    {hasAnonFetch === true ? (<Button id="anon-adhoc-refresh-button"
                                        variant="contained"
                                        onClick={adhocPollButotnClickListener}>Adhoc Anonymous Pull</Button>) : ('')}
                                    <span style={{ 'padding': '10px', 'margin': '10px' }}>Last Pull info: </span>
                                    <Alert severity="info">{dbpull}</Alert>
                                </Stack>
                                <FilterWidget
                                    searchTerm={searchTerm}
                                    onSearchClick={handleSearchButtonClick}
                                    onResetClick={handleResetButtonClick}
                                    onSearchTermChange={handleSearchTermChange}
                                    dbpull={dbpull}
                                    subnetsSearchFlagIsAnonymousVPN={subnetsSearchFlagIsAnonymousVPN}
                                    searchFlagIsAnonymousVPNSetter={searchFlagIsAnonymousVPNSetter}
                                    defaultIsAnonymousVPNOption={defaultIsAnonymousVPNOption}
                                    subnetsSearchFlagIsHostingProvider={subnetsSearchFlagIsHostingProvider}
                                    searchFlagIsHostingProviderSetter={searchFlagIsHostingProviderSetter}
                                    defaultIsHostingProviderOption={defaultIsHostingProviderOption}
                                    subnetsSearchFlagIsPublicProxy={subnetsSearchFlagIsPublicProxy}
                                    searchFlagIsPublicProxySetter={searchFlagIsPublicProxySetter}
                                    defaultIsPublicProxyOption={defaultIsPublicProxyOption}
                                    searchIsTorExitNode={searchIsTorExitNode}
                                    searchIsTorExitNodeSetter={searchIsTorExitNodeSetter}
                                    defaultIsTorExitNodeOption={defaultIsTorExitNodeOption}
                                    searchIsResidentialProxy={searchIsResidentialProxy}
                                    searchIsResidentialProxySetter={searchIsResidentialProxySetter}
                                    defaultIsResidentialProxyOption={defaultIsResidentialProxyOption}
                                    onSearchAnonVersionChange={handleSearchAnonVersionChange}
                                    defaultAnonSearchOption={defaultAnonSearchOption}
                                    searchAnonVersion={searchAnonVersion}
                                    anonSearchOptions={anonSearchOptions}

                                />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div id="anon-subnets-table-div">
                    <Box sx={{ width: '100%' }} id="anon_rules_table_box">
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={'small'}
                                id="anon-subnets-table"
                            >
                                <SingleSelectTableHead
                                    numSelected={selected.length}
                                    order={order()}
                                    orderBy={orderBy()}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                    fetchMethod={fetchData}
                                    id="anon-subnets-table-head"
                                />
                                <TableBody id="anon-subnets-table-body">
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                    {rows.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `anon-subnets-table-checkbox-${index}`;

                                        return (
                                            getRowCells(headCells, row, labelId, isItemSelected, handleRowClick)
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                // height: 33 * emptyRows,
                                                height: 33,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            labelDisplayedRows={defaultLabelDisplayedRows}
                        />
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </Box>
                </div>
            </div>
        );
    }
    else {
        return (<div />);
    }
}
