import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

export default function BLLIPAddressDialog(props) {
    let { open, onClose,
        selectedBLLIPAddress,
        selectedBLLIPAddressCollectionName,
        selectedBLLIPAddressConfidence
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const readOnlyTextFieldHandler = (event) => {
        // don't do anything with typed input, making this
        // field read-only
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>BLL IP Address</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        label="IP Address"
                        variant="outlined"
                        size="small"
                        // width="480px"
                        value={selectedBLLIPAddress}
                        onChange={readOnlyTextFieldHandler}
                        id="bll-ip-addresses-tab-modal-dialog-ip-address-field"
                        style={{marginTop: '15px'}}
                    />
                    <TextField
                        label="Collection Name"
                        variant="outlined"
                        size="small"
                        // width="480px"
                        value={selectedBLLIPAddressCollectionName}
                        onChange={readOnlyTextFieldHandler}
                        id="bll-ip-addresses-tab-modal-dialog-collection-name-field"
                    />
                    <TextField
                        label="Confidence"
                        variant="outlined"
                        size="small"
                        // width="480px"
                        value={selectedBLLIPAddressConfidence}
                        onChange={readOnlyTextFieldHandler}
                        id="bll-ip-addresses-tab-modal-dialog-confidence-field"
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="bll-ip-addresses-tab-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
