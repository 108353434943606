import Box from '@mui/material/Box';
import Navs from '../components/topnav';
import Sidenavs from '../components/sidenav';
import { LOGI_ROLE_ASN_READ } from '../common/LogiRoles';
import { useKeycloak } from '@react-keycloak/web';
import AsnComponent from '../components/Asn/AsnComponent';

function AsnPage() {
	const { keycloak } = useKeycloak();
	let hasAsnRead = keycloak.hasRealmRole(LOGI_ROLE_ASN_READ);
	if (keycloak.idTokenParsed) {
		return (
			<div className='App'>
				<Navs />
				<table className='mptable'>
					<tr>
						<td className='sidenavtd'>
							<Box sx={{ position: 'sticky', top: 0 }}>
								<Sidenavs firewallSettingsOpen={true} adminOpen={false}/>
							</Box>
						</td>
						<td class="asn-page-route-td">
							{hasAsnRead ? (<Box sx={{ height: `100%` }}>
								<div class="asn-page-div">
									<AsnComponent />
								</div>
							</Box>) : (<p>You do not have permission to view this page</p>)}
						</td>
					</tr>
				</table>
			</div>
		);
	} else {
		window.location.href = '/';
	}
};

export default AsnPage;