import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export default function CountryPlusDialog(props) {
    const { onClose, open, iso, iso3, iso_numeric, fips, name, capital, area,
        population, continent, tld, currency_code, currency_symbol,
        currency_name, phone, postal_code_format, postal_code_regex,
        languages, neighbours } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{name}</DialogTitle>
            <div class="modal-dialog-content-div">
                <p>Details for country {name}</p>
                <Stack spacing={2} direction="column">
                    <Stack spacing={2} direction="row">
                        <TextField
                            label="ISO"
                            variant="outlined"
                            size="medium"
                            value={iso}
                        />
                        <TextField
                            label="ISO3"
                            variant="outlined"
                            size="medium"
                            value={iso3}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <TextField
                            label="ISO Numeric"
                            variant="outlined"
                            size="medium"
                            value={iso_numeric}
                        />
                        <TextField
                            label="FIPS"
                            variant="outlined"
                            size="medium"
                            value={fips}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <TextField
                            label="Capital"
                            variant="outlined"
                            size="medium"
                            value={capital}
                        />
                        <TextField
                            label="Area"
                            variant="outlined"
                            size="medium"
                            value={isNaN(area) ? area : area.toLocaleString()}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <TextField
                            label="Population"
                            variant="outlined"
                            size="medium"
                            value={isNaN(population) ? population : population.toLocaleString()}
                        />
                        <TextField
                            label="Continent"
                            variant="outlined"
                            size="medium"
                            value={continent}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <TextField
                            label="Currency Code"
                            variant="outlined"
                            size="medium"
                            value={currency_code}
                        />
                        <TextField
                            label="Currency Symbol"
                            variant="outlined"
                            size="medium"
                            value={currency_symbol}
                        />
                        <TextField
                            label="Currency Name"
                            variant="outlined"
                            size="medium"
                            value={currency_name}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <TextField
                            label="Top-Level Domain"
                            variant="outlined"
                            size="medium"
                            value={tld}
                        />
                        <TextField
                            label="Phone"
                            variant="outlined"
                            size="medium"
                            value={phone}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <TextField
                            label="Postal Code Format"
                            variant="outlined"
                            size="medium"
                            value={postal_code_format}
                        />
                        <TextField
                            label="Postal Code Regex"
                            variant="outlined"
                            size="medium"
                            value={postal_code_regex}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <TextField
                            label="Languages"
                            variant="outlined"
                            size="medium"
                            value={languages}
                        />
                        <TextField
                            label="Neighbors"
                            variant="outlined"
                            size="medium"
                            value={neighbours}
                        />
                    </Stack>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="countriesplus-modal-dialog-close-button" variant="contained" onClick={handleCloseButtonClick}>Close</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}  