import React from 'react';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

export default function EditScanMessageDialog(props) {
  const {
    onClose,
    open,
    ip,
    iapLocation,
    received,
    receivedSetter,
    receivedValid,
    receivedErrMessage,
    updated,
    updatedSetter,
    updatedValid,
    updatedErrMessage,
    updateButtonEnabled,
    updateButtonEnabledSetter,
    updateButtonClickListener,
    validateButtonClickListener
  } = props;

  const handleClose = () => {
    onClose();
  };

  const handleCloseButtonClick = (event) => {
    onClose();
  };

  const handleReceivedChange = (val) => {
    updateButtonEnabledSetter();
    receivedSetter(val);
  };

  const handleUpdatedChange = (val) => {
    updateButtonEnabledSetter();
    updatedSetter(val);
  };

  const handleUpdateButtonClicked = (event) => {
    updateButtonClickListener();
  };

  const handlValidateButtonClicked = (event) => {
    validateButtonClickListener();
  };

  const readOnlyFieldHandler = (event) => {
    // don't do anything, keep field value unchanged
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Scan Message</DialogTitle>
      <div class="modal-dialog-content-div">
        <Stack spacing={2} direction="column">
          <TextField
            label="Host Address(Read-Only)"
            variant="outlined"
            size="medium"
            value={ip}
            onChange={readOnlyFieldHandler}
            id="scan-message-table-edit-modal-dialog-host-address-field"
            style={{marginTop: '15px'}}
          />
          <TextField
            label="IAP Location(Read-Only)"
            variant="outlined"
            size="medium"
            value={iapLocation}
            onChange={readOnlyFieldHandler}
            id="scan-message-table-edit-modal-dialog-iap-location-field"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              {...(receivedValid !== true && { error: undefined })}
              helperText={receivedErrMessage}
              slotProps={{
                textField: {
                required: true,
                id: 'scan-message-table-edit-modal-dialog-received-field',
                },
                }}
              // renderInput={(props) => <TextField
              //  id="scan-message-table-edit-modal-dialog-received-field"
              //  {...props} />}
              label="Received"
              value={received}
              onChange={handleReceivedChange}
              onChangeRaw={handleReceivedChange}
              ampm={false}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              {...(updatedValid !== true && { error: undefined })}
              helperText={updatedErrMessage}
              slotProps={{
                textField: {
                required: true,
                id: 'scan-message-table-edit-modal-dialog-updated-field',
                },
                }}
              // renderInput={(props) => <TextField
              //  id="scan-message-table-edit-modal-dialog-updated-field"
              //  {...props} />}
              label="Updated"
              value={updated}
              onChange={handleUpdatedChange}
              onChangeRaw={handleUpdatedChange}
              ampm={false}
            />
          </LocalizationProvider>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Button id="scan-message-table-edit-modal-dialog-close-button"
              variant="contained"
              onClick={handleCloseButtonClick}>Close</Button>
            <Button id="scan-message-table-edit-modal-dialog-validate-button"
              variant="contained"
              onClick={handlValidateButtonClicked}>Validate</Button>
            <Button id="scan-message-table-edit-modal-dialog-update-button"
              variant="contained"
              onClick={handleUpdateButtonClicked}
              disabled={!updateButtonEnabled}>Update</Button>
          </Stack>
        </Stack>
      </div>
    </Dialog>
  );
}
