export function getEnabledWeekdaysString(monday,
    tuesday, wednesday, thursday, friday,
    saturday, sunday) {

    let days = [];

    if (monday) {
        days.push('M');
    }
    if (tuesday) {
        days.push('Tu');
    }
    if (wednesday) {
        days.push('W');
    }
    if (thursday) {
        days.push('Th');
    }
    if (friday) {
        days.push('F');
    }
    if (saturday) {
        days.push('Sa');
    }
    if (sunday) {
        days.push('Su');
    }

    let ret = "";
    for (let x = 0; x < days.length; x++) {
        ret = ret + days[x];

        if (x < (days.length - 1)) {
            // add a comma
            ret = ret + ",";
        }
    }

    return ret;
}

export function getWeekdaysFromString(weekdaysString) {
    let ret = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    };

    let index = weekdaysString.indexOf('M');
    if (index > -1) {
        ret.monday = true;
    }

    index = weekdaysString.indexOf('Tu');
    if (index > -1) {
        ret.tuesday = true;
    }

    index = weekdaysString.indexOf('W');
    if (index > -1) {
        ret.wednesday = true;
    }

    index = weekdaysString.indexOf('Th');
    if (index > -1) {
        ret.thursday = true;
    }

    index = weekdaysString.indexOf('F');
    if (index > -1) {
        ret.friday = true;
    }

    index = weekdaysString.indexOf('Sa');
    if (index > -1) {
        ret.saturday = true;
    }

    index = weekdaysString.indexOf('Su');
    if (index > -1) {
        ret.sunday = true;
    }

    return ret;
}