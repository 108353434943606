import Box from '@mui/material/Box';
import Navs from '../components/topnav';
import Sidenavs from '../components/sidenav';
import { useKeycloak } from '@react-keycloak/web';
import MainComponent from '../components/mainpage';

function MainPage() {
	const { keycloak } = useKeycloak();
	if (keycloak.idTokenParsed) {
		return (
			<div className='App'>
				<Navs />
				<table className='mptable'>
					<tr>
						<td className='sidenavtd'>
							<Box sx={{ position: 'sticky', top: 0 }}>
								<Sidenavs firewallSettingsOpen={false} adminOpen={false}/>
							</Box>
						</td>
						<td class="asn-page-route-td">
							<Box sx={{ height: `100%` }}>
								<div class="asn-page-div">
									<MainComponent />
								</div>
							</Box>
						</td>
					</tr>
				</table>
			</div>
		);
	} else {
		window.location.href = '/';
	}
};

export default MainPage;