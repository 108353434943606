import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AddReportableCountryDialog from './modals/AddReportableCountryDialog';
import AddReportRecipientDialog from './modals/AddReportRecipientDialog';
import EditReportRecipientDialog from './modals/EditReportRecipientDialog';
import ReportRecipientsTab from './ReportRecipientsTab';
import ReportableCountriesTab from './ReportableCountriesTab';
import {
  isValidReportRecipientNameValue,
  isValidReportRecipientEmailValue,
  reportRecipientEmailAddressIsInUse
} from "../../../common/validation/report/ReportValidator";
import dayjs from 'dayjs';
import {
  LOGI_ROLE_REPORTABLE_COUNTRIES_READ,
  LOGI_ROLE_REPORT_RECIPIENTS_READ
} from '../../../common/LogiRoles';
import "./ReportsComponent.css";
import { fetchErrorMessage } from '../../mainpage';

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const rowsPerPage = 20;

const reportRecipientsUrl = '/middleware/api/report_recipient/';
const reportableCountryUrl = '/middleware/api/reportable_countries/';
const countriesyUrl = '/middleware/api/max_countries/';

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `reports-tab-${index}`,
    'aria-controls': `reports-tabpanel-${index}`,
  };
}

function createReportRecipientRowData(id, name, email, enabled) {
  return {
    id,
    name,
    email,
    enabled,
  };
}

function createReportableCountriesRowData(country_id, country) {
  return {
    country_id,
    country
  };
}

// needed so that reset button doesn't have to be clicked twice
let freshReportRecipientsSearchTerm = '';

export default function ReportsComponents() {
  const [tabIndex, setTabIndex] = useState(0);

  // snackbar state vars
  const [snackState,] = React.useState({
    snackbarVertical: 'bottom',
    snackbarHorizontal: 'center',
  });

  const { snackbarHorizontal, snackbarVertical } = snackState;

  const [reportRecipientsRefreshNotificationOpen, setReportRecipientsRefreshNotificationOpen] = useState(false);
  const reportRecipientsRefreshNotificationMessage = "Report Recipient Data Refreshed";

  const [reportableCountriesRefreshNotificationOpen, setReportableCountriesRefreshNotificationOpen] = useState(false);
  const reportableCountriesRefreshNotificationMessage = "Reportable Countries Data Refreshed";
  const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
  const handleFetchErrorClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFetchErrorMessageOpen(false);
  };

  const { keycloak } = useKeycloak();

  // report recipients tab state vars
  const [reportRecipientsOrder, setReportRecipientsOrder] = useState('asc');
  const [reportRecipientsOrderBy, setReportRecipientsOrderBy] = useState('email');
  const [reportRecipientsSelected, setReportRecipientsSelected] = useState([]);
  const [reportRecipientsOffset, setReportRecipientsOffset] = useState(0);
  const [reportRecipientsPage, setReportRecipientsPage] = useState(0);
  const [reportRecipientsRows, setReportRecipientsRows] = useState([]);
  const [reportRecipientsTotal, setReportRecipientsTotal] = useState(0);
  const [reportRecipientsSearchTerm, setReportRecipientsSearchTerm] = useState('');
  const [addReportRecipientButtonEnabled, setAddReportRecipientButtonEnabled] = useState(true);
  const [editReportRecipientButtonEnabled, setEditReportRecipientButtonEnabled] = useState(false);
  const [deleteReportRecipientButtonEnabled, setDeleteReportRecipientButtonEnabled] = useState(false);
  const [selectedReportRecipientId, setSelectedReportRecipientId] = useState(0);
  const [reportRecipientSwitch, setReportRecipientSwitch] = useState(false);

  // Report Recipients add modal related
  const [reportRecipientAddModalOpen, setReportRecipientAddModalOpen] = useState(false);
  const [reportRecipientAddModalName, setReportRecipientAddModalName] = useState('');
  const [reportRecipientAddModalNameValid, setReportRecipientAddModalNameValid] = useState(true);
  const [reportRecipientAddModalNameErrorMessage, setReportRecipientAddModalNameErrorMessage] = useState('');
  const [reportRecipientAddModalEmail, setReportRecipientAddModalEmail] = useState('');
  const [reportRecipientAddModalEmailValid, setReportRecipientAddModalEmailValid] = useState(true);
  const [reportRecipientAddModalEmailErrorMessage, setReportRecipientAddModalEmailErrorMessage] = useState('');
  const [reportRecipientAddModalEnabled, setReportRecipientAddModalEnabled] = useState(false);
  const [reportRecipientAddModalAddButtonEnabled, setReportRecipientAddModalAddButtonEnabled] = useState(false);
  const [reportRecipientAddModalValidateButtonEnabled,] = useState(true);

  // Report Recipients edit modal related
  const [reportRecipientEditModalOpen, setReportRecipientEditModalOpen] = useState(false);
  const [reportRecipientEditModalName, setReportRecipientEditModalName] = useState('');
  const [reportRecipientEditModalNameValid, setReportRecipientEditModalNameValid] = useState(true);
  const [reportRecipientEditModalNameErrorMessage, setReportRecipientEditModalNameErrorMessage] = useState('');
  const [reportRecipientEditModalEmail, setReportRecipientEditModalEmail] = useState('');
  const [reportRecipientEditModalEmailValid, setReportRecipientEditModalEmailValid] = useState(true);
  const [reportRecipientEditModalEmailErrorMessage, setReportRecipientEditModalEmailErrorMessage] = useState('');
  const [reportRecipientEditModalEnabled, setReportRecipientEditModalEnabled] = useState(false);
  const [reportRecipientEditModalUpdateButtonEnabled, setReportRecipientEditModalUpdateButtonEnabled] = useState(false);
  const [reportRecipientEditModalValidateButtonEnabled,] = useState(true);

  // Reportable Countries tab state vars
  const [reportableCountriesOrder, setReportableCountriesOrder] = useState('asc');
  const [reportableCountriesOrderBy, setReportableCountriesOrderBy] = useState('country');
  const [reportableCountriesSelected, setReportableCountriesSelected] = useState([]);
  const [reportableCountriesOffset, setReportableCountriesOffset] = useState(0);
  const [reportableCountriesPage, setReportableCountriesPage] = useState(0);
  const [reportableCountriesRows, setReportableCountriesRows] = useState([]);
  const [reportableCountriesTotal, setReportableCountriesTotal] = useState(0);
  const [reportableCountrySwitch, setReportableCountrySwitch] = useState(false);

  // Reportable Countries related
  const [addReportableCountryButtonEnabled,] = useState(true);
  const [deleteReportableCountryButtonEnabled, setDeleteReportableCountryButtonEnabled] = useState(false);
  const [reportableCountryModalOpen, setReportableCountryModalOpen] = useState(false);
  const [selectedReportableCountryId, setSelectedReportableCountryId] = useState('');
  const [reportableCountryModalCountryId, setReportableCountryModalCountryId] = useState('');
  const [reportableCountryModalAddButtonDisabled, setReportableCountryModalAddButtonDisabled] = useState(true);

  const [currentlyReportableCountries, setCurrentlyReportableCountries] = useState([]);
  const [reportableCountryOptions, setReportableCountryOptions] = useState([]);

  const hasReportRecipientsRead = keycloak.hasRealmRole(LOGI_ROLE_REPORT_RECIPIENTS_READ);
  const hasReportableCountriesRead = keycloak.hasRealmRole(LOGI_ROLE_REPORTABLE_COUNTRIES_READ);

  useEffect(() => {
    fetchReportRecipientsData();
  }, [reportRecipientSwitch])

  useEffect(() => {
    fetchReportableCountriesData();
  }, [reportableCountrySwitch])

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
    setEditReportRecipientButtonEnabled(false);
    setDeleteReportRecipientButtonEnabled(false);
  };

  const handleToggleEditReportRecipientButtonVisibility = (val) => {
    setEditReportRecipientButtonEnabled(val);
  };

  const handleToggleDeleteReportRecipientButtonVisibility = (val) => {
    setDeleteReportRecipientButtonEnabled(val);
  };

  // create a new report recipient that can then be edited.
  const addReportRecipient = async () => {
    let postData = {
      name: reportRecipientAddModalName,
      email: reportRecipientAddModalEmail,
      enabled: reportRecipientAddModalEnabled
    };

    await fetch(reportRecipientsUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchReportRecipientsData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const launchAddReportRecipientModal = () => {
    let newName = "New Report Recipient";
    let today = dayjs.utc(new Date().toLocaleString()).format('YYYYMMDDTHHmmss');
    let newEmail = "new.recipient." + today + "@changethis.com";

    setReportRecipientAddModalName(newName);
    setReportRecipientAddModalEmail(newEmail);
    setReportRecipientAddModalEnabled(false);

    setReportRecipientAddModalOpen(true);
  };

  const handleAddReportRecipientButtonClick = () => {
    launchAddReportRecipientModal();
  };

  const fetchReportRecipientModalData = async () => {
    await fetch(reportRecipientsUrl
      + selectedReportRecipientId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        // web service response
        // {
        //   "id": 1,
        //   "name": "Stephen Sill",
        //   "email": "stephen.sill@lumen.com",
        //   "enabled": true
        // }
        setReportRecipientEditModalName(respData['name']);
        setReportRecipientEditModalEmail(respData['email']);
        setReportRecipientEditModalEnabled(respData['enabled']);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleEditReportRecipientButtonClick = (event) => {
    // fetch the modal data
    fetchReportRecipientModalData();

    // display the modal
    setReportRecipientEditModalOpen(true);
  };

  const deleteReportRecipient = async (fetchFunctionCallback) => {
    await fetch(reportRecipientsUrl
      + selectedReportRecipientId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        setEditReportRecipientButtonEnabled(false);
        setDeleteReportRecipientButtonEnabled(false);
        fetchFunctionCallback();
      });
  };

  const handleDeleteReportRecipientButtonClick = (fetchFunctionCallback) => {
    deleteReportRecipient(fetchFunctionCallback);
  };

  const performReportRecipientAddModalValidation = () => {
    let shouldEnableUpdateButton = true;

    if (!reportRecipientAddModalNameValid) {
      shouldEnableUpdateButton = false;
    }

    if (!reportRecipientAddModalEmailValid) {
      shouldEnableUpdateButton = false;
    }

    setReportRecipientAddModalAddButtonEnabled(shouldEnableUpdateButton);
  };

  const performReportRecipientEditModalValidation = () => {
    let shouldEnableUpdateButton = true;

    if (!reportRecipientEditModalNameValid) {
      shouldEnableUpdateButton = false;
    }

    if (!reportRecipientEditModalEmailValid) {
      shouldEnableUpdateButton = false;
    }

    setReportRecipientEditModalUpdateButtonEnabled(shouldEnableUpdateButton);
  };

  const handleReportRecipientAddModalClose = (val) => {
    setReportRecipientAddModalOpen(val);
    setAddReportRecipientButtonEnabled(false);
    setDeleteReportRecipientButtonEnabled(false);
    setReportRecipientAddModalAddButtonEnabled(false);
  };

  const handleReportRecipientEditModalClose = (val) => {
    setReportRecipientEditModalOpen(val);
    setEditReportRecipientButtonEnabled(false);
    setDeleteReportRecipientButtonEnabled(false);
    setReportRecipientEditModalUpdateButtonEnabled(false);
  };

  const handleReportRecipientAddModalNameChanged = (val) => {
    setReportRecipientAddModalName(val);

    // force user to have to revalidate
    setReportRecipientAddModalAddButtonEnabled(false);

    let nameValidationResult = isValidReportRecipientNameValue(val);
    setReportRecipientAddModalNameValid(nameValidationResult.res);
    setReportRecipientAddModalNameErrorMessage(nameValidationResult.msg);
  };

  const handleReportRecipientEditModalNameChanged = (val) => {
    setReportRecipientEditModalName(val);

    // force user to have to revalidate
    setReportRecipientEditModalUpdateButtonEnabled(false);

    let nameValidationResult = isValidReportRecipientNameValue(val);
    setReportRecipientEditModalNameValid(nameValidationResult.res);
    setReportRecipientEditModalNameErrorMessage(nameValidationResult.msg);
  };

  const handleReportRecipientAddModalEmailChanged = (val) => {
    setReportRecipientAddModalEmail(val);

    // force user to have to revalidate
    setReportRecipientAddModalAddButtonEnabled(false);

    let emailValidationResult = isValidReportRecipientEmailValue(val);
    setReportRecipientAddModalEmailValid(emailValidationResult.res);
    setReportRecipientAddModalEmailErrorMessage(emailValidationResult.msg);

    if (emailValidationResult.res) {
      // the email address looks like an email address, now check if it's already in use
      reportRecipientEmailAddressIsInUse(reportRecipientsUrl, keycloak.token, selectedReportRecipientId,
        val, setReportRecipientAddModalEmailValid, setReportRecipientAddModalEmailErrorMessage);
    }
  };

  const handleReportRecipientEditModalEmailChanged = (val) => {
    setReportRecipientEditModalEmail(val);

    // force user to have to revalidate
    setReportRecipientEditModalUpdateButtonEnabled(false);

    let emailValidationResult = isValidReportRecipientEmailValue(val);
    setReportRecipientEditModalEmailValid(emailValidationResult.res);
    setReportRecipientEditModalEmailErrorMessage(emailValidationResult.msg);

    if (emailValidationResult.res) {
      // the email address looks like an email address, now check if it's already in use
      reportRecipientEmailAddressIsInUse(reportRecipientsUrl, keycloak.token, selectedReportRecipientId,
        val, setReportRecipientEditModalEmailValid, setReportRecipientEditModalEmailErrorMessage);
    }
  };

  const handleReportRecipientAddModalEnabledChanged = (val) => {
    setReportRecipientAddModalEnabled(val);
    // force user to have to revalidate
    setReportRecipientAddModalAddButtonEnabled(false);
  };

  const handleReportRecipientEditModalEnabledChanged = (val) => {
    setReportRecipientEditModalEnabled(val);
    // force user to have to revalidate
    setReportRecipientEditModalUpdateButtonEnabled(false);
  };

  const updateReportRecipient = async () => {

    let patchData = {
      id: selectedReportRecipientId,
      name: reportRecipientEditModalName,
      email: reportRecipientEditModalEmail,
      enabled: reportRecipientEditModalEnabled
    };
    await fetch(reportRecipientsUrl
      + selectedReportRecipientId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setSelectedReportRecipientId(respData['id'])
        setReportRecipientEditModalName(respData['name']);
        setReportRecipientEditModalEmail(respData['email']);
        setReportRecipientEditModalEnabled(respData['enabled']);

        fetchReportRecipientsData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleReportRecipientAddModalUpdateButtonClick = (event) => {
    addReportRecipient();
    setReportRecipientAddModalOpen(false);
    setSelectedReportRecipientId(0);
    setReportRecipientAddModalAddButtonEnabled(false);
    setEditReportRecipientButtonEnabled(false);
    setDeleteReportRecipientButtonEnabled(false);
  };

  const handleReportRecipientEditModalUpdateButtonClick = (event) => {
    updateReportRecipient();
    setReportRecipientEditModalOpen(false);
    setSelectedReportRecipientId(0);
    setReportRecipientEditModalUpdateButtonEnabled(false);
    setEditReportRecipientButtonEnabled(false);
    setDeleteReportRecipientButtonEnabled(false);
  };

  const handleReportRecipientAddModalValidateButtonClick = (event) => {
    performReportRecipientAddModalValidation();
  };

  const handleReportRecipientEditModalValidateButtonClick = (event) => {
    performReportRecipientEditModalValidation();
  };

  /**
 * Launch the add reportable country modal with a list of available countries that excludes
 *  those currently reportable
 */
  const launchAddReportableCountryModal = async () => {

    // first fetch list of currently reportable countries
    await fetch(reportableCountryUrl
      + '?limit=' + 300, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(entry['country_id']);
        }

        // record what the currently reportable countries are so we can exclude them from options
        setCurrentlyReportableCountries(tmpRows);

        fetch(countriesyUrl
          + '?limit=' + 300, {
          method: 'GET',
          headers: {
            'access-token': keycloak.token
          },
        })
          .then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.json();
          })
          .then((respData) => {
            let tmpRows = [];
            for (let i = 0; i < respData.results.length; i++) {
              let entry = respData.results[i];
              let existingIndex = currentlyReportableCountries.indexOf(entry['pk']);
              if (existingIndex === -1) {

                tmpRows.push({ country_id: entry['pk'], country: entry['name'] });
              }
            }
            setReportableCountryOptions(tmpRows);

            // we need to prune the countries that are already blocked from country options
            // open dialog for adding a new blocked country
            setReportableCountryModalOpen(true);
          }).catch((error) => {
            console.log('error: ' + error);
            setFetchErrorMessageOpen(true);
          });
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleAddReportableCountryButtonClick = () => {
    launchAddReportableCountryModal();
  };

  const deleteReportableCountry = async (fetchFunctionCallback) => {
    await fetch(reportableCountryUrl
      + selectedReportableCountryId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        fetchFunctionCallback();
      });
  };

  const handleDeleteReportableCountryButtonClick = (fetchFunctionCallback) => {
    deleteReportableCountry(fetchFunctionCallback);
  };

  const handleToggleDeleteReportableCountryButtonVisibility = (val) => {
    setDeleteReportableCountryButtonEnabled(val);
  };

  const handleReportableCountryToAddChosenHandler = (val) => {
    setReportableCountryModalCountryId(val);
    setReportableCountryModalAddButtonDisabled(false);

  };

  const handleAddReportableCountryModalCancelled = () => {
    setReportableCountryModalOpen(false);

    setEditReportRecipientButtonEnabled(false);
    setDeleteReportRecipientButtonEnabled(false);
  };

  const addReportableCountry = async (country_id, country) => {

    let postData = {
      country_id: country_id,
      country: country
    };
    await fetch(reportableCountryUrl
      , {
        method: 'POST',
        headers: {
          'access-token': keycloak.token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchReportableCountriesData();
        setEditReportRecipientButtonEnabled(false);
        setDeleteReportRecipientButtonEnabled(false);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleAddReportableCountryModalAddButtonClicked = () => {
    // at this point reportableCountryModalCountryId is country_id|country
    // so split the string and supply the two parts
    let parts = reportableCountryModalCountryId.split("|");
    addReportableCountry(parts[0], parts[1]);
    // do what's needed to add the reportable country
    setReportableCountryModalOpen(false);
  };

  const getReportRecipientsSearchTerm = () => {
    return freshReportRecipientsSearchTerm;
  };

  const getSanitizedReportRecipientsSearchFieldContent = () => {
    let ret = getReportRecipientsSearchTerm();
    // TODO: we might need to url-encode the search term here
    return ret;
  };

  const fetchReportRecipientsData = async () => {
    const sanitizedSearchTerm = getSanitizedReportRecipientsSearchFieldContent();
    const orderPrefix = (reportRecipientsOrder === 'desc' ? '-' : '');

    await fetch(reportRecipientsUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + reportRecipientsOffset
      + '&page=' + (reportRecipientsPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + orderPrefix + reportRecipientsOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setReportRecipientsTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createReportRecipientRowData(entry['id'],
            entry['name'],
            entry['email'],
            entry['enabled']));
        }

        setReportRecipientsSelected([]);
        setReportRecipientsRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setReportRecipientSwitch(false);
  };

  const fetchReportableCountriesData = async () => {
    const orderPrefix = (reportableCountriesOrder === 'desc' ? '-' : '');

    await fetch(reportableCountryUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + reportableCountriesOffset
      + '&page=' + (reportableCountriesPage + 1)
      + '&ordering=' + orderPrefix + reportableCountriesOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setReportableCountriesTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createReportableCountriesRowData(entry['country_id'],
            entry['country']));
        }

        setReportableCountriesSelected([]);
        setReportableCountriesRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setReportableCountrySwitch(false);
  };

  const handleReportRecipientSearchTermChanged = (val) => {
    setReportRecipientsSearchTerm(val);
    freshReportRecipientsSearchTerm = val;
  };

  const handleReportRecipientsRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setReportRecipientsRefreshNotificationOpen(false);
  };

  const displayReportRecipientsRefreshStarted = (event) => {
    setReportRecipientsRefreshNotificationOpen(true);
  };

  const handleReportRecipientsRefreshButtonClick = (event) => {
    fetchReportRecipientsData();
    displayReportRecipientsRefreshStarted();
  };

  const handleReportableCountriesRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setReportableCountriesRefreshNotificationOpen(false);
  };

  const displayReportableCountriesRefreshStarted = (event) => {
    setReportableCountriesRefreshNotificationOpen(true);
  };

  const handleReportableCountriesRefreshButtonClick = (event) => {
    fetchReportableCountriesData();
    displayReportableCountriesRefreshStarted();
  };

  const getReportableCountriesOrderBy = () => {
    return reportableCountriesOrderBy;
  }

  const getReportableCountriesOrder = () => {
    return reportableCountriesOrder;
  }

  const getReportRecipientsOrderBy = () => {
    return reportRecipientsOrderBy;
  }

  const getReportRecipientsOrder = () => {
    return reportRecipientsOrder;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Snackbar
        open={reportRecipientsRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleReportRecipientsRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {reportRecipientsRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={reportableCountriesRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleReportableCountriesRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {reportableCountriesRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={fetchErrorMessageOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleFetchErrorClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {fetchErrorMessage}
        </MuiAlert>
      </Snackbar>
      <AddReportRecipientDialog
        open={reportRecipientAddModalOpen}
        onClose={handleReportRecipientAddModalClose}
        reportRecipientName={reportRecipientAddModalName}
        reportRecipientNameSetter={handleReportRecipientAddModalNameChanged}
        reportRecipientNameValid={reportRecipientAddModalNameValid}
        reportRecipientNameErrorMessage={reportRecipientAddModalNameErrorMessage}
        reportRecipientEmail={reportRecipientAddModalEmail}
        reportRecipientEmailSetter={handleReportRecipientAddModalEmailChanged}
        reportRecipientEmailValid={reportRecipientAddModalEmailValid}
        reportRecipientEmailErrorMessage={reportRecipientAddModalEmailErrorMessage}
        reportRecipientEnabled={reportRecipientAddModalEnabled}
        recipientEnabledSetter={handleReportRecipientAddModalEnabledChanged}
        addButtonEnabled={reportRecipientAddModalAddButtonEnabled}
        addButtonClickListener={handleReportRecipientAddModalUpdateButtonClick}
        validateButtonEnabled={reportRecipientAddModalValidateButtonEnabled}
        validateButtonClickListener={handleReportRecipientAddModalValidateButtonClick}
      />
      <EditReportRecipientDialog
        open={reportRecipientEditModalOpen}
        onClose={handleReportRecipientEditModalClose}
        reportRecipientName={reportRecipientEditModalName}
        reportRecipientNameSetter={handleReportRecipientEditModalNameChanged}
        reportRecipientNameValid={reportRecipientEditModalNameValid}
        reportRecipientNameErrorMessage={reportRecipientEditModalNameErrorMessage}
        reportRecipientEmail={reportRecipientEditModalEmail}
        reportRecipientEmailSetter={handleReportRecipientEditModalEmailChanged}
        reportRecipientEmailValid={reportRecipientEditModalEmailValid}
        reportRecipientEmailErrorMessage={reportRecipientEditModalEmailErrorMessage}
        reportRecipientEnabled={reportRecipientEditModalEnabled}
        reportRecipientEnabledSetter={handleReportRecipientEditModalEnabledChanged}
        updateButtonEnabled={reportRecipientEditModalUpdateButtonEnabled}
        updateButtonClickListener={handleReportRecipientEditModalUpdateButtonClick}
        validateButtonEnabled={reportRecipientEditModalValidateButtonEnabled}
        validateButtonClickListener={handleReportRecipientEditModalValidateButtonClick}
      />
      <AddReportableCountryDialog
        open={reportableCountryModalOpen}
        countryChosenSetter={handleReportableCountryToAddChosenHandler}
        onCancel={handleAddReportableCountryModalCancelled}
        onAdd={handleAddReportableCountryModalAddButtonClicked}
        countryOptions={reportableCountryOptions}
        addButtonDisabled={reportableCountryModalAddButtonDisabled}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="reports tabs">
          <Tab label="Report Recipients" {...a11yProps(0)} />
          <Tab label="Reportable Countries" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        {hasReportRecipientsRead === true ? (
          <ReportRecipientsTab
            addButtonEnabled={addReportRecipientButtonEnabled}
            addButtonClickListener={handleAddReportRecipientButtonClick}
            editButtonEnabled={editReportRecipientButtonEnabled}
            editButtonClickListener={handleEditReportRecipientButtonClick}
            deleteButtonEnabled={deleteReportRecipientButtonEnabled}
            deleteButtonClickListener={handleDeleteReportRecipientButtonClick}
            onSelectRowId={setSelectedReportRecipientId}
            editButtonToggle={handleToggleEditReportRecipientButtonVisibility}
            deleteButtonToggle={handleToggleDeleteReportRecipientButtonVisibility}
            setReportRecipientSwitch={setReportRecipientSwitch}
            order={getReportRecipientsOrder}
            orderSetter={setReportRecipientsOrder}
            orderBy={getReportRecipientsOrderBy}
            orderBySetter={setReportRecipientsOrderBy}
            selected={reportRecipientsSelected}
            selectedSetter={setReportRecipientsSelected}
            offset={reportRecipientsOffset}
            offsetSetter={setReportRecipientsOffset}
            page={reportRecipientsPage}
            pageSetter={setReportRecipientsPage}
            rows={reportRecipientsRows}
            total={reportRecipientsTotal}
            totalSetter={setReportRecipientsTotal}
            searchTerm={reportRecipientsSearchTerm}
            searchTermSetter={handleReportRecipientSearchTermChanged}
            rowsPerPage={rowsPerPage}
            fetchData={fetchReportRecipientsData}
            refreshButtonClickHandler={handleReportRecipientsRefreshButtonClick}
          />) : ('')}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {hasReportableCountriesRead === true ? (
          <ReportableCountriesTab
            addButtonEnabled={addReportableCountryButtonEnabled}
            addButtonClickListener={handleAddReportableCountryButtonClick}
            deleteButtonEnabled={deleteReportableCountryButtonEnabled}
            deleteButtonClickListener={handleDeleteReportableCountryButtonClick}
            onSelectRowId={setSelectedReportableCountryId}
            deleteButtonToggle={handleToggleDeleteReportableCountryButtonVisibility}
            order={getReportableCountriesOrder}
            orderSetter={setReportableCountriesOrder}
            orderBy={getReportableCountriesOrderBy}
            orderBySetter={setReportableCountriesOrderBy}
            setReportableCountrySwitch={setReportableCountrySwitch}
            selected={reportableCountriesSelected}
            selectedSetter={setReportableCountriesSelected}
            offset={reportableCountriesOffset}
            offsetSetter={setReportableCountriesOffset}
            page={reportableCountriesPage}
            pageSetter={setReportableCountriesPage}
            rows={reportableCountriesRows}
            total={reportableCountriesTotal}
            rowsPerPage={rowsPerPage}
            fetchData={fetchReportableCountriesData}
            refreshButtonClickHandler={handleReportableCountriesRefreshButtonClick}
          />) : ('')}
      </TabPanel>
    </Box>
  );
}
