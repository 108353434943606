import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import PrivateRoute from './helpers/PrivateRoute';
import AsnPage from './routes/AsnPage';
import AnonymousPage from './routes/AnonymousPage';
import GeoIPPage from './routes/GeoIPPage';
import ContactUsPage from './routes/ContactUsPage';
import BllPage from './routes/BllPage';
import ScansPage from './routes/ScansPage';
import reportWebVitals from './reportWebVitals';
import NFTRulesPage from './routes/NFTRulesPage';
import LicenseKeyMgmtPage from './routes/Admin/LicenseKeyMgmtPage';
import APIKeysPage from './routes/Admin/APIKeysPage';
import CountriesPlusPage from './routes/Admin/CountriesPlusPage';
import ScheduledTasksPage from './routes/Admin/ScheduledTasksPage';
import ManualRulesPage from './routes/ManualRulesPage';
import CeleryPage from './routes/Admin/CeleryPage';
import DynamicPreferencesPage from './routes/Admin/DynamicPreferencesPage';
import ReportsPage from './routes/Admin/ReportsPage';
import AnalysisPage from './routes/AnalysisPage';
import ContentUploadPage from './routes/Admin/ContentUploadPage';
import PcapPage from './routes/PcapPage';

import './App.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ReactKeycloakProvider authClient={keycloak}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}></Route>
                <Route path="/bllpage" element={
                    <BllPage />
                }></Route>
                <Route path="/geoippage" element={
                    <GeoIPPage />
                }></Route>
                <Route path="/asnpage" element={
                    <AsnPage />
                }></Route>
                <Route path="/anonpage" element={
                    <AnonymousPage />
                }></Route>
                <Route path="/analysis" element={
                    <AnalysisPage />
                }></Route>
                <Route path="/contactus" element={
                    <ContactUsPage />
                }></Route>
                <Route path="/scans" element={
                    <ScansPage />
                }></Route>
                <Route path="/nftables" element={
                    <NFTRulesPage />
                }></Route>
                <Route path="/licensekeys" element={
                    <PrivateRoute>
                        <LicenseKeyMgmtPage />
                    </PrivateRoute>
                }></Route>
                <Route path="/apikeys" element={
                    <APIKeysPage />
                }></Route>
                <Route path="/countriesplus" element={
                    <CountriesPlusPage />
                }></Route>
                <Route path="/scheduledtasks" element={
                    <ScheduledTasksPage />
                }></Route>
                <Route path="/manualrules" element={
                    <ManualRulesPage />
                }></Route>
                <Route path="/pcap" element={
                    <PcapPage />
                }></Route>
                <Route path="/celeryresults" element={
                    <CeleryPage />
                }></Route>
                <Route path="/dynamicpreferences" element={
                    <DynamicPreferencesPage />
                }></Route>
                <Route path="/reports" element={
                    <ReportsPage />
                }></Route>
                <Route path="/contentupload" element={
                    <ContentUploadPage />
                }></Route>
            </Routes>
        </BrowserRouter>
    </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
