import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

export default function DynamicPropEditorDialog(props) {
    const {
        open,
        onClose,
        selectedName,
        selectedHelperText,
        selectedDefaultValue,
        selectedRawValue,
        selectedRawValueValid,
        selectedRawValueSetter,
        selectedRawValueErrorMessage,
        updateButtonEnabled,
        updateButtonClickHandler
    } = props;
    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleUpdateButtonClick = (event) => {
        updateButtonClickHandler();
    };

    const readOnlyTextFieldHandler = (event) => {
        // do nothing.  Don't allow value to be changed
    };

    const handleRawValueChanged = (event) => {
        selectedRawValueSetter(event.target.value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{selectedName}</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        label="Name"
                        variant="outlined"
                        size="medium"
                        value={selectedName}
                        onChange={readOnlyTextFieldHandler}
                        sx={{ minWidth: '280px' }}
                    />
                    <Tooltip title={selectedHelperText}><HelpIcon /></Tooltip>
                    <TextField
                        label="Default Value"
                        variant="outlined"
                        size="medium"
                        value={selectedDefaultValue}
                        onChange={readOnlyTextFieldHandler}
                    />
                    <TextField
                        {...(selectedRawValueValid !== true && { error: undefined })}
                        helperText={selectedRawValueErrorMessage}
                        label="Raw Value"
                        variant="outlined"
                        size="medium"
                        value={selectedRawValue}
                        onChange={handleRawValueChanged}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="globalpreferences-table-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="globalpreferences-table-modal-dialog-update-button"
                            variant="contained"
                            disabled={!updateButtonEnabled()}
                            onClick={handleUpdateButtonClick}>Update</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
