import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid'
import { FixedSizeList } from 'react-window';
import { useKeycloak } from '@react-keycloak/web';
import './mainpage.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export const fetchErrorMessage = "An issue has occurred with fetching data.  If problem persists please contact ispss.cyclops@lumen.com";

function MainComponent() {

	const { keycloak } = useKeycloak();

	const [mpdata, setMpdata] = useState([]);
	const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
	/* Number of milliseconds to show notifications */
	const notificationAutoclose = 5000;

	const [snackState,] = React.useState({
		snackbarVertical: 'bottom',
		snackbarHorizontal: 'center',
	});



	const handleFetchErrorClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setFetchErrorMessageOpen(false);
	};

	useEffect(() => {
		fetch('/middleware/v2/get_front_page_content', {
			method: 'GET',
			headers: {
				'access-token': keycloak.token
			},
		}).then((response) => {
			if (!response.ok) throw new Error(response.status);
			else return response.text();
		}).then(resp => {
			setMpdata(JSON.parse(resp))
		}).catch((error) => {
			console.log('error: ' + error);
			setFetchErrorMessageOpen(true);
		});
	}, [keycloak])
	function MainDataList() {

		const [snackState,] = React.useState({
			snackbarVertical: 'bottom',
			snackbarHorizontal: 'center',
		});

		const { snackbarHorizontal, snackbarVertical } = snackState;
		return (Object.entries(mpdata).map(([key, value]) => {

			// to help selenium be able to find the dashboard widgets
			// we are going to set an id for each one depending
			// on the key
			let widgetId = undefined;
			switch (key) {
				case "Blocked Countries":
					widgetId = "blocked-countries-mainpage-widget"
					break;
				case "Blocked ASNs":
					widgetId = "blocked-asns-mainpage-widget"
					break;
				case "Blocked Anonymous Types":
					widgetId = "blocked-anonymous-types-mainpage-widget"
					break;
				case "Active Scan Alerts":
					widgetId = "active-scan-alerts-mainpage-widget"
					break;
				case "Unique Scan Alerts":
					widgetId = "unique-scan-alerts-mainpage-widget"
					break;
				case "Subscription Updates":
					widgetId = "subscription-updates-mainpage-widget"
					break;
				case "Total BLL IPs":
					widgetId = "total-bll-ips-mainpage-widget"
					break;
				case "Blocked BLL Collection Names":
					widgetId = "blocked-bll-collection-names-mainpage-widget"
					break;
				default:
					console.log("Warning, unhandled key: " + key);
					break;
			}
			if (key === `Blocked BLL Collection Names`) {
				value.sort();
			}

			if (Array.isArray(value)) {
				if (key === `Blocked BLL Collection Names`) {
					value.sort();
				}
				return (<Grid item xs={4}>
					<h2 class="mainpage-widget-header" id={widgetId}>{key}</h2>
					<FixedSizeList
						class="mainpage-widget-list"
						height={175}
						width="100%"
						itemData={value}
						itemSize={35}
						itemCount={value.length}
						overscanCount={5}
					>{ItemRenderer}</FixedSizeList>
					<Snackbar
						open={fetchErrorMessageOpen}
						autoHideDuration={notificationAutoclose}
						onClose={handleFetchErrorClosed}
						anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
					>
						<MuiAlert
							className="logi-snackbar-notification-message"
							severity="info"
							variant="filled"
							sx={{ width: '100%' }}>
							{fetchErrorMessage}
						</MuiAlert>
					</Snackbar>
					<div class="mainpage-widget-footer" />
				</Grid>)
			} else {
				return (<Grid item xs={4}>
					<h2 class="mainpage-widget-header" id={widgetId}>{key}</h2>
					<div class="mainpage-widget-list">
						<p class="mainpage-widget-single-value-p">{isNaN(value) ? value : value.toLocaleString()}</p>
					</div>
					<Snackbar
						open={fetchErrorMessageOpen}
						autoHideDuration={notificationAutoclose}
						onClose={handleFetchErrorClosed}
						anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
					>
						<MuiAlert
							className="logi-snackbar-notification-message"
							severity="info"
							variant="filled"
							sx={{ width: '100%' }}>
							{fetchErrorMessage}
						</MuiAlert>
					</Snackbar>
					<div class="mainpage-widget-footer" />
				</Grid>)
			}
		}

		)
		)
	}

	// The item renderer is declared outside of the list-rendering component.
	// So it has no way to directly access the items array.
	function ItemRenderer({ data, index, style }) {

		// Access the items array using the "data" prop:
		return (
			// <div class="mainpage-widget-item" style={style}>
			// 	{item}
			// </div>
			<div class="mainpage-widget-item" style={{
				...style,
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}>
				{data[index]}
			</div>
		);
	}

	return (
		<div className='App'>
			{/* <Button
				id="mainpage-download-country-report-button"
				variant="contained"
				onClick={() =>
					fetch('/middleware/v2/report', {
						method: 'GET',
						headers: {
							'access-token': keycloak.token
						},
					}).then((res) => {
						return res.blob();
					}).then((blob) => {
						const href = window.URL.createObjectURL(blob);
						const link = document.createElement('a');
						link.href = href;
						link.setAttribute('download', 'report.xlsx');
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					})
						.catch((err) => {
							return Promise.reject({ Error: 'Something Went Wrong', err });
						})

				}>Download Country Report</Button> */}
			<Grid container spacing={2} >
				<MainDataList></MainDataList>
			</Grid>
		</div>
	)
}

export default MainComponent;
