
import Box from '@mui/material/Box';
import Navs from '../components/topnav';
import Sidenavs from '../components/sidenav';
import { LOGI_ROLE_SCANNER_MESSAGES_READ } from '../common/LogiRoles';
import { useKeycloak } from '@react-keycloak/web';
import ScanComponent from '../components/Scans/ScanComponent';
import '../components/Scans/Scans.css';

function ScansPage() {
    const { keycloak } = useKeycloak();
    let hasScanRead = keycloak.hasRealmRole(LOGI_ROLE_SCANNER_MESSAGES_READ);

    if (keycloak.idTokenParsed) {
        return (
            <div className='App'>
                <Navs />
                <table className='mptable'>
                    <tr>
                        <td className='sidenavtd'>
                            <Box sx={{ position: 'sticky', top: 0 }}>
                                <Sidenavs firewallSettingsOpen={true} adminOpen={false}/>
                            </Box>
                        </td>
                        <td class="scans-page-td">
                            {hasScanRead ? (<Box sx={{ height: `100%` }}>
                                <div class="scans-page-div">
                                    <ScanComponent />
                                </div>
                            </Box>) : (<p>You do not have permission to view this page</p>)}
                        </td>
                    </tr>
                </table>
            </div>
        );
    } else {
        window.location.href = '/';
    }
}

export default ScansPage;
