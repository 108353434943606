import Box from '@mui/material/Box';
import Navs from '../components/topnav';
import Sidenavs from '../components/sidenav';
import PcapComponent from '../components/Pcap/PcapComponent';
import { useKeycloak } from '@react-keycloak/web';
import { LOGI_ROLE_PCAP_READ } from '../common/LogiRoles';

function PcapPage() {
	const { keycloak } = useKeycloak();
	const hasPCAPRead = keycloak.hasRealmRole(LOGI_ROLE_PCAP_READ);

	if (keycloak.idTokenParsed) {
		return (
			<div className='App'>
				<Navs />
				<table className='mptable'>
					<tr>
						<td className='sidenavtd'>
							<Box sx={{ position: 'sticky', top: 0 }}>
								<Sidenavs firewallSettingsOpen={true} adminOpen={false} />
							</Box>
						</td>
						<td class="pcap-page-route-td">
							{hasPCAPRead ? (<Box sx={{ height: `100%` }}>
								<div class="pcap-page-div">
									<PcapComponent />
								</div>
							</Box>) : (<p>You do not have permission to view this page</p>)}
						</td>
					</tr>
				</table>
			</div>
		);
	} else {
		window.location.href = '/';
	}
};

export default PcapPage;
