/**
 * Replace null values with the specified replacement.
 * If val is null, the replacement is returned.  Otherwise, val is returned unchanged.
 * 
 * @param {*} val Value to check for null
 * @param {*} replacement String to return as a replacement
 */
export function coalesce(val, replacement) {
    if (val === null || val === undefined) {
        return replacement
    }
    else {
        return val
    }
}


/**
 * Given the value of a Content-Disposition header, extract the filename component
 *  
 * Example value: attachment; filename="manualrules_2023-01-17.xlsx"
 * 
 * TODO: deal with possible case where header value is wrapped onto next line
 * TODO: deal with possibility that UTF-8 encoded filename is present
 * 
 * @param {*} cdHeaderVal The value as gotten from response.headers.get('Content-Disposition')
 * @param {*} defaultVal A value to use if we can't determine the filename
 * 
 * @returns A string to be used as a filename
 */
export function getContentDispositionFilename(cdHeaderVal, defaultVal) {
    let ret = defaultVal;

    if (cdHeaderVal === null || cdHeaderVal === undefined) {
        return ret;
    }

    const filenameToken = "filename=\"";
    let fnTokenLoc = cdHeaderVal.indexOf(filenameToken);

    if(fnTokenLoc > -1) {
        // the Content-Disposition header value has the filename field
        let fnSubstring = cdHeaderVal.substring(fnTokenLoc);

        // lop off the filename=" prefix
        fnSubstring = fnSubstring.substring(filenameToken.length);

        // find the next quote char
        let endQuoteLoc = fnSubstring.indexOf("\"");

        // capture the filename between the quotes
        ret = fnSubstring.substring(0, endQuoteLoc);
    }
    
    return ret;
}
