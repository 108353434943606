import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
  isValidPortNumber,
  isValidIpVersionCidr,
  MR_ACTION_ALLOW,
  MR_DIRECTION_INBOUND,
  checkDuplicateManualRuleRow
}
  from '../../common/validation/manual_rules/ManualRulesValidator';
import { coalesce } from '../../common/utility/DataUtil';
import { useKeycloak } from '@react-keycloak/web';
import ManualRulesTab from './ManualRulesTab';
import AntispoofTab from './AntispoofTab';
import AddManualRuleDialog from './modals/AddManualRuleDialog';
import EditManualRuleDialog from './modals/EditManualRuleDialog';
import EditAntispoofingDialog from './modals/EditAntispoofingDialog';
import AddAntispoofingDialog from './modals/AddAntispoofingDialog';
import { LOGI_ROLE_ANTISPOOFING_RULES_READ, LOGI_ROLE_MANUAL_RULES_READ } from '../../common/LogiRoles';
import "./ManualRules.css";
import { fetchErrorMessage } from '../mainpage';

const rowsPerPage = 20;

const manualRulesUrl = '/middleware/api/manualrules/';
const antispoofingUrl = '/middleware/api/antispoofing/';
const antispoofingV4Url = '/middleware/api/antispoofing_subnets_v4/';
const antispoofingV6Url = '/middleware/api/antispoofing_subnets_v6/';
const mrV4Url = '/middleware/api/manual_rule_v4/';
const mrV6Url = '/middleware/api/manual_rule_v6/';

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

const defaultAntispoofingSearchOption = "Both";

const defaultMrSearchOption = "Both";

const antispoofingSearchOptions = [
  "IPv4",
  "IPv6"
];

const mrSearchOptions = [
  "IPv4",
  "IPv6"
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `manual-rules-tab-${index}`,
    'aria-controls': `manual-rules-tabpanel-${index}`,
  };
}

function createManualRulesRowData(id,
  ip_version,
  protocol,
  source_address,
  source_port,
  destination_address,
  destination_port,
  bidirectional,
  direction,
  action,
  created) {
  return {
    id,
    ip_version,
    protocol,
    source_address,
    source_port,
    destination_address,
    destination_port,
    bidirectional,
    direction,
    action,
    created
  };
}

function createAntiSpoofingRowData(id, subnet, comments, is_allowed) {
  return {
    id,
    subnet,
    comments,
    is_allowed
  };
}

// needed so that reset button doesn't have to be clicked twice
let freshManualRulesSearchTerm = '';
let freshAntispoofingRulesSearchTerm = '';

export default function ManualRulesComponent() {
  const { keycloak } = useKeycloak();
  const [tabIndex, setTabIndex] = useState(0);

  // snackbar state vars
  const [snackState,] = React.useState({
    snackbarVertical: 'bottom',
    snackbarHorizontal: 'center',
  });

  const { snackbarHorizontal, snackbarVertical } = snackState;

  const [manualRulesRefreshNotificationOpen, setManualRulesRefreshNotificationOpen] = useState(false);
  const manualRulesRefreshNotificationMessage = "Manual Rules Data Refreshed";

  const [antispoofingRefreshNotificationOpen, setAntispoofingRefreshNotificationOpen] = useState(false);
  const antispoofingRefreshNotificationMessage = "Antispoofing Data Refreshed";
  const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
  const handleFetchErrorClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFetchErrorMessageOpen(false);
  };

  // manual rules tab state vars
  const [manualRulesOrder, setManualRulesOrder] = useState('asc');
  const [manualRulesOrderBy, setManualRulesOrderBy] = useState('source_address');
  const [manualRulesSelected, setManualRulesSelected] = useState([]);
  const [manualRulesOffset, setManualRulesOffset] = useState(0);
  const [manualRulesPage, setManualRulesPage] = useState(0);
  const [manualRulesRows, setManualRulesRows] = useState([]);
  const [manualRulesTotal, setManualRulesTotal] = useState(0);
  const [manualRulesSearchTerm, setManualRulesSearchTerm] = useState('');
  const [selectedManualRuleId, setSelectedManualRuleId] = useState(0);
  const [addManualRuleButtonEnabled,] = useState(true);
  const [editManualRuleButtonEnabled, setEditManualRuleButtonEnabled] = useState(false);
  const [deleteManualRuleButtonEnabled, setDeleteManualRuleButtonEnabled] = useState(false);
  const [searchMrVersion, setSearchMrVersion] = useState(defaultMrSearchOption);

  // manual rules add modal related
  const [manualRuleAddModalOpen, setManualRuleAddModalOpen] = useState(false);
  const [manualRuleAddModalIpVersion, setManualRuleAddModalIpVersion] = useState("4");
  const [manualRuleAddModalProtocol, setManualRuleAddModalProtocol] = useState("TCP");
  const [manualRuleAddModalSrcAddress, setManualRuleAddModalSrcAddress] = useState("any");
  const [manualRuleAddModalSrcAddressValid, setManualRuleAddModalSrcAddressValid] = useState(true);
  const [manualRuleAddModalSrcAdressErrMessage, setManualRuleAddModalSrcAddressErrMessage] = useState("");
  const [manualRuleAddModalSrcPort, setManualRuleAddModalSrcPort] = useState("any");
  const [manualRuleAddModalSrcPortValid, setManualRuleAddModalSrcPortValid] = useState(true);
  const [manualRuleAddModalSrcPortErrMessage, setManualRuleAddModalSrcPortErrMessage] = useState("");
  const [manualRuleAddModalDstAddress, setManualRuleAddModalDstAddress] = useState("any");
  const [manualRuleAddModalDstAddressValid, setManualRuleAddModalDstAddressValid] = useState(true);
  const [manualRuleAddModalDstAdressErrMessage, setManualRuleAddModalDstAddressErrMessage] = useState("");
  const [manualRuleAddModalDstPort, setManualRuleAddModalDstPort] = useState("any");
  const [manualRuleAddModalDstPortValid, setManualRuleAddModalDstPortValid] = useState(true);
  const [manualRuleAddModalDstPortErrMessage, setManualRuleAddModalDstPortErrMessage] = useState("");
  const [manualRuleAddModalBidirectional, setManualRuleAddModalBidirectional] = useState(true);
  const [manualRuleAddModalDirection, setManualRuleAddModalDirection] = useState(MR_DIRECTION_INBOUND);
  const [manualRuleAddModalAction, setManualRuleAddModalAction] = useState(MR_ACTION_ALLOW);
  const [manualRuleAddModalComments, setManualRuleAddModalComments] = useState("");
  const [manualRuleAddModalAddButtonEnabled, setManualRuleAddModalAddButtonEnabled] = useState(false);
  const [manualRuleAddModalValidationValid, setManualRuleAddModalValidationValid] = useState(false);
  const [manualRuleAddModalValidationStatusMessage, setManualRuleAddModalValidationStatusMessage] = useState("Rule is valid");

  // manual rules edit modal related
  const [manualRuleEditModalOpen, setManualRuleEditModalOpen] = useState(false);
  const [manualRuleEditModalIpVersion, setManualRuleEditModalIpVersion] = useState("4");
  const [manualRuleEditModalProtocol, setManualRuleEditModalProtocol] = useState("TCP");
  const [manualRuleEditModalSrcAddress, setManualRuleEditModalSrcAddress] = useState("any");
  const [manualRuleEditModalSrcAddressValid, setManualRuleEditModalSrcAddressValid] = useState(true);
  const [manualRuleEditModalSrcAdressErrMessage, setManualRuleEditModalSrcAddressErrMessage] = useState("");
  const [manualRuleEditModalSrcPort, setManualRuleEditModalSrcPort] = useState("any");
  const [manualRuleEditModalSrcPortValid, setManualRuleEditModalSrcPortValid] = useState(true);
  const [manualRuleEditModalSrcPortErrMessage, setManualRuleEditModalSrcPortErrMessage] = useState("");
  const [manualRuleEditModalDstAddress, setManualRuleEditModalDstAddress] = useState("any");
  const [manualRuleEditModalDstAddressValid, setManualRuleEditModalDstAddressValid] = useState(true);
  const [manualRuleEditModalDstAdressErrMessage, setManualRuleEditModalDstAddressErrMessage] = useState("");
  const [manualRuleEditModalDstPort, setManualRuleEditModalDstPort] = useState("any");
  const [manualRuleEditModalDstPortValid, setManualRuleEditModalDstPortValid] = useState(true);
  const [manualRuleEditModalDstPortErrMessage, setManualRuleEditModalDstPortErrMessage] = useState("");
  const [manualRuleEditModalBidirectional, setManualRuleEditModalBidirectional] = useState(true);
  const [manualRuleEditModalDirection, setManualRuleEditModalDirection] = useState(MR_DIRECTION_INBOUND);
  const [manualRuleEditModalAction, setManualRuleEditModalAction] = useState(MR_ACTION_ALLOW);
  const [manualRuleEditModalComments, setManualRuleEditModalComments] = useState("");
  const [manualRuleEditModalCreated, setManualRuleEditModalCreated] = useState("");
  const [manualRuleEditModalUpdateButtonEnabled, setManualRuleEditModalUpdateButtonEnabled] = useState(false);
  const [manualRuleEditModalValidationValid, setManualRuleEditModalValidationValid] = useState(true);
  const [manualRuleEditModalValidationStatusMessage, setManualRuleEditModalValidationStatusMessage] = useState("Rule is valid");

  // anti-spoofing tab state vars
  const [antispoofingOrder, setAntispoofingOrder] = useState('asc');
  const [antispoofingOrderBy, setAntispoofingOrderBy] = useState('subnet');
  const [antispoofingSelected, setAntispoofingSelected] = useState([]);
  const [antispoofingOffset, setAntispoofingOffset] = useState(0);
  const [antispoofingPage, setAntispoofingPage] = useState(0);
  const [antispoofingRows, setAntispoofingRows] = useState([]);
  const [antispoofingTotal, setAntispoofingTotal] = useState(0);
  const [antispoofingSearchTerm, setAntispoofingSearchTerm] = useState('');
  const [selectedAntispoofingId, setSelectedAntispoofingId] = useState(0);
  const [addAntispoofingButtonEnabled,] = useState(true);
  const [editAntispoofingButtonEnabled, setEditAntispoofingButtonEnabled] = useState(false);
  const [deleteAntispoofingButtonEnabled, setDeleteAntispoofingButtonEnabled] = useState(false);
  const [searchAntispoofingVersion, setSearchAntispoofingVersion] = useState(defaultAntispoofingSearchOption)

  // anti-spoofing add modal related
  const [antispoofingAddModalOpen, setAntispoofingAddModalOpen] = useState(false);
  const [antispoofingAddModalSubnet, setAntispoofingAddModalSubnet] = useState("");
  const [antispoofingAddModalSubnetValid, setAntispoofingAddModalSubnetValid] = useState(true);
  const [antispoofingAddModalSubnetErrMessage, setAntispoofingAddModalSubnetErrMessage] = useState("");
  const [antispoofingAddModalComments, setAntispoofingAddModalComments] = useState("");
  const [antispoofingAddModalValidationValid, setAntispoofingAddModalValidationValid] = useState(true);
  const [antispoofingAddModalValidationStatusMessage, setAntispoofingAddModalValidationStatusMessage] = useState("Valid");
  const [antispoofingAddModalAddButtonEnabled, setAntispoofingAddModalAddButtonEnabled] = useState(false);

  // anti-spoofing edit modal related
  const [antispoofingEditModalOpen, setAntispoofingEditModalOpen] = useState(false);
  const [antispoofingEditModalSubnet, setAntispoofingEditModalSubnet] = useState("");
  const [antispoofingEditModalSubnetValid, setAntispoofingEditModalSubnetValid] = useState(true);
  const [antispoofingEditModalSubnetErrMessage, setAntispoofingEditModalSubnetErrMessage] = useState("");
  const [antispoofingEditModalComments, setAntispoofingEditModalComments] = useState("");
  const [antispoofingEditModalValidationValid, setAntispoofingEditModalValidationValid] = useState(true);
  const [antispoofingEditModalValidationStatusMessage, setAntispoofingEditModalValidationStatusMessage] = useState("Valid");
  const [antispoofingEditModalUpdateButtonEnabled, setAntispoofingEditModalUpdateButtonEnabled] = useState(false);

  let hasManualRulesRead = keycloak.hasRealmRole(LOGI_ROLE_MANUAL_RULES_READ);
  let hasAntispoofingRead = keycloak.hasRealmRole(LOGI_ROLE_ANTISPOOFING_RULES_READ);

  const handleAntispoofingVersionTermChanged = (val) => {
    setSearchAntispoofingVersion(val);
  };

  const handleMrVersionTermChanged = (val) => {
    setSearchMrVersion(val);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const fetchManualRuleModalData = () => {
    fetch(manualRulesUrl
      + selectedManualRuleId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        // example webservice response
        //   {
        //     "id": 1,
        //     "name": "Rule 1",
        //     "ip_version": 4,
        //     "protocol": "UDP",
        //     "direction": "inbound",
        //     "bidirectional": true,
        //     "action": "allow",
        //     "source_address": null,
        //     "source_port": null,
        //     "destination_address": "192.5.41.40/32",
        //     "destination_port": 123,
        //     "source_address_range_1": null,
        //     "source_address_range_2": null,
        //     "destination_address_range_1": null,
        //     "destination_address_range_2": null,
        //     "source_port_range_1": null,
        //     "source_port_range_2": null,
        //     "destination_port_range_1": null,
        //     "destination_port_range_2": null,
        //     "created": "2022-12-02T14:05:28.280359Z",
        //     "comments": ""
        // }

        setManualRuleEditModalIpVersion(respData['ip_version']);
        setManualRuleEditModalProtocol(respData['protocol']);
        setManualRuleEditModalSrcAddress(coalesce(respData['source_address'], "any"));
        setManualRuleEditModalSrcPort(coalesce(respData['source_port'], "any"));
        setManualRuleEditModalDstAddress(coalesce(respData['destination_address'], "any"));
        setManualRuleEditModalDstPort(coalesce(respData['destination_port'], "any"));
        setManualRuleEditModalBidirectional(respData['bidirectional']);
        setManualRuleEditModalDirection(respData['direction']);
        setManualRuleEditModalAction(respData['action']);
        setManualRuleEditModalComments(respData['comments']);
        setManualRuleEditModalCreated(respData['created']);
        setManualRuleEditModalValidationValid(true);
        setManualRuleEditModalValidationStatusMessage("Rule is valid");
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleEditManualRuleButtonClick = (event) => {
    fetchManualRuleModalData();
    setManualRuleEditModalOpen(true);
  };

  const addManualRule = async () => {
    let postData = {
      protocol: manualRuleAddModalProtocol,
      direction: manualRuleAddModalDirection,
      bidirectional: manualRuleAddModalBidirectional,
      action: manualRuleAddModalAction,
      source_address: manualRuleAddModalSrcAddress !== "any" ? manualRuleAddModalSrcAddress : null,
      source_port: manualRuleAddModalSrcPort !== "any" ? parseInt(manualRuleAddModalSrcPort) : null,
      destination_address: manualRuleAddModalDstAddress !== "any" ? manualRuleAddModalDstAddress : null,
      destination_port: manualRuleAddModalDstPort !== "any" ? parseInt(manualRuleAddModalDstPort) : null,
      comments: manualRuleAddModalComments
    };

    await fetch(manualRulesUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchManualRulesData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const launchAddManualRuleModal = (event) => {
    setManualRuleAddModalIpVersion("4");
    setManualRuleAddModalProtocol("TCP");
    setManualRuleAddModalSrcAddress("any");
    setManualRuleAddModalSrcPort("any");
    setManualRuleAddModalDstAddress("any");
    setManualRuleAddModalDstPort("any");
    setManualRuleAddModalBidirectional(true);
    setManualRuleAddModalDirection(MR_DIRECTION_INBOUND);
    setManualRuleAddModalAction(MR_ACTION_ALLOW);
    setManualRuleAddModalComments("");
    setManualRuleAddModalValidationValid(false);
    setManualRuleAddModalValidationStatusMessage("Rule is invalid");

    setManualRuleAddModalAddButtonEnabled(false);
    setManualRuleAddModalOpen(true);
  };

  const handleAddManualRuleButtonClick = (event) => {
    launchAddManualRuleModal();
  };

  const deleteManualRule = (refreshCallBack) => {
    fetch(manualRulesUrl
      + selectedManualRuleId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        refreshCallBack();
      });
  };

  const handleDeleteManualRuleButtonClick = (refreshCallBack) => {
    deleteManualRule(refreshCallBack);
  };

  const handleManualRuleSelected = (id) => {
    setSelectedManualRuleId(id);
    if (id !== 0) {
      setEditManualRuleButtonEnabled(true);
      setDeleteManualRuleButtonEnabled(true);
    }
    else {
      setEditManualRuleButtonEnabled(false);
      setDeleteManualRuleButtonEnabled(false);
    }
  };

  const handleManualRuleAddModalClose = () => {
    setManualRuleAddModalOpen(false);
    setEditManualRuleButtonEnabled(false);
    setDeleteManualRuleButtonEnabled(false);
  };

  const handleManualRuleEditModalClose = () => {
    setManualRuleEditModalOpen(false);
    setEditManualRuleButtonEnabled(false);
    setDeleteManualRuleButtonEnabled(false);
  };

  const performManualRuleAddModalValidation = () => {

    let ruleValid = true;

    // check that src address is valid for the selected ip version
    let srcAddressValidation = isValidIpVersionCidr(manualRuleAddModalIpVersion,
      manualRuleAddModalSrcAddress,
      manualRuleAddModalProtocol);
    setManualRuleAddModalSrcAddressValid(srcAddressValidation.res);
    setManualRuleAddModalSrcAddressErrMessage(srcAddressValidation.msg);
    if (!srcAddressValidation.res) {
      // rule can't be valid ruleValid
      ruleValid = false;
    }

    // check src port
    let srcPortValidation = isValidPortNumber(manualRuleAddModalSrcPort);
    setManualRuleAddModalSrcPortValid(srcPortValidation.res);
    setManualRuleAddModalSrcPortErrMessage(srcPortValidation.msg);
    if (!srcPortValidation.res) {
      // rule can't be valid
      ruleValid = false;
    }

    // check that dst address is valid for the selected ip version
    let dstAddressValidation = isValidIpVersionCidr(manualRuleAddModalIpVersion,
      manualRuleAddModalDstAddress, manualRuleAddModalProtocol);
    setManualRuleAddModalDstAddressValid(dstAddressValidation.res);
    setManualRuleAddModalDstAddressErrMessage(dstAddressValidation.msg);
    if (!dstAddressValidation.res) {
      // rule can't be valid ruleValid
      ruleValid = false;
    }

    // check dst port
    let dstPortValidation = isValidPortNumber(manualRuleAddModalDstPort);
    setManualRuleAddModalDstPortValid(dstPortValidation.res);
    setManualRuleAddModalDstPortErrMessage(dstPortValidation.msg);
    if (!dstPortValidation.res) {
      // rule can't be valid
      ruleValid = false;
    }

    if (!ruleValid) {
      setManualRuleAddModalAddButtonEnabled(false);
      setManualRuleAddModalValidationStatusMessage("Rule has one or more invalid settings");
    }
    else {

      // let's check if another rule has exactly the same fields set
      // and if so don't mark the rule valid.  The db table has a constraint
      // that would prevent us from inserting a duplicate rule
      checkDuplicateManualRuleRow(manualRulesUrl, keycloak.token, null,
        manualRuleAddModalProtocol === "any" ? null : manualRuleAddModalProtocol,
        manualRuleAddModalSrcAddress === "any" ? null : manualRuleAddModalSrcAddress,
        manualRuleAddModalSrcPort === "any" ? null : manualRuleAddModalSrcPort,
        manualRuleAddModalDstAddress === "any" ? null : manualRuleAddModalDstAddress,
        manualRuleAddModalDstPort === "any" ? null : manualRuleAddModalDstPort,
        manualRuleAddModalDirection,
        manualRuleAddModalAction,
        setManualRuleAddModalAddButtonEnabled,
        setManualRuleAddModalValidationStatusMessage
      );
    }
  };

  const performManualRuleEditModalValidation = () => {

    let ruleValid = true;

    // check that src address is valid for the selected ip version
    let srcAddressValidation = isValidIpVersionCidr(manualRuleEditModalIpVersion,
      manualRuleEditModalSrcAddress,
      manualRuleEditModalProtocol);
    setManualRuleEditModalSrcAddressValid(srcAddressValidation.res);
    setManualRuleEditModalSrcAddressErrMessage(srcAddressValidation.msg);
    if (!srcAddressValidation.res) {
      // rule can't be valid ruleValid
      ruleValid = false;
    }

    // check src port
    let srcPortValidation = isValidPortNumber(manualRuleEditModalSrcPort);
    setManualRuleEditModalSrcPortValid(srcPortValidation.res);
    setManualRuleEditModalSrcPortErrMessage(srcPortValidation.msg);
    if (!srcPortValidation.res) {
      // rule can't be valid
      ruleValid = false;
    }

    // check that dst address is valid for the selected ip version
    let dstAddressValidation = isValidIpVersionCidr(manualRuleEditModalIpVersion,
      manualRuleEditModalDstAddress,
      manualRuleEditModalProtocol);
    setManualRuleEditModalDstAddressValid(dstAddressValidation.res);
    setManualRuleEditModalDstAddressErrMessage(dstAddressValidation.msg);
    if (!dstAddressValidation.res) {
      // rule can't be valid ruleValid
      ruleValid = false;
    }

    // check dst port
    let dstPortValidation = isValidPortNumber(manualRuleEditModalDstPort);
    setManualRuleEditModalDstPortValid(dstPortValidation.res);
    setManualRuleEditModalDstPortErrMessage(dstPortValidation.msg);
    if (!dstPortValidation.res) {
      // rule can't be valid
      ruleValid = false;
    }


    if (!ruleValid) {
      setManualRuleEditModalUpdateButtonEnabled(false);
      setManualRuleEditModalValidationStatusMessage("Rule has one or more invalid settings");
    }
    else {

      // let's check if another rule has exactly the same fields set
      // and if so don't mark the rule valid.  The db table has a constraint
      // that would prevent us from inserting a duplicate rule
      checkDuplicateManualRuleRow(manualRulesUrl, keycloak.token, selectedManualRuleId,
        manualRuleEditModalProtocol === "any" ? null : manualRuleEditModalProtocol,
        manualRuleEditModalSrcAddress === "any" ? null : manualRuleEditModalSrcAddress,
        manualRuleEditModalSrcPort === "any" ? null : manualRuleEditModalSrcPort,
        manualRuleEditModalDstAddress === "any" ? null : manualRuleEditModalDstAddress,
        manualRuleEditModalDstPort === "any" ? null : manualRuleEditModalDstPort,
        manualRuleEditModalDirection,
        manualRuleEditModalAction,
        setManualRuleEditModalUpdateButtonEnabled,
        setManualRuleEditModalValidationStatusMessage
      );
    }
  };

  const handleManualRuleAddModalIpVersionChanged = (val) => {
    setManualRuleAddModalIpVersion(val);

    // force user to click validate
    setManualRuleAddModalAddButtonEnabled(false);
  };

  const handleManualRuleEditModalIpVersionChanged = (val) => {
    setManualRuleEditModalIpVersion(val);

    // force user to click validate
    setManualRuleEditModalUpdateButtonEnabled(false);
  };

  const handleManualRuleAddModalProtocolChanged = (val) => {
    setManualRuleAddModalProtocol(val);
    // force user to click validate
    setManualRuleAddModalAddButtonEnabled(false);
  };

  const handleManualRuleEditModalProtocolChanged = (val) => {
    setManualRuleEditModalProtocol(val);
    // force user to click validate
    setManualRuleEditModalUpdateButtonEnabled(false);
  };

  const handleManualRuleAddModalSrcAddressChanged = (val) => {
    setManualRuleAddModalSrcAddress(val);
    // force user to click validate
    setManualRuleAddModalAddButtonEnabled(false);
  };

  const handleManualRuleEditModalSrcAddressChanged = (val) => {
    setManualRuleEditModalSrcAddress(val);
    // force user to click validate
    setManualRuleEditModalUpdateButtonEnabled(false);
  };

  const handleManualRuleAddModalSrcPortChanged = (val) => {
    setManualRuleAddModalSrcPort(val);
    // force user to click validate
    setManualRuleAddModalAddButtonEnabled(false);
  };

  const handleManualRuleEditModalSrcPortChanged = (val) => {
    setManualRuleEditModalSrcPort(val);
    // force user to click validate
    setManualRuleEditModalUpdateButtonEnabled(false);
  };

  const handleManualRuleAddModalDstAddressChanged = (val) => {
    setManualRuleAddModalDstAddress(val);
    // force user to click validate
    setManualRuleAddModalAddButtonEnabled(false);
  };

  const handleManualRuleEditModalDstAddressChanged = (val) => {
    setManualRuleEditModalDstAddress(val);
    // force user to click validate
    setManualRuleEditModalUpdateButtonEnabled(false);
  };

  const handleManualRuleAddModalDstPortChanged = (val) => {
    setManualRuleAddModalDstPort(val);
    // force user to click validate
    setManualRuleAddModalAddButtonEnabled(false);
  };

  const handleManualRuleEditModalDstPortChanged = (val) => {
    setManualRuleEditModalDstPort(val);
    // force user to click validate
    setManualRuleEditModalUpdateButtonEnabled(false);
  };

  const handleManualRuleAddModalBidirectionalChanged = (val) => {
    setManualRuleAddModalBidirectional(val);
    // force user to click validate
    setManualRuleAddModalAddButtonEnabled(false);
  };

  const handleManualRuleEditModalBidirectionalChanged = (val) => {
    setManualRuleEditModalBidirectional(val);
    // force user to click validate
    setManualRuleEditModalUpdateButtonEnabled(false);
  };

  const handleManualRuleAddModalDirectionChanged = (val) => {
    setManualRuleAddModalDirection(val);
    // force user to click validate
    setManualRuleAddModalAddButtonEnabled(false);
  };

  const handleManualRuleEditModalDirectionChanged = (val) => {
    setManualRuleEditModalDirection(val);
    // force user to click validate
    setManualRuleEditModalUpdateButtonEnabled(false);
  };

  const handleManualRuleAddModalActionChanged = (val) => {
    setManualRuleAddModalAction(val);
    // force user to click validate
    setManualRuleAddModalAddButtonEnabled(false);
  };

  const handleManualRuleEditModalActionChanged = (val) => {
    setManualRuleEditModalAction(val);
    // force user to click validate
    setManualRuleEditModalUpdateButtonEnabled(false);
  };

  const handleManualRuleAddModalCommentsChanged = (val) => {
    setManualRuleAddModalComments(val);
    // force user to click validate
    setManualRuleAddModalAddButtonEnabled(false);
  };

  const handleManualRuleEditModalCommentsChanged = (val) => {
    setManualRuleEditModalComments(val);
    // force user to click validate
    setManualRuleEditModalUpdateButtonEnabled(false);
  };

  const updateManualRule = async () => {

    // take care of any fields that need massaging
    let patchSrcAddress = manualRuleEditModalSrcAddress;
    if (manualRuleEditModalSrcAddress === null ||
      manualRuleEditModalSrcAddress === "" ||
      manualRuleEditModalSrcAddress === "any") {
      // this counts as any, so so null
      patchSrcAddress = null;
    }

    let patchSrcPort = manualRuleEditModalSrcPort;
    if (manualRuleEditModalSrcPort === null ||
      manualRuleEditModalSrcPort === "" ||
      manualRuleEditModalSrcPort === "any") {
      // this counts as any, so so null
      patchSrcPort = null;
    }

    let patchDstAddress = manualRuleEditModalDstAddress;
    if (manualRuleEditModalDstAddress === null ||
      manualRuleEditModalDstAddress === "" ||
      manualRuleEditModalDstAddress === "any") {
      // this counts as any, so so null
      patchDstAddress = null;
    }

    let patchDstPort = manualRuleEditModalDstPort;
    if (manualRuleEditModalDstPort === null ||
      manualRuleEditModalDstPort === "" ||
      manualRuleEditModalDstPort === "any") {
      // this counts as any, so so null
      patchDstPort = null;
    }

    let patchData = {
      id: selectedManualRuleId,
      protocol: manualRuleEditModalProtocol,
      direction: manualRuleEditModalDirection,
      bidirectional: manualRuleEditModalBidirectional,
      action: manualRuleEditModalAction,
      source_address: patchSrcAddress,
      source_port: patchSrcPort,
      destination_address: patchDstAddress,
      destination_port: patchDstPort,
      comments: manualRuleEditModalComments
    };

    await fetch(manualRulesUrl
      + selectedManualRuleId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setManualRuleEditModalIpVersion(respData['ip_version']);
        setManualRuleEditModalProtocol(respData['protocol']);
        setManualRuleEditModalSrcAddress(coalesce(respData['source_address'], "any"));
        setManualRuleEditModalSrcPort(coalesce(respData['source_port'], "any"));
        setManualRuleEditModalDstAddress(coalesce(respData['destination_address'], "any"));
        setManualRuleEditModalDstPort(coalesce(respData['destination_port'], "any"));
        setManualRuleEditModalBidirectional(respData['bidirectional']);
        setManualRuleEditModalDirection(respData['direction']);
        setManualRuleEditModalAction(respData['action']);
        setManualRuleEditModalComments(respData['comments']);
        setManualRuleEditModalCreated(respData['created']);
        setManualRuleEditModalValidationValid(true);
        setManualRuleEditModalValidationStatusMessage("Rule is valid");

        fetchManualRulesData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleManualRuleAddModalAddButtonClicked = (val) => {
    addManualRule();
    handleManualRuleAddModalClose();
  };

  const handleManualRuleEditModalUpdateButtonClicked = (val) => {
    updateManualRule();
    setManualRuleEditModalOpen(false);
    handleManualRuleEditModalClose();
    handleAntispoofingSelected(0);
  };

  const handleAntispoofingSelected = (id) => {
    setSelectedAntispoofingId(id);
    if (id !== 0) {
      setEditAntispoofingButtonEnabled(true);
      setDeleteAntispoofingButtonEnabled(true);
    }
    else {
      setEditAntispoofingButtonEnabled(false);
      setDeleteAntispoofingButtonEnabled(false);
    }
  };

  const fetchAntispoofingModalData = async () => {
    await fetch(antispoofingUrl
      + selectedAntispoofingId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        // example webservice response
        //   {
        //     "id": 2,
        //     "subnet": "7.0.0.0/8",
        //     "comments": "This is my comment",
        //     "created": "2022-12-02T14:16:04.779121Z",
        //     "is_allowed": false
        // }

        setAntispoofingEditModalSubnet(respData['subnet']);
        setAntispoofingEditModalSubnetValid(true);
        setAntispoofingEditModalSubnetErrMessage("");
        setAntispoofingEditModalComments(respData['comments']);
        setAntispoofingEditModalValidationValid(true);
        setAntispoofingEditModalValidationStatusMessage("Valid");
        setAntispoofingEditModalUpdateButtonEnabled(false);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  // create a new rule that can then be edited.
  const addAntispoofingSubnet = async () => {
    let postData = {
      subnet: antispoofingAddModalSubnet,
      comments: antispoofingAddModalComments,
      // this will always be false
      is_allowed: false
    };

    await fetch(antispoofingUrl, {
      method: 'POST',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        fetchAntispoofingData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const launchAddAntispoofingRuleModal = () => {
    setAntispoofingAddModalSubnet('');
    setAntispoofingAddModalSubnetValid(false);
    setAntispoofingAddModalSubnetErrMessage("CIDR subnet invalid");
    setAntispoofingAddModalComments("Newly added Subnet");
    setAntispoofingAddModalValidationValid(false);
    setAntispoofingAddModalValidationStatusMessage("Antispoofing subnet is invalid");
    setAntispoofingAddModalAddButtonEnabled(false);
    setAntispoofingAddModalOpen(true);
  };

  const handleAddAntispoofingButtonClick = (event) => {
    launchAddAntispoofingRuleModal();
  };

  const handleEditAntispoofingButtonClick = (event) => {
    fetchAntispoofingModalData();
    setAntispoofingEditModalOpen(true);
  };

  const deleteAntispoofingRule = async (refreshCallBack) => {
    await fetch(antispoofingUrl
      + selectedAntispoofingId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        refreshCallBack();
      });
  };

  const handleAddAntispoofingDeleteButtonClick = (refreshCallBack) => {
    deleteAntispoofingRule(refreshCallBack);
  };

  const handleAntispoofingAddModalClose = () => {
    setAntispoofingAddModalOpen(false);
    handleAntispoofingSelected(0);
  };

  const handleAntispoofingEditModalClose = () => {
    setAntispoofingEditModalOpen(false);
    handleAntispoofingSelected(0);
  };

  const handleAntiSpoofingAddModalSubnetChanged = (val) => {
    setAntispoofingAddModalSubnet(val);
    // validate that the entered value is a valid cidr block

    // try validating as an IPv4 cidr block first
    let ipVer = '4';
    let validationResult = isValidIpVersionCidr(ipVer, val);

    if (validationResult.res) {
      // is valid v4 cidr block
      setAntispoofingAddModalSubnetValid(true);
      setAntispoofingAddModalSubnetErrMessage('');
    }
    else {
      // try as v6
      ipVer = '6';
      validationResult = isValidIpVersionCidr(ipVer, val);

      if (validationResult.res) {
        // is valid v6 cidr block
        setAntispoofingAddModalSubnetValid(true);
        setAntispoofingAddModalSubnetErrMessage('');
      }
      else {
        // not valid v4 or v6 cidr block
        setAntispoofingAddModalSubnetValid(validationResult.res);
        setAntispoofingAddModalSubnetErrMessage(validationResult.msg);
      }
    }

    // force user to click validate
    setAntispoofingAddModalAddButtonEnabled(false);
  };

  const handleAntiSpoofingEditModalSubnetChanged = (val) => {
    setAntispoofingEditModalSubnet(val);

    // validate that the entered value is a valid cidr block

    // try validating as an IPv4 cidr block first
    let ipVer = '4';
    let validationResult = isValidIpVersionCidr(ipVer, val);

    if (validationResult.res) {
      // is valid v4 cidr block
      setAntispoofingEditModalSubnetValid(true);
      setAntispoofingEditModalSubnetErrMessage('');
    }
    else {
      // try as v6
      ipVer = '6';
      validationResult = isValidIpVersionCidr(ipVer, val);

      if (validationResult.res) {
        // is valid v6 cidr block
        setAntispoofingEditModalSubnetValid(true);
        setAntispoofingEditModalSubnetErrMessage('');
      }
      else {
        // not valid v4 or v6 cidr block
        setAntispoofingEditModalSubnetValid(validationResult.res);
        setAntispoofingEditModalSubnetErrMessage(validationResult.msg);
      }
    }

    // force user to click validate
    setAntispoofingEditModalUpdateButtonEnabled(false);
  };

  const handleAntiSpoofingAddModalCommentsChanged = (val) => {
    setAntispoofingAddModalComments(val);
    // force user to click validate
    setAntispoofingAddModalAddButtonEnabled(false);
  };

  const handleAntiSpoofingEditModalCommentsChanged = (val) => {
    setAntispoofingEditModalComments(val);
    // force user to click validate
    setAntispoofingEditModalUpdateButtonEnabled(false);
  };

  const updateAntispoofingSubnet = async () => {
    let patchData = {
      id: selectedAntispoofingId,
      comments: antispoofingEditModalComments,
      subnet: antispoofingEditModalSubnet
    };

    await fetch(antispoofingUrl
      + selectedAntispoofingId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setAntispoofingEditModalComments(respData['comments']);
        setAntispoofingEditModalSubnet(respData['subnet']);
        setAntispoofingEditModalSubnetValid(true);
        setAntispoofingEditModalSubnetErrMessage("");
        setAntispoofingEditModalValidationStatusMessage("Valid");
        setAntispoofingEditModalUpdateButtonEnabled(true);

        fetchAntispoofingData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleAntiSpoofingAddModalAddButtonClicked = (event) => {
    addAntispoofingSubnet();
    handleAntispoofingAddModalClose();
  };

  const handleAntiSpoofingEditModalUpdateButtonClicked = (event) => {
    updateAntispoofingSubnet();
    handleAntispoofingEditModalClose();
  };

  const performAntispoofingAddModalValidation = () => {
    // the only real validation to do is to ensure the subnet is a valid v4 or v6 subnet
    let valid = true;

    // try first as IPv4
    let validationResult = isValidIpVersionCidr("4", antispoofingAddModalSubnet);
    if (!validationResult.res) {
      // not IPv4, try IPv6
      validationResult = isValidIpVersionCidr("6", antispoofingAddModalSubnet);
      if (!validationResult.res) {
        // not IPv6 either
        valid = false;
      }
    }

    if (!valid) {
      setAntispoofingAddModalSubnetValid(false);
      setAntispoofingAddModalSubnetErrMessage("Entered value is not a valid IPv4 or IPv6 subnet");
      setAntispoofingAddModalValidationValid(false);
      setAntispoofingAddModalValidationStatusMessage("Invalid");
      setAntispoofingAddModalAddButtonEnabled(false);
    }
    else {
      setAntispoofingAddModalSubnetValid(true);
      setAntispoofingAddModalSubnetErrMessage("");
      setAntispoofingAddModalValidationValid(true);
      setAntispoofingAddModalValidationStatusMessage("Valid");
      setAntispoofingAddModalAddButtonEnabled(true);
    }
  };

  const performAntispoofingEditModalValidation = () => {
    // the only real validation to do is to ensure the subnet is a valid v4 or v6 subnet
    let valid = true;

    // try first as IPv4
    let validationResult = isValidIpVersionCidr("4", antispoofingEditModalSubnet);
    if (!validationResult.res) {
      // not IPv4, try IPv6
      validationResult = isValidIpVersionCidr("6", antispoofingEditModalSubnet);
      if (!validationResult.res) {
        // not IPv6 either
        valid = false;
      }
    }

    if (!valid) {
      setAntispoofingEditModalSubnetValid(false);
      setAntispoofingEditModalSubnetErrMessage("Entered value is not a valid IPv4 or IPv6 subnet");
      setAntispoofingEditModalValidationValid(false);
      setAntispoofingEditModalValidationStatusMessage("Invalid");
      setAntispoofingEditModalUpdateButtonEnabled(false);
    }
    else {
      setAntispoofingEditModalSubnetValid(true);
      setAntispoofingEditModalSubnetErrMessage("");
      setAntispoofingEditModalValidationValid(true);
      setAntispoofingEditModalValidationStatusMessage("Valid");
      setAntispoofingEditModalUpdateButtonEnabled(true);
    }
  };

  const getManualRulesSearchTerm = () => {
    return freshManualRulesSearchTerm;
  };

  const getSanitizedManualRulesSearchFieldContent = () => {
    let ret = getManualRulesSearchTerm();
    // TODO: we might need to url-encode the search term here
    return ret;
  };

  const fetchManualRulesData = async () => {
    // todo, do URLencoding on this to avoid any wonky input
    const sanitizedSearchTerm = getSanitizedManualRulesSearchFieldContent();
    const orderPrefix = (manualRulesOrder === 'desc' ? '-' : '');
    const ipFilter = searchMrVersion;

    let mrUrl = manualRulesUrl;
    if (ipFilter === 'Both') {
      mrUrl = manualRulesUrl;
    } else if (ipFilter === 'IPv4') {
      mrUrl = mrV4Url;
    } else if (ipFilter === 'IPv6') {
      mrUrl = mrV6Url;
    }

    await fetch(mrUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + manualRulesOffset
      + '&page=' + (manualRulesPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + orderPrefix + manualRulesOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setManualRulesTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createManualRulesRowData(
            entry['id'],
            entry['ip_version'],
            entry['protocol'],
            entry['source_address'],
            entry['source_port'],
            entry['destination_address'],
            entry['destination_port'],
            entry['bidirectional'],
            entry['direction'],
            entry['action'],
            entry['created']));
        }

        setManualRulesSelected([]);
        setManualRulesRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const getAntispoofingSearchTerm = () => {
    return freshAntispoofingRulesSearchTerm;
  };

  const getSanitizedAntispoofingSearchFieldContent = () => {
    let ret = getAntispoofingSearchTerm();
    // TODO: we might need to url-encode the search term here
    return ret;
  };

  const fetchAntispoofingData = () => {
    // todo, do URLencoding on this to avoid any wonky input
    const sanitizedSearchTerm = getSanitizedAntispoofingSearchFieldContent();
    const orderPrefix = (antispoofingOrder === 'desc' ? '-' : '');
    const ipFilter = searchAntispoofingVersion;

    let antispoofUrl = antispoofingUrl;
    if (ipFilter === 'Both') {
      antispoofUrl = antispoofingUrl;
    } else if (ipFilter === 'IPv4') {
      antispoofUrl = antispoofingV4Url;
    } else if (ipFilter === 'IPv6') {
      antispoofUrl = antispoofingV6Url;
    }

    fetch(antispoofUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + antispoofingOffset
      + '&page=' + (antispoofingPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + orderPrefix + antispoofingOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setAntispoofingTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createAntiSpoofingRowData(entry['id'], entry['subnet'],
            entry['comments'],
            entry['is_allowed']));
        }

        setAntispoofingSelected([]);
        setAntispoofingRows(tmpRows);
        handleAntispoofingSelected(0);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };


  const handleManualRulesSearchTermChanged = (val) => {
    setManualRulesSearchTerm(val);
    freshManualRulesSearchTerm = val;
  };

  const handleAntispoofingRulesSearchTermChanged = (val) => {
    setAntispoofingSearchTerm(val)
    freshAntispoofingRulesSearchTerm = val;
  };

  const handleManualRulesRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setManualRulesRefreshNotificationOpen(false);
  };

  const displayManualRulesRefreshStarted = (event) => {
    setManualRulesRefreshNotificationOpen(true);
  };

  const handleManualRulesRefreshButtonClick = (event) => {
    fetchManualRulesData();
    displayManualRulesRefreshStarted();
  };

  const handleAntispoofingRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAntispoofingRefreshNotificationOpen(false);
  };

  const displayAntispoofingRefreshStarted = (event) => {
    setAntispoofingRefreshNotificationOpen(true);
  };

  const handleAntispoofingRefreshButtonClick = (event) => {
    fetchAntispoofingData();
    displayAntispoofingRefreshStarted();
  };

  const getManualRulesOrderBy = () => {
    return manualRulesOrderBy;
  }

  const getManualRulesOrder = () => {
    return manualRulesOrder;
  }

  const getAntispoofingOrderBy = () => {
    return antispoofingOrderBy;
  }

  const getAntispoofingOrder = () => {
    return antispoofingOrder;
  }

  return (
    <Box sx={{ width: '100%' }} id="manual-rules-top-level-box">
      <Snackbar
        open={manualRulesRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleManualRulesRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {manualRulesRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={fetchErrorMessageOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleFetchErrorClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {fetchErrorMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={antispoofingRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleAntispoofingRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {antispoofingRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <AddManualRuleDialog
        open={manualRuleAddModalOpen}
        onClose={handleManualRuleAddModalClose}
        ipVersion={manualRuleAddModalIpVersion}
        ipVersionSetter={handleManualRuleAddModalIpVersionChanged}
        protocol={manualRuleAddModalProtocol}
        protocolSetter={handleManualRuleAddModalProtocolChanged}
        srcAddress={manualRuleAddModalSrcAddress}
        srcAddressSetter={handleManualRuleAddModalSrcAddressChanged}
        srcAddressValid={manualRuleAddModalSrcAddressValid}
        srcAddressErrorMessage={manualRuleAddModalSrcAdressErrMessage}
        srcPort={manualRuleAddModalSrcPort}
        srcPortSetter={handleManualRuleAddModalSrcPortChanged}
        srcPortValid={manualRuleAddModalSrcPortValid}
        srcPortErrorMessage={manualRuleAddModalSrcPortErrMessage}
        dstAddress={manualRuleAddModalDstAddress}
        dstAddressSetter={handleManualRuleAddModalDstAddressChanged}
        dstAddressValid={manualRuleAddModalDstAddressValid}
        dstAddressErrorMessage={manualRuleAddModalDstAdressErrMessage}
        dstPort={manualRuleAddModalDstPort}
        dstPortSetter={handleManualRuleAddModalDstPortChanged}
        dstPortValid={manualRuleAddModalDstPortValid}
        dstPortErrorMessage={manualRuleAddModalDstPortErrMessage}
        bidirectional={manualRuleAddModalBidirectional}
        bidirectionalSetter={handleManualRuleAddModalBidirectionalChanged}
        direction={manualRuleAddModalDirection}
        directionSetter={handleManualRuleAddModalDirectionChanged}
        action={manualRuleAddModalAction}
        actionSetter={handleManualRuleAddModalActionChanged}
        comments={manualRuleAddModalComments}
        commentsSetter={handleManualRuleAddModalCommentsChanged}
        addButtonEnabled={manualRuleAddModalAddButtonEnabled}
        addButtonClickHandler={handleManualRuleAddModalAddButtonClicked}
        ruleValidationValid={manualRuleAddModalValidationValid}
        ruleValidationStatusMessage={manualRuleAddModalValidationStatusMessage}
        performValidation={performManualRuleAddModalValidation}
      />
      <EditManualRuleDialog
        open={manualRuleEditModalOpen}
        onClose={handleManualRuleEditModalClose}
        manualRuleId={selectedManualRuleId}
        ipVersion={manualRuleEditModalIpVersion}
        ipVersionSetter={handleManualRuleEditModalIpVersionChanged}
        protocol={manualRuleEditModalProtocol}
        protocolSetter={handleManualRuleEditModalProtocolChanged}
        srcAddress={manualRuleEditModalSrcAddress}
        srcAddressSetter={handleManualRuleEditModalSrcAddressChanged}
        srcAddressValid={manualRuleEditModalSrcAddressValid}
        srcAddressErrorMessage={manualRuleEditModalSrcAdressErrMessage}
        srcPort={manualRuleEditModalSrcPort}
        srcPortSetter={handleManualRuleEditModalSrcPortChanged}
        srcPortValid={manualRuleEditModalSrcPortValid}
        srcPortErrorMessage={manualRuleEditModalSrcPortErrMessage}
        dstAddress={manualRuleEditModalDstAddress}
        dstAddressSetter={handleManualRuleEditModalDstAddressChanged}
        dstAddressValid={manualRuleEditModalDstAddressValid}
        dstAddressErrorMessage={manualRuleEditModalDstAdressErrMessage}
        dstPort={manualRuleEditModalDstPort}
        dstPortSetter={handleManualRuleEditModalDstPortChanged}
        dstPortValid={manualRuleEditModalDstPortValid}
        dstPortErrorMessage={manualRuleEditModalDstPortErrMessage}
        bidirectional={manualRuleEditModalBidirectional}
        bidirectionalSetter={handleManualRuleEditModalBidirectionalChanged}
        direction={manualRuleEditModalDirection}
        directionSetter={handleManualRuleEditModalDirectionChanged}
        action={manualRuleEditModalAction}
        actionSetter={handleManualRuleEditModalActionChanged}
        comments={manualRuleEditModalComments}
        commentsSetter={handleManualRuleEditModalCommentsChanged}
        created={manualRuleEditModalCreated}
        updateButtonEnabled={manualRuleEditModalUpdateButtonEnabled}
        updateButtonClickHandler={handleManualRuleEditModalUpdateButtonClicked}
        ruleValidationValid={manualRuleEditModalValidationValid}
        ruleValidationStatusMessage={manualRuleEditModalValidationStatusMessage}
        performValidation={performManualRuleEditModalValidation}
      />
      <AddAntispoofingDialog
        open={antispoofingAddModalOpen}
        onClose={handleAntispoofingAddModalClose}
        subnet={antispoofingAddModalSubnet}
        subnetSetter={handleAntiSpoofingAddModalSubnetChanged}
        subnetValid={antispoofingAddModalSubnetValid}
        subnetErrorMessage={antispoofingAddModalSubnetErrMessage}
        comments={antispoofingAddModalComments}
        commentsSetter={handleAntiSpoofingAddModalCommentsChanged}
        addButtonEnabled={antispoofingAddModalAddButtonEnabled}
        addButtonClickHandler={handleAntiSpoofingAddModalAddButtonClicked}
        ruleValidationValid={antispoofingAddModalValidationValid}
        ruleValidationStatusMessage={antispoofingAddModalValidationStatusMessage}
        performValidation={performAntispoofingAddModalValidation}
      />
      <EditAntispoofingDialog
        open={antispoofingEditModalOpen}
        onClose={handleAntispoofingEditModalClose}
        subnet={antispoofingEditModalSubnet}
        subnetSetter={handleAntiSpoofingEditModalSubnetChanged}
        subnetValid={antispoofingEditModalSubnetValid}
        subnetErrorMessage={antispoofingEditModalSubnetErrMessage}
        comments={antispoofingEditModalComments}
        commentsSetter={handleAntiSpoofingEditModalCommentsChanged}
        updateButtonEnabled={antispoofingEditModalUpdateButtonEnabled}
        updateButtonClickHandler={handleAntiSpoofingEditModalUpdateButtonClicked}
        ruleValidationValid={antispoofingEditModalValidationValid}
        ruleValidationStatusMessage={antispoofingEditModalValidationStatusMessage}
        performValidation={performAntispoofingEditModalValidation}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Manual Rules Tab Panel">
          <Tab label="Manual Rules" {...a11yProps(0)} />
          <Tab label="Antispoofing Subnets" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        {hasManualRulesRead === true ? (<ManualRulesTab
          handleRowSelected={handleManualRuleSelected}
          addButtonEnabled={addManualRuleButtonEnabled}
          addButtonClickHandler={handleAddManualRuleButtonClick}
          editButtonEnabled={editManualRuleButtonEnabled}
          editButtonClickHandler={handleEditManualRuleButtonClick}
          deleteButtonEnabled={deleteManualRuleButtonEnabled}
          deleteButtonClickHandler={handleDeleteManualRuleButtonClick}
          order={getManualRulesOrder}
          orderSetter={setManualRulesOrder}
          orderBy={getManualRulesOrderBy}
          orderBySetter={setManualRulesOrderBy}
          selected={manualRulesSelected}
          selectedSetter={setManualRulesSelected}
          offset={manualRulesOffset}
          offsetSetter={setManualRulesOffset}
          page={manualRulesPage}
          pageSetter={setManualRulesPage}
          rows={manualRulesRows}
          total={manualRulesTotal}
          totalSetter={setManualRulesTotal}
          searchTerm={manualRulesSearchTerm}
          searchTermSetter={handleManualRulesSearchTermChanged}
          rowsPerPage={rowsPerPage}
          fetchData={fetchManualRulesData}
          refreshButtonClickHandler={handleManualRulesRefreshButtonClick}
          searchMrVersionSetter={handleMrVersionTermChanged}
          defaultMrSearchOption={defaultMrSearchOption}
          searchMrVersion={searchMrVersion}
          mrSearchOptions={mrSearchOptions}
        />) : (<p />)}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {hasAntispoofingRead === true ? (<AntispoofTab
          rootFetchUrl={antispoofingUrl}
          handleRowSelected={handleAntispoofingSelected}
          addButtonEnabled={addAntispoofingButtonEnabled}
          addButtonClickHandler={handleAddAntispoofingButtonClick}
          editButtonEnabled={editAntispoofingButtonEnabled}
          editButtonClickHandler={handleEditAntispoofingButtonClick}
          deleteButtonEnabled={deleteAntispoofingButtonEnabled}
          deleteButtonClickHandler={handleAddAntispoofingDeleteButtonClick}
          order={getAntispoofingOrder}
          orderSetter={setAntispoofingOrder}
          orderBy={getAntispoofingOrderBy}
          orderBySetter={setAntispoofingOrderBy}
          selected={antispoofingSelected}
          selectedSetter={setAntispoofingSelected}
          offset={antispoofingOffset}
          offsetSetter={setAntispoofingOffset}
          page={antispoofingPage}
          pageSetter={setAntispoofingPage}
          rows={antispoofingRows}
          total={antispoofingTotal}
          totalSetter={setAntispoofingTotal}
          searchTerm={antispoofingSearchTerm}
          searchTermSetter={handleAntispoofingRulesSearchTermChanged}
          rowsPerPage={rowsPerPage}
          fetchData={fetchAntispoofingData}
          refreshButtonClickHandler={handleAntispoofingRefreshButtonClick}
          antispoofingSearchOptions={antispoofingSearchOptions}
          defaultAntispoofingSearchOption={defaultAntispoofingSearchOption}
          searchAntispoofingVersion={searchAntispoofingVersion}
          searchAntispoofingVersionSetter={handleAntispoofingVersionTermChanged}
        />) : (<div />)}
      </TabPanel>
    </Box>
  );
}