/**
 * Validate that the input is a number suitable to be used as an ASN
 * 
 * @param {*} input 
 */
export function isValidAsnValue(input) {
    let valid = true;
    let msg = "";
    let ret = { valid: valid, msg: msg };

    const min = 1;
    const max = 2147483647; // this is the max size of the postgresql integer data type, which is a signed 32 bit number

    if (input === null) {
        // null input is not at all valid
        ret.valid = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.valid = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }

    if (isNaN(input)) {
        // not a number, so not valid
        ret.valid = false;
        ret.msg = "input must be a valid number";
        return ret;
    }
    else {
        // we have a number, validate it's within a reasonable range
        let parsedNum = parseInt(trimmedInput);

        if (parsedNum < min || parsedNum > max) {
            // outside our allowed range
            ret.valid = false;
            ret.msg = "Number is outside allowed range " + min + "-" + max;
            return ret;
        }
    }
    return ret;
}