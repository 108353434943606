import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

export default function EditIntervalDialog(props) {
    const { onClose,
        open,
        every,
        everySetter,
        intervalModalEveryValid,
        intervalModalEveryErrorMsg,
        period,
        periodSetter,
        intervalModalPeriodValid,
        intervalModalPeriodErrorMsg,
        addButtonEnabled,
        onAddButtonClick,
        onValidateButtonClick,
        validationMessage } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleEveryContentChange = (event) => {
        everySetter(event.target.value);
    };

    const handlePeriodContentChange = (event) => {
        periodSetter(event.target.value);
    };

    const readonlyContentHandler = (event) => {
        // do nothing here we don't want to allow them to change it
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Add Interval</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        id="add-interval-tab-modal-dialog-every-text-field"
                        {...(intervalModalEveryValid !== true && { error: undefined })}
                        helperText={intervalModalEveryErrorMsg}
                        label="Every"
                        variant="outlined"
                        size="medium"
                        width="480px"
                        value={every}
                        onChange={handleEveryContentChange}
                        style={{marginTop: '15px'}}
                    />
                    <TextField
                        id="add-interval-tab-modal-dialog-period-text-field"
                        {...(intervalModalPeriodValid !== true && { error: undefined })}
                        helperText={intervalModalPeriodErrorMsg}
                        label="Period"
                        variant="outlined"
                        size="medium"
                        value={period}
                        onChange={handlePeriodContentChange}
                    />
                    <TextField
                        id="add-interval-tab-modal-dialog-validation-message-text-field"
                        label="Validation Status"
                        variant="outlined"
                        size="medium"
                        value={validationMessage}
                        onChange={readonlyContentHandler}
                        multiline
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button
                            id="add-interval-tab-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button
                            id="add-interval-tab-modal-dialog-validate-button"
                            variant="contained"
                            onClick={onValidateButtonClick}>Validate</Button>
                        <Button
                            id="add-interval-tab-modal-dialog-add-button"
                            variant="contained"
                            onClick={onAddButtonClick}
                            disabled={!addButtonEnabled}
                        >Add</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
