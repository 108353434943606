import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export default function EditLicenseKeyDialog(props) {
    const { selectedLicenseName, onClose, open,
        licenseKeyTextFieldChangeListener, updateButtonEnabled, licenseKeyTextFieldContent,
        updatedTextFieldContent, updateButtonClickHandler,
        verifyButtonEnabled, verificationResultText,
        verifyButtonClickHandler
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleUpdateButtonClick = (event) => {
        updateButtonClickHandler(event);
    };

    const handleVerifyButtonClick = (event) => {
        verifyButtonClickHandler(event);
    };

    const readOnlyTextFieldHandler = (event) => {
        // do nothing so the field isn't changed
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{selectedLicenseName} license key</DialogTitle>
            <div class="modal-dialog-content-div">
                <p>Details for {selectedLicenseName} license key</p>
                <Stack spacing={2} direction="column">
                    <TextField
                        label="License Key"
                        variant="outlined"
                        size="medium"
                        value={licenseKeyTextFieldContent}
                        width="512px"
                        onChange={licenseKeyTextFieldChangeListener}
                        style={{marginTop: '15px'}}
                    />
                    <TextField
                        label="Last Updated"
                        variant="outlined"
                        size="medium"
                        value={updatedTextFieldContent}
                        onChange={readOnlyTextFieldHandler}
                    />
                    <TextField
                        label="Verification Result"
                        variant="outlined"
                        size="medium"
                        value={verificationResultText}
                        onChange={readOnlyTextFieldHandler}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="license-keys-table-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="license-keys-table-modal-dialog-update-button"
                            variant="contained"
                            onClick={handleUpdateButtonClick}
                            disabled={!updateButtonEnabled}>Update</Button>
                        <Button id="license-keys-table-modal-dialog-verify-button"
                            variant="contained"
                            onClick={handleVerifyButtonClick}
                            disabled={!verifyButtonEnabled}>Verify</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
