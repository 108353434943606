import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useKeycloak } from '@react-keycloak/web';
import { getContentDispositionFilename } from '../../../common/utility/DataUtil';
import ContentUploadTab from './ContentUploadTab';
import "./ContentUpload.css";
import ErrorMessageDialog from './modals/ErrorMessagesDialog';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ChangeMessageDialog from './modals/ChangeMessagesDialog';
import { fetchErrorMessage } from '../../mainpage';


const downloadUrl = '/middleware/api/content_download/';
const uploadUrl = '/middleware/api/content_upload/';
const changeUrl = '/middleware/api/content_upload/check/';


/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 3000;

function SimpleBackdrop(props) {

    const { open } = props;

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `content-upload-tab-${index}`,
        'aria-controls': `content-upload-tabpanel-${index}`,
    };
}

function createErrorRowData(sheet, error) {
    return {
        sheet,
        error
    };
}

function createChangeRowData(sheet, action, item) {
    return {
        sheet,
        action,
        item
    };
}

export default function ContentUploadComponent() {
    const { keycloak } = useKeycloak();
    const [tabIndex,] = useState(0);

    // snackbar state vars
    const [snackState,] = React.useState({
        snackbarVertical: 'bottom',
        snackbarHorizontal: 'center',
    });

    const { snackbarHorizontal, snackbarVertical } = snackState;

    const [contentUploadNotificationOpen, setContentUploadNotificationOpen] = useState(false);
    const contentUploadNotificationMessage = "Content File is being uploaded. This might take some time.";

    const [contentChangeNotificationOpen, setContentChangeNotificationOpen] = useState(false);
    const contentChangeNotificationMessage = "Content File is being checked for changes. This might take some time.";

    const [contentUploadCompleteNotificationOpen, setContentUploadCompleteNotificationOpen] = useState(false);
    const contentUploadCompleteNotificationMessage = "Content Upload is complete.";

    const [contentChangeCompleteNotificationOpen, setContentChangeCompleteNotificationOpen] = useState(false);
    const contentChangeCompleteNotificationMessage = "Content Change Check is complete.";
    const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
    const handleFetchErrorClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFetchErrorMessageOpen(false);
    };

    const [backdropOpen, setBackdropOpen] = useState(false);

    //state vars
    const [contentUploadUploadButtonEnabled, setContentUploadUploadButtonEnabled] = useState(false);
    const [contentUploadSelectedUploadFile, setContentUploadSelectedUploadFile] = useState(null);
    // error tab state vars
    const [errorOffset, setErrorOffset] = useState(0);
    const [errorPage, setErrorPage] = useState(0);
    const [errorRows, setErrorRows] = useState([]);
    const [errorTotal, setErrorTotal] = useState(0);
    const [errorModalOpen, setErrorModalOpen] = useState(false);

    //Change modal state vars
    const [changeOffset, setChangeOffset] = useState(0);
    const [changePage, setChangePage] = useState(0);
    const [changeRows, setChangeRows] = useState([]);
    const [changeTotal, setChangeTotal] = useState(0);
    const [changeModalOpen, setChangeModalOpen] = useState(false);


    const rowsPerPage = 10;

    const displayContentUploadStarted = (event) => {
        setContentUploadNotificationOpen(true);
    };

    const displayContentChangeStarted = (event) => {
        setContentChangeNotificationOpen(true);
    };

    const displayContentUploadCompleted = (event) => {
        // Display Errors
        //if errors set modal data and open
        //else display completed message
        // console.log(errorRows)
        setBackdropOpen(false);
        if (errorRows.length > 0) {
            setErrorModalOpen(true)
        }

    };

    const displayContentChangeCompleted = (event) => {
        // Display Changes
        // if changes set modal data and open
        // else display completed message
        // console.log(changeRows)
        setBackdropOpen(false);
        if (changeRows.length > 0) {
            setChangeModalOpen(true)
        }

    };

    useEffect(() => {
        displayContentUploadCompleted();
    }, [errorRows]);

    useEffect(() => {
        displayContentChangeCompleted();
    }, [changeRows]);


    const handleUploadFileSubmit = (formData) => {
        setBackdropOpen(true);
        displayContentUploadStarted();
        fetch(uploadUrl, {
            method: 'POST',
            headers: {
                'access-token': keycloak.token
                // 'Content-Type': "multipart/form-data"
            },
            body: formData
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.json();
        })
            .then((respData) => {
                let tmpRows = [];
                for (let i = 0; i < respData.length; i++) {
                    let entry = respData[i];
                    tmpRows.push(createErrorRowData(
                        entry['sheet'], entry['error']));
                }
                setErrorRows(tmpRows)
                setErrorTotal(tmpRows.length)
                if (tmpRows.length < 1) {
                    setContentUploadCompleteNotificationOpen(true);
                }



            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    };

    const handleChangeRulesFileButtonClick = (formData) => {
        setBackdropOpen(true);
        displayContentChangeStarted();
        fetch(changeUrl, {
            method: 'POST',
            headers: {
                'access-token': keycloak.token,
                // 'Content-Type': "multipart/form-data"
            },
            body: formData
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.json();
        })
            .then((respData) => {
                let tmpRows = [];
                let is_error = false;
                for (let i = 0; i < respData.length; i++) {
                    let entry = respData[i];
                    if ("error" in entry) {
                        tmpRows.push(createErrorRowData(
                            entry['sheet'], entry['error']));
                        is_error = true;
                    } else {
                        tmpRows.push(createChangeRowData(
                            entry['sheet'], entry['action'], entry['item']));
                    }
                }
                if (is_error) {
                    setErrorRows(tmpRows)
                    setErrorTotal(tmpRows.length)
                } else {
                    setChangeRows(tmpRows)
                    setChangeTotal(tmpRows.length)
                }
                if (tmpRows.length < 1) {
                    setContentChangeCompleteNotificationOpen(true);
                }
            }).catch((error) => {
                console.log('error: ' + error);
                setFetchErrorMessageOpen(true);
            });
    };

    const downloadContentFile = async () => {
        await fetch(downloadUrl, {
            method: 'GET',
            headers: {
                'access-token': keycloak.token
            },
        })
            .then(response => {
                response.blob().then(blob => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values

                    let cdHeaderVal = response.headers.get('Content-Disposition');
                    let filename = getContentDispositionFilename(cdHeaderVal, 'logi_combined_content.xlsx');
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.target = "_blank";
                    alink.download = filename;
                    alink.click();
                })
            });
    };

    const handleDownloadRulesFileButtonClick = (event) => {
        // ask the server to download the rules as xlContent();
        downloadContentFile();
    };


    const getErrorOrderBy = () => {
    };

    const getErrorOrder = () => {
    };

    const handleErrorAddModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleAddErrorModalCancel = () => {
        setErrorModalOpen(false);
    };

    const handleErrorMessageModalDownload = () => {
    };

    const getChangeOrderBy = () => {
    };

    const getChangeOrder = () => {
    };

    const handleChangeAddModalClose = () => {
        setChangeModalOpen(false);
    };

    const handleAddChangeModalCancel = () => {
        setChangeModalOpen(false);
    };

    const handleChangeMessageModalDownload = () => {
    };


    return (
        <Box sx={{ width: '100%' }} id="content-upload-top-level-box">
            <Snackbar
                open={contentUploadNotificationOpen}
                autoHideDuration={notificationAutoclose}
                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
                onClose={() => setContentUploadNotificationOpen(false)}
            >
                <MuiAlert
                    className="logi-snackbar-notification-message"
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {contentUploadNotificationMessage}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={fetchErrorMessageOpen}
                autoHideDuration={notificationAutoclose}
                onClose={handleFetchErrorClosed}
                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
            >
                <MuiAlert
                    className="logi-snackbar-notification-message"
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {fetchErrorMessage}
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={contentChangeNotificationOpen}
                autoHideDuration={notificationAutoclose}
                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
                onClose={() => setContentChangeNotificationOpen(false)}
            >
                <MuiAlert
                    className="logi-snackbar-notification-message"
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {contentChangeNotificationMessage}
                </MuiAlert>
            </Snackbar>

            <Snackbar
                open={contentUploadCompleteNotificationOpen}
                autoHideDuration={notificationAutoclose}
                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
                onClose={() => setContentUploadCompleteNotificationOpen(false)}
            >
                <MuiAlert
                    className="logi-snackbar-notification-message"
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {contentUploadCompleteNotificationMessage}
                </MuiAlert>
            </Snackbar>

            <Snackbar
                open={contentChangeCompleteNotificationOpen}
                autoHideDuration={notificationAutoclose}
                anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
                onClose={() => setContentChangeCompleteNotificationOpen(false)}
            >
                <MuiAlert
                    className="logi-snackbar-notification-message"
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {contentChangeCompleteNotificationMessage}
                </MuiAlert>
            </Snackbar>

            <ErrorMessageDialog
                onClose={handleErrorAddModalClose}
                open={errorModalOpen}
                onCancel={handleAddErrorModalCancel}
                onDownload={handleErrorMessageModalDownload}
                order={getErrorOrder}
                orderBy={getErrorOrderBy}
                rows={errorRows}
                total={errorTotal}
                rowsPerPage={rowsPerPage}
                offset={errorOffset}
                offsetSetter={setErrorOffset}
                page={errorPage}
                pageSetter={setErrorPage}
            />

            <ChangeMessageDialog
                onClose={handleChangeAddModalClose}
                open={changeModalOpen}
                onCancel={handleAddChangeModalCancel}
                onDownload={handleChangeMessageModalDownload}
                onUpload={handleUploadFileSubmit}
                uploadFile={contentUploadSelectedUploadFile}
                order={getChangeOrder}
                orderBy={getChangeOrderBy}
                rows={changeRows}
                total={changeTotal}
                rowsPerPage={rowsPerPage}
                offset={changeOffset}
                offsetSetter={setChangeOffset}
                page={changePage}
                pageSetter={setChangePage}
            />

            <SimpleBackdrop open={backdropOpen} />


            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} aria-label="Content Upload Tab Panel">
                    <Tab label="Content File Upload" {...a11yProps(0)} />
                </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={0}>
                <ContentUploadTab
                    fileUploadListener={handleUploadFileSubmit}
                    uploadButtonEnabled={contentUploadUploadButtonEnabled}
                    uploadButtonEnabledSetter={setContentUploadUploadButtonEnabled}
                    selectedUploadFile={contentUploadSelectedUploadFile}
                    selectedUploadFileSetter={setContentUploadSelectedUploadFile}
                    downloadButtonClickListener={handleDownloadRulesFileButtonClick}
                    fileCheckListener={handleChangeRulesFileButtonClick}
                />
            </TabPanel>
        </Box>
    );
}