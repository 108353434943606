import React from 'react';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function AddScanContainerDialog(props) {
    const {
        onClose,
        open,
        name,
        nameSetter,
        nameValid,
        nameErrMessage,
        ipVersion,
        ipVersionSetter,
        addButtonEnabled,
        addButtonClickListener,
        validateButtonClickListener
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleNameContentChange = (event) => {
        nameSetter(event.target.value);
    };

    const handleAddButtonClicked = (event) => {
        addButtonClickListener();
    };

    const handlValidateButtonClicked = (event) => {
        validateButtonClickListener();
    };

    const handleIPVersionChange = (event) => {
        ipVersionSetter(event.target.value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Add New Scan Container</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        {...(nameValid !== true && { error: undefined })}
                        helperText={nameErrMessage}
                        label="Scan Container Name"
                        variant="outlined"
                        size="medium"
                        value={name}
                        onChange={handleNameContentChange}
                        id="scan-container-table-add-modal-dialog-container-name-field"
                        style={{marginTop: '15px'}}
                    />
                    <FormControl>
                        <InputLabel id="scan-container-table-add-modal-dialog-ip-version-select-label">IP Version</InputLabel>
                        <Select
                            labelId="scan-container-table-add-modal-dialog-ip-version-select-label"
                            id="scan-container-table-add-modal-dialog-ip-version-select"
                            label="IP Version"
                            onChange={handleIPVersionChange}
                            defaultValue={4}
                            value={ipVersion}
                        >
                            {[4, 6].map((ipVersion) => {
                                let isSelected = ipVersion;
                                return (
                                    <MenuItem selected={isSelected} value={ipVersion}>{ipVersion}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="scan-container-table-add-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="scan-container-table-add-modal-dialog-validate-button"
                            variant="contained"
                            onClick={handlValidateButtonClicked}>Validate</Button>
                        <Button id="scan-container-table-add-modal-dialog-add-button"
                            variant="contained"
                            onClick={handleAddButtonClicked}
                            disabled={!addButtonEnabled}>Add</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}  