import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LOGI_ROLE_DYNAMIC_PREFS_WRITE } from '../../../common/LogiRoles';
import {
    populateHiddenCols
}
    from '../../../common/table/TableHelper';
import EnhancedTableToolbar from '../../../common/table/EnhancedTableToolbar';
import "./DynamicPreferencesTable.css";
import SingleSelectTableHead from '../../../common/table/SingleSelectTableHead';

const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Id',
        hide: true,
    },
    {
        id: 'verbose_name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
        hide: false,
    },
    {
        id: 'helper_text',
        numeric: false,
        disablePadding: false,
        label: 'Description',
        hide: false,
    },
    {
        id: 'default_value',
        numeric: false,
        disablePadding: true,
        label: 'Default Value',
        hide: false,
    },
    {
        id: 'raw_value',
        numeric: false,
        disablePadding: true,
        label: 'Value',
        hide: false,
    }
];

const hiddenCols = populateHiddenCols(headCells);

const getRowCells = (row, labelId, isItemSelected, handleClick) => {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!hiddenCols.has("id") ? (<TableCell component="td" id={labelId} scope="row" padding="none">{row.id}</TableCell>) : ''}
            {!hiddenCols.has("name") ? (<TableCell align="left">{row.name}</TableCell>) : ''}
            {!hiddenCols.has("helper_text") ? (<TableCell align="center"><Tooltip title={row.helper_text}><HelpIcon /></Tooltip></TableCell>) : ''}
            {!hiddenCols.has("default_value") ? (<TableCell align="left">{row.default_value}</TableCell>) : ''}
            {!hiddenCols.has("raw_value") ? (<TableCell align="left">{row.raw_value}</TableCell>) : ''}
        </TableRow >
    )
};

function FilterWidget(props) {
    const { searchTerm, onSearchClick, onResetClick, onSearchTermChange } = props;

    return (
        <form onSubmit={onSearchClick}>
            <div id="globalpreferences-filter-widget-div" class="globalpreferences-filter-widget-div">
                <div class="globalpreferences-filter-widget-body-div">
                    <Stack id="globalpreferences-filter-widget-control-stack" spacing={2} direction="column">
                        <TextField id="globalpreferences-filter-widget-search-input"
                            label="Search"
                            variant="outlined"
                            size="medium"
                            value={searchTerm}
                            onChange={onSearchTermChange}
                            sx={{ m: 1, minWidth: 100, maxWidth: 410 }}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <Button id="globalpreferences-filter-widget-search-button" variant="contained" button type="submit">Search</Button>
                        <Button id="globalpreferences-filter-widget-reset-button" variant="contained" onClick={onResetClick}>Reset</Button>
                    </Stack>
                </div>
            </div>
        </form>
    );
};

export default function DynamicPreferencesTab(props) {

    let {
        editButtonEnabled,
        editButtonClickListener,
        singleSelectionHandler,
        setDynamicPrefsSwitch,
        order,
        orderSetter,
        orderBy,
        orderBySetter,
        page,
        pageSetter,
        offset,
        offsetSetter,
        selected,
        selectedSetter,
        searchTerm,
        searchTermSetter,
        rows,
        total,
        fetchData,
        rowsPerPage,
        refreshButtonClickHandler
    } = props;

    const { keycloak } = useKeycloak();
    let hasDynamicPrefsWrite = keycloak.hasRealmRole(LOGI_ROLE_DYNAMIC_PREFS_WRITE);

    useEffect(() => {
        handleResetButtonClick();
        // fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        orderBySetter(property);
        const isAsc = orderBy() === property && order() === 'asc';
        orderSetter(isAsc ? 'desc' : 'asc');
        setDynamicPrefsSwitch(true);
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleSearchTermChange = (event) => {
        searchTermSetter(event.target.value);
    };
    const handleSearchButtonClick = (event) => {
        event.preventDefault();
        fetchData();
        singleSelectionHandler(null);
    };

    function handleResetButtonClick(event) {
        offsetSetter(0);
        pageSetter(0);
        orderSetter('asc');
        orderBySetter('verbose_name');
        searchTermSetter('');
        fetchData();
        singleSelectionHandler(null);
    }

    const handleChangePage = (event, newPage) => {

        let newOffset = offset;

        if (newPage > page) {
            newOffset += rowsPerPage;
        }
        else {
            newOffset -= rowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        offsetSetter(newOffset);
        pageSetter(newPage);
        setDynamicPrefsSwitch(true);

    };

    const handleRowClick = (event, id) => {
        if (selected[0] === id) {
            singleSelectionHandler(null);
            selectedSetter([]);
        } else {
            let newSelected = [id];
            singleSelectionHandler(id);
            selectedSetter(newSelected);
        }
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    return (
        <div id="globalpreferences-table-top-level-div">
            <div id="globalpreferences-table-button-bar-div">
                <Accordion style={{ 'borderRadius': '10px' }} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Dynamic Preferences Refresh / Search</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2} direction="column">
                            <Stack spacing={2} direction="row">
                                <Button
                                    id="globalpreferences-table-refresh-button"
                                    variant="contained"
                                    onClick={refreshButtonClickHandler}>Refresh</Button>
                            </Stack>
                            <FilterWidget
                                searchTerm={searchTerm}
                                onSearchClick={handleSearchButtonClick}
                                onResetClick={handleResetButtonClick}
                                onSearchTermChange={handleSearchTermChange} />
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div id="globalpreferences-table-div">
                <Stack spacing={2} direction="row">
                    <Box sx={{ width: '100%' }} id="globalpreferences_table_box">
                        <TableContainer>
                            <Table
                                sx={{ width: '100%' }}
                                aria-labelledby="tableTitle"
                                size={'small'}
                                id="globalpreferences-table"
                            >
                                <SingleSelectTableHead
                                    numSelected={selected.length}
                                    order={order()}
                                    orderBy={orderBy()}
                                    onRequestSort={handleRequestSort}
                                    rowCount={total}
                                    headCells={headCells}
                                    fetchMethod={fetchData}
                                    id="globalpreferences-table-head"
                                />
                                <TableBody id="globalpreferences-table-body">
                                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                    {rows.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `globalpreferences-table-checkbox-${index}`;

                                        return (
                                            getRowCells(row, labelId, isItemSelected, handleRowClick)
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                // height: 33 * emptyRows,
                                                height: 33,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            labelDisplayedRows={defaultLabelDisplayedRows}
                        />
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </Box>
                    <div class="globalpreferences-table-table-buttons-div">
                        {hasDynamicPrefsWrite ? (
                            <Button id="globalpreferences-table-edit-button"
                                variant="contained"
                                onClick={editButtonClickListener}
                                disabled={!editButtonEnabled}>Edit</Button>
                        ) : (<p />)
                        }
                    </div>
                </Stack>
            </div>
        </div>
    );
}
