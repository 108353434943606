import Box from '@mui/material/Box';
import Navs from '../components/topnav';
import Sidenavs from '../components/sidenav';
import { useKeycloak } from '@react-keycloak/web';
import { LOGI_ROLE_MANUAL_RULES_READ } from '../common/LogiRoles';
import ManualRulesComponent from '../components/Manual_Rules/ManualRulesComponent';

function ManualRulesPage() {
	const { keycloak } = useKeycloak();

	let hasManualRulesRead = keycloak.hasRealmRole(LOGI_ROLE_MANUAL_RULES_READ);

	if (keycloak.idTokenParsed) {
		return (
			<div className='App'>
				<Navs />
				<table className='mptable'>
					<tr>
						<td className='sidenavtd'>
							<Box sx={{ position: 'sticky', top: 0 }}>
								<Sidenavs firewallSettingsOpen={true} adminOpen={false}/>
							</Box>
						</td>
						<td class="manual-rules-page-td">
							{hasManualRulesRead ? (<Box sx={{ height: `100%` }}>
								<div class="manual-rules-page-div">
									<ManualRulesComponent />
								</div>
							</Box>) : (<p>You do not have permission to view this page</p>)}
						</td>
					</tr>
				</table>
			</div>
		);
	} else {
		window.location.href = '/';
	}
};

export default ManualRulesPage
