export function isValidScanContainerNameValue(input) {
    let res = true;
    let msg = "";
    let ret = { res: res, msg: msg };


    if (input === null) {
        // null input is not at all valid
        ret.res = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.res = false;
        ret.msg = "blank input is not allowed";
        return ret;
    }
    return ret;
}

/**
 * Check if the name the user wants as the scan container name is already in use.  if it is, they'll have to choose another name.
 * 
 * @param {*} baseUrl Base url to fetch scan containers with
 * @param {*} keycloakToken Our keycloak token to authenticate with
 * @param {*} id If supplied, it means we are editing an existing scan container and if a result comes for this id we'll allow it since it's updating the name on the same scan container
 * @param {*} name The scan container name we are checking is in use or not
 * @param {*} resultCallback A function that we'll call with the final result of true or false
 * 
 * @returns true if the name is already in use by an existing scan container, false otherwise
 */
export function scanContainerNameIsInUse(baseUrl, keycloakToken, id, name, fieldIsValidCallback,
    fieldErrorMessageCallback) {

    // build the url
    let fetchUrl = baseUrl + "?name=" + encodeURIComponent(name);

    fetch(fetchUrl, {
        method: 'GET',
        headers: {
            'access-token': keycloakToken
        },
    })
        .then((response) => response.json())
        .then((respData) => {
            let existingId = null
            let inUse = false;

            if (respData.results.length > 0) {
                // should be only one result
                let entry = respData.results[0];
                existingId = entry['id'];
            }
            if (id !== null) {
                // we are editing an existing scan container, so if existingId != null but equals
                // the id of the scan container we are editing, it's no issue
                if (existingId !== null) {
                    if (existingId !== id) {
                        // another scan container has this name so we must force the user to pick something else
                        inUse = true;
                    }
                    else {
                        // since this name exists on the scan container we are editing, we'll not say the name is already in use
                        // by another scan container
                    }
                }
                else {
                    // there is no scan container with this name, all good
                }
            }
            else {
                // since id is null here, we are likely adding a new scan container that doesn't have an id yet
                // we we are simply interested if any scan container exists with this name already
                if (existingId !== null) {
                    // an existing scan container has this name already
                    inUse = true;
                }
            }

            if (inUse) {
                fieldIsValidCallback(false);
                fieldErrorMessageCallback("This name is in use by another scan container, please pick another name");
            }
            else {
                fieldIsValidCallback(true);
                fieldErrorMessageCallback("");
            }
        });
}
