const PATTERN__DYN_PREF__EMAIL = "^[a-zA-Z0-9\\-\\_\\.]{1,253}@[a-zA-Z0-9\\-\\_\\.]{1,253}\\.[a-zA-Z0-9\\.]{2,8}$";

/**
 * Check that the input value is a number suitable for being used as a rule priority
 * 
 * @param {*} inputVal 
 */
function isValidRulePriorityValue(input) {
    let ret = { res: true, msg: "" };

    const min = 0;
    const max = 99;

    if (input === null) {
        // null input is not at all valid
        ret.res = false;
        ret.msg = "Blank values not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.res = false;
        ret.msg = "Blank values not allowed";
        return ret;
    }

    if (isNaN(input)) {
        // not a number, so not valid
        ret.res = false;
        ret.msg = "Input is not a number";
    }
    else {
        // we have a number, validate it's within a reasonable range
        let parsedNum = parseInt(trimmedInput);

        if (parsedNum < min || parsedNum > max) {
            // outside our allowed range
            ret.res = false;
            ret.msg = "Input must be a number in the range " + min + "-" + max;
        }
    }

    return ret;
}

/**
 * Check that the input value is a True or False
 * 
 * @param {*} inputVal 
 */
function isValidBooleanValue(input) {
    let ret = { res: true, msg: "" };

    if (input === null) {
        // null input is not at all valid
        ret.res = false;
        ret.msg = "Blank values not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.res = false;
        ret.msg = "Blank values not allowed";
        return ret;
    }

    if ("True" !== trimmedInput && "False" !== trimmedInput) {
        // not a number, so not valid
        ret.res = false;
        ret.msg = "Value must be either \"True\" or \"False\"";
    }

    return ret;
}

/**
 * Check that the input value is an email address
 * 
 * @param {*} inputVal 
 */
function isValidEmailValue(input) {
    let ret = { res: true, msg: "" };

    if (input === null) {
        // null input is not at all valid
        ret.res = false;
        ret.msg = "Blank values not allowed";
        return ret;
    }

    let trimmedInput = input.trim();
    if (trimmedInput === '') {
        // blank input is not at all valid
        ret.res = false;
        ret.msg = "Blank values not allowed";
        return ret;
    }

    let pMatch = trimmedInput.match(PATTERN__DYN_PREF__EMAIL);

    if (pMatch === null) {
        // didn't match our email address regex
        ret.res = false;
        ret.msg = "Value must be an email address";
    }

    return ret;
}

/**
 * Check that the input value suitable for being used as a raw value to a dynamic preference based
 * on the name of the dynamic preference.
 * 
 * @param {*} dynamicPrefName
 * @param {*} inputVal 
 */
export function isValidDynamicPreferenceValue(dynamicPrefName, input) {
    let ret;

    switch (dynamicPrefName) {
        case "blacklotuslabs":
        case "antispoofing":
        case "autonomous_system_number":
        case "manualdroplist":
        case "maxmind":
        case "maxmindanonymous":
        case "scan_alert":
        case "whitelist":
            // will validate as rule priority number
            ret = isValidRulePriorityValue(input);
            break;
        case "scan_alert_whitelist":
            // will validate as a boolean, e.g. True|False
            ret = isValidBooleanValue(input);
            break;
        case "contactemail":
            // will validate as an email address
            ret = isValidEmailValue(input);
            break;
        default:
            // unhandled case, no validation expected
            ret = { res: true, msg: "" };
    }

    return ret;
}