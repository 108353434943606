import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useKeycloak } from '@react-keycloak/web';
import { coalesce } from '../../common/utility/DataUtil'
import BlockedBllTab from './BlockedBllTab';
import BllIpAddressTab from './BLLIPAddressTab';
import ExceptionTab from './ExceptionTab';
import ManualBlockTab from './ManualBlockTab';
import AdversaryReconnaissanceTab from './AdversaryReconnaissanceTab';
import { isValidConfidence } from '../../common/validation/bll/BLLValidator';
import BLLIPAddressDialog from './modals/BLLIPAddressDialog';
import BlockedBLLCollectionDialog from './modals/BlockedBLLCollectionDialog';
import BLLAddManualBlockDialog from './modals/AddBllManualBlockDialog';
import BLLAddExceptionsDialog from './modals/AddBllExceptionsDialog';

import './BllComponent.css';
import { fetchErrorMessage } from '../mainpage';

const rowsPerPage = 20;

const bllIpAddressUrl = '/middleware/api/bll_blacklotuslabsipaddress/';
const blockedBllCollectionsUrl = '/middleware/api/bll_blockedblacklotuslabs/';
const adhocRefreshBLLUrl = '/middleware/api/v3/bll/adhoc_refresh';
const adhocRefreshBLLAdversaryReconnaissanceUrl = '/middleware/api/v3/bll/adversary_reconnaissance_adhoc_refresh';
const exceptionsUrl = '/middleware/api/bll_exceptionsubnets/';
const exceptionsIPv4Url = '/middleware/api/bll_exceptionsubnets_v4/';
const exceptionsIPv6Url = '/middleware/api/bll_exceptionsubnets_v6/';
const manualBlockUrl = '/middleware/api/bll_manualblockedsubnets/';
const manualBlockv4Url = '/middleware/api/bll_manualblockedsubnets_v4/';
const manualBlockv6Url = '/middleware/api/bll_manualblockedsubnets_v6/';
const adversaryReconnaissanceUrl = '/middleware/api/bll_adversaryreconnaissancesubnets/';
const adversaryReconnaissanceIPv4Url = '/middleware/api/bll_adversaryreconnaissancesubnets_v4/';
const adversaryReconnaissanceIPv6Url = '/middleware/api/bll_adversaryreconnaissancesubnets_v6/';
const lastDbUpdateUrl = "/middleware/api/bll_blacklotuslabsipaddress/?ppage=1&ppage_size=10&pager=custom2";

// hard-code this for now, might have to
// query the default from the server at some point
const DEFAULT_CONFIDENCE = 60;

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

const defaultBllSearchOption = "Both";

const bllSearchOptions = [
  "IPv4",
  "IPv6"
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`bll-ip-addresses-tabpanel-${index}`}
      aria-labelledby={`bll-ip-addresses-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `bll-ip-addresses-tab-${index}`,
    'aria-controls': `bll-ip-addresses-tabpanel-${index}`,
  };
}

function createBllIpAddressRowData(id, ip_address, collection_name, confidence) {
  return {
    id,
    ip_address,
    collection_name,
    confidence,
  };
}

function createBlockedBllCollectionRowData(id, collection_name, enabled, confidence) {
  return {
    id,
    collection_name,
    enabled,
    confidence,
  };
}

function createExceptionBllRowData(id, subnet, created) {
  return {
    id,
    subnet,
    created
  };
}

function createManualBlockBllRowData(id, subnet, created) {
  return {
    id,
    subnet,
    created
  };
}

function createAdversaryReconnaissanceBllRowData(id, subnet, created) {
  return {
    id,
    subnet,
    created
  };
}

// needed so that you don't have to click the refresh button 
let freshBllIpAddressSearchTerm = '';
let freshBllExceptionSearchTerm = '';
let freshBllManualBlockSearchTerm = '';
let freshBllAdversaryReconnaissanceSearchTerm = '';

export default function BllComponent() {
  const { keycloak } = useKeycloak();

  const [tabIndex, setTabIndex] = React.useState(0);

  // snackbar state vars
  const [snackState,] = React.useState({
    snackbarVertical: 'bottom',
    snackbarHorizontal: 'center',
  });

  const { snackbarHorizontal, snackbarVertical } = snackState;

  const [bllIpAddressesRefreshNotificationOpen, setBllIpAddressesRefreshNotificationOpen] = useState(false);
  const bllIpAddressesRefreshNotificationMessage = "BLL IP Address Data Refreshed";
  const [adhocBllFetchStartedNotificationOpen, setAdhocBllFetchStartedNotificationOpen] = useState(false);
  const adhocBllFetchStartedNotificationMessage = "Adhoc BLL Fetch Started";
  const [adhocBllFetchFinishedNotificationOpen, setAdhocBllFetchFinishedNotificationOpen] = useState(false);
  const adhocBllFetchFinishedNotificationMessage = "Adhoc BLL Fetch Completed";
  const [blockedBllRefreshNotificationOpen, setBlockedBllRefreshNotificationOpen] = useState(false);
  const blockedBllRefreshNotificationMessage = "Blocked BLL Data Refreshed";
  const [exceptionBllRefreshNotificationOpen, setExceptionBllRefreshNotificationOpen] = useState(false);
  const exceptionBllRefreshNotificationMessage = "BLL Exception Data Refreshed";
  const [manualBlockBllRefreshNotificationOpen, setManualBlockBllRefreshNotificationOpen] = useState(false);
  const manualBlockBllRefreshNotificationMessage = "BLL Manual Block Data Refreshed";
  const [adversaryReconnaissanceBllRefreshNotificationOpen, setAdversaryReconnaissanceBllRefreshNotificationOpen] = useState(false);
  const adversaryReconnaissanceBllRefreshNotificationMessage = "Threat Reconnaissance Data Refreshed";
  const [adhocBllAdversaryReconnaissanceFetchStartedNotificationOpen, setAdhocBllAdversaryReconnaissanceFetchStartedNotificationOpen] = useState(false);
  const adhocBllAdversaryReconnaissanceFetchStartedNotificationMessage = "Adhoc Threat Reconnaissance Fetch Started";
  const [adhocBllAdversaryReconnaissanceFetchFinishedNotificationOpen, setAdhocBllAdversaryReconnaissanceFetchFinishedNotificationOpen] = useState(false);
  const adhocBllAdversaryReconnaissanceFetchFinishedNotificationMessage = "Adhoc Threat Reconnaissance Fetch Completed";
  const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
  const handleFetchErrorClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFetchErrorMessageOpen(false);
  };


  const [dbpull, setDbpull] = useState('Last database update not available.')

  // bll ip address tab state vars
  const [bllIpAddressOrder, setBllIpAddressOrder] = useState('asc');
  const [bllIpAddressOrderBy, setBllIpAddressOrderBy] = useState('id');
  const [bllIpAddressSelected, setBllIpAddressSelected] = useState([]);
  const [bllIpAddressOffset, setBllIpAddressOffset] = useState(0);
  const [bllIpAddressPage, setBllIpAddressPage] = useState(0);
  const [bllIpAddressRows, setBllIpAddressRows] = useState([]);
  const [bllIpAddressTotal, setBllIpAddressTotal] = useState(0);
  const [bllIpAddressSearchTerm, setBllIpAddressSearchTerm] = useState('');
  const [bllIpAddressSwitch, setBllIpAddressSwitch] = useState(false);

  // bll ip address modal related
  const [viewBLLIPAddressButtonEnabled, setViewBLLIPAddressButtonEnabled] = useState(false);
  const [bllIPAddressModalOpen, setBllIPAddressModalOpen] = useState(false);
  const [selectedBLLIPAddressId, setSelectedBLLIPAddressId] = useState(0);
  const [selectedBLLIPAddress, setSelectedBLLIPAddress] = useState('');
  const [selectedBLLIPAddressCollectionName, setSelectedBLLIPAddressCollectionName] = useState('');
  const [selectedBLLIPAddressConfidence, setSelectedBLLIPAddressConfidence] = useState('');

  // blocked bll collection tab state vars
  const [blockedBllCollectionOrder, setBlockedBllCollectionOrder] = useState('asc');
  const [blockedBllCollectionOrderBy, setBlockedBllCollectionOrderBy] = useState('collection_name__collection_name');
  const [blockedBllCollectionSelected, setBlockedBllCollectionSelected] = useState([]);
  const [blockedBllCollectionOffset, setBlockedBllCollectionOffset] = useState(0);
  const [blockedBllCollectionPage, setBlockedBllCollectionPage] = useState(0);
  const [blockedBllCollectionRows, setBlockedBllCollectionRows] = useState([]);
  const [blockedBllCollectionTotal, setBlockedBllCollectionTotal] = useState(0);
  const [blockedBllSwitch, setBlockedBllSwitch] = useState(false);

  // exceptions bll tab state vars
  const [exceptionBllOrder, setExceptionBllOrder] = useState('asc');
  const [exceptionBllOrderBy, setExceptionBllOrderBy] = useState('subnet');
  const [exceptionTableData, setExceptionTableData] = useState([]);
  const [bllExceptionsModalOpen, setBllExceptionsModalOpen] = useState(false);
  const [deleteExceptionsButtonEnabled, setDeleteExceptionsButtonEnabled] = useState(false);
  const [uploadExceptionsButtonEnabled, setUploadExceptionsButtonEnabled] = useState(false);
  const [selectedExceptionId, setSelectedExceptionId] = useState(0);
  const [exceptionBllOffset, setExceptionBllOffset] = useState(0);
  const [exceptionBllPage, setExceptionBllPage] = useState(0);
  const [exceptionBllSelected, setExceptionBllSelected] = useState([]);
  const [exceptionBllRows, setExceptionBllRows] = useState([]);
  const [exceptionBllTotal, setExceptionBllTotal] = useState(0);
  const [, setSelectedExceptionBLLId] = useState(0);
  const [, setSelectedExceptionBLLSubnet] = useState('');
  const [, setSelectedExceptionBLLCreated] = useState('');
  const [bllExceptionSearchTerm, setBllExceptionSearchTerm] = useState('');
  const [, setViewBLLExceptionButtonEnabled] = useState(false);
  const [searchBllVersion, setSearchBllVersion] = useState(defaultBllSearchOption);
  const [bllExceptionsSwitch, setBllExceptionsSwitch] = useState(false);

  // adversaryReconnaissance bll tab state vars
  const [adversaryReconnaissanceBllOrder, setAdversaryReconnaissanceBllOrder] = useState('asc');
  const [adversaryReconnaissanceBllOrderBy, setAdversaryReconnaissanceBllOrderBy] = useState('subnet');
  const [adversaryReconnaissanceTableData, setAdversaryReconnaissanceTableData] = useState([]);
  const [, setSelectedAdversaryReconnaissanceId] = useState(0);
  const [adversaryReconnaissanceBllOffset, setAdversaryReconnaissanceBllOffset] = useState(0);
  const [adversaryReconnaissanceBllPage, setAdversaryReconnaissanceBllPage] = useState(0);
  const [adversaryReconnaissanceBllSelected, setAdversaryReconnaissanceBllSelected] = useState([]);
  const [adversaryReconnaissanceBllRows, setAdversaryReconnaissanceBllRows] = useState([]);
  const [adversaryReconnaissanceBllTotal, setAdversaryReconnaissanceBllTotal] = useState(0);
  const [, setSelectedAdversaryReconnaissanceBLLId] = useState(0);
  const [, setSelectedAdversaryReconnaissanceBLLSubnet] = useState('');
  const [, setSelectedAdversaryReconnaissanceBLLCreated] = useState('');
  const [bllAdversaryReconnaissanceSearchTerm, setBllAdversaryReconnaissanceSearchTerm] = useState('');
  const [bllAdversaryReconnaissanceSwitch, setBllAdversaryReconnaissanceSwitch] = useState(false);

  // manual block tab state vars
  const [manualBlockBllOrder, setManualBlockBllOrder] = useState('asc');
  const [manualBlockBllOrderBy, setManualBlockBllOrderBy] = useState('subnet');
  const [manualBlockTableData, setManualBlockTableData] = useState([]);
  const [bllManualBlockModalOpen, setBllManualBlockModalOpen] = useState(false);
  const [deleteManualBlockButtonEnabled, setDeleteManualBlockButtonEnabled] = useState(false);
  const [uploadManualBlockButtonEnabled, setUploadManualBlockButtonEnabled] = useState(false);
  const [selectedManualBlockId, setSelectedManualBlockId] = useState(0);
  const [manualBlockBllOffset, setManualBlockBllOffset] = useState(0);
  const [manualBlockBllPage, setManualBlockBllPage] = useState(0);
  const [manualBlockBllSelected, setManualBlockBllSelected] = useState([]);
  const [manualBlockBllRows, setManualBlockBllRows] = useState([]);
  const [manualBlockBllTotal, setManualBlockBllTotal] = useState(0);
  const [, setSelectedManualBlockBLLId] = useState(0);
  const [, setSelectedManualBlockBLLSubnet] = useState('');
  const [, setSelectedManualBlockBLLCreated] = useState('');
  const [bllManualBlockSearchTerm, setBllManualBlockSearchTerm] = useState('');
  const [, setViewBLLManualBlockButtonEnabled] = useState(false);
  const [searchBllVersionMB, setSearchBllVersionMB] = useState(defaultBllSearchOption);
  const [bllManualBlockSwitch, setBllManualBlockSwitch] = useState(false);

  // blocked bll collection modal related
  const [editBlockedBLLCollectionButtonEnabled, setEditBlockedBLLCollectionButtonEnabled] = useState(false);
  const [editBlockedBLLCollectionModalOpen, setEditBlockedBLLCollectionModalOpen] = useState(false);
  const [selectedBlockedBLLCollectionId, setSelectedBlockedBLLCollectionId] = useState(0);
  const [selectedBlockedBLLCollectionName, setSelectedBlockedBLLCollectionName] = useState('');
  const [selectedBlockedBLLCollectionEnabled, setSelectedBlockedBLLCollectionEnabled] = useState(true);
  const [selectedBlockedBLLCollectionConfidence, setSelectedBlockedBLLCollectionConfidence] = useState(DEFAULT_CONFIDENCE);
  const [selectedBlockedBLLCollectionConfidenceValid, setSelectedBlockedBLLCollectionConfidenceValid] = useState(true);
  const [selectedBlockedBLLCollectionConfidenceErrorMessage, setSelectedBlockedBLLCollectionConfidenceErrorMessage] = useState("");
  const [selectedBlockedBLLCollectionValidateButtonEnabled,] = useState(true);
  const [selectedBlockedBLLCollectionUpdateButtonEnabled, setSelectedBlockedBLLCollectionUpdateButtonEnabled] = useState(false);

  // Switches useEffects go below.
  useEffect(() => {
    fetchBllIpAddressData();
  }, [bllIpAddressSwitch])

  useEffect(() => {
    fetchBlockedBllCollectionData();
  }, [blockedBllSwitch])

  useEffect(() => {
    fetchExceptionBllData();
  }, [bllExceptionsSwitch])

  useEffect(() => {
    fetchManualBlockBllData();
  }, [bllManualBlockSwitch])

  useEffect(() => {
    fetchAdversaryReconnaissanceBllData();
  }, [bllAdversaryReconnaissanceSwitch])

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setViewBLLIPAddressButtonEnabled(false);
    setEditBlockedBLLCollectionButtonEnabled(false);
  };

  const handleToggleViewBLLIPAddressButtonVisibility = (val) => {
    setViewBLLIPAddressButtonEnabled(val);
  };

  const handleToggleViewBLLExceptionButtonVisibility = (val) => {
    setViewBLLExceptionButtonEnabled(val);
  };

  const handleToggleViewBLLManualBlockButtonVisibility = (val) => {
    setViewBLLManualBlockButtonEnabled(val);
  };

  const fetchBLLIPAddressModalData = () => {
    fetch(bllIpAddressUrl
      + selectedBLLIPAddressId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setSelectedBLLIPAddressId(respData['id']);
        setSelectedBLLIPAddress(respData['ip_address']);
        setSelectedBLLIPAddressCollectionName(coalesce(respData['collection_name'], "-"));
        setSelectedBLLIPAddressConfidence(coalesce(respData['confidence'], "-"));
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleViewBLLIPAddressButtonClick = (event) => {
    if (!bllIPAddressModalOpen) {
      // fetch the bll ip address data for display in model
      fetchBLLIPAddressModalData();
      setBllIPAddressModalOpen(true);
    } else {
      setBllIPAddressModalOpen(false);
    }
  };

  const handleBLLIPAddressModalClose = (val) => {
    setBllIPAddressModalOpen(false);
  };

  const handleBlockedBLLCollectionModalClose = (val) => {
    setEditBlockedBLLCollectionModalOpen(val);
  };

  const handleBllManualBlockModalClose = () => {
    setBllManualBlockModalOpen(false);
  }

  const handleBllExceptionsModalClose = () => {
    setBllExceptionsModalOpen(false);
  }

  const fetchBlockedBLLCollectionModalData = () => {
    fetch(blockedBllCollectionsUrl
      + selectedBlockedBLLCollectionId + '/', {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        // web service response
        // {
        //   "id": 64,
        //     "collection_name": "pro-attack",
        //       "enabled": true,
        //         "confidence": 70
        // }
        setSelectedBlockedBLLCollectionName(respData['collection_name']);
        setSelectedBlockedBLLCollectionEnabled(respData['enabled']);
        let modalConfidence = respData['confidence'];
        if (modalConfidence === 0) {
          // if the value from the server is 0, populate with a default
          modalConfidence = DEFAULT_CONFIDENCE;
        }
        setSelectedBlockedBLLCollectionConfidence(modalConfidence);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleEditBlockedBLLCollectionButtonClick = (event) => {
    // fetch modal data
    fetchBlockedBLLCollectionModalData();
    // display modal
    setEditBlockedBLLCollectionModalOpen(true);
  };

  const handleAddBLLExceptionsButtonClick = (event) => {
    // fetch modal data
    //fetchBlockedBLLCollectionModalData();
    // display modal
    setBllExceptionsModalOpen(true);
  };

  const handleAddBLLManualBlockButtonClick = (event) => {
    // fetch modal data
    //fetchBlockedBLLCollectionModalData();
    // display modal
    setBllManualBlockModalOpen(true);
  };

  const performBlockedBLLCollectionModalValidation = () => {
    let shouldEnableUpdateButton = true;

    if (!selectedBlockedBLLCollectionConfidenceValid) {

      shouldEnableUpdateButton = false;
    }

    setSelectedBlockedBLLCollectionUpdateButtonEnabled(shouldEnableUpdateButton);
  };

  const handleBlockedBLLCollectionModalEnabledChanged = (val) => {
    setSelectedBlockedBLLCollectionEnabled(val);
  };

  const handleBlockedBLLCollectionModalConfidenceChanged = (val) => {
    setSelectedBlockedBLLCollectionConfidence(val);

    let ret = isValidConfidence(val);
    setSelectedBlockedBLLCollectionConfidenceValid(ret.res);
    setSelectedBlockedBLLCollectionConfidenceErrorMessage(ret.msg);
  };

  const updateBlockedBLLCollection = async () => {

    let patchData = {
      id: selectedBlockedBLLCollectionId,
      enabled: selectedBlockedBLLCollectionEnabled,
      confidence: selectedBlockedBLLCollectionConfidence
    };
    await fetch(blockedBllCollectionsUrl
      + selectedBlockedBLLCollectionId + '/', {
      method: 'PATCH',
      headers: {
        'access-token': keycloak.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patchData)
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setSelectedBlockedBLLCollectionId(respData['id'])
        setSelectedBlockedBLLCollectionName(respData['collection_name']);
        setSelectedBlockedBLLCollectionEnabled(respData['enabled']);
        let modalConfidence = respData['confidence'];
        if (modalConfidence === 0) {
          // if the value from the server is 0, populate with a default
          modalConfidence = DEFAULT_CONFIDENCE;
        }
        setSelectedBlockedBLLCollectionConfidence(modalConfidence);

        fetchBlockedBllCollectionData();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  };

  const handleBlockedBLLCollectionModalUpdateButtonClick = () => {
    updateBlockedBLLCollection();
    setSelectedBlockedBLLCollectionUpdateButtonEnabled(false);
    setEditBlockedBLLCollectionModalOpen(false);
    setSelectedBlockedBLLCollectionId(0);
  };

  const handleBlockedBLLCollectionModalValidateButtonClick = () => {
    performBlockedBLLCollectionModalValidation();
  };

  const handleBLLBlockCollectionRowSelection = (val) => {
    setSelectedBlockedBLLCollectionId(val);
    setSelectedBlockedBLLCollectionConfidence(DEFAULT_CONFIDENCE);
    setSelectedBlockedBLLCollectionName("");
    setSelectedBlockedBLLCollectionConfidenceValid(true);
    setSelectedBlockedBLLCollectionConfidenceErrorMessage("");
    setSelectedBlockedBLLCollectionUpdateButtonEnabled(false);

    if (val === 0) {
      setEditBlockedBLLCollectionButtonEnabled(false);
    }
    else {
      setEditBlockedBLLCollectionButtonEnabled(true);
    }
  };

  const handleBLLExceptionRowSelection = (val) => {
    setSelectedExceptionBLLId(val);
    setSelectedExceptionBLLSubnet("");
    setSelectedExceptionBLLCreated("");
    setSelectedExceptionId(val);
  };

  const handleBLLAdversaryReconnaissanceRowSelection = (val) => {
    setSelectedAdversaryReconnaissanceBLLId(val);
    setSelectedAdversaryReconnaissanceBLLSubnet("");
    setSelectedAdversaryReconnaissanceBLLCreated("");
    setSelectedAdversaryReconnaissanceId(val);
  };

  const handleBLLManualBlockRowSelection = (val) => {
    setSelectedManualBlockBLLId(val);
    setSelectedManualBlockBLLSubnet("");
    setSelectedManualBlockBLLCreated("");
    setSelectedManualBlockId(val);
  };

  const getFreshBllIpAddressSearchTerm = () => {
    return freshBllIpAddressSearchTerm;
  };

  const getSanitizedBllIpAddressSearchFieldContent = () => {
    let ret = getFreshBllIpAddressSearchTerm();
    return encodeURIComponent(ret);
  };

  const getFreshBllExceptionSearchTerm = () => {
    return freshBllExceptionSearchTerm;
  }

  const getFreshBllManualBlockSearchTerm = () => {
    return freshBllManualBlockSearchTerm;
  }
  const getFreshBllAdversaryReconnaissanceSearchTerm = () => {
    return freshBllAdversaryReconnaissanceSearchTerm;
  }

  const getSanitizedBllExceptionSearchFieldContent = () => {
    let ret = getFreshBllExceptionSearchTerm();
    return encodeURIComponent(ret);
  }

  const getSanitizedBllAdversaryReconnaissanceSearchFieldContent = () => {
    let ret = getFreshBllAdversaryReconnaissanceSearchTerm();
    return encodeURIComponent(ret);
  }

  const getSanitizedBllManualBlockSearchFieldContent = () => {
    let ret = getFreshBllManualBlockSearchTerm();
    return encodeURIComponent(ret);
  }

  const fetchBllIpAddressData = async () => {
    const sanitizedSearchTerm = getSanitizedBllIpAddressSearchFieldContent();
    const orderPrefix = (bllIpAddressOrder === 'desc' ? '-' : '');

    await fetch(bllIpAddressUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + bllIpAddressOffset
      + '&page=' + (bllIpAddressPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + orderPrefix + bllIpAddressOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setBllIpAddressTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createBllIpAddressRowData(entry['id'],
            entry['ip_address'],
            coalesce(entry['collection_name'], "-"),
            coalesce(entry['confidence'], "-")));
        }

        setBllIpAddressSelected([]);
        setBllIpAddressRows(tmpRows);

        getLastDbUpdate();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setBllIpAddressSwitch(false);
  };

  const fetchBlockedBllCollectionData = () => {
    const orderPrefix = (blockedBllCollectionOrder === 'desc' ? '-' : '');

    let bBLLob = '';
    if (blockedBllCollectionOrderBy === 'collection_name') {
      bBLLob = 'collection_name__collection_name';
    } else {
      bBLLob = blockedBllCollectionOrderBy;
    }

    fetch(blockedBllCollectionsUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + blockedBllCollectionOffset
      + '&page=' + (blockedBllCollectionPage + 1)
      + '&ordering=' + orderPrefix + bBLLob, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setBlockedBllCollectionTotal(respData.count);
        // console.log("Blocked BLL TMP: ", respData)
        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createBlockedBllCollectionRowData(entry['id'],
            entry['collection_name'],
            coalesce(entry['enabled'], "-"),
            coalesce(entry['confidence'], "-")));
        }

        setBlockedBllCollectionSelected([]);
        setBlockedBllCollectionRows(tmpRows);
        // console.log("Blocked BLL TMP: ", tmpRows)
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setBlockedBllSwitch(false);
  };

  const fetchExceptionBllData = () => {
    const sanitizedSearchTerm = getSanitizedBllExceptionSearchFieldContent();
    const ipFilter = searchBllVersion;
    // console.log("IP Filter: ", ipFilter);
    const exceptionOrderPrefix = (exceptionBllOrder === 'desc' ? '-' : '');
    let bllExceptionsUrl = exceptionsUrl;
    if (ipFilter === 'Both') {
      bllExceptionsUrl = exceptionsUrl;
      // console.log("It's Both.");
    } else if (ipFilter === 'IPv4') {
      bllExceptionsUrl = exceptionsIPv4Url;
      // console.log("It's IPv4");
    } else if (ipFilter === 'IPv6') {
      bllExceptionsUrl = exceptionsIPv6Url;
      // console.log("It's IPv6");
    }
    // console.log("What URL: ", bllExceptionsUrl)

    fetch(bllExceptionsUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + exceptionBllOffset
      + '&page=' + (exceptionBllPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + exceptionOrderPrefix + exceptionBllOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setExceptionBllTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createExceptionBllRowData(entry['id'],
            entry['subnet'],
            coalesce(entry['created'], "-")));
        }

        setExceptionBllSelected([]);
        setExceptionBllRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setBllIpAddressSwitch(false);
  };

  const fetchAdversaryReconnaissanceBllData = () => {
    const sanitizedSearchTerm = getSanitizedBllAdversaryReconnaissanceSearchFieldContent();
    const ipFilter = searchBllVersion;
    const adversaryReconnaissanceOrderPrefix = (adversaryReconnaissanceBllOrder === 'desc' ? '-' : '');
    let bllAdversaryReconnaissanceUrl = adversaryReconnaissanceUrl;
    if (ipFilter === 'Both') {
      bllAdversaryReconnaissanceUrl = adversaryReconnaissanceUrl;
      // console.log("It's Both.");
    } else if (ipFilter === 'IPv4') {
      bllAdversaryReconnaissanceUrl = adversaryReconnaissanceIPv4Url;
      // console.log("It's IPv4");
    } else if (ipFilter === 'IPv6') {
      bllAdversaryReconnaissanceUrl = adversaryReconnaissanceIPv6Url;
      // console.log("It's IPv6");
    }
    // console.log("What URL: ", bllAdversaryReconnaissanceUrl)

    fetch(bllAdversaryReconnaissanceUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + adversaryReconnaissanceBllOffset
      + '&page=' + (adversaryReconnaissanceBllPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + adversaryReconnaissanceOrderPrefix + adversaryReconnaissanceBllOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setAdversaryReconnaissanceBllTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createAdversaryReconnaissanceBllRowData(entry['id'],
            entry['subnet'],
            coalesce(entry['created'], "-")));
        }

        setAdversaryReconnaissanceBllSelected([]);
        setAdversaryReconnaissanceBllRows(tmpRows);
        getLastAdversaryReconnaissanceDbUpdate();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setBllAdversaryReconnaissanceSwitch(false);
  };


  const fetchManualBlockBllData = () => {
    const sanitizedSearchTerm = getSanitizedBllManualBlockSearchFieldContent();
    const ipFilter = searchBllVersionMB;
    const manualBlockOrderPrefix = (manualBlockBllOrder === 'desc' ? '-' : '');

    let bllManualBlockUrl = manualBlockUrl;
    if (ipFilter === 'Both') {
      bllManualBlockUrl = manualBlockUrl;
      // console.log("It's Both.");
    } else if (ipFilter === 'IPv4') {
      bllManualBlockUrl = manualBlockv4Url;
      // console.log("It's IPv4");
    } else if (ipFilter === 'IPv6') {
      bllManualBlockUrl = manualBlockv6Url;
      // console.log("It's IPv6");
    }

    fetch(bllManualBlockUrl
      + '?limit=' + rowsPerPage
      + '&offset=' + manualBlockBllOffset
      + '&page=' + (manualBlockBllPage + 1)
      + (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
      + '&ordering=' + manualBlockOrderPrefix + manualBlockBllOrderBy, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((respData) => {
        setManualBlockBllTotal(respData.count);

        let tmpRows = [];
        for (let i = 0; i < respData.results.length; i++) {
          let entry = respData.results[i];
          tmpRows.push(createManualBlockBllRowData(entry['id'],
            entry['subnet'],
            coalesce(entry['created'], "-")));
        }

        setManualBlockBllSelected([]);
        setManualBlockBllRows(tmpRows);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
    setBllManualBlockSwitch(false);
  };

  const handleBllIPAddressSearchTermChanged = (val) => {
    setBllIpAddressSearchTerm(val);
    freshBllIpAddressSearchTerm = val;
  };

  const handleBllExceptionVersionTermChanged = (val) => {
    setSearchBllVersion(val);
  };

  const handleBllManualBlockVersionTermChanged = (val) => {
    setSearchBllVersionMB(val);
  };

  const handleBllExceptionSearchTermChanged = (val) => {
    setBllExceptionSearchTerm(val);
    freshBllExceptionSearchTerm = val;
  };

  const handleBllManualBlockSearchTermChanged = (val) => {
    setBllManualBlockSearchTerm(val);
    freshBllManualBlockSearchTerm = val;
  };

  const handleBllAdversaryReconnaissanceVersionTermChanged = (val) => {
    setSearchBllVersion(val);
  };

  const handleBllAdversaryReconnaissanceSearchTermChanged = (val) => {
    setBllAdversaryReconnaissanceSearchTerm(val);
    freshBllAdversaryReconnaissanceSearchTerm = val;
  };

  const handleAdhocRefreshButtonClick = async () => {
    displayAdhocBllFetchStarted();
    await fetch(adhocRefreshBLLUrl, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.text();
      })
      .then((response) => {
        displayAdhocBllFetchFinished();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  }

  const handleAdhocRefreshAdversaryReconnaissanceButtonClick = async () => {
    displayAdhocBllAdversaryReconnaissanceFetchStarted();
    await fetch(adhocRefreshBLLAdversaryReconnaissanceUrl, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.text();
      })
      .then((response) => {
        displayAdhocBllAdversaryReconnaissanceFetchFinished();
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  }

  const getLastDbUpdate = async () => {
    await fetch(lastDbUpdateUrl, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((response) => {
        let result;
        result = response['last_updated'].find((tk) => tk.includes('Black Lotus Labs'))
        setDbpull(result);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  }

  const getLastAdversaryReconnaissanceDbUpdate = async () => {
    await fetch(lastDbUpdateUrl, {
      method: 'GET',
      headers: {
        'access-token': keycloak.token
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((response) => {
        let result;
        result = response['last_updated'].find((tk) => tk.includes('Threat Reconnaissance'))
        setDbpull(result);
      }).catch((error) => {
        console.log('error: ' + error);
        setFetchErrorMessageOpen(true);
      });
  }

  const handleBllIpAddressesRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setBllIpAddressesRefreshNotificationOpen(false);
  };

  const displayBllIPAddressRefreshStarted = (event) => {
    setBllIpAddressesRefreshNotificationOpen(true);
  };

  const handleBLLIPAddressRefreshButtonClick = (event) => {
    fetchBllIpAddressData();
    displayBllIPAddressRefreshStarted();
  };

  const handleAdhocBllFetchStartedNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAdhocBllFetchStartedNotificationOpen(false);
  };

  const displayAdhocBllFetchStarted = (event) => {
    setAdhocBllFetchStartedNotificationOpen(true);
  };

  const displayAdhocBllAdversaryReconnaissanceFetchStarted = (event) => {
    setAdhocBllAdversaryReconnaissanceFetchStartedNotificationOpen(true);
  };

  const handleAdhocBllAdversaryReconnaissanceFetchStartedNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAdhocBllAdversaryReconnaissanceFetchStartedNotificationOpen(false);
  };


  const handleAdhocBllFetchFinishedNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAdhocBllFetchFinishedNotificationOpen(false);
  };

  const handleAdhocBllAdversaryReconnaissanceFetchFinishedNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAdhocBllAdversaryReconnaissanceFetchFinishedNotificationOpen(false);
  };



  const displayAdhocBllFetchFinished = (event) => {
    setAdhocBllFetchFinishedNotificationOpen(true);
  };

  const displayAdhocBllAdversaryReconnaissanceFetchFinished = (event) => {
    setAdhocBllAdversaryReconnaissanceFetchFinishedNotificationOpen(true);
  };

  const handleBlockedBllRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setBlockedBllRefreshNotificationOpen(false);
  };

  const handleExceptionBllRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setExceptionBllRefreshNotificationOpen(false);
  };

  const handleAdversaryReconnaissanceBllRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAdversaryReconnaissanceBllRefreshNotificationOpen(false);
  };

  const handleManualBlockBllRefreshNotificationClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setManualBlockBllRefreshNotificationOpen(false);
  };

  const displayBlockedBllRefreshStarted = (event) => {
    setBlockedBllRefreshNotificationOpen(true);
  };

  const handleBlockedBLLRefreshButtonClick = (event) => {
    fetchBlockedBllCollectionData();
    displayBlockedBllRefreshStarted();
  };

  const displayExceptionBllRefreshStarted = (event) => {
    setExceptionBllRefreshNotificationOpen(true);
  };

  const handleExceptionBLLRefreshButtonClick = (event) => {
    fetchExceptionBllData();
    displayExceptionBllRefreshStarted();
  };

  const displayAdversaryReconnaissanceBllRefreshStarted = (event) => {
    setAdversaryReconnaissanceBllRefreshNotificationOpen(true);
  };

  const handleAdversaryReconnaissanceBLLRefreshButtonClick = (event) => {
    fetchAdversaryReconnaissanceBllData();
    displayAdversaryReconnaissanceBllRefreshStarted();
  };

  const displayManualBlockBllRefreshStarted = (event) => {
    setManualBlockBllRefreshNotificationOpen(true);
  };

  const handleManualBlockBLLRefreshButtonClick = (event) => {
    fetchManualBlockBllData();
    displayManualBlockBllRefreshStarted();
  };

  const getBlockedBllCollectionOrder = () => {
    return blockedBllCollectionOrder;
  }

  const getBlockedBllCollectionOrderBy = () => {
    return blockedBllCollectionOrderBy;
  }

  const getAdversaryReconnaissanceBllOrder = () => {
    return adversaryReconnaissanceBllOrder;
  }

  const getAdversaryReconnaissanceBllOrderBy = () => {
    return adversaryReconnaissanceBllOrderBy;
  }


  const getExceptionBllOrder = () => {
    return exceptionBllOrder;
  }

  const getManualBlockBllOrder = () => {
    return manualBlockBllOrder;
  }

  const getExceptionBllCollectionOrderBy = () => {
    return exceptionBllOrderBy;
  }

  const getManualBlockBllOrderBy = () => {
    return manualBlockBllOrderBy;
  }

  const getBllIpAddressOrderBy = () => {
    return bllIpAddressOrderBy;
  }

  const getBllIpAddressOrder = () => {
    return bllIpAddressOrder;
  }

  const handleExceptionsSelected = (id) => {
    setSelectedExceptionId(id);
    if (id !== 0) {
      setDeleteExceptionsButtonEnabled(true);
    }
    else {
      setDeleteExceptionsButtonEnabled(false);
    }
  };


  const handleManualBlockSelected = (id) => {
    setSelectedManualBlockId(id);
    if (id !== 0) {
      setDeleteManualBlockButtonEnabled(true);
    }
    else {
      setDeleteManualBlockButtonEnabled(false);
    }
  };

  const deleteException = (refreshCallBack) => {
    fetch(exceptionsUrl
      + selectedExceptionId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        setDeleteExceptionsButtonEnabled(false);
        refreshCallBack();
      });
  };

  const deleteManualBlock = (refreshCallBack) => {
    fetch(manualBlockUrl
      + selectedManualBlockId + '/', {
      method: 'DELETE',
      headers: {
        'access-token': keycloak.token
      },
    })
      .then((response) => {
        setDeleteManualBlockButtonEnabled(false);
        refreshCallBack();
      });
  };

  const handleDeleteExceptionButtonClick = (refreshCallBack) => {
    deleteException(refreshCallBack);
  };

  const handleDeleteManualBlockButtonClick = (refreshCallBack) => {
    deleteManualBlock(refreshCallBack);
  };

  return (
    <Box sx={{ width: '100%' }} id="bll-ip-addresses-top-level-box">
      <Snackbar
        open={bllIpAddressesRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleBllIpAddressesRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {bllIpAddressesRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={adhocBllFetchStartedNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleAdhocBllFetchStartedNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {adhocBllFetchStartedNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={fetchErrorMessageOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleFetchErrorClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {fetchErrorMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={adhocBllFetchFinishedNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleAdhocBllFetchFinishedNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {adhocBllFetchFinishedNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={adhocBllAdversaryReconnaissanceFetchStartedNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleAdhocBllAdversaryReconnaissanceFetchStartedNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {adhocBllAdversaryReconnaissanceFetchStartedNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={adhocBllAdversaryReconnaissanceFetchFinishedNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleAdhocBllAdversaryReconnaissanceFetchFinishedNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {adhocBllAdversaryReconnaissanceFetchFinishedNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={blockedBllRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleBlockedBllRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {blockedBllRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={exceptionBllRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleExceptionBllRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {exceptionBllRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={manualBlockBllRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleManualBlockBllRefreshNotificationClosed}
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {manualBlockBllRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={adversaryReconnaissanceBllRefreshNotificationOpen}
        autoHideDuration={notificationAutoclose}
        onClose={handleAdversaryReconnaissanceBllRefreshNotificationClosed} handleAdhocBllAdversaryReconnaissanceFetchStartedNotificationClosed
        anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
      >
        <MuiAlert
          className="logi-snackbar-notification-message"
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}>
          {adversaryReconnaissanceBllRefreshNotificationMessage}
        </MuiAlert>
      </Snackbar>
      <BLLIPAddressDialog
        open={bllIPAddressModalOpen}
        onClose={handleBLLIPAddressModalClose}
        selectedBLLIPAddress={selectedBLLIPAddress}
        selectedBLLIPAddressCollectionName={selectedBLLIPAddressCollectionName}
        selectedBLLIPAddressConfidence={selectedBLLIPAddressConfidence}
      />
      <BlockedBLLCollectionDialog
        open={editBlockedBLLCollectionModalOpen}
        onClose={handleBlockedBLLCollectionModalClose}
        selectedBlockedBLLCollectionName={selectedBlockedBLLCollectionName}
        selectedBlockedBLLCollectionEnabled={selectedBlockedBLLCollectionEnabled}
        selectedBlockedBLLCollectionEnabledSetter={handleBlockedBLLCollectionModalEnabledChanged}
        selectedBlockedBLLCollectionConfidence={selectedBlockedBLLCollectionConfidence}
        selectedBlockedBLLCollectionConfidenceSetter={handleBlockedBLLCollectionModalConfidenceChanged}
        selectedBlockedBLLCollectionConfidenceValid={selectedBlockedBLLCollectionConfidenceValid}
        selectedBlockedBLLCollectionConfidenceErrorMessage={selectedBlockedBLLCollectionConfidenceErrorMessage}
        selectedBlockedBLLCollectionUpdateButtonEnabled={selectedBlockedBLLCollectionUpdateButtonEnabled}
        selectedBlockedBLLCollectionUpdateButtonClickListener={handleBlockedBLLCollectionModalUpdateButtonClick}
        selectedBlockedBLLCollectionValidateButtonEnabled={selectedBlockedBLLCollectionValidateButtonEnabled}
        selectedBlockedBLLCollectionValidateButtonClickListener={handleBlockedBLLCollectionModalValidateButtonClick}
      />
      <BLLAddManualBlockDialog
        open={bllManualBlockModalOpen}
        onClose={handleBllManualBlockModalClose}
        fetchData={fetchManualBlockBllData}
      />
      <BLLAddExceptionsDialog
        open={bllExceptionsModalOpen}
        onClose={handleBllExceptionsModalClose}
        fetchData={fetchExceptionBllData}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="BLL Tab Panel">
          <Tab label="BLL IP Address" {...a11yProps(0)} />
          <Tab label="BLL Collections" {...a11yProps(1)} />
          <Tab label="Manual Blocks" {...a11yProps(2)} />
          <Tab label="Threat Reconnaissance" {...a11yProps(3)} />
          <Tab label="Exceptions" {...a11yProps(4)} />
          {/* <Tab label="Collection Names" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <BllIpAddressTab
          viewButtonEnabled={viewBLLIPAddressButtonEnabled}
          viewButtonClickListener={handleViewBLLIPAddressButtonClick}
          onSelectRowId={setSelectedBLLIPAddressId}
          isSelectRowId={selectedBLLIPAddressId}
          viewButtonToggle={handleToggleViewBLLIPAddressButtonVisibility}
          order={getBllIpAddressOrder}
          orderSetter={setBllIpAddressOrder}
          orderBy={getBllIpAddressOrderBy}
          orderBySetter={setBllIpAddressOrderBy}
          setBllIpAddressSwitch={setBllIpAddressSwitch}
          selected={bllIpAddressSelected}
          selectedSetter={setBllIpAddressSelected}
          offset={bllIpAddressOffset}
          offsetSetter={setBllIpAddressOffset}
          page={bllIpAddressPage}
          pageSetter={setBllIpAddressPage}
          rows={bllIpAddressRows}
          total={bllIpAddressTotal}
          totalSetter={setBllIpAddressTotal}
          searchTerm={bllIpAddressSearchTerm}
          searchTermSetter={handleBllIPAddressSearchTermChanged}
          rowsPerPage={rowsPerPage}
          fetchData={fetchBllIpAddressData}
          refreshButtonClickHandler={handleBLLIPAddressRefreshButtonClick}
          adhocPullButtonClickHandler={handleAdhocRefreshButtonClick}
          dbpull={dbpull}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <BlockedBllTab
          editButtonEnabled={editBlockedBLLCollectionButtonEnabled}
          editButtonClickListener={handleEditBlockedBLLCollectionButtonClick}
          editButtonToggle={setEditBlockedBLLCollectionButtonEnabled}
          onSelectRowId={handleBLLBlockCollectionRowSelection}
          order={getBlockedBllCollectionOrder}
          orderSetter={setBlockedBllCollectionOrder}
          orderBy={getBlockedBllCollectionOrderBy}
          orderBySetter={setBlockedBllCollectionOrderBy}
          setBlockedBllSwitch={setBlockedBllSwitch}
          selected={blockedBllCollectionSelected}
          selectedSetter={setBlockedBllCollectionSelected}
          offset={blockedBllCollectionOffset}
          offsetSetter={setBlockedBllCollectionOffset}
          page={blockedBllCollectionPage}
          pageSetter={setBlockedBllCollectionPage}
          rows={blockedBllCollectionRows}
          total={blockedBllCollectionTotal}
          rowsPerPage={rowsPerPage}
          fetchData={fetchBlockedBllCollectionData}
          refreshButtonClickHandler={handleBlockedBLLRefreshButtonClick}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <ExceptionTab
          onSelectRowId={handleBLLExceptionRowSelection}
          order={getExceptionBllOrder}
          orderSetter={setExceptionBllOrder}
          orderBy={getExceptionBllCollectionOrderBy}
          orderBySetter={setExceptionBllOrderBy}
          setBllExceptionsSwitch={setBllExceptionsSwitch}
          selected={exceptionBllSelected}
          selectedSetter={setExceptionBllSelected}
          offset={exceptionBllOffset}
          offsetSetter={setExceptionBllOffset}
          page={exceptionBllPage}
          pageSetter={setExceptionBllPage}
          rows={exceptionBllRows}
          total={exceptionBllTotal}
          totalSetter={setExceptionBllTotal}
          rowsPerPage={rowsPerPage}
          refreshButtonClickHandler={handleExceptionBLLRefreshButtonClick}
          handleRowSelected={handleExceptionsSelected}
          fetchData={fetchExceptionBllData}
          manualBlockTableData={exceptionTableData}
          setManualBlockTableData={setExceptionTableData}
          addButtonClickListener={handleAddBLLExceptionsButtonClick}
          deleteButtonEnabled={deleteExceptionsButtonEnabled}
          deleteButtonClickHandler={handleDeleteExceptionButtonClick}
          deleteButtonToggle={setDeleteExceptionsButtonEnabled}
          uploadButtonEnabled={uploadExceptionsButtonEnabled}
          uploadButtonEnabledSetter={setUploadExceptionsButtonEnabled}
          viewButtonToggle={handleToggleViewBLLManualBlockButtonVisibility}
          searchTerm={bllExceptionSearchTerm}
          searchTermSetter={handleBllExceptionSearchTermChanged}
          bllSearchOptions={bllSearchOptions}
          defaultBllSearchOption={defaultBllSearchOption}
          searchBLLVersion={searchBllVersion}
          searchBLLVersionSetter={handleBllExceptionVersionTermChanged}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <ManualBlockTab
          onSelectRowId={handleBLLManualBlockRowSelection}
          order={getManualBlockBllOrder}
          orderSetter={setManualBlockBllOrder}
          orderBy={getManualBlockBllOrderBy}
          orderBySetter={setManualBlockBllOrderBy}
          setBllManualBlockSwitch={setBllManualBlockSwitch}
          selected={manualBlockBllSelected}
          selectedSetter={setManualBlockBllSelected}
          offset={manualBlockBllOffset}
          offsetSetter={setManualBlockBllOffset}
          page={manualBlockBllPage}
          pageSetter={setManualBlockBllPage}
          rows={manualBlockBllRows}
          total={manualBlockBllTotal}
          totalSetter={setManualBlockBllTotal}
          rowsPerPage={rowsPerPage}
          refreshButtonClickHandler={handleManualBlockBLLRefreshButtonClick}
          handleRowSelected={handleManualBlockSelected}
          fetchData={fetchManualBlockBllData}
          manualBlockTableData={manualBlockTableData}
          setManualBlockTableData={setManualBlockTableData}
          addButtonClickListener={handleAddBLLManualBlockButtonClick}
          deleteButtonEnabled={deleteManualBlockButtonEnabled}
          deleteButtonClickHandler={handleDeleteManualBlockButtonClick}
          deleteButtonToggle={setDeleteManualBlockButtonEnabled}
          uploadButtonEnabled={uploadManualBlockButtonEnabled}
          uploadButtonEnabledSetter={setUploadManualBlockButtonEnabled}
          viewButtonToggle={handleToggleViewBLLExceptionButtonVisibility}
          searchTerm={bllManualBlockSearchTerm}
          searchTermSetter={handleBllManualBlockSearchTermChanged}
          bllSearchOptions={bllSearchOptions}
          defaultBllSearchOption={defaultBllSearchOption}
          searchBLLVersion={searchBllVersionMB}
          searchBLLVersionSetter={handleBllManualBlockVersionTermChanged}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <AdversaryReconnaissanceTab
          onSelectRowId={handleBLLAdversaryReconnaissanceRowSelection}
          order={getAdversaryReconnaissanceBllOrder}
          orderSetter={setAdversaryReconnaissanceBllOrder}
          orderBy={getAdversaryReconnaissanceBllOrderBy}
          orderBySetter={setAdversaryReconnaissanceBllOrderBy}
          setBllAdversaryReconnaissanceSwitch={setBllAdversaryReconnaissanceSwitch}
          selected={adversaryReconnaissanceBllSelected}
          selectedSetter={setAdversaryReconnaissanceBllSelected}
          offset={adversaryReconnaissanceBllOffset}
          offsetSetter={setAdversaryReconnaissanceBllOffset}
          page={adversaryReconnaissanceBllPage}
          pageSetter={setAdversaryReconnaissanceBllPage}
          rows={adversaryReconnaissanceBllRows}
          total={adversaryReconnaissanceBllTotal}
          totalSetter={setAdversaryReconnaissanceBllTotal}
          rowsPerPage={rowsPerPage}
          refreshButtonClickHandler={handleAdversaryReconnaissanceBLLRefreshButtonClick}
          fetchData={fetchAdversaryReconnaissanceBllData}
          adversaryReconnaissanceTableData={adversaryReconnaissanceTableData}
          setadversaryReconnaissanceTableData={setAdversaryReconnaissanceTableData}
          searchTerm={bllAdversaryReconnaissanceSearchTerm}
          searchTermSetter={handleBllAdversaryReconnaissanceSearchTermChanged}
          bllSearchOptions={bllSearchOptions}
          defaultBllSearchOption={defaultBllSearchOption}
          searchBLLVersion={searchBllVersion}
          searchBLLVersionSetter={handleBllAdversaryReconnaissanceVersionTermChanged}
          adhocPullButtonClickHandler={handleAdhocRefreshAdversaryReconnaissanceButtonClick}
          dbpull={dbpull}
        />
      </TabPanel>
    </Box>
  );
}
