import Box from '@mui/material/Box';
import Navs from '../../components/topnav';
import Sidenavs from '../../components/sidenav';
import { useKeycloak } from '@react-keycloak/web';
import { LOGI_ROLE_CONTENT_READ } from '../../common/LogiRoles';
import ContentUploadComponent from '../../components/Admin/ContentUpload/ContentUploadComponent';

function ContentUploadPage() {
    const { keycloak } = useKeycloak();

    let hasContentUploadRead = keycloak.hasRealmRole(LOGI_ROLE_CONTENT_READ);

    if (keycloak.idTokenParsed) {
        return (
            <div className='App'>
                <Navs />
                <table className='mptable'>
                    <tr>
                        <td className='sidenavtd'>
                            <Box sx={{ position: 'sticky', top: 0 }}>
                                <Sidenavs firewallSettingsOpen={false} adminOpen={false}/>
                            </Box>
                        </td>
                        <td class="content-upload-page-td">
                            {hasContentUploadRead ? (<Box sx={{ height: `100%` }}>
                                <div class="content-upload-page-div">
                                    <ContentUploadComponent />
                                </div>
                            </Box>) : (<p>You do not have permission to view this page</p>)}
                        </td>
                    </tr>
                </table>
            </div>
        );
    } else {
        window.location.href = '/';
    }
};

export default ContentUploadPage
