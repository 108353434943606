import React from 'react';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default function AddScanContainerSubnetDialog(props) {
    const {
        onClose,
        open,
        subnetAddress,
        subnetAddressSetter,
        subnetAddressValid,
        subnetAddressErrMessage,
        addButtonEnabled,
        addButtonClickListener,
        validateButtonClickListener
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleSubnetAddressContentChange = (event) => {
        subnetAddressSetter(event.target.value);
    };

    const handleAddButtonClicked = (event) => {
        addButtonClickListener();
    };

    const handlValidateButtonClicked = (event) => {
        validateButtonClickListener();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Add Subnet To Scan Container</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        {...(subnetAddressValid !== true && { error: undefined })}
                        helperText={subnetAddressErrMessage}
                        label="Subnet Address"
                        variant="outlined"
                        size="medium"
                        value={subnetAddress}
                        onChange={handleSubnetAddressContentChange}
                        id="scan-container-table-add-subnet-modal-dialog-subnet-field"
                        style={{marginTop: '15px'}}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="scan-container-table-add-subnet-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="scan-container-table-add-subnet--modal-dialog-validate-button"
                            variant="contained"
                            onClick={handlValidateButtonClicked}>Validate</Button>
                        <Button id="scan-container-table-add-subnet--modal-dialog-add-button"
                            variant="contained"
                            onClick={handleAddButtonClicked}
                            disabled={!addButtonEnabled}>Add</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}  