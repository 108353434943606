import Box from '@mui/material/Box';
import Navs from '../../components/topnav';
import Sidenavs from '../../components/sidenav';
import LicenseMgmtComponent from '../../components/Admin/LicenseKeys/LicenseMgmtComponent';
import { useKeycloak } from '@react-keycloak/web';
import './LicenseKeyMgmtPage.css';

function LicenseKeyMgmtPage() {
	const { keycloak } = useKeycloak();
	if (keycloak.idTokenParsed) {
		return (
			<div className='App'>
				<Navs />
				<table className='mptable'>
					<tr>
						<td className='sidenavtd'>
							<Box sx={{ position: 'sticky', top: 0 }}>
								<Sidenavs firewallSettingsOpen={false} adminOpen={true}/>
							</Box>
						</td>
						<td class="admin-license-mgmt-page-td">
							<Box sx={{ height: `100%` }}>
								<div class="admin-license-mgmt-page-div">
									<LicenseMgmtComponent />
								</div>
							</Box>
						</td>
					</tr>
				</table>
			</div>
		);
	} else {
		window.location.href = '/';
	}
};

export default LicenseKeyMgmtPage;