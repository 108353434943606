import React from 'react';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

export default function AddScanMessageDialog(props) {
    const {
        onClose,
        open,
        ip,
        ipSetter,
        ipValid,
        ipErrMessage,
        iapLocation,
        iapLocationSetter,
        iapLocationValid,
        iapLocationErrMessage,
        received,
        receivedSetter,
        receivedValid,
        receivedErrMessage,
        updated,
        updatedSetter,
        updatedValid,
        updatedErrMessage,
        addButtonEnabled,
        addButtonEnabledSetter,
        addButtonClickListener,
        validateButtonClickListener,
        iapLocationOptions
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleIPAddressContentChange = (event) => {
        ipSetter(event.target.value);
    };

    const handleIAPLocationChange = (event) => {
        iapLocationSetter(event.target.value);
    };

    const handleReceivedChange = (val) => {
        addButtonEnabledSetter();
        receivedSetter(val);
    };

    const handleUpdatedChange = (val) => {
        addButtonEnabledSetter();
        updatedSetter(val);
    };


    const handleAddButtonClicked = (event) => {
        addButtonClickListener(event);
    };

    const handlValidateButtonClicked = (event) => {
        validateButtonClickListener(event);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Add New Scanner Message</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField
                        {...(ipValid !== true && { error: undefined })}
                        helperText={ipErrMessage}
                        label="Host Address"
                        variant="outlined"
                        size="medium"
                        value={ip}
                        onChange={handleIPAddressContentChange}
                        id="scan-message-table-add-modal-dialog-host-address-field"
                        style={{marginTop: '15px'}}
                    />
                    <FormControl>
                        <InputLabel id="scan-message-table-add-modal-dialog-iap-location-select-label">IAP Location</InputLabel>
                        <Select
                            {...(iapLocationValid !== true && { error: undefined })}
                            helperText={iapLocationErrMessage}
                            labelId="scan-message-table-add-modal-dialog-iap-location-select-label"
                            id="scan-message-table-add-modal-dialog-iap-location-select"
                            label="IAP Location"
                            onChange={handleIAPLocationChange}
                            defaultValue={iapLocationOptions[0]}
                            value={iapLocation}
                        >
                            {iapLocationOptions.map((location) => {
                                let isSelected = location === iapLocation;
                                return (
                                    <MenuItem selected={isSelected} value={location}>{location}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            {...(receivedValid !== true && { error: undefined })}
                            helperText={receivedErrMessage}
                            slotProps={{
                                textField: {
                                required: true,
                                id: 'scan-message-table-add-modal-dialog-received-field',
                                },
                                }}
                            // renderInput={(props) => <TextField
                            //    id="scan-message-table-add-modal-dialog-received-field"
                            //    {...props} />}
                            label="Received"
                            value={received}
                            onChange={handleReceivedChange}
                            onChangeRaw={handleReceivedChange}
                            ampm={false}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            {...(updatedValid !== true && { error: undefined })}
                            helperText={updatedErrMessage}
                            slotProps={{
                                textField: {
                                required: true,
                                id: 'scan-message-table-add-modal-dialog-updated-field',
                                },
                                }}
                            //renderInput={(props) => <TextField
                            //    id="scan-message-table-add-modal-dialog-updated-field"
                            //    {...props} />}
                            label="Updated"
                            value={updated}
                            onChange={handleUpdatedChange}
                            onChangeRaw={handleUpdatedChange}
                            ampm={false}
                        />
                    </LocalizationProvider>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="scan-message-table-add-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="scan-message-table-add-modal-dialog-validate-button"
                            variant="contained"
                            onClick={handlValidateButtonClicked}>Validate</Button>
                        <Button id="scan-message-table-add-modal-dialog-add-button"
                            variant="contained"
                            onClick={handleAddButtonClicked}
                            disabled={!addButtonEnabled}>Add</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
