import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import NFTRulesTab from './NFTRulesTab';
import { useKeycloak } from '@react-keycloak/web';
import ViewNFTablesRulesetDetailsDialog from './modals/ViewNFTablesRulesetDetailsDialog';
import './NFTRulesTable.css';
import { fetchErrorMessage } from '../mainpage';
/**
 * Main url for fetching nftables generated rulesets
 */
const nftablesUrl = '/middleware/api/nftrules/';

const startAdhocRuleGenerationURL = '/middleware/api/v3/generate_adhoc_ruleset'
const rowsPerPage = 20;

/* NUmber of milliseconds to show notifications */
const notificationAutoclose = 5000;

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`nftrules-tabpanel-${index}`}
			aria-labelledby={`nftrules-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `nftrules-tab-${index}`,
		'aria-controls': `nftrules-tabpanel-${index}`,
	};
}


function createNftRulesetRowData(id, created, sha1, unix_timestamp) {
	return {
		id,
		created,
		sha1,
		unix_timestamp,
	};
}

// required so that we don't have to click reset button twice
let freshSearchTerm = '';

function NFTRulesComponent() {
	const { keycloak } = useKeycloak();

	const [tabIndex, setTabIndex] = React.useState(0);

	// snackbar state vars
	const [snackState,] = React.useState({
		snackbarVertical: 'bottom',
		snackbarHorizontal: 'center',
	});

	const { snackbarHorizontal, snackbarVertical } = snackState;

	const [nftRulesRefreshNotificationOpen, setNftRulesRefreshNotificationOpen] = useState(false);
	const nftRulesRefreshNotificationMessage = "NFTables Ruleset Data Refreshed";

	const [adhocRulesetGenerationStartedNotificationOpen, setAdhocRulesetGenerationStartedNotificationOpen] = useState(false);
	const adhocRulesetGenerationStartedNotificationMessage = "Adhoc NFTables Ruleset Generation Started";
	const [fetchErrorMessageOpen, setFetchErrorMessageOpen] = useState(false);
	const handleFetchErrorClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setFetchErrorMessageOpen(false);
	};

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('created');
	const [selected, setSelected] = useState([]);
	const [offset, setOffset] = useState(0);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [viewButtonEnabled, setViewButtonEnabled] = useState(false);
	const [selectedId, setSelectedId] = useState(0);
	const [selectedSha1, setSelectedSha1] = useState('N/A');
	const [selectedCreated, setSelectedCreated] = useState('N/A');
	const [selectedUnixTimestamp, setSelectedUnixTimestamp] = useState('N/A');
	const [nftSwitch, setNftSwitch] = useState(false);

	useEffect(() => {
		fetchData();
	}, [nftSwitch])

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	const getSearchTerm = () => {
		return freshSearchTerm;
	};

	const getSearchFieldContent = () => {
		let ret = getSearchTerm();
		return encodeURIComponent(ret);
	};

	const fetchData = async () => {
		const sanitizedSearchTerm = getSearchFieldContent();
		const orderPrefix = (order === 'desc' ? '-' : '');

		await fetch(nftablesUrl
			+ '?limit=' + rowsPerPage
			+ '&offset=' + offset
			+ '&page=' + (page + 1)
			+ (sanitizedSearchTerm === '' ? '' : '&search=' + sanitizedSearchTerm)
			+ '&ordering=' + orderPrefix + orderBy, {
			method: 'GET',
			headers: {
				'access-token': keycloak.token
			},
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				setTotal(respData.count);

				let tmpRows = [];
				for (let i = 0; i < respData.results.length; i++) {
					let entry = respData.results[i];
					tmpRows.push(createNftRulesetRowData(entry['id'], entry['created'], entry['sha1'], entry['unix_timestamp']));
				}

				setSelectedId(0);
				setSelectedCreated('N/A');
				setSelectedSha1('N/A');
				setSelectedUnixTimestamp('N/A');
				setSelected([]);
				setRows(tmpRows);
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
		setNftSwitch(false);
	};

	const fetchModalData = async () => {
		await fetch(nftablesUrl
			+ selectedId + '/', {
			method: 'GET',
			headers: {
				'access-token': keycloak.token
			},
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((respData) => {
				setSelectedId(respData['id']);
				setSelectedCreated(respData['created']);
				setSelectedSha1(respData['sha1']);
				setSelectedUnixTimestamp(respData['unix_timestamp']);
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
	};

	const handleStartAdhocRuleGeneration = () => {
		fetch(startAdhocRuleGenerationURL, {
			method: 'GET',
			headers: {
				'access-token': keycloak.token
			}
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.text();
			})
			.then((response) => {
				displayAdhocRulesetGenerationStarted();
			}).catch((error) => {
				console.log('error: ' + error);
				setFetchErrorMessageOpen(true);
			});
	}

	const handleSearchTermChanged = (val) => {
		setSearchTerm(val);
		freshSearchTerm = val;
	};

	const handleViewNftRulesetButtonClick = () => {
		fetchModalData();
		setModalOpen(true);
	}

	const handleModalClose = () => {
		setModalOpen(false);
		setSelected([]);
		handleNftRuleSelected(0);
		setViewButtonEnabled(false);
	};

	const handleNftRuleSelected = (val) => {
		setSelectedId(val);
	};

	const handleNftRulesRefreshNotificationClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setNftRulesRefreshNotificationOpen(false);
	};

	const displayNftRulesRefreshStarted = (event) => {
		setNftRulesRefreshNotificationOpen(true);
	};

	const handleNftRulesRefreshButtonClick = (event) => {
		fetchData();
		displayNftRulesRefreshStarted();
	};

	const handleAdhocRulesetGenerationStartedNotificationClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setAdhocRulesetGenerationStartedNotificationOpen(false);
	};

	const displayAdhocRulesetGenerationStarted = (event) => {
		setAdhocRulesetGenerationStartedNotificationOpen(true);
	};

	const getOrderBy = () => {
		return orderBy;
	}

	const getOrder = () => {
		return order;
	}

	return (
		<Box sx={{ width: '100%' }} id="nftables-rules-top-level-box">
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Snackbar
					open={nftRulesRefreshNotificationOpen}
					autoHideDuration={notificationAutoclose}
					onClose={handleNftRulesRefreshNotificationClosed}
					anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
				>
					<MuiAlert
						className="logi-snackbar-notification-message"
						severity="info"
						variant="filled"
						sx={{ width: '100%' }}>
						{nftRulesRefreshNotificationMessage}
					</MuiAlert>
				</Snackbar>
				<Snackbar
					open={adhocRulesetGenerationStartedNotificationOpen}
					autoHideDuration={notificationAutoclose}
					onClose={handleAdhocRulesetGenerationStartedNotificationClosed}
					anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
				>
					<MuiAlert
						className="logi-snackbar-notification-message"
						severity="info"
						variant="filled"
						sx={{ width: '100%' }}>
						{adhocRulesetGenerationStartedNotificationMessage}
					</MuiAlert>
				</Snackbar>
				<Snackbar
					open={fetchErrorMessageOpen}
					autoHideDuration={notificationAutoclose}
					onClose={handleFetchErrorClosed}
					anchorOrigin={{ vertical: snackbarVertical, horizontal: snackbarHorizontal }}
				>
					<MuiAlert
						className="logi-snackbar-notification-message"
						severity="info"
						variant="filled"
						sx={{ width: '100%' }}>
						{fetchErrorMessage}
					</MuiAlert>
				</Snackbar>
				<ViewNFTablesRulesetDetailsDialog
					open={modalOpen}
					onClose={handleModalClose}
					selectedCreated={selectedCreated}
					selectedSha1={selectedSha1}
					selectedUnixTimestamp={selectedUnixTimestamp}
				/>

				<Tabs value={tabIndex} onChange={handleTabChange} aria-label="NFTablesRules Tab Panel">
					<Tab label="NFTables Rules" {...a11yProps(0)} />
				</Tabs>
			</Box>
			<TabPanel value={tabIndex} index={0}>
				<NFTRulesTab
					viewButtonEnabled={viewButtonEnabled}
					viewButtonClickListener={handleViewNftRulesetButtonClick}
					onSelectRowId={handleNftRuleSelected}
					viewButtonToggle={setViewButtonEnabled}
					order={getOrder}
					orderSetter={setOrder}
					orderBy={getOrderBy}
					orderBySetter={setOrderBy}
					setNftSwitch={setNftSwitch}
					selected={selected}
					selectedSetter={setSelected}
					offset={offset}
					offsetSetter={setOffset}
					page={page}
					pageSetter={setPage}
					rows={rows}
					total={total}
					totalSetter={setTotal}
					searchTerm={searchTerm}
					searchTermSetter={handleSearchTermChanged}
					fetchData={fetchData}
					rowsPerPage={rowsPerPage}
					triggerAdhocRuleGenerationButtonClick={handleStartAdhocRuleGeneration}
					refreshButtonClickHandler={handleNftRulesRefreshButtonClick}
				/>
			</TabPanel>
		</Box>
	);
};

export default NFTRulesComponent;
