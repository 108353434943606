import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    populateHiddenCols
} from '../../common/table/TableHelper';
import EnhancedTableToolbar from '../../common/table/EnhancedTableToolbar';
import {
    LOGI_ROLE_SCAN_CONTAINERS_READ,
    LOGI_ROLE_SCAN_CONTAINERS_ADD,
    LOGI_ROLE_SCAN_CONTAINERS_EDIT,
    LOGI_ROLE_SCAN_CONTAINERS_DELETE
} from '../../common/LogiRoles';
import SingleSelectTableHead from '../../common/table/SingleSelectTableHead';
import SelectedcontainerEditWidget from './widgets/SelectedcontainerEditWidget';
import ScanContainerFilterWidget from './widgets/ScanContainerFilterWidget';

const mainTableHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Id',
        hide: true,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
        hide: false,
    },
    {
        id: 'subnet_count',
        numeric: true,
        disablePadding: true,
        label: 'Subnet Count',
        hide: false,
    },
    {
        id: 'total_addresses',
        numeric: true,
        disablePadding: true,
        label: 'Total Addresses',
        hide: false,
    },
    {
        id: 'ip_version',
        numeric: true,
        disablePadding: true,
        label: 'IP Version',
        hide: false,
    },
];

const mainTableHiddenCols = populateHiddenCols(mainTableHeadCells);

const getMainRowCells = (row, labelId, isItemSelected, handleClick) => {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!mainTableHiddenCols.has("id") ? (<TableCell align="left">{row.id}</TableCell>) : ''}
            {!mainTableHiddenCols.has("name") ? (<TableCell align="left">{row.name}</TableCell>) : ''}
            {!mainTableHiddenCols.has("subnet_count") ? (<TableCell align="left">{row.subnet_count.toLocaleString()}</TableCell>) : ''}
            {!mainTableHiddenCols.has("total_addresses") ? (<TableCell align="left">{row.total_addresses.toLocaleString()}</TableCell>) : ''}
            {!mainTableHiddenCols.has("ip_version") ? (<TableCell align="left">{row.ip_version.toLocaleString()}</TableCell>) : ''}
        </TableRow>
    )
};

export default function ScanContainersTab(props) {
    const {
        handleScanContainerRowSelected,
        addScanContainerButtonEnabled,
        addBScanContaineruttonClickHandler,
        deleteButtonEnabled,
        deleteButtonClickHandler,
        scanContainersOrder,
        scanContainersOrderSetter,
        scanContainersOrderBy,
        scanContainersOrderBySetter,
        selectedContainers,
        selectedContainersSetter,
        scanContainersOffset,
        scanContainersOffsetSetter,
        scanContainersPage,
        scanContainersPageSetter,
        scanContainersRows,
        scanContainersTotal,
        scanContainersTotalSetter,
        ipVersionSetter,
        searchTerm,
        searchTermSetter,
        scanContainersRowsPerPage,
        fetchContainersData,
        addSubnetButtonEnabled,
        addSubnetButtonClickListener,
        deleteSubnetButtonEnabled,
        deleteSubnetButtonClickListener,
        updateNameButtonEnabled,
        updateNameButtonClickListener,
        validateNameButtonEnabled,
        validateNameButtonClickListener,
        subnetsOrder,
        subnetsOrderSetter,
        subnetsOrderBy,
        subnetsOrderBySetter,
        selectedSubnets,
        selectedSubnetsSetter,
        containerName,
        containerNameSetter,
        containerNameValid,
        containerNameErrorMessage,
        subnetRows,
        subnetTotal,
        handleSubnetSelected,
        containerDescription,
        containerDescriptionSetter,
        updateDescriptionButtonEnabled,
        updateDescriptionButtonClickListener,
        refreshButtonClickHandler
    } = props;

    const { keycloak } = useKeycloak();

    const hasScanContainersRead = keycloak.hasRealmRole(LOGI_ROLE_SCAN_CONTAINERS_READ);
    const hasScanContainersAdd = keycloak.hasRealmRole(LOGI_ROLE_SCAN_CONTAINERS_ADD);
    const hasScanContainersEdit = keycloak.hasRealmRole(LOGI_ROLE_SCAN_CONTAINERS_EDIT);
    const hasScanContainersDelete = keycloak.hasRealmRole(LOGI_ROLE_SCAN_CONTAINERS_DELETE);

    useEffect(() => {
        handleResetButtonClick();
        // fetchContainersData(true);
    }, []);

    const handleRequestSort = (event, property) => {
        scanContainersOrderBySetter(property);
        const isAsc = scanContainersOrderBy === property && scanContainersOrder === 'asc';
        scanContainersOrderSetter(isAsc ? 'desc' : 'asc');
        fetchContainersData(true);
    };

    const isSelected = (id) => {
        return selectedContainers.indexOf(id) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = 0;

    const handleSearchTermChange = (event) => {
        searchTermSetter(event.target.value);
    };
    const handleSearchButtonClick = (event) => {
        event.preventDefault();
        selectedContainersSetter([]);
        handleScanContainerRowSelected(0);
        fetchContainersData(true);
    };

    function handleResetButtonClick(event) {
        scanContainersOffsetSetter(0);
        scanContainersPageSetter(0);
        scanContainersTotalSetter(0);
        ipVersionSetter(4);
        scanContainersOrderSetter('asc');
        scanContainersOrderBySetter('name');
        searchTermSetter('');
        selectedContainersSetter([]);
        handleScanContainerRowSelected(0);
        fetchContainersData(true);
    }

    const handleChangePage = (event, newPage) => {

        let newOffset = scanContainersOffset;

        if (newPage > scanContainersPage) {
            newOffset += scanContainersRowsPerPage;
        }
        else {
            newOffset -= scanContainersRowsPerPage;
            if (newOffset < 0) {
                newOffset = 0;
            }
        }
        scanContainersOffsetSetter(newOffset);
        selectedContainersSetter([]);
        handleScanContainerRowSelected(0);
        scanContainersPageSetter(newPage);
        // fetch the next page's data
        fetchContainersData(true);
    };

    const handleScanContainersRowClick = (event, id) => {
        const selectedIndex = selectedContainers.indexOf(id);
        let newSelected = [];

        if (selectedIndex > -1) {
            // this row is already selected, we'll unselected it by setting 
            // new selected to []
            handleScanContainerRowSelected(0);
        }
        else {
            // select the new id
            newSelected.push(id);
            handleScanContainerRowSelected(id);
        }

        selectedContainersSetter(newSelected);
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from.toLocaleString()}–${to.toLocaleString()} of ${count !== -1 ? count.toLocaleString() : `more than ${to.toLocaleString()}`}`;
    };

    const handleDeleteScanContainerButtonClick = (event) => {
        deleteButtonClickHandler(fetchContainersData);
    };

    if (hasScanContainersRead) {
        return (
            <div id="scan-containers-table-top-level-div">
                <div id="scan-containers-table-button-bar-div">
                <Accordion style={{'borderRadius': '10px'}} defaultExpanded={true}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Scan Container Search / Refresh</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={2} direction="column">
                        <Stack spacing={2} direction="row">
                            <Button
                                id="scan-containers-table-refresh-button"
                                variant="contained"
                                onClick={refreshButtonClickHandler}>Refresh</Button>
                        </Stack>
                        <ScanContainerFilterWidget
                            searchTerm={searchTerm}
                            onSearchClick={handleSearchButtonClick}
                            onResetClick={handleResetButtonClick}
                            onSearchTermChange={handleSearchTermChange} />
                    </Stack>
                    </AccordionDetails>
                </Accordion>
                </div>
                <div id="scan-containers-table-div">
                    <Stack spacing={2} direction="row">
                        <Box sx={{ width: '100%' }} id="scan-containers-table-box">
                            <TableContainer>
                                <Table
                                    sx={{ width: '100%' }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    id="scan-containers-table"
                                >
                                    <SingleSelectTableHead
                                        numSelected={selectedContainers.length}
                                        order={scanContainersOrder()}
                                        orderBy={scanContainersOrderBy()}
                                        onRequestSort={handleRequestSort}
                                        rowCount={scanContainersRows.length}
                                        headCells={mainTableHeadCells}
                                        fetchMethod={fetchContainersData}
                                        id="scan-containers-table-head"
                                    />
                                    <TableBody id="scan-containers-table-body">
                                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
                                        {scanContainersRows.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `scan-containers-table-checkbox-${index}`;

                                            return (
                                                getMainRowCells(row, labelId, isItemSelected, handleScanContainersRowClick)
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    // height: 33 * emptyRows,
                                                    height: 33,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[scanContainersRowsPerPage]}
                                component="div"
                                count={scanContainersTotal}
                                rowsPerPage={scanContainersRowsPerPage}
                                page={scanContainersPage}
                                onPageChange={handleChangePage}
                                labelDisplayedRows={defaultLabelDisplayedRows}
                            />
                        </Box>
                        <div class="scan-containers-table-buttons-div">
                            <Stack spacing={2} direction="column">
                                {hasScanContainersAdd ? (
                                    <Button id="scan-containers-table-add-button"
                                        variant="contained"
                                        onClick={addBScanContaineruttonClickHandler}
                                        disabled={!addScanContainerButtonEnabled}>Add</Button>
                                ) : ('')}
                                {hasScanContainersDelete ? (
                                    <Button id="scan-containers-table-delete-button"
                                        variant="contained"
                                        onClick={handleDeleteScanContainerButtonClick}
                                        disabled={!deleteButtonEnabled}>Delete</Button>
                                ) : ('')}
                            </Stack>
                        </div>
                    </Stack>
                </div>
                {selectedContainers.length === 0 ? ('') :
                    (<SelectedcontainerEditWidget
                        hasScanContainersEdit={hasScanContainersEdit}
                        addSubnetButtonEnabled={addSubnetButtonEnabled}
                        addSubnetButtonClickListener={addSubnetButtonClickListener}
                        deleteSubnetButtonEnabled={deleteSubnetButtonEnabled}
                        deleteSubnetButtonClickListener={deleteSubnetButtonClickListener}
                        updateNameButtonEnabled={updateNameButtonEnabled}
                        updateNameButtonClickListener={updateNameButtonClickListener}
                        validateNameButtonEnabled={validateNameButtonEnabled}
                        validateNameButtonClickListener={validateNameButtonClickListener}
                        subnetsOrder={subnetsOrder}
                        subnetsOrderSetter={subnetsOrderSetter}
                        subnetsOrderBy={subnetsOrderBy}
                        subnetsOrderBySetter={subnetsOrderBySetter}
                        selectedSubnets={selectedSubnets}
                        selectedSubnetsSetter={selectedSubnetsSetter}
                        containerName={containerName}
                        containerNameSetter={containerNameSetter}
                        containerNameValid={containerNameValid}
                        containerNameErrorMessage={containerNameErrorMessage}
                        subnetRows={subnetRows}
                        subnetTotal={subnetTotal}
                        handleSubnetSelected={handleSubnetSelected}
                        containerDescription={containerDescription}
                        containerDescriptionSetter={containerDescriptionSetter}
                        updateDescriptionButtonEnabled={updateDescriptionButtonEnabled}
                        updateDescriptionButtonClickListener={updateDescriptionButtonClickListener}
                    />)
                }
            </div>
        );
    }
    else {
        return ('');
    }
}