import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function AddeportRecipientDialog(props) {
    let { open, onClose,
        reportRecipientName,
        reportRecipientNameSetter,
        ReportRecipientNameValid,
        reportRecipientNameErrorMessage,
        reportRecipientEmail,
        reportRecipientEmailSetter,
        reportRecipientEmailValid,
        reportRecipientEmailErrorMessage,
        reportRecipientEnabled,
        recipientEnabledSetter,
        addButtonEnabled,
        addButtonClickListener,
        validateButtonEnabled,
        validateButtonClickListener
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleCloseButtonClick = (event) => {
        onClose();
    };

    const handleNameChanged = (event) => {
        reportRecipientNameSetter(event.target.value);
    };

    const handleEmailChanged = (event) => {
        reportRecipientEmailSetter(event.target.value);
    };

    const handleEnabledChanged = (event) => {
        recipientEnabledSetter(event.target.checked)
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Add Report Recipient</DialogTitle>
            <div class="modal-dialog-content-div">
                <Stack spacing={2} direction="column">
                    <TextField id="add-report-recipient-name"
                        {...(ReportRecipientNameValid !== true && { error: undefined })}
                        helperText={reportRecipientNameErrorMessage}
                        label="Name"
                        variant="outlined"
                        size="small"
                        value={reportRecipientName}
                        onChange={handleNameChanged}
                        style={{marginTop: '15px'}}
                    />
                    <TextField id="add-report-recipient-email"
                        {...(reportRecipientEmailValid !== true && { error: undefined })}
                        helperText={reportRecipientEmailErrorMessage}
                        label="Email"
                        variant="outlined"
                        size="small"
                        value={reportRecipientEmail}
                        onChange={handleEmailChanged}
                    />
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            id="add-report-recipient-enabled-checkbox"
                            checked={reportRecipientEnabled}
                            onChange={handleEnabledChanged}
                        />} label="Enabled" />
                    </FormGroup>
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Button id="report-recipients-tab-add-modal-dialog-close-button"
                            variant="contained"
                            onClick={handleCloseButtonClick}>Close</Button>
                        <Button id="report-recipients-tab-add-modal-dialog-validate-button"
                            variant="contained"
                            disabled={!validateButtonEnabled ? true : false}
                            onClick={validateButtonClickListener}>Validate</Button>
                        <Button id="report-recipients-tab-add-modal-dialog-add-button"
                            variant="contained"
                            disabled={!addButtonEnabled ? true : false}
                            onClick={addButtonClickListener}>Add</Button>
                    </Stack>
                </Stack>
            </div>
        </Dialog>
    );
}
