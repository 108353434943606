import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {
    populateHiddenCols,
    getComparator,
    stableSort
} from '../../../common/table/TableHelper';
import SingleSelectTableHead from '../../../common/table/SingleSelectTableHead';

const scanContainerSubnetHeadCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Id',
        hide: true,
    },
    {
        id: 'address',
        numeric: false,
        disablePadding: true,
        label: 'Subnet',
        hide: false,
    },
];

const subnetsTableHiddenCols = populateHiddenCols(scanContainerSubnetHeadCells);

const getRowCells = (row, labelId, isItemSelected, handleClick) => {
    return (
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.address}
            selected={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {!subnetsTableHiddenCols.has("address") ? (<TableCell align="left">{row.address}</TableCell>) : ''}
        </TableRow>
    )
};

export default function SelectedcontainerEditWidget(props) {
    const {
        hasScanContainersEdit,
        addSubnetButtonEnabled,
        addSubnetButtonClickListener,
        deleteSubnetButtonEnabled,
        deleteSubnetButtonClickListener,
        updateNameButtonEnabled,
        updateNameButtonClickListener,
        validateNameButtonEnabled,
        validateNameButtonClickListener,
        subnetsOrder,
        subnetsOrderSetter,
        subnetsOrderBy,
        subnetsOrderBySetter,
        selectedSubnets,
        selectedSubnetsSetter,
        containerName,
        containerNameSetter,
        containerNameValid,
        containerNameErrorMessage,
        subnetRows,
        subnetTotal,
        handleSubnetSelected,
        containerDescription,
        containerDescriptionSetter,
        updateDescriptionButtonEnabled,
        updateDescriptionButtonClickListener
    } = props;

    const handleContainerNameFieldInputChange = (event) => {
        containerNameSetter(event.target.value)
    };

    const handleContainerDescriptionFieldInputChange = (event) => {
        containerDescriptionSetter(event.target.value)
    };

    const handleSubnetRowClick = (event, id) => {
        const selectedIndex = selectedSubnets.indexOf(id);
        let newSelected = [];

        if (selectedIndex > -1) {
            // this row is already selected, we'll unselected it by setting 
            // new selected to []
            handleSubnetSelected(0);
        }
        else {
            // select the new id
            newSelected.push(id);
            handleSubnetSelected(id);
        }

        selectedSubnetsSetter(newSelected);
    };

    const handleRequestSort = (event, property) => {
        subnetsOrderBySetter(property);
        const isAsc = subnetsOrderBy === property && subnetsOrder === 'asc';
        subnetsOrderSetter(isAsc ? 'desc' : 'asc');
        // fetchContainersData() -;
    };

    const fetchSubnetData = () => {

    };

    const isSelected = (id) => {
        return selectedSubnets.indexOf(id) !== -1;
    };

    const defaultLabelDisplayedRows = (val) => {
        return val.toLocaleString() + ' Subnet(s)';
    };

    const readOnlycontainerNameChangeListener = (event) => {
        // user doesn't have the role needed
        // do nothing here
    };

    return (
        <div id="scan-containers-edit-widget-div">
            <Stack id="scan-containers-edit-widget-control-stack"
                spacing={0}
                direction="column"
            >
                {hasScanContainersEdit === true ? (<Stack
                    spacing={2}
                    direction="row"
                    justifyContent="left"
                    alignItems="baseline">
                    <TextField id="scan-containers-edit-widget-container-name-input"
                        {...(containerNameValid !== true && { error: undefined })}
                        helperText={() => { return containerNameErrorMessage }}
                        label="Container Name"
                        variant="outlined"
                        size="medium"
                        value={containerName}
                        onChange={handleContainerNameFieldInputChange}
                        sx={{ minWidth: 350, maxWidth: 500 }}
                    />
                    <Button id="scan-containers-edit-widget-validate-name-button"
                        variant="contained"
                        onClick={validateNameButtonClickListener}
                        disabled={!validateNameButtonEnabled}
                        sx={{ maxHeight: 55 }}
                    >Validate Name</Button>
                    <Button id="scan-containers-edit-widget-edit-name-button"
                        variant="contained"
                        onClick={updateNameButtonClickListener}
                        disabled={!updateNameButtonEnabled}
                        sx={{ maxHeight: 55 }}
                    >Update Name</Button>
                </Stack>) : (
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="left"
                        alignItems="baseline">
                        <TextField id="scan-containers-edit-widget-container-name-input"
                            label="Container Name"
                            variant="outlined"
                            size="medium"
                            value={containerName}
                            onChange={readOnlycontainerNameChangeListener}
                            sx={{ minWidth: 350, maxWidth: 500 }}
                        />
                    </Stack>)}
                {hasScanContainersEdit === true ? (<Stack
                    spacing={2}
                    direction="row"
                    justifyContent="left"
                    alignItems="baseline">
                    <TextField id="scan-containers-edit-widget-container-description-input"
                        {...(containerNameValid !== true && { error: undefined })}
                        helperText={() => { return containerNameErrorMessage }}
                        label="Container Description"
                        variant="outlined"
                        size="medium"
                        value={containerDescription}
                        onChange={handleContainerDescriptionFieldInputChange}
                        sx={{ minWidth: 350, maxWidth: 500 }}
                        multiline
                        minRows={5}
                    />
                    <Button id="scan-containers-edit-widget-edit-description-button"
                        variant="contained"
                        onClick={updateDescriptionButtonClickListener}
                        disabled={!updateDescriptionButtonEnabled}
                        sx={{ maxHeight: 55 }}
                    >Update Description</Button>
                </Stack>) : (
                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="left"
                        alignItems="baseline">
                        <TextField id="scan-containers-edit-widget-container-description-input"
                            label="Container Description"
                            variant="outlined"
                            size="medium"
                            value={containerDescription}
                            onChange={readOnlycontainerNameChangeListener}
                            sx={{ minWidth: 350, maxWidth: 500 }}
                            multiline
                            minRows={5}
                        />
                    </Stack>)}
                <table>
                    <tr>
                        <td>
                            <TableContainer>
                                <Table
                                    sx={{ width: '100%' }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    id="scan-container-subnets-table"
                                >
                                    <SingleSelectTableHead
                                        numSelected={selectedSubnets.length}
                                        order={subnetsOrder}
                                        orderBy={subnetsOrderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={subnetRows.length}
                                        headCells={scanContainerSubnetHeadCells}
                                        fetchMethod={fetchSubnetData}
                                        id="scan-container-subnets-table-head"
                                    />
                                    <TableBody id="scan-container-subnets-table-body">
                                        {stableSort(subnetRows, getComparator(subnetsOrder, subnetsOrderBy))
                                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `scan-containers-edit-widget-subnets-table-checkbox-${index}`;

                                                return (
                                                    getRowCells(row, labelId, isItemSelected, handleSubnetRowClick)
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div class="MuiTablePagination-root">{defaultLabelDisplayedRows(subnetTotal)}</div>
                        </td>
                        <td id="scan-containers-edit-widget-subnets-table-button-td">
                            <Stack
                                spacing={2}
                                direction="column"
                                justifyContent="left"
                                alignItems="baseline">
                                {hasScanContainersEdit === true ? (
                                    <Button id="scan-containers-edit-widget-add-subnet-button"
                                        variant="contained"
                                        disabled={!addSubnetButtonEnabled}
                                        sx={{ minWidth: "5.3rem", maxHeight: 55 }}
                                        onClick={addSubnetButtonClickListener}
                                    >Add</Button>) : ('')}
                                {hasScanContainersEdit === true ? (
                                    <Button id="scan-containers-edit-widget-delete-subnet-button"
                                        variant="contained"
                                        disabled={!deleteSubnetButtonEnabled}
                                        sx={{ maxHeight: 55 }}
                                        onClick={deleteSubnetButtonClickListener}
                                    >Delete</Button>) : ('')}
                            </Stack>
                        </td>
                    </tr>
                </table>
            </Stack>
        </div>
    );
};